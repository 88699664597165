import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Dialog, Select, MenuItem, FormControl, InputLabel, OutlinedInput, 
         InputAdornment,  Box, Typography, Grid,  FormControlLabel, TextField, 
         Button, IconButton, CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Files from "../Files/Files";
import { DialogActions, DialogContent, DialogTitle } from "./../CustomDialog";

import {countryToFlag} from '../../store/countries';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 180,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

const countryUA = {    
    Code: "1",
    CurrencyId: "FC7C5C6B-2EFD-48D9-8185-37A79F571C83",
    CurrencyIdrref: {$type: 'System.Byte[], System.Private.CoreLib', $value: 'gYU3p59XHINI2S79/Hxcaw=='},
    Id: "751FD88A-5FDD-11E2-BEBC-0050569667C4",
    Idrref: {$type: 'System.Byte[], System.Private.CoreLib', $value: 'vrwAUFaWZ8QR4l/ddR/Yig=='},
    IsDeleted: false,
    Name: "Україна",
    TwoLetterISORegionName: "UA"
};

const rowsPerPageOptions = [25, 50, 100, 500, 1000];

const CatalogItem = forwardRef(function CatalogItem(props, ref) {
    const { token, id } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const [items, setItems] = useState([]);
    // const [changed, setChanged] = useState([]);
    const changed = [];

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(countryUA);
    const [catalogSetting, setCatalogSetting] = useState();
    // const [files, setFiles] = useState([]);

    const [pageItems, setPageItems] = useState(0);
    const [rowsPerPageItems, setRowsPerPageItems] = useState(25);
    const [magazine, setMagazine] = useState();
    
    const [openDlg, setOpenDlg] = useState(false);

    
    useImperativeHandle(ref, () => ({
        updateData() {            
            if(magazine && country){
                const fi = catalogSetting.Files.findIndex(f => f.Appointment === magazine.Appointment && f.TwoLetterISORegionName === country.TwoLetterISORegionName);
                if(fi > -1){
                    const updated = {
                            CatalogSetting: {...catalogSetting},
                            Items: changed
                    };

                    updated.CatalogSetting.Files[fi] = magazine;
                    
                    const url = `/api/v1.0/catalog/savecatalogsettings/${id}`; 
                    setLoading(true);
                    fetch(url,
                        {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json;charset=UTF-8',
                                'Authorization': token
                            },
                            body: JSON.stringify(updated)
                        })
                        .then(response => response.json())
                        .then(data => {
                            if(data){                    
                                console.log('Save catalog', data);
                                
                                setCountries(data.Countries);
                    
                                if(data.CatalogSetting){                    
                                    setCatalogSetting(data.CatalogSetting);
                                }
                    
                                if(data.Items){
                                    setItems(data.Items);
                                }

                            }
                        })
                        .catch((error) => {
                            console.log('Error save catalog', error);
                        })
                        .finally(() => { setLoading(false)});  
                }
            }                        
        },        
    }));

    const handleSelectFile = (path) => {
        setOpenDlg(false);
        console.log(`Select file: ${path}`);
        const changed = {...magazine};
        changed.Path = path;
        setMagazine(changed);
    };

    const handleClose = () => {
        setOpenDlg(false);
    };

    const handleDlgFile = () => {
        setOpenDlg(true);
    };

    const handleChangeCountry = (e) => {
        const idx = countries.findIndex(c => c.TwoLetterISORegionName === e.target.value);
        if(idx > -1){
            if(magazine){
                const fi = catalogSetting.Files.findIndex(f => f.Id === magazine.Id);
                if(fi > -1){
                    catalogSetting.Files[fi] = magazine;
                    setCatalogSetting({...catalogSetting});
                }
            }

            setCountry(countries[idx]);
        }
    };

    const handleChangeMagazinePage = (rowIndex, value) => {
        // console.log(items[rowIndex], value);
        const rowData = items[rowIndex];
        const idx = changed.findIndex(c => c.Code === rowData.Code &&  c.TwoLetterISORegionName === rowData.TwoLetterISORegionName);
        if(idx === -1){
            const row = {...rowData};
            row.MagazinePageIndex = value;
            changed.push(row);
        }else{
            changed[idx].MagazinePageIndex = value;
        }
        // console.log(changed);
        // setChanged(changed);        
    }; 

    const handleChangeMagazinePath = (e) => {
        if(magazine){
            const changed = {...magazine};
            changed.Path = e.target.value;
            setMagazine(changed);
        }
    }

    const getCatalog = useCallback(() => {
        if(!id)
            return;

        const url = `/api/v1.0/catalog/getcatalog/${id}`; 
        console.log(url);             
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    console.log('Catalog', data);
                    
                    setCountries(data.Countries);
                    
                    if(data.CatalogSetting){
                        // setFiles(data.CatalogSetting.Files);
                        setCatalogSetting(data.CatalogSetting);
                    }
                    
                    if(data.Items){
                        setItems(data.Items);
                    }                    
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { setLoading(false)});

    }, [id, token]);

    useEffect(() => {
        if(!catalogSetting) 
            return;

        const magazines = catalogSetting.Files.filter(f => (f.TwoLetterISORegionName === country.TwoLetterISORegionName && f.Appointment === 0 ) );

        if(magazines.length > 0){
            setMagazine(magazines[0]);
        }
        else{            
            const _magazine = {
                $type: "Start.Doing.Module.Core.Models.CatalogFile, Start.Doing.Module.Core",
                Appointment: 0,
                CatalogSettingId: catalogSetting.Id,
                CreatedOn: new Date(),
                Id: 0,
                Path: '',
                TwoLetterISORegionName: country.TwoLetterISORegionName,
                UpdatedOn: new Date()
            };
            catalogSetting.Files.push(_magazine);
            setCatalogSetting({...catalogSetting});
            setMagazine(_magazine);
        }
        
    }, [catalogSetting, country ]);

    // Load data by catalog
    useEffect(() => {
        getCatalog();        
    }, [getCatalog]);

    const columns = [
        {
          name: "Code",
          label: "Code",
          options: { 
            setCellProps: () => ({ style: { minWidth: '24px', maxWidth: "24px", width: "24px" }}),
          }
        },
        {
            name: "Name",
            label: "Name",
            options: { 
                setCellProps: () => ({ style: { minWidth: '400px', maxWidth: "400px" , width: "400px" }}),
            }
        },
        {
            name: 'TwoLetterISORegionName',
            label: 'Country',
            options: { 
                setCellProps: () => ({ style: { width: "16px" }}),                
                }
        },
        {
            name: "CurrencyName",
            label: "$"
        },
        {
            name: "Rest",
            options: {
                filter: false,
            }            
        },
        {
            name: "Reserve",
            label: "Res",
            options: {
                filter: false,
            }            
        },
        {
            name: "OldPrice",
            label: "Black Price",
            options: {
                filter: false,
            }
        },        
        {
            name: "CatalogPrice",
            label: "Red price",
            options: {
                filter: false,
            }
        },
        {
            name: "SellingPrice",
            label: "Consultant price",
            options: {
                filter: false,
            }            
        },
        {
            name: "CatalogPricePv",
            label: "BB Price",
            options: {
                filter: false,
            }            
        },        
        {
            name: "MagazinePageIndex",
            label: "Page",
            options: { 
                setCellProps: () => ({ style: { minWidth: '32px', maxWidth: "32px", width: "32px" }}),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        label=""
                        value={value}
                        control={
                            <TextField
                                size="small" 
                                variant="outlined" 
                                value={value} 
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 1000 } }}
                        />}
                        onChange={event => {                                            
                                            const p = event.target.value === "" ? 0 : parseInt(isNaN(event.target.value) ? 0 : event.target.value);
                                            updateValue(p);
                                            handleChangeMagazinePage(tableMeta.rowIndex, p)
                                        }}
                    />
                  )
            }
        },
        {
            name: "Series",
            label: "Seria"
        }

    ];

    const options = {
        download: true,
        print: false,
        filter: true,
        page: pageItems,
        rowsPerPage: rowsPerPageItems,
        rowsPerPageOptions: rowsPerPageOptions,
        serverSide: false,
        filterType: "textField",// "dropdown",
        responsive: 'vertical',
        tableBodyHeight:'450px',
        selectableRows: "none",        
        count: items ? items.length : 0, 
        onTableChange: (action, tableState) => {
          switch (action) {
            case "changePage":          
              // setPage(tableState.page);
              break;
            case "changeRowsPerPage":
              // setPage(0);
              // setPageSize(tableState.rowsPerPage);          
              break;
              case "search":
                {
                //   const phone = tableState.searchText ? tableState.searchText: "";
                //   if(phone.length === 0 || phone.length > 11 ) {
                //     setPage(0);
                //     setPhone(phone);            
                //   }
                  break;
                }
            default:
              break;
          }
        },
        setTableProps: () => {
          return {          
            size: 'small',
          };
        },                    
    };
    
    return (    
    <div className={classes.root}>
                    
        <Grid container direction="row" spacing={2}>
            <Grid item xs={4} md={2}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId="country-label"                    
                        value={countries.length > 0 ? country.TwoLetterISORegionName : ''}
                        onChange={handleChangeCountry}
                        label="Country"
                        id="country"
                        name="country"
                    >
                    {countries.map((c) => <MenuItem key={c.TwoLetterISORegionName} value={c.TwoLetterISORegionName}>{`${countryToFlag(c.TwoLetterISORegionName)}  ${c.Name}`}</MenuItem> )}            
                    </Select>
                </FormControl> 
            </Grid>
            <Grid item xs={8} md={6}>
                {magazine && 
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel  htmlFor="magazine-path">Interactive catalog file, pdf</InputLabel>
                        <OutlinedInput
                            id="magazine-path"
                            value={magazine.Path}
                            onChange={handleChangeMagazinePath}
                            labelWidth={190}
                            // endAdornment={
                            //     <InputAdornment position="start" >
                            //         <IconButton onClick={handleDlgFile}>
                            //             <MoreHorizIcon />
                            //         </IconButton>                                    
                            //     </InputAdornment>
                            // }
                        />
                </FormControl>
                }
            </Grid>
        </Grid>

        {loading && 
            <Typography display="block" align="center" variant="h4">Loading...</Typography>
        }

        {!loading && 
        <MUIDataTable 
            responsive="standard"
            data={items}
            columns={columns} 
            options={options}
        /> 
        }

        <Dialog onClose={handleClose} aria-labelledby="dialog-title-files" open={openDlg} fullWidth={true} maxWidth="lg" >
                <DialogTitle id="dialog-title-files" onClose={handleClose} >
                    Files
                </DialogTitle>
                <DialogContent dividers style={{minHeight: 600}}>
                    <Files onSelect={handleSelectFile} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="default">
                        {t("buttons.Close")}
                    </Button>
                </DialogActions>
        </Dialog>
    </div>
  )
});

CatalogItem.propTypes = {
    token: PropTypes.any,
    id: PropTypes.string.isRequired
}

export default CatalogItem