import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {Box, Grid, Typography} from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
import { AppContext, AppConsumer } from "../../providers/appProvider";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {        
        flexGrow: 1,
        width: "100%",        
    },
    topbox: {
        padding: theme.spacing(3,1),        
        height: theme.spacing(60),                
        width: "100%",
        display: "block",
        position: "relative",
        background: "linear-gradient(180deg, #44BEE5 -15.31%, #C7A4F8 108.09%)",
        [theme.breakpoints.down("sm")]: {
            height: theme.spacing(40),
        }
    },    
    topboxln1: {
        color: "#fff",
        textShadow: "1px 1px #858585",
        position: 'absolute',
        width: "50%",
        top: theme.spacing(30),
        right: theme.spacing(-1/2),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(1),
            fontSize: "2rem",
            width: "100%",
            top: theme.spacing(10),
            right: 0,
        },
    },
    boxn1: {
        marginLeft: theme.spacing(6), 
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(0), 
            padding: theme.spacing(7, 3),
        }
    },
    boxn2: {
        padding: theme.spacing(6, 0, 6, 8),
        background: "#444444",
        borderRadius: "30px",
        margin: theme.spacing(0, 6),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(0),
            padding: theme.spacing(7, 3),
            borderRadius: "0",
        }
    },
    boxn3: {
        marginLeft: theme.spacing(6), 
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(0), 
            padding: theme.spacing(7, 3),
        }
    },
    boxn4: {
        padding: theme.spacing(8,6),        
        [theme.breakpoints.down("sm")]: {            
            padding: theme.spacing(1, 1),        
        }
    },
    boxn4pnl: {
        padding: theme.spacing(8, 2),
        background: "#EFEFEF", 
        width: "100%", 
        height: "200px", 
        borderRadius: 8 
    },
    boxn5: {
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0, 6, 4),
        }
    },
    boxn6: {
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0, 6, 4),
        }
    },
    boxn7: {
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0, 6, 4),
        }
    },
    boxn8: {
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0, 6, 4),
        }
    },
    boxn9: {
        padding: theme.spacing(6, 0, 6, 8),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(4, 0, 6, 4),
        }
    }
  })
);

export const Marathon = () => {
    const classes = useStyles();
    // const auth = useContext(AppContext);

  return (
  <AppConsumer> 
    {() => (
        <div className={classes.root}>
            <Box className={classes.topbox}>
                <Typography className={classes.topboxln1} component="h1" variant='h3'>
                    Персональна програма переваг “Найкраще для своїх”
                    Персональна програма
                </Typography>
            </Box>
            <Box className={classes.boxn1}> 
                <Typography component='h5' variant='h5' gutterBottom>
                    Моментальний <br />
                    Welcome бонус 100 грн
                </Typography>
                <Typography  variant='body2'>
                    Можливо ми трохи крейзі, али подарунки то є наша пристрасть.<br /><br />
                    
                    Ми любимо дарувати наші найкращі товари тим, хто любить нашу продукцію та<br />
                    регулярно її купує. Тому зроби перше замовлення на 500 грн і в наступну посилку<br />
                    ми додамо наш супер-бестселер Засіб для чищення духовок, витяжок та грилю “Суперсила” лише за 1 грн.<br />

                    А потім за кожне замовлення на 1000 грн та більше ти зможеш сам обрати свій <br />
                    подарунок зі списку наших найкращих товарів
                </Typography>
            </Box>
            
            <Box className={classes.boxn2}> 
                <Grid container direction="row" spacing={0}  justify="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography display="block" align='center'>
                            <img style={{ width: "100%"}} alt="line" src="/assets/images/line.svg" />
                        </Typography>                        
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography style={{ color:"#C7A4F8"}} component='h5' variant='h5' gutterBottom>
                            Кешбек до 25%
                        </Typography>
                        <Typography variant="body2" style={{color: "#fff"}}>
                        Розмір кешбеку залежить лише від тебе.<br />
                        Стартуй з 10% кешбеку на будь-яке замовлення.<br />
                        Як тільки сума замовлень стане більше 3000 грн,<br />
                        твій кешбек збільшиться до 15%. Після 6000 грн,<br />
                        кешбек стане 20% і нарешті після 10000 грн кешбек 25%
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            
            <Box className={classes.boxn3}> 
                <Typography component='h5' variant='h5' gutterBottom>
                    Подарунки на будь-який смак
                </Typography>
                <Typography  variant='body2'>
                    Кажуть, що краще один раз спробувати, ніж сто разів почути.<br /><br />
                    А ми впевнені, що кожен, хто хоча б раз спробує якість нашої <br />
                    продукції стане нашим прихильником назавжди.<br /><br />
                    Саме тому ми вирішили надати можливість<br />
                    майже безкоштовно отримати перше замовлення.
                </Typography>
            </Box>

            <Box className={classes.boxn4}> 
                <Grid container direction="row" spacing={4} justify="center" alignItems="center">
                    <Grid item xs={6} sm={3}>
                        <Box className={classes.boxn4pnl}>
                            <Typography display="block" align='center' variant='body2'>
                                Суперсіла,<br />помада,<br />кремчік,<br />туш,<br />вітамінкі
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box className={classes.boxn4pnl}>
                            <Typography display="block" align='center' variant='body2'>
                            Суперсіла,<br />помада,<br />кремчік,<br />туш,<br />вітамінкі
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box className={classes.boxn4pnl}>
                            <Typography display="block" align='center' variant='body2'>
                            Суперсіла,<br />помада,<br />кремчік,<br />туш,<br />вітамінкі
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box className={classes.boxn4pnl}>
                            <Typography display="block" align='center' variant='body2'>
                            Суперсіла,<br />помада,<br />кремчік,<br />туш,<br />вітамінкі
                            </Typography>
                        </Box>
                    </Grid>                                        
                </Grid>
            </Box>

            <Box className={classes.boxn6}> 
            </Box>

            <Box className={classes.boxn7}> 
            </Box>

            <Box className={classes.boxn8}> 
            </Box>
            
            <Box className={classes.boxn9}> 
            </Box>
        </div>
    )}
  </AppConsumer>
  )
}

