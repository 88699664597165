import React from 'react';
import PropTypes from "prop-types";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {lightBlue} from "@material-ui/core/colors";
import {Badge, Avatar, IconButton} from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        btn: {
            padding: 2,
            marginLeft: 2,
            marginRight: 2, 
            border: "2px solid", 
            borderColor: lightBlue[800]
        },
        avatar: {                      
            width: theme.spacing(4),
            height: theme.spacing(4),
            
        },
        active: {
            backgroundColor: theme.palette.primary.main
        }
    }));

export const UserAvatar = (props) => {
    const {refBtn, adviser, onClick } = props;
    const classes = useStyles();
    
  return (
    <Badge 
        badgeContent={adviser ? <small style={{marginLeft: "-8px", marginTop: "1px", fontSize: ".8em"}}>{adviser.Country}</small> : null}
        overlap="rectangular"
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <IconButton ref={refBtn} className={classes.btn}  size="small" onClick={onClick}>
            {adviser && <>        
                {adviser.Avatar !== "" ?
                    <Avatar salt={adviser.Name} className={clsx(classes.avatar, classes.active)} src={`${host}${adviser.Avatar}`}></Avatar> :
                    <Avatar  salt={adviser.Name} className={clsx(classes.avatar, classes.active)}>
                        <PersonOutlineOutlinedIcon />
                    </Avatar>
                }
            </>}
            { !adviser && 
                <Avatar salt='user' className={classes.avatar}>
                    <PersonOutlineOutlinedIcon />
                </Avatar>
            }
        </IconButton>
    </Badge>
  )
}

UserAvatar.propTypes = {
    // refBtn: PropTypes.ref(),
    refBtn: PropTypes.oneOfType([
        // Either a function
        PropTypes.func, 
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    adviser: PropTypes.object,
    onClick: PropTypes.func,    
};