import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";

import Paper from "@material-ui/core/Paper";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import moment from "moment";
import "moment/locale/uk";
import {Tooltip, IconButton, TextField, FormGroup, FormLabel, Typography } from '@material-ui/core';
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

moment.locale("uk");


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {    
            marginTop: 1,
            padding: theme.spacing(1,1),
            flexGrow: 1,
            width: "100%",
            // background: "#f7f9fc",
        },
        page: {
            padding: theme.spacing(2,2),
            marginTop: theme.spacing(1),
        },      
        nowrap: {
            whiteSpace: "nowrap"
        }
    })
);

const UserLogins = () => {
    const now = moment();
    const classes = useStyles();
    const { t } = useTranslation();
    const auth = useContext(AppContext);
    const [token, setToken] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [format, setFormat] = useState('csv');

    const [from, setFrom]= useState(now);
    const [to, setTo]= useState(now);
    const [userName, setUserName]= useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [logs, setLogs] = useState({"Data": [], "Page": 1, "PageSize": 10, "TotalCount": 0 });

    const columnDate = (val) => {
        return (
            <div className={classes.nowrap}>{moment(val).format('DD-MM-YY')}</div>            
        );
    }

    const columnTime = (val) => {
        return (                
            <div className={classes.nowrap}>{moment(val).format('HH:mm:ss')}</div>
        );
    }

    const displayDataRange = (filterList, onChange, index, column) => {
        return (
            filterList ?
            <>
                <FormLabel>Date</FormLabel>
                <FormGroup row>
                    <TextField
                    id="startDate"
                    label="From date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={event => {
                        filterList[index][0] = event.target.value;
                        setFrom(moment(event.target.value));
                        // console.log('startDate', event.target.value, filterList[index], index, column);
                        
                        onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                    />
                    <TextField
                    id="endDate"
                    label="To date"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={event => {
                        filterList[index][1] = event.target.value;
                        setTo(moment(event.target.value));
                        // console.log('endDate',filterList[index], index, column);
                        
                        onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                    />
                </FormGroup>
            </>
            : null
        );
    }

    const downloadData = () => {
        const URL = `/api/v1.0/account/downloaduserlogins/${userName.length === 0 ? " ": userName}/${from.format("YYYY-MM-DD")}/${to.format("YYYY-MM-DD")}/${format}`;        
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, { method: "GET", headers: headers })
        .then(response => response.blob())
            .then(blob => {                    
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = "logins.csv";
                document.body.appendChild(a);
                a.click();    
                a.remove();       
        })
        .catch(error => console.log(error))
        .finally(() => {});
    };

    const customToolbar = () => {
        return (
            <>
                <Tooltip title={"Download all csv"}>                        
                    <IconButton onClick={downloadData} >
                        <GetAppOutlinedIcon />
                    </IconButton>
                </Tooltip>          
          </>
        );  
    };

    const columns = [
        {
            name: "Id",
            label: "#",
            options: {
                filter: false,
                sort: false
            }
        },                    
        {
            name: "CreatedOn",
            label: t("Date"),
            options: {
              filter: true,
              sort: false,
              filterType: 'custom',
              customFilterListOptions: {
                render: v => {
                    if (v[0] && v[1]) {
                    return `From date: ${v[0]} <-> To date: ${v[1]}`;
                    } else if (v[0]) {
                    return `From date: ${v[0]}`;
                    } else if (v[1]) {
                    return `To date: ${v[1]}`;
                    }
                    return false;
                },
                update: (filterList, filterPos, index) => {
                    // console.log('customFilterListOnDelete: ', filterList, filterPos, index);
      
                    if (filterPos === 0) {
                      filterList[index].splice(filterPos, 1, '');
                    } else if (filterPos === 1) {
                      filterList[index].splice(filterPos, 1);
                    } else if (filterPos === -1) {
                        setFrom(moment());
                        setTo(moment());

                      filterList[index] = [];
                    }
      
                    return filterList;
                  },
              },
              filterOptions: {
                names: [],
                logic(date, filters) {

                  var check = new Date(date);
                  var from = new Date(filters[0]);
                  var to = new Date(filters[1]);
                  from.setDate(from.getDate() + 1);
                  to.setDate(to.getDate() + 1);
                  from = new Date(from).setHours(0,0,0,0);
                  to = new Date(to).setHours(23,59,59,59);
        
                  if(filters[0] && filters[1] && check >= to && check <= from) {
                    return true;
                  } else if (filters[0] && check >= to) {
                    return true;
                  } else if (filters[1] && check <= from) {
                    return true;
                  }
                  return false;
                },
                display: (filterList, onChange, index, column) => (
                    
                    displayDataRange(filterList, onChange, index, column)
                ),
              },

              customBodyRender: (value) => {
                return columnDate(value)
              }
            }
        },
        {
            name: "CreatedOn",
            label: t("Time"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return columnTime(value)
                  }
            }
        },                    
        {
            name: "UserName",
            label: "Reg. Num",
            options: {
                filter: true,
                sort: false                        
            }
        },
        {
            name: "IpAddress",
            label: "IpAddress",
            options: {
                filter: true,
                sort: false                      
            }
        },
        {
            name: "AppssId",
            label: "AppId",
            options: {
                filter: true, 
                sort: false                       
            }
        },
        {
            name: "UserAgent",
            label: "UserAgent",
            options: {
                filter: false,  
                sort: false                      
            }
        },
    ];

    const options = {
        filter: true,
        filterType: "multiselect",
        responsive: "standard",
        selectableRows: "none",
        serverSide: true,
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        count: logs.TotalCount,
        // eslint-disable-next-line no-unused-vars
        setFilterChipProps: (colIndex, colName, data) => {
            //console.log(colIndex, colName, data);
            return {
              color: 'primary',
              variant: 'outlined',
              className: 'testClass123',
            };
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case "changePage":
                    setPage(tableState.page);
                  // this.changePage(tableState.page, tableState.searchText, tableState.sortOrder);
                  break;
                case "sort":
                  // this.sort(tableState.page, tableState.sortOrder);
                  break;
                case "search":
                    setUserName(tableState.searchText ? tableState.searchText: '');
                    // this.changePage(0, tableState.searchText, tableState.sortOrder);
                    break;
                case "changeRowsPerPage":
                    setPageSize(tableState.rowsPerPage);
                    // this.changeRowsPerPage(tableState.rowsPerPage);
                    break;
                default:
                    // console.log("action not handled.");
                    break;                      
              }
        },
        
        customToolbar : () => {
            return customToolbar()
        }
    };


    const fetchData = useCallback( async () => {
        if(!token ) return;
        
        const URL = `/api/v1.0/account/getuserslogins/${userName.length === 0 ? " ": userName}/${from.format("YYYY-MM-DD")}/${to.format("YYYY-MM-DD")}/${page}/${pageSize}`;
        console.log(URL);
        // console.log('fetchData:', token);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        fetch(URL, { method: "GET", headers: headers })
            .then(response => response.json())
                .then(data => {              
                    setLogs(data);                    
            })
            .finally(() => {
                
            });
    }, [token, userName, from, to, page, pageSize]);

    useEffect(() => {
        function loadData() {
            if(!token ) return;
            // console.log('userName: ', userName, userName.length);

            if( !( userName.length === 0 || userName.length > 2))
                return;

            const URL = `/api/v1.0/account/getuserslogins/${userName.length === 0 ? " ": userName}/${from.format("YYYY-MM-DD")}/${to.format("YYYY-MM-DD")}/${page}/${pageSize}`;
            // console.log('AFTER URL',URL);

            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Accept", "application/json");
            headers.append("Authorization", token);
            fetch(URL, { method: "GET", headers: headers })      
                .then(response => response.json())
                    .then(data => {                        
                        setLogs(data);                    
                })
                .finally(() => {
                    
                });
        }
        loadData();
        
    }, [token, userName, from, to, page, pageSize]);

    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    token =  `Bearer ${user.access_token}`;
                }
                setToken(token); 
            });
        }
        fetchUser();
        fetchData();
    }, [auth, fetchData]);

    return (
        <AppConsumer>
            {() => (
                <div className={classes.root}>
                    <Paper className={classes.page} elevation={2} >
                        <Typography variant="caption" display="block"  align="right">{from.format("DD-MM-YY")} - {to.format("DD-MM-YY")} </Typography>
                        <MUIDataTable 
                            responsive="standard"
                            title={"Users login"}
                            data={ logs.Data }                            
                            columns={columns} 
                            options={options} 
                            
                    />
                    </Paper>
                </div>
            )}
        </AppConsumer>
    );
}

export default UserLogins