import React, {useState, useEffect, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, alpha } from "@material-ui/core/styles";
import { AppContext } from "../../providers/appProvider";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from 'react-redux';
import { actionOrderCreators } from "../../store/Order";
import { useWidth} from '../../helpers/utils';

import PdfViewer from '../Viewer/PdfViewer';
import { Fab, Grid,  Popover, Paper, IconButton, Box } from "@material-ui/core";
import { AddShoppingCart } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
    createStyles({        
        root: {
            flexGrow: 1,
            position: 'relative'
        },                        
        showItems: {            
            position: "absolute",
            top:  46, 
            right: 7,
            zIndex: 20
        },
        popover: {
            marginTop: theme.spacing(1/4)
        },
        paper: {
            padding: theme.spacing(1/2,1), 
            marginBottom: theme.spacing(1),            
        }
    })
);

const InteractiveCatalog = (props) => {    
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);    
    const url = query.get('url');
    const p = query.get('p');
    const app = useContext(AppContext);
    const width = useWidth();
    // const { lang, country } = app;  
    const dispatch = useDispatch();
    
    const [token, setToken] = useState();
    const [items, setItems] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleShowItems = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleCloseItems = () => {
        setAnchorEl(null);
    };

    const handleAdd = (idx) => {
        if(items.length === 0) return;
        const pos =  items[idx];
        dispatch(actionOrderCreators.addToOrder([ { pos: pos, qty: 1, orderrowtype: 0}] ));
    };

    // Get Catalog items by page
    const loadItems = useCallback((pages) => {
        if (!token) return;
        
        const url = `api/v1/catalogitem/catalogitemsbypage/${pages.join(',')}`;         
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    setItems(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });
        
    }, [token]);

    const changePage = (pages) => {           
        loadItems(pages)        
    }

    // Get token
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }
                setToken(token);
            });
        }
        fetchUser();

    }, [app]);

    const open = Boolean(anchorEl);
    const id = open ? 'show-items' : undefined;

    return (
        <div className={classes.root}>
        
        {items.length > 0 && 
        <>
            <div className={classes.showItems}>
                <Fab 
                    size={width === 'xs'? 'small': 'medium'}
                    anchorel={anchorEl}
                    color='secondary'
                    aria-describedby={id}
                    aria-label="catalog ites"
                    onClick={handleShowItems}                    
                >                    
                    <AddShoppingCart />                    
                </Fab>
            </div>

            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseItems}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box pt={1} pl={1} pr={1} pb={0}>
                {items.map((item, idx) => 
                <Paper key={item.Code} elevation={4} className={classes.paper} >
                    <Grid  container direction="row" spacing={2} justifyContent="flex-start" alignItems="center" >
                        <Grid xs={10} item>
                            <b>{item.Code}</b> {item.LocalName}
                        </Grid>
                        <Grid xs={2} item>
                            <IconButton 
                                color='primary'
                                size='small' 
                                onClick={()=> handleAdd(idx)}
                            >
                                <AddShoppingCart />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
                )}
                </Box>
            </Popover>
        </>} 

                
        <PdfViewer url={url} page={p ? parseInt(p) : 1} callbackPageChange={changePage} />
        
        </div>
  )
}

InteractiveCatalog.propTypes = {}

// export default InteractiveCatalog;
export default connect(
    state => state.orderRequest,
    dispatch => bindActionCreators(actionOrderCreators, dispatch)
)(InteractiveCatalog);
