import React, {useContext, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import {AppContext,  AppConsumer } from "../../providers/appProvider";
import {TextField, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles,  } from "@material-ui/core/styles";
import { countries, countryToFlag } from "../../store/countries";
import { useTranslation } from "react-i18next";


const I18N_LANGUAGE = "i18nextLng"; 

const useStyles = makeStyles((theme) =>
    createStyles({      
        root: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        option: {
            fontSize: 15,
            '& > span': {
              marginRight: 10,
              fontSize: 18,
            },
          },
        bg: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundImage: 'url(/assets/files/images/global.png)'
        }              
  })
);

const Country = forwardRef( function Country (props, ref)  {
    const { onSave } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const app = useContext(AppContext);
    const [country, setCountry] = useState(app.country || "UA");
    const [lang, setLang] = useState(app.lang);
    const [languages, setLanguages]= useState([]);

    const findCountry = (iso) => {
        const idx = countries.findIndex(c => c.code === iso);
        if(idx!== -1){
          return countries[idx];
        }else{
          const ua = "UA";
          return countries[countries.findIndex(c => c.code === ua)];
        }
    };

    const findLang = (val) => {
        if(languages.length === 0) return null;
        
        const idx = languages.findIndex(c => c.EnglishName === val || c.TwoLetterISOLanguageName === val);
        if(idx!== -1){
          return languages[idx];
        }else{
          const uk = "uk";
          return languages[languages.findIndex(c => c.EnglishName === uk || c.TwoLetterISOLanguageName === val)];
        }
    };

    const handelChangeCountry = (e, val) => {
        // console.log("handelChangeRecipientCountry", val);
        if(val){            
            setCountry(val.code);
        }
    };

    const handelChangeLang = (e, val) => {
        // console.log("handelChangeLang", val);
        if(val){            
            setLang(val.TwoLetterISOLanguageName);                        
        }
    };

    useEffect(() => {
        function fetchLangs() {
            const URL =  "/api/v1.0/languege";        
            fetch(URL, { method: "GET", })
                .then(response => response.json())
                    .then(data => {                         
                        setLanguages(data);                                
                    })
                .catch(error => {                    
                    console.log(error);
                });
        }
        fetchLangs();                
    }, []); 

    useEffect(() => {
        if(!app) return;

        setCountry(app.country || "UA");
        setLang(app.lang);
    }, [app]);


    useImperativeHandle(ref, () => ({
        Save() {
            const culture = findLang(lang);
            
            if((country && country !== '') && (lang && lang !== '')) {            
                const URL = `/api/v1/Languege/${culture.Name}`;                                            
                fetch(URL)
                .then(() => {                
                    app.changeCulture(country, culture.TwoLetterISOLanguageName);                                 
                })        
                .catch(error => console.log(error))
                .finally(() => {          
                });
            }            
        },        
    }));

    return (
        <AppConsumer>
            {() => (
                <div>
                    <div className={classes.bg} style={{height: 100, marginBottom: 16}}></div>
                    <Box mb={3}>
                        <Autocomplete
                            value={findCountry(country)}
                            name='country'
                            id='country'
                            style={{ width: '100%' }}
                            options={countries}
                            classes={{ option: classes.option, }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                handelChangeCountry(event, newValue);
                            }}
                            renderOption={(option) => (
                            <>
                                {/* <span>{countryToFlag(option.code)}</span>   */}
                                {option.name} ({option.code})                                
                            </>
                            )}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Select your country")}
                                variant="outlined"
                                inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        />
                    </Box>
                    <Box>
                        <Autocomplete
                            value={findLang(lang)}
                            name='lang'
                            id='lang'
                            style={{ width: '100%' }}
                            options={languages}
                            classes={{ option: classes.option, }}
                            autoHighlight
                            getOptionLabel={(option) => option.EnglishName}
                            onChange={(event, newValue) => {
                                handelChangeLang(event, newValue);
                            }}
                            renderOption={(option) => (
                            <>                                
                              {option.EnglishName} ({option.TwoLetterISOLanguageName})
                            </>
                            )}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Select your language")}
                                variant="outlined"
                                inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        />
                    

                    </Box>

                </div>
            )}
        </AppConsumer>
    )
});

Country.propTypes = {
    // onClose: PropTypes.func,
    // onSave: PropTypes.func,    
}

export default Country;
