import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { UserAvatar } from "../UserAvatar";
import { Grid,  Box, Chip, Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
        },
        label: {
            padding: "1px 4px",
            marginRight: "4px"
        },
        regnum: {
          color: theme.palette.primary.dark
        },
        userName: {
            textTransform: "capitalize"
        },
        small: {
            backgroundColor: theme.palette.primary.dark,
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginLeft: theme.spacing(1),
            fontSize: "0.4rem !important"
          },
          balance: {
            // minWidth: 68
          },      
    })
);


const AdviserBlock = (props) => {
    const {adviser, balances} = props;
    const classes = useStyles();

  return (
    <div className={classes.root}>
        {adviser && 
        <Grid container direction="row" spacing={1} >
            <Grid item xs={3} sm={2} style={{textAlign: 'center'}}>
              <UserAvatar adviser={adviser} />                
            </Grid>
            <Grid item xs={9} sm={10}>
                <div className={clsx(classes.label, classes.regnum)}>{adviser.RegistrationCode}</div>
                <div className={clsx(classes.label, classes.userName)}>
                  {adviser.FirstName.toLowerCase()} {adviser.LastName.toLowerCase()} 
                </div>
            </Grid>
        </Grid>
        }

        {balances && 
        <Box mt={1} pl={1/2}>
            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                {balances.map((balance, idx) => (
                    balance.Amount !== 0.0 || balance.AmountOB !== 0 ?  
                    <Grid item key={idx} >
                      
                      {balance.Amount !== 0 && 
                      <Chip
                        className={classes.balance}
                        label={Math.floor(balance.Amount)} 
                        size="small" 
                        variant="outlined"
                        color= "primary"
                        avatar={<Avatar className={classes.small}>{balance.CurrencyName}</Avatar>}
                        style={{marginRight: balance.AmountOB !== 0 ? 4 : 0 }}
                      />
                      }

                    {balance.AmountOB !== 0 && 
                      <Chip
                        className={classes.balance}
                        label={Math.floor(balance.AmountOB)} 
                        size="small" 
                        variant="outlined"
                        color= "secondary"
                        avatar={<Avatar className={classes.small}>{balance.CurrencyName}</Avatar>}                        
                      />
                      }

                      </Grid>: null                    
                    ))
                  }                
            </Grid>
        </Box>
        }
    </div>
  )
}

AdviserBlock.propTypes = {
    adviser: PropTypes.object,
    balances: PropTypes.array,
}

export default AdviserBlock