import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from "react-router-dom";
import { Fab } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';


function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {    
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({    
    wrapper: {      
      position: 'relative',
    },
    
    btnBack: {
      position: "absolute",
      top: theme.spacing(7),
      left: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {            
        top: theme.spacing(0),
        left: theme.spacing(0),
      },      
      zIndex: 1
    },    
  })
);


const GoBackRecharge = () => {
    const classes = useStyles();
    const width = useWidth();
  return (
    <div className={classes.wrapper}>
        <Fab 
            className={classes.btnBack}                   
            color="primary" 
            component={Link} 
            to={"/recharge"}
            size={width === "xs" ? "small": "large"}
            >
            <EditIcon />
        </Fab>              
    </div> 
  )
}

export default GoBackRecharge