
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
// import { useTranslation } from "react-i18next";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import BSlide from './BSlide';

// eslint-disable-next-line no-unused-vars
import {Box, InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      flexGrow: 1,      
    },    
    button: {
      // marginRight: theme.spacing(1),
      // color: "#fff",
    },
    stepper: {
      padding: theme.spacing(1/2, 1)
      // position: "absolute",
      // backgroundColor: "transparent",
      
      // left: 0,
      // right: 0,
      // bottom: 0,
    }
}));

const BigCarousel = (props) => {
  const {id, className, onClick, slides} = props;

  // const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if(slides.length > activeStep + 1){
        setActiveStep(activeStep + 1);
    }
};

const handleBack = () => {
    if(0 <= activeStep - 1){
        setActiveStep(activeStep - 1);
    }
};

const handleStepChange = (step) => {
    setActiveStep(step);
};
  // const [ component, setComponent] = useState(node);
  
  // useEffect( () => {
  //   if(node)
  //     setComponent(node);
  // }, [node]);

  return (
    <div id={id} className={clsx("jr-carousel", className, classes.root)} onClick={onClick}> 
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={5000000}
          style={{position: "relative"}}
        >
          {slides.map((slide) => <React.Fragment key={slide.Id} ><BSlide slide={slide} /></React.Fragment>  )}
        </AutoPlaySwipeableViews>

        <MobileStepper
          className={classes.stepper}
          steps={slides.length}
          position="static"
          activeStep={ activeStep }
          nextButton={
          <IconButton className={classes.button} onClick={handleNext} disabled={activeStep === slides.length - 1}>
              {/* {t("buttons.Next")} */}
               <KeyboardArrowRight />
            
          </IconButton>
          }
          backButton={
          <IconButton className={classes.button} onClick={handleBack} disabled={slides.length === 0}>            
            {/* {t("buttons.Back")}  */}
            <KeyboardArrowLeft />
          </IconButton>
          }
      />

        
        {slides.length === 0 && 
          <Typography variant="h5" style={{padding: 16, display: 'block', width: '100%', textAlign: 'center'}}>Carousel</Typography>
        }
    </div>
  )
}

BigCarousel.propTypes = {
  className: PropTypes.string,
  interval: PropTypes.number,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,      
    })
  ).isRequired,
  onClick: () => null
}

export default BigCarousel