import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import HtmlBlock from "./HtmlBlock";
import TitleBlock from './TitleBlock';
import BonuseNewcomer from './Widgets/BonuseNewcomer';
import PdfBlock from "./PdfBlock";
import ImageBlock from './ImageBlock';
import VideoBlock from './VideoBlock'
import VideoLinkBlock from './VideoLinkBlock';
import ColumnsBlock from "./ColumnsBlock";
import ContainerBlockView from "./ContainerBlockView";
import ColumnsBlockView from "./ColumnsBlockView";

import { BlockType } from "../../../definitions/pageblocktype";


const useStyles = makeStyles((theme) =>
    createStyles({        
        paper: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),            
        },
        
        youtubesection: {
            // padding: theme.spacing(0, 60),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(0, 1),
            },
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(0,1),
            },
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(0,2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(0,40),
            },
        },
        sectionShadow: {
            margin: theme.spacing(1),
            padding: theme.spacing(2),
            borderRadius: 12,            
            // boxShadow: theme.palette.ambilight.boxshadow
        }
    })
);



const PageBlocks = props => {
    const { content } = props;
    const classes = useStyles();
     
  return (    
        content && 
        <Paper className={classes.paper} elevation={0} square>
            {content.Blocks.map(( block, index ) => (
                <Box key={index}>
                    
                         { [
                            BlockType.HTMLBLOCK,
                            BlockType.BIGSLIDER,
                            BlockType.MIDDLESLIDER,
                            BlockType.SMALLSLIDER,
                            BlockType.LOGOIMAGE,
                            BlockType.IMAGE,
                            BlockType.WGBONUSENEWCOMER,
                            BlockType.PDFVIEWER,
                            BlockType.VIDEO,
                            BlockType.VIDEOLINK,
                            BlockType.CONTAINER,
                            BlockType.TITLE,
                            BlockType.BUTTON,
                        ].includes(block.PageBlockType) && 
                            <ContainerBlockView 
                                block={block}                                 
                            />
                        }

                        {[ BlockType.COLUMNS,
                            BlockType.TEXTTEXT,
                            BlockType.IMAGETEXT,
                            BlockType.VIDEOTEXT,
                        ].includes(block.PageBlockType) &&                                                            
                            <ColumnsBlockView block={block} />                            
                        }
                                    
                </Box>
        ))}
        </Paper>            
  )
}

PageBlocks.propTypes = {
    content: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Key: PropTypes.string.isRequired,
        Name: PropTypes.string.isRequired,        
        StartedOn: PropTypes.string.isRequired,
        FinishedOn: PropTypes.string.isRequired,                
        Blocks: PropTypes.arrayOf(
            PropTypes.shape({
                Id: PropTypes.number.isRequired,
                Key: PropTypes.string.isRequired,
                Name: PropTypes.string.isRequired,
                PageBlockType: PropTypes.number.isRequired,
                Stack: PropTypes.number.isRequired,
                Content: PropTypes.object
            })
        ).isRequired,
    }).isRequired,
};

export default PageBlocks;