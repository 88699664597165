import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import {  Link } from "react-router-dom";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Paper, AppBar, Toolbar, Grid, Tabs, Tab,
         Box, Typography, IconButton, Button, TablePagination } from "@material-ui/core";
// import moment from "moment";
import { useTranslation } from "react-i18next";

import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionOrderCreators } from "../../store/Order";

import OrderRow from "./OrderRow";
import DraftRow from "./DraftRow";

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useWidth} from '../../helpers/utils';


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(0, 1/4)
        },
        appbar: {            
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        title: {            
            fontWeight: 400,            
        },
        tabs: {
            // flexGrow: 1,
            marginRight: theme.spacing(1)
        },
        flexGrow: {
            flexGrow: 1,
        },
        tabdescr: {
            // fontSize: '.6rem'
        },
        paper: {
            width: '100%',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            padding: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        button: {
            marginRight: theme.spacing(1)
        }
    })
);


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel_${index}`}
        aria-labelledby={`tabpanel_${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            {children}
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tab-${index}`,
    };
}

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const width = useWidth();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
            size="small"
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton 
            size="small" 
            onClick={handleBackButtonClick} 
            disabled={page === 0} 
            aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            size="small"
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            size="small"
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
const rowsPerPageOptions = [10, 25, 50, 100];

const History = () => {
    const classes = useStyles();    
    const app = useContext(AppContext);
    const { t } = useTranslation();
    const width = useWidth();
    const [token, setToken] = useState();
    
    const currentOrder = useSelector((state) =>  state.orderRequest.Order );
    const dispatch = useDispatch();

    const [docsTab, setDocsTab] = useState(0);    
    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState(0);
    const [pageOrders, setPageOrders] = useState(0);
    const [rowsPerPageOrders, setRowsPerPageOrders] = useState(10);
    
    const [drafts, setDrafts] = useState([]);
    const [draftsCount, setDraftsCount] = useState(0);
    const [pageDrafts, setPageDrafts] = useState(0);
    const [rowsPerPageDrafts, setRowsPerPageDrafts] = useState(10);

    const [canceled, setСanceled] = useState([]);
    const [canceledCount, setСanceledCount] = useState(0);
    const [pageCanceled, setPageCanceled] = useState(0);
    const [rowsPerPageCanceled, setRowsPerPageCanceled] = useState(10);
    
    // Get token
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }
                setToken(token);
            });
        }
        fetchUser();

    }, [app]);


    const getCanceledOrders = useCallback(() => {
        const url = `api/v1/orders/getcanceledorders?pi=${pageOrders}&ps=${rowsPerPageOrders}`; 
        // console.log(url);             
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    // console.log('Orders', data);
                    setСanceled(data.Data);
                    setСanceledCount(data.TotalCount);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });

    }, [pageOrders, rowsPerPageOrders, token]);


    const getOrders = useCallback(() => {
        const url = `api/v1/orders/getorders?pi=${pageOrders}&ps=${rowsPerPageOrders}`; 
        // console.log(url);             
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    // console.log('Orders', data);
                    setOrders(data.Data);
                    setOrdersCount(data.TotalCount);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });

    }, [pageOrders, rowsPerPageOrders, token]);

    // Get canceled orders
    useEffect(() => {
        if (!token) return;
        getCanceledOrders();
    }, [token, getCanceledOrders]);

    // Get orders
    useEffect(() => {
        if (!token) return;
        getOrders();        
    }, [token, getOrders]);

    const getDrafts = useCallback(() => {
        const url =  `api/v1/orders/getdrafts?pi=${pageDrafts}&ps=${rowsPerPageDrafts}`; 
        // console.log(url);
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){
                    // console.log('Drafts', data);
                    setDrafts(data.list);
                    setDraftsCount(data.tt);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });
    }, [pageDrafts, rowsPerPageDrafts, token]);

    // Get drafts
    useEffect(() => {
        if (!token) return;
        getDrafts();
    }, [getDrafts, token]);

    const reloadDrafts = () => {
        setDocsTab(1);
        getDrafts();
    };

    const handleChangeTab = (event, newValue) => {
        setDocsTab(newValue);
    };

    const handleChangePageOrders = (event, newPage) => {
        setPageOrders(newPage);
    };
    
    const handleChangeRowsPerPageOrders = (event) => {
        setRowsPerPageOrders(parseInt(event.target.value, 10));
        setPageOrders(0);
    };

    const handleChangePageDrafts = (event, newPage) => {
        setPageDrafts(newPage);
    };
    
    const handleChangeRowsPerPageDrafts = (event) => {
        setRowsPerPageDrafts(parseInt(event.target.value, 10));
        setPageDrafts(0);
    };

    const handleChangePageCanceled = (event, newPage) => {
        setPageCanceled(newPage);
    };
    
    const handleChangeRowsPerPageCanceled = (event) => {
        setRowsPerPageCanceled(parseInt(event.target.value, 10));
        setPageCanceled(0);
    };

    const callbackUpdRow = (canceled) => {
        const idx = orders.findIndex(o => o.Id === canceled.Id);
        if(idx > -1){
            orders[idx].StateCode = canceled.StateCode;
            orders[idx].StateName = canceled.StateName;
            setOrders([...orders]);
        }        
    };

    const callbackUpdRowDraft = (deleted) => {
        const idx = drafts.findIndex(o => o.Id === deleted.Id);
        if(idx > -1){            
            getDrafts();
            if(currentOrder && currentOrder.Id === deleted.Id){
                dispatch(actionOrderCreators.undefinedOrder());
            }            
        }
    };

    const handlleRefresh = () => {
        switch (docsTab) {
            case 0:
                getOrders();
                break;
            case 1:
                getDrafts();
                break;
            default:
                break;
        }
    };

    // const handlleClick = () => {
    //     console.log('currentOrder', currentOrder);
    //     // dispatch(actionOrderCreators.undefinedOrder());
    // };

  return (
    <AppConsumer>
        {() => (
            <Paper className={clsx( classes.root, classes.paper)}>
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                    {width === "xs" &&
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} >
                            <Grid item xs={6}>
                                <Typography className={classes.title}  variant="h6" color="inherit" style={{paddingLeft: 8, paddingTop: 4}}>
                                    {t("orders.JournalOrders")  }
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                            <IconButton
                                className={classes.button}                            
                                color="primary"
                                title={t("New order")}
                                component={Link}
                                aria-label="new-order"
                                to='/order/new'
                            > 
                                <LibraryAddOutlinedIcon  />
                            </IconButton>

                            <IconButton
                                className={ width === "xs" ? null : classes.button}
                                title={t("buttons.Refresh")}
                                aria-label="refresh-orders"
                                onClick={handlleRefresh}
                            >
                                <RefreshIcon />
                            </IconButton>
                            </Grid>
                        </Grid>                            
                        }
                    <Toolbar variant="dense" style={{paddingLeft: 8, paddingRight: 8}}>
                        <div className={classes.tabs}>
                            <Tabs
                                // variant={width === "xs" ? "fullWidth" : "scrollable" } 
                                // centered={width === "xs" ? true : undefined } 
                                value={docsTab}
                                onChange={handleChangeTab}
                                aria-label="List_docs"
                            >                            
                                <Tab 
                                    title={t("orders.tabHistory")}
                                    wrapped 
                                    icon={<DoneAllOutlinedIcon />}
                                    label={t("orders.tabHistory")}                                                                            
                                    {...a11yProps(0)} 
                                /> 
                                <Tab
                                    title={t("orders.tabDrafts")}
                                    wrapped 
                                    icon={<EditOutlinedIcon />}
                                    label={t("orders.tabDrafts")}
                                    {...a11yProps(1)} 
                                />
                                <Tab
                                    title={t("orders.tabCanceled")}
                                    wrapped 
                                    icon={<DeleteForeverOutlinedIcon />}
                                    label={t("orders.tabCanceled")}
                                    {...a11yProps(2)} 
                                />
                            </Tabs>                                                        
                        </div>
                                                                                                                        
                        {width !== "xs" &&
                            <>
                            <div className={classes.flexGrow}></div>
                            <IconButton
                                className={classes.button}                            
                                color="primary"
                                title={t("New order")}
                                component={Link}
                                aria-label="new-order"
                                to='/order/new'
                            > 
                                <LibraryAddOutlinedIcon  />
                            </IconButton>

                            <IconButton
                                className={ width === "xs" ? null : classes.button}
                                title={t("buttons.Refresh")}
                                aria-label="refresh-orders"
                                onClick={handlleRefresh}
                            >
                                <RefreshIcon />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" color="inherit">
                            {t("orders.JournalOrders")  }
                            </Typography>
                            </>
                        }
                    </Toolbar>                    
                </AppBar>
                
                {/* Orders */}
                <TabPanel value={docsTab} index={0}> 
                    {orders.map((row) => (
                        <OrderRow 
                            key={row.Id} 
                            row={row} 
                            token={token} 
                            onUpdate={callbackUpdRow} 
                            onReload={getOrders}
                            onReloadDrafts={reloadDrafts} /> 
                    ))}
                    
                    <Box>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={rowsPerPageOptions}
                            // colSpan={3}
                            count={ordersCount}
                            rowsPerPage={rowsPerPageOrders}
                            page={pageOrders}
                            SelectProps={{                                
                                native: true,
                            }}
                            labelRowsPerPage={""}
                            onPageChange={handleChangePageOrders}
                            onRowsPerPageChange={handleChangeRowsPerPageOrders}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Box>
                </TabPanel>
                
                {/* Drafts */}
                <TabPanel value={docsTab} index={1} >
                    {drafts.map((row) => (
                        <DraftRow 
                            key={row.Id} 
                            token={token} 
                            row={row} 
                            onUpdate={callbackUpdRowDraft} /> 
                    ))}

                    <Box>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={rowsPerPageOptions}
                            colSpan={3}
                            count={draftsCount}
                            rowsPerPage={rowsPerPageDrafts}
                            page={pageDrafts}
                            SelectProps={{                                
                                native: true,
                            }}
                            labelRowsPerPage={""}
                            onPageChange={handleChangePageDrafts}
                            onRowsPerPageChange={handleChangeRowsPerPageDrafts}
                            ActionsComponent={TablePaginationActions}
                        />

                    </Box>
                </TabPanel>
                
                
                {/* Canceled */}
                <TabPanel value={docsTab} index={2} >

                    {canceled.map((row) => (
                            <OrderRow 
                                key={row.Id} 
                                row={row} 
                                token={token} 
                                onUpdate={callbackUpdRow} 
                                onReload={getOrders}
                                onReloadDrafts={reloadDrafts} /> 
                        ))}
                        
                        <Box>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={canceledCount}
                                rowsPerPage={rowsPerPageCanceled}
                                page={pageOrders}
                                SelectProps={{                                
                                    native: true,
                                }}
                                labelRowsPerPage={""}
                                onPageChange={handleChangePageCanceled}
                                onRowsPerPageChange={handleChangeRowsPerPageCanceled}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Box>

                </TabPanel>

            </Paper>
        )}
    </AppConsumer>    
  )
};

export default connect(
    state => state.orderRequest,
    dispatch => bindActionCreators(actionOrderCreators, dispatch)
)(History);