import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            display: 'block',
            textAlign: 'center'
        },
        logo: {            
            width: "280px",
            maxWidth: "100%",
            height: "auto",
        }

    })
);

const LogoImage = (props) => {
    const { content } = props;
    const classes = useStyles();
  return (
    <div className={classes.root}>
        <img className={classes.logo} alt="Jerelia" src={`${host}${content.TopBanner}`} />
    </div>
  )
}

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

LogoImage.propTypes = {
    content: PropTypes.shape({
        Id: PropTypes.number.isRequired,                                
        Key: PropTypes.string.isRequired,
        TopBanner: PropTypes.string.isRequired,                
        TopMobileBanner: PropTypes.string.isRequired,        
    }).isRequired,
    blockKey: PropTypes.string.isRequired,
}

export default LogoImage;