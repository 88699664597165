import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef, useContext, useCallback } from "react";
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Dialog, Box, Button, Grid,
         TextField, FormControlLabel, Checkbox, IconButton, InputAdornment, FormControl, Paper     } from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "../../../CustomDialog";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ClearIcon from '@material-ui/icons/Clear';

import WrapTextOutlinedIcon from '@material-ui/icons/WrapTextOutlined';

import { useTranslation } from "react-i18next";
import Files from "../../../Files/Files";
import {countryToFlag, findCountyByToLetter} from '../../../../store/countries';
import {BlockType} from "../../../../definitions/pageblocktype";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },        
        button: {
            marginRight: theme.spacing(1/4)
        },
        role: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
                
    })
);

const ContentFiles = forwardRef( function ContentFiles (props, ref)  {
    const { content, pageBlockType, langs, roles, token, onSave, keyDisabled } = props;
    
    const classes = useStyles();
    const { t } = useTranslation();
    const [openFileDlg, setOpenFileDlg] = useState(false);
    const [selectedField, setSelectedField] = useState();
    const [localValues, setLocalValues] = useState(content.LocalValues ? content.LocalValues: [] );
    const [shortVideo, setShortVideo] = useState(content.Props.ShortVideo ? content.Props.ShortVideo === 'true' : false )
    // console.log(content);

    useImperativeHandle(ref, () => ({
        updateData() {                        
            Save() 
        },        
    }));

    const Save = () => {        
            const _content = {...content};
            _content.LocalValues = localValues;
            if(pageBlockType === BlockType.VIDEOLINK){
                _content.Props.ShortVideo = `${shortVideo}`;
            }

            const URL =  "/api/v1/content";
            fetch(URL, {
                method: _content.Id === 0 ? "POST" : "PUT",
                credentials: "include",
                headers: {
                    authorization: token,              
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",                
                },
                body: JSON.stringify(_content)
            })
            .then((response) => {
              if(response.status === 200){
                response.json().then((data) => {                    
                    console.log('After save doc to DB', data);                    
                    if(onSave){
                        onSave(data);
                    }
                })
              } else {
                response.text().then((text) => {
                    console.log(text);
                })
              }
          });

        
    };

    const handleDlgFile = (field) => {
        setSelectedField(field);
        setOpenFileDlg(true);
    };

    const handleFillAllValues = (lang) => {        
        const _localValues = [...localValues];
        const local = _localValues.find(l => l.TwoLetterISOLanguageName === lang);
        
        if(local && local.Value !== ''){
            console.log(local); 
            for (let i = 0; i < _localValues.length; i++) {
                const el = _localValues[i];
                if(el.TwoLetterISOLanguageName === lang)
                    continue;
                el.Value = local.Value;                
            }            
            setLocalValues(_localValues);  
        }
    }

    const handleFileDlgClose = () => {
        setOpenFileDlg(false);
    };

    const onSelectFile = (path) => {        
        setOpenFileDlg(false);
        if(selectedField){ 
            setLocalValue(selectedField, path);
        }            
    };

    const handleChangeFileName = (e) => {
        setLocalValue(e.target.name, e.target.value);        
    }

    const setLocalValue = (country, fileName) => {
        const _localValues = [...localValues];
        const local = _localValues.find(l => l.TwoLetterISOLanguageName === country);
        if(local){
            local.Value =fileName;
        }
        setLocalValues(_localValues);  
    };

    const handleChangeShortVideo = (e) => {
        setShortVideo(e.target.checked);
    }

  return (
    <div className={classes.root}>
        
        {content && 
            <Paper elevation={0}>
                {pageBlockType === BlockType.VIDEOLINK &&
                    <Box mb={3}>
                        
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={shortVideo}
                                onChange={handleChangeShortVideo}
                                name="ShortVideo"
                                color="primary"
                            />
                            }
                            label="Use short video "
                        />
                    </Box>
                }
            <Grid container direction="row" spacing={2}>
                {                    
                    localValues.map((local) =>                         
                        <Grid key={local.TwoLetterISOLanguageName}  item xs={12} sm={6} md={4} > 
                            <TextField
                                name={local.TwoLetterISOLanguageName}
                                label={`${countryToFlag(local.TwoLetterISOLanguageName)} ${content.Name} for ${findCountyByToLetter(local.TwoLetterISOLanguageName)}`}                                
                                fullWidth
                                margin='dense'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start"> 
                                        <IconButton title="Fill all the fields with the same values" size='small' className={classes.button} onClick={() => handleFillAllValues(local.TwoLetterISOLanguageName)}>
                                            <WrapTextOutlinedIcon />                                            
                                        </IconButton>
                                        
                                        {(pageBlockType === BlockType.IMAGE || pageBlockType === BlockType.VIDEO || pageBlockType === BlockType.PDFVIEWER) &&
                                        <>                                                                                
                                        <IconButton title="Select file" size='small' className={classes.button} onClick={() => handleDlgFile(local.TwoLetterISOLanguageName)}>
                                            <MoreHorizIcon />                                            
                                        </IconButton>
                                        <IconButton title="Clear the field" size='small' onClick={() => setLocalValue(local.TwoLetterISOLanguageName, '')}>
                                            <ClearIcon />                                            
                                        </IconButton>
                                        </>}
                                    </InputAdornment>,
                                }}
                                variant="outlined"
                                value={local.Value}
                                onChange={handleChangeFileName}
                            />
                        </Grid>                        
                    ) 
                }
            </Grid>
            </Paper>
        }

        <Dialog 
            onClose={handleFileDlgClose} 
            aria-labelledby="dialog-title-files" 
            open={openFileDlg} 
            fullWidth={true} 
            maxWidth="lg" 
        >
            <DialogTitle id="dialog-title-files" onClose={handleFileDlgClose} >Select file</DialogTitle>
            <DialogContent  style={{height: 600}}>
                <Files onSelect={onSelectFile} />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' autoFocus onClick={handleFileDlgClose} color="default">{t("buttons.Close")}</Button>
            </DialogActions>
        </Dialog>
        
    </div>
  )
});

ContentFiles.propTypes = {
    content: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    pageBlockType: PropTypes.number.isRequired,
    keyDisabled: PropTypes.bool.isRequired,
    onSave: PropTypes.func,    
    langs: PropTypes.arrayOf(
        PropTypes.shape({
            EnglishName: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            NativeName: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,
            Active: PropTypes.bool.isRequired,
        })
    ).isRequired,
}



export default ContentFiles