import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse, Typography, Paper, IconButton, AppBar, Toolbar } from "@material-ui/core";
import arrayMove from "array-move";
import { Container, Draggable } from "react-smooth-dnd";
import clsx from "clsx";
import moment from "moment";
import { grey } from '@material-ui/core/colors';
import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';
import SlideProps from './SlideProps';

import DragHandleIcon from "@material-ui/icons/DragHandle";
import ImageIcon from '@material-ui/icons/Image';
import VideocamIcon from '@material-ui/icons/Videocam';
import HelpIcon from '@material-ui/icons/Help';
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(0, 1),
            borderRadius: 8,
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        listitem: {
            marginTop: theme.spacing(1),
            borderRadius: 12,
            borderTop: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: `2px solid ${theme.palette.divider}`,
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        moveicon: {
            cursor: "move"
        },
        mr: {
            marginRight: theme.spacing(1)
        },
        appbar: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbar: {
            paddingLeft: theme.spacing(1),
            paddingRight: 0,            
        },
    })
);

// const SliderEditor = (props) => {
const SliderEditor = forwardRef( function SliderEditor (props, ref)  {

    const classes = useStyles();
    const theme = useTheme();
    const { onSave } = props;
    const app = useContext(AppContext);    
    const [token, setToken] = useState();
    const now = new Date();
    const [slider, setSlider] = useState(props.slider);
    const [items, setItems] = useState(props.slider.Items);        
    const [openId, setOpenId] = useState(-1);
    const refSlide = useRef();
    
    useImperativeHandle(ref, () => ({
        updateData() {
            Save();
            //TODO: add async update doc
            // updateDoc(langTab).then(                
            //     onSave({...doc}, typeblock)
            // );
        },        
    }));

    const Save = () => {        
        slider.Items = [...items];
        console.log('Save slider', slider);

        if(refSlide && refSlide.current && refSlide.current.updateData ){
            const slide = refSlide.current.updateData();
            if(slide){        
                const _items = items.map((item, i) => {
                    if(openId === i){
                        return slide;
                    }else{
                        return item;
                    }
                });
                slider.Items = [..._items];                 
                setItems(_items);
            }
        }

        // Here save slider to DB then change state
        if(token){
            for (let i = 0; i < slider.Items.length; i++) {
                const slide = slider.Items[i];
                slide.PosStack = i;
            }
            console.log('Save slider to DB', slider);
            const URL =  "/api/v1.0/slider/save";
            fetch(URL, {
                method: 'POST',
                credentials: "include",
                headers: {
                    authorization: token,              
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",                
                },
                body: JSON.stringify(slider)
            })
            .then((response) => {
              if(response.status === 200){
                response.json().then((data) => {                    
                    console.log('After save slider', data);
                    setItems(data.Items);
                    setSlider(data);
                    if(onSave){
                        onSave(data);
                    }
                })
              } else {
                response.text().then((text) => {
                    console.log(text);
                })
              }
          });

        }
        
    };

    const onDrop = ({ removedIndex, addedIndex }) => {
        // console.log({ removedIndex, addedIndex });        
        setItems(items => arrayMove(items, removedIndex, addedIndex));
    };

    const expandPanel = useCallback((idx) => {
        setOpenId(idx);
    },[]);

    const handleExpandMore = (idx) => {
        expandPanel(idx === openId ? -1 : idx);

        if(refSlide && refSlide.current && refSlide.current.updateData && openId !== -1){            
            const slide = refSlide.current.updateData();
            if(slide){
                // console.log('Update slide', slide);
                const nestItems = items.map((item, i) => {
                    if(openId === i){
                        return slide;
                    }else{
                        return item;
                    }
                });
                setItems(nestItems);
            }
        }                        
        // setOpenId(idx === openId ? -1 : idx);
    };

    const handleDelSlideItem = (idx) => {
        setItems(items => {
            return items.filter((_, i) => i !== idx)
        });
    };

    const handleCopySlideItem = (idx) => {
        const exist = {...items[idx]};
        exist.Id = 0;
        for (let i = 0; i < exist.LocalValues.length; i++) {
            const val = exist.LocalValues[i];
            val.Id = 0;            
        }

        exist.Caption = `Copy ${exist.Caption}`;
        setItems([
            ...items,
            exist
        ]);
        setOpenId(-1);        
    };

    const handleAddSlide = () => {
        if(token){
            
            const URL =  "/api/v1.0/slider/newslide";
            fetch(URL, {
                method: 'GET',
                credentials: "include",
                headers: {
                    authorization: token,
                },
            })
            .then((response) => {
              if(response.status === 200){
                response.json().then((data) => {                    
                    console.log('Get new slide', data);
                    setItems([                        
                        ...items,
                        data,
                    ]);
                    setOpenId(-1);                    
                })
              } else {
                response.text().then((text) => {
                    console.log(text);
                })
              }
          });
        }
    };

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }                                
            });
        }
        fetchUser();

    }, [app]);
    
    return (
        <AppConsumer>
        {() => (
            <Box>
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                    <Toolbar variant="dense" className={classes.toolbar}>
                        <IconButton title="Add slide" onClick={handleAddSlide}>
                            <AddPhotoAlternateOutlinedIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            <List component="nav" > 
                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop} >
                
                {items.map(( slide, index ) => (
                    <Draggable key={index}>
                        <ListItem button onClick={() => handleExpandMore(index)} className={classes.listitem}>
                            <ListItemIcon className={classes.mr}>                                
                                { IsImage(extractFileExt(slide.FullPath)) ? <ImageIcon fontSize='small' className={classes.mr} color='primary' /> : ( IsVideo(extractFileExt(slide.FullPath)) ? <VideocamIcon fontSize='small' className={classes.mr} color='primary'/> :<HelpIcon fontSize='small' className={classes.mr} color='secondary' />) }
                                { slide.Hide ? 
                                    <VisibilityOffOutlinedIcon fontSize='small' style={{ color: moment(now).isBetween(slide.ShowFrom, slide.ShowTo) ? theme.palette.primary.main: grey[500] }} /> : 
                                    <VisibilityOutlinedIcon fontSize='small' style={{ color: moment(now).isBetween(slide.ShowFrom, slide.ShowTo) ? theme.palette.primary.main: grey[500] }} /> }
                            </ListItemIcon>
                            
                            <ListItemText primary={`${index + 1} - ${slide.Caption}`} />
                            
                            <ListItemSecondaryAction>
                                <ListItemIcon>
                                    <IconButton size='small' onClick={()=>handleExpandMore(index)}>
                                        {index === openId ? <ExpandLess /> : <ExpandMore /> }
                                    </IconButton>
                                </ListItemIcon>
                            
                                <ListItemIcon>
                                    <IconButton size='small' onClick={()=>handleCopySlideItem(index)} title="Copy">
                                        <span className="material-icons">content_copy</span>
                                    </IconButton>                                    
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton size='small' onClick={()=>handleDelSlideItem(index)} title="Delete">
                                        <ClearIcon  />
                                    </IconButton>                                    
                                </ListItemIcon>
                                                                
                                <ListItemIcon className={clsx("drag-handle", classes.moveicon)} title="Drag and drop me">                                    
                                    <DragHandleIcon />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Collapse in={openId === index} timeout="auto" unmountOnExit>
                            <Box mt={1}>
                                <Paper className={classes.paper} elevation={0} variant="outlined">
                                    <SlideProps 
                                        ref={refSlide}
                                        slide={slide} 
                                        langs={props.langs} 
                                        countries={props.countries} 
                                        roles={props.roles} 
                                    /> 
                                </Paper>
                            </Box>
                        </Collapse>
                    </Draggable>
                    ))}

                </Container>
            </List>
            </Box>
        )}
        </AppConsumer>
    )
});

SliderEditor.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            Code: PropTypes.string.isRequired,
            CurrencyId: PropTypes.string.isRequired,
            Id: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            TwoLetterISORegionName: PropTypes.string.isRequired,
        })
    ),
    langs: PropTypes.arrayOf(
        PropTypes.shape({
            EnglishName: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            NativeName: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,
        })
    ),
    slider: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
        Descr: PropTypes.string,
        ViewName: PropTypes.string.isRequired,
        FileName: PropTypes.string.isRequired,
        Items: PropTypes.arrayOf(
            PropTypes.shape({
                Id: PropTypes.number.isRequired,
                Caption: PropTypes.string.isRequired,
                Url: PropTypes.string.isRequired,
                Text: PropTypes.string.isRequired,
                Path: PropTypes.string.isRequired,
                // Img: PropTypes.string.isRequired,
                FullPath: PropTypes.string.isRequired,
                FullPathMobile: PropTypes.string.isRequired,
                IsAuthenticated: PropTypes.bool.isRequired,
                Roles: PropTypes.arrayOf(PropTypes.string),
                Hide: PropTypes.bool.isRequired,
                ShowText: PropTypes.bool.isRequired,
                ShowFrom: PropTypes.string.isRequired,
                ShowTo: PropTypes.string.isRequired,
                ButtonText: PropTypes.string.isRequired,
                BgColorTexBox: PropTypes.string,
                PosStack: PropTypes.number,
                Visitor: PropTypes.number.isRequired,
                LocalValues: PropTypes.arrayOf(
                    PropTypes.shape({
                        Id: PropTypes.number.isRequired,
                        Caption: PropTypes.string.isRequired,
                        Text: PropTypes.string.isRequired,
                        ButtonText: PropTypes.string.isRequired,
                        TwoLetterISOLanguageName: PropTypes.string.isRequired,
                    })
                ),
            })
        ).isRequired,
    }),
    onSave: PropTypes.func,
};

export default SliderEditor;
