import React, { useState, useContext, useEffect }from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { Paper, Button, Typography, FormControl, InputLabel,OutlinedInput } from '@material-ui/core';
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1, 1, 1, 1),
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),            
    },
    control: {      
    },
  })
);

const BearerToken = () => {
    const classes = useStyles();    
    const auth = useContext(AppContext);    
    const [token, setToken] = useState(null);
    const [profile, setProfile] = useState('');

    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    // console.log(user);
                    setProfile(JSON.stringify(user.profile, null, "\t"));
                    token = user.access_token;
                }                
                setToken(token);
            });
        }
        fetchUser();
    }, [auth]);

    return (
        <AppConsumer> 
        {() => (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h5">
                        Token
                    </Typography>
                </Paper>

                <Paper className={classes.paper}>
                    <Typography align='center' variant="h6" gutterBottom>
                        User token profile                        
                    </Typography>
                    <FormControl fullWidth className={classes.control} variant="outlined">
                        <InputLabel htmlFor="user-profile">User profile</InputLabel>
                        <OutlinedInput
                            id="user-profile"
                            value={profile}
                            multiline
                            rows={9}
                            labelWidth={90}
                        />
                    </FormControl>
                    
                </Paper>

                <Paper className={classes.paper}>
                    <Typography align='center' variant="h6" gutterBottom>
                        For swagger                        
                    </Typography>
                    <FormControl fullWidth className={classes.control} variant="outlined">
                        <InputLabel htmlFor="swagger-token">For swagger</InputLabel>
                        <OutlinedInput
                            id="swagger-token"
                            value={token ? `Bearer ${token}`: ''}
                            multiline
                            rows={9}
                            labelWidth={90}
                        />
                    </FormControl>
                    <Typography display='block' style={{marginTop: 8}} >
                        <Button variant="outlined" color="primary" 
                            startIcon={<FileCopyOutlinedIcon />}
                            onClick={() => navigator.clipboard.writeText(token ? `Bearer ${token}`: '') } >Copy</Button>
                    </Typography>
                </Paper>

                <Paper className={classes.paper}>                    
                    <Typography align='center' variant="h6" gutterBottom>
                        For postman
                    </Typography>
                    <FormControl fullWidth className={classes.control} variant="outlined">
                        <InputLabel htmlFor="postman-token">For postman</InputLabel>
                        <OutlinedInput
                            id="postman-token"
                            value={token ? token: ''}
                            multiline
                            rows={8}
                            labelWidth={90}
                        />
                    </FormControl>
                    <Typography display='block' style={{marginTop: 8}} >
                        <Button variant="outlined" color="primary" 
                            startIcon={<FileCopyOutlinedIcon />}
                            onClick={() => navigator.clipboard.writeText(token ? token: '') } >Copy</Button>
                    </Typography>
                </Paper>

            </div>
        )}
        </AppConsumer>
    )
}

export default BearerToken;