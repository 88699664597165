import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from '@material-ui/core/Slide';
import { AppBar, Paper, Box, Divider, Grid, Tabs, Tab, IconButton,  InputBase, Toolbar, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormLabel, FormControl, RadioGroup, FormControlLabel, Typography, Button, TextField, Checkbox, Radio } from "@material-ui/core";
import { OidcUserManager } from "../../helpers/OidcSettings";
import uuidv4 from "uuid/v4";
import {alpha, withStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import SaveOutlined from "@material-ui/icons/SaveOutlined";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/MenuBuilder";
import { connect } from "react-redux";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey";

import { Icon } from "@material-ui/core";
import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath, getNodeAtPath, addNodeUnderParent } from "react-sortable-tree";
// import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';


import TranslateIcon from '@material-ui/icons/Translate';
import SearchIcon from '@material-ui/icons/Search';
import "react-sortable-tree/style.css";
import { countryToFlag } from "../../store/countries";
import { CatalogIcon, ChildIcon, FaceIcon, ForBodyIcon, ForHomeIcon, HairIcon, HealthIcon, JewerlyIcon, MakeupIcon, ManIcon, 
    ParfumsIcon, HeartPulseIcon, HomeLightbulbIcon, FaceOutlineIcon, AccountTieOutline, WomanHeadIcon } from "../icons/jerelia-icons";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },

    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 12,
        padding: theme.spacing(1),
        boxShadow: theme.palette.ambilight.boxshadow
    },

    appbar: {            
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    toolbar: {
        // padding: theme.spacing(1, 2)
    },

    input: {
        marginRight: theme.spacing(2), 
    },
    formControl: {
        width: '100%',
        display: 'block'
        // margin: theme.spacing(1),
    },
    margin: {
        marginRight: theme.spacing(1),
    },
    iconButton: {
        padding: 10,
    },
    menuname: {
        paddingRight: theme.spacing(2)
    },
    treeWrapper: {
        height: 640,
    },
    langname: {
        fontSize: '.6rem'
      },  
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.75),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 1),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      icon: {
        color: grey[600],
        fontSize: 22
      }
});

const maxDepth = 3;
const newItemMenu = "New menu item";

const getIcon = (icon) => {
    switch (icon) {
      case "catalog":          
        return (<CatalogIcon className={clsx( "vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      case "child":          
        return (<ChildIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
        case "women-head":          
        return (<WomanHeadIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);  
      case "face":
        return (<FaceIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      case "face-outline":          
        return (<FaceOutlineIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20}/>);  
      case "for-body":          
        return (<ForBodyIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      case "for-home":          
        return (<span className={clsx("vertical-align-middle", "padding-bottom-3")}><ForHomeIcon width={20} height={20} /></span>);
      case "hair":          
        return (<span className={clsx("vertical-align-middle", "padding-bottom-3")}><HairIcon width={20} height={20} /> </span>);
      case "health":          
        return (<HealthIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20}/>);
      case "jewerly":          
        return (<JewerlyIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      case "makeup":          
        return (<MakeupIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20}/>);
      case "account-tie-outline":          
        return (<AccountTieOutline className={clsx("vertical-align-middle", "padding-bottom-3")} />);
      case "parfums":          
        return (<ParfumsIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      case "heart-pulse":          
        return (<span className={clsx("vertical-align-middle", "padding-bottom-3")}><HeartPulseIcon width={18} height={18} /></span>);
        case "manager":          
        return (<ManIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);  
      case "home-lightbulb":          
        return (<HomeLightbulbIcon className={clsx("vertical-align-middle", "padding-bottom-3")} width={20} height={20} />);
      default:
        return (<span className={clsx("material-icons", "vertical-align-middle", "padding-bottom-3")}>{icon}</span>)          
    }
};

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box pt={1} pb={1}>
            {children}
          </Box>
        )}
      </div>
    );
}

function arrayRemove(arr, value) {
    return arr.filter(function(ele){ 
        return ele != value; 
    });
}  

const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
}

class MenuBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: this.props.match.params.file || "new",
            searchString: "",
            searchFocusIndex: 0,
            searchFoundCount: null,
            openDlgNode: false,
            openDlgDelNode: false,
            fullWidth: true,
            maxWidth: "xl",
            temptxt: "",
            node: {},
            path: [],
            treeIndex: -1,
            langTab: 0,
            inputFocus: null,
            showError: false,
            errorTxt: "",
            token: null,
        };
        // this.props.requestMenu(this.state.file); 
    }
            
    // This method runs when incoming props (e.g., route params) change
    // UNSAFE_componentWillReceiveProps(nextProps) {        
    //     this.setState(
    //         {filename: nextProps.match.params.file || "new"}
    //     );
    //     this.props.requestMenu(nextProps.match.params.file); 
    // };

    async componentDidMount() {        
        this.props.requestMenu(this.state.file);
        const userManager = await OidcUserManager();
        userManager.getUser().then(user => {        
            if (user !== null) {
                if(user.access_token !== null ){
                    const token =  `Bearer ${user.access_token}`;                        
                    this.setState({token: token});
                }
            }
        });        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.file !== this.state.file) {
            console.log('Load componentDidUpdate');
            this.props.requestMenu(this.state.file);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.file !== prevState.file){
          return { file: nextProps.match.params.file};
       }
       else return null;
    }
   
    handleOpenDlgNode = opened => {     
        this.setState({openDlgNode: opened});
    };

    handleOpenDlgDelNode = opened => {     
        this.setState({openDlgDelNode: opened});
    };

    handleSearchOnChange = e => {
        this.setState({ searchString: e.target.value });
    };
    
    selectPrevMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;    
        this.setState({
          searchFocusIndex:
            searchFocusIndex !== null
              ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
              : searchFoundCount - 1,
        });
    };
    
    selectNextMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;    
        this.setState({
          searchFocusIndex:
            searchFocusIndex !== null
              ? (searchFocusIndex + 1) % searchFoundCount
              : 0
        });
    };
    
    toggleNodeExpansion = expanded => {
        this.props.changeMenu(
            toggleExpandedForAll({
                treeData: this.props.treeData,
                expanded
            }));
    };

    handleDelItem = (rowInfo) => {
        // let {node, treeIndex, path} = rowInfo;
        let {path} = rowInfo;
        let treeData = removeNodeAtPath({
            treeData: this.props.treeData,
            path: path,
            getNodeKey: ({treeIndex: number}) => {                  
                return number;
            },
            ignoreCollapsed: true,
        });

        this.props.changeMenu(treeData);
    };

    handleSaveItem = () => {
        this.props.changeMenu(
            changeNodeAtPath({
                treeData: this.props.treeData,
                newNode: this.state.node,                
                path: this.state.path,
                ignoreCollapsed: true,
                getNodeKey: ({ treeIndex }) =>  treeIndex                
            })
        );
        this.setState({openDlgNode: false});
    };

    addNode(rowInfo){
        let {treeIndex, path} = rowInfo;
        const NEW_NODE = {
            children: null,
            classname: "",
            backgroundimg: "",
            href: "/",
            icon: "",
            id: uuidv4(),
            isauthenticated: false,
            visitor: 0,
            open: false,
            roles: "",
            subtitle: "",
            target: "",
            title: newItemMenu,
            countries: [],
            translates: [],
        };

        path.pop();

        let parentNode = getNodeAtPath({
            treeData: this.props.treeData,
            path : path,
            getNodeKey: ({ treeIndex }) =>  treeIndex,
            ignoreCollapsed : true
        });

        let getNodeKey = ({ node: object, treeIndex: number }) => {
            return number;
        };

        let parentKey = getNodeKey(parentNode);
        
        if(parentKey === -1) {
            parentKey = null;
        }        

        let newTree = addNodeUnderParent({
            treeData: this.props.treeData,
            newNode: NEW_NODE,
            expandParent: true,
            parentKey: treeIndex,
            getNodeKey: ({ treeIndex }) =>  treeIndex
     });
     this.props.changeMenu(newTree.treeData);
    }

    handleNewItem = () => { 
            const node = {
                children: null,
                classname: "",
                backgroundimg: "",
                href: "/",
                icon: "",
                id: uuidv4(),
                isauthenticated: false,
                visitor: 0,
                open: false,
                roles: "",
                subtitle: "",
                target: "",
                title: newItemMenu,
                countries: [],
                translates: [],
            };

            for (let i = 0; i < this.props.langs.length; i++) {
                const lang = this.props.langs[i];                                                                    
                node.translates.push({
                        Id: 0, Lang: lang.TwoLetterISOLanguageName, Name: "", DicKey: "", Value: `${node.title} ${lang.TwoLetterISOLanguageName}`
                });
            }
            
            this.props.changeMenu( 
                addNodeUnderParent({
                    treeData: this.props.treeData,
                    newNode: node,
                    expandParent: true,
                    parentKey: null,
                    getNodeKey: ({ treeIndex }) =>  treeIndex
                }).treeData);        
    };

    handleEditItem = ({ node, path, treeIndex }) => {
        console.log('Edit node', node);
        for (let i = 0; i < this.props.langs.length; i++) {
            const lang = this.props.langs[i];
            const idx = node.translates.findIndex(t => t.Lang === lang.TwoLetterISOLanguageName);
            
            if(idx === -1){                
                node.translates.push({
                    Id: 0, Lang: lang.TwoLetterISOLanguageName, Name: "", DicKey: "", Value: ""
                });
            }
        }
        
        this.setState({ node: {...node}, path: path, treeIndex: treeIndex, langTab: 0, openDlgNode: true });                
    };

    checkedCountry = (country) => {
        const { node } = this.state;
        
        if(!node.countries) return false;
        
        return node.countries.findIndex(c => c === country) > -1;
    }

    handelClearAllCountries = () => {
        const node = {...this.state.node};
        node.countries = [];
        this.setState({node: node});
    }

    handelSelectAllCountries = () => {
        const node = {...this.state.node};
        const {countries} = this.props;
        for (let idx = 0; idx < countries.length; idx++) {
            
            const country = countries[idx];
            const exist = node.countries.findIndex(c=> c === country.TwoLetterISORegionName);

            if(exist === -1){
                node.countries.push(country.TwoLetterISORegionName);
            }
        }
        this.setState({node: node});
    }

    handleChange = (e) => {
        const node = {...this.state.node};

        if(e.target.type === "checkbox") {            
            const idx = node.countries.findIndex(c=> c === e.target.name);
            if(idx === -1){
                node.countries.push(e.target.name);
            }else{
                const cc = arrayRemove(node.countries, e.target.name);// node.countries.splice(idx, 1);
                node.countries = cc;
            }
            // node[e.target.name] = e.target.checked;
        } else {
            node[e.target.name] = e.target.value;
        }                
        this.setState({node: node});
    };

    handleChangeTransValue = (e) => {
        const { langs } = this.props;
        const { node, langTab } = this.state;                
        
        if(!node)
            return;
        
        if(node.translates.length === 0)
            return;        
                
        const lang = langs[langTab];

        const tranIdx = node.translates.findIndex(t => t.Lang === lang.TwoLetterISOLanguageName);
                
        if(tranIdx !== -1){
            node.translates[tranIdx].Value = e.target.value;
            this.setState({ node: {...node} });
        }                        
    };

    handleChangeTab = (event, newValue) => {   
        console.log('handleChangeTab', newValue);
        this.setState({ langTab: newValue });
    };
    
    handleSaveTree = () => {
        this.props.saveMenu(this.props.treeData);
    };

    getTranslateValue = (node, lang) => {
        if(!node) return "";
        if(node.translates.length === 0) return "";

        const idx = node.translates.findIndex(t => t.Lang === lang);
        if(idx === -1) return "";

        return node.translates[idx].Value;        
    };

    handleTraslateByAllLangs = () => {
        const { langs } = this.props;        
        const { node } = this.state;

        if(!node)
            return;
        
        if(node.translates.length === 0)
            return;
        
        const data = [];

        for (let i = 0; i < langs.length; i++) {
            const lang = langs[i];

            // if(lang.TwoLetterISOLanguageName === 'uk') 
            //     continue;

            data.push(
                {
                    "text": this.state.node.title,
                    "sourceLanguageCode": "UK",
                    "targetLanguageCode": lang.TwoLetterISOLanguageName
                }
    
            );
        }
        // console.log('Translete by all langs', data);

        this.translateSentence(data);

        
        // console.log('Translete to all langs', langs, this.state.node.title);
    };

    setTransletes = (data, targetLanguageCode) => {
        const { node } = this.state; 
        const { langs } = this.props;

        console.log('Transleted', data, targetLanguageCode);
        if(!node || !data)
            return;

        const idxLang = langs.findIndex(lang => lang.TwoLetterISOLanguageName === targetLanguageCode);
        if(idxLang === -1)
            return;
        
        const lang = langs[idxLang];
        const tranIdx = node.translates.findIndex(t => t.Lang === lang.TwoLetterISOLanguageName);
                
        if(tranIdx !== -1){
            node.translates[tranIdx].Value = data.Text;
            this.setState({ node: {...node} });
        }                        
        

    };

    translateSentence = (data) => {
        const url = '/api/v1.0/translate/sentence';
        for (let i = 0; i < data.length; i++) {
            const elemen = data[i];
                   
            fetch(url,
                {
                    method: 'POST',         
                    body: JSON.stringify(elemen),
                    headers: {                  
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Authorization": this.state.token,
                    }
                },
            )
            .then((response) => response.json())
                .then((data) => {
                    this.setTransletes(data, elemen.targetLanguageCode);
                })
                .catch((error) => {
                    console.log('Error', error);
                    this.setState({showError: true, errorTxt: `Error translete from ${elemen.sourceLanguageCode} to ${elemen.targetLanguageCode}. Text: ${elemen.text}`});
                });
        }
    };

    handleTraslateBySelLang = () => {
        const { langs } = this.props;   
        const { node, langTab } = this.state; 
        
        if(!node)
            return;
        
        if(node.translates.length === 0)
            return;        
                
        const lang = langs[langTab];
        
        const data = [{
            "text": this.state.node.title,
            "sourceLanguageCode": "UK",
            "targetLanguageCode": lang.TwoLetterISOLanguageName
        }];


        this.translateSentence(data);
        
        // console.log(lang, data);        
    };

    render(){
        const { classes, countries, langs } = this.props;        
        const { searchString, searchFocusIndex, searchFoundCount, langTab, showError, errorTxt } = this.state;                
        return(
            <Paper className={clsx(classes.root, classes.paper)}> 
                {
                    showError &&  
                    <Snackbar 
                        anchorOrigin={{ vertical: "top", horizontal: "center" }} 
                        onClose={() => this.setState({ showError: false})}
                        open={showError} autoHideDuration={5000} 
                        TransitionComponent={TransitionUp}
                    >
                        <Alert variant="filled" severity="error" onClose={()=> this.setState({ showError: false})} >
                            {errorTxt}
                        </Alert>
                    </Snackbar>
                }
                
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                    <Toolbar className={classes.toolbar}>
                        
                        <Typography variant="h6" className={classes.title}>
                            Menu editor
                        </Typography>
                        <IconButton title="Save menu" className={classes.iconButton} onClick={this.handleSaveTree} >
                            <SaveOutlined />
                        </IconButton>                
                        <IconButton title="Add menu item" className={classes.iconButton} onClick={this.handleNewItem} >
                            <Add />
                        </IconButton>
                        <IconButton title="Expand" className={classes.iconButton} onClick={this.toggleNodeExpansion.bind(this, true)}>
                            <KeyboardArrowDown />
                        </IconButton>
                        <IconButton title="Collapse" className={classes.iconButton} onClick={this.toggleNodeExpansion.bind(this, false)}>
                            <KeyboardArrowUp />
                        </IconButton>

                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                onChange={this.handleSearchOnChange}
                                placeholder="Search menu…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>

                        {/* <InputBase className={classes.input} placeholder="Search menu item" onChange={this.handleSearchOnChange}/> */}
                        
                        <IconButton className={classes.iconButton} onClick={this.selectPrevMatch}>
                            <ArrowBack />
                        </IconButton>
                        <IconButton  className={classes.iconButton} onClick={this.selectPrevMatch}>
                            <ArrowForward />
                        </IconButton>
                        <label>
                            {searchFocusIndex} / {searchFoundCount}
                        </label>
                    </Toolbar>
                </AppBar>
                
                <div className={classes.treeWrapper}>
                    <SortableTree
                        treeData={this.props.treeData}
                        maxDepth={maxDepth}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        isVirtualized={true}
                        searchFinishCallback={matches =>
                            this.setState({
                              searchFoundCount: matches.length,
                              searchFocusIndex:
                                matches.length > 0 ? searchFocusIndex % matches.length : 0,
                            })
                          }
                        onChange={treeData => this.props.changeMenu(treeData)}
                        generateNodeProps={rowInfo => ({
                            title: (
                                <span> 
                                    {rowInfo.node.icon && rowInfo.node.icon !== "" && 
                                        getIcon(rowInfo.node.icon)                                    
                                    }
                                    {rowInfo.node.title}
                                </span>),                            
                            buttons: [
                            <IconButton key={0}  onClick={() => this.handleEditItem(rowInfo)}>
                                <Edit />
                            </IconButton>,
                            <IconButton key={1}  onClick={() => this.handleDelItem(rowInfo)}>
                                <Clear />
                            </IconButton>,                            
                            <IconButton key={2}  onClick={() => this.addNode(rowInfo)}>
                                <Add />
                            </IconButton>,                        
                            ],
                        })}
                        // theme={FileExplorerTheme}
                    />
                </div>
                
                <Typography className={classes.menuname} variant="overline" display="block" align="right" gutterBottom >
                    Menu : {this.state.file}
                </Typography>            
                
                <Dialog 
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.state.openDlgNode} onClose={this.handleOpenDlgNode.bind(this, false)} 
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><span className="material-icons">edit_note</span> Menu: {this.state.node.title}</DialogTitle>
                        <DialogContent> 
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        autoFocus                                        
                                        name="title"
                                        label="Title"
                                        type="text"                        
                                        value={this.state.node.title}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        variant="outlined"
                                        size="small"
                                        name="href"
                                        label="HREF"
                                        type="text"
                                        fullWidth
                                        value={this.state.node.href || "/"}
                                        onChange={e => this.handleChange(e)}
                                    />                                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        name="backgroundimg"
                                        label="Background Img"
                                        type="text"
                                        fullWidth
                                        value={this.state.node.backgroundimg || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid> 
                                {/* <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="subtitle"
                                        label="Subtitle"
                                        type="text"
                                        fullWidth
                                        value={this.state.node.subtitle || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid> */}
                            </Grid>
                            {langs && langs.length > 0 &&
                                <>
                                    <AppBar position="static" elevation={0} color='default' variant="outlined" style={{marginTop: 16, marginBottom: 8}}>
                                        <Toolbar variant="dense">
                                            <IconButton
                                                title="Translate to all langs" 
                                                className={classes.menuButton} 
                                                edge="start" color="secondary" 
                                                aria-label="menu"
                                                onClick={() => this.handleTraslateByAllLangs()}
                                            >
                                                <TranslateIcon />
                                            </IconButton>
                                            <Tabs
                                                variant="scrollable"
                                                value={langTab}
                                                onChange={this.handleChangeTab}
                                                aria-label="Langs resources"
                                            >
                                                {langs.map((res, idx) => 
                                                <Tab key={res.TwoLetterISOLanguageName} 
                                                    wrapped 
                                                    label={<>
                                                        <Typography variant="body2">{countryToFlag(res.Name.length === 2 ? res.Name : res.Name.substring(3,5) )} {res.TwoLetterISOLanguageName}</Typography>
                                                        <Typography variant="caption" className={classes.langname}>{res.EnglishName}</Typography>
                                                      </>} 
                                                    {...a11yProps(idx)} /> 
                                                )}
                                            </Tabs>
                                        </Toolbar>
                                    </AppBar>
                                    {langs.map((res, idx) => 
                                        <TabPanel key={res.TwoLetterISOLanguageName} value={langTab} index={idx} >
                                            {this.state.node && this.state.node.translates && this.state.node.translates.length > 0 &&
                                            <Grid container direction="row" spacing={2} >
                                                <Grid item xs={2} sm={1} style={{textAlign: 'center'}}>
                                                    <IconButton
                                                        title="Translate to selected lang"                                                         
                                                        edge="start"
                                                        color="primary" 
                                                        aria-label="translete"
                                                        onClick={e => this.handleTraslateBySelLang()}
                                                    >
                                                        <TranslateIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={10} sm={11}>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth                                                
                                                        name="Value"
                                                        label={`Title by ${res.Name}`}
                                                        type="text"                                                    
                                                        value= {this.getTranslateValue(this.state.node, res.TwoLetterISOLanguageName)}
                                                        onChange={e => this.handleChangeTransValue(e)}
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                            }
                                            
                                             
                                            
                                        </TabPanel>
                                    )}                                    
                                </>
                            }
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}  >
                                <Grid item xs={2} sm={1}>
                                    <Typography component="div" align="center">                
                                        <Icon className={classes.icon}>{ this.state.node.icon ? this.state.node.icon : "block"}</Icon>        
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <TextField 
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={classes.input}
                                        margin="normal"
                                        name="icon"
                                        label="Material Icon"
                                        type="text"                    
                                        value={this.state.node.icon || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        margin="normal" 
                                        className={classes.input}
                                        name="classname"
                                        label="Classname"                        
                                        type="text"
                                        value={this.state.node.classname || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth 
                                        margin="normal"
                                        name="target"
                                        label="Target"
                                        type="text"
                                        value={this.state.node.target || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Divider style={{marginTop: 8, marginBottom: 16}} />
                            
                            {countries && countries.length > 0 &&
                            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" >
                                <Grid item xs={12} sm={3} >
                                    <Button variant="outlined" startIcon={<span className="material-icons">done_all</span>} className={classes.margin} size="small" onClick={()=>this.handelSelectAllCountries()}>
                                        Check all
                                    </Button>
                                    <Button variant="outlined" startIcon={<span className="material-icons">remove_done</span>} size="small" onClick={()=>this.handelClearAllCountries()}>
                                        Uncheck all
                                    </Button>                                    
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <FormGroup row>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormLabel component="legend">Available in the country</FormLabel>
                                            {
                                                countries.map((country) => (                    
                                                    <FormControlLabel style={{width: 174}} key={country.TwoLetterISORegionName}
                                                        control={   <Checkbox 
                                                                        name={country.TwoLetterISORegionName} 
                                                                        checked={this.checkedCountry(country.TwoLetterISORegionName)} 
                                                                        onChange={e => this.handleChange(e)}
                                                                    />
                                                                }
                                                        label={<>{countryToFlag(country.TwoLetterISORegionName)} {country.Name}</>}
                                                    />
                                            ))
                                            }
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                             </Grid>
                            }                                                        

                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={12} sm={4}>
                                    <FormControl component="fieldset" fullWidth >
                                        <RadioGroup row
                                            aria-label="visitor" name="visitor"  
                                            value={`${this.state.node.visitor}`}
                                            onChange={this.handleChange} 
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="All"  />
                                            <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                                            <FormControlLabel value="2" control={<Radio />} label="Authorized" />                        
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField 
                                        variant="outlined"
                                        size="small"
                                        name="roles"
                                        label="Roles: Administrator, Consultant, CoWorker..."
                                        type="text"
                                        fullWidth
                                        value={this.state.node.roles || ""}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>                                                                
                            </Grid> 
                            
                            <Divider style={{marginTop: 16}} />
                        </DialogContent>
                        
                        <DialogActions>
                            <Button style={{ minWidth: 128}} variant="outlined" onClick={this.handleOpenDlgNode.bind(this, false)}>
                                Cancel
                            </Button>
                            <Button style={{marginRight: 16, minWidth: 128}} variant="outlined" onClick={this.handleSaveItem.bind()} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                </Dialog>
            </Paper>            
        );
    }
}

MenuBuilder.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(    
    state => state.menuBuilder,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(MenuBuilder));