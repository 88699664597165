import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {IconButton, Paper, Grid, Typography, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinearProgress from '@material-ui/core/LinearProgress';

import moment from "moment";
import "moment/locale/uk";
// eslint-disable-next-line no-unused-vars
import { AppContext, AppConsumer } from "../../providers/appProvider";
import OrderHistory from "../Order/OrderHistory";
import clsx from "clsx";

moment.locale("uk");

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {        
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(1),
    },
    sectionbox:{
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
    },
    detailsbox: {
        margin: theme.spacing(1, 0),
    },
    headerbox: {
        padding: theme.spacing(1),
        height: theme.spacing(7)
    },
    heading: {
        fontWeight: "bold"
    },
    data: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(7),
            padding: theme.spacing(1,1,0,1),
        },
    },
    table: {
        
    },
    tcell: {        
        padding: '6px 12px 6px 4px'
    },
    pre: {
        paddingTop:16, 
        backgroundColor: "#eee", 
        fontSize: ".8em", 
        whiteSpace: 'pre'
    },

    dialogPaper: {             
        minHeight: 400,
        [theme.breakpoints.down('sm')]: {
            minHeight: 360,
            width: "calc(100% - 16px)",
            margin: 16
        },
    },
    dialogTitle:{
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    btn: {
        fontSize: ".8em"
    },
    tblhd: {
        fontSize: ".9em"
    }
  })
);

// eslint-disable-next-line no-unused-vars
const StyledTableContainer = withStyles((theme) => ({
    root: {
        width: "max-content",
        [theme.breakpoints.up('sm')]: {
            width: "100%",
        },
    }
}))(TableContainer);

const BusinessPack = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [token, setToken] = useState();
    const auth = useContext(AppContext);
    const [businessPack, setBusinessPack] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [openDlg, setOpenDlg] = useState(false);
    const [openDlgOrder, setOpenDlgOrder] = useState(false);

    const [description, setDescription] = useState();
    // const [orderId, setOrderId] = useState('2B1CA0CC-FAC7-11EC-8104-000C29E6B2CD');
    const [orderId, setOrderId] = useState('');
    const [order, setOrder] = useState(null);
    const maxWidth = 'md';
    const fullWidth = true;
    // const colors = [
    //     {color: "green", descr: "Опис занчення кольору"}, 
    //     {color: "yellow", descr: "Опис занчення кольору"}, 
    //     {color: "red", descr: "Опис занчення кольору"}
    // ];

    const handleOpenDlb = () => {
        setOpenDlg(true);
    };

    const handleCloseDlg = () => {
        setOpenDlg(false);
    };

    const handleCloseDlgOrder = () => {
        setOrder(null);
        setOrderId('');
        setOpenDlgOrder(false);
    };    
    
    const handleOpenMenu = (e, phone) => {
        // {`tel: ${(phone.startsWith("380") ? phone : `38${phone}`)}`}
        const strPhone = `${(phone.startsWith("380") ? phone : `38${phone}`)}`;
        
        const tel = 
            <MenuItem>
                <Button size="small" href={`tel:${strPhone}`} startIcon={<PhoneForwardedIcon fontSize="small" />}>
                    Телефон
                </Button>
            </MenuItem>;

        const viber = 
            <MenuItem>
                <Button size="small" href={`viber://chat/?number=%2B${strPhone}`} startIcon={<ViberIcon fontSize="small" width={24} height={24} />}>
                    Viber
                </Button>
            </MenuItem>;
        
        const telegram =
            <MenuItem>
                <Button size="small" href={`https://t.me/+${strPhone}`} startIcon={<TelegramIcon fontSize="small" width={24} height={24} />}>
                    Telegram
                </Button>            
            </MenuItem>;
        
        const whatsapp =
        <MenuItem>
            <Button size="small" href={`https://wa.me/+${strPhone}`} startIcon={<WhatsAppIcon fontSize="small" />}>
                WhatsApp
            </Button>            
        </MenuItem>;

        const items = [];

        items.push(tel);
        items.push(viber);
        items.push(telegram);
        items.push(whatsapp);
        
        setMenuItems(items);
        
        setAnchorEl(e.currentTarget);
    };
    
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const fetchData = useCallback(async () => {
        if (!token) return;

        const url = `/api/v1.0/reports/getbusinnespack`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data) {                    
                    setBusinessPack(data);
                    // setStr(JSON.stringify(data, undefined, 2))
                }
            });
    }, [token]);

    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = undefined;
                if (user != null) {                                        
                    token = user.access_token;
                    setToken(token);
                }
            });
        }
        fetchUser();        
        fetchData();

    }, [auth, fetchData]);

    useEffect(() => {
        const fetchDescr = async () => {
            // const URL = '/api/v1.0/content/businesspack';
            const URL =  '/api/v1.0/content/businesspack/false/uk';
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Accept", "application/json");
            
            await fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
            .then(cont => {            
              if(cont){                
                setDescription(cont);
              }
            })
            .catch(() => {
            // console.log(`Error getting ${URL}: ${error.message}`);
            });            
        }
        
        fetchDescr();
    }, []);

    useEffect (() => {
        if(!orderId || orderId === '')
            return;
        
        const loadOrder = async (id) => {
            setOpenDlgOrder(true);
            const URL = `/api/v1.0/Order/History/${id}`;
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Accept", "application/json");
            await fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
            .then(odr => { 
                if(odr){
                    console.log(odr)
                    setOrder(odr);                    
                }
            })
            .catch((error) => {
                console.log(`Error getting ${URL}: ${error.message}`);
            });            
        }

        loadOrder(orderId);            
        
    },[orderId]);

  return (
  <AppConsumer> 
    {() => (
        <div className={classes.root}>
            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center"> 
                <Grid item>
                    <Typography component="h1" variant='h4'>
                        Бізнес Пак
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleOpenDlb}>
                        <HelpOutlineIcon />
                    </IconButton>
                </Grid>
            </Grid>
                                
                {businessPack &&                                                 
                <>
                <Grid container direction="row" spacing={2}> 
                    {/* Orders */}
                    <Grid item xs={12} sm={6}>                        
                            {/* <Paper className={classes.sectionbox}>
                                <Typography variant='h6'>
                                    Замовлення
                                </Typography>                                
                            </Paper> */}
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center" style={{fontSize: ".9em", fontWeight: "bold"}}>
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.headerbox}>Замовлення</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper className={classes.headerbox}>Поточний період</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper className={classes.headerbox}>Попередній період</Paper>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Загалом замовлень, шт.</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentParcels}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviosParcels}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>На суму, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentParcelsSum}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousParcelsSum}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Нараховано БізнесПаку, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentBusinessPackSum}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousBusinessPackSum}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Виплачено БізнесПаку, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentBusinessPackPaid}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousBusinessPackPaid}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Повернень, шт</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentReturns}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousReturns}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Сума повернень, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentReturnsSum}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousReturnsSum}</Paper>
                                </Grid>
                            </Grid>
                            {/* <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Вартість повернень, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentReturnsCost}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousReturnsCost}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Загалом доход по БізнесПаку, грн</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.CurrentRevenue}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.PreviousRevenue}</Paper>
                                </Grid>
                            </Grid> */}

                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-detail-orders"
                                id="panel1a-detail-orders"
                                className={classes.detailsbox}
                                >
                                <Typography className={classes.heading}>Реєстр замовлень</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{paddingLeft: 2, paddingRight: 2}}>
                                    <StyledTableContainer>
                                    <Table size="small" className={classes.table} aria-label="detail orders">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={clsx(classes.tcell, classes.tblhd)}></TableCell>
                                                <TableCell className={clsx(classes.tcell, classes.tblhd)}>Дата</TableCell>
                                                <TableCell className={clsx(classes.tcell, classes.tblhd)} align="center">Замовлення</TableCell>
                                                <TableCell className={clsx(classes.tcell, classes.tblhd)} align="right">Сума, грн</TableCell>
                                                <TableCell className={clsx(classes.tcell, classes.tblhd)} align="right">БізнесПак, грн</TableCell>
                                                {/* <TableCell align="right">Статус</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {businessPack.Data.Parsels.map((row) => (
                                            <TableRow key={row.ParcelNum}>
                                                {/* <TableCell align="right" style={{backgroundColor: `${row.ParcelStatus}`}}>{row.ParcelStatus}</TableCell> */}
                                                <TableCell className={classes.tcell} >
                                                    <FiberManualRecordIcon fontSize="small" style={{color: `${row.ParcelStatus}`}} />                                                     
                                                </TableCell>
                                                <TableCell className={classes.tcell} >
                                                    <Typography variant="caption" noWrap>
                                                        {moment(row.Date).format("DD-MM-YY")}
                                                    </Typography>                                                    
                                                </TableCell>
                                                <TableCell className={classes.tcell}>
                                                    <Button className={classes.btn} fullWidth color="primary" onClick={() => setOrderId(row.Id)} >{row.ParcelNum}</Button>
                                                </TableCell>
                                                <TableCell className={classes.tcell} align="right">{row.ParcelSum}</TableCell>
                                                <TableCell className={classes.tcell} align="right">{row.ParcelBusinessPackSum}</TableCell>                                            
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </StyledTableContainer>
                                </AccordionDetails>
                            </Accordion>                            
                        
                    </Grid>
                    {/* Consultants */}
                    <Grid item xs={12} sm={6}>
                            {/* <Paper className={classes.sectionbox}>
                                <Typography variant='h6'>
                                    Клієнти
                                </Typography>                                
                            </Paper> */}
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center" style={{fontSize: ".9em", fontWeight: "bold"}}>
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.headerbox}>Клієнти</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper className={classes.headerbox}>Поточний період</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper className={classes.headerbox}>Попередній період</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Загалом клієнтів</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.ClientsSummary.CurrentBusinessPackClients}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.ClientsSummary.PreviousBusinessPackClients}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Нових</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.ClientsSummary.CurrentBusinessPackNewClients}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}}  className={classes.data}>{businessPack.Data.ClientsSummary.PreviousBusinessPackNewClients}</Paper>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} justifyContent="flex-start"  alignItems="center">
                                <Grid item xs={6} sm={8} >
                                    <Paper className={classes.data}>Активних</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2} >
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.ClientsSummary.CurrentBusinessPackActiveClients}</Paper>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <Paper style={{textAlign: "center"}} className={classes.data}>{businessPack.Data.ClientsSummary.PreviousBusinessPackActiveClients}</Paper>
                                </Grid>
                            </Grid>

                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-detail-consultants"
                                id="panel1a-detail-consultants"
                                className={classes.detailsbox}
                                >
                                <Typography className={classes.heading}>Детально про останні замовлення</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{paddingLeft: 2, paddingRight: 2}}>
                                    <StyledTableContainer>
                                        <Table size="small" className={classes.table} aria-label="detail orders">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={clsx(classes.tcell, classes.tblhd)} ></TableCell>
                                                    <TableCell className={clsx(classes.tcell, classes.tblhd)}>Клієнти</TableCell>
                                                    <TableCell className={clsx(classes.tcell, classes.tblhd)}><span style={{ whiteSpace: "nowrap" }}>К-сть</span></TableCell>
                                                    <TableCell className={clsx(classes.tcell, classes.tblhd)}>Останнє</TableCell>
                                                    <TableCell className={clsx(classes.tcell, classes.tblhd)}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {businessPack.Data.Clients.map((row, idx) => (
                                                <TableRow key={idx}>                                                    
                                                    <TableCell className={classes.tcell} >
                                                        <FiberManualRecordIcon fontSize="small" style={{color: `${row.ClientStatus}`}}  />
                                                    </TableCell>
                                                    <TableCell className={classes.tcell} component="th" scope="row">
                                                        {row.ClientName} {row.ClientLastName}
                                                    </TableCell>
                                                    <TableCell className={classes.tcell} align="center">{row.OrdersNum}</TableCell>
                                                    <TableCell className={classes.tcell}>
                                                        <Typography variant="caption" noWrap>
                                                        {moment(row.LastOrderDate).format("DD-MM-YY")}    
                                                        </Typography>                                                        
                                                    </TableCell> 
                                                    <TableCell className={classes.tcell} align="center">
                                                        <IconButton onClick={(e)=>handleOpenMenu(e, row.ClientPhone)} >
                                                            <MoreHorizIcon fontSize="small" />
                                                        </IconButton>
                                                        {/* <IconButton  href={`tel: ${(row.ClientPhone.startsWith("380") ? row.ClientPhone : `38${row.ClientPhone}`)}`} color="primary">
                                                            <MoreHorizIcon fontSize="small" />
                                                        </IconButton> */}
                                                    </TableCell>                                                    
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </StyledTableContainer>
                                </AccordionDetails>
                            </Accordion>
                        
                    </Grid>
                </Grid>
                <Menu
                    id="contacts-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                >
                    {
                        menuItems.map((component, idx) => (
                            React.cloneElement(component, { key: idx })
                        ))
                    }
                </Menu>

                <Dialog
                    open={openDlg}
                    fullWidth={true}
                    maxWidth='md'
                    onClose={handleCloseDlg}
                    aria-labelledby="legend-dialog-title"
                    aria-describedby="legend-dialog-description"
                >
                    <DialogTitle id="legend-dialog-title">
                        {description ? description.LocalValues[0].Title: ""}
                    </DialogTitle>
                    <DialogContent>
                        {description && 
                        <div>
                            <div dangerouslySetInnerHTML={{__html: description.LocalValues[0].Value }}></div>
                        </div>
                        }
                    
                    </DialogContent>
                    <DialogActions>                    
                        <Button onClick={handleCloseDlg} color="primary" autoFocus>
                            {t("buttons.Close")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDlgOrder}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    onClose={handleCloseDlgOrder}
                    aria-labelledby="order-dialog-title"
                    aria-describedby="order-dialog-description"
                    classes={{ paper : classes.dialogPaper}}
                >                    
                    <DialogTitle id="order-dialog-title"> 
                        { order && 
                        <Typography className={classes.dialogTitle} component='h3' variant="h6" display='block' >
                            <small style={{textTransform: "uppercase"}}>Замовлення</small> {order.DocNum} <small style={{whiteSpace: 'nowrap'}} >{moment(order.CreatedOn).format("DD-MM-YYYY")}</small>
                        </Typography>
                        }
                    </DialogTitle>                    
                    <DialogContent>
                        { order ?
                            <OrderHistory order={order} />
                            :
                            <LinearProgress />
                        }
                    </DialogContent>
                    <DialogActions>                    
                        <Button onClick={handleCloseDlgOrder} color="primary" autoFocus>
                            {t("buttons.Close")}
                        </Button>
                    </DialogActions>
                </Dialog>                
                </>                
                }                        
        </div>
    )}
  </AppConsumer>
  )
}

export default BusinessPack;

