import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid, TextField, AppBar, Toolbar, IconButton, Divider, CircularProgress,
         Tabs, Tab, Typography, Chip, InputAdornment, FormGroup, FormControl,
         FormControlLabel, Checkbox, RadioGroup, Radio, Paper, Button, Dialog,
        } from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "../CustomDialog";
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../store/countries";
import Files from "../Files/Files";

import TranslateIcon from '@material-ui/icons/Translate';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';;
import PreviewMedia from '../Manage/Content/PreviewMedia';
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        appbar: {            
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,            
        },
        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            padding: theme.spacing(1,2),
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        role: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        chip: {
            marginRight: theme.spacing(1)
        },
        chipIcon: {
            fontSize: 18
        },
        formControl: {
            padding: theme.spacing(0, 1),
            width: '100%',
            display: 'block'          
        },
        formControlLabel: {
            width: 202,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'            
        },
        lbInfo: {
            fontSize: ".9em",            
            padding: theme.spacing(1/8, 1),
            marginRight: theme.spacing(1),
            backgroundColor: "#e8e8e863",
            borderRadius: "6px"
        },
        
    })
);

function a11yProps(index) {
    return {
        id: `cnt-tab-${index}`,
        'aria-controls': `cnt-tabpanel-${index}`,
    };
}

const SlideProps = forwardRef(function SlideProps (props, ref)  {
    const { langs, countries, roles } = props;    
    const [slide, setSlide] = React.useState({ ...props.slide });
    const classes = useStyles();
    const app = useContext(AppContext);
    const { lang } = app;
    const [token, setToken] = useState();
    const { t } = useTranslation();
    const [langTab, setLangTab] =  useState(0); // useState(langs ? langs.length-1 : 0);
    const [loading, setLoading] = useState(false);    
    const [localIdx, setLocalIdx] = useState(0);
    const [changed, setChanged] = useState(false);
    const [showImgPreview, setShowImgPreview] = useState(false);
    const [imgPreview, setImgPreview] = useState();
    const [openFileDlg, setOpenFileDlg] = useState(false);
    const [selectedField, setSelectedField] = useState();
    
    moment.locale(lang);
    // const [openDlg, setOpenDlg] = useState(false);
    // console.log(slide);

    useImperativeHandle(ref, () => ({
        updateData() {
            if(changed){
                // console.log('Update slide', slide);
                return slide;
            }            
        },        
    }));

    const checkedCountry = (country) => {
        if(!slide.Countries) return false;        
        
        return slide.Countries.findIndex(c => c === country) > -1;
    }

    const handleChangeCountry = (e) => {
        const _slide = {...slide};
        const idx = _slide.Countries.findIndex(c=> c === e.target.name);
                
        if(idx === -1){
            _slide.Countries.push(e.target.name);
        }else{
            _slide.Countries.splice(idx, 1);
        }
        setSlide(_slide);
        setChanged(true);
    };

    const handleClearCountries = () => {
        const _slide = {...slide};
        _slide.Countries = [];
        setSlide(_slide);
        setChanged(true);
    };

    const handleAddAllCountries = () => {
        const _slide = {...slide};
        _slide.Countries = [];
        
        for (let idx = 0; idx < countries.length; idx++) {
            const country = countries[idx];                                                
            _slide.Countries.push(country.TwoLetterISORegionName);            
        }
        setSlide(_slide);
        setChanged(true);
    };

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            const value = parseInt(e.target.value);
            setSlide({
                ...slide,
                [e.target.name]: value
            });
        }else if(e.target.type === "checkbox"){
            const value = e.target.checked;
            setSlide({
                ...slide,
                [e.target.name]: value
            });
        }else{
            const value = e.target.value;
            setSlide({
                ...slide,
                [e.target.name]: value
            });
        }
        setChanged(true);
    };

    const handleChangeLocalValues = (index) => (e) => {
        const LocalValues = slide.LocalValues.map((item, i) => {
            if (index === i) {
                return { ...item, [e.target.name]: e.target.value };
            } else {
                return item;
            }
        });
        setSlide({
            ...slide,            
            "LocalValues": LocalValues
        });
        setChanged(true);
    };

    const onClearAll = () => {
        setSlide({
            ...slide,            
            "Roles": []
        });
        setChanged(true);
    }

    const onDeleteRole = (role) => {
        const idx = slide.Roles.findIndex(r => r === role);
        if(idx > -1){
            setSlide({
                ...slide,            
                "Roles": slide.Roles.filter(r => r !== role)
            });
            setChanged(true);
        }
    }

    const onAddRole = (role) => {
        const idx = slide.Roles.findIndex(r => r === role);
        if(idx === -1){
            const roles = [
                ...slide.Roles,
                role
            ];
            setSlide({
                ...slide,            
                "Roles": roles
            });
            setChanged(true);
        }
    };

    const handleTransleteAll = (e) => {
        Translate(true);
    };

    const handleTransleteSelected = (e) => {
        Translate(false);
    };

    const Translate = (all) => {
        
        const translate = {
            "lang": langs[langTab].TwoLetterISOLanguageName,
            "caption": slide.Caption,
            "text": slide.Text,
            "buttonText": slide.ButtonText
        };

        const URL = `/api/v1.0/Translate/Slide/${all}`;
        // console.log(URL, translate);
        
        fetch(
            URL, { 
                method: "POST",
                headers: { 
                        authorization: token, 
                        "Accept": "application/json", 
                        "Content-Type": "application/json;charset=UTF-8" 
                },
                body: JSON.stringify(translate)
            }
        )
        .then((response) => {
                if(response.status === 200){
                    response.json().then((translated) => {                        
                        // console.log('translated', translated);
                        if(translated &&  translated.length > 0){
                            const _slide = {...slide};
                            
                            for (let i = 0; i < translated.length; i++) {
                                const transl = translated[i];
                                const loc = _slide.LocalValues.find(l => l.TwoLetterISOLanguageName === transl.Lang);
                                if(loc){
                                    loc.Caption = transl.Caption;
                                    loc.Text = transl.Text;
                                    loc.ButtonText = transl.ButtonText;
                                }                                                                
                            }
                            
                            setSlide(_slide);
                            setChanged(true);
                        }
                    });
                }else {
                    alert(`${response.status} ${response.statusText}`);
                }
            })
        .catch(error => {                
            console.log("Error", error);
        });

    }

    const handleChangeTab = (event, newValue) => {
        setLangTab(newValue);        
    };
    
    const handleShowPreviewImg = (src) => {
        setImgPreview(slide[src]);
        setShowImgPreview(true);
    };

    const handleClosePreviewImg = ()=> {
        setShowImgPreview(false);
        setImgPreview(undefined);
    }

    // const handlePreviewSlider = () => {
    //     alert('Sorry! Not yet Implemented')
    // };

    const handleFileDlgClose = () => {
        setOpenFileDlg(false);
    };

    const handleDlgFile = (field) => {
        setSelectedField(field);
        setOpenFileDlg(true);
    };

    const onSelectFile = (path) => {        
        setOpenFileDlg(false);

        if(selectedField){            
            const _slide = {...slide};
            _slide[selectedField] = path;
            setSlide(_slide);
        }            
    };

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }                                
            });
        }
        fetchUser();        
    }, [app]);
    

    useEffect(()=> {
        const lang = langs[langTab];        
        setLocalIdx(slide.LocalValues.findIndex(v => v.TwoLetterISOLanguageName === lang.TwoLetterISOLanguageName));

    }, [langTab, langs, slide]);
    
  return (
    <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
            {/* Media files */}
            <Grid container direction="row" spacing={2}  >
                <Grid item xs={12} sm={6}>
                    <TextField                                
                        name="FullPath"
                        label="Path to desktop image / video"                                
                        placeholder="Select path to image"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <>
                                <IconButton onClick={()=>handleShowPreviewImg('FullPath')}>
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDlgFile("FullPath")}>
                                    <MoreHorizIcon />
                                </IconButton>
                                </>
                            </InputAdornment>,
                            }}
                        variant="outlined"
                        value={slide.FullPath}
                        onChange = {handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="FullPathMobile"
                        label="Path to mobile image"                    
                        placeholder="Select path to image"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <>
                                <IconButton onClick={()=>handleShowPreviewImg('FullPathMobile')}>
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                                </>
                                <IconButton onClick={() => handleDlgFile("FullPathMobile")}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </InputAdornment>,
                        }}
                        variant="outlined"
                        value={slide.FullPathMobile} 
                        onChange = {handleChange}
                    />

                </Grid>
            </Grid>
            
            {/* Common fields */}
            <Grid container direction="row" spacing={2} >
                <Grid item xs={12} sm={5}>
                    <TextField                    
                        name="Caption"
                        label="Caption"                    
                        placeholder="Caption"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={slide.Caption}
                        onChange = {handleChange}
                    />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <TextField                    
                        name="Text"
                        label="Text"                    
                        placeholder="Text"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={slide.Text}
                        onChange = {handleChange}
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <TextField                    
                        name="ButtonText"
                        label="Button text"                    
                        placeholder="ButtonText"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={slide.ButtonText}
                        onChange = {handleChange}
                    />                
                </Grid>

            </Grid>

            <Grid container direction="row" spacing={2} >
                <Grid item xs={12}>
                    <TextField                    
                        name="Url"
                        label="Action url"                    
                        placeholder="Action url"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={slide.Url}
                        onChange = {handleChange}
                    />                
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2} justifyContent="flex-start"   alignItems="center" >
                <Grid item xs={6} sm='auto'>
                    <TextField
                        name="ShowFrom"
                        type="datetime-local"
                        label="Date show from, MM/DD/YY"                    
                        placeholder="Show from"
                        margin="normal"
                        inputProps={{ step: 1 }}
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={moment(slide.ShowFrom).format("YYYY-MM-DDTkk:mm") }
                        onChange = {handleChange}
                    />
                </Grid>
                <Grid item xs={6} sm='auto'>
                    <TextField
                        name="ShowTo"
                        type="datetime-local"
                        label="Date show from, MM/DD/YY"                    
                        placeholder="Show from"
                        margin="normal"
                        inputProps={{ step: 1 }}
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={moment(slide.ShowTo).format("YYYY-MM-DDTkk:mm") }
                        onChange = {handleChange}
                    />
                </Grid>
                {/* <Grid item xs={4} sm='auto'>
                    <TextField
                        name="AutoPlayInterval"
                        type='numeric'
                        label="Play interval, s"                    
                        placeholder="Show from"
                        margin="normal"
                        inputProps={{ step: 1, min: 0, max: 300 }}
                        
                        InputLabelProps={{ shrink: true }}                                
                        variant="outlined"
                        value={slide.AutoPlayInterval }
                        onChange = {handleChange}
                    />
                </Grid> */}
                <Grid item xs={4} sm='auto'>
                    <Box pt={2}>
                        <FormControlLabel control={<Checkbox name="Hide" checked={slide.Hide} onChange = {handleChange} />} label="Hide slide" />
                    </Box>
                </Grid>
                <Grid item xs={4} sm='auto'>
                    <Box pt={2} >
                        <FormControlLabel control={<Checkbox name="ShowText" checked={slide.ShowText} onChange = {handleChange} />} label="Show text" />
                    </Box>
                </Grid>
                {/* <Grid item xs={12} sm={true}>
                    <Box pt={2} style={{textAlign:'right'}}>
                        <Button                                                  
                            variant='outlined'
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={handlePreviewSlider}
                        >
                            Preview slide
                        </Button>

                    </Box>
                </Grid> */}
            </Grid>
        </Paper>

        {/* Content by langs */}
        <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
            <Toolbar variant="dense" className={classes.toolbar}> 
                <div className={classes.wrapper}>
                    <IconButton 
                        title="Translete to all langs" 
                        
                        aria-label="translete_to_all" 
                        color="secondary"
                        style={{marginRight: 8}}
                        disabled={loading}
                        onClick={handleTransleteAll}
                    >
                    <span className="material-icons">g_translate</span>
                    </IconButton>
                    {loading && <CircularProgress size={48} className={classes.fabProgress} />}
                </div>
                <div className={classes.wrapper}>
                    <IconButton 
                        title="Translete selected lang"
                        
                        aria-label="translete_to_selected" 
                        color="primary"
                        disabled={loading}
                        onClick={handleTransleteSelected}
                    >
                        <TranslateIcon />
                    </IconButton>
                    {loading && <CircularProgress size={48} className={classes.fabProgress} />}
                </div>
                
                <Divider orientation='vertical' style={{height: 32}} />

                <Tabs
                    variant="scrollable"
                    value={langTab}
                    onChange={handleChangeTab}
                    aria-label="doc tabs"
                >
                    {langs.map((lang, index) => (
                        <Tab
                            key={lang.TwoLetterISOLanguageName}
                            label={<>
                                <Typography variant="body2">{countryToFlag(lang.Name.length === 2 ? lang.Name : lang.Name.substring(3,5) )} {lang.TwoLetterISOLanguageName}</Typography>
                                <Typography variant="caption" className={classes.langname}>{lang.EnglishName}</Typography>
                            </>}
                            {...a11yProps(index)}
                        />
                    ))
                    }
                </Tabs>
            </Toolbar>
        </AppBar>
                
        <Paper className={classes.paper} elevation={0}>
            {slide.LocalValues && 
                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="stretch" >
                    <Grid item xs={12} sm={5}>
                        <TextField                    
                            name="Caption"
                            label= {`${slide.LocalValues[localIdx].TwoLetterISOLanguageName}: Caption`}
                            placeholder="Caption"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}                                
                            variant="outlined"
                            value={slide.LocalValues[localIdx].Caption}
                            onChange={handleChangeLocalValues(localIdx)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField                    
                            name="Text"
                            label= {`${slide.LocalValues[localIdx].TwoLetterISOLanguageName}: Text`}
                            placeholder="Text"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}                                
                            variant="outlined"
                            value={slide.LocalValues[localIdx].Text}
                            onChange={handleChangeLocalValues(localIdx)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField                    
                            name="ButtonText"
                            label= {`${slide.LocalValues[localIdx].TwoLetterISOLanguageName}: Button text`}
                            placeholder="ButtonText"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}                                
                            variant="outlined"
                            value={slide.LocalValues[localIdx].ButtonText}
                            onChange={handleChangeLocalValues(localIdx)}
                        />                
                    </Grid>
                </Grid>
            }

        </Paper>
        
        {/* Access by roles */}
        <Paper className={classes.paper} elevation={0}>
            <Box pl={2} pr={2}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm='auto'>
                        <FormControl component="fieldset" >
                            <RadioGroup row
                                aria-label="visitor" name="Visitor"
                                value={`${slide.Visitor}`}                                        
                                onChange={handleChange}
                            >
                                <FormControlLabel value="0" control={<Radio />} label="All"  />
                                <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                                <FormControlLabel value="2" control={<Radio />} label="Authorized" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    
                    {slide.Visitor === 2 &&
                        <Grid item xs={12} sm='auto'>    
                            {roles.map((role) => (
                                <Chip className={classes.role} 
                                    key={role}                                    
                                    disabled={slide.Visitor !== 2}
                                    label={role} variant="outlined"
                                    size="small" icon={<CheckCircleOutlineIcon />}
                                    onClick={() => onAddRole(role)} />
                            ))}
                        </Grid>    
                    }
                </Grid>                
            </Box>
            
            {slide.Visitor === 2 &&
            <Alert icon={false} variant="outlined" severity="info">
                <div>Available for roles: { slide.Roles.length === 0  && <b className={classes.lbInfo}>Nobody</b>}</div>
                {slide.Roles.length > 0 && 
                    <Chip className={classes.role}
                    label='Clear all' variant="outlined"
                    color="secondary" size="small" icon={<HighlightOffIcon />}
                    onClick={onClearAll} />
                }

                {slide.Roles.map((role) => (
                    <Chip color='primary' className={classes.role} key={role}
                        label={role} variant="outlined"
                        size="small" icon={<HighlightOffIcon />}
                        onClick={() => onDeleteRole(role)} />
                    ))
                } 
            </Alert>
            }            
        </Paper>

        {countries && 
        <Paper className={classes.paper} elevation={0}>
            <Box mb={1} pl={1} pr={1}>
                <Typography 
                    component='span' 
                    style={{marginRight: 16}} >
                        Available for countries:
                </Typography>
                <Chip variant='outlined'
                    size='small'
                    className={classes.chip}
                    label='Check all' color="primary"
                    icon={<DoneAllIcon />}
                    onClick={handleAddAllCountries}
                />
                <Chip variant='outlined'
                    size='small'
                    label='Uncheck all' color="secondary"
                    className={classes.chip}
                    icon={<span className={clsx(classes.chipIcon, "material-icons")} >remove_done</span>}
                    onClick={handleClearCountries}
                />                                
            </Box>
            
            
            {countries && 
                <FormGroup row>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {/* <FormLabel component="legend">Available in the country</FormLabel> */}
                        {
                            countries.map((country) => (                    
                                <FormControlLabel className={classes.formControlLabel} key={country.TwoLetterISORegionName}
                                    control={   <Checkbox 
                                                    name={country.TwoLetterISORegionName} 
                                                    checked={checkedCountry(country.TwoLetterISORegionName)} 
                                                    onChange={handleChangeCountry}
                                                />
                                            }
                                    label={<>{countryToFlag(country.TwoLetterISORegionName)} {country.Name}</>}
                                />
                        ))
                        }
                    </FormControl>
                </FormGroup>
            }
            
            
        </Paper>
        }
        
        <Dialog 
            onClose={handleClosePreviewImg} 
            aria-labelledby="dialog-img" 
            open={showImgPreview} 
            fullWidth={true} 
            maxWidth="lg" 
        >
            <DialogTitle id="dialog-img" onClose={handleClosePreviewImg}> 
                Media › {imgPreview}
            </DialogTitle> 
            <DialogContent style={{ minHeight: 500}}>
                <PreviewMedia src={`${host}${imgPreview}`} caption={slide['Caption']} text={slide['Text']} showText={slide['ShowText']} />
            </DialogContent>
            
            <DialogActions>
                <Button variant='outlined'  onClick={handleClosePreviewImg}>Cancel</Button>
            </DialogActions>

        </Dialog>

        <Dialog onClose={handleFileDlgClose} aria-labelledby="dialog-title-files" open={openFileDlg} fullWidth={true} maxWidth="lg" >
            <DialogTitle id="dialog-title-files" onClose={handleFileDlgClose} >Select file</DialogTitle>
            <DialogContent  style={{height: 600}}>
                    <Files onSelect={onSelectFile} />
                </DialogContent>
            <DialogActions>
                <Button variant='outlined' autoFocus onClick={handleFileDlgClose} color="default">{t("buttons.Close")}</Button>
            </DialogActions>
        </Dialog>


    </div>
  )
});

SlideProps.propTypes = {
    slide: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Caption: PropTypes.string.isRequired,
        Url: PropTypes.string.isRequired,
        Text: PropTypes.string.isRequired,
        Path: PropTypes.string.isRequired,
        // Img: PropTypes.string.isRequired,
        FullPath: PropTypes.string.isRequired,
        FullPathMobile: PropTypes.string.isRequired,
        IsAuthenticated: PropTypes.bool.isRequired,
        Roles: PropTypes.arrayOf(PropTypes.string),
        Hide: PropTypes.bool.isRequired,
        ShowText: PropTypes.bool.isRequired,
        ShowFrom: PropTypes.string.isRequired,
        ShowTo: PropTypes.string.isRequired,
        ButtonText: PropTypes.string.isRequired,
        BgColorTexBox: PropTypes.string,
        PosStack: PropTypes.number,
        Visitor: PropTypes.number.isRequired,        
        LocalValues: PropTypes.arrayOf(
            PropTypes.shape({
                Id: PropTypes.number.isRequired,
                Caption: PropTypes.string.isRequired,
                Text: PropTypes.string.isRequired,
                ButtonText: PropTypes.string.isRequired,
                TwoLetterISOLanguageName: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            Code: PropTypes.string.isRequired,
            CurrencyId: PropTypes.string.isRequired,
            Id: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            TwoLetterISORegionName: PropTypes.string.isRequired,
        })
    ).isRequired,
    langs: PropTypes.arrayOf(
        PropTypes.shape({
            EnglishName: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            NativeName: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default SlideProps;