import React, { useState, useEffect, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles, alpha } from "@material-ui/core/styles";
import { Document, Page,  pdfjs } from "react-pdf";
import { useWidth} from '../../helpers/utils';
import { AppBar, Toolbar, Box, IconButton, Link, 
    Typography, InputBase } from "@material-ui/core";
import clsx from "clsx";
import {FirstPage, LastPage, NavigateNext, NavigateBefore, ZoomIn, ZoomOut, 
        AspectRatio, GetApp } from '@material-ui/icons';
import HeightIcon from '@material-ui/icons/Height';


import pdfjsWorker from "/node_modules/pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
};

const useStyles = makeStyles((theme) =>
    createStyles({        
        root: {
            flexGrow: 1,

        },
        pages: {
            display: 'flex',
            position: 'relative',
            // minHeight: 600
        },
        page: {
            // padding: theme.spacing(1/4),
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        appbar: {
            // boxShadow: theme.palette.ambilight.boxshadow,
            marginBottom: theme.spacing(1/2)
        },
        toolbar: {            
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(1),
        },
        menuButton: {
            marginRight: theme.spacing(1),            
        },
        pageof: {
            margin: theme.spacing(0, 2),
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(0, 1),
            },            
        },
        scrollingWrapper: {            
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            overflow: 'auto',
            padding: theme.spacing(0,1),                    
        },
        control: {
            display: 'flex',            
            backgroundColor: 'transparent',
            border: 'none',
            padding: theme.spacing(3, 0),
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            top: 0,
            zIndex: 10
        },
        controlStart: {
            position: 'absolute',
            insetInlineStart: 0
        },
        controlEnd: {
            position: 'absolute',
            insetInlineEnd: 0
        },

        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.black, 0.05),
            '&:hover': {
              backgroundColor: alpha(theme.palette.common.black, 0.1),
            },
            // marginRight: theme.spacing(2),
            margin: theme.spacing(0, 2),
            
            // width: '100%',
            [theme.breakpoints.up('sm')]: {
              // marginLeft: theme.spacing(3),
              // width: theme.spacing(15),
              // padding: theme.spacing(0, 1),
            },
          },
          inputRoot: {
            color: 'inherit',
          },
          inputInput: {
            padding: theme.spacing(1),
            // vertical padding + font size from searchIcon
            // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            // paddingLeft: theme.spacing(2),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '5ch',
            },
          },
        iconWidth: {
            transform: "rotate(90deg)",
        },
        twopages: {
            transform: "rotate(90deg)",
        },


    })
);

const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
  
    React.useEffect(() => {
      const handleResize = () =>
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return windowSize;
};

const sensitive = 150;

const PdfViewer = (props) => {
    const {url, page, callbackPageChange} = props;    
    const classes = useStyles();
    const widthBrk = useWidth();
    const { width, height } = useWindowSize();
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(page);
    const [pageScale, setPageScale] = useState(widthBrk === 'xs' ? 1 : 0.5 );
    const [pageHeight, setPageHeight] = useState( height);
    const [pageWidth, setPageWidth] = useState(width);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [showTwoPage, setShowTwoPage] = useState(false);
    const [minHeight,  setMinHeight] = useState(200);
    // const containerRef = useRef();
    const docRef = useRef();

    const moveSliderRight = () => {
        changePage(+1);
    };

    const moveSliderLeft = () => {
        changePage(-1);
    };

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };
    
    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }
    
    const handleTouchEnd = () => {
        if (touchStart - touchEnd > sensitive) {
            // do your stuff here for left swipe
            moveSliderRight();
        }
    
        if (touchStart - touchEnd < -1*sensitive) {
            // do your stuff here for right swipe
            moveSliderLeft();
        }
    }
    
    function onDocumentLoadSuccess({ numPages }){
        if(numPages > 0){
            setNumPages(numPages);
            let p = 1;
            if(page){
                p = parseInt(page);
            }
            setPageNumber(p);

            const pages = [p, p];            
            loadPageItems(pages);            
        }
    };

    const calculateMinHeight = () => {
        const pn = pageNumber - 1;
        console.log(docRef.current.pages.current[pn].clientHeight);

        if(docRef && docRef.current.pages.current && docRef.current.pages.current[pn]){            
            setMinHeight(docRef.current.pages.current[pn].clientHeight )
        }        
    };

    const changePage = (offSet) => {
        
        let _pageNum = pageNumber + offSet;
        
        if(_pageNum > numPages) return;
        if( _pageNum < 1 ) return;
                
        if(pageNumber > 1 && showTwoPage){
            if(offSet > 0)
                _pageNum++;
            else
            _pageNum--;
        }

        const p = Math.max(1, _pageNum);

        setPageNumber(p);
        
        if(callbackPageChange){
            const pages = [parseInt(p)];
            
            if(showTwoPage)
                pages.push( parseInt(p) + (parseInt(p % 2) === 0 ? 1 : 0) );
            loadPageItems(pages);            
        }

        calculateMinHeight();

        // if(callbackPageChange){            
        //     callbackPageChange(_pageNum,  showTwoPage &&  _pageNum <  numPages ? (_pageNum + 1) : undefined);
        // }
    };

    const loadPageItems = useCallback((pages)=>{
        if(!callbackPageChange)
            return;
        
        callbackPageChange(pages);
    }, [callbackPageChange]);
    
    const changePageBack = () => {
        changePage(-1)
    };

    const changePageNext = () => {
        changePage(+1)
    };

    const goToPage = (page) => {
        if(page > 0 && page <= numPages ){            
            if(!showTwoPage){
                setPageNumber(page);
                return;
            } 

            let _page = page - (page % 2);
            if(_page < 1){
                _page = 1;
            }

            setPageNumber(_page);
        }
    };

    const zoomPage = (aspect) => {
        if(pageScale + aspect > 0 && pageScale + aspect <= 5  ){
            setPageScale(pageScale => pageScale + aspect);
        }
        calculateMinHeight();     
    };

    const zoomIn = () => {        
        zoomPage(+0.5);
        calculateMinHeight();
    }

    const zoomOut = () => {        
        const zoom = pageScale / 2;
        const scale = Math.max(0.125, zoom);

        zoomPage(-1 * scale);
        setMinHeight(minHeight * scale);
    }

    const zoomFullWidth = () => {        
        setPageScale(showTwoPage ? 0.5 : 1);
        calculateMinHeight();
    }

    const zoomDefaultHeight = () => {
        setPageScale(0.5);
        calculateMinHeight();
    }
    
    const changeTwoPages = () => {
        setShowTwoPage(prevShowTwoPage => !prevShowTwoPage);
        
        if(!showTwoPage && pageScale >= 1){
            setPageScale(0.5);
        }
        changePage(0);
        calculateMinHeight();
    };

    
    // Change the page height, width
    useEffect(() => {
        let d = 1.7;
        switch (widthBrk) {
            case "lg":                
                setPageHeight(height);
                if(pageScale > 1){
                    d = 1.5;
                }
                setPageWidth(width / d);
                break;
            case "md":                 
                setPageHeight(height - 100);
                setPageWidth(width / d);
                break;
            case "sm":
                setPageHeight(height - 100);
                setPageWidth(width / d);
                break;
            case "xl":
                setPageHeight(height);
                setPageWidth(width / d);
                break;
            case "xs":                
                setPageHeight(height / 2 );
                setPageWidth(width - 4);
                break;
            default:                
                setPageHeight(height - 100);
                setPageWidth(width / d);
                break;
        }
        
    }, [height, pageNumber, pageScale, width, widthBrk]);

    const w = 18;

  return (
    <AppConsumer>
        {() => (
            <div className={classes.root} 
                onTouchStart={e => handleTouchStart(e)}
                onTouchMove={e => handleTouchMove(e)}
                onTouchEnd={e => handleTouchEnd(e)}
            >
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0}>
                    <Toolbar  className={classes.toolbar} >
                        {widthBrk !== "xs" &&
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="first page"
                            onClick = {()=>goToPage(1)}
                        >
                            <FirstPage />
                        </IconButton>
                        }                    
                        {widthBrk !== "xs" &&
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="before page"
                            disabled={!Boolean(pageNumber > 1)}
                            onClick={changePageBack}
                        >
                            <NavigateBefore />
                        </IconButton>                    
                        }
                        
                        <div className={classes.search}> 
                            <InputBase
                                value={pageNumber}
                                onChange={(e) => goToPage(parseInt(isNaN(e.target.value) ? 1 : parseInt(e.target.value))) }
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            inputProps={{ 'aria-label': 'page_number' }}
                            />
                        </div>
                        
                        {widthBrk !== "xs" &&
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="next page"
                            disabled={!Boolean(pageNumber < numPages)}
                            onClick={changePageNext}
                        >
                            <NavigateNext />
                        </IconButton>
                        }

                        {widthBrk !== "xs" &&
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="last page"
                            onClick = {()=>goToPage(numPages)}
                        >
                            <LastPage />
                        </IconButton>
                        }

                        {widthBrk !== "xs" &&
                            <Typography variant='body2' className={classes.pageof} >
                                {pageNumber} of {numPages}
                            </Typography>
                        }
                        
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="zoom in page"
                            onClick={zoomIn}
                        >
                            <ZoomIn />
                        </IconButton>
                        
                        <IconButton                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="zoom out page"
                            onClick={zoomOut}
                        >
                            <ZoomOut />
                        </IconButton>
                        
                        {!showTwoPage && 
                        <IconButton
                            title='Width 100%'                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="fit to width"
                            onClick={zoomFullWidth}
                        >
                            <HeightIcon className={classes.iconWidth} />
                        </IconButton>
                        }

                        <IconButton
                            title='fiit to screen'                            
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="fit to scale"
                            onClick={zoomDefaultHeight}
                        >
                            <AspectRatio />
                        </IconButton>
                        
                        <IconButton  
                            title={showTwoPage ? 'Two pages': 'Single page'}                          
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="view pages"
                            onClick={changeTwoPages}
                        >
                            {/* {showTwoPage ? <ViewAgendaOutlinedIcon className={classes.twopages} /> : <ViewCarouselOutlinedIcon />}  */}
                            {showTwoPage ? <span className="material-icons">auto_stories</span> :   <ViewCarouselOutlinedIcon />} 
                        </IconButton>

                        <IconButton                            
                            color="inherit"
                            aria-label="download pdf"
                            component={Link}
                            href={url}
                            download={url.replace(/^.*[\\\/]/, '')}
                        >
                            <GetApp />
                        </IconButton>
                        {/* <span>
                            minHeight: {minHeight}
                        </span> */}
                    </Toolbar>            
                </AppBar>
                
                <Box className={classes.scrollingWrapper} style={{ minHeight }}>
                    <Document
                        ref={docRef}
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={console.error}
                        options={options}
                    >
                        <div className={classes.pages}>
                            <div className={classes.page}>
                                <Page 
                                    pageNumber={pageNumber}
                                    // renderTextLayer={false}
                                    // renderAnnotationLayer={false}                                
                                    scale={pageScale}
                                    width={ Boolean(width) ? width - w : 300 }
                                />
                            </div>
                            {showTwoPage && pageNumber > 1 && pageNumber < numPages && 
                            <div className={classes.page}>
                                <Page 
                                    pageNumber={pageNumber + 1}
                                    scale={pageScale}
                                    width={ Boolean(width) ? width - w : 300 }
                                />
                            </div>
                            }                            
                        </div>                        
                    </Document> 
                        <div 
                                className={clsx(classes.control, classes.controlStart)} 
                                disabled={!Boolean(pageNumber > 1)}
                                onClick={changePageBack}
                            >
                                <IconButton 
                                    disabled={!Boolean(pageNumber > 1)}
                                    color='secondary'                                    
                                >
                                    <ArrowBackIosIcon />
                                </IconButton>
                                
                        </div>
                        <div 
                            className={clsx(classes.control, classes.controlEnd)} 
                            disabled={!Boolean(pageNumber < numPages)}
                            onClick={changePageNext}
                        >
                            <IconButton 
                                color='secondary'
                                disabled={!Boolean(pageNumber < numPages)}               
                            >
                                <ArrowForwardIosIcon /> 
                            </IconButton>
                        </div>
                    
                </Box>
                {/* <div>pageScale: {pageScale} widthBrk: {widthBrk}</div> */}
            
            </div>
    )}
    </AppConsumer>    
  )
}

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired,
    page: PropTypes. number.isRequired,
    callbackPageChange: PropTypes.func
}

export default PdfViewer;
