import React, {useState} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
// import { useTranslation } from "react-i18next";

import {Box, Typography, IconButton, Grid} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SSlide from './SSlide';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      flexGrow: 1,      
    },    
    button: {      
    },
    stepper: {
      padding: theme.spacing(0)      
    }
}));

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {    
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

function getBlocks(width) {  
    switch (width) {
      case 'xs':
        return 2;         
      case 'sm':
        return 3;           
      case 'md':
        return 4; 
      case 'lg':
        return 6;
      case 'xl':
        return 12;
      default:
        return 2;        
    }    
}

const SmCarousel = (props) => {
  const {id, className, onClick, slides} = props;
  // const { t } = useTranslation();  
  const classes = useStyles();
  const width = useWidth();
  const [activeStep, setActiveStep] = useState(0);

  const blocks = getBlocks(width);
  const swiViews = Math.floor(slides.length / blocks) +  (slides.length % blocks > 0 ? 1 : 0);
  // console.log(blocks, swiViews)

  const pages = [];
  for (let i = 0; i < swiViews; i++) {      
    const cols = [];
    for (let y = 0; y < blocks; y++) {
      let index = i * blocks + y;            
      if(index +1 > slides.length) break;
      cols.push(slides[index]);  
      
    }
    pages.push(cols);
  }

  const handleNext = (e) => {
    e.stopPropagation();
    if(pages.length > activeStep + 1){
        setActiveStep(activeStep + 1);
    }
  };

  const handleBack = (e) => {
    e.stopPropagation();
    if(0 <= activeStep - 1){
        setActiveStep(activeStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div id={id} className={clsx("jr-carousel", className, classes.root)} onClick={onClick}>      
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={5000000}
          style={{position: "relative"}}
        >
         {/* {pages.map((page, idx) =>
          <Grid 
            key={idx}
            container             
            spacing={1} 
            justifyContent="center"
            alignItems="center" 
            >
            {page.map((col, ydx) => 
            <Grid key={ydx} item xs={12} sm={6} md={4} lg={2} xl={1}>
              { col &&  <SSlide slide={col} /> }              
            </Grid>)}
          </Grid>)}   */}

          {pages.map((page, idx) => <Box p={1}  key={idx}>
          <Grid  container spacing={1} justifyContent="center" alignItems="center" >
            {page.map((col, ydx) => <Grid key={ydx} item xs={6} sm={3} md={4} lg={2} xl={1}>
              { col &&  <SSlide slide={col} /> }              
            </Grid>)}
          </Grid></Box>)}  

        </AutoPlaySwipeableViews> 

        <MobileStepper
          className={classes.stepper}
          steps={pages.length}
          position="static"
          activeStep={ activeStep }
          nextButton={
          <IconButton size='small' className={classes.button} onClick={handleNext} disabled={activeStep === pages.length - 1}>
              {/* {t("buttons.Next")} */}
               <KeyboardArrowRight />            
          </IconButton>
          }

          backButton={
          <IconButton size='small' className={classes.button} onClick={handleBack} disabled={pages.length === 0}>            
            {/* {t("buttons.Back")}  */}
            <KeyboardArrowLeft />
          </IconButton>
          }
        />
        
        {slides.length === 0 && 
          <Typography variant="h5" style={{padding: 16, display: 'block', width: '100%', textAlign: 'center'}}>Carousel</Typography>
        }
    </div>
  )
}

SmCarousel.propTypes = {
  className: PropTypes.string,
  interval: PropTypes.number,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,      
    })
  ).isRequired,
  onClick: () => null
}

export default SmCarousel