import React from "react";
import {Box, Typography, Paper, Button, IconButton, Grid } from "@material-ui/core";

import BigCarousel from './BigCarousel';
import MdCarousel from './MdCarousel';
import SmCarousel from './SmCarousel';

import Media from "./Media";
import CButton from "./CButton";
// import uuidv4 from "uuid/v4";
import "../../styles/style.css";


const KeysToComponentMap = {  
  Typography: Typography,  
  Paper: Paper,
  Button: Button,
  IconButton: IconButton,
  Box: Box,
  Grid:  Grid,
  "BigCarousel": BigCarousel,
  "MdCarousel": MdCarousel,
  "SmCarousel": SmCarousel,
  "Media": Media,
  "CButton": CButton
};

const Components = (item, onClick) => {    
  const handleClick = (e, node) => {      
      if(onClick){
        onClick(e, node);
        e.stopPropagation();
    }
  }

  if(!item.props){
    item.props = {className:'cc'};
    item.props = {id: item.Id};
  }else{    
      item.props.className = 'cc';
      item.props.id = item.Id;    
  }

  

  if (typeof KeysToComponentMap[item.component] !== "undefined") {     
    return React.createElement(
      KeysToComponentMap[item.component],
      { key: item.Id, ...item.props, onClick: (e) => handleClick(e, item) },
      item.children && (typeof item.children === "string" ? item.children : item.children.map(child => Components(child, onClick)))
    );
  }
  
  return React.createElement(
    item.component,
    { key: item.Id, ...item.props, onClick: (e) => handleClick(e, item) },      
    item.children && (typeof item.children === "string" ? item.children : item.children.map(child => Components(child, onClick)))
  );   

  // return React.createElement(
  //   () => <div className="unknown" >The component <b>{`<${item.component}>`}</b> has not been created yet.</div>,
  //   { key: item.Id, ...item.props }
  // );
};

export default Components;
