
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
// import Autocomplete from "@material-ui/lab/Autocomplete";

// eslint-disable-next-line no-unused-vars
import {InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },    
    button: {
      marginRight: theme.spacing(1)
    },
}));



const ImgEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [src, setSrc] = useState(node.props && node.props.src ? node.props.src: '');
      
  const handleUpdate = () => {
    if(onUpdate){
      component.props.src = src;
      onUpdate(component);
    }
  };

  const handleChangeSrc = (e) => {
    setSrc(e.target.value);
    
    // component.props.color = e.target.value;
    // setComponent({...component});
  };

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={classes.root}>
        {component && 
            <> 
            <Grid container spacing={1} style={{marginTop: 8}}>                                          
              {typeof component.props.src && 
                <Grid item xs={12}>
                  <TextField 
                      variant="outlined"
                      fullWidth
                      margin="danse"
                      size="small"
                      aria-label="img-src" placeholder="Src"
                      value={src}
                      onChange={handleChangeSrc}
                      />
                </Grid>
              }              
              <Grid item xs={12}>
                  <Button 
                    fullWidth 
                    size="small" 
                    variant="outlined" 
                    color='primary' 
                    onClick={handleUpdate} startIcon={<CheckIcon />}>Applay</Button>
              </Grid>              
            </Grid>
            
            <Grid container spacing={1} style={{ martinTop: 16 }} >
                <Grid item xs={12}>
                  <Typography display="block" align="center" variant="caption" gutterBottom>Preview</Typography>
                  {src !== '' &&  
                    <img alt="Preview" src={src} style={{maxWidth: "100%"}} />
                  }
                </Grid>
            </Grid>                                  
            </>
        }
    </div>
  )
}

ImgEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default ImgEditor