const HTMLBLOCKTYPE = 0;
const BIGSLIDERTYPE = 1;
const MIDDLESLIDERTYPE = 2;
const SMALLSLIDERTYPE = 3;
const LOGOIMAGETYPE = 4
const IMAGETYPE = 5;
const WGBONUSENEWCOMER = 6;
const PDFVIEWER = 7;


const BlockType = {
    HTMLBLOCK: 0,
    BIGSLIDER: 1,
    MIDDLESLIDER: 2,
    SMALLSLIDER: 3,
    LOGOIMAGE: 4,
    IMAGE: 5,
    WGBONUSENEWCOMER: 6,
    PDFVIEWER: 7,
    VIDEO: 8,
    VIDEOLINK: 9,
    CONTAINER: 10,
    TITLE: 11,
    BUTTON: 12,
    COLUMNS: 13,
    COLUMN: 14,
    TEXTTEXT: 15,
    IMAGETEXT: 16,
    VIDEOTEXT: 17,
    SHORTVIDEO: 18
}

export { BlockType, 
        HTMLBLOCKTYPE, BIGSLIDERTYPE, MIDDLESLIDERTYPE, SMALLSLIDERTYPE, 
        LOGOIMAGETYPE, IMAGETYPE, WGBONUSENEWCOMER, PDFVIEWER };