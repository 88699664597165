import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";

// import AuthService from "../helpers/authService";

// import * as RootReducer from "./RootReducer";
import * as Counter from "./Counter";
import * as WeatherForecasts from "./WeatherForecasts";
import * as ListContents from "./ListContents";
import * as NavItems from "./NavItems";
import * as ContentEditor from "./ContentEditor";
import * as MenuBuilder from "./MenuBuilder";
import * as FilesBuilder from "./FilesBuilder";
import * as Catalog from "./Catalog";
import * as Search from "./Search";
import * as Order from "./Order";
import * as Orders from "./Orders";
import * as Register from "./Register";
import * as Promotions from "./Promotions";
import * as LandingPage from "./LandingPage";
import * as Goods from "./Goods";

// import * as Auth from './Auth';

export default function configureStore(history, initialState) {
  // const authService = new AuthService();  
  const reducers = {
    
    // rootReducer: RootReducer.reducer,
    appLayout: NavItems.reducer,    
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    listContents: ListContents.reducer,    
    contentEditor: ContentEditor.reducer,
    menuBuilder: MenuBuilder.reducer,
    filesBuilder: FilesBuilder.reducer,
    catalogRequest: Catalog.reducer,
    searchRequest: Search.reducer,
    orderRequest: Order.reducer,
    ordersRequest: Orders.reducer,
    register: Register.reducer,
    //catalogItemRequest: CatalogItem.reducer,
    promotionItemsRequest: Promotions.reducer,
    // landing: Landing.reducer,
    // landingEditor: LandingEditor.reducer,
    goodsItemsRequest: Goods.reducer,
    landingPage: LandingPage.reducer,
    // userManager
    // auth: Auth.reducer
  };
    
  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  // eslint-disable-next-line no-undef
  const isDevelopment = process.env.NODE_ENV === "development";
  if (isDevelopment && typeof window !== "undefined" && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
}
