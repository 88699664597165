import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";
import { duration } from "moment";

const requestSearch = "REQUEST_SEARCH";
const receiveSearch = "RECEIVE_RECIVE";
const initialState = { 
  response: {     
    CatalogItems: null
  },
  query: '',
  page: 1,  
  size: 12,
  lang: '',
  country: '',
  isLoading: false 
};

export const actionCreators = {
    requestSearch: (query, page, size, user, country, lang) => async (dispatch, getState) => {
        
        if(!query || query.length === 0 || query === '')
            return;
      
        const state = getState();
                        

        if (query === state.searchRequest.query &&            
            lang === state.searchRequest.lang  &&
            country === state.searchRequest.country 
        ) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }

        if(query.length === 0){      
            return;
        }
    
        dispatch({ type: requestSearch, query, page, size, lang, country });

        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
                
        const url = `/api/v1/CatalogItem/Search/${query}/${country}/${lang}/false`;
        // console.log('search', url);

        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    // console.log('data', data)
                    
                    dispatch({ type: receiveSearch, query, page, size, lang, country, result: data });    
                })
        .catch((error) => {
            console.log('Error', error);
            dispatch({ type: receiveSearch, query, page, size, lang, country, initialState  }); 
        });        
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
  
    if (action.type === requestSearch) {
      return {
        ...state,
        query: action.query,
        page: action.page,
        size: action.size,
        lang: action.lang,
        country: action.country,
        isLoading: true
      };
    }
  
    if (action.type === receiveSearch) {       
      return {
        ...state,
        query: action.query,
        page: action.page,
        size: action.size,
        lang: action.lang,
        country: action.country,
        response: action.result,
        isLoading: false
      };
    }
  
    return state;
};
  