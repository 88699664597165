
import React, {useState, useEffect } from "react";
import PropTypes from 'prop-types';
import uuidv4 from "uuid/v4";

// eslint-disable-next-line no-unused-vars
import {Grow, Popper, Box, InputLabel, Checkbox, FormControlLabel, FormControl, TextField, Typography, 
        Paper, Button, ButtonGroup, Select, MenuList, MenuItem, Grid} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";

import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },    
    button: {
      marginRight: theme.spacing(1)
    },
}));

const direction = ['row', 'row-reverse', 'column', 'column-reverse'];
const justifyContent = ['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly'];
const rowSpacing = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10];
const alignItems = ['flex-start', 'center',  'flex-end', 'stretch', 'baseline'];
const numbergrids = [false, 'auto',  true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
// const min = 1;
const COLUMNS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const GridEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [ componetExt, setComponetExt] = useState('');
  const [ columns, setColumns] = useState(node.children.length);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(COLUMNS[node.children.length-1]);
          
  const updateComponent = () => {
    if(!onUpdate || !node)
      return;

      if(componetExt === 'row'){
        const length = component.children.length; 
        if(columns > length){
          for (let i = 0; i < columns - length; i++) {
            component.children.push({
              Id: uuidv4(),
              component: "Grid",
              props: {
                item: true,
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
                xl: 2
              },
              children: [
                {
                Id: uuidv4(),
                component: "span",
                children: `Column ${length+1+i}`,
              }
            ],
            });
          }
        }else if(columns < length){
          component.children = component.children.slice(0, columns);
        }
      }
      onUpdate(component);    
  };

  // const handleChangeColumns = (e) => {
  //   if (e.target.value === "") {
  //     setColumns(min);
  //     return;
  //   }
  //   const value = +e.target.value;    
  //   if (value < min) {
  //     setColumns(min);
  //   } else {
  //     setColumns(value);
  //   }
  // };
  
  const handleChangeDirection = (e) => {
    component.props.direction = e.target.value;
    setComponent({...component});  
  };

  const handleChangeRowSpancing = (e) => {
    component.props.spacing = e.target.value;
    setComponent({...component});  
  };

  const handleChangeRowAlignItems = (e) => {
    component.props.alignItems = e.target.value;
    setComponent({...component});  
  };

  const handleChangeRowJustifyContent = (e) => {
    component.props.justifyContent = e.target.value;
    setComponent({...component});  
  };

  const handleChangXS = (e) => {    
    component.props[e.target.name] = e.target.value;
    setComponent({...component});  
  }

  const handleClick = () => {
    console.info(`You clicked ${COLUMNS[columns-1]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
    setColumns(COLUMNS[index]);
    updateComponent();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // useEffect (() => {
  //   handleUpdate();
  // }, [handleUpdate, columns]);

  useEffect( () => {
    if(node){    
      if(node.component === 'Grid'){          
        setComponetExt(typeof node.props.item === 'undefined' ? 'row' : "item") ;      
      }else{
        setComponetExt(''); 
      }
      setComponent(node);
    }    
  }, [node]);
///  
  return (
    <div className={classes.root}>
        {component && 
            <Box pl={1} pr={1} pt={2}>

              {/* Grid row properties */}
              {componetExt === 'row' && 
                <Box py={2}>
                  <table style={{width: "100%"}}>
                    <tbody>
                      <tr>
                        <td>Columns</td>
                        <td>
                        <ButtonGroup size="small" variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
                          <Button onClick={handleClick}>{columns}</Button>
                          <Button
                            color="primary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        </ButtonGroup>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu">
                                    {COLUMNS.map((option, index) => (
                                      <MenuItem
                                        key={option}                                        
                                        selected={index === (columns - 1)}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Columns</td>
                        <td>                        
                          <TextField
                            size="small"
                            value={columns}                   
                            fullWidth
                            type="number" inputProps={{ min: min,  step: 1 }}
                            min={min} 
                            onChange={handleChangeColumns}
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td>Direction</td>
                        <td>
                          <FormControl size='small' variant="outlined" className={classes.formControl}>
                            <Select
                              size="small"
                              fullWidth
                              labelId="direction-label"
                              id="direction"
                              value={component.props.direction}
                              onChange={handleChangeDirection}
                              >
                              { direction.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                            </Select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>Spacing</td>
                        <td>
                          <FormControl size='small' variant="outlined" className={classes.formControl}>
                            <Select
                              size="small"
                              fullWidth
                              labelId="rowSpacing"
                              id="rowSpacing"
                              value={component.props.spacing}
                              onChange={handleChangeRowSpancing}
                              >
                              { rowSpacing.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                            </Select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>justifyContent</td>
                        <td>
                        <FormControl size='small' variant="outlined" className={classes.formControl}>
                          <Select
                            size="small"
                            fullWidth
                            labelId="justifyContent"
                            id="justifyContent"
                            value={component.props.justifyContent}
                            onChange={handleChangeRowJustifyContent}
                            >
                            { justifyContent.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                          </Select>
                          </FormControl>
                        </td>
                      </tr>
                      <tr>
                        <td>alignItems</td>
                        <td>
                        <FormControl size='small' variant="outlined" className={classes.formControl}>
                          <Select
                            size="small"
                            fullWidth
                            labelId="alignItems"
                            id="alignItems"
                            value={component.props.alignItems}
                            onChange={handleChangeRowAlignItems}
                            >
                            { alignItems.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                          </Select>
                          </FormControl>
                        </td>
                      </tr>
                    </tbody>                    
                  </table>
                </Box>
              }

              {/* Grid item properties */}
              {componetExt === 'item' && 
                <div>
                  <Typography variant="caption">Grid breakpoints</Typography>
                  <Grid container spacing={1} style={{marginTop: 8}}>
                    {breakpoints.map((brk) => {
                      return( 
                        <Grid item key={brk} xs={4}>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink id={`${brk}-label`}>{brk}</InputLabel>
                            <Select
                              labelId={`${brk}-label`}
                              size="small"
                              fullWidth                          
                              name={brk}
                              value={component.props[brk]}
                              onChange={handleChangXS}
                              >
                              { numbergrids.map((item, idx) => <MenuItem key={idx} value={item}>{`${item}`}</MenuItem>  )}                    
                            </Select>
                          </FormControl>
                        </Grid> 
                        )
                      })}                                                        
                  </Grid>
                </div>
              }

              <Box p={2}>
                <Button 
                  fullWidth 
                  size="small" 
                  variant="outlined" 
                  color='primary' 
                  onClick={updateComponent} startIcon={<CheckIcon />}
                >
                  Applay
                </Button>
              </Box>
          </Box>
        }
    </div>
  )
}

GridEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default React.memo(GridEditor);