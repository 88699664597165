
import React, { Component } from "react";
// import {withRouter} from "react-router-dom";
import { withCookies } from "react-cookie";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { AppContext } from "../../providers/appProvider";
// import { InfiniteLoader, List, WindowScroller, AutoSizer } from "react-virtualized";
// import { InfiniteLoader, AutoSizer, CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry }  from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {Card, CardMedia, CardContent,  Grid, Box, Paper, Tabs, Tab, Button, Typography, IconButton, Tooltip, TextField} from "@material-ui/core";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import { withTranslation } from "react-i18next";
import ProdPromoThumb from "../Order/ProdPromoThumb";

const ITEM_WIDTH = 400;
const ITEM_HEIGHT = 184;
const ITEM_XS_HEIGHT = 184;
const cdn = process.env.REACT_APP_HOST;


const styles = theme => ({
    root: {        
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(3, 2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(3), 
      boxShadow: theme.palette.ambilight.boxshadow
    },
    tabBox: {        
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 2),
        boxShadow: theme.palette.ambilight.boxshadow
      },
    media: {
        height: theme.spacing(60),
        [theme.breakpoints.down("sm")]: {
            height: theme.spacing(25),
        },

    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
      promoTab: {
        // padding: theme.spacing(1),
      },
      block: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
      },
      
      card: {
        margin: theme.spacing(-1),
        borderRadius: theme.spacing(0),
        boxShadow: theme.palette.ambilight.boxshadow
      },
      row: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
      },
      tabPanel: {
        padding: theme.spacing(1,0)
      },      
});

function a11yProps(index) {
  return {
    id: `promo-tab-${index}`,
    "aria-controls": `promo-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabs-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (        
          <>
          {children}
          </>        
      )}
    </div>
  );
}

function getMaxItemsAmountPerRow(width) {
  return Math.max(Math.floor(width / ITEM_WIDTH), 1);
}

function getRowsAmount(width, itemsAmount, hasMore) {
  const maxItemsPerRow = getMaxItemsAmountPerRow(width);  
  const rows = Math.ceil(itemsAmount/ maxItemsPerRow) + (hasMore ? 1 : 0);  
  return rows;
}

const RowItem = React.memo(function RowItem({prodId, item, promo, classes, disabled, style}) {  
  return (
      <div key={prodId} style={style}>
          <ProdPromoThumb  disabled={disabled}  promo={promo} item={item} qty={1}></ProdPromoThumb>
      </div>      
  );
});

class DetailPromotion extends Component {
    constructor(props) {
        super(props);      
        const list = [];
        if(props.promotion){ 
            if(props.promotion.Conditions.length > 0){
                for (let i = 0; i < props.promotion.Conditions.length; i++) {
                const cond = props.promotion.Conditions[i];
                if(cond.Products.length > 0){
                    for (let y = 0; y < cond.Products.length; y++) {
                    const item = cond.Products[y];
                    list.push( {"type": "item", "Item": item } );
                    }
                }
                }
            }
      }
            
      this.state = {
        rowsSelected:[],
        ActionByCondition: null,
        indexPromoTab: 0,
        options: {
            filterType: "textField",            
            enableNestedDataAccess: ".",                        
            download: false,
            print: false,
            viewColumns: false,
            responsive: "vertical",            
            selectToolbarPlacement: "none",
            rowsSelected:[],
            onRowSelectionChange: (rowsSelected, allRows) => {              
              this.setState({ rowsSelected: allRows });          
            },
            customToolbar: () => {
              return (
                    <React.Fragment>                
                      <Tooltip title={"Add selected"}>
                        <IconButton onClick={this.handleAddSelectedProducts}>
                          <PlaylistAddIcon  />
                        </IconButton>
                      </Tooltip>
                      
                    </React.Fragment>
              );
            }
        },
        columns: this.getActionsColums(),
        list: list,
      };
      this.infiniteLoaderRef = React.createRef();
      this.infiniteLoaderRefTwo = React.createRef();
    }

    static contextType = AppContext;

    createOrderPos = (data, promo) => {
        let price = data.Product.CatalogPrice;
        let promotionType = promo.PromotionType === 1 ? 2 : 0;
        
        switch (promo.PromotionType) {
            case 0:
            price = data.Product.CatalogPrice;
            break;
            case 1:
            price = data.Prices[0];        
            break;
            case 2:
            price = data.Prices[0];        
            break;
            default:
            price = data.Product.CatalogPrice;
            break;
        }
    
        const position = {
            ParentIdrref: data.Product.Idrref,
            ProductId: data.Product.Id,
            orderrowtype: promotionType,
            Name: data.Product.Name,
            LocalName: data.Product.Name,
            Price: price,
            CatalogPrice: data.Product.CatalogPrice,
            CatalogPricePv: data.Product.CatalogPricePv,
            Rate: data.Rate === 0 ? parseFloat(data.Product.CatalogPrice / data.Product.CatalogPricePv).toFixed(2) : data.Rate,
            Code: data.Product.Code,
            Image: data.Product.Image,
            Source: { 
            Id: promo.Id,
            Idrref: promo.Idrref,
            Name: promo.Name,
            Description: promo.Description
            }
        };
        return position;     
    }

    AddSigleToOrder = (data, qty) => {
        if(parseInt(qty) === 0){
            return;
        }
        
        const pos = this.createOrderPos(data, this.props.promotion);
        this.props.actions.addToOrder([ 
            { pos: pos, qty: parseInt(qty), orderrowtype: pos.orderrowtype } 
        ]);
    };

    getActionsColums = () => {
            const { classes } = this.props;  
            const columns =  [         
            { 
                name: "Product.Code",
                label: "Code"
            }, 
            { 
                name: "Product.Name",
                label: "Product name"
            },
            {
                name: "Product.CatalogPrice",
                label: "Price",
                options: {
                customBodyRender: (value) => (
                    <span className="price--line-through">{parseFloat(value).toFixed(2)}</span>
                )
                }
            },        
            {
                name: "Prices",
                label: "My Price",
                filter: false,
                sort: false,
                options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => (              
                    <span className={classes.colorRed}>{parseFloat(value[0]).toFixed(2)}</span>
                )
                } 
            },
            {           
                name: "Qty",
                label: "Qty",
                filter: false,
                options: {
                customBodyRender: (value, tableMeta, updateValue) => (            
                    <TextField                  
                        type="number" margin="normal"
                        inputProps={{ min: 1, max: 1000  }}
                        value={value === 0 ? "" : value}
                        onChange={event => { 
                        updateValue(event.target.value);
    
                        const ActionByCondition = {...this.props.promotion.ActionByCondition };
                        const products = ActionByCondition.Products.slice();                    
                        products[tableMeta.rowIndex].Qty = parseInt(event.target.value);                    
                        ActionByCondition.Products = products;
                        this.setState({ActionByCondition: ActionByCondition});
                        }}
                    />              
                )
                }
            },
            {
                name: "Buy",
                options: {
                filter: false,
                sort: false,
                empty: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                customBodyRender: (value, tableMeta) => {
                    return (
                    <Button                                                        
                        color="primary" variant="outlined" size="small"                   
                        onClick={ () =>  {
                            // console.log(tableMeta.rowData);
                            this.AddSigleToOrder(this.props.promotion.ActionByCondition.Products[tableMeta.rowIndex], tableMeta.rowData[4] );
                            // window.alert(`Clicked "Buy" for row ${tableMeta.rowIndex}`); 
                        }}
                        >
                        <AddShoppingCard />
                    </Button>
                    );
                }
                }
            },
            ];
            return columns;
    };

    handleChangeIndexPromoTab = (event, newValue) => {
            this.setState({ indexPromoTab: newValue});
    };

    loadMorePromoCondition = ( { startIndex, stopIndex } ) => {
        // const batchSize = stopIndex - startIndex;
        // const offset = stopIndex;
        // console.log("batchSize", batchSize, "offset", offset);

        return new Promise(resolve =>
        setTimeout(() => {
            if(startIndex &&  stopIndex){

            // for (let index = startIndex; index <= stopIndex; index++) {
            //   // itemStatusMap[index] = LOADED;
            // }
            
            resolve();
            }
        }, 2500)
        );
    };

    isPromoConditionLoaded = ( { index } ) => {      
        return Boolean( this.state.list.length[ index ] );
    };

    render() {
        const { IsAuthenticated, classes, promotion, width, t } = this.props;
        const { indexPromoTab, list } = this.state;
        const hasMore = false;
        const hasMoreTwo = false;
        
        const _width =  width === "xs" ? (window.innerWidth - 8) : (0.08 * window.innerWidth);
        const _height = (width === "xs" ? 0.9 : 0.8) * window.innerHeight;
        
        const rowCount = getRowsAmount(_width, list.length, hasMore);
        const rowCountTwo = getRowsAmount(_width,promotion.ActionByCondition.Products.length, hasMoreTwo);

        const banner = promotion.Banners && promotion.Banners.length > 0 ? `${cdn}${promotion.Banners[0].FileName}`: null;
        
        const rowRenderer = ({index, style}) => {
          const {classes} = this.props;
          const {list} = this.state;          
          const item = list[index];          
          return (            
              <RowItem 
                disabled={!IsAuthenticated} 
                promo={promotion} 
                key={item.Item.Product.Id} 
                item={item.Item} 
                prodId={item.Item.Product.Id} 
                classes={classes} 
                style={style}
              /> 
            
          )
        };

        const rowRendererTwo = ({index, style}) => {           
          const item = promotion.ActionByCondition.Products[index];                    
          return (            
              <RowItem 
                promo={promotion} 
                key={item.Product.Id} 
                item={item} 
                prodId={item.Product.Id} 
                classes={classes} 
                style={style}
              />            
          )
        };
        
        return (
            <>
                {
                    promotion && 
                    <div className={classes.root}>
                        
                        
                            <Card className={classes.card} elevation={0}>
                                <CardMedia
                                    className={classes.media}
                                    image={banner}
                                >
                                </CardMedia>
                                <CardContent>
                                    <Typography variant="h5" component="h2">                            
                                        { promotion.Localizations && promotion.Localizations.length > 0 ? promotion.Localizations[0].Name : promotion.Name }
                                    </Typography>
                                    <Typography>
                                        { promotion.Localizations && promotion.Localizations.length > 0 ? promotion.Localizations[0].Description : promotion.Description }                                        
                                    </Typography>
                                </CardContent>
                            </Card>
                        
                                                                      

                      {width === "xs" &&
                      <Box>
                        <Paper className={classes.tabBox}>
                        
                        <Tabs variant="fullWidth" value={indexPromoTab} onChange={this.handleChangeIndexPromoTab} aria-label="promotion conditions tabs">
                          { promotion.ActionByCondition.Products.length > 0 && 
                            <Tab wrapped label={ 
                              <Typography variant="caption">
                                <div>{t("order.PromotionalGoods")}</div>
                                <small>&nbsp;</small>
                              </Typography>  } {...a11yProps(0)} /> }
                          { (list.length > 0 &&  !promotion.ActionByCondition.ExcludeProducts) && 
                            <Tab wrapped label={<Typography variant="caption">
                              <div>{t("order.TermsAction")}</div>
                              <small>
                              {promotion && promotion.Conditions.length > 0 && promotion.Conditions[0].ExcludeProducts &&
                               <>{t("за винятком")}</>
                              }&nbsp;
                              </small>
                              </Typography>}
                               {...a11yProps(1)} /> }
                        </Tabs>
                        </Paper> 
                                             
                        <TabPanel className={classes.tabPanel} id="act-tab-1" value={indexPromoTab} index={0} >
                          { promotion.ActionByCondition.Products.length > 0 && 
                            <InfiniteLoader ref={this.infiniteLoaderRefTwo} itemCount={rowCountTwo} isItemLoaded={this.isPromoConditionLoaded} loadMoreItems={this.loadMorePromoCondition} >
                              {({onItemsRendered, ref}) => (                              
                                    <List
                                      className={classes.grid}
                                      ref={ref}
                                      height={_height}
                                      width={"100%"}
                                      itemCount={rowCountTwo}
                                      itemSize={ ITEM_XS_HEIGHT}
                                      onItemsRendered={onItemsRendered}
                                      noItemsRenderer={this.noItemsRenderer}
                                    >
                                    {rowRendererTwo}
                                    </List>
                              )}
                            </InfiniteLoader>
                          }
                          
                        </TabPanel>                                                

                        { (list.length > 0 &&  !promotion.ActionByCondition.ExcludeProducts) &&
                        <TabPanel className={classes.tabPanel} value={indexPromoTab} index={1}>                          
                          <InfiniteLoader ref={this.infiniteLoaderRef} itemCount={rowCount} isItemLoaded={this.isPromoConditionLoaded} loadMoreItems={this.loadMorePromoCondition} >
                            {({onItemsRendered, ref}) => (                              
                                  <List
                                    className={classes.grid}
                                    ref={ref}
                                    height={_height}
                                    width={"100%"}
                                    itemCount={rowCount}
                                    itemSize={ITEM_XS_HEIGHT}
                                    onItemsRendered={onItemsRendered}
                                    noItemsRenderer={this.noItemsRenderer}
                                  >
                                  {rowRenderer}
                                  </List>
                            )}
                          </InfiniteLoader>                        
                        </TabPanel>
                        }  
                      </Box>
                      } 
                      {width !== "xs" && 
                      <Box mt={2}>
                        <Grid container  direction="row" spacing={2}  justifyContent="center" alignItems="stretch">
                          <Grid item xs={6} >
                            <Paper className={classes.paper}>
                            <Typography variant="h6" display="block" align="center">
                              <div>{t("order.PromotionalGoods")}</div>
                              <Typography  display="block" align="center" color="textSecondary" variant="caption">&nbsp;</Typography>
                            </Typography>
                            </Paper>
                          { promotion.ActionByCondition.Products.length > 0 && 
                            <InfiniteLoader ref={this.infiniteLoaderRefTwo} itemCount={rowCountTwo} isItemLoaded={this.isPromoConditionLoaded} loadMoreItems={this.loadMorePromoCondition} >
                              {({onItemsRendered, ref}) => (                              
                                    <List
                                      className={classes.grid}
                                      ref={ref}
                                      height={_height}
                                      width={"100%"}
                                      itemCount={rowCountTwo}
                                      itemSize={ITEM_HEIGHT}
                                      onItemsRendered={onItemsRendered}
                                      noItemsRenderer={this.noItemsRenderer}
                                    >
                                    {rowRendererTwo}
                                    </List>
                              )}
                            </InfiniteLoader>
                          }
                          </Grid>

                          <Grid item xs={6}>
                          <Paper className={classes.paper}>
                            <Typography variant="h6" display="block" align="center">
                              <div>{t("order.TermsAction")}</div>                              
                              <Typography  display="block" align="center" color="textSecondary" variant="caption">&nbsp; 
                              {promotion && promotion.Conditions.length > 0 && promotion.Conditions[0].ExcludeProducts &&
                               <>{t("за винятком")}</> 
                              }
                              </Typography>
                              
                            </Typography>
                            </Paper>
                            { (list.length > 0 &&  !promotion.ActionByCondition.ExcludeProducts) &&
                              <InfiniteLoader ref={this.infiniteLoaderRef} itemCount={rowCount} isItemLoaded={this.isPromoConditionLoaded} loadMoreItems={this.loadMorePromoCondition} >
                                {({onItemsRendered, ref}) => (                              
                                      <List
                                        className={classes.grid}
                                        ref={ref}
                                        height={_height}
                                        width={"100%"}
                                        itemCount={rowCount}
                                        itemSize={ITEM_HEIGHT}
                                        onItemsRendered={onItemsRendered}
                                        noItemsRenderer={this.noItemsRenderer}
                                      >
                                      {rowRenderer}
                                      </List>
                                )}
                              </InfiniteLoader> 
                            }
                          </Grid>
                        </Grid>
                      </Box>
                      } 
                    </div>
                }
            </>
        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)(
  connect(    
    state => state.orderRequest,
    dispatch => {
      return {
        actions: bindActionCreators(Object.assign({}, actionOrderCreators ), dispatch)
      }    }
  )(withCookies(DetailPromotion)))
));
