import React, { useState, useContext} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { AppContext, AppConsumer } from "../../providers/appProvider";

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Paper, Typography, Grid,
    IconButton, CircularProgress, Menu, MenuItem, Collapse, Box, 
    Divider
} from "@material-ui/core";
import LazyLoad from "react-lazyload";
import ConfirmationDialog from './ConfirmationDialog';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CreateIcon from "@material-ui/icons/Create";
import ClearIcon from "@material-ui/icons/Clear";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { red, green } from '@material-ui/core/colors';
import { useWidth} from '../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingTop: 4
        },        
        paper: {
            width: '100%',

            marginBottom: theme.spacing(2),
        },
        paperrow: {
            fontSize: "1em",            
            marginBottom: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        row: {
            // fontSize: "1em",            
        },
        rowhead: {
            fontSize: ".8em",
            paddingBottom: theme.spacing(0),
        },
        rowactbtn: {
            margin: theme.spacing(0, 1),
        },
        header: {
            textTransform: "uppercase"
        },
        prices: {
            textAlign: "right",
        },
        center: {
            textAlign: "center",
        },
        payed: {
            color: theme.palette.success.main,
        },
        stateLbl: {
            textTransform: "uppercase",
            fontSize: ".75em",
            fontWeight: 500,
            marginTop: 4,
        },
        state: {
            borderLeft: "6px solid #c3c3c3",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
        },

        st10: {
            borderLeft: "6px solid #c3c3c3",
        },
        stClrr10: {
        },
        st30: {
            borderLeft: "6px solid #f44336",
        },
        stClr30: {
            color: "#f44336",
            fontSize: ".9em",
        },
        st50: {
            borderLeft: "6px solid #ff9800",
        },
        st50T: {
            borderLeft: "6px solid #ff9800",
            

        },
        stClr50: {
            color: "#ff9800",
            fontSize: ".9em",
            // fontSize: ".7em"
        },
        st70: {
            borderLeft: "6px solid #2196f3",
        },
        stClr70: {
            color: "#2196f3",
        },
        st150: {
            borderLeft: "6px solid #4caf50",
        },
        stClr150: {
            color: "#388e3c",
            fontSize: ".9em",
            fontWeight: 500,
        },
        discount: {
            color: red[800],
        },
        menuicon: {
            marginRight: theme.spacing(2)
        },
        
        detailBox: {
            paddingTop: theme.spacing(2),
            // backgroundColor: "#f9f9f9",
            fontSize: "0.9em",
        },
        
        bp: {
            border: "none"
        },
        colorP: {
            color: theme.palette.primary.main,
        },
        bdrbtn: {
            marginBottom: theme.spacing(1),
        },
        detail: {
            margin: theme.spacing(0, 2, 2, 2),
            borderBottom: "1px solid #e0e0e0"
        },
        detailSum: {
            borderLeft: "1px solid #e0e0e0",
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                borderLeft: "none",
            },
        },

        detailCont: {
            margin: theme.spacing(0),
            padding: theme.spacing(1, 0),
            width: "100%"
        },

        detailsrow: {
            padding: theme.spacing(2, 0)            
        },
        
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },          
        
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: 1,
            marginTop: -14,
            zIndex: 1,
        },
        fabButton: {  
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(3),            
            zIndex: 1,
        },
    })
);


const cdn = `${process.env.REACT_APP_CDN}Pictures/`;
const imgSize = "76x76";

function DraftRow(props) {
    const { row, token, onUpdate } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const width = useWidth();
    const history = useHistory();
    const app = useContext(AppContext);

    // const [detailOrder, setDetailOrder] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [anchorElOrder, setAnchorElOrder] = useState();
    const [openMenuOrder, setOpenMenuOrder] = useState(false);
    const [draft, setDraft] = useState();
    const [loading, setLoading ] = useState(false);

    const [openDlg, setOpenDlg] = useState(false);
    const [queryText, setQueryText] = useState("");
    const [queryTitle, setQueryTitle] = useState("");

    const handleClickMenuDraft = (e) => {
        setAnchorElOrder(e.currentTarget);
        setOpenMenuOrder(true);        
    }
    
    const handleCloseMenuDraft = () => {
        setAnchorElOrder(null);
        setOpenMenuOrder(false);        
    };

    const handleDeleteDraft = () => {
        handleCloseMenuDraft();
        setQueryTitle(t("orders.DeleteDraft", {number: row.Id}));
        setQueryText(t("Are you sure you want to delete this draft?"));
        setOpenDlg(true);
    };

    const dialogCloseCallBack = () => {
        if(!row)
            return;
        const url = `api/v1/order/deletedraft?id=${row.Id}`;
        fetch(url,
            {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then((response) => {
                if(response.status === 200){
                    onUpdate({"Id": row.Id});
                }
          })
            // .then(response => response.json())
            // .then(data => {
            //     if(data){
            //         console.log("DELETE DRAFT", data);
            //         onUpdate(data);
            //     }
            // })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { }); 
    };

    const handleCloseDialog = (choice) => {
        setOpenDlg(false);
        if (choice) {
            dialogCloseCallBack();
        }
    };

    const loadDraft = (id) => {
        setLoading(true);
        
        const url = `api/v1/order/get/${app ? app.lang: 'uk'}/${id}`;        
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    // console.log('Draft', data);
                    setDraft(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => {
                setLoading(false);
             });
    };

    const handleShowDetails = () => {
        const show = !showDetails;
        setShowDetails(show);
        
        if(show && !draft)
            loadDraft(row.Id);
    };

    const rowPrice = (pos, useBusinessPack) => {
        if (!draft) return 0;

        if(pos.OrderRowDetails.length > 1) {
            return parseFloat((pos.Amount - (!useBusinessPack ? pos.DiscountCdc : 0)) / pos.Qty).toFixed(2);
        } else if(pos.OrderRowDetails.length === 1){
          if(pos.OrderRowDetails[0].OrderRowPromotions.length > 1){
            return parseFloat((pos.Amount - (!useBusinessPack ? pos.DiscountCdc : 0)) / pos.Qty).toFixed(2);
          }        
          return parseFloat((pos.Amount - (!useBusinessPack ? pos.DiscountCdc : 0)) / pos.Qty).toFixed(2);
        }

        return parseFloat((pos.Amount - (!useBusinessPack ? pos.DiscountCdc : 0)) / pos.Qty).toFixed(2);
    };

    const handleEditOrder = () => {
        if (!row) return;
        history.push(`/order/${row.Id}`);
    };

    return (
        <>
        <Paper className={clsx(classes.paperrow, classes.state )} elevation={0}>
            <Grid className={classes.row} container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item >
                    <div className={classes.wrapper}> 
                        <IconButton size='small' className={classes.button} aria-label="expand row" onClick={handleShowDetails}>
                            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        {loading && <CircularProgress size={28} className={classes.buttonProgress} /> }
                    </div>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {width !== "xs" &&
                    <Grid 
                        className={classes.rowhead}
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                         <Grid item xs={1} sm={2} md={1} >{t("orders.State")}</Grid>
                         <Grid item xs={4} sm={2} >{t("orders.DocNum")}</Grid>
                         <Grid item xs={2} sm={2} >{t("orders.Date")}</Grid>
                         <Grid item align="right" xs={4} sm={2}>{t("orders.AmountWithDiscount")}</Grid>
                         <Grid item align="right" xs={4} sm={2}>{t("orders.AmountWithoutDiscount")}</Grid>
                         <Grid item align="right" xs={2}></Grid>                                                                        
                     </Grid> 
                    }
                    <Grid className={classes.row} container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">                        
                        {/* 1 row */}
                        <Grid item xs={6} sm={2} md={1} >
                            <Typography className={classes.stateLbl} display="block">
                                {t("orders.Draft")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2} >
                            {row.DocNumber}
                        </Grid>
                        {/* 2 row */}
                        <Grid item xs={12} sm={2} >
                            {moment(row.CreatedOn).format("DD-MM-YYYY")} <Typography component="small" variant="caption">{moment(row.CreatedOn).format("h:mm:ss")}</Typography>
                        </Grid>

                        <Grid item align={width === "xs" ? "left" : "right"} xs={6} sm={2}>
                            { row.AmountCatalogPrice !== 0 &&
                            <Typography variant="button" display="block">
                            {parseFloat(row.AmountCatalogPrice - row.DiscountCdc).toFixed(2)}       
                            </Typography>                          
                            }
                        </Grid>
                        <Grid item align={width === "xs" ? "left" : "right"} xs={6} sm={2}>
                            <span className="text-gray price--line-through">{parseFloat(row.AmountCatalogPrice).toFixed(2)}</span>
                        </Grid>                        

                    </Grid>
                </Grid>

                <Grid item xs={2} sm={1} style={{ textAlign: 'right' }}>
                    <IconButton size='small' className={classes.button} aria-label="order more" onClick={(e) => handleClickMenuDraft(e)}>
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
                
                {/* Details of draft */}
                <Collapse className={classes.detailCont} in={showDetails && Boolean(draft)} unmountOnExit>
                    <Box pt={0} pr={1} pb={0} pl={1} style={{minHeight: 100}}>                    
                        <Divider />
                        {draft && 
                        <Grid className={classes.detailsrow}  container spacing={1} direction="row" justifyContent="flex-start" alignItems="stretch">
                            <Grid item xs={12}>
                                <Typography style={{paddingLeft: 16, marginBottom: 16, fontWeight: 'bold'}}>                                 
                                    {t("Order details")}
                                </Typography>
                                {draft.Rows.map((pos) => (
                                    <div className={classes.detail} key={pos.Id}>
                                        <Grid  container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" >
                                            <Grid item xs={2} sm={1}>
                                                <Typography variant="body2">{pos.Code}</Typography>
                                            </Grid>
                                            <Grid item xs={2} sm={"auto"} >
                                                <LazyLoad height={76}>
                                                    <Link to={{ pathname: `/product/${pos.Code}`}} >
                                                        <img style={{ maxWidth: 51}} alt={pos.ProductCode} src={`${cdn}${imgSize}/${pos.Image}`} />
                                                    </Link>                                                                                                
                                                </LazyLoad>
                                            </Grid>
                                            <Grid item xs={8} sm={10}>
                                                <Typography color="inherit" component={Link} to={`/product/${pos.Code}`} variant="body2" gutterBottom>{pos.Name}</Typography>
                                                <Typography variant="body2" display="block" style={{fontWeight: 500}} gutterBottom>
                                                    {pos.Qty}<small>{t("qty")}</small> * {rowPrice(pos, draft.UseBusinessPack)} = {parseFloat(pos.Qty * rowPrice(pos, draft.UseBusinessPack) ).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>                                    
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        }
                    </Box>
                </Collapse>
            </Grid>
        </Paper>
        
        {/* Draft menu */}
        <Menu 
            anchorEl={anchorElOrder}
            open={openMenuOrder}            
            onClose={handleCloseMenuDraft}
        >
            <MenuItem 
                // component={Link} 
                // to={`/order/${row.Id}`} 
                onClick={handleEditOrder}
            >
                <CreateIcon className={classes.menuicon} /> 
                {t("orders.Edit")}
            </MenuItem>
            <MenuItem 
                onClick={handleDeleteDraft}>
                    <ClearIcon className={classes.menuicon} /> 
                    {t("orders.Delete")}
            </MenuItem>
        </Menu>
        
        {openDlg && 
        <ConfirmationDialog             
            classes={{
                paper: classes.dialog,
            }}
            fullWidth={true}
            open={openDlg}
            onClose={handleCloseDialog}
            title={queryTitle}
            text={queryText}
        />}
        </>
    )
}

DraftRow.propTypes = {
    token: PropTypes.string,
    row: PropTypes.shape(
        {
            Id: PropTypes.number.isRequired,
            CreatedOn: PropTypes.string,
            AmountCatalogPrice: PropTypes.number.isRequired,
            DiscountCdc: PropTypes.number.isRequired,
        }
    ).isRequired,
}

export default DraftRow
