import React, { useState, useContext, useCallback, useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import { AppContext } from "../../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import HomePageSkeleton from "./HomePageSkeleton";
import PageBlocks from "./PageBlocks";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
        },        
    })
);

const Viewer = (props) => {    
    const app = useContext(AppContext);
    const { lang, country } = app;
    const classes = useStyles();
    const { url, showSkeleton } = props;
    
    const [token, setToken] = useState(null);
    const [data, setData] = useState();
    
    const loadData = useCallback(() => {
        if(!token) return;
        if(!url) return;
                        
        fetch(`/api/v1.0/page/Read/${url}/${lang}/${country}`,
            { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => {
                if(response.status === 200){
                    response.json().then((page) => {                         
                        setData(page);                                                
                    });
                }else {
                    console.log(`${response.status} ${response.statusText}. Sorry! Not implemented yet.`)
                }                
            })
        .catch(error => {
            console.log("Error", error);
        });
    }, [country, lang, token, url]);

    useEffect(() => {
        if(!token) return;
        if(!url) return;

        loadData();
    }, [lang, loadData, url, token]);
    
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }else{
                    setToken('Empty');
                }
            });
        }

        fetchUser();
        window.scrollTo({top: -200, behavior: "smooth"});
    }, [app]);

  return (        
    <div className={classes.root}>
        { data && <PageBlocks content={data} /> }
        { !data && showSkeleton && <HomePageSkeleton /> }
    </div>
  )
}

Viewer.propTypes = {
    url: PropTypes.string.isRequired,
    showSkeleton: PropTypes.bool.isRequired
}

export default Viewer;