import React, { useState, useContext, useCallback, useEffect, useRef} from "react";
import PropTypes, { bool } from 'prop-types';
import { useParams, Link, useHistory } from "react-router-dom";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Box, Grid, AppBar, Toolbar, IconButton, Backdrop, Dialog, FormGroup,
         Typography, Badge,FormControl, Chip, RadioGroup, FormControlLabel, Radio,
         Paper, Button, TextField, InputAdornment, Checkbox, InputLabel, Select, MenuItem,
         List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse, } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { DialogActions, DialogContent, DialogTitle } from "../../CustomDialog";
import { useWidth} from '../../../helpers/utils';
import moment from "moment";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../../store/countries";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll'


import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import HtmlBlock from "./HtmlBlock";
import LogoImage from "./LogoImage";
import BonuseNewcomer from './Widgets/BonuseNewcomer';
import ContentFiles from './Widgets/ContentFiles';
import PdfBlock from "./PdfBlock";

import SliderEditor from '../../Slider/SliderEditor';
import HtmlEditor  from '../HtmlEditor/HtmlEditor';
import { roles } from '../../../definitions/roles';
import { HTMLBLOCKTYPE, BIGSLIDERTYPE, MIDDLESLIDERTYPE, 
         SMALLSLIDERTYPE, LOGOIMAGETYPE, IMAGETYPE, 
         WGBONUSENEWCOMER, PDFVIEWER } from "../../../definitions/pageblocktype";
import LinkIcon from '@material-ui/icons/Link';

import { ContentType } from '../../../definitions/contenttype';
import { ShowNotification} from '../../../definitions/shownotification';

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
        appbar: {            
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbar: {
            // padding: theme.spacing(1, 2)
        },
        toolbarButton: {
            marginRight: theme.spacing(2),
          },
        title: {
            flexGrow: 1,
            //textAlign: 'right'
        },
        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            padding: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbox: {
            marginTop: theme.spacing(2),
            minHeight: theme.spacing(30),
        },
        toolsboxitem: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(1/2),
            marginBottom: theme.spacing(1)
        },
        secondaryAction: {            
            right: theme.spacing(-2)
        },
        button: {
            marginRight: theme.spacing(1)
        },
        listitem: {
        },
        block: {            
            marginTop: theme.spacing(1),
            border: `1px dashed ${theme.palette.divider}`,
        },
        content: {
            paddingBottom: theme.spacing(2)
        },
        moveicon: {
            cursor: "move"
        },
        mr: {
            marginRight: theme.spacing(1)
        },
        dialogContent: {
            minHeight: theme.spacing(60)
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        role: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        chip: {
            marginRight: theme.spacing(1)
        },
        chipIcon: {
            fontSize: 18
        },
        formControl: {
            padding: theme.spacing(0, 1),
            width: '100%',
            display: 'block'          
        },
        formControlLabel: {
            width: 202,
            padding: theme.spacing(0,1),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'            
        },
        lbInfo: {
            fontSize: ".9em",            
            padding: theme.spacing(1/8, 1),
            marginRight: theme.spacing(1),
            backgroundColor: "#e8e8e863",
            borderRadius: "6px"
        },
        
    })
);

function GetIconBlockType(key){
    switch (key) {
        case 0:
            return <span className="material-icons">html</span>
        case 1:
            return <span className="material-icons">view_carousel</span>
        case 2: 
            return <span className="material-icons">burst_mode</span>
        case 3: 
            return <span className="material-icons">grid_view</span>    
        case 4: 
            return <span className="material-icons">wallpaper</span> 
        case 5: 
            return <span className="material-icons">wallpaper</span> 
        case 6: 
            return <span className="material-icons">widgets</span>
        default:
            return <span className="material-icons">note</span>
    }
}

function CanEdit(blockType) {
    return  blockType === HTMLBLOCKTYPE || 
            blockType === BIGSLIDERTYPE  || 
            blockType === MIDDLESLIDERTYPE || 
            blockType === SMALLSLIDERTYPE || 
            blockType === LOGOIMAGETYPE || 
            blockType === IMAGETYPE ||
            blockType === PDFVIEWER;
}

const toolsBlocks = [
    {        
        PageBlockType: HTMLBLOCKTYPE,
        Name: 'Html block',
        Action: true,
        Icon: "html"
    },
    {     
        PageBlockType: BIGSLIDERTYPE,
        Name: 'Big slider',
        Action: true,
        Icon: "view_carousel"
    },
    {
        PageBlockType: MIDDLESLIDERTYPE,
        Name: 'Middle slider',
        Action: true,
        Icon: "burst_mode"
    },
    {     
        PageBlockType: SMALLSLIDERTYPE,
        Name: 'Small slider',
        Action: true,
        Icon: "grid_view"
    },
    {
        PageBlockType: WGBONUSENEWCOMER,
        Name: 'WG bonuse newcomer',
        Action: true,
        Icon: "widgets"
    },
    {
        PageBlockType: PDFVIEWER,
        Name: 'Pdf viewer',
        Action: true,
        Icon: "picture_as_pdf"
    },

]

const getIcon = (block) => {
    const typeBlock = toolsBlocks.find( b=> b.PageBlockType === block);
    if(typeBlock){
        return typeBlock.Icon
    }else{
        return "note";
    }
}

const Content = (props) => {
    const app = useContext(AppContext);
    const history = useHistory();
    const width = useWidth();
    const classes = useStyles();
    const { t } = useTranslation();
    const { lang } = app;
    const { name } = useParams();    
    const [token, setToken] = useState();
    const [langs, setLangs] = useState([]);
    const [countries, setCountries] = useState([]);
    
    const [content, setContent] = useState();
    const [expanded, setExpanded] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [editBlockIdx, setEditBlockIdx] = useState(-1);
    const [showTools, setShowTools] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showCommonAttib, setShowCommonAttib ] = useState(false);
    const [showDelDlg, setShowDelDlg] = useState(false);

    const refContentEditor = useRef();

    // moment.locale(lang);

    const handleChange = (e) => {        
        if (e.target.type === "radio") {            
            setContent({
                ...content,
                [e.target.name]: parseInt(e.target.value)
            });
        }else if(e.target.type === "checkbox"){            
            setContent({
                ...content,
                [e.target.name]: e.target.checked
            });        
        }else{                        
            if(e.target.name === 'ContentType'){          
                const val = parseInt(e.target.value);

                setContent({
                    ...content,
                    [e.target.name]: val
                });
            }else{                
                setContent({
                    ...content,
                    [e.target.name]: e.target.value
                });
            }
        }                
    }

    const checkUniqUrl = () => {
        if(!content) return;
        console.log(content.Key)
    };

    const handleShowToolsBox = () => {
        setShowTools(!showTools);
    };

    const handleCloseDlg = () => {
        setOpenEdit(false);
        setFullScreen(false);
        setEditBlockIdx(-1);
    };

    const handleEditContent = (index) => {
        setEditBlockIdx(index);
        setOpenEdit(true);        
    };

    const AddBlock = (blockType) => {
        fetch(`/api/v1.0/page/addblock/${blockType}`, { headers: { authorization: token} })
            .then((response) => {                
                if(response.status === 200){
                    response.json().then((block) => {                        
                        if(block){
                            
                            const _content = {...content};
                            block.PageId = _content.Id;
                            block.Stack = _content.Blocks.length;
                            _content.Blocks.push(block);
                            
                            if(process.env.NODE_ENV === "development")
                                console.log('Create new block', block);
                                //console.log(_content);
                            

                            setContent(_content);
                        }
                        else{
                            alert('NULL Sorry! Not implemented yet');
                        }
                    });
                }else {
                    alert(`${response.status} ${response.statusText}. Sorry! Not implemented yet.`)
                }
                
            })
        .catch(error => {                
            console.log("Error", error);
        });
    };

    const Save = () => {
        if(!content) return;        
        setShowBackdrop(true);
        const url = content.Id === 0 ? '/api/v1.0/page/create/': `/api/v1.0/page/update/${content.Id}`;
        const method = content.Id === 0 ? "POST" : "PUT";
        
        console.log('method', method, '; url', url, 'save', content);

        fetch(url, { 
                method: method,
                headers: { 
                        authorization: token, 
                        "Accept": "application/json", 
                        "Content-Type": "application/json;charset=UTF-8" 
                },
                body: JSON.stringify(content)
            }
        )
        .then((response) => {
            if(response.status === 200){
                response.json().then((data) => {
                    console.log('After save', data);
                    setContent(data);
                    window.history.pushState(null, '', `/manage/content/${data.Key}`);
                });
            }else{
                console.log(`${response.status} ${response.statusText}`);
            }
        })
        .catch(error => {                
            console.log("Error", error);
        })
        .finally(()=> {
            setShowBackdrop(false);
        });        
    };
    
    const handleSave = () => {
        Save();
    };

    /* Save part of content */
    const handleSaveContent = () => {
        if(editBlockIdx > -1){
            refContentEditor.current.updateData();
            console.log('handleSaveContent', content);
        }
    };

    const onSaveBlockContent = (data) => {
        if(editBlockIdx > -1 && data){
            const _content = {...content};
            _content.Blocks[editBlockIdx].Content = data;
            setContent(_content);
        }
    };

    const handleCopyUrl = () => {
        if(!content) return;
        // const _url = `${window.location.protocol}//${window.location.host}/view/${content.Key}`;
        const _url = `/view/${content.Key}`;        
        navigator.clipboard.writeText(_url);        
    };

    const handleOpenUrl = () => {        
        history.push(`/view/${content.Key}`);
    };

    const handleExpandMore = (index) => {
        const exp = expanded.includes(index);
        
        if(exp){
            setExpanded(expanded => {
                // return expanded.filter((_, i) => i !== index)
                return expanded.filter(i => i !== index)
            });
        }else{
            setExpanded([
                ...expanded,
                index
            ]);
        }
    };

    const deleteBlock = (index) => {
        
        setShowDelDlg(false);

        if(editBlockIdx && editBlockIdx === index){
            setEditBlockIdx(-1);
        }

        setExpanded([]);
        setContent(
            {...content,
                Blocks: content.Blocks.filter((_, i) => i !== index)
            }
        );

    };

    const handleDelete = (index) => {
        setEditBlockIdx(index);
        setShowDelDlg(true);
    }

    const handleUnfoldMore = () => {
        setExpanded(Array.from(Array(content.Blocks.length).keys()));
    };

    const handleUnfoldLess = () => {
        setExpanded([]);
    };

    const handleSync = () => {
        loadContent();
    };

    const loadContent = useCallback(() => {
        if(!token) return;
        
        fetch('/api/v1.0/utils/getcultures', { headers: { authorization: token} })
        .then((response) => response.json())
            .then((data) => {
                if (data) {
                setLangs(data);                                   
                }
            })
       .catch((error) => {
           console.log('Error', error);
       });
       
       fetch('/api/v1.0/utils/countries', { headers: { authorization: token } })
            .then((response) => response.json())
                .then((data) => {                    
                    if (data) {                        
                        setCountries(data);
                    }
                })
        .catch((error) => {
            console.log('Error', error);
        });
        
        const url = name ? `/api/v1.0/page/getbykey/${name}`: '/api/v1.0/page/newpage' ;
        
        fetch(url,            
            { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    if (data) {                    
                        console.log('Load content', data)
                        setContent(data);
                        setExpanded(Array.from(Array(data.Blocks.length).keys()));
                    }
                })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally( () => setShowBackdrop(false) );

    }, [token, name]);

    const onDrop = ({ removedIndex, addedIndex }) => {
        const _content = {...content};        
        _content.Blocks = arrayMove(_content.Blocks, removedIndex, addedIndex);
        for (let i = 0; i < _content.Blocks.length; i++) {
            const block = _content.Blocks[i];
            block.Stack = i;            
        }                
        setContent(_content);

        //setContent(content => content.Blocks = blocks));
    };

    const onClearAll = () => {
        setContent({
            ...content,            
            "Roles": []
        });
    }

    const onDeleteRole = (role) => {
        const idx = content.Roles.findIndex(r => r === role);
        if(idx > -1){
            setContent({
                ...content,            
                "Roles": slide.Roles.filter(r => r !== role)
            });            
        }
    }

    const onAddRole = (role) => {
        const idx = content.Roles.findIndex(r => r === role);
        if(idx === -1){
            const roles = [
                ...content.Roles,
                role
            ];
            setContent({
                ...content,            
                "Roles": roles
            });
            
        }
    };

    const checkedCountry = (country) => {
        if(!content.Countries) return false;        
        
        return content.Countries.findIndex(c => c === country) > -1;
    }

    const handleChangeCountry = (e) => {
        const _content = {...content};
        const idx = _content.Countries.findIndex(c=> c === e.target.name);
                
        if(idx === -1){
            _content.Countries.push(e.target.name);
        }else{
            _content.Countries.splice(idx, 1);
        }
        setContent(_content);        
    };

    const handleClearCountries = () => {
        const _content = {...content};
        _content.Countries = [];
        setContent(_content);        
    };

    const handleAddAllCountries = () => {
        const _content = {...content};
        _content.Countries = [];
        
        for (let idx = 0; idx < countries.length; idx++) {
            const country = countries[idx];                                                
            _content.Countries.push(country.TwoLetterISORegionName);            
        }
        setContent(_content);        
    };

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }                                
            });
        }
        fetchUser();
        loadContent(name);
    }, [app, loadContent, name]);

  return (
    <AppConsumer>
        {() => (            
        <Paper className={clsx(classes.root, classes.paper)}> 
            
            <Backdrop className={classes.backdrop} open={showBackdrop} >
                    <div className="loader"></div>
            </Backdrop>
            
            <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                <Toolbar className={classes.toolbar}>
                    <IconButton edge="start" className={classes.toolbarButton} color="inherit" aria-label="menu" onClick={handleShowToolsBox}  >
                        <span className="material-icons">menu_open</span>
                    </IconButton>                                                
                    <Typography variant='h6' className={classes.title}>
                        { content && <>
                            {content.Name} : &nbsp;
                            
                            <Button 
                                color='primary' 
                                startIcon={<span className="material-icons">content_copy</span>}
                                onClick={handleCopyUrl}
                                title="Copy url"
                            >
                                <span className="material-icons">link</span>&nbsp;{content.Key}
                            </Button>

                            <Button 
                                color='primary' 
                                startIcon={<span className="material-icons">open_in_new</span>}
                                onClick={handleOpenUrl}
                                title="Copy url"
                            >
                                Open 
                            </Button>
                        </>
                        }                         
                    </Typography>         
                    <div>
                        <IconButton className={classes.mr} title="Go to list" component={Link} to='/manage/contents' >
                            <span className="material-icons">arrow_back</span>
                        </IconButton>
                        
                        <IconButton className={classes.mr} title="Save" onClick={handleSave}>
                            <SaveIcon />
                        </IconButton>

                        <IconButton className={classes.mr} title="Reload" onClick={handleSync}>
                            <SyncIcon />
                        </IconButton>

                        <IconButton className={classes.mr}  title="Expand all" onClick={handleUnfoldMore}>
                            <span className="material-icons">unfold_more</span>
                        </IconButton>

                        <IconButton className={classes.mr} title="Collapse all" onClick={handleUnfoldLess}>
                            <span className="material-icons">unfold_less</span>
                        </IconButton>
                    </div>                    
                </Toolbar>
            </AppBar>
            
            {/* Page attributes */}
            <Paper variant="outlined" style={{paddingTop: 4, paddingBottom: 4, minHeight: 64}} elevation={0}>
                {content && 
                <>                
                <List component="nav">
                    <ListItem button onClick={() => setShowCommonAttib(!showCommonAttib)}>
                        <ListItemIcon title={content.Predefined ? "Predefined page" : "Page"} > 
                            {content.Predefined ? 
                                <Badge variant="dot" color="secondary" overlap="rectangular" ><span title="Predefined page" className={clsx("material-icons")}>star</span></Badge> : 
                                <span className="material-icons">edit_document</span>}
                        </ListItemIcon>
                        <ListItemText primary={"General page attributes"} />
                        <ListItemSecondaryAction >
                            <ListItemIcon >
                                <IconButton onClick={() => setShowCommonAttib(!showCommonAttib)}>
                                    {showCommonAttib ?  <ExpandLess /> : <ExpandMore /> }
                                </IconButton> 
                            </ListItemIcon>
                        </ListItemSecondaryAction> 
                    </ListItem>
                </List>
                <Collapse className={classes.content} in={showCommonAttib} timeout="auto" unmountOnExit>
                    {content && 
                    <Box pl={2} pr={2}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    name="Name"
                                    label="Name"
                                    fullWidth
                                    variant="outlined"
                                    margin='normal'
                                    value={content.Name}
                                    onChange = {handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    name="Key"
                                    label="Key"
                                    fullWidth
                                    variant="outlined"
                                    margin='normal'
                                    value={content.Key}
                                    onChange = {handleChange}
                                    InputProps={{
                                        endAdornment: 
                                        <IconButton onClick={checkUniqUrl}>
                                            <LinkIcon />
                                        </IconButton>
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center" >
                            <Grid item xs={6} sm='auto'>
                                <TextField
                                    name="StartedOn"
                                    type="datetime-local"
                                    label="Date show from"                    
                                    placeholder="Show from"
                                    margin="normal"
                                    inputProps={{ step: 1 }}
                                    InputLabelProps={{ shrink: true }}                                
                                    // variant="outlined"
                                    value={moment(content.StartedOn).format("YYYY-MM-DDTkk:mm") }
                                    onChange = {handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm='auto'>
                                <TextField
                                    name="FinishedOn"
                                    type="datetime-local"
                                    label="Date show from"                    
                                    placeholder="Show from"
                                    margin="normal"
                                    inputProps={{ step: 1 }}
                                    InputLabelProps={{ shrink: true }}                                
                                    // variant="outlined"
                                    value={moment(content.FinishedOn).format("YYYY-MM-DDTkk:mm") }
                                    onChange = {handleChange}
                                />
                            </Grid>
                            <Grid item xs={4} sm='auto'>
                                <Box pt={2}>
                                    <FormControlLabel control={<Checkbox name="Hide" checked={content.Hide} onChange = {handleChange} />} label="Hide page" />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={3} md={2}>                           
                                <FormControl  className={classes.FormControl} fullWidth>
                                    <InputLabel                                         
                                        id="select-contenttype"
                                        aria-describedby="select-contenttype"
                                    >
                                        Content type
                                    </InputLabel>
                                    <Select
                                        labelId="select-contenttype"                                        
                                        name="ContentType"
                                        value={ content.ContentType ? `${content.ContentType}`: '0'}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value='0'>Page</MenuItem>                                        
                                        <MenuItem value='4'>Notification</MenuItem>                                        
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                        {content.ContentType === ContentType.Notification && 
                        <Paper style={{padding: 8, marginTop: 8}} elevation={0} variant='outlined'>
                            <Typography variant="subtitle1">Additional props</Typography>
                            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center" >
                                <Grid item xs={12} sm='auto'>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row
                                            aria-label="Show notification" name="ShowNotification"
                                            value={`${content.ShowNotification}`}                                        
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="Everywhere"  />
                                            <FormControlLabel value="1" control={<Radio />} label="Except" />
                                            <FormControlLabel value="2" control={<Radio />} label="Only" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField 
                                        name="DestinationUrl"
                                        label="DestinationUrl"
                                        disabled={content.ShowNotification === ShowNotification.Everywhere}
                                        fullWidth
                                        variant="outlined"
                                        margin='normal'
                                        value={content.DestinationUrl}
                                        onChange = {handleChange}
                                    />
                                </Grid>
                                
                            </Grid>
                            
                        </Paper>
                        }

                       <Paper style={{padding: 8, marginTop: 8}} elevation={0} variant='outlined'> 
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm='auto'>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row
                                            aria-label="visitor" name="Visitor"
                                            value={`${content.Visitor}`}                                        
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="All"  />
                                            <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                                            <FormControlLabel value="2" control={<Radio />} label="Authorized" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                
                                {content.Visitor === 2 &&
                                    <Grid item xs={12} sm='auto'>    
                                        {roles.map((role) => (
                                            <Chip className={classes.role} 
                                                key={role}                                    
                                                disabled={content.Visitor !== 2}
                                                label={role} variant="outlined"
                                                size="small" icon={<CheckCircleOutlineIcon />}
                                                onClick={() => onAddRole(role)} />
                                        ))}
                                    </Grid>    
                                }
                            </Grid>                
                                
                            {content.Visitor === 2 &&
                            <Alert icon={false} variant="outlined" severity="info">
                                <div>Available for roles: { content.Roles.length === 0  && <b className={classes.lbInfo}>Nobody</b>}</div>
                                {content.Roles.length > 0 && 
                                    <Chip className={classes.role}
                                    label='Clear all' variant="outlined"
                                    color="secondary" size="small" icon={<HighlightOffIcon />}
                                    onClick={onClearAll} />
                                }

                                {content.Roles.map((role) => (
                                    <Chip color='primary' className={classes.role} key={role}
                                        label={role} variant="outlined"
                                        size="small" icon={<HighlightOffIcon />}
                                        onClick={() => onDeleteRole(role)} />
                                    ))
                                } 
                            </Alert>
                            }
                        </Paper> 

                        {countries && 
                        <Paper style={{padding: 8, marginTop: 8}} elevation={0} variant='outlined'>
                            <Box mb={1} pl={1} pr={1}>
                                <Typography 
                                    component='span' 
                                    style={{marginRight: 16}} >
                                        Available for countries:
                                </Typography>
                                <Chip variant='outlined'
                                    size='small'
                                    className={classes.chip}
                                    label='Check all' color="primary"
                                    icon={<DoneAllIcon />}
                                    onClick={handleAddAllCountries}
                                />
                                <Chip variant='outlined'
                                    size='small'
                                    label='Uncheck all' color="secondary"
                                    className={classes.chip}
                                    icon={<span className={clsx(classes.chipIcon, "material-icons")} >remove_done</span>}
                                    onClick={handleClearCountries}
                                />                                
                            </Box>
                            {countries && 
                                <FormGroup row>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        {/* <FormLabel component="legend">Available in the country</FormLabel> */}
                                        {
                                            countries.map((country) => (                    
                                                <FormControlLabel className={classes.formControlLabel} key={country.TwoLetterISORegionName}
                                                    control={   <Checkbox 
                                                                    name={country.TwoLetterISORegionName} 
                                                                    checked={checkedCountry(country.TwoLetterISORegionName)} 
                                                                    onChange={handleChangeCountry}
                                                                />
                                                            }
                                                    label={<>{countryToFlag(country.TwoLetterISORegionName)} {country.Name}</>}
                                                />
                                        ))
                                        }
                                    </FormControl>
                                </FormGroup>
                            }
            

                        </Paper>
                        }

                    </Box>
                    }
                </Collapse>
                </>
                }
            </Paper>

            <Grid container direction="row" spacing={2}>
                {/* Tools box */}
                {showTools &&
                <Grid item xs={12} sm={2}>
                    <Paper className={clsx(classes.paper, classes.toolbox)} >                        
                        <Typography variant='caption' display='block' align='center'>
                            Tools box
                        </Typography>
                        <List component="nav">
                            {toolsBlocks.map( (block, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    className={classes.toolsboxitem}
                                    onClick={block.Action ? ()=>AddBlock(block.PageBlockType): null}
                                >
                                    <ListItemIcon >                                        
                                        {<span className="material-icons">{block.Icon}</span>}
                                    </ListItemIcon>
                                    <ListItemText primary={block.Name} />
                                </ListItem>
                            ))}                                            
                        </List>
                    </Paper>
                </Grid>
                }
                
                {/* Content blocks */}
                <Grid item xs={ 12 } sm={showTools ? 10 : 12}>
                    <List component="nav">
                        {content && 
                        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop} >
                        {content.Blocks.map(( block, index ) => (
                            <Draggable key={index} className={classes.block}>
                                <ListItem button onClick={() => handleExpandMore(index)} className={classes.listitem}>
                                    <ListItemIcon>                                        
                                        {<span className="material-icons">{ getIcon(block.PageBlockType)}</span>}
                                    </ListItemIcon>

                                    <ListItemText 
                                        primary={`${block.Name} ${block.Content && block.Content && block.PageBlockType !== WGBONUSENEWCOMER ? ' › ' +  block.Content.Name: "" }`} 
                                        // secondary={`[${block.Content && block.Content ?  block.Content.Id: "" }]`}  
                                    />

                                    <ListItemSecondaryAction >
                                        
                                        <ListItemIcon title="Edit block" >
                                            <IconButton disabled={!CanEdit(block.PageBlockType)} onClick={()=>handleEditContent(index)} >
                                                <span className="material-icons">mode_edit</span>
                                            </IconButton>                                    
                                        </ListItemIcon>
                                        
                                        <ListItemIcon title="Delete block">
                                            <IconButton  onClick={() => handleDelete(index)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                                                                
                                        <ListItemIcon title="Edit block" style={{marginRight: 16}}>
                                            <IconButton onClick={() => handleExpandMore(index)}>
                                                {expanded.includes(index) ?  <ExpandLess /> : <ExpandMore /> }
                                            </IconButton>                                    
                                        </ListItemIcon>                                        

                                        <ListItemIcon className={clsx("drag-handle", classes.moveicon)} title="Drag and drop me">                                    
                                            <DragHandleIcon />                                    
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                </ListItem>

                                <Collapse className={classes.content} in={expanded.includes(index)} timeout="auto" unmountOnExit>
                                    {block.Content && 
                                    <Box p={1}> 
                                        {block.PageBlockType === HTMLBLOCKTYPE && block.Content.LocalValues.length > 0 &&
                                            <HtmlBlock localValue={block.Content.LocalValues[0]} blockKey={block.Key} />
                                        }
                                        {block.PageBlockType === BIGSLIDERTYPE &&
                                            <BigSlider slider={block.Content } blockKey={block.Key} autoplay={false} /> 
                                        }
                                        {block.PageBlockType === MIDDLESLIDERTYPE &&
                                            <MiddleSlider slider={block.Content } blockKey={block.Key} /> 
                                        }
                                        {block.PageBlockType === SMALLSLIDERTYPE &&
                                            <SmallSlider slider={block.Content } blockKey={block.Key} /> 
                                        }
                                        {block.PageBlockType === LOGOIMAGETYPE && 
                                            <LogoImage content={block.Content} blockKey={block.Key} />
                                        }
                                        {block.PageBlockType === WGBONUSENEWCOMER && 
                                            <BonuseNewcomer data={block.Content.Data} blockKey={block.Key} />
                                        } 
                                        
                                        {block.PageBlockType === PDFVIEWER && block.Content.LocalValues.length > 0 &&
                                            <PdfBlock url={block.Content.LocalValues[0].Value} />
                                        } 
                                    </Box>
                                    }
                                </Collapse>
                            </Draggable>
                        ))}
                        </Container>
                        }
                    </List>
            </Grid>
            </Grid>
            
            {/* Edit page block content */}
            <Dialog aria-labelledby="dialog-content" open={openEdit} fullWidth={true} maxWidth="xl" fullScreen={fullScreen}>
                <DialogTitle id="dialog-content" onClose={handleCloseDlg} >
                    {editBlockIdx > -1 && content.Blocks[editBlockIdx] &&
                    <>
                       Block › {content.Blocks[editBlockIdx].Name}
                    </> 
                    }
                </DialogTitle>
                <DialogContent className={classes.dialogContent} dividers>
                    {editBlockIdx > -1 && content.Blocks[editBlockIdx] && content.Blocks[editBlockIdx].Content && 
                    <>
                       
                        {/* HTML block editor */}
                        { [HTMLBLOCKTYPE, LOGOIMAGETYPE].includes(content.Blocks[editBlockIdx].PageBlockType) && 
                        <HtmlEditor
                            ref={refContentEditor}
                            roles={roles}
                            token={token}
                            keyDisabled={true}
                            article={content.Blocks[editBlockIdx].Content}
                            langs={langs}
                            onSave={onSaveBlockContent}
                        />
                        }

                        {/* Sliders editor */}
                        { [BIGSLIDERTYPE, MIDDLESLIDERTYPE, SMALLSLIDERTYPE].includes(content.Blocks[editBlockIdx].PageBlockType) && 
                            <SliderEditor
                                ref={refContentEditor}
                                slider={content.Blocks[editBlockIdx].Content}
                                roles={roles}
                                langs={langs}
                                countries={countries}
                                onSave={onSaveBlockContent}
                            /> 
                        }

                        {/* PDF block editor */}
                        { [PDFVIEWER].includes(content.Blocks[editBlockIdx].PageBlockType) && 
                        <ContentFiles
                            ref={refContentEditor}
                            roles={roles}
                            token={token}
                            keyDisabled={true}
                            content={content.Blocks[editBlockIdx].Content}
                            langs={langs}
                            onSave={onSaveBlockContent}
                        />
                        }
                    </>
                    }
                </DialogContent>
                <DialogActions style={{padding: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                        
                    >                        
                        <Grid item xs={4} sm={2} md={1}>
                            <Button 
                                className={classes.button} 
                                variant='contained' 
                                fullWidth 
                                autoFocus 
                                onClick={handleSaveContent} 
                                color="primary" 
                                startIcon={<SaveIcon />}
                            >
                                {t("buttons.Save")}
                            </Button>
                        </Grid>
                        <Grid item xs='auto'>
                            <Button className={classes.button} variant='outlined' onClick={handleCloseDlg} color="default">
                                {t("buttons.Close")}
                            </Button>
                        </Grid>
                    </Grid>
                        

                    
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDelDlg}
                onClose={()=> setShowDelDlg(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">{"Delete block?"}</DialogTitle>                
                <DialogContent>
                    {editBlockIdx > -1 && content.Blocks[editBlockIdx] &&
                        <Typography>
                            Url key: {content.Blocks[editBlockIdx].Key}; Block name: {content.Blocks[editBlockIdx].Name}
                        </Typography>
                    }
                </DialogContent>
                <DialogActions>
                <Button  onClick={()=> setShowDelDlg(false)}>
                    Cancel
                </Button>
                <Button color="primary" autoFocus onClick={()=>deleteBlock(editBlockIdx)}>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

        </Paper>
        )}
    </AppConsumer>
  )
}

Content.propTypes = {}

export default Content;
