import React, { useState, useEffect, useContext, useCallback } from 'react';

import { useTranslation } from "react-i18next";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { bindActionCreators } from "redux";
import { connect, useSelector } from 'react-redux';
import { actionCreators } from "../../store/NavItems";
// import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { Container, List, ListItem,  Grid, IconButton, Typography, Snackbar } from "@material-ui/core";
import { FirstStep } from "../Start/FirstStep";
import { useWidth} from '../../helpers/utils';

import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {    
            backgroundColor: theme.palette.type === "light" ? "#132530" : "#0b0f15",    
            position: 'relative',
        },          
        list: {
            margin: 0,
            padding: 0,
            listStyle: "none"            
        },
        colhead: {            
            color: theme.palette.type === "light" ? grey[500] : theme.palette.grey[400],
        },
        link: {
            paddingBottom: theme.spacing(1/4),
            [theme.breakpoints.down("xs")]: {
                display: "block",
                textAlign: "center",
            },    
            fontSize: "0.9rem",
            padding: theme.spacing(0, 0),   
            margin: theme.spacing(0, 0),
            color: theme.palette.type === "light" ? grey[300] : theme.palette.grey[300],
        },
        
        footer: {
            
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            color: theme.palette.type === "light" ? grey[300] : theme.palette.grey[300],    
        },
        share: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(4),
            border: `1px solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.dark, // theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.type === "light" ? "#132530" : "#0b0f15",    
            [theme.breakpoints.up("sm")]: {
                right: theme.spacing(1),
            },
            // backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.type === "light" ? "#132530" : "#0b0f15",    
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
        copyright: {
            backgroundColor: grey[900]
        }
    })
);

const SubItem = ( props => {
    const { items } = props;
    const classes = useStyles();
    return (      
      <List className={classes.list}>
      { items.map((item) => (      
        <li className={classes.link} key={item.id}>
          
          { item.href.startsWith("/") ?
            <ListItem 
              className={classes.link} 
              component={Link} 
              to={item.href}
            >
             {item.title}          
            </ListItem> 
            :
            <Typography 
              className={classes.link} 
              href={item.href} 
              title={item.subtitle} 
              target={item.target} 
              variant="subtitle1" 
              component="a"
            >
            {item.title}
          </Typography>
        }
  
        <SubItem items={item.children} />
        </li>            
      ))} 
      </List>
    );
});

export const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const app = useContext(AppContext);
    const { lang, country } = app;
    // const params = useParams();
    const width = useWidth();    
    const [token, setToken] = useState(null);
    const [ changeCountry, setChangeCountry] = useState(false);
    const [ showSnackBar, setShowSnackBar] = useState(false);
    const [ footerNavItems, setFooterNavItems ] = useState([]);
    const appLayout = useSelector((state) =>  state.appLayout );
    const {Adviser} = appLayout;
    const [copyUrl, setCopyUrl] = useState();
    
    const loadData = useCallback((country, lang) => {        
        if(!token) return;
        const url = `/api/applayout/footernav/${country}/${lang}`; 
        fetch(url,
            { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => {
                if(response.status === 200){
                    response.json().then((data) => {                        
                        setFooterNavItems(data);                        
                    });
                }else {
                    console.log(`${response.status} ${response.statusText}. Sorry! Not implemented yet.`)
                }
                
            })
        .catch(error => {                
            console.log("Error", error);
        });
    }, [setFooterNavItems, token]);

    const closeDialogFirsStep = () => {
        setChangeCountry(false);
    };

    const showDialogFirsStep = () => {
        setChangeCountry(true);
        // https://localhost:5501/product/52422?ref=1100207
    };
    
    useEffect(() => {
        if(!token) return;                
        loadData(country, lang);
    },[country, lang, loadData, token]);

    const handleCopyUrl = () => {        
        

        const query = new URLSearchParams(window.location.search);
        let existRef = false;
        let count = 0;
        for (const key of query.keys()) {
            count++;
            console.log(key, count);
            if(key === "ref"){
                existRef = true;
                break;
            }
        }
        
        const url = (Adviser && !existRef) ? `${window.location.href.replace(/\/$/, "")}${(`${count > 0 ? '&' :'?'}ref=${Adviser.RegistrationCode}`)}`: 
                    window.location.href;
                     
                            
        navigator.clipboard.writeText(url); 
        setCopyUrl(url);
        setShowSnackBar(true);
    };
    
    const CloseSnackBar = () => {
        setShowSnackBar(false);
    }

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }else{
                    setToken('Empty');
                }                                
            });
        }
        fetchUser();        
    }, [app]);

    return (
        <AppConsumer>
        {() => (
            <>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: 'right' }} onClose={CloseSnackBar}
                open={showSnackBar} autoHideDuration={4000} >
                <Alert severity="info" onClose={CloseSnackBar}  >
                    <AlertTitle>{t("profile.CopyReferalLink")}</AlertTitle>
                    {copyUrl}
                </Alert>
            </Snackbar>

            <div className={classes.root}>              
                <Container maxWidth="lg" component="footer" className={classes.footer}>
                    
                    <IconButton size='medium' className={classes.share} aria-label="share-link" onClick={handleCopyUrl} >
                        <ShareIcon />
                    </IconButton>
                    

                    <Grid container spacing={2} justifyContent="center" alignItems="flex-start" >
                        { footerNavItems.map((footer) => (
                        <Grid item xs={12} sm={4} md={3} lg={2} key={footer.title}>
                            <Typography 
                                variant="subtitle1" 
                                display='block' 
                                className={classes.colhead} 
                                align={ width === "xs" ? "center": "left"}  
                                title={footer.subtitle} 
                                gutterBottom 
                            >
                                {footer.title}
                            </Typography>                
                            <SubItem items={footer.children} />
                        </Grid>
                        ))}
                    </Grid>                                                            
                
                </Container>
                {changeCountry && <FirstStep closeDialog={closeDialogFirsStep} />}
            </div>
            </>
        )}
        </AppConsumer>
    )
}

export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Footer)

