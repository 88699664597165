const requestCatalogItemsType = "REQUEST_CATALOG_ITEMS";
const receiveCatalogItemsType = "RECEIVE_CATALOG_ITEMS";
const initialState = { 
  response: { 
    ProductGroup: { LocalName: "" }, 
    CatalogItems: null, 
  },  
  grp: "",
  isLoading: false,
  activeNavItem: {title: "", key: ""},
};


const getActiveNavItem = (items, key) => {
  if( typeof(items) === "undefined" || items === null){
    return null;
  }

  if(items.length === 0){
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    const item = items[i];                    
    if(decodeURI(item.href).indexOf(key) > 0){
      return {title: item.title, key: key};      
    }    
    const find = getActiveNavItem(item.children, key);
    if(find !== null){
      return find;
    }    
  }
  return null;
}

export const actionCreators = {  
  requestCatalogItems: (grp, pi, user, country, lang ) => async (dispatch, getState) => {    
    // Access to to anothe reducer    
    // getState().otherReducer;
    // const lang  = getActiveLang( getState().appLayout.app.Langs);
    
    if(grp.length === 0){      
      return;
    }

    const state = getState();

    // console.log('requestCatalogItems', 'grp', grp, 'lang', lang, 
    //             'state.catalogRequest.grp', state.catalogRequest.grp, 
    //             'state.catalogRequest.lang', state.catalogRequest.lang);

    if (grp === state.catalogRequest.grp && state.catalogRequest.lang === lang) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }
    
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
    
    const url = `/api/v1.0/catalogitem/get/${grp}/${country ? country : 'UA'}/${lang ? lang : 'UK' }`;
    
    fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
      .then((response) => response.json())
        .then((data) => {
          dispatch({ type: receiveCatalogItemsType, grp, pi, lang, country, result: data });    
        })
        .catch((error) => {
            console.log('Error', error);
            dispatch({ type: receiveCatalogItemsType, grp, pi, lang, country, initialState  });      
        });        
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case requestCatalogItemsType:
      return {
        ...state,
        pi: action.pi,
        grp: action.grp,
        lang: action.lang,
        country: action.country,
        activeNavItem: action.activeNavItem !== null ? action.activeNavItem :{title: "", key: ""},
        isLoading: true
      };
    case receiveCatalogItemsType:
      return {
        ...state,
        pi: action.pi,
        grp: action.grp,
        lang: action.lang,
        country: action.country,
        response: action.result,      
        isLoading: false
      };
    default:
      return state;
  }


};
