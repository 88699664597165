import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({        
        title: {
            '& h2': {
                fontSize: "1.2rem",
                fontWeight: 400
            }            
        },
    })
);

function ConfirmationDialog(props) {
    const { onClose, open, title, text,  ...other } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleCancel = () => {
        onClose();
    };
    
    const handleOk = () => {
        onClose(true);
    };

  return (
    <Dialog
      maxWidth="xs"      
      aria-labelledby="confirmation-dialog-title"
      open={open}      
      {...other}
    >
        <DialogTitle className={classes.title} >
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {text}
            </DialogContentText>    
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleCancel} color="primary">
            {t("buttons.Cancel")}
            </Button>            
            <Button onClick={handleOk} color="primary">
            {t("buttons.Ok")}
            </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}

export default ConfirmationDialog
