import axios from "axios";

const requestNavItemsType = "REQUEST_NAVITEMS";
const receiveNavItemsType = "RECEIVE_NAVITEMS";
const requestCustomerType = "REQUEST_CUSTOMER";
const receiveCustomerType = "RECEIVE_CUSTOMER";

const requestBalanceType = "REQUEST_BALANCE";
const receiveBalanceType = "RECEIVE_BALANCE";

const requestLangsType = "REQUEST_LANGS";
const receiveLangsType = "RECEIVE_LANGS";



// const requestFooterNavItemsType = "REQUEST_FOOTERNAVITEMS";
// const receiveFooterNavItemsType = "RECEIVE_FOOTERNAVITEMS";

const requestApplayPromoCodeType = "REQUEST_APPLAYPROMOCODE";
const receiveApplayPromoCodeType = "RECEIVE_APPLAYPROMOCODE";
const showSnakBarFromNavBarType = "SHOW_SNACKBAR_NAVBAR";
const hideSnakBarFromNavBarType = "HIDE_SNACKBAR_NAVBAR";
const showDlgGiftCardType = "DIALOG_GIFT"; 
const setAppUserType = "SET_APP_USER";


const initialState = {
  AppSettings: {
    AppName: "",  
    Chats: [],
  },   
  LeftNavItems: [],
  GroupNavItems: [],
  Langs: [],
  FooterNavItems: [],  
  isLoading: false,  
  promocode: null,
  Adviser: null,
  user: null,
  Balances: [],
  snackbarTxt: "",
  variant: "success",
  snackbarShow: false, 
  showDlgGiftCard: false,
};

export const actionCreators = {

    setAppUser: (user) => async (dispatch, getState) => {
        dispatch({ type: setAppUserType, user });
    },

    getActiveLang: () => async (dispatch, getState) => {
        const langs = getState().appLayout.Langs;
        for (let i = 0; i < langs.length; i++) {
        const element = langs[i];
        if (element.Active) {
            return element.Name;
        }
        }
        return "";
    },

    // requestFooterNavItems: (country) => async (dispatch, getState) => {
    //     const appLayout = getState().appLayout;
    //     const user = appLayout.user;
    //     const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
    //     dispatch({ type: requestFooterNavItemsType });

    //     const url = `/api/applayout/footernav/${country}`;
                
    //     fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
    //         .then((response) => response.json())
    //             .then((data) => {    
    //                 dispatch({ type: receiveFooterNavItemsType, footerNavItems: data });
    //             })
    //             .catch((error) => {
    //                 console.log('Error', error);
    //             });
    // },

    requestLangs: () => async (dispatch) => {
            

        const url = "/api/v1.0/languege"; 
        
        fetch(url)
            .then((response) => response.json())
                .then((data) => {                    
                    dispatch({ type: receiveLangsType, langs: data });
                })
                .catch((error) => {
                    console.log('Error', error);            
                });     
    },


    requestNavItems: (country) => async (dispatch, getState) => {
        const appLayout = getState().appLayout;
        const user = appLayout.user;
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
        
        dispatch({ type: requestNavItemsType }); 

        const url = `/api/applayout/layout/${country}`; 
        // console.log('requestNavItems', url);
        
        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {                    
                    dispatch({ type: receiveNavItemsType, applayout: data });
                })
                .catch((error) => {
                    console.log('Error', error);            
                });     
    },

    applayPromoCode: (code, user) => async (dispatch, getState) => {    
        const appLayout = getState().appLayout;
        const user = appLayout.user;
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;     

        dispatch({ type: requestApplayPromoCodeType });

      const URL = `/api/v1.0/Adviser/Promocode/${code}`;
      axios
        .get(URL, { headers: { Authorization: token } })
        .then(response => {          
          // dispatch({ type: receiveApplayPromoCodeType, promocode: response.data });
          dispatch({
            type: showSnakBarFromNavBarType,
            variant: "success",
            snackbarTxt: response.data,
            showDlgGiftCard: false,
            flag: false,
            //snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        })
        .catch(error => {          
          dispatch({
            type: showSnakBarFromNavBarType,
            variant: error.response.status === 409 ?  "warning": "error",
            snackbarTxt: error.response.data,            
            showDlgGiftCard: true,
            flag: true,
            //snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });                    
        });        
  },

  hideSnackBar: () => (dispatch) => {
    dispatch({ type: hideSnakBarFromNavBarType });
  },
  
  showDlgGift: (flag) => (dispatch) =>{
    dispatch({ type: showDlgGiftCardType, flag });
  },

  getCustomer : () => async (dispatch, getState) => {                      
        const appLayout = getState().appLayout;
        const user = appLayout.user;
        
        if(!user)
            return;

        dispatch({ type: requestCustomerType });
            
        const url =  "/api/v1.0/balance/total";
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
        
        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {                    
                    dispatch({ type: receiveBalanceType, Balances: data.Balances, Adviser: data.Adviser});
                })
                .catch((error) => {
                    console.log('Error', error);            
                });        
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case setAppUserType: 
        return {
            ...state,
            user: action.user
        };    
    case requestNavItemsType:
    case requestApplayPromoCodeType: 
    case requestCustomerType:
    case requestBalanceType:
    case requestLangsType:
      return {
        ...state,
        isLoading: true
      };
    case  receiveLangsType: 
      return {
        ...state,
        isLoading: false,
        Langs: action.langs
      };
    case receiveNavItemsType:
      return {
        ...state,
        ...action.applayout,
        isLoading: false
      };
    // case receiveFooterNavItemsType:
    //   return {
    //     ...state,
    //     FooterNavItems: action.footerNavItems,
    //     isLoading: false
    //   }; 
    case receiveApplayPromoCodeType:
        return {
          ...state,
          promocode: action.promocode,
          isLoading: false
        }; 
    case receiveCustomerType:
      return {
        ...state,
        Adviser: action.Adviser,
        isLoading: false
      };
    case receiveBalanceType: 
      return {
      ...state,
      Balances: action.Balances,
      Adviser: action.Adviser,
      isLoading: false
      };
    case showSnakBarFromNavBarType: 
        return {
          ...state,
          snackbarTxt: action.snackbarTxt,
          variant: action.variant,
          snackbarShow: true,
          showDlgGiftCard: action.flag,
          isLoading: false,          
        };
    case hideSnakBarFromNavBarType:
          return {
            ...state,
            snackbarTxt: "",
            snackbarShow: false,
            variant: "success"
          }; 
    case showDlgGiftCardType: 
        return {
          ...state,
          showDlgGiftCard: action.flag
        } ;      
    default:
      return state;
  }
};
