import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import { Card,  Typography, CardActionArea, CardMedia, CardContent,  CardActions, Button } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { Link, useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';
import PropTypes from "prop-types";

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) =>
  createStyles({    
    card: {
      height: '100%',
      // minHeight: 200,
      borderRadius: 0,
      border: 'none'
    },
    cardcontent: {
      padding: theme.spacing(1, 0),
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      color: "#fff",
      background: "rgba(0,0,0,.4)"
      // minHeight: theme.spacing(10)
    },
    captionTop: {
      fontSize: '2.5rem',
      fontWeight: 100,
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: '1.5rem',
      },

    },
    cardActions: {
      height: '100%',
      // paddingBottom: theme.spacing(3),
    },

    mediaImg: {
      paddingTop: "55.25%",
    //   [theme.breakpoints.up("sm")]: {
    //     paddingTop: "65.25%",
    //   },
    },
    mediaVideo: {
      minHeight: "auto!important",
      height: "56.25vw!important",
      maxHeight: "calc(100vh - 138px - 10vh)!important"    
      
    },

  })
);

const BSlide = (props) => {
  const { slide } = props;  
  const classes = useStyles();
  // const { t } = useTranslation();        
  const ext = (slide && slide.Media ? extractFileExt(slide.Media): "");
    
  // let w  = "md";            
    const history = useHistory();
    return (      
        <Card className={classes.card} elevation={0}>
              <CardActionArea className={classes.cardActions} onClick={() => history.push(slide.Url)}>
                {IsImage(ext) && 
                <CardMedia
                  className={classes.mediaImg}                  
                  image={`${host}${slide.Media}`}
                  title={slide.Title}
                />
                }

                {IsVideo(ext) && 
                <div className={classes.mediaVideo}>
                  <video style={{ width: "100%"}} muted loop autoPlay playsInline>
                    <source
                      src={`${host}${slide.Media}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                }

                
                <CardContent className={classes.cardcontent}>
                  <Typography className={classes.captionTop} variant="h5" display='block' align='center'>
                    {slide.Title}
                  </Typography>
                  <Typography  display='block' align='center'>
                    {slide.SubTitle}
                  </Typography>
                </CardContent>
                
              </CardActionArea>
          </Card>            
    )
}

BSlide.propTypes = {  
  slide:                         
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Media: PropTypes.string.isRequired,
      Title: PropTypes.string,
      SubTitle: PropTypes.string
      // Caption: PropTypes.string.isRequired,
      // Url: PropTypes.string.isRequired,
      // Text: PropTypes.string.isRequired,
      // Path: PropTypes.string.isRequired,
      // Img: PropTypes.string.isRequired,
      // FullPath: PropTypes.string.isRequired,
      // IsAuthenticated: PropTypes.bool.isRequired,
      // Roles: PropTypes.string.isRequired,
      // Hide: PropTypes.bool.isRequired,
      // ShowText: PropTypes.bool.isRequired,
      // ShowFrom: PropTypes.string.isRequired,
      // ShowTo: PropTypes.string.isRequired,  
      // ButtonText: PropTypes.string.isRequired,        
    }).isRequired,
};

export default BSlide;