import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography,  Paper, Avatar, Chip, Badge } from "@material-ui/core";
import { deepOrange, green, purple, grey, blue, red } from '@material-ui/core/colors';
// import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from "recharts";

import { useWidth} from '../../../../helpers/utils';

// import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1)
        },
        paper: {                        
            borderRadius: 12,
            padding: theme.spacing(2),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        flex:{
            display: 'flex'
        },
        mr: {
            marginRight: theme.spacing(2)
        },
        head: {
            marginBottom: theme.spacing(3),
            // [theme.breakpoints.down("sm")]: {
            //     marginBottom: theme.spacing(1),
            // },
        },
        label: {
            color: grey[600]
        },

        badgeGreen: {
            backgroundColor: green[500],
        },
        badgePurple: {
            backgroundColor: purple[500],
        },
        badgeBlue: {
            backgroundColor: blue[500],
        },
        badgeOrange: {
            backgroundColor: deepOrange[500],
        },

        numNewcons: {            
            color: '#fff',            
            backgroundColor: green[500],
        },
        numNewconsLess: {            
            color: '#fff',            
            backgroundColor: green[200],
        },

        sumOoNewcons: {            
            color: '#fff',            
            backgroundColor: purple[500],
        },
        sumOoNewconsLess: {            
            color: '#fff',            
            backgroundColor: purple[200],
        },

        bonusSum: {
            color: '#fff',            
            backgroundColor: deepOrange[500],
        },        
        bonusSumLess: {
            color: '#fff',            
            backgroundColor: deepOrange[200],
        },        
        ooCons: {
            color: '#fff',            
            backgroundColor: blue[500],
        },
        ooConsLess: {
            color: '#fff',            
            backgroundColor: blue[200],
        },


        large: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        wgbox: {            
            position: "relative",                            
        },
        wgimg: {
            position: "absolute",
            top: theme.spacing(-6),
            right: theme.spacing(0),            
            height: theme.spacing(28),
            [theme.breakpoints.down("sm")]: {
                top: theme.spacing(2),
                height: theme.spacing(25),
                display: 'none'
            },
        },
        details: {
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(12),
            [theme.breakpoints.down("sm")]: {
                position: 'relative',
                top: theme.spacing(0),
                right: theme.spacing(0),
                width: '100%',                                
                // margin: '16px 35%'
                // top: theme.spacing(6),
                // right: theme.spacing(10),
            },
        }
    })    
);

const MinNewcomer = 3;
const MinBonnusBalesNewcomer = 125;
const PersonalVolumeNewcomer = 30;

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//         top: -2,
//         backgroundColor: green[500],
//         color: '#44b700',
//         boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//         '&::after': {
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             borderRadius: '50%',
//             animation: '$ripple 1.2s infinite ease-in-out',
//             border: '1px solid currentColor',
//             content: '""',
//         },
//     },
//     '@keyframes ripple': {
//       '0%': {
//         transform: 'scale(.8)',
//         opacity: 1,
//       },
//       '100%': {
//         transform: 'scale(2.4)',
//         opacity: 0,
//       },
//     },
// }))(Badge);
  
  const StyledTwoBadge = withStyles((theme) => ({
    badge: {
      right: 16,
      top: 1,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0',
      color: '#fff',
      // backgroundColor: green[500]      
    },
  }))(Badge);


const BonuseNewcomer = props => {
    const classes = useStyles();
    const history = useHistory();    
    const { t } = useTranslation();
    const width = useWidth();

    const { days_to_end, id_cons, oo_cons, treshold_bonus, 
            num_newcons, sum_oo_newcons, bonus_sum, currency, 
            num_notpaid_order, sum_notpaid_order } = props.data;
    
    // console.log('data', props.data);
    
    
    const handleGoToDescribe = () => {
        history.push(`/page/RecruitingPlus_${currency.toLowerCase()}`);
    } 


  return (
    <div className={clsx(classes.root)} >
                    
        <Paper className={clsx(classes.paper, classes.wgbox)}>
            <Box className={classes.head}>
                <Typography variant='h6' display='block' align={width === 'xs' ? 'center': 'center'}>
                    {t("Реєструй нових консультантів - отримуй бонуси")}
                </Typography>
            </Box>
            {/* <img className={classes.wgimg} src='/assets/images/widgets/female.svg' /> */}
                                    
            <Grid container direction="row" spacing={width !== 'xs' ? 4 : 2} justifyContent='center'>
                <Grid item xs={12} sm='auto'>
                    <Box className={classes.flex}>
                        { num_newcons >=  MinNewcomer  ?
                        <StyledTwoBadge                            
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}                            
                            badgeContent={<CheckCircleOutlineIcon style={{fontSize: 14}} />}     
                            classes={{ badge: classes.badgeGreen }} 
                        >
                            <Avatar variant="rounded" className={clsx(classes.numNewcons, classes.large, classes.mr)}>
                                <PersonAddOutlinedIcon fontSize="large" />
                            </Avatar> 
                       </StyledTwoBadge> :
                        <Avatar variant="rounded" className={clsx(classes.numNewconsLess, classes.large, classes.mr)}>
                            <PersonAddOutlinedIcon fontSize="large" />
                        </Avatar>
                        }
                        
                        <div>
                            <Typography className={classes.label} variant='body2' display='block'>{t("Нових консультантів")}</Typography>
                            <Typography variant='h5' display='block'>{num_newcons}</Typography>
                        </div>
                    </Box>                            
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Box className={classes.flex}>
                        {sum_oo_newcons >= MinBonnusBalesNewcomer ?                        
                            <StyledTwoBadge                            
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}                            
                                badgeContent={<CheckCircleOutlineIcon style={{fontSize: 14}} />}
                                classes={{ badge: classes.badgePurple }} 
                            >
                                <Avatar variant="rounded" className={clsx(classes.sumOoNewcons, classes.large, classes.mr)}>
                                    <span className="material-icons" style={{fontSize: 32}}>functions</span>                            
                                </Avatar>
                            </StyledTwoBadge>
                            :
                            <Avatar variant="rounded" className={clsx(classes.sumOoNewconsLess, classes.large, classes.mr)}>
                                <span className="material-icons" style={{fontSize: 32}}>functions</span>                            
                            </Avatar>
                        }

                        <div>
                            <Typography className={classes.label} variant='body2' display='block'>{t("Сума балів новачків")}</Typography>
                            <Typography variant='h5' display='block'>{sum_oo_newcons} </Typography>
                        </div>
                    </Box>                            
                </Grid>
                <Grid item xs={12} sm='auto' >
                    <Box className={classes.flex}>
                        {oo_cons >= PersonalVolumeNewcomer ?
                            <StyledTwoBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}                            
                                badgeContent={<CheckCircleOutlineIcon style={{fontSize: 14}} />}
                                classes={{ badge: classes.badgeBlue }} 
                            >
                                <Avatar variant="rounded" className={clsx(classes.ooCons, classes.large, classes.mr)}>
                                    <span className="material-icons" style={{fontSize: 32}}>view_in_ar</span>
                                </Avatar>
                            </StyledTwoBadge>
                            :
                            <Avatar variant="rounded" className={clsx(classes.ooConsLess, classes.large, classes.mr)}>
                                <span className="material-icons" style={{fontSize: 32}}>view_in_ar</span>
                            </Avatar>
                        }
                        <div>
                            <Typography className={classes.label} variant='body2' display='block'>{t("Особистий об'єм")}</Typography>
                            <Typography variant='h5' display='block'>{oo_cons} </Typography>
                        </div>
                    </Box>                            
                </Grid>

                {/* Temporaly closed */}
                {/* <Grid item xs={12} sm='auto'>
                    <Box className={classes.flex}>
                        {bonus_sum > 0 ?
                        <StyledTwoBadge                            
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}                            
                            badgeContent={<CheckCircleOutlineIcon style={{fontSize: 14}} />}
                            classes={{ badge: classes.badgeOrange }} 
                        >
                            <Avatar variant="rounded" className={clsx(classes.bonusSum, classes.large, classes.mr)}>
                                <AttachMoneyIcon fontSize="large" />
                            </Avatar>
                        </StyledTwoBadge> 
                        :
                        <Avatar variant="rounded" className={clsx(classes.bonusSumLess, classes.large, classes.mr)}>
                            <AttachMoneyIcon fontSize="large" />
                        </Avatar>
                        }

                        <div>
                            <Typography className={classes.label} variant='body2' display='block'>{t("Твій бонус",{currency: currency} )}</Typography>
                            <Typography variant='h5' display='block'>{bonus_sum} </Typography>
                        </div>
                    </Box>                            
                </Grid>    */}
            </Grid>

            {/* <Box pt={2} mb={2}> 
                <Typography component='div' className={classes.label} variant='body2'>
                    <div dangerouslySetInnerHTML={{__html: t("Розмір бонусу визначаєш ти", {MinBonnusBalesNewcomer: MinBonnusBalesNewcomer, treshold_bonus: treshold_bonus, currency: currency }) }}></div>                    
                </Typography>
            </Box> */}

            {/* <Chip
                className={classes.details} 
                color='primary'
                variant="outlined" 
                icon={<InfoOutlinedIcon />} 
                label={t("Умови програми")}
                onClick={handleGoToDescribe}
            /> */}
        </Paper>
    </div>
  )
}

BonuseNewcomer.propTypes = {
    data: PropTypes.shape({
        "days_to_end": PropTypes.number.isRequired,
        "id_cons": PropTypes.number.isRequired,
        "oo_cons": PropTypes.number.isRequired,
        "treshold_bonus": PropTypes.number.isRequired,
        "num_newcons": PropTypes.number.isRequired,
        "sum_oo_newcons": PropTypes.number.isRequired,
        "bonus_sum": PropTypes.number.isRequired,
        "currency": PropTypes.string.isRequired,
        "num_notpaid_order": PropTypes.number.isRequired,
        "sum_notpaid_order": PropTypes.number.isRequired,
        "role": PropTypes.number.isRequired
    }).isRequired
}

export default BonuseNewcomer;