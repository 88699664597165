import React, { Component } from "react";

import { instanceOf } from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import Layout from "./components/Layout/Layout";
import { Routes } from "./Routes";

import { withCookies, Cookies } from "react-cookie";
import "./styles/index.css";



class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {        
    super(props);        
    const query = new URLSearchParams(window.location.search.toLowerCase());        
        
    // Store refId adviser
    const refKey = query.getAll("ref");
    if(refKey.length !== 0){
      const refVal = refKey.toString();
      if(refVal.length > 0){
        this.setCookiesFromUrl("ref", refVal, 31);
      }
    }

    // Store admitad
    const admitad_uid = query.getAll("admitad_uid");
    if(admitad_uid.length !== 0){
      const admitad_uidVal = admitad_uid.toString();
      if(admitad_uidVal.length > 0){        
        console.log("admitad_uid", admitad_uidVal);
        this.setCookiesFromUrl("admitad_uid", admitad_uidVal, 90);
      }
    }
  }

  setCookiesFromUrl = (name, val, days) => {
    const { cookies } = this.props;                
    window.sessionStorage.setItem(name, val);    
    cookies.set(name, val, { path: "/", maxAge: days*24*60*60});
  };

  render () {    
    return (      
        <StylesProvider>
          <Layout > 
            <CssBaseline />              
            {Routes}              
          </Layout>
        </StylesProvider>        
    );
  }
}

export default withCookies(App);
