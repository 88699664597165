/* /src/components/auth/silentRenew.jsx */

import React from "react";
import { AppConsumer } from "../../providers/appProvider";
export const SilentRenew = () => (
    <AppConsumer>
        {({ signinSilentCallback }) => {     
            console.log("silentRenew");       
            signinSilentCallback();
            return <p style={{ width: "100%", textAlign: "center"}}>Завантаження...</p>;
        }}
    </AppConsumer>
);