import React, {useRef, useState,} from 'react';
import {AppContext,  AppConsumer } from "../../providers/appProvider";
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';

import { createStyles, makeStyles,  } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Country from '../Country/Country';
// import { bindActionCreators } from "redux";
// import { connect, useSelector, useDispatch } from 'react-redux';
// import { actionCreators } from "../../store/NavItems";

const useStyles = makeStyles((theme) =>
    createStyles({      
        option: {
            fontSize: 15,
            '& > span': {
              marginRight: 10,
              fontSize: 18,
            },
          },
        bg: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundImage: 'url(/assets/files/images/global.png)'
        }
      
        
  })
);

export const FirstStep = (props) => {
    // const { closeDialog } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    
    const [open, setOpen] = useState(true);
                    
    const refCountry = useRef();

    const handleSave = () => {
        refCountry.current.Save();
        setOpen(false);
    }
    
    
  return (
    <AppConsumer>
        {() => (
            <Dialog             
                aria-labelledby="dlg-select-country" 
                open={open} 
                fullWidth={true}
                maxWidth={'sm'}
            >                
                <DialogContent  >
                    <Country ref={refCountry} />
                </DialogContent>
                <DialogActions>
                    <Button style={{marginRight: 16}} variant='contained' color="primary" onClick={handleSave}>
                        {t('buttons.Save')}
                    </Button>
                </DialogActions>
            </Dialog>
            
        )}
    </AppConsumer>
    
  )
}

FirstStep.propTypes = {    
    closeDialog: () => null
}

export default FirstStep;

// export default connect(
//     state => state.appLayout,
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(FirstStep);