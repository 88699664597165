
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';

// eslint-disable-next-line no-unused-vars
import {Box, InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {      
      // flexGrow: 1,
      height: '100%',
      position: 'relative',
      overflowX: "hidden"
    },
    img: {
      maxWidth: "100%"
    },
    mediaImg: {
      paddingTop: "55.25%"      
    },

    mediaVideo: {
      minHeight: "auto!important",
      // height: "56.25vw!important",
      // maxHeight: "calc(100vh - 138px - 10vh)!important"      
    },
}));

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const Media = (props) => {
  const {id, className, onClick, src, alt } = props;  
  const classes = useStyles();
  const fileExt = extractFileExt(src);

  
  // const [ component, setComponent] = useState(node);
  
  // useEffect( () => {
  //   if(node)
  //     setComponent(node);
  // }, [node]);

  return (
    <div id={id} className={clsx("jr-media", className, classes.root)} onClick={onClick}>
      {IsImage(fileExt) && 
      <figure className={classes.img} style={{margin: "0", padding: "0"}} onClick={onClick}> 
          <picture className={classes.img}>
            <source media="(min-width: 1134px)" srcSet={`${host}${src}`} />
            <img className={classes.img} src={`${host}${src}`} alt={alt} />
          </picture>
      </figure>
      }

      {IsVideo(fileExt) && 
        <div className={classes.mediaVideo}>
          <video style={{ width: "100%"}} muted loop autoPlay playsInline>
            <source
              src={`${host}${src}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
      </div>
      }
    </div>
  )
}

Media.propTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
  // interval: PropTypes.number,
  // images: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     Id: PropTypes.string.isRequired,      
  //   })
  // ).isRequired,
  onClick: () => null
}

export default Media