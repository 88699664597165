import React, { useEffect, useState, useContext, useCallback } from "react";
import { AppContext, AppConsumer } from '../../providers/appProvider';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, alpha} from "@material-ui/core/styles";
import { InputBase, Checkbox, Paper, IconButton, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

import moment from "moment";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            flexGrow: 1,        
        },
        table: {
            minWidth: 500,
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.black, 0.05),
            '&:hover': {
              backgroundColor: alpha(theme.palette.common.black, 0.1),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.spacing(3),
              width: 'auto',
            },
          },
          searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        inputRoot: {
            color: 'inherit',
          },
        inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
        },
    })
);

const API_URL = '/api/v1.0';
const defaultPageSize = 10;

const Articles = () => {
    const classes = useStyles();
    const auth = useContext(AppContext);
    const [token, setToken] = useState(null);
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [articles, setArticle] = useState([]);
    
    const handleNewPage = () => {
        history.push('/builder');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, defaultPageSize));
        setSearch('');
        setPage(0);
      };
    

    const loadArticles = useCallback(async (tkn)  => {
        if(search !=='' && search.length < 3){
            return;
        }
        
        const url = `${API_URL}/articles/${(search.length > 0 ? search : "%20" )}/${page}/${pageSize}`;
                    
        await fetch(url, { 
            method: "GET", 
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": tkn
            },            
        })
        .then((response) => response.json())
          .then((data) => {
            console.log(data);
            // const { Page, Data, PageSize, TotalCount } = data;
            
            setArticle(data.Data);
            setTotal(data.TotalCount);
            
            // console.log( Page, Data, PageSize, TotalCount );
          })
          .catch((error) => {
            console.log("Error", error);
          });
                  
    }, [search, page, pageSize]);


    useEffect(() => { 
        if(!token){
            return;
        }        
        loadArticles(token);
    }, [token, loadArticles]);


    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {                 
                    token = `Bearer ${user.access_token}`;
                }                
                setToken(token);                                
            });
        }
        fetchUser();        
    }, [auth]);

  return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>
                <Paper style={{ width: '100%', marginBottom: 16 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item><Typography variant="h5" style={{paddingLeft: 16}}>Articles</Typography></Grid>
                        <Grid item>
                            <IconButton                        
                                color="inherit"
                                aria-label="filterButton"
                                onClick={handleNewPage}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                            <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        </Grid>
                    </Grid>
                    
                </Paper>

                {articles && 
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="articles table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{width: 60}}>#</TableCell>
                                    <TableCell style={{width: 170}}>Data</TableCell>
                                    <TableCell>Url key</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Action</TableCell>
                                    
                                </TableRow>
                            </TableHead>  
                            <TableBody>
                                { articles.map( article => (
                                <TableRow key={article.Id} >
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={article.Publish}
                                        inputProps={{ 'aria-labelledby': article.Id }}
                                        />
                                    </TableCell>
                                    <TableCell style={{width: 60}} component="th" scope="row">{article.Id}</TableCell>
                                    <TableCell style={{width: 170}}>{moment(article.CreatedOn).format("YYYY-MM-DD kk:mm:ss")}</TableCell>
                                    <TableCell >{article.UrlKey}</TableCell>                                
                                    <TableCell >{article.Name}</TableCell>
                                    <TableCell >{article.Title}</TableCell>
                                    <TableCell style={{width: 50}} >
                                        <IconButton component={Link} to={`/builder/${article.Id}`}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow> ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={page-1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />                
                </Paper>
                }
            </div>
        )}
    </AppConsumer>
  )
}

export default Articles;