// Modules
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
// import moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Components
// import { VariableSizeList as List } from "react-window";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import {ListItemText, ListItem, ListItemIcon, Divider, Button, IconButton, Menu, MenuItem, Typography} from '@material-ui/core';

// Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { UserAvatar } from "../UserAvatar";

// Styles
import styles from "./Messages.css";


// eslint-disable-next-line no-unused-vars
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    float: "left", 
    marginTop: -2,
    marginRight: 8
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  button: {
    marginRight: theme.spacing(2)
  },
  listItemIcon: {
    marginRight: 12
  },
  avatar: {
    backgroundColor: "rgba(236, 236, 236, 0.2)",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  title: {      
    textTransform: "capitalize",
  },
}));

const Invites = (props) => {
  const { messages, onAccept, onDecline, onBlock} = props;
  const { t } = useTranslation();
  

  // References
  const listRef = useRef({});
  // const rowHeights = useRef({});


  const classes = useStyles();

  useEffect(() => {     
    const scrollToBottom = () => {
      if(messages.length > 1 && listRef.current.scrollToItem)
        listRef.current.scrollToItem(messages.length - 1, "end");
    }
    scrollToBottom();    
  }, [messages, listRef]);

const RowInvate = (props) => {
  const { index, style } = props;
  const invite = props.data[index];
  const rowRef = useRef({});
  const [anchorEl, setAnchorEl] = useState(null);  
    
    const handleClickMenu = (e) => {     
      setAnchorEl(e.currentTarget);
    };
  
    const handleCloseMenu = () => {      
      setAnchorEl(null);    
    };
  
    const acceptInvite = (idx) => {
      onAccept(idx);
    };
  
    const declineInvite = (idx) => {
        onDecline(idx);
        handleCloseMenu();
    };
  
    const blockInvite = (idx) => {      
      handleCloseMenu();
      onBlock(idx);      
    };

  return (
    <>    
      <ListItem ref={rowRef} style={style} key={index} >
          <ListItemIcon className={classes.listItemIcon} >
              {invite.AdviserFrom ? <UserAvatar adviser={invite.AdviserFrom} /> :null }
          </ListItemIcon>
          <ListItemText
              primary={ <Typography>{invite.AdviserFrom ? <span className={classes.title}>{`${(invite.AdviserFrom.LastName + " " + invite.AdviserFrom.FirstName).toLowerCase()}. `}</span>  : null} {t("chat.InviteYou")}</Typography> }
              secondary={
                  <span dangerouslySetInnerHTML={{__html: invite.Body.replace(/(<? *script)/gi, "illegalscript")}}></span>
              }
          />
          <Button size="small" edge="end" aria-label="accept" variant="outlined" color="secondary" onClick={()=>acceptInvite(index)}>
            {t("chat.Accept")}
          </Button>
                    
          <IconButton edge="end" aria-label="more" onClick={ (e) => handleClickMenu(e)}>
              <MoreVertIcon fontSize="small" />
          </IconButton>          
      </ListItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}                  
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={()=> acceptInvite(index)} >
          <ListItemIcon className={classes.listItemIcon}>
            <CheckCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          {t("chat.Accept")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=> declineInvite(index)} >
          <ListItemIcon className={classes.listItemIcon}>
            <CancelOutlinedIcon fontSize="small" />
          </ListItemIcon>
            {t("chat.Decline")}
        </MenuItem>
        <MenuItem onClick={() => blockInvite(index)} >
          <ListItemIcon className={classes.listItemIcon}>
            <BlockOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("chat.Block")}
        </MenuItem>
      </Menu>
    </>
  );
};  

  return (
    
    <AutoSizer style={styles.messagesContainer}>
      {({ height, width }) => (
        <List 
          height={ messages.length < 6 ? messages.length * 52 : height} 
          width={width - 2} 
          itemSize={50} 
          itemCount={messages.length} 
          itemData={messages}>
          {RowInvate}
        </List>
        // <List
        //   className="List"
        //   height={height - 8}
        //   itemCount={messages.length}
        //   itemSize={getRowHeight}
        //   ref={listRef}
        //   width={width}
        // >
        //   {Row}          
        // </List>
      )}
    </AutoSizer>

    
    
  );
};

Invites.propTypes = {
  messages: PropTypes.array.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired
};

export default Invites;
