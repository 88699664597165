import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import SmallSlide from "./SmallSlide";
import { useWidth} from '../../helpers/utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {    
        // marginTop: theme.spacing(1),        
    },    
  })
);

const SmallSlider = (props) => {    
    const classes = useStyles();
    const { cname, slider } = props;
    const width= useWidth();
        
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div className={classes.root} cname={cname}>
        <Grid container direction="row" spacing={1} justifyContent="center"  alignItems="stretch">
          {slider.Items.map((slide) => (
            <Grid item key={slide.Id} xs={6} sm={6} md={2}>
                <SmallSlide slide={slide} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
}

SmallSlider.propTypes = {  
  cname: PropTypes.string,
  slider: PropTypes.shape({
      Id: PropTypes.number.isRequired,      
      Name: PropTypes.string.isRequired,
      Descr: PropTypes.string,
      ViewName: PropTypes.string.isRequired,
      FileName: PropTypes.string.isRequired,
      Items: PropTypes.arrayOf(
        PropTypes.shape({
          Id: PropTypes.number.isRequired,
          Caption: PropTypes.string.isRequired,
          Url: PropTypes.string.isRequired,
          Text: PropTypes.string.isRequired,
          Path: PropTypes.string.isRequired,
          // Img: PropTypes.string.isRequired,
          FullPath: PropTypes.string.isRequired,
          IsAuthenticated: PropTypes.bool.isRequired,
          Roles: PropTypes.arrayOf(PropTypes.string),
          Hide: PropTypes.bool.isRequired,
          ShowText: PropTypes.bool.isRequired,
          ShowFrom: PropTypes.string.isRequired,
          ShowTo: PropTypes.string.isRequired,
          ButtonText: PropTypes.string,
        })
      ).isRequired,

  })
};


export default SmallSlider;