/* /src/components/auth/callback.jsx */
import React, { useContext } from "react";
import {AppContext,  AppConsumer } from "../../providers/appProvider";
import Typography from "@material-ui/core/Typography";
import { useHistory } from 'react-router-dom';

import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionCreators } from "../../store/NavItems";


export const Callback = () => {
    const app = useContext(AppContext);
    const { signinRedirectCallback, UserManager, changeUser } = app;
    const history = useHistory();
    const dispatch = useDispatch();    
    
    UserManager.signinRedirectCallback()
        .then(user => {                                                 
            signinRedirectCallback();

            // console.log('signinRedirectCallback user', user);
            changeUser(user);
            actionCreators.setAppUser(user);            
            if(user){                
                dispatch(actionCreators.getCustomer());

                const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
                const URL = '/api/v1.0/account/log/';
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Accept", "application/json");
                headers.append("Authorization", token);
                fetch(URL, { method: "GET", headers: headers })
                    .then(response =>  {                
                        console.log(response.status);
                    });                
            }

            history.push('/');            
        });

    return (
        <Typography>Loading user...</Typography>
    )    
};


export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Callback)