import React from 'react';
import PropTypes from 'prop-types';
import UnderConstr from '../Manage/UnderConstr/UnderConstr';

const index = props => {
  return (
    <UnderConstr />
  )
}

index.propTypes = {}

export default index