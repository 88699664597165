// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "../../components/CustomDialog";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    },



  })
);

export const LocalityDialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { onClose, selectedValue, open } = props;

  const [search, setSearch] = useState("");
  // const [locality, setLocality] = React.useState(null);
  // const [localities, setLocalities] = React.useState(null);

  const handleClose = () => {
    onClose(selectedValue);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };

  // const fetchData = useCallback(async () => {

  // }, [search]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="dlg-locality" open={open} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle onClose={handleClose}>
        {t("Виберіть місто або населений пункт")}
      </DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                label="Rearch locality"
                value={search}
                helperText="Some important text"
                variant="outlined"
                onChange={e => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose} color="default" variant="outlined" >
          {t("buttons.Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LocalityDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object,
};