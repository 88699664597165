import React, { useState } from "react";
import PropTypes from 'prop-types';
import {Hidden, Collapse, Divider, Grid, TextField, InputAdornment, IconButton} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",            
        },
        searchbox: {  
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: grey[300],  
            display: "flex",
            alignItems: "center",    
            width: "100%",
        },
        input: {            
            flex: 1,
        },
        buttons: {        
            marginRight: theme.spacing(1),
        },
    })
);

const SearchBoxMobile = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const { showSearchBox, handleCloseSearchBox} = props;
    const [search, setSearch] = useState('');

    const searchProducts = () => {
        if(search.length > 2){
          history.push(`/search/${search}`);
        }            
      }
  
      const keyPressSearch = e => {
        if (e.key === "Enter") {
          searchProducts();
          e.preventDefault();
        }
      };
    
      const handleSearch = (e) => {
        searchProducts();
        e.preventDefault();
      };
  

  return (
    <Hidden smUp>
        <Collapse in={showSearchBox} timeout="auto" >
            <Divider />
            <Grid spacing={0} container direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={9}>                    
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={search}                
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={keyPressSearch }
                        className={classes.input}
                        placeholder={t("common.SearchProducts")}
                        // inputProps={{ "aria-label": t("common.SearchProducts") }}
                        InputProps={{
                            endAdornment: (
                                <IconButton 
                                    type="submit"
                                    role="button"
                                    size="small"                                
                                    aria-label="search" 
                                    onClick={handleSearch}
                                    color="primary"
                                >
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}  
                    />
                    
                </Grid>
                <Grid item xs='auto'>          
                    {/* <IconButton type="submit"
                        role="button"
                        className={classes.buttons}
                        aria-label="search" 
                        onClick={handleSearch}
                        color="primary"
                    >
                        <SearchIcon />
                    </IconButton> */}
                    <IconButton                         
                        role="button" 
                        aria-label="close search" 
                        onClick={handleCloseSearchBox} color="inherit">
                        <CloseIcon />
                    </IconButton>
                
                </Grid>
            </Grid>      

            <Divider />
        </Collapse>
    </Hidden>
  )
}

SearchBoxMobile.propTypes = {
    showSearchBox: PropTypes.bool.isRequired,
    handleCloseSearchBox: PropTypes.func.isRequired
}

export default SearchBoxMobile