import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars
import { Card,  Typography, CardActionArea, CardMedia, CardContent,  CardActions, Button } from "@material-ui/core";
import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';

// eslint-disable-next-line no-unused-vars
import { Link, useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) =>
  createStyles({    
    card: {
      height: '100%',
      minHeight: 160,      
      border: 'none'
    },
    cardcontent: {
      padding: theme.spacing(1),      
    },
    title: {      
           
    },
    subtitle: {      
     
    },
    cardActions: {
      padding: theme.spacing(1),
    },

    mediaImg: {      
        paddingTop: "85.25%", // 16:9      
        // backgroundSize: "contain"
    },

    mediaVideo: {
      minHeight: "auto!important",
      height: "56.25vw!important",
      maxHeight: "calc(100vh - 138px - 10vh)!important",      
      // height: 400
    },

  })
);

const SSlide = (props) => {
  const { slide } = props;  
  const classes = useStyles();  
  const ext = (slide && slide.Media ? extractFileExt(slide.Media): "");      
  const history = useHistory();
  
  const handleClick = () => {
    if(slide.Url === '') return;
    history.push(slide.Url);
  }

  return (      
        <Card className={classes.card} elevation={2}>
            <CardActionArea onClick={handleClick}>
            {IsImage(ext) && 
                <CardMedia
                  className={classes.mediaImg}                  
                  image={`${host}${slide.Media}`}
                  title={slide.Title}
                />
                }

                {IsVideo(ext) && 
                <div className={classes.mediaVideo}>
                  <video style={{ width: "100%"}} muted loop autoPlay playsInline>
                    <source
                      src={`${host}${slide.Media}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>                
                }
              </CardActionArea>
              {/* <CardContent className={classes.cardcontent}>
              </CardContent> */}

              <CardActionArea className={classes.cardActions} onClick={handleClick}>                
                  <Typography className={classes.title} variant="body2" display='block' align='center'>
                    {slide.Title}
                  </Typography>
                  <Typography className={classes.subtitle} variant='caption'  display='block' align='center'>
                    {slide.SubTitle}
                  </Typography>
              
                
              </CardActionArea>
          </Card>            
    )
}

SSlide.propTypes = {  
  slide:                         
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Media: PropTypes.string.isRequired,
      Title: PropTypes.string,
      SubTitle: PropTypes.string
      // Caption: PropTypes.string.isRequired,
      // Url: PropTypes.string.isRequired,
      // Text: PropTypes.string.isRequired,
      // Path: PropTypes.string.isRequired,
      // Img: PropTypes.string.isRequired,
      // FullPath: PropTypes.string.isRequired,
      // IsAuthenticated: PropTypes.bool.isRequired,
      // Roles: PropTypes.string.isRequired,
      // Hide: PropTypes.bool.isRequired,
      // ShowText: PropTypes.bool.isRequired,
      // ShowFrom: PropTypes.string.isRequired,
      // ShowTo: PropTypes.string.isRequired,  
      // ButtonText: PropTypes.string.isRequired,        
    }).isRequired,
};

export default SSlide;