import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import { connect } from "react-redux";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import {Avatar, Chip, Typography, Grid, TextField, Paper, Box, jssPreset } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { OrderRowType } from "../../definitions/order";
import { AppContext } from "../../providers/appProvider";
import { getThemeProps } from "@material-ui/styles";

const styles = theme => ({
    root: {
        position: "relative",        
        height:  172,        
        width: "100%",
        padding: theme.spacing(2, 1),        
        boxShadow: theme.palette.ambilight.boxshadow,
        borderRadius: "1rem",        
      },      
      content: {
        flex: "1 0 auto",
        height: 64,
        padding: theme.spacing(0,1,1,1),
        [theme.breakpoints.down("sm")]: {
            height: 60,
        },
      },
      buy: {
        marginTop: 0,        
      },
      cover: {
        display: 'block',        
        height: theme.spacing(10),        
        [theme.breakpoints.down("sm")]: {            
            height: theme.spacing(9), 
        },        
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",        
      },
      bal_conner_img: {
        right: 0,
        bottom: 0,
        height: "90%",
        position: "absolute"
    },
      controls: {        
        padding: theme.spacing(1,0,0,1),
      },
      playIcon: {
        height: 28,
        width: 28,
      },
      price: {
        marginRight: theme.spacing(2),
      },
      qty: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: theme.spacing(10),
        "&[type=number]": {
            "-moz-appearance": "textfield",        
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",          
        },
      },
      right: {
        textAlign: 'right'
      }
});

class ProdPromoThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,
            openDlg: false,            
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            imgXsSize: "76x76",
            Qty: this.props.qty,  
        };
        this.handleBuy = this.handleBuy.bind(this);        
    }
    
    static contextType = AppContext;

    componentDidMount () {
      
        const { getUser } = this.context;

        getUser().then( (user) => {  
            this.setState({user: user});
        });      
    };

    callBackFn = (id) => {
        if(id > 0){    
            this.props.history.push(`/order/${id}`);
        }
    };

    handleBuy() {
        const { t } = this.props;
        const { user } = this.state;
        
        if( user){ 
            const qty = isNaN(parseInt(this.state.Qty)) ? 1 : parseInt(this.state.Qty);  
            this.setState({Qty: qty});        
            const {item, promo} = this.props;        
            let price = item.Product.CatalogPrice;       
            let promotionType = OrderRowType.Default;
            let sourse = null;
            let useLO = true;

            if(promo){
                if(promo.PromotionType === OrderRowType.Promotion){
                    promotionType = OrderRowType.Chest;
                    if(item.Prices && item.Prices.length > 0) {
                        useLO =  promo.ActionByCondition.UseLO;
                        price = item.Prices[0];
                        sourse =  { 
                            Id: promo.Id,
                            Idrref: promo.Idrref,
                            Name: promo.Name,
                            Description: promo.Description
                            };
                    }                
                }                
            }
                        
            const pos = {
                ParentIdrref: item.Product.Idrref,
                ProductId: item.Product.Id,
                orderrowtype: promotionType, // OrderRowType.Chest.Default,
                Name: item.Product.LocalName === "" ? item.Product.LocalName: item.Product.Name,
                LocalName: item.Product.LocalName === "" ? item.Product.LocalName: item.Product.Name,
                Price: price,
                CatalogPrice: item.Product.CatalogPrice,
                CatalogPricePv: item.Product.CatalogPricePv,
                Rate: parseFloat(item.Product.CatalogPrice / item.Product.CatalogPricePv).toFixed(2),
                Code: item.Product.Code,
                Image: item.Product.Image,
                UseLO: useLO,
                Source: sourse,
            };

            this.props.actions.addToOrder([{ pos: pos, qty: qty, orderrowtype: promotionType }], this.callBackFn);

        } else {
            // this.setState({openDlg: true}); 
            this.props.actions.showMessage(t("order.AllowAddToOrderText"), "warning");
        }    
    }

    handleChangeQty = (e) => {
        // console.log("Change value", e.target.value);
        const qty = parseInt(e.target.value) > 0 ? e.target.value : 1;
        this.setState({ Qty: qty})
    }

    handleCloseDlg = () => {
        this.setState({openDlg: false});
    };

    render() {
        const {item, classes, promo, disabled, width, t, theme } = this.props;
        const { cdn } = this.state;        
        return (
            <>
            {/* <AllowOrderDlg open={openDlg} onClose={this.handleCloseDlg} /> */}
            <Paper className={classes.root} >
                <img className={classes.bal_conner_img} alt="triangle background" src="/assets/images/triangle-light.png" />
                
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3} sm={2} >
                        <div className={classes.cover} style={{ backgroundImage: `url(${cdn}${ width === "xs" ? this.state.imgXsSize: this.state.imgMdSize }/${item.Product.Image})`}}></div> 
                    </Grid>
                    <Grid item xs={9} sm={10} >
                        <Typography variant='body1'>
                            <b>{item.Product.Code}</b> {item.Product.LocalName !== '' ? item.Product.LocalName : item.Product.Name}
                        </Typography>

                        {/*                                                
                        <Box mt={2} className={clsx(classes.right)} pr={2}>
                            {
                                item.Prices && item.Prices.length > 0 ? 
                                <Typography variant="h5" component='span'> 
                                    <span>{parseFloat(item.Prices[0]).toFixed(2)}</span>
                                    <small className="price--line-through text-gray" style={{marginLeft: 8, fontSize: ".8rem"}}>{parseFloat(item.Product.CatalogPrice).toFixed(2)}</small>
                                </Typography> : 
                                <Typography variant="h5" component='span'> 
                                    {parseFloat(item.Product.CatalogPrice).toFixed(2)}
                                    <span>&nbsp;</span>
                                    <small className="price--line-through text-gray" style={{marginLeft: 8, fontSize: ".8rem"}}>&nbsp;</small>
                                </Typography>
                            }
                            <TextField
                                className={classes.qty}
                                variant="outlined"                                
                                size="small"
                                inputProps={{
                                    step: 1, 
                                    min: 1, 
                                    max: (promo.PromotionType === 1 ? this.props.qty : 1000)
                                }}
                                value={this.state.Qty}
                                onChange={this.handleChangeQty}  
                            />
                            <Chip 
                                className={classes.buy}
                                avatar={<Avatar><ShoppingCartOutlinedIcon style={{fontSize: 16}} /></Avatar>}
                                variant= {theme.palette.type === "light" ? "default" : "outlined" } 
                                label= {t("buttons.Buy")}
                                color="primary"
                                onClick={this.handleBuy}
                            /> 
                        </Box>
 */}
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={2} className={clsx(classes.right)} pr={width==="xs"? 2 : 4}>
                            {
                                item.Prices && item.Prices.length > 0 ? 
                                <Typography variant="h5" component='span'> 
                                    <span>{parseFloat(item.Prices[0]).toFixed(2)}</span>
                                    <small className="price--line-through text-gray" style={{marginLeft: 8, fontSize: ".8rem"}}>{parseFloat(item.Product.CatalogPrice).toFixed(2)}</small>
                                </Typography> : 
                                <Typography variant="h5" component='span'> 
                                    {parseFloat(item.Product.CatalogPrice).toFixed(2)}
                                    <span>&nbsp;</span>
                                    <small className="price--line-through text-gray" style={{marginLeft: 8, fontSize: ".8rem"}}>&nbsp;</small>
                                </Typography>
                            }
                            <TextField
                                className={classes.qty}
                                variant="outlined"                                
                                size="small"
                                inputProps={{
                                    step: 1, 
                                    min: 1, 
                                    max: (promo.PromotionType === 1 ? this.props.qty : 1000)
                                }}
                                value={this.state.Qty}
                                onChange={this.handleChangeQty}  
                            />
                            <Chip 
                                className={classes.buy}
                                avatar={<Avatar><ShoppingCartOutlinedIcon style={{fontSize: 16}} /></Avatar>}
                                variant= {theme.palette.type === "light" ? "default" : "outlined" } 
                                label= {t("buttons.Buy")}
                                color="primary"
                                onClick={this.handleBuy}
                            /> 
                        </Box>

                    </Grid>


                </Grid>                                                                                    
            </Paper>
            </>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles, { withTheme: true })(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(ProdPromoThumb)
))));