import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {BlockType} from "../../../definitions/pageblocktype";

import TitleBlock from "./TitleBlock";
import HtmlBlock from "./HtmlBlock";
import VideoLinkBlock from "./VideoLinkBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import LogoImage from "./LogoImage";
import BonuseNewcomer from "./Widgets/BonuseNewcomer";
import ContentFiles from "./Widgets/ContentFiles";
import PdfBlock from "./PdfBlock";

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center'
        },
        
        designblock: {
            border: `1px dashed ${theme.palette.divider}`,
        },
        
        childblock: {
            margin: theme.spacing(1/2),
            padding: theme.spacing(1/2),
            border: `1px dashed ${theme.palette.divider}`,
        },
        controls: {
            position:'relative',            
        },
        buttons: {
            position: 'absolute',            
            // backgroundColor: theme.palette.primary.main,
            bottom: theme.spacing(-2),
            right: theme.spacing(-1),
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
        },
        button: {
            padding: 2,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.palette.ambilight.boxshadow
        }

    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const ColumnsBlock = props => {
    const { block, design, onEdit } = props;
    const classes = useStyles();
    
    const handleEdit = (content, blockType) => {
        if(onEdit){
            onEdit(content, blockType);
        }
    }

  return (
    <div className={classes.root}>
        {block && block.Childs &&
        <Grid className={clsx(design ? classes.designblock: '')} container direction="row" spacing={0} justifyContent="center" alignItems="flex-start">
            { block.Childs.map((child, idx) => (
                <Grid item key={idx} xs={12} sm={6}>
                    <div className={clsx(classes.controls, design ? classes.childblock: '')}>
                                                
                        {child.Content && child.PageBlockType ===  BlockType.TITLE &&  child.Content.LocalValues &&  child.Content.LocalValues.length > 0 &&
                            <TitleBlock
                                localValue={ child.Content.LocalValues[0]}
                                blockKey={ child.Key }
                                propsBlock={child.Content.Props}
                            />
                        }

                        {child.Content && child.PageBlockType === BlockType.HTMLBLOCK && child.Content.LocalValues && 
                            <HtmlBlock
                                localValue={ child.Content.LocalValues[0]}
                                blockKey={ child.Key }
                            />
                        }

                        {child.Content && child.PageBlockType === BlockType.IMAGE && child.Content.LocalValues && 
                            <ImageBlock url={ child.Content.LocalValues[0].Value } />
                        }

                        
                        {child.Content && child.PageBlockType === BlockType.BIGSLIDER && child.Content.LocalValues &&
                            <BigSlider slider={ child.Content } blockKey={ block.Key } autoplay={ false } />
                        }

                        {child.Content && child.PageBlockType === BlockType.MIDDLESLIDER && child.Content.LocalValues && 
                            <MiddleSlider slider={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.SMALLSLIDER && child.Content.LocalValues && 
                            <SmallSlider slider={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.LOGOIMAGE &&  child.Content.LocalValues && 
                            <LogoImage content={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.WGBONUSENEWCOMER && child.Content.LocalValues && 
                            <BonuseNewcomer data={ child.Content.Data } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.PDFVIEWER && child.Content.LocalValues && 
                            <PdfBlock url={ child.Content.LocalValues[0].Value } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.VIDEOLINK && child.Content.LocalValues && 
                            <VideoLinkBlock url={ child.Content.LocalValues[0].Value} />
                        }

                        {child.Content && child.PageBlockType === BlockType.VIDEO && child.Content.LocalValues && 
                            <VideoBlock url={ child.Content.LocalValues[0].Value} />
                        }                        

                        {onEdit &&
                        <div className={classes.buttons}>
                            <IconButton
                                className={classes.button} 
                                size='small' 
                                onClick={()=> handleEdit(child.Content, child.PageBlockType)} 
                                aria-label="edit content" 
                                title='Edit item' 
                            >
                                <EditIcon fontSize='small'/>
                            </IconButton>
                        </div> 
                        }
                    </div>
                </Grid>
            ))}
        </Grid>
        }
    </div>
  )
}

ColumnsBlock.propTypes = {
    block: PropTypes.object.isRequired,
    design: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
}

export default ColumnsBlock;