import React, { useState, useContext, useCallback, useEffect, useRef} from "react";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, Link, Button, IconButton, Paper, Dialog } from "@material-ui/core";
import  { Link as RouterLink } from "react-router-dom";
import { DialogActions, DialogContent, DialogTitle } from "../../CustomDialog";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useWidth} from '../../../helpers/utils';

import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import EditIcon from "@material-ui/icons/Edit";
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';

const useStyles = makeStyles((theme) =>
    createStyles({
             root: {
                 flexGrow: 1,
                 // padding: theme.spacing(1),
                 margin: theme.spacing(1),
             },

             appbar: {            
                 marginTop: theme.spacing(1),
                 marginBottom: theme.spacing(1),
                 borderRadius: 12,
                 boxShadow: theme.palette.ambilight.boxshadow
             },
             paper: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                borderRadius: 12,
                padding: theme.spacing(1),
                boxShadow: theme.palette.ambilight.boxshadow
            },
            area: {
                minHeight: theme.spacing(100)
            },
             toolbar: {
                 // padding: theme.spacing(1, 2)
             },
             toolbarButton: {
                 marginRight: theme.spacing(2),
               },
             title: {
                 flexGrow: 1,
                 textAlign: 'right'
             },
             
             toolbox: {
                 marginTop: theme.spacing(2),
                 minHeight: theme.spacing(30),
             },
             toolsboxitem: {
                 border: `1px solid ${theme.palette.divider}`,
                 borderRadius: theme.spacing(1/2),
                 marginBottom: theme.spacing(1)
             },
             secondaryAction: {            
                 right: theme.spacing(-2)
             },
             button: {
                 marginRight: theme.spacing(1)
             },
             
             mr: {
                 marginRight: theme.spacing(1)
             },
             col1: {
                width: theme.spacing(2),
                [theme.breakpoints.down("sm")]: {
                    width: '50%'
                },
             },
             col2: {
                width: theme.spacing(2),
                [theme.breakpoints.down("sm")]: {
                    width: '50%'
                },
             }
    })
);

const initData = {
    'Data': [],
    'Page': 0,
    'PageSize': 20,
    'TotalCount': 0    
};

function getIcon(item){
    if(item.Predefined){
        return  <LoyaltyOutlinedIcon color="secondary" style={{fontSize: 16}} />
    }

    switch (item.ContentType) {
        case 0:            
            return <InsertDriveFileOutlinedIcon style={{fontSize: 16}} />;
        case 4:
            return <NotificationsActiveOutlinedIcon color="secondary" style={{fontSize: 16}}/>;
        case 8:
            return <AnnouncementOutlinedIcon color="secondary" style={{fontSize: 16}}/>;

        default:
            return <InsertDriveFileOutlinedIcon  style={{fontSize: 16}}/>;
    }
}

const Contents = () => {
    const app = useContext(AppContext);
    const width = useWidth();
    const classes = useStyles();
    const { t } = useTranslation();
    const [token, setToken] = useState();
    const [data, setData] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');
    const [showDelDlg, setShowDelDlg] = useState(false);
    const [deleted, setDeleted] = useState();

    const loadContent = useCallback(() => {
        if(!token) return;
        const url  = `/api/v1.0/page/getpages/${search === ''? ' ': search}/${page+1}/${pageSize}`;
        console.log(url);

        fetch(url, { headers: { authorization: token} })
        .then((response) => response.json())
            .then((data) => {
                console.log('Get pages', data);
                if (data) {
                    setData(data);
                }
            })
       .catch((error) => {
           console.log('Error', error);
       });
        
    }, [page, pageSize, search, token]);


    const deletePage = () => {
        if(!deleted) return;

        const url = `/api/v1.0/page/delete/${deleted.Id}`;
        const method = "DELETE";
                
        fetch(url, { 
                method: method,
                headers: { 
                    authorization: token,
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                }
            }
        )
        .then((response) => {
            if(response.status === 200){
                loadContent();
            }else{
                console.log(`${response.status} ${response.statusText}`);
            }
        })
        .catch(error => {                
            console.log("Error", error);
        })
        .finally(()=> {
            setShowDelDlg(false);
            setDeleted(null);

        });



    };

    const handleDelete = (key) => {
        console.log('delete', key);
        
        if(!data) return;
        
        const rows = data.Data.filter(p => (p.Key === key) );        
        if(rows && rows.length > 0){
            setDeleted(rows[0]);
            setShowDelDlg(true);
        }
    }

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }
            });
        }
        fetchUser();
        loadContent();
    }, [app, loadContent]);

    const columns = [
        {
          name: "Id",
          label: "Id",          
          options: {
            setCellProps: () => ({ className: classes.col1}), 
          }
        },
        // {
        //     name: "Predefined",
        //     label: "P",
        //     options: {
        //         filter: false,
        //         setCellProps: () => ({ className: classes.col2}), 
        //         customBodyRender: (value) => {
        //           return (
        //             value ? <LoyaltyOutlinedIcon color='secondary' style={{fontSize: 14}} /> : <LocalOfferOutlinedIcon style={{fontSize: 14}} />
        //           )
        //         }
        //     }
        // },
        {
            name: "ContentType",
            label: "CT",
            options: {
                filter: true,                
                // setCellProps: () => ({ className: classes.col2}), 
                customBodyRenderLite: (dataIndex) => {
                    return getIcon(data.Data[dataIndex])
                },
                // customBodyRender: (value) => {
                //   return (
                //     getIcon(value)
                //   )
                // }
            }
        },
        {
            name: "Key",
            label: "Key",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return (<>
                        <div>
                        <Link component={RouterLink} to={`/view/${data.Data[dataIndex].Key}`} >
                            {data.Data[dataIndex].Key}
                        </Link>                    
                        
                        </div>
                    </>)
                }
            }
        },
        {
            name: "Name",
            label: "Name",
        },
        {
          name: "StartedOn",
          label: "Start-Finish",
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              return (
                <>                
                <Typography variant='caption' component='div'>
                    {moment(data.Data[dataIndex].StartedOn).format("DD-MM-yyyy HH:mm:ss")}
                </Typography>
                <Typography variant='caption' component='div'>
                {moment(data.Data[dataIndex].FinishedOn).format("DD-MM-yyyy HH:mm:ss")}
                </Typography>
                </>
              )
            }
          }
        },        
        {
            name: "Key",
            label: " ",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Typography display='block' align='right'>
                        {/* <IconButton className={classes.mr} component={RouterLink} to={`/manage/content/${data.Data[dataIndex].Key}`}>
                        <span className="material-icons">edit</span>
                        </IconButton> */}

                        <IconButton color="primary" className={classes.mr} component={RouterLink} to={`/manage/pageeditor/${data.Data[dataIndex].Key}`}>
                            <span className="material-icons">edit_note</span>
                        </IconButton>

                        {!data.Data[dataIndex].Predefined &&
                            <IconButton color="secondary" onClick={()=> handleDelete(data.Data[dataIndex].Key)}>
                                <span className="material-icons">delete_outline</span>
                            </IconButton>
                        }
                        </Typography>
                    )
                }
            }
        },

        
    ];
      
    const options = {
        download: false,
        print: false,
        filter: true,
        page: page,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [20, 50, 100],
        serverSide: true,
        filterType: "textField",
        responsive: "simple",
        selectableRows: "none",
        searchText: search,
        count: data ? data.TotalCount: 0, 
        onTableChange: (action, tableState) => {
          switch (action) {
            case "changePage":          
              setPage(tableState.page);
              break;
            case "changeRowsPerPage":
              setPage(0);
              setPageSize(tableState.rowsPerPage);          
              break;
              case "search":
                {                                    
                    setPage(0);
                    setSearch(tableState.searchText ? tableState.searchText: "");                  
                  break;
                }
            default:
              break;
          }
        },
        
        customToolbar: () => {
          return (
              <>           
                <IconButton className={classes.mr} title="Add new contenet page" component={RouterLink} to='/manage/pageeditor' >
                  <AddIcon />
                </IconButton>
                <IconButton className={classes.mr} title="Refresh" onClick={loadContent}  >
                    <SyncIcon />
                </IconButton>
            </>
          )
        },
        
        // customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        //     return (
        //       <CustomSearchRender
        //         searchText={searchText}
        //         onSearch={handleSearch}
        //         onHide={hideSearch}
        //         options={options}
        //       />
        //     );
        // }

    
    };
    
    return (
        <AppConsumer>
            {() => (
                <div className={clsx(classes.root)}> 
                    {/* <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                        <Toolbar className={classes.toolbar}>
                        <IconButton className={classes.mr} title="Reload" >
                            <SyncIcon />
                        </IconButton>
                        </Toolbar>
                    </AppBar> */}

                    <Paper className={classes.paper} > 
                        <MUIDataTable 
                            responsive="standard"
                            title="Content pages"
                            data={data ?  data.Data: []}
                            columns={columns} 
                            options={options} 
                            // components={components} 
                        />    
                    </Paper>
                    
                    <Dialog
                        open={showDelDlg}
                        onClose={()=> setShowDelDlg(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="xs"
                        fullWidth={true}
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete page?"}</DialogTitle>                
                        <DialogContent>
                            { deleted &&
                                <Typography>
                                    Delete page: Id: {deleted.Id}. {deleted.Name}
                                </Typography>
                            }
                        </DialogContent>
                        <DialogActions>
                        <Button  onClick={()=> setShowDelDlg(false)}>
                            Cancel
                        </Button>
                        <Button color="primary" autoFocus onClick={deletePage}>
                            Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                                                            
                </div>
                
            )}
        </AppConsumer> 
  )
}

export default Contents