import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1,2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: 2,
      color: theme.palette.grey[500],
    },
  });

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

export const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(1,2),
    },
  }))(MuiDialogContent);


  export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0,2,1,2),
  },
}))(MuiDialogActions);
