import React, { useState, useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";


import { actionOrderCreators } from "../../store/Order";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";

import { useWidth} from '../../helpers/utils';

import { Paper, Grid, Typography } from "@material-ui/core";
import PromotionsThumb from "../Order/PromotionsThumb";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,        
            padding: theme.spacing(1),
        },                  
        paperTop: {
        padding: theme.spacing(1,2),
        marginBottom: theme.spacing(2),
        borderRadius: 8,
        boxShadow: theme.palette.ambilight.boxshadow
        },
        paper: {
            padding: theme.spacing(2),
            boxShadow: theme.palette.ambilight.boxshadow
        },        
        group: {
            [theme.breakpoints.down("sm")]: {           
                fontSize: "1.3rem",
                textAlign: "center",
            },
        },    
    })
);

export const Promo = (props) => {
    const classes = useStyles();
    const app = useContext(AppContext);
    const { t } = useTranslation();
    const width = useWidth();
    const history = useHistory();
    const [token, setToken] = useState(null);

    
    const { lang } = app;

    const dispatch = useDispatch();
    const { promotions, isLoading } = useSelector((state) =>  state.orderRequest );
    const cards = width === 'xs' ? [0] : [0, 1];

    const handleClickPromotions = (id) => {        
        history.push(`/promotion/${id}`)
    };

    // useEffect(()=> {
    //     dispatch(actionOrderCreators.requestPromotions(lang));
    // }, [dispatch, lang ]);

    useEffect(() => {
        // if(!token) return; 
        dispatch(actionOrderCreators.requestPromotions(lang));
    },[dispatch, lang, token]);


    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }else{
                    setToken('');
                }                                
            });
        }
        fetchUser();        
    }, [app]);

  return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>
                
                <Paper className={classes.paperTop} elevation={0}>
                    <Typography  className={classes.group} component="h2" variant="h4" >
                        {t("promotions.title")}
                    </Typography>
                </Paper>
                {isLoading && 
                <Grid container spacing={2} direction="row" > 
                    { cards.map((c) => (
                    <Grid item key={c} xs={12} sm={12} md={6} >
                        <PromotionsThumb  />
                    </Grid>
                    ))}  
                </Grid>
                }
                
                { promotions && !isLoading && 
                    <Grid container spacing={2} direction="row" > 
                        { promotions.map((item) => (
                            <Grid item key={item.Id} xs={12} sm={12} md={6} >
                                <PromotionsThumb item={item} clickByPromotions={()=> handleClickPromotions(item.Id)} ></PromotionsThumb>
                            </Grid>
                        ))}  
                    </Grid>
                }
            </div>
        )}
    </AppConsumer>
  )
}


export default connect(
    state => state.orderRequest,
    dispatch => bindActionCreators(actionOrderCreators, dispatch)
)(Promo)
