import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Grid, Box } from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {BlockType} from "../../../definitions/pageblocktype";

import TitleBlock from "./TitleBlock";
import HtmlBlock from "./HtmlBlock";
import VideoLinkBlock from "./VideoLinkBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import LogoImage from "./LogoImage";
import BonuseNewcomer from "./Widgets/BonuseNewcomer";
import PdfBlock from "./PdfBlock";

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center'
        },
        


    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const ColumnsBlockView = props => {
    const { block } = props;
    const classes = useStyles();
    
    
  return (
    <div className={classes.root}>
        
        {block && block.Childs &&
        <Grid container direction="row" spacing={0} justifyContent="center" alignItems="flex-start">
            { block.Childs.map((child) => (
                <Grid item key={child.Key} xs={12} sm={6}>
                    <Box p={1}>
                                                
                        {child.Content && child.PageBlockType ===  BlockType.TITLE &&  child.Content.LocalValues &&  child.Content.LocalValues.length > 0 &&
                        
                            <TitleBlock
                                localValue={ child.Content.LocalValues[0]}
                                blockKey={ child.Key }
                                propsBlock={child.Content.Props}
                            />
                        }

                        {child.Content && child.PageBlockType === BlockType.HTMLBLOCK && child.Content.LocalValues && 
                            <HtmlBlock
                                localValue={ child.Content.LocalValues[0]}
                                blockKey={ child.Key }
                            />
                        }

                        {child.Content && child.PageBlockType === BlockType.IMAGE && child.Content.LocalValues && 
                            <ImageBlock url={ child.Content.LocalValues[0].Value } />
                        }

                        
                        {child.Content && child.PageBlockType === BlockType.BIGSLIDER && child.Content.LocalValues &&
                            <BigSlider slider={ child.Content } blockKey={ block.Key } autoplay={ false } />
                        }

                        {child.Content && child.PageBlockType === BlockType.MIDDLESLIDER && child.Content.LocalValues && 
                            <MiddleSlider slider={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.SMALLSLIDER && child.Content.LocalValues && 
                            <SmallSlider slider={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.LOGOIMAGE &&  child.Content.LocalValues && 
                            <LogoImage content={ child.Content } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.WGBONUSENEWCOMER && child.Content.LocalValues && 
                            <BonuseNewcomer data={ child.Content.Data } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.PDFVIEWER && child.Content.LocalValues && 
                            <PdfBlock url={ child.Content.LocalValues[0].Value } blockKey={ block.Key } />
                        }

                        {child.Content && child.PageBlockType === BlockType.VIDEOLINK && child.Content.LocalValues && 
                            <VideoLinkBlock url={ child.Content.LocalValues[0].Value} />
                        }

                        {child.Content && child.PageBlockType === BlockType.VIDEO && child.Content.LocalValues && 
                            <VideoBlock url={ child.Content.LocalValues[0].Value} />
                        }                        
                                        
                    </Box>
                </Grid>
            ))}
        </Grid>
        }
    </div>
  )
}

ColumnsBlockView.propTypes = {
    block: PropTypes.object.isRequired,
 
}

export default ColumnsBlockView;