import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {Paper, Grid, Typography} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1)
        },
        paper: {
            padding: theme.spacing(3,2),
            borderRadius: theme.spacing(2),
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        },
    })
);   

const NotFound = (props) => {
    const { text } = props;

    const classes = useStyles();
    const { t } = useTranslation();
  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <Grid 
                container 
                direction="row" 
                justifyContent="center" 
                alignItems="center" 
            >
                <Grid item xs={11} sm={10} md={4}>
                    <img style={{width: '100%'}} src="./assets/images/404.svg" alt="empty" />
                </Grid>
            </Grid>
            <Typography variant='h5' style={{fontWeight: '100'}} display='block' align='center'>{t("common.SearchNotFound", {str: text})}</Typography>
            {/* <Alert variant="outlined" severity="warning">
                {t("common.SearchNotFound", {str: text})}
            </Alert> */}
        </Paper>
    </div>
  )
}

NotFound.propTypes = {
    text: PropTypes.string,
    
}


export default NotFound;