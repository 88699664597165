
import "./styles/index.css";
import React, {useState} from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import { CookiesProvider } from "react-cookie";
import { AppProvider } from "./providers/appProvider";

import { StrictMode } from 'react';
import App from "./App";
import { SnackbarProvider } from "notistack";
import "./i18n";
import CustormSnackbar from './components/Subscribe/CustormSnackbar';

// import ReactGA from "react-ga";
// import TagManager from "react-gtm-module";

window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
// TODO: Enable google tag manager
// TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER});
// window.dataLayer = window.dataLayer || [];
// function gtag() {        
//     window.dataLayer.push(arguments);
// };         
// gtag('consent', 'default', {
//     'ad_storage': "denied",
//     'analytics_storage': "denied",
//     'ad_user_data': "denied",
//     'ad_personalization': "denied"
// });

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
const rootElement = document.getElementById("root");

const Root = () => {

    
  return (    
      <AppProvider>
        <CookiesProvider>
          <Provider store={store}>
              <ConnectedRouter history={history}>
                <SnackbarProvider 
                    maxSnack={5} 
                    Components={{
                        custormSnackbar: CustormSnackbar
                    }}
                >
                    <App />
                </SnackbarProvider>
              </ConnectedRouter>
          </Provider>
        </CookiesProvider>
      </AppProvider>    
  );
}

ReactDOM.render(<Root />, rootElement );