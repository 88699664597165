
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import uuidv4 from "uuid/v4";
// import Autocomplete from "@material-ui/lab/Autocomplete";

// eslint-disable-next-line no-unused-vars
import {List, ListItem, ListItemIcon, ListItemText, Box, InputLabel,  TextField, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
// import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },    
    button: {
      marginRight: theme.spacing(1)
    },
}));

const CarouselEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [slide, setSlide] = useState(null)
        
  const handleUpdate = () => {
    if(onUpdate){  
      onUpdate(component);
    }
  };

  const handleAddSlide = () => {
    const add = {
      Id: uuidv4(),
      Title: `Slide # ${component.props.slides.length+1} title`,
      SubTitle: `Sub title slide #${component.props.slides.length+1}`,
      Media: "",
      Url: "",
    };
    component.props.slides.push(add);
    setSlide(add);
    setComponent({...component});  
  };

  const handleEditSlide = (idx) => {
    setSlide(component.props.slides[idx]);    
  };

  const handleDeleteSlide = (idx) => {
    if(slide && slide.Id === component.props.slides[idx].Id)
      setSlide(null);
    
    component.props.slides.splice(idx, 1);
    setComponent({...component});
  };

  const handleChangeProperty = (e) => {
    if(!slide) return;
    slide[e.target.name] = e.target.value;
    setComponent({...component});
  }

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={classes.root}>
        {component && 
          <Box pl={1} pr={1} pt={2}>                        
            <List 
              component="nav"
              aria-labelledby="properties-list"
              dense={true}
            >
              <ListItem button onClick={handleAddSlide} >
                <ListItemText primary="Add slide" />                      
                    <AddIcon fontSize="inherit" />                      
              </ListItem>
              { component.props.slides.map((slide, idx) => <ListItem button key={slide.Id} onClick={()=>handleEditSlide(idx)}>
                <ListItemText primary={`${idx+1}. ${slide.Title}`} />                    
                  <IconButton aria-label="delete-slide" size="small" onClick={()=>handleDeleteSlide(idx)}>
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                
                </ListItem> 
              )}
            </List>
                            
              {slide &&
                <>
                <TextField
                  label="Media image or video"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name="Media"
                  size="small"
                  aria-label="slider-media" placeholder="Image or video"
                  value={slide.Media}
                  onChange={handleChangeProperty}
                  />
                <TextField
                  label="URL"
                  margin="dense"
                  variant="outlined"              
                  fullWidth
                  name="Url"                  
                  size="small"
                  aria-label="slider-url" placeholder="URL"
                  value={slide.Url} 
                  onChange={handleChangeProperty}
                  />
                <TextField
                  label="Title"
                  margin="dense"
                  variant="outlined"                   
                  fullWidth
                  name="Title"                  
                  size="small"
                  aria-label="slider-title" placeholder="Title"
                  value={slide.Title}
                  onChange={handleChangeProperty}          
                  />
                  <TextField
                  label="Subtitle"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name="SubTitle"                  
                  size="small"
                  aria-label="slider-subtitle" placeholder="Title"
                  value={slide.SubTitle}
                  onChange={handleChangeProperty}
                  />              
                </>
              }
                              
            <Box p={2}>
              <Button 
                fullWidth 
                size="small" 
                variant="outlined" 
                color='primary' 
                onClick={handleUpdate} startIcon={<CheckIcon />}
              >
                Applay
              </Button>
            </Box>                                    
          </Box>
        }
    </div>
  )
}

CarouselEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default CarouselEditor