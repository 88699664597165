const countries = [

    // { code: 'AD', name: 'Andorra', phone: '376' },
    { code: 'AE', name: 'United Arab Emirates', phone: '971' },
    // { code: 'AF', name: 'Afghanistan', phone: '93' },
    // { code: 'AG', name: 'Antigua and Barbuda', phone: '1-268' },
    // { code: 'AI', name: 'Anguilla', phone: '1-264' },
    // { code: 'AL', name: 'Albania', phone: '355' },
    // { code: 'AM', name: 'Armenia', phone: '374' },
    // { code: 'AO', name: 'Angola', phone: '244' },
    // { code: 'AQ', name: 'Antarctica', phone: '672' },
    // { code: 'AR', name: 'Argentina', phone: '54' },
    // { code: 'AS', name: 'American Samoa', phone: '1-684' },
    { code: 'AT', name: 'Austria', phone: '43' },
    // { code: 'AU', name: 'Australia', phone: '61', suggested: true },
    // { code: 'AW', name: 'Aruba', phone: '297' },
    // { code: 'AX', name: 'Alland Islands', phone: '358' },
    // { code: 'AZ', name: 'Azerbaijan', phone: '994' },
    { code: 'BA', name: 'Bosnia and Herzegovina', phone: '387' },
    // { code: 'BB', name: 'Barbados', phone: '1-246' },
    // { code: 'BD', name: 'Bangladesh', phone: '880' },
    { code: 'BE', name: 'Belgium', phone: '32' },
    // { code: 'BF', name: 'Burkina Faso', phone: '226' },
    { code: 'BG', name: 'Bulgaria', phone: '359' },
    // { code: 'BH', name: 'Bahrain', phone: '973' },
    // { code: 'BI', name: 'Burundi', phone: '257' },
    // { code: 'BJ', name: 'Benin', phone: '229' },
    // { code: 'BL', name: 'Saint Barthelemy', phone: '590' },
    // { code: 'BM', name: 'Bermuda', phone: '1-441' },
    // { code: 'BN', name: 'Brunei Darussalam', phone: '673' },
    // { code: 'BO', name: 'Bolivia', phone: '591' },
    // { code: 'BR', name: 'Brazil', phone: '55' },
    // { code: 'BS', name: 'Bahamas', phone: '1-242' },
    // { code: 'BT', name: 'Bhutan', phone: '975' },
    // { code: 'BV', name: 'Bouvet Island', phone: '47' },
    // { code: 'BW', name: 'Botswana', phone: '267' },
    // { code: 'BY', name: 'Belarus', phone: '375' },
    // { code: 'BZ', name: 'Belize', phone: '501' },
    // { code: 'CA', name: 'Canada', phone: '1', suggested: true },
    // { code: 'CC', name: 'Cocos (Keeling) Islands', phone: '61' },
    // { code: 'CD', name: 'Congo, Democratic Republic of the', phone: '243' },
    // { code: 'CF', name: 'Central African Republic', phone: '236' },
    // { code: 'CG', name: 'Congo, Republic of the', phone: '242' },
    // { code: 'CH', name: 'Switzerland', phone: '41' },
    // { code: 'CI', name: "Cote d'Ivoire", phone: '225' },
    // { code: 'CK', name: 'Cook Islands', phone: '682' },
    // { code: 'CL', name: 'Chile', phone: '56' },
    // { code: 'CM', name: 'Cameroon', phone: '237' },
    // { code: 'CN', name: 'China', phone: '86' },
    // { code: 'CO', name: 'Colombia', phone: '57' },
    // { code: 'CR', name: 'Costa Rica', phone: '506' },
    // { code: 'CU', name: 'Cuba', phone: '53' },
    // { code: 'CV', name: 'Cape Verde', phone: '238' },
    // { code: 'CW', name: 'Curacao', phone: '599' },
    // { code: 'CX', name: 'Christmas Island', phone: '61' },
    // { code: 'CY', name: 'Cyprus', phone: '357' },
    { code: 'CZ', name: 'Czech Republic', phone: '420' },
    { code: 'DE', name: 'Germany', phone: '49', suggested: true },
    // { code: 'DJ', name: 'Djibouti', phone: '253' },
    // { code: 'DK', name: 'Denmark', phone: '45' },
    // { code: 'DM', name: 'Dominica', phone: '1-767' },
    // { code: 'DO', name: 'Dominican Republic', phone: '1-809' },
    // { code: 'DZ', name: 'Algeria', phone: '213' },
    // { code: 'EC', name: 'Ecuador', phone: '593' },
    { code: 'EE', name: 'Estonia', phone: '372' },
    // { code: 'EG', name: 'Egypt', phone: '20' },
    // { code: 'EH', name: 'Western Sahara', phone: '212' },
    // { code: 'ER', name: 'Eritrea', phone: '291' },
    { code: 'ES', name: 'Spain', phone: '34' },
    // { code: 'ET', name: 'Ethiopia', phone: '251' },
    // { code: 'FI', name: 'Finland', phone: '358' },
    // { code: 'FJ', name: 'Fiji', phone: '679' },
    // { code: 'FK', name: 'Falkland Islands (Malvinas)', phone: '500' },
    // { code: 'FM', name: 'Micronesia, Federated States of', phone: '691' },
    // { code: 'FO', name: 'Faroe Islands', phone: '298' },
    { code: 'FR', name: 'France', phone: '33', suggested: true },
    // { code: 'GA', name: 'Gabon', phone: '241' },
    { code: 'GB', name: 'United Kingdom', phone: '44' },
    // { code: 'GD', name: 'Grenada', phone: '1-473' },
    // { code: 'GE', name: 'Georgia', phone: '995' },
    // { code: 'GF', name: 'French Guiana', phone: '594' },
    // { code: 'GG', name: 'Guernsey', phone: '44' },
    // { code: 'GH', name: 'Ghana', phone: '233' },
    // { code: 'GI', name: 'Gibraltar', phone: '350' },
    // { code: 'GL', name: 'Greenland', phone: '299' },
    // { code: 'GM', name: 'Gambia', phone: '220' },
    // { code: 'GN', name: 'Guinea', phone: '224' },
    // { code: 'GP', name: 'Guadeloupe', phone: '590' },
    // { code: 'GQ', name: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', name: 'Greece', phone: '30' },
    // { code: 'GS', name: 'South Georgia and the South Sandwich Islands', phone: '500' },
    // { code: 'GT', name: 'Guatemala', phone: '502' },
    // { code: 'GU', name: 'Guam', phone: '1-671' },
    // { code: 'GW', name: 'Guinea-Bissau', phone: '245' },
    // { code: 'GY', name: 'Guyana', phone: '592' },
    // { code: 'HK', name: 'Hong Kong', phone: '852' },
    // { code: 'HM', name: 'Heard Island and McDonald Islands', phone: '672' },
    // { code: 'HN', name: 'Honduras', phone: '504' },
    { code: 'HR', name: 'Croatia', phone: '385' },
    // { code: 'HT', name: 'Haiti', phone: '509' },
    { code: 'HU', name: 'Hungary', phone: '36' },
    // { code: 'ID', name: 'Indonesia', phone: '62' },
    // { code: 'IE', name: 'Ireland', phone: '353' },
    // { code: 'IL', name: 'Israel', phone: '972' },
    // { code: 'IM', name: 'Isle of Man', phone: '44' },
    // { code: 'IN', name: 'India', phone: '91' },
    // { code: 'IO', name: 'British Indian Ocean Territory', phone: '246' },
    // { code: 'IQ', name: 'Iraq', phone: '964' },
    // { code: 'IR', name: 'Iran, Islamic Republic of', phone: '98' },
    // { code: 'IS', name: 'Iceland', phone: '354' },
    { code: 'IT', name: 'Italy', phone: '39' },
    // { code: 'JE', name: 'Jersey', phone: '44' },
    // { code: 'JM', name: 'Jamaica', phone: '1-876' },
    // { code: 'JO', name: 'Jordan', phone: '962' },
    // { code: 'JP', name: 'Japan', phone: '81', suggested: true },
    // { code: 'KE', name: 'Kenya', phone: '254' },
    // { code: 'KG', name: 'Kyrgyzstan', phone: '996' },
    // { code: 'KH', name: 'Cambodia', phone: '855' },
    // { code: 'KI', name: 'Kiribati', phone: '686' },
    // { code: 'KM', name: 'Comoros', phone: '269' },
    // { code: 'KN', name: 'Saint Kitts and Nevis', phone: '1-869' },
    // { code: 'KP', name: "Korea, Democratic People's Republic of", phone: '850' },
    // { code: 'KR', name: 'Korea, Republic of', phone: '82' },
    // { code: 'KW', name: 'Kuwait', phone: '965' },
    // { code: 'KY', name: 'Cayman Islands', phone: '1-345' },
    // { code: 'KZ', name: 'Kazakhstan', phone: '7' },
    // { code: 'LA', name: "Lao People's Democratic Republic", phone: '856' },
    // { code: 'LB', name: 'Lebanon', phone: '961' },
    // { code: 'LC', name: 'Saint Lucia', phone: '1-758' },
    // { code: 'LI', name: 'Liechtenstein', phone: '423' },
    // { code: 'LK', name: 'Sri Lanka', phone: '94' },
    // { code: 'LR', name: 'Liberia', phone: '231' },
    // { code: 'LS', name: 'Lesotho', phone: '266' },
    { code: 'LT', name: 'Lithuania', phone: '370' },
    // { code: 'LU', name: 'Luxembourg', phone: '352' },
    { code: 'LV', name: 'Latvia', phone: '371' },
    // { code: 'LY', name: 'Libya', phone: '218' },
    // { code: 'MA', name: 'Morocco', phone: '212' },
    // { code: 'MC', name: 'Monaco', phone: '377' },
    { code: 'MD', name: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', name: 'Montenegro', phone: '382' },
    // { code: 'MF', name: 'Saint Martin (French part)', phone: '590' },
    // { code: 'MG', name: 'Madagascar', phone: '261' },
    // { code: 'MH', name: 'Marshall Islands', phone: '692' },
    { code: 'MK', name: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    // { code: 'ML', name: 'Mali', phone: '223' },
    // { code: 'MM', name: 'Myanmar', phone: '95' },
    // { code: 'MN', name: 'Mongolia', phone: '976' },
    // { code: 'MO', name: 'Macao', phone: '853' },
    // { code: 'MP', name: 'Northern Mariana Islands', phone: '1-670' },
    // { code: 'MQ', name: 'Martinique', phone: '596' },
    // { code: 'MR', name: 'Mauritania', phone: '222' },
    // { code: 'MS', name: 'Montserrat', phone: '1-664' },
    // { code: 'MT', name: 'Malta', phone: '356' },
    // { code: 'MU', name: 'Mauritius', phone: '230' },
    // { code: 'MV', name: 'Maldives', phone: '960' },
    // { code: 'MW', name: 'Malawi', phone: '265' },
    // { code: 'MX', name: 'Mexico', phone: '52' },
    // { code: 'MY', name: 'Malaysia', phone: '60' },
    // { code: 'MZ', name: 'Mozambique', phone: '258' },
    // { code: 'NA', name: 'Namibia', phone: '264' },
    // { code: 'NC', name: 'New Caledonia', phone: '687' },
    // { code: 'NE', name: 'Niger', phone: '227' },
    // { code: 'NF', name: 'Norfolk Island', phone: '672' },
    // { code: 'NG', name: 'Nigeria', phone: '234' },
    // { code: 'NI', name: 'Nicaragua', phone: '505' },
    { code: 'NL', name: 'Netherlands', phone: '31' },
    { code: 'NO', name: 'Norway', phone: '47' },
    // { code: 'NP', name: 'Nepal', phone: '977' },
    // { code: 'NR', name: 'Nauru', phone: '674' },
    // { code: 'NU', name: 'Niue', phone: '683' },
    { code: 'NZ', name: 'New Zealand', phone: '64' },
    // { code: 'OM', name: 'Oman', phone: '968' },
    // { code: 'PA', name: 'Panama', phone: '507' },
    // { code: 'PE', name: 'Peru', phone: '51' },
    // { code: 'PF', name: 'French Polynesia', phone: '689' },
    // { code: 'PG', name: 'Papua New Guinea', phone: '675' },
    // { code: 'PH', name: 'Philippines', phone: '63' },
    // { code: 'PK', name: 'Pakistan', phone: '92' },
    { code: 'PL', name: 'Poland', phone: '48' },
    // { code: 'PM', name: 'Saint Pierre and Miquelon', phone: '508' },
    // { code: 'PN', name: 'Pitcairn', phone: '870' },
    // { code: 'PR', name: 'Puerto Rico', phone: '1' },
    // { code: 'PS', name: 'Palestine, State of', phone: '970' },
    { code: 'PT', name: 'Portugal', phone: '351' },
    // { code: 'PW', name: 'Palau', phone: '680' },
    // { code: 'PY', name: 'Paraguay', phone: '595' },
    // { code: 'QA', name: 'Qatar', phone: '974' },
    // { code: 'RE', name: 'Reunion', phone: '262' },
    { code: 'RO', name: 'Romania', phone: '40' },
    { code: 'RS', name: 'Serbia', phone: '381' },
    // { code: 'RU', name: 'Russian Federation', phone: '7' },
    // { code: 'RW', name: 'Rwanda', phone: '250' },
    // { code: 'SA', name: 'Saudi Arabia', phone: '966' },
    // { code: 'SB', name: 'Solomon Islands', phone: '677' },
    // { code: 'SC', name: 'Seychelles', phone: '248' },
    // { code: 'SD', name: 'Sudan', phone: '249' },
    { code: 'SE', name: 'Sweden', phone: '46' },
    // { code: 'SG', name: 'Singapore', phone: '65' },
    // { code: 'SH', name: 'Saint Helena', phone: '290' },
    { code: 'SI', name: 'Slovenia', phone: '386' },
    // { code: 'SJ', name: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', name: 'Slovakia', phone: '421' },
    // { code: 'SL', name: 'Sierra Leone', phone: '232' },
    // { code: 'SM', name: 'San Marino', phone: '378' },
    // { code: 'SN', name: 'Senegal', phone: '221' },
    // { code: 'SO', name: 'Somalia', phone: '252' },
    // { code: 'SR', name: 'Suriname', phone: '597' },
    // { code: 'SS', name: 'South Sudan', phone: '211' },
    // { code: 'ST', name: 'Sao Tome and Principe', phone: '239' },
    // { code: 'SV', name: 'El Salvador', phone: '503' },
    // { code: 'SX', name: 'Sint Maarten (Dutch part)', phone: '1-721' },
    // { code: 'SY', name: 'Syrian Arab Republic', phone: '963' },
    // { code: 'SZ', name: 'Swaziland', phone: '268' },
    // { code: 'TC', name: 'Turks and Caicos Islands', phone: '1-649' },
    // { code: 'TD', name: 'Chad', phone: '235' },
    // { code: 'TF', name: 'French Southern Territories', phone: '262' },
    // { code: 'TG', name: 'Togo', phone: '228' },
    // { code: 'TH', name: 'Thailand', phone: '66' },
    // { code: 'TJ', name: 'Tajikistan', phone: '992' },
    // { code: 'TK', name: 'Tokelau', phone: '690' },
    // { code: 'TL', name: 'Timor-Leste', phone: '670' },
    // { code: 'TM', name: 'Turkmenistan', phone: '993' },
    // { code: 'TN', name: 'Tunisia', phone: '216' },
    // { code: 'TO', name: 'Tonga', phone: '676' },
    // { code: 'TR', name: 'Turkey', phone: '90' },
    // { code: 'TT', name: 'Trinidad and Tobago', phone: '1-868' },
    // { code: 'TV', name: 'Tuvalu', phone: '688' },
    // { code: 'TW', name: 'Taiwan, Province of China', phone: '886' },
    // { code: 'TZ', name: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', name: 'Ukraine', phone: '380' },
    // { code: 'UG', name: 'Uganda', phone: '256' },
    // { code: 'US', name: 'United States', phone: '1', suggested: true },
    // { code: 'UY', name: 'Uruguay', phone: '598' },
    // { code: 'UZ', name: 'Uzbekistan', phone: '998' },
    // { code: 'VA', name: 'Holy See (Vatican City State)', phone: '379' },
    // { code: 'VC', name: 'Saint Vincent and the Grenadines', phone: '1-784' },
    // { code: 'VE', name: 'Venezuela', phone: '58' },
    // { code: 'VG', name: 'British Virgin Islands', phone: '1-284' },
    // { code: 'VI', name: 'US Virgin Islands', phone: '1-340' },
    // { code: 'VN', name: 'Vietnam', phone: '84' },
    // { code: 'VU', name: 'Vanuatu', phone: '678' },
    // { code: 'WF', name: 'Wallis and Futuna', phone: '681' },
    // { code: 'WS', name: 'Samoa', phone: '685' },
    // { code: 'XK', name: 'Kosovo', phone: '383' },
    // { code: 'YE', name: 'Yemen', phone: '967' },
    // { code: 'YT', name: 'Mayotte', phone: '262' },
    // { code: 'ZA', name: 'South Africa', phone: '27' },
    // { code: 'ZM', name: 'Zambia', phone: '260' },
    // { code: 'ZW', name: 'Zimbabwe', phone: '263' },
];

const phonesCultureInfo = {
    'am-AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    'ar-AE': /^((\+?971)|0)?5[024568]\d{7}$/,
    'ar-BH': /^(\+?973)?(3|6)\d{7}$/,
    'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
    'ar-EG': /^((\+?20)|0)?1[0125]\d{8}$/,
    'ar-IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
    'ar-JO': /^(\+?962|0)?7[789]\d{7}$/,
    'ar-KW': /^(\+?965)[569]\d{7}$/,
    'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
    'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
    'ar-TN': /^(\+?216)?[2459]\d{7}$/,
    'be-BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    'bg-BG': /^(\+?359|0)?8[789]\d{7}$/,
    'bn-BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
    'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'da-DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'de-DE': /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
    'de-AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
    'el-GR': /^(\+?30|0)?(69\d{8})$/,
    'en-AU': /^(\+?61|0)4\d{8}$/,
    'en-GB': /^(\+?44|0)7\d{9}$/,
    'en-GG': /^(\+?44|0)1481\d{6}$/,
    'en-GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
    'en-HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    'en-MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    'en-IE': /^(\+?353|0)8[356789]\d{7}$/,
    'en-IN': /^(\+?91|0)?[6789]\d{9}$/,
    'en-KE': /^(\+?254|0)(7|1)\d{8}$/,
    'en-MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    'en-MU': /^(\+?230|0)?\d{8}$/,
    'en-NG': /^(\+?234|0)?[789]\d{9}$/,
    'en-NZ': /^(\+?64|0)[28]\d{7,9}$/,
    'en-PK': /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    'en-RW': /^(\+?250|0)?[7]\d{8}$/,
    'en-SG': /^(\+65)?[89]\d{7}$/,
    'en-SL': /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
    'en-TZ': /^(\+?255|0)?[67]\d{8}$/,
    'en-UG': /^(\+?256|0)?[7]\d{8}$/,
    'en-US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    'en-ZA': /^(\+?27|0)\d{9}$/,
    'en-ZM': /^(\+?26)?09[567]\d{7}$/,
    'es-CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
    'es-EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
    'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    'es-MX': /^(\+?52)?(1|01)?\d{10,11}$/,
    'es-PA': /^(\+?507)\d{7,8}$/,
    'es-PY': /^(\+?595|0)9[9876]\d{7}$/,
    'es-UY': /^(\+598|0)9[1-9][\d]{6}$/,
    'et-EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    'fa-IR': /^(\+?98[/\-\s]?|0)9[0-39]\d[/\-\s]?\d{3}[/\-\s]?\d{4}$/,
    'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    'fj-FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
    'fo-FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'fr-FR': /^(\+?33|0)[67]\d{8}$/,
    'fr-GF': /^(\+?594|0|00594)[67]\d{8}$/,
    'fr-GP': /^(\+?590|0|00590)[67]\d{8}$/,
    'fr-MQ': /^(\+?596|0|00596)[67]\d{8}$/,
    'fr-RE': /^(\+?262|0|00262)[67]\d{8}$/,
    'he-IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
    'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
    'id-ID': /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'ja-JP': /^(\+81[-]?(\(0\))?|0)[6789]0[-]?\d{4}[-]?\d{4}$/,
    'kk-KZ': /^(\+?7|8)?7\d{9}$/,
    'kl-GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'ko-KR': /^((\+?82)[-]?)?0?1([0|1|6|7|8|9]{1})[-]?\d{3,4}[-]?\d{4}$/,
    'lt-LT': /^(\+370|8)\d{8}$/,
    'ms-MY': /^(\+?6?01){1}(([0145]{1}(-|\s)?\d{7,8})|([236789]{1}(\s|-)?\d{7}))$/,
    'nb-NO': /^(\+?47)?[49]\d{7}$/,
    'ne-NP': /^(\+?977)?9[78]\d{8}$/,
    'nl-BE': /^(\+?32|0)4?\d{8}$/,
    'nl-NL': /^(\+?31|0)6?\d{8}$/,
    'nn-NO': /^(\+?47)?[49]\d{7}$/,
    'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'pt-BR': /(?=^(\+?5{2}-?|0)[1-9]{2}-?\d{4}-?\d{4}$)(^(\+?5{2}-?|0)[1-9]{2}-?[6-9]{1}\d{3}-?\d{4}$)|(^(\+?5{2}-?|0)[1-9]{2}-?9[6-9]{1}\d{3}-?\d{4}$)/,
    'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
    'ro-RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|-)?\d{3}(\s|\.|-)?\d{3}$/,
    'ru-RU': /^(\+?7|8)?9\d{9}$/,
    'sl-SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
    'sk-SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
    'sv-SE': /^(\+?46|0)[\s-]?7[\s-]?[02369]([\s-]?\d){7}$/,
    'th-TH': /^(\+66|66|0)\d{9}$/,
    'tr-TR': /^(\+?90|0)?5\d{9}$/,
    'uk-UA': /^(\+?38|8)?0\d{9}$/,
    'vi-VN': /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
    'zh-CN': /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
    'zh-TW': /^(\+?886-?|0)?9\d{8}$/,
};

const phones = {
    'AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    'AE': /^((\+?971)|0)?5[024568]\d{7}$/,
    'BH': /^(\+?973)?(3|6)\d{7}$/,
    'DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
    'EG': /^((\+?20)|0)?1[0125]\d{8}$/,
    'IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
    'JO': /^(\+?962|0)?7[789]\d{7}$/,
    'KW': /^(\+?965)[569]\d{7}$/,
    'SA': /^(!?(\+?966)|0)?5\d{8}$/,
    'SY': /^(!?(\+?963)|0)?9\d{8}$/,
    'TN': /^(\+?216)?[2459]\d{7}$/,
    // 'BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    'BG': /^(\+?359|0)?8[789]\d{7}$/,
    'BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
    'CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'DE': /(\(?([\d \-)–+/(]+){11,}\)?([ .\-–/]?)([\d]+))/,
    'AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
    'GR': /^(\+?30|0)?(69\d{8})$/,
    'AU': /^(\+?61|0)4\d{8}$/,
    'GB': /^(\+?44|0)7\d{9}$/,
    'GG': /^(\+?44|0)1481\d{6}$/,
    'GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
    'HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    'MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    'IE': /^(\+?353|0)8[356789]\d{7}$/,
    'IN': /^(\+?91|0)?[6789]\d{9}$/,
    'KE': /^(\+?254|0)(7|1)\d{8}$/,
    'MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    'MU': /^(\+?230|0)?\d{8}$/,
    'NG': /^(\+?234|0)?[789]\d{9}$/,
    'NZ': /^(\+?64|0)[28]\d{7,9}$/,
    'PK': /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    'RW': /^(\+?250|0)?[7]\d{8}$/,
    'SG': /^(\+65)?[89]\d{7}$/,
    'SL': /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
    'TZ': /^(\+?255|0)?[67]\d{8}$/,
    'UG': /^(\+?256|0)?[7]\d{8}$/,
    'US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    'ZA': /^(\+?27|0)\d{9}$/,
    'ZM': /^(\+?26)?09[567]\d{7}$/,
    'CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
    'EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
    'ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    'MX': /^(\+?52)?(1|01)?\d{10,11}$/,
    'PA': /^(\+?507)\d{7,8}$/,
    'PY': /^(\+?595|0)9[9876]\d{7}$/,
    'UY': /^(\+598|0)9[1-9][\d]{6}$/,
    'EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    'IR': /^(\+?98[-\s]?|0)9[0-39]\d[-\s]?\d{3}[-\s]?\d{4}$/,
    'FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    'FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
    'FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'FR': /^(\+?33|0)[67]\d{8}$/,
    'GF': /^(\+?594|0|00594)[67]\d{8}$/,
    'GP': /^(\+?590|0|00590)[67]\d{8}$/,
    'MQ': /^(\+?596|0|00596)[67]\d{8}$/,
    'RE': /^(\+?262|0|00262)[67]\d{8}$/,
    'IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
    'HU': /^(\+?36)(20|30|70)\d{7}$/,
    'ID': /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    'IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    'JP': /^(\+81[ -]?(\(0\))?|0)[6789]0[ -]?\d{4}[ -]?\d{4}$/,
    'KZ': /^(\+?7|8)?7\d{9}$/,
    'GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    'KR': /^((\+?82)[ -]?)?0?1([0|1|6|7|8|9]{1})[ -]?\d{3,4}[ -]?\d{4}$/,
    'LT': /^(\+370|8)\d{8}$/,
    'MY': /^(\+?6?01){1}(([0145]{1}(-|\s)?\d{7,8})|([236789]{1}(\s|-)?\d{7}))$/,
    'NO': /^(\+?47)?[49]\d{7}$/,
    'NP': /^(\+?977)?9[78]\d{8}$/,
    'BE': /^(\+?32|0)4?\d{8}$/,
    'NL': /^(\+?31|0)6?\d{8}$/,    
    'PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    'BR': /(?=^(\+?5{2}-?|0)[1-9]{2}-?\d{4}-?\d{4}$)(^(\+?5{2}-?|0)[1-9]{2}-?[6-9]{1}\d{3}-?\d{4}$)|(^(\+?5{2}-?|0)[1-9]{2}-?9[6-9]{1}\d{3}-?\d{4}$)/,
    'PT': /^(\+?351)?9[1236]\d{7}$/,
    'RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|-)?\d{3}(\s|\.|-)?\d{3}$/,
    // 'RU': /^(\+?7|8)?9\d{9}$/,
    'SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
    'SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    'RS': /^(\+3816|06)[- \d]{5,9}$/,
    'SE': /^(\+?46|0)[\s-]?7[\s-]?[02369]([\s-]?\d){7}$/,
    'TH': /^(\+66|66|0)\d{9}$/,
    'TR': /^(\+?90|0)?5\d{9}$/,
    'UA': /^(\+?38|8)?0\d{9}$/,
    'VN': /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
    'CN': /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
    'TW': /^(\+?886-?|0)?9\d{8}$/,
};

const onlyCountries = ["ua", "pl", "de", "au", "az", "at", "be", "ba", "bg", "ca", "ch","cz","cy","dk", "ee", "fi", "fr", "gr", "hu", "il", "it", "jp", "kz", "lv", "lt", "md", "no", "pt", "ro", "sk", "si", "es", "se", "ch", "tr", "tm", "gb", "us"];

const excludeCountries = ["BY", "EE", "LV", "LT", "MD", "PL", "RU"];

const findCountyByToLetter = (twoLetterName) => {
    const coutry = countries.find(c => c.code === twoLetterName);
    return coutry ? coutry.name : twoLetterName
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

export { countries, excludeCountries, phones, phonesCultureInfo, onlyCountries, findCountyByToLetter, countryToFlag };