import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";

import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IconButton, Button, Dialog } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import { DialogActions, DialogContent, DialogTitle } from "./../CustomDialog";

import moment from "moment";
import "moment/locale/uk";
import { useTranslation } from "react-i18next";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CatalogItem from "./CatalogItem";
import CatalogFiles from "./CatalogFiles.jsx";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(0, 1/4)
        },
        title: {            
            fontWeight: 400,            
        },
        tabs: {            
            marginRight: theme.spacing(1)
        },
        flexGrow: {
            flexGrow: 1,
        },
        tabdescr: {
            // fontSize: '.6rem'
        },
        paper: {
            
            marginBottom: theme.spacing(2),
        },
        button: {
            marginRight: theme.spacing(1)
        }
    })
);

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {    
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
};

const rowsPerPageOptions = [10, 25, 50, 100];

const Catalogs = () => {
    const classes = useStyles();    
    const auth = useContext(AppContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const catalogRef = useRef();
    const width = useWidth();
    const [token, setToken] = useState();
    const [catalogs, setCatalogs] = useState([]);
    const [countries, setCountries] = useState([]);
    const [catalog, setCatalog] = useState();
    
    const [pageCatalogs, setPageCatalogs] = useState(0);
    const [rowsPerPageCatalogs, setRowsPerPageCatalogs] = useState(25);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openDlg, setOpenDlg] = useState(false);
    const [openDlgFiles, setOpenDlgFiles] = useState(false);


    const saveCatalog = () => {
        catalogRef.current.updateData();
    };

    const editCatalog = (idx) => {
        if(catalogs.length === 0) return;        
        // console.log('Edit catalog', catalogs[idx]);

        setCatalog(catalogs[idx]);
        setOpenDlg(true);
    };

    const editFilesCatalog = (idx) => {
        if(catalogs.length === 0) return;        
        setCatalog(catalogs[idx]);
        setOpenDlgFiles(true);
    };

    const getCatalogs = useCallback(() => {
        const url = `/api/v1.0/catalog/getcatalogs?pi=${pageCatalogs}&ps=${rowsPerPageCatalogs}`; 
        // console.log(url);             
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    // console.log('Catalogs', data);
                    setCatalogs(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });

    }, [pageCatalogs, rowsPerPageCatalogs, token]);

    // Get countries
    useEffect(() => {
        if(!token) return;
                
        fetch('/api/v1.0/Utils/Countries', { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    if (data) {                        
                        setCountries(data);
                    }
                })
            .finally(() => {
            });
    }, [token]);

    // Get catalogs
    useEffect(() => {
        if (!token) return;
        getCatalogs();        
    }, [token, getCatalogs]);

    // Get token
    useEffect(() => {
        if (!auth) return;

        function fetchUser() {
            auth.getUser().then(async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }
                setToken(token);
            });
        }
        fetchUser();

    }, [auth]);


    const columns = [
        {
            name: "Current",
            label: "!",            
            options: { 
                setCellProps: () => ({ style: { width: "16px" }}),
                customBodyRender: (value) => {
                  return (
                    value ? <AccessTimeIcon color='secondary' /> : null 
                  )
                }
              }       
        },
        {
          name: "Number",
          label: "Number",          
        },
        {
          name: "PlanedStartDate",
          label: "Start",
          options: {
            customBodyRender: (value) => {
              return (
                moment(value).format("DD-MM-YYYY")
              )
            }
          }
        },
        {
            name: "PlanedFinishDate",
            label: "Finish",
            options: {            
              customBodyRender: (value) => {
                return (
                  moment(value).format("DD-MM-YYYY")
                )
              }
            }
          },
          {
            name: "Name",
            label: "Name",
            options: {
              filter: false,
            }
          },
          {
            name: "Act",
            label: " ",
            options: {
              filter: false,
              sort: false,
              empty: true,
              setCellProps: () => ({ style: { width: "128px" }}),
              customBodyRenderLite: (dataIndex, rowIndex) => {
                return (
                  <>
                  <IconButton onClick={() => {                
                      editFilesCatalog(dataIndex);
                    }}>                
                        <PictureAsPdfOutlinedIcon />
                  </IconButton>

                  <IconButton onClick={() => {                
                      editCatalog(dataIndex);
                    }}>                
                        <EditIcon />
                  </IconButton>
                  </>
                );
              }
            }
          },
        
    ];

    const options = {
        download: false,
        print: false,
        filter: true,
        page: pageCatalogs,
        rowsPerPage: rowsPerPageCatalogs,
        rowsPerPageOptions: rowsPerPageOptions,
        // serverSide: true,
        filterType: "dropdown",
        responsive: "standard",
        selectableRows: "none",
        // searchText: phone,
        count: catalogs ? catalogs.length : 0, 
        onTableChange: (action, tableState) => {
          switch (action) {
            case "changePage":          
              // setPage(tableState.page);
              break;
            case "changeRowsPerPage":
              // setPage(0);
              // setPageSize(tableState.rowsPerPage);          
              break;
              case "search":
                {
                //   const phone = tableState.searchText ? tableState.searchText: "";
                //   if(phone.length === 0 || phone.length > 11 ) {
                //     setPage(0);
                //     setPhone(phone);            
                //   }
                  break;
                }
            default:
              break;
          }
        },
        setTableProps: () => {
          return {
            // material ui v5 only
            size: 'small',
          };
        },                    
    };    

    const handleCloseDlgFiles = () => {
        setOpenDlgFiles(false);
    }

    const handleSaveDlgFiles = (files) => {        
        setOpenDlgFiles(false);
    }
        
    return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>                                
                <MUIDataTable 
                    responsive="standard"
                    title="Catalogs"
                    data={catalogs}                            
                    columns={columns} 
                    options={options}                    
                /> 
            
            {catalog && openDlgFiles && 
                <CatalogFiles 
                    isOpen={openDlgFiles} 
                    catalog={catalog} 
                    countries={countries}
                    onClose={handleCloseDlgFiles}
                    onSave={handleSaveDlgFiles}
                />
            }
            
            {catalog && 
            <Dialog 
                onClose={() => setOpenDlg(false)} 
                aria-labelledby="catalog-dialog-title" 
                open={openDlg} 
                fullWidth={true} 
                maxWidth="xl" 
                fullScreen={fullScreen} 
            >
                <DialogTitle className={classes.dialogtitle} onClose={() => setOpenDlg(false)}>
                { catalog.Current ? <AccessTimeIcon color='secondary' /> : null } {catalog ? `№ ${catalog.Number}: ${catalog.Name}` : undefined}
                </DialogTitle>
                <DialogContent style={{height: 660, paddingTop: 8}}>
                    <CatalogItem  
                        ref={catalogRef}                                                 
                        token={token}
                        id={catalog.Id}
                    />
                </DialogContent>
                <DialogActions>
                  <Button                       
                    color="primary" 
                    startIcon={<SaveIcon />}
                    onClick={saveCatalog}
                  >{t("buttons.Save")}</Button>

                    <Button onClick={() => setOpenDlg(false)}>{t("buttons.Close")}</Button> 
                </DialogActions>
            </Dialog>
            }
            </div>
        )}
    </AppConsumer>    
  )
}

export default Catalogs;