import React, { useState, useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { actionCreators } from "../../store/Catalog";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import CatalogItemXs from "./CatalogItemXs";
import Alert from '@material-ui/lab/Alert';
import {LinearProgress, Typography, Grid, IconButton, Paper} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import { useWidth} from '../../helpers/utils';
import { Page404 } from "../Page";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2, 1),
            marginBottom: 90,
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(1 * 0.5),
                minHeight: 300,
            },
        },
        paperTop: {
            padding: theme.spacing(0, 1),
            [theme.breakpoints.down("sm")]: {
                marginTop: theme.spacing(2)
            },
            borderRadius: 8,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        paper: {
            padding: theme.spacing(0, 1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        listview: {
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            position: "relative",
            zIndex: 1    
        },
        catalogItem: {
            height: "522px",
            borderWidth: 0,
            borderRadius: 12,
            position: "relative",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: "390px",
            }, 
            "&:hover": {
                zIndex: 1000,
            },
        },
        wrapper: {        
            position: "absolute",        
            zIndex: 1,
            width: "100%",        
            height: "516px",
            overflow: "hidden",
            left: 0,
            top: 0,        
            borderRadius: 12, 
            [theme.breakpoints.down("sm")]: {
                height: "387px",
                minHeight: "387px",
            }, 
            "&:hover": {
                height: "auto !important",
                transform: "scale(1.04)",
                borderWidth: 0,
                boxShadow: theme.palette.ambilight.boxshadow
            }
        },
        
        view: {
            textAlign: "right",
        },
        group: {
            fontWeight: 100,
            [theme.breakpoints.down("sm")]: {           
                fontSize: "1.3rem",
            },
        },
        groupBox: {
            marginBottom: theme.spacing(1)
        },
        progress: {
            height: 2,
            position: "absolute",
            top: 0,
            left: 0,
            right:0,
        },  
        linearColorPrimary: {
            backgroundColor: "#abdcda",
        },
        linearBarColorPrimary: {
            backgroundColor: "#d20000",
        },
        bntview: {
            color: theme.palette.primary.light
        },
        icon: {
            position: "relative",
        },
    })
);


const Catalog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();            
    const width = useWidth();
    const app = useContext(AppContext);
    const { country ,lang } = app;

    const [ user, setUser ] = useState(app.user);
    
    const dispatch = useDispatch();    
    const {response, isLoading} = useSelector((state) =>  state.catalogRequest );
    const group = props.match.params.grp || "";

    const handleCopyUrl = () => {                
        const _url = `${window.location.protocol}//${window.location.host}/catalog/${group}?ref=${user ? user.profile.name : ''}`;        
        navigator.clipboard.writeText(_url);        
    };
    
    useEffect(()=> {        
        dispatch(actionCreators.requestCatalogItems(group, 0, user, country , lang));
    }, [group, user, dispatch, country, lang]);

    useEffect(() => {        
        function fetchUser() {            
            app.getUser().then( (user) => {                
                setUser(user);
            });
        }
        fetchUser();
    }, [app]);

    return (
        <AppConsumer>
        {() => (
            <div className={classes.root}>
                {isLoading && 
                <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />                  
                }

                {response.ProductGroup && response.CatalogItems && response.CatalogItems.length > 0 && 
                    <Paper 
                        className={classes.paperTop} 
                        elevation={0} 
                    >
                        <Grid container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            style={{marginBottom: 16}}
                        >
                            <Grid item>
                                <Typography  className={classes.group} component="h2" variant="h4" >
                                    {response.ProductGroup.LocalName} <sup style={{fontSize: ".6em"}}>{response.CatalogItems.length}</sup>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton color="primary" onClick={handleCopyUrl}>
                                    <ShareIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                </Paper>
                }

                {response.CatalogItems &&  response.CatalogItems.length > 0 &&
                <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={width === "xs" ? 1 : 2}>
                       { response.CatalogItems.map((item) => (
                           <Grid item key={item.Code} xs={6} sm={6} md={4} lg={3} xl={2}>
                               <div className={classes.catalogItem}>
                                    <div className={classes.wrapper}>
                                        <CatalogItemXs 
                                            item={item} 
                                            IsAuthenticated={ Boolean(user) } 
                                            userName={user ? user.profile.name : ''}
                                            magazine={response.Magazine}
                                        />
                                    </div>
                               </div>
                           </Grid>
                       ))}
                </Grid>
                }


                {response.CatalogItems &&  response.CatalogItems.length === 0 &&                
                    <Page404 text={response.ProductGroup.LocalName} />
                }

            </div>
        )}
        </AppConsumer>
    )
}

// Catalog.propTypes = {
//   second: PropTypes.third
// }

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Catalog)


export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Catalog)