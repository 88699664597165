import React, { useState, useContext, useEffect } from "react";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import { Helmet } from "react-helmet";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Grid, Chip, Avatar, Paper, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(4, 2),
      borderRadius: theme.spacing(2),
      boxShadow: theme.palette.ambilight.boxshadow        
    },
    paymentbox: {
      minHeight: 355,
      position: "relative",
    },
    header: {
      // fontWeight: 300,
    },
    logo: {
      maxWidth: "80%",
      height: 48,
    },
    balance: {
      // fontWeight: 400,
      fontSize: "1.1rem",
    },
    small: {
      fontSize: "0.6rem !important"
    },
    customer: {
      textTransform: "capitalize",
      // fontWeight: 300,
      marginBottom: theme.spacing(2),
    },
    
    bal_conner_img: {
      right: 0,
      bottom: 0,
      height: "100%",
      position: "absolute"
    },

    bal_img: {
      color: theme.palette.primary.light,
      fontSize: theme.spacing(7),
      display: "none",
      [theme.breakpoints.up("sm")]: {
          position: "absolute", 
          display: "block",
          top: theme.spacing(2),
          right: 8,
          fontSize: theme.spacing(11),
          right: theme.spacing(3),
      },                        
      filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))",      
    },
  })
);

const Recharge = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AppContext);
  const { t } = useTranslation();

  const [token, setToken] = useState();
  const [country, setCountry] = useState();
  const [balances, setBalances] = useState([]);
  const [adviser, setAdviser] = useState();
  const [amount, setAmount] = useState(100);

  // Get customer balance
  useEffect(() => {
    if (!token) return;
    const url = "/api/v1.0/balance/total";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // console.log("Balances", data);
          setBalances(data.Balances);
          setAdviser(data.Adviser);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {});
  }, [token]);

  // Get payment country
  useEffect(() => {
    if (!token) return;
    const url = "/api/v1.0/payment/country";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // console.log("Payment country", data);
          setCountry(data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {});
  }, [token]);

  // Get token
  useEffect(() => {
    if (!auth) return;

    function fetchUser() {
      auth.getUser().then(async (user) => {
        let token = null;
        if (user != null) {
          token = `Bearer ${user.access_token}`;
        }
        setToken(token);
      });
    }
    fetchUser();
  }, [auth]);

  const PaymentProviderLogo = (payment) => {
    const idx = payment.Values.findIndex((p) => p.FieldName === "Logo");
    if (idx > -1) {
      return payment.Values[idx].Value;
    }
    return null;
  };

  return (
    <AppConsumer>
      {() => (
        <div className={classes.root}>
          {/* <Helmet>              
              <link rel='stylesheet' href='https://pay.fondy.eu/latest/checkout-vue/checkout.css' />
              <script src="https://pay.fondy.eu/latest/checkout-vue/checkout.js" async></script>
              <script src="//static.liqpay.ua/libjs/checkout.js" async></script>
            </Helmet> */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Paper className={ clsx(classes.paper, classes.paymentbox)} elevation={0}>
                <span className={clsx("material-icons", classes.bal_img)}>
                  currency_exchange
                </span>
                <img className={classes.bal_conner_img} alt="triangle background" src="/assets/images/triangle-light.png" />
                {country && country.PaymentProviders && (
                  <>
                    <Typography
                      className={classes.header}
                      variant="h4"
                      align="center"                      
                    >
                      {t("pay.Recharge")}
                    </Typography>

                    {adviser && 
                      <Typography 
                        variant="h5" 
                        component="h2" 
                        color="textSecondary" 
                        className={classes.customer} 
                        align="center"
                      >
                        <small>{adviser.Code}</small>, {(`${adviser.FirstName} ${adviser.LastName}`).toLowerCase()} 
                      </Typography>
                    }

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        style={{ marginBottom: 16 }}
                      >                      

                      {balances.map((balance, idx) => (
                        balance.Amount !== 0.0 || balance.AmountOB !== 0 ?
                          <Grid item key={idx} >
                            {balance.Amount !== 0 && 
                            <Chip
                              className={classes.balance}
                              label={(balance.Amount).toFixed(2)}                               
                              variant="outlined"
                              color="primary"
                              avatar={<Avatar className={classes.small}>{balance.CurrencyName}</Avatar>}
                            />
                            }

                            {balance.AmountOB !== 0 &&                             
                            <Chip
                              className={classes.balance}
                              label={(balance.AmountOB).toFixed(2)}                               
                              variant="outlined"
                              color="secondary"
                              style={{marginLeft: 8}}
                              avatar={<Avatar className={classes.small}>{balance.CurrencyName}</Avatar>}
                            />
                            }

                            </Grid>: null                    
                          ))
                        }                
                    </Grid>

                    
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      style={{ marginBottom: 8 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-amount">
                            {t("pay.Amount")}{" "}
                          </InputLabel>
                          <OutlinedInput
                            type="number"
                            id="outlined-adornment-amount"
                            value={ parseFloat(amount).toFixed(2)}
                            inputProps={{ min: 1 }}
                            onChange={(e) => setAmount(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                {country.CurrencyName}
                              </InputAdornment>
                            }
                            labelWidth={60}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box p={2}>
                        <Typography variant="body1" align="center" gutterBottom>
                            {t("Select payment system")}
                        </Typography>
                    </Box>
                    
                    
                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        >                        
                        {country.PaymentProviders.map((payment) => (
                            <Grid item xs={12} sm={6} md='auto' key={payment.Id}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                fullWidth
                                component={Link}
                                to={{
                                    pathname: `/payment/${payment.Name.toLowerCase()}`,
                                    state: { payment: {                                                    
                                                        amount: amount,
                                                        title: t("pay.Recharge"),
                                                        order_desc: ''
                                                    }}
                                }}
                            >
                                <span style={{marginRight: 8}}>{t("Pay with")}</span>
                                <img
                                className={clsx(classes.logo, payment.Name.toLowerCase())}
                                alt={payment.Name}
                                src={PaymentProviderLogo(payment)}
                                />
                            </Button>
                            </Grid>
                        ))}
                        </Grid>
                    
                  </>
                )}
                
                
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </AppConsumer>
  );
};

export default Recharge;
