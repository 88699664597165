import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionCreators } from "../../store/NavItems";
import { useCookies } from "react-cookie";

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  Paper, Popper, Grow, ClickAwayListener, MenuList, MenuItem, ListItemIcon, Divider,
  AppBar,
  Toolbar,
  Grid,
  InputBase,
  Avatar,
  Tabs,
  Tab,
  CssBaseline,
  Box,
  Typography,
  IconButton,
  Button,
  TablePagination,
} from "@material-ui/core";
import { useWidth} from '../../helpers/utils';

import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from "@material-ui/icons/Search";
import RedeemIcon from "@material-ui/icons/Redeem";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";

import { grey } from "@material-ui/core/colors";

import { UserAvatar } from "../UserAvatar";
import Basket from "../Basket/Basket";
import { Chat } from "../Chat/Chat";
import BigMenu from './BigMenu';
import SearchBoxMobile from './SearchBoxMobile';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {        
        width: "280px",
        maxWidth: "100%",
        height: "auto",
        marginTop: theme.spacing(1),
        marginLeft: -286,    
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginLeft: 0,
        },
        // filter: "drop-shadow(1px 2px 1px rgb(0 0 0 / 0.2))"
    },
    toolbar: {
      paddingLeft: theme.spacing(1/2),
      paddingRight: theme.spacing(0),
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(2),
      },
    },
    menuButton: {      
      marginRight: theme.spacing(0),
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(1),
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    searchbox: {  
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: grey[300],  
      borderRadius: theme.spacing(1),
      display: "flex",
      alignItems: "center",    
      width: "100%",
    },  
    input: {
      marginLeft: theme.spacing(1),
      
      flex: 1,
    },
    iconButton: {      
      color: theme.palette.primary.main,
    },
    btn: {    
      marginRight: 2, 
      padding: 2, 
      border: "2px solid", 
      borderColor: theme.palette.primary.main
    },
    avatar: {        
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.primary.main,
    },
    iconLogin: {
      color: "#bbd5f2",
    },
    divider: {
      height: 26,
    },  
  })
);

const TopAppBar = (props) => {
    const { open, toggleDrawer, showDlgCertificateCode } = props;
    const classes = useStyles();
    const width = useWidth();
    const { t } = useTranslation();
    const history = useHistory();
    
    // const [cookies] = useCookies();
    // const orderId = cookies['orderId'];
    
    // const dispatch = useDispatch();        
    const appLayout = useSelector((state) =>  state.appLayout );
    const { Adviser } = appLayout;

    const [logo, setLogo]  = useState('/assets/files/images/logos/jerelia.svg');
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [search, setSearch] = useState('');
    const [menuAccount, setMenuAcccount] = useState(false);
    const [openChat, setOpenChat] = useState(false);

    const accountRef = useRef(null);

    const handleToggleSearchBox = () => {
      setShowSearchBox(!showSearchBox);
    };

    const handleCloseSearchBox = () => {
      setShowSearchBox(false);
    };

    const searchProducts = () => {
      if(search.length > 2){
        history.push(`/search/${search}`);
      }            
    }

    const keyPressSearch = e => {
      if (e.key === "Enter") {
        searchProducts();
        e.preventDefault();
      }
    };
  
    const handleSearch = (e) => {
      searchProducts();
      e.preventDefault();
    };
  
    const handleGiftCardOpen = () => {
      showDlgCertificateCode();
    };

    const handleOpenChat = () => {
      setOpenChat(true);
    };
    
    const handleCloseChat = () => {
      setOpenChat(false);
    };

    const handleToggleMenuAccount = () => {      
      setMenuAcccount(!menuAccount);
    };

    const handleCloseAccountMenu = () => {
      setMenuAcccount(false);
    }

    const handleListKeyDown = (e) => {
      if (e.key === "Tab") {
        e.preventDefault();
        setMenuAcccount(false);
      }
    }

    useEffect(() => {
      const URL =  "/api/v1/content/getlogo";
      fetch(URL, { method: "GET" })
        .then(response => response.json())
              .then(data => {
                setLogo(data.logo);
              }
      )
        .catch(error => {           
              console.log(error);
        });  
    }, []);


  return (
    <>
    <AppBar position="static" color="inherit" elevation={0}>
      {/* Header toolbar */}
      <Grid
        container
        direction="row"
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
      >
        {/* Menu butterbrod & search */}
        <Grid item xs="auto">
          <Toolbar
            variant={width === "xs" ? "dense" : "regular"}
            className={classes.toolbar}
          >
            <IconButton 
                name="bnt-drawer" 
                onClick={toggleDrawer(true)} 
                className={classes.menuButton} 
                color="primary" 
                aria-label="Open drawer" 
            >
                <MenuIcon />
            </IconButton>
            
            {/* Section Mobile */}
            {width === "xs" && 
              <IconButton aria-label="search" color="primary" onClick={handleToggleSearchBox} >
                <SearchIcon />
              </IconButton>
            }
            {/* Search section Desktop */}
            <div className={classes.sectionDesktop}>
                    {/* Search box */}
                    <Paper component="form" className={classes.searchbox} elevation={0} style={{width: 300}}>
                      <InputBase
                        value={search}                
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => keyPressSearch(e) }
                        className={classes.input}
                        placeholder={t("common.SearchProducts")}
                        inputProps={{ "aria-label": t("common.SearchProducts") }}
                      />                      
                      <IconButton type="submit"
                        size="small"
                        className={classes.iconButton} 
                        aria-label="search" 
                        onClick={handleSearch}
                        >
                        <SearchIcon />
                      </IconButton> 
                    </Paper>
            </div>

          </Toolbar>
        </Grid>

        {/* Logo image */}
        <Grid item xs={true}>
            <div style={{display: 'block', width: '100%', textAlign: 'center'}}>
                <Link to={"/"}>
                    <img className={classes.logo} alt="Jerelia" src={logo} />
                </Link>                        
            </div>
        </Grid>

        {/* Gift & Adviser & Basket */}
        <Grid item xs="auto"> 
            <Box pr={1} style={{ textAlign: "right" }}>
            {Adviser &&  width !== "xs" && 
            <IconButton 
              className={classes.btn}
              role="button" 
              aria-label="Gift card" 
              name="giftcardbutton" 
                aria-haspopup="true" 
              onClick={handleGiftCardOpen} 
              color="primary" 
            >
              <Avatar color='primary' className={classes.avatar}>
                <RedeemIcon />
              </Avatar>                    
            </IconButton>
            }

            <UserAvatar 
              refBtn={accountRef} 
              adviser={Adviser} 
              onClick={handleToggleMenuAccount} 
            /> 

            {Adviser && 
            <Basket />
            }
          </Box>
        </Grid>
      </Grid>
      
      <SearchBoxMobile 
        showSearchBox={showSearchBox} 
        handleCloseSearchBox={handleCloseSearchBox} 
      />      
    </AppBar>
    
    {/* Big menu of produc groups */}
    <BigMenu />

    {/* User menu */}
    <Popper      
      open={menuAccount} 
      placement="bottom-end" 
      anchorEl={accountRef.current} role={undefined} 
      transition disablePortal style={{ zIndex: 2}}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === "bottom" ? "end top" : "end bottom" }}
        >
          <Paper elevation={6}>
            <ClickAwayListener onClickAway={() => setMenuAcccount(false)}>                        
            { Adviser ? 
                <Box p={1/5}>
                    {Adviser && 
                    <div style={{paddingLeft: "18px", paddingRight: "12px"}}>
                      <Typography variant="subtitle2">
                        {Adviser.RegistrationCode}
                      </Typography>                                
                      <Typography className={classes.userName} gutterBottom>
                        {Adviser.FirstName.toLowerCase()} {Adviser.LastName.toLowerCase()} 
                      </Typography>
                    </div>
                    }
                    
                    <MenuList id="menu-list-grow" onKeyDown={(e) => handleListKeyDown(e)}>
                      {/* { 1 === 1 &&
                      <MenuItem onClick={handleOpenChat}>
                        <ListItemIcon><ForumOutlinedIcon /></ListItemIcon>
                        {t("appbar.menu.Chat")}
                      </MenuItem>
                      } */}

                      <MenuItem to="/profile" component={Link} onClick={handleCloseAccountMenu}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <PermContactCalendarOutlinedIcon />
                        </ListItemIcon> 
                        {t("appbar.menu.Profile")}
                      </MenuItem>
                      <Divider />
                      <MenuItem component={Link} to="/logout" >
                        <ListItemIcon><ExitToAppSharpIcon /></ListItemIcon>
                        {t("appbar.menu.Logout")}
                      </MenuItem>
                    </MenuList>
                  </Box> :
                  <MenuList  id="menu-list-grow" onKeyDown={(e) => handleListKeyDown(e)}>
                    <MenuItem component={Link} to="/register" onClick={handleCloseAccountMenu}>
                      <ListItemIcon><PersonAddOutlinedIcon /></ListItemIcon>
                      {t("appbar.menu.Register")}
                    </MenuItem>
                    <Divider />
                    <MenuItem component={Link} to="/signin">
                      <ListItemIcon><PermIdentityIcon /></ListItemIcon>                          
                        {t("appbar.menu.Login")}
                    </MenuItem>
                  </MenuList>
                }

                {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem>                    
                <MenuItem onClick={this.handleClose}>Logout</MenuItem> */}                  
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>

    {/* {Adviser && openChat && 
      <Chat adviser={Adviser} open={openChat} onClose={handleCloseChat} /> 
    } */}
    </>
  );
};

TopAppBar.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    showDlgCertificateCode: PropTypes.func.isRequired
};

export default connect(
  state => state.appLayout,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(TopAppBar)
// export default TopAppBar;
