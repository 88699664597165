
import React, { useState, useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { actionCreators } from "../../store/Search";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import CatalogItemXs from "../Catalog/CatalogItemXs";
import Alert from '@material-ui/lab/Alert';
import {LinearProgress, Typography, Grid, IconButton, Paper} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import { useWidth} from '../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2, 1),
            marginBottom: 90,
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(1 * 0.5),
                minHeight: 300,
            },
        },
        paperTop: {
            padding: theme.spacing(0, 1),
            borderRadius: 8,
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        },
        paper: {
            padding: theme.spacing(0, 1),
            borderRadius: 12,
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        },
        listview: {
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            position: "relative",
            zIndex: 1    
        },
        catalogItem: {
            height: "522px",
            borderWidth: 0,
            borderRadius: 12,
            position: "relative",
            zIndex: 1,
            [theme.breakpoints.down("sm")]: {
                height: "390px",
            }, 
            "&:hover": {
                zIndex: 1000,
            },
        },
        wrapper: {        
            position: "absolute",        
            zIndex: 1,
            width: "100%",        
            height: "516px",
            overflow: "hidden",
            left: 0,
            top: 0,        
            borderRadius: 12, 
            [theme.breakpoints.down("sm")]: {
                height: "387px",
                minHeight: "387px",
            }, 
            "&:hover": {
                height: "auto !important",
                transform: "scale(1.04)",
                borderWidth: 0,
                boxShadow: "0 8px 10px rgb(54 74 178 / 16%), 0 6px 30px rgb(54 74 178 / 4%), 0 16px 24px rgb(54 74 178 / 8%)",
            }
        },
        
        view: {
            textAlign: "right",
        },
        group: {
            fontWeight: 100,
            [theme.breakpoints.down("sm")]: {           
                fontSize: "1.3rem",
            },
        },
        groupBox: {
            marginBottom: theme.spacing(1)
        },
        progress: {
            height: 2,
            position: "absolute",
            top: 0,
            left: 0,
            right:0,
        },  
        linearColorPrimary: {
            backgroundColor: "#abdcda",
        },
        linearBarColorPrimary: {
            backgroundColor: "#d20000",
        },
        bntview: {
            color: theme.palette.primary.light
        },
        icon: {
            position: "relative",
        },
    })
);



const Search = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();            
    const width = useWidth();
    const app = useContext(AppContext);
    const { country, lang } = app;

    const [ user, setUser ] = useState(app.user);
    
    const dispatch = useDispatch();    
    const {response, isLoading} = useSelector((state) =>  state.searchRequest );
    const query = props.match.params.query || "";
    const page = parseInt(props.match.params.page, 10) || 1;
    const size = parseInt(props.match.params.page, 10) || 12;

    useEffect(()=> {        
        dispatch(actionCreators.requestSearch(query, page, size, user, country, lang));
    }, [query, page, size, user, dispatch, country, lang]);

    useEffect(() => {        
        function fetchUser() {            
            app.getUser().then( (user) => {                
                setUser(user);
            });
        }
        fetchUser();
    }, [app]);

  return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>
                {isLoading && 
                <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />                  
                }

            {!isLoading &&  response.CatalogItems &&  response.CatalogItems.length > 0 &&
                <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="flex-start" 
                    spacing={width === "xs" ? 1 : 2}>
                       { response.CatalogItems.map((item) => (
                           <Grid item key={item.Code} xs={6} sm={6} md={4} lg={3} xl={2}>
                               <div className={classes.catalogItem}>
                                    <div className={classes.wrapper}>
                                        <CatalogItemXs 
                                            item={item} 
                                            IsAuthenticated={ Boolean(user) } 
                                            userName={user ? user.profile.name : ''}
                                            magazine={response.Magazine}
                                        />
                                    </div>
                               </div>
                           </Grid>
                       ))}
                   </Grid>                    
                }

                {response.CatalogItems &&  response.CatalogItems.length === 0 &&                
                <Paper style={{padding: 32,textAlign: "center"}}
                        className={classes.paperTop} 
                        elevation={0} 
                >
                    <Grid 
                        container 
                        direction="row" 
                        justifyContent="center" 
                        alignItems="center" 
                    >
                        <Grid item xs={11} sm={10} md={4}>
                            <img style={{width: '100%'}} src="./assets/images/404.svg" alt="empty" />
                        </Grid>
                    </Grid>
                    
                    <Grid 
                        container 
                        direction="row" 
                        justifyContent="center" 
                        alignItems="center" 
                    >
                        <Grid item xs={12} sm={10} md={4}>
                        <Alert variant="outlined" severity="warning">
                        {t("common.SearchNotFound", {str: response.ProductGroup.LocalName})}
                    </Alert>                                    
                        </Grid>
                    </Grid>                                    
                </Paper>
                }
            </div>
        )}
    </AppConsumer>
  )
}


export default connect(
    state => state.searchRequest,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Search)