/* /src/components/auth/callback.jsx */
import React from "react";
import { AppConsumer } from "../../providers/appProvider";

export const Signin = () => (

    <AppConsumer>
        {({ signinRedirect }) => {
                console.log("signin");

                signinRedirect();
            return <p>Завантаження...</p>;
        }}
    </AppConsumer>
);