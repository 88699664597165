// Modules
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";

// Components
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
// Styles
import styles from "./Messages.css";

// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    float: "left", 
    marginTop: -2,
    marginRight: 8
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Messages = ({ messages, contact }) => {
  // References
  const listRef = useRef(null);
  const rowHeights = useRef({});

  const classes = useStyles();

  useEffect(() => {    
    const scrollToBottom = () => {
      if(messages.length > 1 && listRef && listRef.current)
        listRef.current.scrollToItem(messages.length - 1, "end");
    }    
    scrollToBottom();    
  }, [messages, listRef]);

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 82;
  }

  function Row({ index, style }) {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

    return (
        <div style={style}>
            {messages[index].From !== contact.Name ? (
                <div ref={rowRef} style={styles.sendMessageContainer}>
                    <div style={styles.sendMessage}>
                        <div style={{ width: "100%" }}>                            
                            {/* {messages[index].Body.replace(/(<? *script)/gi, "illegalscript")} */}
                            <div dangerouslySetInnerHTML={{__html: messages[index].Body.replace(/(<? *script)/gi, "illegalscript")}} ></div>
                            <div style={styles.clockMessage}>{moment(messages[index].UpdatedOn).format("kk:mm")}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div ref={rowRef} style={styles.receivedMessageContainer}>
                    <div style={styles.receivedMessage}>
                        <div style={{ width: "100%" }}>
                            <Avatar className={classes.small} salt={contact.Name} src={`${host}${contact.Avatar}`}></Avatar>
                            <div dangerouslySetInnerHTML={{__html: messages[index].Body.replace(/(<? *script)/gi, "illegalscript")}} ></div>
                            <div style={styles.clockMessage}>{moment(messages[index].UpdatedOn).format("kk:mm")}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  

  return (
    <AutoSizer style={styles.messagesContainer}>
      {({ height, width }) => (
        <List
          className="List"
          height={height - 8}
          itemCount={messages.length}
          itemSize={getRowHeight}
          ref={listRef}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};

export default Messages;
