import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box, Paper, Card, CardMedia, CardContent, Grid } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { useWidth} from '../../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
        },
        paper: {
            margin: theme.spacing(1),            
            borderRadius: 12,
            padding: theme.spacing(2,1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        mediaImg: {
            paddingTop: "36.25%", // 16:9
            [theme.breakpoints.down("sm")]: {
              paddingTop: "56.25%", // 16:9      
            },
        },
        mb: {
            marginBottom: theme.spacing(1)
        },
        card: {
            borderRadius: 16,      
            height: '100%',
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",      
            boxShadow: theme.palette.ambilight.boxshadow
        },
        cardcontent: {
            minHeight: theme.spacing(18)
        },
        cardActions: {
            paddingBottom: theme.spacing(3),
        },
        media: {
            paddingTop: "56.25%", // 16:9
        },
        backdrop: {
            position: "absolute",   
            top: theme.spacing(30), 
            left: '50%',
            [theme.breakpoints.down("sm")]: {
                top: theme.spacing(10), 
                left: '45%',
            },
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);


const HomePageSkeleton = () => {
    const classes = useStyles();
    const width = useWidth();
    const cards = width === 'xs' ? [0, 1] : [0, 1, 2, 3];
    return (
        <div className={classes.root}>                        
            <div style={{position:'relative'}}>
                <div className={classes.backdrop}>
                    <div className="loader"></div>
                </div>
            </div>
            <Skeleton className={clsx(classes.mediaImg, classes.mb)} variant="rect" height={200} />
            <Skeleton className={clsx(classes.mb)} variant="rect" height={40} />
            
            
            

            <Paper className={classes.paper}>                                
                <Skeleton variant="text"  />
                <Skeleton variant="text"  />
                <Skeleton variant="text"  />
            </Paper>

            <Box p={1}>
                <Grid container direction="row" spacing={2} justifyContent="center"  alignItems="stretch">
                    {cards.map((c,i) => (
                        <Grid item key={c} xs={12} sm={6} md={3}>
                            <Card className={classes.card}>
                                <Skeleton className={clsx(classes.media,)} variant="rect" height={200} />
                                {/* <CardMedia
                                    className={classes.media}                                    
                                /> */}
                                <CardContent className={classes.cardcontent}>

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    )
}

export default HomePageSkeleton;