import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { AppContext, AppConsumer } from "../../providers/appProvider";
// import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
// import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CodeIcon from "@material-ui/icons/Code";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";


// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {    
        marginTop: 2,
        paddingTop: 8,
        flexGrow: 1,
        background: "#f7f9fc",
    },
    
    paper: {      
      padding: theme.spacing(1)
    },
    section: {
        marginTop: theme.spacing(1)
    },
    sectionbox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    
    row: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: theme.spacing(1),
    },

    adviser: {
        textTransform: "capitalize"
    },
    ellipsis: {
        textOverflow: "ellipsis"
    },
    report: {
        padding: theme.spacing(0, 1),
    },
    reporthd: {
        padding: theme.spacing(2,4),
        // color: "#fff",
        // textShadow: "1px 1px 1px rgba(70,162,206,1)",
        // textShadow: "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)",        
        // background: "linear-gradient( 333deg, rgba(116,251,253,1) 0%, rgb(122 235 255) 24%, rgba(50,162,206,1) 100%)"
    },    
    
    indicator: {
        minHeight: theme.spacing(18),
        [theme.breakpoints.down("xs")]: {
            minHeight: "auto",
          },
        // padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        // boxShadow: "rgb(50 50 93 / 1%) 0px 2px 5px -1px, rgb(0 0 0 / 3%) 0px 1px 3px -1px",
    },    
    indname: {
        fontWeight: 400
    },
    large: {
        width: 132,
        height: 132,
        textAlign: "center",
        display: "block",
        margin: "0 auto",
        border: "2px solid #fff",
        fontSize: "4em",
        // lineHeight: "3em",
        // color: theme.palette.getContrastText(lightBlue[500]),
        // backgroundColor: lightBlue[300],
        letterSpacing: "-0.1em",        
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },
    detailIcon: {
        height: 32,
        width: 32,
    },
    container: {
        padding: theme.spacing(0,1),
        // maxHeight: 440,
    },
  })
);

const BaseIndicatorsPrev = () => {
    const classes = useStyles();
    const auth = useContext(AppContext);
    const [adviser, setAdviser] = useState(null);
    const [token, setToken] = useState(null);
    const [headerData, setHeaderData] = useState(null);
    const [detailsDataOne, setDetailsDataOne] = useState(null);
    const [detailsDataTwo, setDetailsDataTwo] = useState(null);
    const [detailsDataThree, setDetailsDataThree] = useState(null);
    const [detailsDataFour, setDetailsDataFour] = useState(null);
    const [collapsePanels, setCollapsePanels] = useState([]);

    // const columns = [        
    //     { id: 'Client', label: 'Client', minWidth: 270 },
    //     { id: 'LO', label: 'LO', minWidth: 70 },
    // ];

    const changeCollapsePanel = (panel) => {        
        const pos = collapsePanels.indexOf(panel);
        
        if(pos > -1 ){            
            collapsePanels.splice(pos, 1);
            setCollapsePanels([...collapsePanels]);
            return false;
        }
        else
        {
            collapsePanels.push(panel);
            setCollapsePanels([...collapsePanels]);
            return true;
        }        
    };

    const checkCollapsePanel = (panel) => {        
        return collapsePanels.indexOf(panel) > -1;        
    };

    const handleLinkClick = (event, clientid) => {
        event.preventDefault();
        alert(`Click by client: ${clientid}`)   
    }

    const loadReportDetailsData = async (clientId, viewType) => {            
        const URL = `/api/v1.0/reports/getreportdetailedrows/${clientId}/${viewType}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {                                
                // Load report details data
                console.info("getreportdetailedrows: ", data);
                switch (viewType) {
                    case 1:
                        setDetailsDataOne(data);
                        break;
                    case 2:
                        setDetailsDataTwo(data);
                        break;
                    case 3:
                        setDetailsDataThree(data);
                        break;
                    case 4:
                        setDetailsDataFour(data);
                        break;                                    
                    default:
                        break;
                }

                
            });
    }

    const loadReportHeaderData = async (clientId) => {        
        const URL = `/api/v1.0/reports/getreportheaders/${clientId}`;            
        console.log(token);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {                                
                // Load report header data
                // console.info("getreportheaders: " ,data);
                setHeaderData(data);                            
            });
    }
    
    const fetchData = useCallback( async () => {
        if(!token ) return;
        if(!adviser ) return;
                    
        // console.log('token', token);

        loadReportHeaderData(adviser.Id);
        loadReportDetailsData(adviser.Id, 1);
        loadReportDetailsData(adviser.Id, 2);
        loadReportDetailsData(adviser.Id, 3);
        loadReportDetailsData(adviser.Id, 4);

    // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    token =  `Bearer ${user.access_token}`;
                }
                
                const URL = "/api/v1.0/adviser/getadviserbycontext";
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Accept", "application/json");
                headers.append("Authorization", token);
                fetch(URL, { method: "GET", headers: headers })      
                    .then(response => response.json())
                        .then(data => {
                            setAdviser(data);
                            setToken(token);
                        });
            });
        }
        fetchUser();
        fetchData();
    }, [auth, fetchData]);

    const buildSection = (name, head, details) => {                
        return(
            
            <Paper className={clsx(classes.paper, classes.section)}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>                    
                        <Typography variant="button" gutterBottom>Section: {name}</Typography> 
                    </Grid>
                </Grid>
                {head && 
                <Grid container direction="row" spacing={2}>
                    {
                        Object.keys(head).map(key => (
                            key !== "$type" ?
                            <Grid key={key} item xs={6} sm={3} >
                                <Paper className={classes.sectionbox} variant="outlined">
                                    <Typography variant="body2" gutterBottom align="center">{key}</Typography> 
                                    <Typography align="center" variant="h5">{head[key]}</Typography> 
                                </Paper>                            
                            </Grid> : null                        
                    ))}
                </Grid>
                }
                <Typography align="right" style={{marginTop: 4}}>
                    <Button aria-label="detail" onClick={ () => changeCollapsePanel(name)}
                        startIcon={<CodeIcon />}                        
                    >
                        Details
                    </Button>
                    {/* <IconButton aria-label="detail" onClick={ () => changeCollapsePanel(name)}>
                        <CodeIcon />
                    </IconButton> */}
                </Typography>                
                {details && 
                    <Collapse in={checkCollapsePanel(name)}>
                        { details.Data && details.Data.length > 0 &&                         
                        <TableContainer className={classes.container}>
                            <Grid  className={classes.row} container direction="row" spacing={1}>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="subtitle2">Name</Typography>
                                    <Typography variant="subtitle2">Phone / Email</Typography>
                                </Grid>
                                <Grid item xs={3} sm={1}>
                                    <Typography  variant="subtitle2">LO</Typography>
                                </Grid>
                                <Grid item xs={3} sm={1}>
                                    <Typography variant="subtitle2">OC</Typography>
                                </Grid>
                                <Grid item xs={3} sm={1}>
                                    <Typography  variant="subtitle2">FLC</Typography>
                                </Grid>
                                <Grid item xs={3} sm={1}>
                                    <Typography variant="subtitle2">RC</Typography>
                                </Grid>
                            </Grid> 
                                {details.Data.map((row) => (
                                    <Grid  className={classes.row} key={row.ClientID} container direction="row" spacing={1}>
                                        <Grid item xs={12} sm={8}>
                                            <Typography className={classes.ellipsis} variant="body1" >
                                                {
                                                    row.ActivityStatus === "Холодный" ? <span title={row.ActivityStatus} style={{fontSize: 14, color: "#36c2f6"}} className="material-icons">ac_unit</span>: <span title={row.ActivityStatus} style={{fontSize: 14, color: "red"}} className="material-icons">thermostat</span>
                                                }
                                                &nbsp;
                                                <Link href="#" onClick={(e) => handleLinkClick(e, row.ClientID) }>{row.Client}</Link>
                                                
                                                <small>&nbsp;{row.ClientType}</small>
                                            </Typography>
                                            <Typography className={classes.ellipsis} variant="caption">{row.PhoneNumber} / {row.Email}</Typography>
                                        </Grid>

                                        <Grid item xs={3} sm={1}>
                                            <Typography align="right" variant="body1">{row.LO}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={1}>
                                            <Typography align="right" variant="body1">{row.OrderCount}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={1}>
                                            <Typography align="right" variant="body1">{row.FirstLineCount}</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={1}>
                                            <Typography align="right" variant="body1">{row.RecruitCount}</Typography>
                                        </Grid>
                                    </Grid> 
                                    
                                ))}
                            {/* <Table stickyHeader aria-label={`table details ${name}`}>
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {details.Data.map((row) => (
                                    <TableRow key={row.ClientID}>
                                        <TableCell>
                                            {row.Client}
                                        </TableCell>
                                        <TableCell>
                                            {row.LO}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table> */}
                        </TableContainer>

                        }
                    </Collapse>
                }
            </Paper>            
        );
    };

    return (
      <AppConsumer>
        {() => (
          <div className={classes.root}>
            <div className={classes.report}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper className={clsx(classes.indicator, classes.reporthd)}>
                    <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={12} sm={8}>
                            <Typography component="h1" variant="h5" >
                                Базові показники
                            </Typography>                            
                            {adviser && 
                                <>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Реєстраційний номер: <b>{adviser.RegistrationCode}</b>
                                </Typography>
                                <Typography className={classes.adviser} component="h3" variant="h4" gutterBottom >
                                    {adviser.FirstName.toLowerCase()}{" "}
                                    {adviser.LastName.toLowerCase()}
                                </Typography>                                                                                                         
                                </>
                            }
                        </Grid>
                        {/* <Grid item sm={4}>
                        { adviser &&                         
                                <Avatar alt={`${adviser.FirstName} ${adviser.LastName}`} src={adviser.Avatar} className={classes.large} />                                                
                        }
                        </Grid> */}
                    </Grid>                                        
                  </Paper>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Paper className={clsx(classes.indicator, classes.reporthd)}>
                    {headerData && <>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={12}>
                                <Typography noWrap variant="body2" > 
                                    LO
                                </Typography>
                                <Typography style={{fontSize: "2.5em"}}>
                                    {headerData.CurrentData.LO ? parseInt(headerData.CurrentData.LO) : 0}
                                </Typography>
                                <Typography variant="body2"> 
                                    Orders <b style={{fontSize: "1.2em"}}>{headerData.CurrentData.OrderCount}</b>
                                </Typography>
                                
                            </Grid>
                        </Grid>
                    </>}
                  </Paper>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Paper className={clsx(classes.indicator, classes.reporthd)}>
                        {headerData && <>
                            <Grid container direction="row" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body2" > 
                                    First line count
                                </Typography>
                                <Typography style={{fontSize: "2.5em"}}>
                                    {headerData.CurrentData.FirstLineCount}
                                </Typography>
                                <Typography variant="body2"> 
                                    Recruitings <b style={{fontSize: "1.2em"}}>{headerData.CurrentData.RecruitCount}</b>
                                </Typography>
                                
                            </Grid>
                        </Grid>
                            {/* <Typography variant="body1" > 
                                    First line count / Recruit Count
                                </Typography>
                                <Typography style={{fontSize: "2.6em"}} gutterBottom>
                                    {headerData.CurrentData.FirstLineCount} / { headerData.CurrentData.RecruitCount}
                            </Typography> */}
                        </>}
                    </Paper>
                </Grid>
              </Grid>


            {detailsDataOne && headerData &&
                <>{buildSection("FirstLine", headerData.FirstLine, detailsDataOne)}</>
            }

            {detailsDataTwo && headerData &&
                <>{buildSection("FirstLevelStatusHolders", headerData.FirstLevelStatusHolders, detailsDataTwo)}</>                
            }

            {detailsDataThree &&  headerData &&  
                <>{buildSection("FirstLevelWithoutStatus", headerData.FirstLevelWithoutStatus, detailsDataThree)}</>                
            }

            {detailsDataFour &&  headerData &&
                <>{buildSection("GrandManagers", headerData.GrandManagers, detailsDataFour)}</>                        
            }  
                          
            </div>
          </div>
        )}
      </AppConsumer>
    );
}

export default BaseIndicatorsPrev
