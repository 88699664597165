import React from 'react';
import { createStyles, makeStyles, useTheme, alpha } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

// eslint-disable-next-line no-unused-vars
import { Card,  Typography, CardActionArea, CardMedia, CardContent,  CardActions, Button } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { Link, useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useWidth} from '../../helpers/utils';
import PropTypes from "prop-types";
import grey from "@material-ui/core/colors/grey";
import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) =>
  createStyles({    
    card: {
        flexGrow: 1,
        borderRadius: 0,            
    },
    cardcontent: {
        width: "100%",
        display: "block",
      padding: theme.spacing(1/2, 0),
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      color: grey[200],
      textShadow: `0px 1px ${theme.spacing(1/8)}px ${grey[700]}`,      
      backgroundColor: alpha('rgb(0,0,0)', 0.4), // '#00000052',      
    },
    caption: {
      fontSize: '2rem',
      fontWeight: 200,
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: '1.5rem',
      },
    },

    text: {                
        fontWeight: 400,        
      },

    cardActions: {
      padding: theme.spacing(2, 0),
    },

    mediaImg: {
      paddingTop: "36.25%", // 16:9
      [theme.breakpoints.down("sm")]: {
        paddingTop: "56.25%", // 16:9      
      },
    },
    mediaVideo: {
      // minHeight: "auto!important",
      height: "36.25vw!important",
      // maxHeight: "calc(100vh - 138px - 10vh)!important",
      [theme.breakpoints.down("sm")]: {        
        height: "65vw!important",
      },
    },
  })
);


const BigSlide = (props) => {        
    const { slide } = props;  
    const classes = useStyles();
    const width = useWidth();
  // const { t } = useTranslation();  
  // console.log('slide', slide);
  
    const ext = (slide && slide.FullPath ? extractFileExt(slide.FullPath): "");
        
    const history = useHistory();

    const handleClickByCard = (e) => {
        e.stopPropagation();
        if(slide.Url !== ""){
            history.push(slide.Url);
        }
    }

    return (      
        <Card className={classes.card} elevation={0}>
              <CardActionArea onClick={handleClickByCard}>
                {IsImage(ext) && 
                <CardMedia
                  className={classes.mediaImg}
                  image={width !== 'xs' ? `${host}${slide.FullPath}` : (slide.FullPathMobile.length === 0 ? `${host}${slide.FullPath}` : `${host}${slide.FullPathMobile}`)}
                  title={slide.Title}
                />
                }

                {IsVideo(ext) && 
                <div className={classes.mediaVideo}>
                  <video style={{ width: "100%"}} muted loop autoPlay playsInline>
                    <source
                    
                    src={width !== 'xs' ? `${host}${slide.FullPath}` : (slide.FullPathMobile.length === 0 ? `${host}${slide.FullPath}` : `${host}${slide.FullPathMobile}`)}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>                
                }
                </CardActionArea>
                
                <CardActionArea >
                { slide.ShowText && slide.LocalValues.length > 0 &&
                <CardContent className={classes.cardcontent} >
                  <Typography className={classes.caption} variant="h5" display='block' align='center' >
                    {slide.LocalValues[0].Caption === "" ? slide.Caption : slide.LocalValues[0].Caption}
                  </Typography>
                  <Typography className={classes.text} variant="h6"  display='block' align='center'>
                    {slide.LocalValues[0].Text === "" ? slide.Text: slide.LocalValues[0].Text} 
                  </Typography>
                </CardContent>
                }                
              </CardActionArea>
        </Card>            
    )
}

BigSlide.propTypes = {  
  slide:                         
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Caption: PropTypes.string.isRequired,
      Url: PropTypes.string.isRequired,
      Text: PropTypes.string.isRequired,
      Path: PropTypes.string.isRequired,
      // Img: PropTypes.string.isRequired,
      FullPath: PropTypes.string.isRequired,
      FullPathMobile: PropTypes.string.isRequired,
      IsAuthenticated: PropTypes.bool.isRequired,
      Roles: PropTypes.arrayOf(PropTypes.string),
      Hide: PropTypes.bool.isRequired,
      ShowText: PropTypes.bool.isRequired,
      ShowFrom: PropTypes.string.isRequired,
      ShowTo: PropTypes.string.isRequired,  
      ButtonText: PropTypes.string,        
      BgColorTexBox: PropTypes.string,      
      LocalValues: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number.isRequired,
            ButtonText: PropTypes.string,
            Caption: PropTypes.string,
            Text: PropTypes.string,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,            
        })
        ).isRequired,
    }).isRequired,
};

export default BigSlide;