import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {                                    
            flexGrow: 1,
            padding: theme.spacing(0, 1),
            marginBottom: theme.spacing(1)
        },        
    })
);

const TitleBlock = props => {
    const classes = useStyles();
    const { localValue, propsBlock } = props;
    
    if(propsBlock){
        delete propsBlock["$type"];
    }
            
    return (        
        <Typography className={classes.root} component='div' {...propsBlock}>{localValue.Value }</Typography>                
    )
}

TitleBlock.propTypes = {
    localValue: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Annonce: PropTypes.string.isRequired,
        ContentId: PropTypes.number.isRequired,
        CreatedOn: PropTypes.string.isRequired,
        Descr: PropTypes.string.isRequired,
        Keywords: PropTypes.string.isRequired,
        Title: PropTypes.string.isRequired,
        TwoLetterISOLanguageName: PropTypes.string.isRequired,
        UpdatedOn: PropTypes.string.isRequired,
        Value: PropTypes.string.isRequired        
    }).isRequired,
    blockKey: PropTypes.string.isRequired,
    propsBlock: PropTypes.shape({}),
    
};

export default TitleBlock;