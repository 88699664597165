const ContentType = Object.freeze({    
    Page: 0,
    Post: 1,
    Landing: 2,
    Widget: 3,
    Notification: 4,
    Event: 5,
    Program: 6,
    Block: 7, 
    PopupNotification: 8
  });
  
  export { ContentType };