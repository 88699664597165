/* /src/components/auth/callback.jsx */
import React from "react";
import { AppConsumer } from "../../providers/appProvider";
import Typography from "@material-ui/core/Typography";

export const SigninPopup = () => (
    <AppConsumer>
        {({ signinPopup }) => {
            console.log("signinPopup");
            signinPopup();
            return <Typography align="center" style={{padding: 32, display: "block", width: "100%"}}>Завантаження...</Typography>
        }}
    </AppConsumer>
);