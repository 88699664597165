import React, { createContext, useState, ReactNode, FC, useEffect } from 'react';
import AuthService from "../helpers/authService";
import i18n from "../i18n";
const I18N_LANGUAGE = "i18nextLng"; 


// const DefaultValues = {
//     theme: "light",
//     changeTheme: () => {},
//     authService: {},    
//     signinRedirectCallback: () => {},
//     logout: () => {},
//     signoutRedirectCallback: () => {},
//     isAuthenticated: () => {},
//     signinRedirect: () => {},
//     signinSilentCallback: () => {},
//     createSigninRequest: () => {}
// };

const AppContext = createContext();

const AppConsumer = AppContext.Consumer;

const AppProvider  = ({ children }) => {
    const authService = new AuthService();    
    const [user, setUser] = useState();

    const [theme, setTheme] = useState( localStorage.getItem('jr-theme') || "light");    
    // const [country, setCountry] = useState(localStorage.getItem('jr-country') || "UA");
    const [country, setCountry] = useState(localStorage.getItem('jr-country'));
    const [lang, setLang] = useState(localStorage.getItem(I18N_LANGUAGE) || "uk");
    const [acceptCookies, setAcceptCookies] = useState(localStorage.getItem('jr-accept-cookies') || 0);
    
    const changeTheme = () => {
        const saved = localStorage.getItem('jr-theme');
        let value = "light";
        if(saved === "light"){
            value = "dark";
        }else if(saved === "dark"){
            value = "light";
        }else{
            value = "light";
        }

        setTheme(value);
        localStorage.setItem('jr-theme', value);
    };

    const changeUser = (value) => {
        setUser(value);
    };

    const changeCountry = (value) => {
        // if(value === 'uk'){
        //     debugger;
        //     console.log(value);
        // }
        setCountry(value);
        localStorage.setItem('jr-country', value);
    }

    const changeLang = (val) => {
        setLang(val);
        i18n.changeLanguage(val);
    };

    const changeAcceptCookies = (value) => {
        setAcceptCookies(value);
        localStorage.setItem('jr-accept-cookies', value);
    }
    
    const changeCulture = (country, language) => {
        setCountry(country);
        localStorage.setItem('jr-country', country);
        setLang(language);
        i18n.changeLanguage(language);
    };

    return (
        <AppContext.Provider
            value={{
                theme,
                user,
                country,
                lang,
                acceptCookies,
                ...authService,
                changeTheme, 
                changeUser,
                changeCountry,
                changeLang,
                changeCulture,
                changeAcceptCookies
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppConsumer, AppProvider };