import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import clsx from "clsx";
import { withRouter, useParams } from "react-router-dom";
import uuidv4 from "uuid/v4";
// import PropTypes from 'prop-types';
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {Drawer,CssBaseline, Box, Typography, Button, Grid, Collapse, TextField, IconButton, Icon } from "@material-ui/core";
// import {Link as LinkMui }  from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';

import SaveIcon from '@material-ui/icons/Save';
import DesktopMacOutlinedIcon from '@material-ui/icons/DesktopMacOutlined';
import LaptopIcon from '@material-ui/icons/Laptop';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CollectionsIcon from '@material-ui/icons/Collections';
import Crop75Icon from '@material-ui/icons/Crop75';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LayersClearOutlinedIcon from '@material-ui/icons/LayersClearOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

import Components from "./components";
import PropsEditor from "./PropsEditor";

const API_URL = '/api/v1.0';

const drawerWidth = 240;
const toolBarTop = 104;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 80,
    },
    section: {
      textAlign: "left",
      fontWeight: 500
    },
    tools_sect: {
      position: 'relative'
    },
    collapse: {
      position: 'absolute',
      top: 0,
      right: -1,     
    },
    typograph_txt: {
      margin: theme.spacing(1),
      fontSize: '1em',
      fontWeight: 400,      
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      width: "100%",
      padding: 8,
      resize: "none",
      borderRadius: 4,
      border: "1px solid #e0e0e0;",
      backgroundColor: "rgba(236, 236, 236, 0.33)"
    },
    desktop: {

    },
    laptop: {
        width: 434,
        // backgroundColor: grey[700]
    },
    code: {
      color: 'crimson',
      backgroundColor: '#f1f1f1'      
    },
    button: {
      marginRight: theme.spacing(1)
    },
    close: {
      position: 'absolute',
      top: 0,
      right: -1,
      height: 36,
      width: 36
    },
    
    drawer: {
      width: drawerWidth,      
      flexShrink: 0,      
      "& .MuiBackdrop-root": {
          display: "none"
      },
      "& .MuiDrawer-paper": {
          width: drawerWidth,          
          position: 'fixed',
          padding: theme.spacing(1),
          top: toolBarTop,          
          // border: 'none',
          border: '1px solid #ccc',
          borderRadius: 4,
          transition: 'none !important'
      }
    },
    content: {
      flexGrow: 1,
      // backgroundColor: theme.palette.background.default,
      // padding: theme.spacing(0, 1),
    },


  })
);

const defaultProps = {  
  borderColor: '#ccc',  
  // ml: 1,
  // mr: 1,
  border: 1,  
};

const defaultToolsBox = {  
  borderColor: '#ccc',  
  mb: 1,
  pt:0,
  pl: 1,
  pr: 1,
  pb: 1,
  border: 1,
  borderRadius: "borderRadius"
};

const templates = [
  {
    Id: 0,
    Data: {
      Id: uuidv4(),
      component: "root",
      children: [
        {
          Id: uuidv4(),
          component: "Typography",
          props: {
            display: "block",
            gutterBottom: true,
            variant: "h1",
            style: {
              color: "red",
            },
          },
          children: "Typography h1",
        },
        {
          Id: uuidv4(),
          component: "Typography",
          props: {
            display: "block",
            gutterBottom: true,
            variant: "h2",
          },
          children: "Typography h2",
        },
        {
          Id: uuidv4(),
          component: "Paper",
          props: {
            elevation: 2,
            style: { padding: 8, marginBottom: 8, textAlign: "center" },
          },
          children: [
            {
              Id: uuidv4(),
              component: "img",
              props: {
                src: "https://jerelia.com/assets/files/images/programs/2000x1100_Velyki_Peregony.jpg",
                style: { padding: 8, maxWidth: "100%"},
              }
            },
            // {
            //   Id: uuidv4(),
            //   component: "figure",              
            //   children: [
            //     {
            //       Id: uuidv4(),
            //       component: "picture",
            //       children:[
            //         {
            //           Id: uuidv4(),
            //           component: "source",
            //           props: {
            //             media: "(min-width: 960px)",
            //             srcSet: "https://jerelia.com/assets/files/images/programs/2000x1100_Velyki_Peregony.jpg" 
            //           }
            //         },
            //         {
            //           Id: uuidv4(),
            //           component: "source",
            //           props: {
            //             srcSet: "https://jerelia.com/assets/files/images/programs/1080x1080_Velyki_Peregony.jpg"
            //           }
            //         },
            //         {
            //           Id: uuidv4(),
            //           component: "img",
            //           props: {
            //             src: "https://jerelia.com/assets/files/images/programs/2000x1100_Velyki_Peregony.jpg",
            //             style: { padding: 8, maxWidth: "100%"},
            //           }
            //         },
            //       ],
            //       props:{
            //         style: {margin: 0, padding: 0}
            //       } 
            //     }
            //   ],
            //   props:{
            //     style: {margin: 0, padding: 0}
            //   }
            // }
          ],
        },
        {
          Id: uuidv4(),
          component: "Paper",
          children: [
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button contained",
              props: {
                variant: "contained",
                color: "primary",
                style: { marginRight: 4 },
              },
            },
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button outlined",
              props: {
                variant: "outlined",
                color: "primary",
                style: { marginRight: 4 },
              },
            },
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button Link",
              props: {
                color: "primary",
                style: { marginRight: 4 },
                href: "",
              },
            },
            {
              Id: uuidv4(),
              component: "Typography",
              children:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
              props: {
                gutterBottom: true,
                variant: "body1",
              },
            },
            {
              Id: uuidv4(),
              component: "Typography",
              children: "Typography subtitle2",
              props: {
                gutterBottom: true,
                variant: "subtitle2",
              },
            },
          ],
          props: {
            elevation: 4,
            style: { padding: 8 },
          },
        },
        {
          Id: uuidv4(),
          component: "Box",
          children: [],
          props: {
            mt: 2,
            mb: 2,
            p: 1,
          },
        },
        {
          Id: uuidv4(),
          component: "Box",
          children: [
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button contained",
              props: {
                variant: "contained",
                color: "primary",
                style: { marginRight: 4 },
              },
            },
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button outlined",
              props: {
                variant: "outlined",
                color: "primary",
                style: { marginRight: 4 },
              },
            },
            {
              Id: uuidv4(),
              component: "Button",
              children: "Button Link 2",
              props: {
                color: "secondary",
                style: { marginRight: 4 },
                href: "",
              },
            },
            {
              Id: uuidv4(),
              component: "p",
            },
            {
              Id: uuidv4(),
              component: "Typography",
              children: "Typography h3",
              props: {
                gutterBottom: true,
                variant: "h3",
              },
            },
            {
              Id: uuidv4(),
              component: "Typography",
              children: "Typography subtitle2",
              props: {
                gutterBottom: true,
                variant: "subtitle2",
              },
            },
          ],
          props: {
            mb: 1,
          },
        },
        {
          Id: uuidv4(),
          component: "Box",
          props: {
            mb: 1,
          },
          children: [
            {
              Id: uuidv4(),
              component: "span",
              children: "span block #1",
            },
            {
              Id: uuidv4(),
              component: "span",
              children: "span block #2",
              props: {
                style: {
                  color: "#fff",
                  backgroundColor: "#ff0000",
                  padding: "4px",
                  marginRight: "8px",
                },
              },
            },
            {
              Id: uuidv4(),
              component: "a",
              props: {
                href: "/",
              },
              children: "link block",
            },
          ],
        },
        // Row
        {
          Id: uuidv4(),
          component: "Grid",
          props: {
            container: true,
            spacing: 2,
          },
          children: [
            {
              Id: uuidv4(),
              component: "Grid",
              props: {
                item: true,
                xs: 12,
                sm: 6,
                md: 3,
              },
              children: "Column 4-1",
            },
            {
              Id: uuidv4(),
              component: "Grid",
              props: {
                item: true,
                xs: 12,
                sm: 6,
                md: 3,
              },
              children: "Column 4-2",
            },
            {
              Id: uuidv4(),
              component: "Grid",
              props: {
                item: true,
                xs: 12,
                sm: 6,
                md: 3,
              },
              children: "Column 4-3",
            },
            {
              Id: uuidv4(),
              component: "Grid",
              props: {
                item: true,
                xs: 12,
                sm: 6,
                md: 3,
              },
              children: "Column 4-4",
            },
          ],
        },
        {
          Id: uuidv4(),
          component: "code",
          children:
            "The push() method adds one or more elements to the end of an array and returns the new length of the array.",
          props: {
            style: { marginTop: 16, display: "block" },
          },
        },
      ],
    },
    Title: "Template #1",
    Name: "Demo",
  },
  {
    Id: 0,
    Data: {
      Id: "1d5ebcba-dad6-4621-b909-d514484084a6",
      component: "root",
      children: [
        {
          Id: "0bfc0399-3253-48a0-ad49-1e92f87be4c1",
          component: "BigCarousel",
          props: {
            slides: [
              {
                Id: "b17054ae-3486-4447-a6ea-962e8de07775",
                Title: "Slide #1 title",
                SubTitle: "Sub title slide #1",
                Media: "/assets/files/images/sliders/07901_PODIUM_баннер.jpg",
                Url: "/page/ZSU",
              },
              {
                Id: "8948b1d2-713b-424b-9e20-81b4839578d7",
                Title: "Slide #2 title",
                SubTitle: "Sub title slide #2",
                Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
                Url: "/page/ZSU",
              },
              {
                Id: "ec191a25-c1a1-456f-8534-3920458f2ff8",
                Title: "Slide #3 title",
                SubTitle: "Sub title slide #3",
                Media: "/assets/files/videos/action_2109.mp4",
                Url: "/page/ZSU",
              }
            ],
          },
        },
        {
          Id: "ec3e3c20-3764-493e-a07a-22a954fc20bf",
          component: "Typography",
          children: "Template Blank page Typography",
          props: {
            gutterBottom: false,
            variant: "h2",
            align: "center",
          },
        },
        
      ],
    },
    Title: "Blank page Template #1",
    Name: "Tmp #1",
  },
  {
    Id: 0,
    Data: {
      Id: uuidv4(),
      component: "root",
      children: [
        {
          Id: uuidv4(),
          component: "Typography",
          children: "Template #2",
          props: {
            gutterBottom: false,
            variant: "h1",
            align: "center",
          },
        },
        {
          Id: uuidv4(),
          component: "Box",
          children: "div block",
          props: {
            mt: 2,
            mb: 2,
          },
        },
        {
          Id: uuidv4(),
          component: "Box",
          props: {
            mb: 1,
            p: 2,
          },
          children: [
            {
              Id: uuidv4(),
              component: "Typography",
              children: "srOnly typography",
              props: {
                gutterBottom: false,
                variant: "srOnly",
                align: "center",
              },
            },
          ],
        },
      ],
    },
    Title: "Blank page Template #2",
    Name: "Tmp #2",
  },
];

const min = 1;

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function getWindowscrollY() {  
  return window.scrollY;
}

const Builder = () => {  
  const classes = useStyles();
  const auth = useContext(AppContext);
  const [token, setToken] = useState(null);
  const containerRef = useRef();
  // const [height, setHeight] = useState(0);
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [scrollY, setScrollY] = useState(getWindowscrollY());
  
  const [devMode, setDevMode] = useState(true);
  const [view, setView] = useState('desktop');
  const {id} = useParams();
  
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [urlKey, setUrlKey] = useState('');
      
  // eslint-disable-next-line no-unused-vars
  const [json, setJson] = useState('');
    
  const [page, setPage] = useState(null);
  const [selectedComponet, setSelectedComponet] = useState();
  const [collapsed, setCollapsed ] = useState(['Templates', 'Page', 'PageAttributes', 'Typography', 'Button', 'IconButton', 'Containers', 'Sliders', 'Image', 'Utils', 'UrlPage']);
  
  // New component base props
  const [typographyText, setTypographyText] = useState('');
  
  const [columns, setColumns] = useState(4);
  const colDirection = 'row';
  const colJustifyContent = 'flex-start';
  const colAlignItems = 'stretch';

  // const preventDefault = (event) => event.preventDefault();

  const handelSave = async () => {
    if(!page) return;
    if (!token) return;

    if(urlKey.length === 0){
      collapseTools('PageAttributes');
      return;
    }

    try
    {      
      const article = {
        Id: page.Id,
        UrlKey: urlKey,
        Name: name,
        Title: title,
        Data: JSON.stringify(page.Data)
      };

      const url = `${API_URL}/articles${(article.Id > 0 ? `/${article.Id}` : "" )}`;
      const method = article.Id === 0 ? "POST": "PUT";
      const json = JSON.stringify(article);

      // console.log('save object', article);
      // console.log('save json', json);
      // console.log(url);
        
      await fetch(url, { 
          method: method, 
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": token
          },
          body: json
      })
      .then((response) => response.json())
        .then((data) => {
          console.log(data);
          page.Id = data.Id;
          setPage({...page});
        })
        .catch((error) => {
          console.log("Error", error);
        });
        // const saved: IArticle = await result.json();
        // setArticle(saved);
     
    }
    catch(error)
    {
      console.log("Save error:", error);
    }
  };

  const getTemplatePage = (name) => {    
    const temp = templates.find(tmp => tmp.Name === name);    
    if(temp){
      setName('');
      setTitle('');
      setUrlKey('');
      return  JSON.parse(JSON.stringify(temp));
    }
    return null;
  };

  const getNewPage = () => {
    setName('');
    setTitle('');
    setUrlKey('');
    return {
      Id: 0,
      Publish: false,
      UrlKey: "",
      Title: 'New page',
      Name: 'New page',
      Data: {
        Id: uuidv4(),
        component: 'root',
        children: [          
          {
            Id: uuidv4(),
            component: "Typography",
            props: {
              display: "block",
              align: "center",
              gutterBottom: true,
              variant: "h2",
            },
            children: "New page header",
          }
        ],
      },        
    }
  };

  const clearPage = () => {
    const newPage = getNewPage();    
    setPage(newPage);    
    setSelectedComponet(null);
    setJson('');
  }; 

  // const markSelectedNode = useCallback((id) => {    
  //   const elem = document.getElementById(id);
  //   if(elem){
  //     elem.className += " selected";
  //   }
  // }, []);

  const addNodeToPage = (node, parent) => {
    if(!node.props){                                     
      node.props = {};
    }
    
    if(!parent){
      page.Data.children.push(node);
    }else {
      if(typeof parent.children === "object")
          parent.children.push(node);
      else{
        page.Data.children.push(node);
      }
    }

    const temp = {...page};
    temp.Name = name;
    temp.Title = title;
    temp.urlKey = urlKey;

    setPage(temp);
    setSelectedComponet(node);        
  };

  const handleAddBigSlider = () => {
    const bigCarousel = {
      Id: uuidv4(),
      component: "BigCarousel",
      props: {
        // slides: []
        slides: [
          {
            Id: uuidv4(),
            Title: "Slide #1 title",
            SubTitle: "Sub title slide #1",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "/page/ZSU",
          },
          {
            Id: uuidv4(),
            Title: "Slide #2 title",
            SubTitle: "Sub title slide #2",
            Media: "/assets/files/videos/action_2109.mp4",
            Url: "/page/ZSU",
          }
        ],
      },
    };
    addNodeToPage(bigCarousel, selectedComponet);         
  };

  const handleAddMiddleSlider = () => {
    const mdCarousel = {
      Id: uuidv4(),
      component: "MdCarousel",
      props: {
        // slides: []
        slides: [
          {
            Id: uuidv4(),
            Title: "MD Slide #1 title",
            SubTitle: "Sub title slide #1",
            Media: "/assets/files/images/🌿BELKOVA/Programs 2022/2807_VIP-min.gif",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #2 title",
            SubTitle: "Sub title slide #2",
            Media: "/assets/files/images/🌿BELKOVA/Programs 2022/2807_mlm-min.gif",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #3 title",
            SubTitle: "Sub title slide #3",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #4 title",
            SubTitle: "Sub title slide #4",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #5 title",
            SubTitle: "Sub title slide #5",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #6 title",
            SubTitle: "Sub title slide #6",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "MD Slide #7 title",
            SubTitle: "Sub title slide #7",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
        ],
      },
    };
    addNodeToPage(mdCarousel, selectedComponet);
  };

  const handleAddSmallSlider = () => {
    const smCarousel = {
      Id: uuidv4(),
      component: "SmCarousel",
      props: {
        // slides: []
        slides: [
          {
            Id: uuidv4(),
            Title: "SM Slide #1 title",
            SubTitle: "Sub title slide #1",
            Media: "/assets/files/images/🌿BELKOVA/Programs 2022/2807_VIP-min.gif",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #2 title",
            SubTitle: "Sub title slide #2",
            Media: "/assets/files/images/🌿BELKOVA/Programs 2022/2807_mlm-min.gif",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #3 title",
            SubTitle: "Sub title slide #3",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #4 title",
            SubTitle: "Sub title slide #4",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #5 title",
            SubTitle: "Sub title slide #5",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #6 title",
            SubTitle: "Sub title slide #6",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
          {
            Id: uuidv4(),
            Title: "SM Slide #7 title",
            SubTitle: "Sub title slide #7",
            Media: "/assets/files/images/🔵2022/md/ZSY_1204-min.png",
            Url: "",
          },
        ],
      },
    };
    addNodeToPage(smCarousel, selectedComponet);
  };

  const handleAddTextBlock = () => {    
      addNodeToPage({
        Id: uuidv4(),
        component: "Typography",
        children: typographyText === '' ? 'New text block': typographyText,
        props: {}
      }, selectedComponet);
      setTypographyText('');    
  };

  const handleAddButton = () => {    
    addNodeToPage({
      Id: uuidv4(),
      component: "CButton",
      children: "New button",
      props: {
        variant: "contained",
        color: "primary",
        size: "small",
        href: "#",
        // fullWidth: true,
        style: { },
      },
    }, selectedComponet);    
  };

  const handleAddIconButton = () => {    
    addNodeToPage({
      Id: uuidv4(),
      component: "IconButton",
      children: "",
      props: {        
        color: "primary",
        style: { },
      },
    }, selectedComponet);    
  };

  const handleAddImageBlock = () => {    
      addNodeToPage({
        Id: uuidv4(),
        component: "img",        
        props: {
          src: "",
          alt: "",
          style: { padding: 8, maxWidth: "100%"},          
        }
      }, selectedComponet);
  };

  const handleAddMediaBlock = () => {
    addNodeToPage({
      Id: uuidv4(),
      component: "Media",        
      props: {
        src: "",
        alt: "",        
        style: { padding: 8, maxWidth: "100%"},          
      }
    }, selectedComponet);
  };
  
  const handleAddDiv = () => {
    addNodeToPage({
        Id: uuidv4(),
        component: "Box",
        children: [],
        props: {
         style: {
          padding: "8px",
          margin: "0px"
        }
        }
      },selectedComponet);
  };

  const handleAddPaper = () => {
    addNodeToPage({
      Id: uuidv4(),
      component: "Paper",
      children: [],
      props: {
        elevation: 2,
        style: { padding: 8, marginBottom: 8, textAlign: "center" },
      },
    }, selectedComponet);    
  };

  const handleAddColumns = () => {
    const grid = {
      Id: uuidv4(),
      component: "Grid",
      props: {
        container: true,
        direction: colDirection,
        justifyContent: colJustifyContent,
        alignItems: colAlignItems,
        spacing: 2,
      },
      children: [],
    };

    for (let i = 0; i < columns; i++) {
      grid.children.push({
        Id: uuidv4(),
        component: "Grid",
        props: {
          item: true,
          xs: 12,
          sm: 6,
          md: 4,
          lg: 3,
          xl: 2
        },
        children: [
          {
            Id: uuidv4(),
            component: "Typography",
            children: `Column ${i+1}`,
            props: {variant: 'caption'}
          }
        //   {
        //   Id: uuidv4(),
        //   component: "span",
        //   children: `Column ${i+1}`,
        // }
      ],
      });
    }    
    addNodeToPage(grid, selectedComponet );
  };

  const handleChangeColumns = (e) => {
    if (e.target.value === "") {
      setColumns(min);
      return;
    }
    const value = +e.target.value;    
    if (value < min) {
      setColumns(min);
    } else {
      setColumns(value);
    }
  };
  
  const handleChangeDevMode = () => {
    setDevMode(!devMode);    
  };
  
  const handleToJSON = () => {
    setJson(JSON.stringify(page));
    // setJson(JSON.stringify(componentArray, getCircularReplacer()));
  };

  const handleDelete = (node) => {
    if(!node) return;    
    const temp = {...page};
    temp.Name = name;
    temp.Title = title;
    temp.urlKey = urlKey;

    deleteComponent(temp.Data, node);        
    setSelectedComponet(undefined);
    setPage(temp);          
  };

  const handleMove = (node, direction) => {
    if(!node) return;        
    const moved = moveComponent(page.Data, node, direction);    
    if(moved){
      const temp = {...page};
      temp.Name = name;
      temp.Title = title;
      temp.urlKey = urlKey;
      setPage(temp);
    }    
  };

  const handleUpdate = (node) => {
    if(!node) return;    
    const temp = {...page};
    temp.Name = name;
    temp.Title = title;
    temp.urlKey = urlKey;
    const updated = updateComponent(temp.Data, node); 
    if(updated){
      setSelectedComponet({...updated});
      setPage(temp);
    }
  };

  const searchNode = useCallback( (element, id) => {
    if(element.Id === id){
         return element;
    }else if (element.children != null){
         var i;
         var result = null;
         for(i=0; result === null && i < element.children.length; i++){
              result = searchNode(element.children[i], id);
         }
         return result;
    }
    return null;
  }, []);

  const deleteComponent = (node, deleted) => {
    if (node.children !== null) {
      
      if(node.children && typeof node.children === "object" ){
        for (let i = 0; i < node.children.length; i++) {
          if(node.children[i].Id === deleted.Id){
            node.children.splice(i, 1);
            break;
          }
          deleteComponent(node.children[i], deleted);
        }
      }    
    }    
  };

  const moveComponent = (node, moved, direction) => {
    if (node.Id === moved.Id) {      
      return node;
    } else if (node.children !== null) {
      let result = null;
      if(node.children && typeof node.children === "object" ){
        for (let i = 0; result === null && i < node.children.length; i++) {
          if(node.children[i].Id === moved.Id){            
            if(direction === 'down'){
              const downIdx = i+1;
              if(node.children.length > downIdx ){
                const temp1 = node.children[i];
                const temp2 = node.children[downIdx];
                node.children[i] = temp2;
                node.children[downIdx] = temp1;
                // console.log(`move ${direction} ${downIdx}`);
              }
            }
            
            if(direction === 'up'){
              const upIdx = i-1;
              if(upIdx >= 0 ){
                const temp1 = node.children[i];
                const temp2 = node.children[upIdx];
                node.children[i] = temp2;
                node.children[upIdx] = temp1;

                // console.log(`move ${direction} ${upIdx}`);
              }
            }
            return moved;       
          }
          result = moveComponent(node.children[i], moved, direction);
        }
      }
      return result;
    }
    return null;
  };

  const updateComponent = (node, changed) => {
      if (node.Id === changed.Id) {        
        // node.onClick = handleClickNode.bind(this, node.Id); 
        return node;
      } else if (node.children !== null) {
        let result = null;
        if(node.children && typeof node.children === "object" ){
          for (let i = 0; result === null && i < node.children.length; i++) {
            if(node.children[i].Id === changed.Id){
              const temp = {...changed};              
              node.children[i] = temp;
            }
            result = updateComponent(node.children[i], changed);
          }
        }
        return result;
      }
      return null;
  };

  // function offset(el) {
  //   var rect = el.getBoundingClientRect(),
  //   scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  //   scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //   return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  // }

  const clearSelectedCss = (id) => {
    const elem = document.getElementById(id);
    if(elem){
      let className = elem.className;
      let result = className.replace(' selected', '');            
      elem.className = result;     
    }
  };

  const clearSelection = () => {    
    if(selectedComponet){
      clearSelectedCss(selectedComponet.Id);
    }
    setSelectedComponet(undefined);
  };

  const handleClickNode = useCallback((e, node) => {
    
    if(page){      
      if(node){

        if(selectedComponet){          
          clearSelectedCss(selectedComponet.Id);
        }

        if(!e.currentTarget.className.includes(' selected')){
          e.currentTarget.className += ' selected';
        }

        setSelectedComponet(node);

        // console.log(window.screenY,  e.currentTarget.offsetTop,  e.currentTarget.offsetHeight );
        // console.log(document.documentElement.scrollTop);
        
        // setTop(e.currentTarget.offsetTop - (window.pageYOffset || document.documentElement.scrollTop));
        // setLeft(e.currentTarget.offsetLeft + e.currentTarget.offsetWidth  );
        // setAnchorEl(e.currentTarget);        
        
        // setSelectedComponet({...node});
        
      }
      else
        setSelectedComponet(undefined);
    }    
  }, [page, selectedComponet]);

  const handleClickLoadPage = (tmpName) => {
    const templ = getTemplatePage(tmpName);    
    if(templ){
        setPage(templ);
        setSelectedComponet(null);
        setJson('');
    }
  };

  const collapseTools = (id) => {
    const idx = collapsed.indexOf(id);
    if(idx === -1){
      collapsed.push(id);
    }else{
      collapsed.splice(idx, 1);
    }
    setCollapsed([...collapsed]);
  };

  const handleCollapseTools = (e, id) => {
    e.stopPropagation();
    collapseTools(id);
  };

  const fetchPage = useCallback(async () => {
    if (!token) return;

    if(!id){
      const newPage = getNewPage(); // getTemplatePage('Tmp #1');
      setPage(newPage);
    }else{
      const url = `${API_URL}/articles/${id}`;                    
      await fetch(url, { 
          method: 'GET', 
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": token
          },          
      })
      .then((response) => response.json())
        .then((data) => {
          if(data){            
            setPage({
              Id: data.Id,
              UrlKey: data.UrlKey,
              Name: data.Name,
              Title: data.Title,
              Data: JSON.parse(data.Data)
            });
            setName(data.Name);
            setTitle(data.Title);
            setUrlKey(data.UrlKey);
          } else{
            const newPage = getNewPage();
            setPage(newPage);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
      // const templ = getTemplatePage("Demo");      
          
  }, [token, id]);

  useEffect( () => {
    fetchPage();
  }, [token, fetchPage]);

  useEffect(() => {
    function fetchUser() {
      auth.getUser().then((user) => {
        let token = null;
        if (user != null) {
          token = `${user.token_type} ${user.access_token}`;
        }
        setToken(token);
      });
    }
    fetchUser();
  }, [auth]);

  // useEffect(() => {    
  //   setHeight(containerRef.current.clientHeight - 64);    
  // }, [containerRef])

  useEffect(() => {
    function handleWindowScroll() {
      const scrollY = getWindowscrollY();       
      setScrollY(scrollY);
    }

    window.addEventListener('scroll', handleWindowScroll);
    
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);


  useEffect(() => {
    function handleWindowResize() {
      const winSize = getWindowSize();       
      setWindowSize(winSize);
    }

    window.addEventListener('resize', handleWindowResize);
    
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toolsArray = [

    
    //Top Templates
    <Box mb={1} key='Top'>             
      <Grid container direction="row" spacing={1}>
          <Grid item>
            <IconButton 
              size='small'
              component={Link}              
              to={"/manage/articles"} 
              title="Back to articles"         
            >
              <ArrowBackIcon/>
            </IconButton >
          </Grid>
          <Grid item>
            <IconButton 
            size='small'
              onClick={handelSave} 
              color="primary"
              title="Save page"
            >
              <SaveIcon/>
            </IconButton>
          </Grid>
          <Grid item >
            <IconButton 
              size='small'
              onClick={clearPage} 
              color="secondary" 
              title="Clear all content" 
            >
              <LayersClearOutlinedIcon/>
            </IconButton>
          </Grid>
        </Grid>      
    </Box>,

    <Box {...defaultToolsBox} className={classes.tools_sect} key='Templates'>
      <Typography variant='caption' display='block' className={classes.section}  gutterBottom>Templates</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Templates')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Templates')) }>
        <Grid container direction="row" spacing={1}>
          {templates.map((tmp) => 
            <Grid key={tmp.Name} item xs={6}>
              <Button 
                size="small" 
                variant="outlined" 
                fullWidth 
                startIcon={<span className="material-icons">view_quilt</span>} 
                onClick={() => handleClickLoadPage(tmp.Name)}
                title={tmp.Title}
              >
                {tmp.Name}
              </Button>  
            </Grid>
          )}          
        </Grid>
      </Collapse>
    </Box>,

    // Page
    <Box {...defaultToolsBox} key='Page' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Page</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Page')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Page')) }>
        <Grid container direction="row" spacing={1}>           
          <Grid item>
            <IconButton 
              size="small" 
              variant="outlined" 
              onClick={handleChangeDevMode}
              title={devMode ? "hide border": "show border"} >
              {devMode? <BorderClearIcon/> : <BorderOuterIcon />}              
              </IconButton>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={()=> setView('desktop')} title='Desktop' >
              <DesktopMacOutlinedIcon/>
            </IconButton>
          </Grid>
          <Grid item >
            <IconButton size="small" onClick={()=> setView('laptop')} title='Laptop' >
              <LaptopIcon/>
            </IconButton>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <TextField 
            label="key url"
            margin="dense"
            variant="outlined"
            required
            error={urlKey.length === 0}
            fullWidth            
            size="small"
            aria-label="page_urlKey" placeholder="Key url"
            value={urlKey}
            onChange={(e) => setUrlKey(e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="page name"
            margin="dense"
            variant="outlined"
            fullWidth            
            size="small"
            aria-label="page_name" placeholder="Page name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField 
              label="page title"
              margin="dense"
              variant="outlined"
              fullWidth            
              size="small"
              aria-label="page_title" placeholder="Page title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              />
          </Grid>
          {page && page.Id > 0 && <>
          <Grid item xs={12}>
            <Button size='small'
              startIcon={<FileCopyIcon />}
              onClick={() => navigator.clipboard.writeText(`/article/${page.UrlKey}`)} 
            >
              Copy url
            </Button>            
          </Grid>

          <Grid item xs={12}>
            <Link to={`/article/${page.UrlKey}`} target="_blank" rel="noreferrer noopener" color="inherit">
              Preview in new window
            </Link>
          </Grid>
          </>}
      </Grid>                       

      </Collapse>
    </Box>,

    // Page attributes
    // <Box {...defaultToolsBox} key='PageAttributes' className={classes.tools_sect}>
    //   <Typography variant='caption' display='block' className={classes.section} gutterBottom>Page attributes</Typography>
    //   <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'PageAttributes')}>
    //     <PlaylistPlayIcon fontSize="inherit" />
    //   </IconButton>
    //   <Collapse in={ !(collapsed.find((el) => el === 'PageAttributes')) }>
      
    //   </Collapse>
    // </Box>,
      
    // Containers
    <Box {...defaultToolsBox} key='Containers' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Containers</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Containers')}>
        <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Containers')) }>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <IconButton 
            size="small"             
            onClick={handleAddDiv}
            title='Box'
            >
              <DashboardOutlinedIcon />
            </IconButton>
        </Grid>
        <Grid item>
          <IconButton 
            size="small" 
            onClick={handleAddPaper}
            title='Paper'
            >
              <NoteOutlinedIcon />
            </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={3}>
          <IconButton onClick={handleAddColumns} title='Add columns'>
            <ViewColumnOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          <TextField
            label="Columns"
            size="small"
            variant="outlined"
            fullWidth
            value={columns}                            
            margin='dense'      
            type="number" inputProps={{ min: min,  step: 1 }}
            min={min} 
            onChange={handleChangeColumns}
          />
      </Grid>        
      </Grid>                        
      </Collapse>
    </Box>,

    // Typography
    <Box {...defaultToolsBox} key='Typography' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Typography</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Typography')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Typography')) }>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Button 
              size="small" 
              variant="outlined" 
              onClick={handleAddTextBlock} 
              startIcon={<TextFieldsIcon />}
              fullWidth                  
            >
              Text block
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
                key='90f8c3aa-3b57-48c4-80bb-f5a5f584070a'
                label="Text" 
                value={typographyText} 
                variant='outlined'
                fullWidth
                onChange={e => setTypographyText(e.target.value) } 
              />
          </Grid>    
        </Grid>
      </Collapse>
    </Box>,
    
    // Button
    <Box {...defaultToolsBox} key='Button' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Button</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Button')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Button')) }>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={6}>
            <Button 
              size="small" 
              variant="outlined" 
              onClick={handleAddButton} 
              startIcon={<Crop75Icon />}
              fullWidth                  
            >
              Button
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
              size="small" 
              variant="outlined" 
              onClick={handleAddIconButton} 
              startIcon={<RadioButtonUncheckedIcon />}
              fullWidth                  
            >
              Button
            </Button>
          </Grid>          
        </Grid>
      </Collapse>
    </Box>,
    
    // Image
    <Box {...defaultToolsBox} key='Image' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Image</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Image')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Image')) }>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}> 
          <Grid item xs={6}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddMediaBlock}
              startIcon={<CollectionsIcon />}
              fullWidth
            >
              Media
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddImageBlock}
              startIcon={<ImageOutlinedIcon />}
              fullWidth
            >
              Image
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>,

    // Sliders
    <Box {...defaultToolsBox} key='Sliders' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Sliders</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Sliders')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Sliders')) }>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <Typography component="div" display='block'  gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddBigSlider} startIcon={<ViewCarouselIcon />}>Lg</Button></Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="div" display='block'  gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddMiddleSlider} startIcon={<ViewCarouselIcon />}>Md</Button></Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component="div" display='block'  gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddSmallSlider} startIcon={<ViewCarouselIcon />}>Sm</Button></Typography>
          </Grid>
        </Grid>
      </Collapse>
    </Box>,

    // Utils
    <Box {...defaultToolsBox} key='Utils' className={classes.tools_sect}>
      <Typography variant='caption' display='block' className={classes.section} gutterBottom>Utils</Typography>
      <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Utils')}>
          <PlaylistPlayIcon fontSize="inherit" />
      </IconButton>
      <Collapse in={ !(collapsed.find((el) => el === 'Utils')) }>
        <Box>
          <Button size="small" variant="outlined" fullWidth onClick={handleToJSON}>JSON</Button>
        </Box>      
      </Collapse>
    </Box>,     
  ];

  return (
    <AppConsumer>
      {() => (
        <div className={classes.root} style={{ padding: 4 }}>
          <CssBaseline />
          
          {/* Builder */}
          <div className={classes.content} onClick={clearSelection}>
            
            <Box className={ clsx( (devMode ? "":"builder"), (view === 'desktop' ? classes.desktop: classes.laptop) ,(devMode ? "show-block":"") )} p={1} borderRadius="borderRadius" {...defaultProps} > 
              
              {/* Build custom componets */}
              { page ? page.Data.children.map(item => Components(item, handleClickNode)) 
                     : <Typography variant='h4' display='block' align='center'>Empty page</Typography>  }
            </Box>            
            
            <Box m={1} p={2}>
              <code className={classes.code}>
                {json}
              </code>                
            </Box>
          
          </div>
          
          <div ref={containerRef} style={{ position: 'relative'}}>
            <Drawer
              PaperProps={{
                style: {                  
                  top: scrollY - toolBarTop > 0 ? 4 : toolBarTop - scrollY,
                  height: (scrollY - toolBarTop > 0 ? windowSize.innerHeight - 2 : windowSize.innerHeight - toolBarTop + scrollY) - 4
                }                
              }}
              open={true}
              anchor='right'
              className={classes.drawer}
              variant="persistent"                                                    
            >
              {/* <Typography variant="caption" gutterBottom>
                  W:{windowSize.innerWidth}; H:{windowSize.innerHeight};
                  Y - Bar = {scrollY - toolBarTop } Y:{scrollY} </Typography> */}
              {toolsArray}
                  
              {selectedComponet && 
              <Box {...defaultToolsBox} key='Utils' className={classes.tools_sect}>
                <Typography variant='caption' display='block' className={classes.section} gutterBottom>Properties</Typography>

                <IconButton aria-label="collapse" className={classes.collapse} size="small" onClick={(e)=>handleCollapseTools(e, 'Properties')}>
                    <PlaylistPlayIcon fontSize="inherit" />
                </IconButton>
                <Collapse in={ !(collapsed.find((el) => el === 'Properties')) }>                      
                  <PropsEditor 
                    node={selectedComponet} 
                    onUpdate={handleUpdate} 
                    onMove={handleMove} 
                    onDelete={handleDelete} />
                </Collapse>
              </Box>                
              }
            </Drawer>            
        </div>
                                  
        </div>
      )}
    </AppConsumer>
  );
};

// Builder.propTypes = {
//     data: PropTypes.shape({
//         Id: PropTypes.number.isRequired,
//         name: PropTypes.string,
//         title: PropTypes.string,
//         body: PropTypes.arrayOf(

//         )
//     }).isRequired
// }

export default withRouter(Builder);
