import React,  {
    useState    
} from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import {
    Box, Grid, IconButton, FormGroup, Tabs, Tab, Typography, FormControl, 
    Chip, RadioGroup, FormControlLabel, Radio, TextField, Checkbox, Card,
    CardContent, CardActions, Collapse, Divider, Button, InputLabel,
    Select, MenuItem, Paper,
    Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TablePagination
} from "@material-ui/core";
import { roles } from '../../../definitions/roles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LinkIcon from '@material-ui/icons/Link';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { countryToFlag } from "../../../store/countries";
import { ContentType } from '../../../definitions/contenttype';
import { ShowNotification} from '../../../definitions/shownotification';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        role: {
            marginRight: theme.spacing(1/2),
            marginTop: theme.spacing(1/2),
        },
        formControl: {
            padding: theme.spacing(0, 1),
            width: "100%",
            display: "block",            
        },
        formControlLabel: {
            width: 164, 
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        countrychexbox: {
            fontSize: '.9em'
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        chipIcon: {
            fontSize: 18,
        },
        expand: {            
            transform: 'rotate(0deg)',
            marginLeft: 'auto',            
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        action: {
            cursor: 'pointer'
        },
        
        codesPaper: {
            padding: theme.spacing(1,1)
        },
        users: {
            width: '100%',
        },
        codes: {
            maxHeight: 240,
            // overflow: "hidden",
            // overflowY: "scroll"   
        },
        button: {
            marginRight: theme.spacing(1)
        }
    })
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`page-atrribs-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const PageAttribute = (props) => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const [expanded, setExpanded] = useState(true);
    const { page, countries,
            onChange, onAddRole,onDeleteRole, onClearAll, onAddUser, onDeleteUser, onDeleteAllCode,
            onChangeCountry, onAddAllCountries, onClearCountries } = props;
    
    const [pages, setPages] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [code, setCode] = React.useState('');
    
    const handleAddUser = () => {
        const _code = parseInt(code);
        
        console.log(_code);
        if(!isNaN(_code)){
            onAddUser(_code);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPages(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPages(0);
      };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    const checkUniqUrl = () => {
        if (!content) return;
        console.log(content.Key);
    };

    const checkedCountry = (country) => {
        if(!page.Countries) return false;        
        
        return page.Countries.findIndex(c => c === country) > -1;
    }

    return (
        <div className={classes.root}>
            <div>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    aria-label="Page tabs props"
                >
                    <Tab label="Page attributes" />
                </Tabs>
                <TabPanel value={tab} index={0}>
                    {page && (
                        <>
                            <TextField
                                name="Name"
                                label="Page name"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                value={page.Name}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <TextFieldsIcon />
                                }}                      
                            />

                            <TextField 
                                name="Key"
                                label="Key"
                                fullWidth
                                variant="outlined"
                                margin='dense'
                                value={page.Key}
                                onChange = {onChange}
                                InputProps={{
                                    endAdornment: 
                                    <IconButton onClick={checkUniqUrl} size='small'>
                                        <LinkIcon />
                                    </IconButton>
                                }}
                            />

                            <Grid  container direction='row' spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        name="StartedOn"
                                        // type="datetime-local"
                                        type='date'
                                        label="Show from"                    
                                        placeholder="Show from"
                                        margin='dense'
                                        inputProps={{ step: 1 }}
                                        InputLabelProps={{ shrink: true }}                                
                                        variant="outlined"
                                        fullWidth
                                        //value={moment(page.StartedOn).format("YYYY-MM-DDTkk:mm") }
                                        value={moment(page.StartedOn).format("YYYY-MM-DD") }
                                        onChange = {onChange}                                        
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    name="FinishedOn"
                                    type="date"
                                    label="Show to"                    
                                    placeholder="Show to"
                                    fullWidth
                                    margin="dense"
                                    inputProps={{ step: 1 }}
                                    InputLabelProps={{ shrink: true }}                                
                                    variant="outlined"
                                    // value={moment(content.FinishedOn).format("YYYY-MM-DDTkk:mm") }
                                    value={moment(page.FinishedOn).format("YYYY-MM-DD") }
                                    onChange = {onChange}
                                />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" className={classes.FormControl} fullWidth>
                                        <InputLabel                                         
                                            id="select-contenttype"
                                            aria-describedby="select-contenttype"
                                        >
                                            Content type
                                        </InputLabel>
                                        <Select
                                            labelId="select-contenttype"                                        
                                            name="ContentType"
                                            value={ page.ContentType ? `${page.ContentType}`: '0'}
                                            onChange={onChange}
                                        >
                                            <MenuItem value='0'>Page</MenuItem>                                        
                                            <MenuItem value='4'>Notification</MenuItem>
                                            {/* <MenuItem value='8'>Popup Notification</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                {(page.ContentType === ContentType.Notification || page.ContentType === ContentType.PopupNotification) && 
                                    <Grid item xs={12}>
                                        <TextField 
                                            type="number"
                                            name="ShowDuration"
                                            label="Show duration, sec"
                                            fullWidth
                                            variant="outlined"
                                            margin='dense'
                                            value={page.ShowDuration === 0 ? 3 : page.ShowDuration}
                                            onChange = {onChange}
                                            InputProps={{
                                                endAdornment: <AccessTimeIcon />                                                
                                            }}
                                        />
                                    </Grid>
                                }

                                {page.ContentType === ContentType.Notification && 
                                <Grid item xs={12}>
                                    <Typography variant="body2">Additional props</Typography>                                                                            
                                    <FormControl component="fieldset" >
                                        <RadioGroup row
                                            aria-label="Show notification" name="ShowNotification"
                                            value={`${page.ShowNotification}`}                                        
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="Everywhere"  />
                                            <FormControlLabel value="1" control={<Radio />} label="Except" />
                                            <FormControlLabel value="2" control={<Radio />} label="Only" />
                                        </RadioGroup>
                                    </FormControl>
                                                                
                                    <TextField 
                                        name="DestinationUrl"
                                        label="DestinationUrl"
                                        disabled={page.ShowNotification === ShowNotification.Everywhere}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        multiline
                                        minRows={3}
                                        value={page.DestinationUrl ? page.DestinationUrl : ""}
                                        onChange = {onChange}
                                    />                                                                                                                                            
                                </Grid>}

                                <Grid item xs={12}>
                                        <FormControlLabel className={classes.formControlLabel} 
                                                            control={   
                                                                <Checkbox                                                                 
                                                                    name='ShowFirstTime'
                                                                    checked={page.ShowFirstTime}
                                                                    onChange={onChange}
                                                                />
                                                            }
                                                            label='Show for the first time'
                                                        />
                                </Grid>

                            </Grid>
                            
                            
                                {/* <Divider style={{marginBottom: 8}} /> */}
                                
                                <Card elevation={0}>
                                    <CardActions className={classes.action} disableSpacing onClick={handleExpandClick}>
                                        <Typography variant="button">
                                            Rights and access to page
                                        </Typography>
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                            >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    
                                

                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                
                                    <FormControl component="fieldset" >
                                        <RadioGroup row
                                            aria-label="visitor" name="Visitor"
                                            value={`${page.Visitor}`}                                        
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="All"  />
                                            <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                                            <FormControlLabel value="2" control={<Radio />} label="Roles of users" />
                                            <FormControlLabel value="3" control={<Radio />} label="Users" />
                                        </RadioGroup>
                                </FormControl>

                                {page.Visitor === 2 &&
                                    <div>
                                        <div>
                                            <Typography variant="subtitle2" display="block" gutterBottom>
                                                Select roles:
                                            </Typography>
                                        {roles.map((role) => (
                                            <Chip className={classes.role} 
                                                key={role}                                    
                                                disabled={page.Visitor !== 2}
                                                label={role} variant="outlined"
                                                size="small" icon={<CheckCircleOutlineIcon />}
                                                onClick={() => onAddRole(role)} />
                                        ))}
                                        </div>
                                        <Box pt={2}>
                                            <Typography variant="subtitle2" display="block">
                                                Selected roles: {page.Roles.length === 0 ?<b>None</b>:null}
                                            </Typography>

                                            {page.Roles.length > 0 && 
                                                <Chip className={classes.role}
                                                label='Clear all' variant="outlined"
                                                color="secondary" size="small" icon={<HighlightOffIcon />}
                                                onClick={onClearAll} />
                                            }

                                            {page.Roles.map((role) => (
                                                <Chip color='primary' className={classes.role} key={role}
                                                    label={role} variant="outlined"
                                                    size="small" icon={<HighlightOffIcon />}
                                                    onClick={() => onDeleteRole(role)} />
                                                ))
                                            } 
                                        </Box>
                                    </div>    
                                }

                                {page.Visitor === 3 && 
                                <div>
                                    <Grid  container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center" spacing={1}
                                    >
                                        <Grid item xs='auto'>
                                        <TextField
                                            style={{width: '154px'}}
                                            name="Code"
                                            label="Reg. number"                                            
                                            variant="outlined"
                                            margin="dense"
                                            value={code}
                                            onChange={e => setCode(e.target.value)}                                            
                                        />
                                        </Grid>
                                        <Grid item >
                                            <Button                                                
                                                variant='outlined'
                                                color="default"
                                                className={classes.button}
                                                startIcon={<PersonAddIcon />}
                                                onClick={handleAddUser}
                                            >
                                                Add                                    
                                            </Button>

                                            <Button                                            
                                                variant='outlined'
                                                color="default"
                                                className={classes.button}
                                                startIcon={<PersonAddDisabledIcon />}
                                                onClick={()=>onDeleteAllCode()}
                                            >
                                            Clear
                                            </Button>
                                        </Grid>
                                    </Grid>                                    

                                    <Paper className={classes.users} elevation={0} style={{marginTop: 16}}>
                                        <TableContainer className={classes.codes}>
                                            <Table size="small" stickyHeader className={classes.table} aria-label="codes table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>User codes</TableCell>
                                                        <TableCell>Act</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {page.RegistCodes.slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row}>
                                                        <TableCell>{row}</TableCell>
                                                        <TableCell>
                                                            <IconButton size='small' onClick={()=> onDeleteUser(row)} >
                                                                <ClearIcon fontSize='small' />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                                })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={page.RegistCodes.length}
                                            rowsPerPage={rowsPerPage}
                                            page={pages}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />

                                    </Paper>
                                        
                                    
                                    
                                    
                                </div>
                                }

                                {countries && 
                                <Box pt={2}>
                                    <Divider style={{marginBottom: 8}} />   
                                    <Box pt={1} >
                                        <Typography 
                                            component='span' 
                                            variant="body2"
                                            style={{marginRight: 24}} >
                                            Show in countries:
                                        </Typography>
                                        <Chip variant='outlined'
                                            size='small'
                                            className={classes.chip}
                                            label='Check all' color="primary"
                                            icon={<DoneAllIcon />}
                                            onClick={onAddAllCountries}
                                        />
                                        <Chip variant='outlined'
                                            size='small'
                                            label='Uncheck all' color="secondary"
                                            className={classes.chip}
                                            icon={<span className={clsx(classes.chipIcon, "material-icons")} >remove_done</span>}
                                            onClick={onClearCountries}
                                        />                                
                                    </Box>
                                    <Box mt={1} style={{overflow: 'auto'}}>
                                        <FormGroup row>
                                            <FormControl component="fieldset" className={classes.formControl}>                                        
                                                {
                                                    countries.map((country) => (                    
                                                        <FormControlLabel className={classes.formControlLabel} key={country.TwoLetterISORegionName}
                                                            control={   
                                                                <Checkbox                                                                 
                                                                    name={country.TwoLetterISORegionName}
                                                                    checked={checkedCountry(country.TwoLetterISORegionName)}
                                                                    onChange={onChangeCountry}
                                                                />
                                                            }
                                                            label={<Typography className={classes.countrychexbox}>{countryToFlag(country.TwoLetterISORegionName)} {country.Name}</Typography>}
                                                        />
                                                ))
                                                }
                                            </FormControl>
                                        </FormGroup>
                                    </Box>
                                </Box>                                
                                }
                                </Collapse> 
                                
                                </Card>
                            
                        </>
                    )}
                </TabPanel>
            </div>
        </div>
    );
};

PageAttribute.propTypes = {
    page: PropTypes.object,
    countries: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onAddRole: PropTypes.func.isRequired,
    onDeleteRole: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    onChangeCountry: PropTypes.func.isRequired,
    onAddAllCountries: PropTypes.func.isRequired,
    onClearCountries: PropTypes.func.isRequired,
    onAddUser: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onDeleteAllCode: PropTypes.func.isRequired,
};

export default PageAttribute;
