
// eslint-disable-next-line no-unused-vars
import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/NavItems";
import Button from "@material-ui/core/Button";
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import IconButton from "@material-ui/core/IconButton";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Divider from "@material-ui/core/Divider";
// import Alert from "@material-ui/lab/Alert";
import ShoppingCard from "@material-ui/icons/ShoppingCartOutlined";
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
// eslint-disable-next-line no-unused-vars
// import clsx from "clsx";
import { AppContext, AppConsumer } from "../../providers/appProvider";
// import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {    
            marginTop: theme.spacing(1/2),            
            flexGrow: 1,            
        },
        customer: {
            textTransform: "capitalize",
        },
        paper: {
            padding: theme.spacing(0, 2),            
        },
        currency: {
            fontSize: "1.2rem"
        },
        goto: {
            margin: theme.spacing(2,0,1,1)
        },
        divider: {
            marginBottom: theme.spacing(1)
        },
        balance: {
            // color: theme.palette.secondary.main,
        },
        btnViber: {
            margin: theme.spacing(1),
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
            "&:hover": {
              backgroundColor: deepPurple[400],
            },
          },
          btnTelegram: {
            margin: theme.spacing(1),
            color: theme.palette.getContrastText(lightBlue[600]),
            backgroundColor: lightBlue[600],
            "&:hover": {
              backgroundColor: lightBlue[500],
            },
          },
    })
);

const RechargeSuccess = (props) => {
    const { amount, customerName } = (props.location && props.location.recharged) || {};
    const {Balance, getCustomer} = props;

    // const [token, setToken] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    
    const auth = useContext(AppContext);
    
    

    useEffect(() => {
        const loadData = (user) => {        
            if(!user) return;        
            getCustomer(user);
        };
        
        function fetchUser() {
            auth.getUser().then(user => {    
                if (user != null) {    
                    loadData(user);
                }    
            });
        }

        fetchUser();                
    }, [auth, getCustomer]); 

    return (                
    <AppConsumer>
        {() => (            
            <div className={classes.root}>
                <Grid container direction="row" alignItems="stretch" spacing={2} style={{ justifyContent: "center"}} >
                    <Grid item xs={12} sm={8} md={4}>
                        <Paper className={classes.paper}>

                            
                            {amount && amount > 0 && 
                                <>
                                <Typography align="center" component="h1" variant="h6" gutterBottom>{t("common.ThankYou")}</Typography>
                                <Typography className={classes.customer} align="center" variant="h5" gutterBottom>{customerName}</Typography>
                                <Typography align="center" variant="body1" gutterBottom>
                                    {t("common.ForRecharge")}:
                                </Typography>
                                <Typography align="center" component="h1" variant="h4" gutterBottom>{amount} <small className={classes.currency}>{t("common.CurrencyName")}</small></Typography>
                                </>
                            }                            
                            
                            {Balance &&
                                <Typography variant={amount && amount > 0 ? "body1": "h5"}  className={classes.balance} align="center" >
                                    Поточний баланс: <br /> <b>{Balance.Amount}</b> / <b>{Balance.AmountOB}</b>
                                </Typography>                                
                            }
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.goto}  >
                                <Grid item style={{textAlign: "center"}}> 
                                    <Button size="small" className={classes.goto} color="primary" startIcon={<ShoppingCard />}
                                        onClick={() => {props.history.push("/order");}} >
                                        {t("pay.GoToOrder")}
                                    </Button>                                        
                                </Grid> 
                                <Grid item style={{textAlign: "center"}}> 
                                    <Button size="small" className={classes.goto} color="primary" startIcon={<LibraryBooksOutlinedIcon />}
                                        onClick={() => {props.history.push("/orders");}} >
                                        {t("pay.GoToOrders")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item>
                                    <IconButton className={classes.btnViber} aria-label="viber" target="_about" href="viber://pa?chatURI=supportjerelia">
                                        <ViberIcon color="primary" fontSize="small" width={24} height={24} />
                                    </IconButton>
                                    <IconButton className={classes.btnTelegram} aria-label="telegram" target="_about" href="https://t.me/JR_support_Bot">
                                        <TelegramIcon fontSize="small" width={24} height={24} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="body2" >{t("register.ContactSupport")}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )}   
    </AppConsumer>
    );
}

// RechargeSuccess.propTypes = {
//     amount: PropTypes.number.isRequired
// }

// export default RechargeSuccess;

export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(RechargeSuccess);