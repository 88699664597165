
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
// import Autocomplete from "@material-ui/lab/Autocomplete";

import ColorPicker from "material-ui-color-picker";
// eslint-disable-next-line no-unused-vars
import {ButtonGroup, Collapse, Box, InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CheckIcon from '@material-ui/icons/Check';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    heading: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightRegular,      
    },
    acrd_summary: {
      backgroundColor: '#11101008',
      padding: theme.spacing(0, 1),
      minHeight: 38
    },
    details: {
      display: 'block',
      padding: theme.spacing(1/2, 1/2)
    },
    message: {
      fontSize: '1em',
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      fontWeight: 400,    
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      width: "100%",
      padding: 8,
      resize: "none",
      borderRadius: 4,
      border: "1px solid #cccccc",
      backgroundColor: "rgba(238, 238, 238, 0.35)"
    },
    button: {
      marginRight: theme.spacing(1)
    },
    formControl: {      
      width: "100%",
      display: "block",
      marginBottom: theme.spacing(1)
    },
    tg_button:{
      padding: theme.spacing(1/4)
    }


}));


const TypographyVariants = [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly' ];
const TypographyAligns = ['inherit', 'left',  'center',  'right',  'justify'];
const TypographyColors = ['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error'];

const TypographyEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [ padding, setPadding] = useState(node.props && node.props.style && node.props.style.padding ? node.props.style.padding: '');
  const [ margin, setMargin] = useState(node.props && node.props.style && node.props.style.margin ? node.props.style.margin: '');
  const [color, setColor] = useState(node.props && node.props.style && node.props.style.color ? node.props.style.color: '#000');
  const [backgroundColor, setBackgroundColor] = useState(node.props && node.props.style && node.props.style.backgroundColor ? node.props.style.backgroundColor: '#fff');
  const [ fontSize, setFontSize] = useState(node.props && node.props.style && node.props.style.fontSize ? node.props.style.fontSize: '');
  const [formats, setFormats] = React.useState(() => []);
    
  const handleFormat = (event, newFormats) => {
        
    if(typeof component.props.style === "undefined")
      component.props.style = {};
  
    const style = {...component.props.style};

    if(typeof style.fontWeight === "undefined")
      style.fontWeight = '';

    if(typeof style.fontStyle === "undefined")
      style.fontStyle = '';

    if(typeof style.textDecoration === "undefined")
      style.textDecoration = '';
    
    style.fontWeight =  newFormats.findIndex(el => el === 'bold') > -1 ? 'bold' : '';
    style.fontStyle = newFormats.findIndex(el => el === 'italic') > -1 ? 'italic' : ''; 
    style.textDecoration = newFormats.findIndex(el => el === 'underline') > -1 ? 'underline' : '';
    
    component.props.style = style;
  
    setFormats(newFormats);  
    setComponent(component);
  };

  const handleChangeChildrenText = (e) => {
    if(typeof component.children === "string"){
      component.children = e.target.value;          
      setComponent({...component});
    }
  };

  const handleChangeTypographyVariant = (e) => {
    component.props.variant = e.target.value;
    setComponent({...component});
  };

  const handleChangeTypographyGutterBottom = (e) => {
    component.props.gutterBottom = e.target.checked;
    setComponent({...component});
  };

  // const handleChangeTypographyAlign = (e) => {
  //   component.props.align = e.target.value;
  //   setComponent({...component});
  // };

  const handleChangeAlign = (event, newAlignment) => {
    component.props.align = newAlignment;
    setComponent({...component});
    handleUpdate();
  };

  const handleChangeTypographyColor = (e) => {
    component.props.color = e.target.value;
    setComponent({...component});
  };

  const handleChangePadding = (e) => {    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    if(typeof component.props.style === "undefined")
      component.props.style.padding = '';
  
    const style = {...component.props.style};
    style.padding = e.target.value;
    component.props.style = style;
    setPadding(e.target.value);
    setComponent(component);
  };

  const handleChangeMargin = (e) => {    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    if(typeof component.props.style === "undefined")
      component.props.style.margin = '';
  
    const style = {...component.props.style};
    style.margin = e.target.value;
    component.props.style = style;
    setMargin(e.target.value);
    setComponent(component);
  };

  const handleChangeFontSize = (e) => {    
    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    
    const style = {...component.props.style};

    if(typeof style.fontSize === "undefined")
      style.fontSize = '';

    style.fontSize = e.target.value;    
    component.props.style = style;

    setFontSize(e.target.value);
    setComponent(component);
  };

  const handleChangeColor = (color) => {    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    
    const style = {...component.props.style};  
    
    if(typeof style.color === "undefined")
      style.color = '';

    style.color = color;    
    component.props.style = style;

    setColor(color);
    setComponent({...component});
  };

  const handleChangeBackgroundColor = (color) => {
    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    if(typeof component.props.style === "undefined")
      component.props.style.backgroundColor = '';
    
    const style = {...component.props.style};
    style.backgroundColor = color;
    component.props.style = style;
    setBackgroundColor(color);
    setComponent(component);
  };

  const handleUpdate = () => {
    if(onUpdate)
      onUpdate(component);
  };

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={classes.root}>
        {component && 
        <Box pt={1}>
          
          {/* Text tool box */}
          <Accordion square>
            <AccordionSummary
              className={classes.acrd_summary}
              expandIcon={<ExpandMoreIcon fontSize="small" />}
              aria-controls="text-content"
              id="text-header"
            >
              <Typography className={classes.heading}>Text</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>              
              {typeof component.children === "string" &&               
                <TextareaAutosize                       
                    className={classes.message}
                    aria-label="minimum height" minRows={2} maxRows={5} placeholder="Text"
                    value={component.children} 
                    onChange={handleChangeChildrenText} 
                    />
              }              
            </AccordionDetails>
          </Accordion>
          
          {/* Typography tool box */}
          <Accordion square>
            <AccordionSummary
              className={classes.acrd_summary}
              expandIcon={<ExpandMoreIcon fontSize="small" />}
              aria-controls="typography-content"
              id="typography-header"
            >
              <Typography className={classes.heading}>Typography</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                        
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="typographyVariantLB">Variant</InputLabel>
                  <Select
                      label="Variant"
                      fullWidth
                      margin="dense"
                      size="small"
                      labelId="typographyVariant"
                      id="typographyVariant"
                      value={component.props.variant ? component.props.variant: TypographyVariants[8] }
                      onChange={handleChangeTypographyVariant}
                    >
                      { TypographyVariants.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}
                    </Select>                
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="typographyColorLB">System color</InputLabel>
                  <Select
                    label="System color"
                    size="small"
                    margin="dense"
                    fullWidth
                    labelId="typographyColorLB"
                    id="typographyColor"
                    value={component.props.color ? component.props.color: TypographyColors[0] }
                    onChange={handleChangeTypographyColor}
                  >
                    { TypographyColors.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}
                  </Select>
                </FormControl>
              
                <TextField 
                    label="Font size"
                    size="small"
                    variant="outlined"
                    value={fontSize}                   
                    fullWidth
                    margin="dense"
                    onChange={handleChangeFontSize}
                />

                {/* ['inherit', 'left',  'center',  'right',  'justify']; */}
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item >
                    <Typography variant="caption" >Align</Typography>
                  </Grid>
                  <Grid item>
                    <ToggleButtonGroup 
                      size="small" 
                      value={component.props.align ? component.props.align: TypographyAligns[0] }
                      exclusive 
                      onChange={handleChangeAlign}>
                      <ToggleButton value="left" title="left">
                        <FormatAlignLeftIcon fontSize="small" />
                      </ToggleButton>
                      <ToggleButton value="center" title="center">
                        <FormatAlignCenterIcon fontSize="small" />
                      </ToggleButton>
                      <ToggleButton value="right" title="right">
                        <FormatAlignRightIcon fontSize="small" />
                      </ToggleButton>
                      <ToggleButton value="justify" title="justify">
                        <FormatAlignJustifyIcon fontSize="small" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}  alignItems="center">
                  <Grid item >
                      <Typography variant="caption" >Style</Typography>
                  </Grid>
                  <Grid item>
                    <ToggleButtonGroup size="small" value={formats} onChange={handleFormat} aria-label="text formatting">
                      <ToggleButton className={classes.tg_button} fontSize="small" value="bold" aria-label="bold">
                        <FormatBoldIcon />
                      </ToggleButton>
                      <ToggleButton className={classes.tg_button} fontSize="small" value="italic" aria-label="italic">
                        <FormatItalicIcon />
                      </ToggleButton>
                      <ToggleButton className={classes.tg_button} fontSize="small" value="underline" aria-label="underline">
                        <FormatUnderlinedIcon />
                      </ToggleButton>
                    {/* <ToggleButton fontSize="small" value="color" aria-label="color">
                      <FormatColorFillIcon />
                      <ArrowDropDownIcon />
                    </ToggleButton> */}
                  </ToggleButtonGroup>
                  </Grid>
                </Grid>

                
                                                
                <ColorPicker
                  label="Custom color"
                  variant="outlined"
                  name="color"                                   
                  value={color}
                  onChange={color => handleChangeColor(color)}
                  size="small"
                  margin="dense"
                />
                <Typography display='block' variant='caption' gutterBottom style={{ color: color }}>{color}</Typography>
                
                <ColorPicker
                  label="Custom bg color"
                  variant="outlined"
                  name="backgroundColor"
                  value={backgroundColor}
                  onChange={color => handleChangeBackgroundColor(color)}
                  size="small"
                  margin="dense"
                />
                <Typography display='block' variant='caption' gutterBottom style={{ color: backgroundColor }}>{backgroundColor}</Typography> 
                  
            </AccordionDetails>
          </Accordion>
          
          {/* Layout tool box */}
          <Accordion square>
            <AccordionSummary
              className={classes.acrd_summary}
              expandIcon={<ExpandMoreIcon fontSize="small" />}
              aria-controls="layout-content"
              id="layout-header"
            >
              <Typography className={classes.heading}>Layout</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

            
              
              

              {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="typographyAlignLB">Align</InputLabel>
                  <Select
                          label="Align"                        
                          size="small"                        
                          margin="dense"
                          fullWidth                        
                          id="typographyAlign"
                          labelId="typographyAlignLB"
                          value={component.props.align ? component.props.align: TypographyAligns[0] }
                          onChange={handleChangeTypographyAlign}
                          >
                          { TypographyAligns.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                        </Select>
              </FormControl> */}

              <TextField
                variant="outlined"
                margin="dense"
                label="Padding" 
                size="small"
                value={padding}                   
                fullWidth
                onChange={handleChangePadding}
              />
              <TextField 
                label="Margin"
                variant="outlined"
                margin="dense"
                size="small"
                value={margin}                   
                fullWidth
                onChange={handleChangeMargin}
              />

              <FormControlLabel
                className={classes.formControl}
                control={
                  <Checkbox
                          size="small"
                          checked={component.props.gutterBottom ? component.props.gutterBottom: false}
                          onChange={handleChangeTypographyGutterBottom}
                          color='default'
                          name="typographyGutterBottom"            
                      /> }
                label="Gutter bottom"
              />
              

            </AccordionDetails>
          
          </Accordion>                      
          <Box p={2}>
            <Button 
              fullWidth 
              size="small" 
              variant="outlined" 
              color='primary' 
              onClick={handleUpdate} startIcon={<CheckIcon />}
              >
                Applay
            </Button> 
          </Box>
        </Box>
        }
    </div>
  )
}

TypographyEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default React.memo(TypographyEditor);