
import React, { useState, useContext, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import { AppContext, AppConsumer } from "../../providers/appProvider";

import {Grid, Paper, Typography, CircularProgress, Chip, Avatar} from "@material-ui/core";
import clsx from "clsx";

// import Button from "@material-ui/core/Button";
// import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(3, 2),
        borderRadius: theme.spacing(1),        
        boxShadow: theme.palette.ambilight.boxshadow
    },
    header: {
        // fontWeight: 300,
    },
    customer: {
        textTransform: "capitalize",
        // fontWeight: 300,
    },
    
    balance: {
        position: "relative",
        
    },
    
    bal_conner_img: {
        right: 0,
        bottom: 0,
        height: "90%",
        position: "absolute"
    },

    bal_img: {
        color: theme.palette.primary.light,
        fontSize: theme.spacing(9),
        right: 8,
        [theme.breakpoints.up("sm")]: {
            fontSize: theme.spacing(11),
            right: 16,
        },
        
        // bottom: 10,
        top: 8,        
        position: "absolute",
        filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))",
        
    },

    small: {                                
        fontSize: "0.6rem !important"
      },
  })
);

export const Balance = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const auth = useContext(AppContext);
    // eslint-disable-next-line no-unused-vars
    const [token, setToken] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    
    // const [recharged, setRecharged] = useState({
    //     amount: 0,
    //     customerName: ""
    //   });

    // const test = () => {
    //     props.history.push({  pathname: '/recharge_success_consult', recharged});
    // };

    const loadData = (token) => {
        const URL =  "/api/v1.0/balance/dashboard";        
        fetch(URL, {
                method: "GET",
                headers: {"Authorization": token }
            }
        )
        .then(response => response.json())
            .then(data => {
                setData(data);
                setLoading(false);                
                // console.log(data);
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
        });
        // .finally( () => setLoading(false)); 
    };

    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    token =  `Bearer ${user.access_token}`;
                    loadData(token);                                                    
                }
                setToken(token);                
            });
        }        
        fetchUser();                
    }, [auth]); 
      

    return (
        <AppConsumer>
        {() => (
            <div className={classes.root}>                
                    {data &&
                    <>  
                        {loading && 
                        <>
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} style={{paddingTop: "24px"}}>
                                <Grid item>
                                    <CircularProgress size={40} />
                                </Grid>                    
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
                                <Grid item>
                                    <Typography color="textSecondary" variant="h6" align="center" gutterBottom>
                                        {t("common.Loading")}...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                        }
                                                                    

                        <Paper className={clsx(classes.paper)} style={{marginBottom: 16}}>
                            <Typography variant="h4" component="h1" className={classes.header}>
                                {t("reports.dashboard.Consultant_Current_Balance")}
                            </Typography>
                            <Typography variant="h6" component="h2" color="textSecondary" className={classes.customer} >
                                <small>{data.CustomerRegNumber}</small>, {data.CustomerName}
                            </Typography>
                        </Paper>
                        
                        <Grid 
                            container 
                            direction="row" 
                            spacing={2}  
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >                                                                                        
                            { data.Rows.map((source) => (
                            <Grid item xs={12} sm={4} key={source.SourceId}>
                                <Paper className={clsx(classes.paper, classes.balance)} style={{height: "100%"}} >
                                    
                                    <Typography variant="h6">
                                        {t(source.SourceName)}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2" style={{marginBottom: 16}}>
                                        <small>{t("Percentage of write-off when paying for orders")}</small> <b>{source.Percent}%</b>
                                    </Typography>

                                    
                                    <Grid container direction="row" spacing={2}>
                                        {source.CustomerBalances.map((balance) => (
                                            <Grid item key={balance.CurrencyId}>
                                                <Chip                                                    
                                                    label={<Typography variant="subtitle2">
                                                                { (balance.Amount !== 0.00 ? balance.Amount: balance.AmountOB).toFixed(2) }
                                                            </Typography> }                                                    
                                                    variant="outlined"
                                                    color="primary"
                                                    avatar={<Avatar className={classes.small}>{balance.CurrencyName}</Avatar>}
                                                />
                                                                                                                                                                                                                            
                                            </Grid>
                                        ) )}
                                    </Grid>
                                    <span className={clsx("material-icons", classes.bal_img)}>
                                        {source.Icon}
                                    </span>
                                    <img className={classes.bal_conner_img} alt="triangle background" src="/assets/images/triangle-light.png" />
                                </Paper>                                                                                
                            </Grid>
                            ))
                            }                                                                                                
                        </Grid> 
                        
                    </>
                    }                
            </div>
        )}
        </AppConsumer>
        
    )
}
