import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Paper, Typography, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { bindActionCreators } from "redux";
import { actionPromotionItemCreators } from "../../store/Promotions";
import { withTranslation } from "react-i18next";


const styles = theme => ({
    appBar: {
        position: "relative",        
        // backgroundColor: theme.palette.appbar.background,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    title: {
        flexGrow: 1,
    },
    paper:{
        padding: theme.spacing(1),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    dialogcontent:{
        padding: theme.spacing(2),
        margin: theme.spacing(1),
    },
});
  

class Promotion extends Component {
    constructor(props) {
        super(props); 
        // console.log('Module',`../Promotions/${this.props.promotion.ModuleName}.jsx`);
        this.state = {
            module: React.lazy(() => import(`../Promotions/${this.props.promotion.ModuleName}.jsx`)),
        };        
        // this.promotionRef = React.createRef();
    }

    componentDidMount() {        
        // console.log("Module:", this.props.promotion.ModuleName);
        // import(`../Promotions/${this.props.promotion.ModuleName}.jsx`)
        //         .then(module => this.setState({ module: module.default }));
    }

    handleClickDlgClose = () => {
        this.props.actions.showDialog(false);
    };
  
    handleClickSavePromotion = ()  => {
        // this.promotionRef.current.Save();        
        this.child.Save();
    };

    render() {
        const { classes, promotion, t } = this.props;
        const { module: Component } = this.state;
        return (
            <>                        
                <AppBar className={classes.appBar} color='inherit' elevation={0}>
                    <Toolbar>
                        <IconButton                                                        
                            onClick={this.handleClickSavePromotion}
                            title={t("buttons.Save")}
                        >
                            <SaveIcon />                            
                        </IconButton>   
                                            
                        <Typography variant="h6" className={classes.title}></Typography>

                        <IconButton
                            color="inherit"
                            onClick={this.handleClickDlgClose}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
               
                { Component && 
                    <Component childRef={ref => (this.child = ref)} promotion={promotion}></Component>
                }               
            </>
    );}
}

export default withTranslation()(withStyles(styles)(
    connect(        
        state => state.promotionItemsRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionPromotionItemCreators ), dispatch)
        }}        
    )(Promotion)
));