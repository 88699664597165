import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { BrowserRouter } from "react-router-dom";
import { AppContext, AppConsumer } from '../../providers/appProvider';
import { createStyles, makeStyles, } from "@material-ui/core/styles";
import clsx from "clsx";
import uuidv4 from "uuid/v4";
import ReactTestRenderer from "react-test-renderer";
import { Typography, IconButton, Drawer, Container, Paper, Button, Divider, Box } from '@material-ui/core';

import BigSlider from "../Slider/BigSlider";
import MiddleSlider from "../Slider/MiddleSlider";
import SmallSlider from "../Slider/SmallSlider";

import MenuIcon from '@material-ui/icons/Menu';
import CodeIcon from '@material-ui/icons/Code';
import ClearIcon from '@material-ui/icons/Clear';
import HorizontalSplitOutlinedIcon from '@material-ui/icons/HorizontalSplitOutlined';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            flexGrow: 1,
            position: "relative",
        },
        drawer: {
            position: "relative",
            marginLeft: "auto",
            width: 200,
            "& .MuiBackdrop-root": {
                display: "none"
            },
            "& .MuiDrawer-paper": {
                // marginTop: 4,
                paddingTop: theme.spacing(1),
                background: '#fff',
                width: 200,
                position: "absolute",
                height: (props) => props.height,
                transition: "none !important"
            },
            "& .MuiDrawer-paper *": {
                color: theme.palette.text.primary,
            }
        },
        button: {
            margin: 2,
        },
        code: {
            whiteSpace: 'normal'            
        },
        wrap: {            
            position: "relative",
            width: "auto",
            marginBottom: 4,
            padding: 2,
            border: "1px dashed #ff000000",
            borderRadius: 2
        },
        borderRed: {
            border: "1px dashed blue"
        },
        label: {
            position: 'absolute',
            top: -1,
            left: -1,
            fontSize: ".75em",
            padding: "0 4px",
            color: "#fff",
            backgroundColor: "blue",
            borderRadius: 3
        }
    })
);

const FILE_BIGSLIDER = "hp-big-slider";    
const FILE_MIDDLESLIDER = "hp-middle-slider";
const FILE_BOTTOMSLIDER = "hp-small-slider";

export const Collaboration = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { title } = props;
    const auth = useContext(AppContext);
    
    // eslint-disable-next-line no-unused-vars
    const [token, setToken] = useState(null);
    const [open, setOpen] = useState(false);
    const containerRef = useRef();    
    const [json, setJson] = useState('');
    const [height, setHeight] = useState(0);
    const [showBorder, setShowBorder] = useState(true);

    const [bigSlider, setBigSlider] = useState(null);
    const [middleSlider, setMiddleSlider] = useState(null);
    const [smallSlider, setSmallSlider] = useState(null);


    const classes = useStyles({ height: height });

    const KeysToComponentMap = {
        h1: Typography,
        h2: Typography,
        h3: Typography,
        h4: Typography,
        h5: Typography,
        h6: Typography,
        button: Button,
        a: "a",
        box: Box,
        span: "span",
        div: "div",
        p: "p"
    };
    
    const loadSlider = async (token, name ) => { 
        const URL = `/api/v1.0/slider/load/${name}/true`;

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(slider => {
                    

                    if(name === FILE_BIGSLIDER){
                        setBigSlider(slider);
                    }
                    
                    if(name === FILE_MIDDLESLIDER){
                        setMiddleSlider(slider);
                    }

                    if(name === FILE_BOTTOMSLIDER)
                        setSmallSlider(slider);
                    
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    useEffect(() => {
        if (open) {
            setHeight(containerRef.current.clientHeight - 64);
        } else {
            setHeight(0);
        }
    }, [open]);

    const handleFilterIconClick = () => {
        setOpen(!open);
    };

    const handleAddBigSlider = () => {
        if(bigSlider){
            console.log('BigSlider', bigSlider);

            componentArray.push(<BigSlider slider = {bigSlider} cname="BigSlider" />); 
            setcomponentArray([...componentArray]);
        }
    };

    const handleAddMiddleSlider = () => {
        if(middleSlider){
            componentArray.push(<MiddleSlider cname='MiddleSlider' slider={middleSlider} />); 
            setcomponentArray([...componentArray]);
        }
    };

    const handleAddSmallSlider = () => {
        if(smallSlider){
            componentArray.push(<SmallSlider cname='SmallSlider' slider={smallSlider} />); 
            setcomponentArray([...componentArray]);
        }
    };

    const handleAddTextBlock = () => {
        const components = [...componentArray];
        components.push(<Typography key={uuidv4()} variant='body1' display='block' align='center' gutterBottom cname='Typography' >{`New string # ${components.length+1}`}</Typography>);
        setcomponentArray(components);
    };

    const handleAddPaper = () => {
        const components = [...componentArray];
        components.push(
            <Paper key={uuidv4()} cname='Paper' >
                <Typography variant='h4' display='block' align='center' gutterBottom cname='Typography'>
                    {`New paper # ${components.length+1}`}
                </Typography>
                
            </Paper>);
        setcomponentArray(components);
    };
    
    const handleAddDiv = () => {
        const components = [...componentArray];
        // eslint-disable-next-line react/no-unknown-property
        components.push(<div key={uuidv4()} cname='div'></div>);
        setcomponentArray(components);
    };

    const handleClear =() => {
        setcomponentArray([]);
    };

    const handleToJson = () => {        
        const renderer = ReactTestRenderer.create(
            <BrowserRouter>{componentArray}</BrowserRouter>
            // componentArray
            // <>
            // {componentArray.map((item, idx) => (
            //         React.createElement(item.object.type, item.object.props)                    
            //     ))}
            // </>
        );
                
        const r = renderer.toJSON();
        console.log('Components', componentArray);        
        console.log('Objects', r);

        setJson(JSON.stringify(r));                        
        // setJson(JSON.stringify(componentArray, getCircularReplacer()));
    };

    const renderer = (config) => {

        if(typeof config === "string")
            return React.createElement('span', {key: uuidv4()},  config);

        if (typeof KeysToComponentMap[config.type] !== "undefined") {

          return React.createElement(
            KeysToComponentMap[config.type],
            {...config.props, key: uuidv4()},
            config.children &&
                            (typeof config.children === "string"
                                ? config.children
                                : config.children.map(child => renderer(child)))
          );
        }
    };

    const handlePopulate = () => {
        if(json === '') return;

        const arr = JSON.parse(json);
        const components = [];

        if(!arr) return;
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i];
            const component = renderer(el);
            components.push(component);
        }
        setcomponentArray(components);
    };

    // const getCircularReplacer = () => {
    //     const seen = new WeakSet();
    //     return (key, value) => {
    //       if (typeof value === "object" && value !== null) {
    //         if (seen.has(value)) {
    //           return;
    //         }
    //         seen.add(value);
    //       }
    //       return value;
    //     };
    // };

    
    const selectComponent = (idx) => {
        if(componentArray.length === 0) return null;
        return componentArray[idx];
    };

    const handleSelectComponet = (idx) => {
        const c = selectComponent(idx); 
        console.log(c);
        if(c){
            // const props = {...c.props};
            // props.children ="Click here!!!";
            // const clone = React.cloneElement(
            //     c,
            //     props,
            // );
            // componentArray[idx] = clone;

            // // clone.key = uuidv4();
            // console.log(clone);
            // // componentArray.push(clone);            
            // setcomponentArray([...componentArray]);
            
            console.log(`Click by ${getLabel(c)}`);
        }        
    };

    const getLabel = (item) => {
         if(item.props !== "undefined" && item.props.cname)
             return item.props.cname;

        // if(item.type !== "undefined" && item.type.displayName)
        //     return item.type.displayName;

        // if(item.type !== "undefined"){
        //     if(item.type.options !== "undefined" )
        //         if(item.type.options.name !== "undefined" )
        //             return item.type.options.name;   
        // }
        return "";
    }

    const [componentArray, setcomponentArray] = useState([
        <div key={uuidv4()}></div>
        // <Typography variant='h1' key={uuidv4()} display='block' align='center' style={{padding: 4}} cname='Typography'>Hello world</Typography>,
        // <Box key={uuidv4()} cname='Box'>
        //     <Button key={uuidv4()} className={classes.button} variant="outlined" cname='Button' >Test1</Button>
        //     <Button key={uuidv4()} className={classes.button} variant="outlined" cname='Button' >Test2</Button>
        // </Box>
    ]);
    
    const toolsArray = [
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddBigSlider}>Big slider</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddMiddleSlider}>Middle slider</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddSmallSlider}>Small slider</Button></Typography>,

        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddTextBlock}>Typography</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddDiv}>Div</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth onClick={handleAddPaper}>Paper</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom><Button size="small" variant="outlined" fullWidth>Columns</Button></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom></Typography>,
        <Typography component="div" key={uuidv4()} display='block' align='center' gutterBottom></Typography>
    ];

    
    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    // console.log(user);
                    token = `Bearer ${user.access_token}`;
                }
                
                setToken(token);

                // loadHtmlBlock(token, 'topline', HtmlBlockType.TopLine);
                // loadHtmlBlock(token, 'home_page_middle_block', HtmlBlockType.HomePageMiddleBlock);
                // loadHtmlBlock(token, 'home_page_bottom_block', HtmlBlockType.HomePageBottomBlock);
                
                loadSlider(token, FILE_BIGSLIDER);
                loadSlider(token, FILE_MIDDLESLIDER);
                loadSlider(token, FILE_BOTTOMSLIDER);
            });
        }
        fetchUser();
    }, [auth]);

    return (
        <AppConsumer>
        {() => (
        <div ref={containerRef} className={classes.root}>
            <Paper style={{ display: "flex", width: '100%', marginBottom: 4 }}>
                <IconButton
                    style={{ marginLeft: "auto" }}
                    color="inherit"
                    aria-label="filterButton"
                    onClick={handleFilterIconClick}
                >
                    <MenuIcon />
                </IconButton>
            </Paper>

            <Drawer
                open={open}
                className={classes.drawer}
                variant="persistent"
                anchor="right"
            >
                <Container>                    
                    <div style={{marginBottom: 16}}>
                        {toolsArray}
                    </div> 
                    < Divider />
                    
                    <div style={{marginTop: 16}}>                        
                        <Button className={classes.button} size='small' variant="outlined" fullWidth startIcon={<CodeIcon />} onClick={handleToJson}>Json</Button><br />
                        <Button className={classes.button} size='small' variant="outlined" fullWidth startIcon={<ClearIcon />} onClick={handleClear }>Clear</Button><br />
                        <Button className={classes.button} size='small' variant="outlined" disabled={json === ''} fullWidth startIcon={<HorizontalSplitOutlinedIcon />} onClick={handlePopulate}>Populate</Button><br />
                        <div>
                            <IconButton title={!showBorder ? "Show boder": "Hide border"} className={classes.button} onClick={() => setShowBorder(!showBorder)}>{!showBorder ? <BorderOuterIcon /> : <BorderClearIcon />}</IconButton>
                        </div>
                    </div>                                       
                </Container>
            </Drawer>
            
            <Paper style={{ margin: '16px 0', padding: 16 }}>                
                {componentArray.map((item, idx) => (
                    <div key={idx} className={clsx(classes.wrap, showBorder ? classes.borderRed : "")} onClick={() => handleSelectComponet(idx)} >                        
                        {item}
                        {showBorder && <span className={classes.label}>{getLabel(item)}</span>}
                    </div>                    
                    ))}
                {/* {componentArray.map(item => item)} */}
            </Paper>
            
            <Paper style={{ margin: '16px 0', padding: 16 }}>
                <code className={classes.code}>
                    {json}
                </code>
            </Paper>

        </div>
        )}
        </AppConsumer>
    )
}

Collaboration.propTypes = {
    title: PropTypes.string
}