// eslint-disable-next-line no-unused-vars
import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { withStyles, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useMediaQuery from '@material-ui/core/useMediaQuery';
// eslint-disable-next-line no-unused-vars

import { AppContext, AppConsumer } from "../../providers/appProvider";
// eslint-disable-next-line no-unused-vars
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import Alert from "@material-ui/lab/Alert";

import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "../CustomDialog";
import { List, ListItem, ListItemIcon, ListItemText, Collapse, 
         Button, IconButton, FormControl, InputAdornment, Badge, Typography,
         OutlinedInput, InputLabel, TextareaAutosize, Snackbar, Grid, Divider
         } from "@material-ui/core";

import {grey} from "@material-ui/core/colors";

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { FixedSizeList } from 'react-window';
import Messages from "./Messages";
import Invites from "./Invites";

import { UserAvatar } from "../UserAvatar";

const drawerWidth = 325;
// eslint-disable-next-line no-undef


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // marginTop: theme.spacing(1),
            // paddingTop: theme.spacing(2),
            flexGrow: 1,
            background: "#f7f9fc",
        },        
        title: {
            fontWeight: 400,
            marginLeft: theme.spacing(1),
            textTransform: "capitalize",
            flex: 1,
        },
        paper: {
            margin: theme.spacing(1),
            padding: theme.spacing(1)
        },
        dialogPaper: {       
            position: 'absolute',
            minHeight: 450,
            // marginBlockEnd: 'env(keyboard-inset-height, 100px)',
            
            marginBlockEnd: 0,
            [theme.breakpoints.up('sm')]: {
                marginBlockEnd: 16,
                margin: 16,
                right: 0,
                bottom: 0,
            },
        },
        message: {
            fontSize: '1em',
            fontWeight: 400,
            fontFamily: "Roboto, Helvetica, Arial, sans-seri",
            lineHeight: "1.1876em",
            letterSpacing: "0.00938em",
            width: "100%",
            padding: 8,
            resize: "none",
            borderRadius: 4,
            border: "1px solid #e0e0e0;",
            backgroundColor: "rgba(236, 236, 236, 0.33)"
        },
        messages: {
            display: "flex",
            flexDirection: "column",            
            height: "100%",
            justifyContent: "flex-end",
            overflow: "hidden",
            padding: "0px 8px",
            width: "100%",
            backgroundColor: 'rgba(190, 190, 190, 0.1)'
        },
        hide: {
            display: 'none',
        },        
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        nested: {
            // paddingLeft: theme.spacing(3),
            paddingTop: 0,
            paddingBottom: 0,
            textTransform: "capitalize"
        },
        listItemIcon: {
            // minWidth: 40,
        },
        search: {
            position: 'relative',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: grey[400],
            borderRadius: 4,
            margin: theme.spacing(2,0),
            padding: theme.spacing(1),                        
            // [theme.breakpoints.up('sm')]: {
                
            // },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',            
        },
        inputInput: {
            padding: theme.spacing(2, 2, 2, 1),            
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
        },
        avatar: {            
            // backgroundColor: "rgba(236, 236, 236, 0.2)",
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        badgeOnline: {
            bottom: "16%",
            right: "16%",
            height: 12,
            width: 12,
            borderRadius: 6,
            border : "2px solid #fff",
            background: '#00ff00',
            // boxShadow: "rgb(250 250 250 / 24%) 0px 0px 1px 1px, rgb(250 250 250 / 24%) 0px 16px 32px -4px"
        },
        badgeOffline: {
            background: '#9e9e9e'
        },
    })
);

const MessageType = {
    Message: 0,
    Invite: 1,
    Spam: 2
};

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -15,
      top: 12,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}))(Badge);

const TIMERNOTVIEWED = 15;
const INVITEID = 2147483647;

export const Chat = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const auth = useContext(AppContext);
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const { onClose, selectedValue, open, adviser } = props;

    const [token, setToken] = useState();
    const [connection, setConnection] = useState(null);
    
    const [userId, setUserId] = useState("");
    const [userFrom, setUserFrom] = useState("");
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [receiveMessage, setReceiveMessage] = useState(null);
    const [systemMessType, setSystemMessType] = useState('');
    const [joined, setJoined] = useState(null);

    const [invites, setInvites] = useState([]);
    const [inviteTxt, setInviteTxt] = useState('Вітаю');
    const [notViewed, setNotViewed] = useState(0);

    const [contacts, setContacts] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [opened, setOpened] = useState([0]);
    
    const [searchContact, setSearchContact] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    
    // eslint-disable-next-line no-unused-vars
    const [findInviteContact, setInviteContact] = useState(null);
    const [showSnackBar, setShowSnackBar] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [snackBarTxt, setSnackBarTxt] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const [found, setFound] = useState(false);
    
    //const [showInvite, setShowInvite] = useState(true);
    
    // eslint-disable-next-line no-unused-vars
    const [messagesHeight, setMessagesHeight] = useState(340);

    const [counter, setCounter] = useState(0);


    const updeteNotViewed = (name) => {
        if (contacts.length > 0){
            const list = [...contacts];
            
            for (let i = 0; i < list.length; i++) {
                const grp = list[i];
                const contact = grp.Childs.find(item => (item.Name === name));
                if(contact){
                    contact.NotViewed.Count = 0;
                    setContacts(list);
                    break;
                }                
            }
        }
    }

    const clearViewed = (clearContact) => {
        if (!token) return;
        console.log("Clear not viewed", clearContact);
        const url = `/api/v1.0/Contact/ClearViewed/${clearContact.Name}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        
        fetch(url, { method: "GET", headers: headers })
            .then(response => response.text())
                .then(data => {                
                    console.log('clearViewed', data);
                    fetchNotViewedqty(token);
                    updeteNotViewed(clearContact.Name);
                });
    };

    const CloseSnackBar = () => {
        setShowSnackBar(false);
    }

    // Timer clear viewed    
    useEffect(() => {
        if(!selectedContact) return;
        
        // if(counter !== 0 )
        //     return () => clearInterval(timer);

        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        
        if(counter === 0 && selectedContact && selectedContact.NotViewed && selectedContact.NotViewed.Count > 0){  
            clearViewed(selectedContact);            
        }
        
        return () => clearInterval(timer);
    });

    const handleSearch = (e) => {
        setUserId(e.target.value);
    };

    const fetchContacts = useCallback(async (tkn) => {
        if (!tkn) return;

        const url = `/api/v1.0/contact/list`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${tkn}`);

        fetch(url, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    // console.log('CONTACTS', data);
                    setContacts(data);
                }
            });
    }, []);

    const fetchNotViewedqty = useCallback(async (tkn) => {        
        const url = '/api/v1.0/Contact/GetNotViewed';
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${tkn}`);
        
        fetch(url, { method: "GET", headers: headers })
            .then(response => response.text())
            .then(data => {
                if (data) {                    
                    setNotViewed(parseInt(data));
                }
            });
    }, []);

    const fetchMessages = useCallback(async (tkn) => {
        if (!selectedContact) return;

        const url = `/api/v1.0/Contact/ChatMessages/${selectedContact.Name}/ /1/2147483647`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${tkn}`);
        
        fetch(url, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data) {                    
                    setMessages(data.Data);
                }
            });
    }, [selectedContact]);

    const fetchInvites = useCallback(async (tkn) => {
        const url = '/api/v1.0/Contact/invites/1/2147483647';
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${tkn}`);                        

        fetch(url, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(data => {
                if(data) {
                    console.log('Invites', data);
                    setInvites(data.Data);                    
                }
            });
    }, []);

    const fetchContact  = useCallback(async (regNumber) => {
        if (!token) return;
        
        const url = `/api/v1.0/contact/get/${regNumber}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { method: "GET", headers: headers })
            .then((response) => {
                if(response.status === 200){
                    response.json().then((contact) => {
                        // console.log('fetchContact', contact);
                        setSearchContact(contact);
                        setFound(true);                        
                    });
                }else if(response.status === 404){
                    setFound(false);
                    setSearchContact(null);                    
                }
          })
          .catch(error => {                
                console.log("Error", error);
        });

        
    }, [token]);

    useEffect(() => {
        if (!token) return;
        
        const filter = [];
        
        if (userId.length > 0 && userId.length < 7 && contacts.length > 0){                        
            for (let i = 0; i < contacts.length; i++) {
                const grp = contacts[i];
                filter.push(...grp.Childs.filter(item => (item.Name.includes(userId) || item.FullName.includes(userId.toLowerCase()))));
            }
        }

        setFiltered(filter);
        setSearchContact(null);

        if(userId.length >= 7 && filter.length === 0 && !isNaN(parseInt(userId))) {
            fetchContact(userId);
        }            

    }, [token, userId, contacts, fetchContact]);
    
    useEffect(() => {
        if(!receiveMessage) return;

        if(selectedContact && selectedContact.Name === receiveMessage.From){
            messages.push(receiveMessage);
            setMessages([...messages]);
            setReceiveMessage(null);
        }
        
        if(!selectedContact || selectedContact.Name !== receiveMessage.From ){
            setSnackBarTxt(`From user: ${receiveMessage.From}. ${receiveMessage.Body}`);
            setShowSnackBar(true);
            setReceiveMessage(null);
        }
    }, [receiveMessage, selectedContact, messages]);

    useEffect(() => {
        if(!joined) return;
        
        if (contacts.length > 0){
            const list = [...contacts];
            
            for (let i = 0; i < list.length; i++) {
                const grp = list[i];
                const contact = grp.Childs.find(item => (item.Name === joined));
                if(contact){
                    contact.Online = systemMessType === 'joined';
                    setContacts(list);
                    break;
                }                
            }
        }                    
        setJoined(null);                        
    }, [joined,systemMessType, contacts]);

    // Recive messagess from hub
    useEffect(() => {
        if(connection){
            if(connection._connectionStarted) return;
            // console.log('Start', Date.now());
            connection.start()
            .then(() => {                
                connection.on("ReceiveMessage", (_user, _message) => {                    
                    // receiveMessage(_user, _message); 
                    setReceiveMessage(JSON.parse(_message));
                    console.log("ReceiveMessage", _user, _message);
                });

                connection.on("ReceiveSystemMessage", (_user, _message) => {
                    setSystemMessType(_message);
                    setJoined(_user);
                    // console.log("ReceiveSystemMessage", _user, _message);
                });
            })
            .catch((error) => console.log(error));
        }
        
    }, [connection]);

    // Get messages by selected contact
    useEffect( () => {
        if (!token) return;
        fetchMessages(token);
    }, [token, fetchMessages]);
    
    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {                
                if (user != null) {                    
                    const token = user.access_token;

                    const connect = new HubConnectionBuilder()
                                    .withUrl("/chatHub", { accessTokenFactory: () => `Bearer ${token}` })
                                        .withAutomaticReconnect()                                        
                                            .build();
                    
                    setUserFrom(user.profile.name);
                    setConnection(connect);
                    setToken(token);
                    fetchContacts(token);
                    fetchInvites(token);
                    fetchNotViewedqty(token);
                }
            });
        }

        fetchUser();            
    }, [auth, fetchContacts, fetchInvites, fetchNotViewedqty]);

    const sendMessage = async (messageType) => {
        if (connection) {
            try {
                let txt = '';                 
                let remoteMethod = "SendMessageToUser";
                let contact = null;
                // let messageType = MessageType.Message;
                switch (messageType) {
                    case MessageType.Message:
                        contact = selectedContact;
                        txt = message;
                        remoteMethod = "SendMessageToUser";
                        break;
                    case MessageType.Invite:
                            contact = searchContact;
                            txt = inviteTxt;
                            remoteMethod = "InviteUser";
                            break;
                    default:
                        contact = selectedContact;
                        txt = message;
                        remoteMethod = "SendMessageToUser";
                        break;
                }
                
                if (txt === '') return;
                                
                const _mess = { 
                    Id: 0, 
                    From: userFrom, 
                    To: contact.Name, 
                    MessageType: messageType,
                    Body: txt.replace(/(<? *script)/gi, "illegalscript") 
                };

                await connection.invoke(remoteMethod, _mess.To, _mess.Body);

                messages.push(_mess);
                setMessages([...messages]);
                setMessage("");
            } catch (err) {
                console.error(err);
            }

        }
    };

    const handleClose = () => {
        if(connection){
            connection.stop().then(() => {
                console.log('Chat server has disconnected');
            });
        }
        onClose(selectedValue);
    };

    const handleExpand = (id) => {
        const idx = opened.indexOf(id);
        console.info('id:', id)
        if (idx === -1) {
            opened.push(id);
        } else {
            opened.splice(idx, 1);
        }
        setOpened([...opened]);
    }

    const handleSelectContact = (contact) => {
        console.log(contact);
        setSelectedContact(contact);
        
        if(contact.NotViewed && contact.NotViewed.Count > 0)
            setCounter(TIMERNOTVIEWED);
    };

    const handleInvite = () => {
        sendMessage(MessageType.Invite);
        setFound(false);
    };

    const handleCloseInvite = () => {
        setUserId('');
        setFound(false);
    };
    
    const RowContact = (props) => {
        const { index, style } = props;
        const contact = props.data[index];
        return (
            <ListItem className={classes.nested} button style={style} key={index} onClick={() => handleSelectContact(contact)} >
                <ListItemIcon className={classes.listItemIcon}>
                    {contact.Online &&                     
                    <Badge 
                        classes={{badge: classes.badgeOnline }} 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        overlap="circular" 
                        color="secondary" badgeContent=" " variant="dot">
                        <UserAvatar adviser={contact} />
                    </Badge>
                    }

                    {!contact.Online && 
                        <UserAvatar adviser={contact} />
                    }
                </ListItemIcon>
                <ListItemText
                    primary={                         
                        <StyledBadge overlap="rectangular" badgeContent={contact.NotViewed ? contact.NotViewed.Count: 0} color="secondary">
                            {contact.FullName}
                        </StyledBadge>                        
                    }
                    secondary={<Typography display='block' style={{width: "100%"}} variant="caption" >{contact.Name}</Typography> }
                />
            </ListItem>
        );
    };

    const acceptInvite = (idx) => {
        if (!token) return;

        const url = `/api/v1.0/contact/accept/${invites[idx].Id}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { method: "POST", headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    // console.log('accept invite', data );
                    invites.splice(idx,1);
                    setInvites([...invites]);                    
                }
            });
    };
  
    const declineInvite = (idx) => {
        if (!token) return;

        const url = `/api/v1.0/contact/decline/${invites[idx].Id}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { method: "DELETE", headers: headers })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    // console.log('delete invite', data);                    
                    invites.splice(idx,1);
                    setInvites([...invites]);                    
                }
            });
    };
  
    const blockInvite = (idx) => {
        alert(invites[idx].Body);
        invites.splice(idx,1);        
        setInvites([...invites]);
    };
    
    return (
        <AppConsumer>
            {() => (
                <>
                <Dialog onClose={handleClose} aria-labelledby="dlg-chat" classes={{ paper : classes.dialogPaper}}
                    open={open} fullWidth={true} maxWidth={"sm"} fullScreen={fullScreen} scroll="paper">

                    <DialogTitle onClose={handleClose}>
                        {adviser &&
                            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                                {selectedContact && 
                                <Grid item>
                                    <IconButton onClick={() => setSelectedContact(null)}>
                                        <ArrowBackIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                }
                                <Grid item>
                                    { selectedContact ? <UserAvatar adviser={selectedContact} /> 
                                                         : <UserAvatar adviser={adviser} /> 
                                    }
                                </Grid>
                                <Grid item>

                                { selectedContact ? 
                                    <Typography className={classes.title}>
                                        <StyledBadge overlap="rectangular" badgeContent={notViewed } color="secondary">
                                            {selectedContact.FullName.toLowerCase()}
                                        </StyledBadge>
                                        <Typography variant="caption" style={{ display: 'block', width: "100%"}}>
                                            {selectedContact.Name}
                                        </Typography>
                                    </Typography>
                                    : <Typography className={classes.title}>
                                        <StyledBadge overlap="rectangular" badgeContent={notViewed } color="secondary">
                                            {(`${adviser.LastName} ${adviser.FirstName}`).toLowerCase()}
                                        </StyledBadge>
                                        <Typography variant="caption" style={{ display: 'block', width: "100%"}}>
                                            {adviser.Code}
                                        </Typography>
                                    </Typography>
                                }
                                </Grid>
                            </Grid>
                        }
                    </DialogTitle>
                        
                    <DialogContent >
                    { !selectedContact && 
                    <div>    
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="search_contact">{t("chat.SearchContact")}</InputLabel>
                            <OutlinedInput
                                id="search_contact"
                                value={userId}
                                onChange={(input) => { handleSearch(input); }}
                                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                endAdornment={  <InputAdornment position="end" >
                                        <IconButton size="small" aria-label="clear search" onClick={handleCloseInvite} >
                                        <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                labelWidth={120}
                            />
                        </FormControl>

                        {found && 
                        <>
                            {searchContact ?
                                <div className={classes.search} >                                
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item>
                                            <UserAvatar adviser={searchContact} />                                        
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.title} display='block'> 
                                                {searchContact.FullName.toLowerCase()}
                                                <Typography variant="caption" display='block'>
                                                    {searchContact.Name}
                                                </Typography>
                                            </Typography>                                            
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextareaAutosize 
                                                id="invive_user"
                                                className={classes.message}
                                                aria-label="minimum height" minRows={2} placeholder={t("chat.NewMessage")}
                                                value={inviteTxt}
                                                onChange={(e) => { setInviteTxt(e.target.value); }} />
                                        </Grid>                                
                                    </Grid>
                                    <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center" style={{marginTop: 8}} >
                                        <Grid item >
                                            <Button variant="outlined" color="secondary" startIcon={<PersonAddIcon />} onClick={handleInvite}>
                                                Invite user
                                            </Button>
                                        </Grid>                                                                                
                                    </Grid>
                                </div>
                                : !found &&
                                <Typography variant="body2" display="block" align="center" style={{paddingTop: 16}}>
                                    <span className="material-icons"> sentiment_very_dissatisfied </span><br />
                                    Not found
                                </Typography>
                                
                            }                                
                        </>
                        }
                       
                        {userId === "" &&
                        <List component="nav" className={classes.list} disablePadding>
                            {invites.length > 0 && 
                            <>
                                <ListItem button onClick={() => handleExpand(INVITEID)}>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <GroupAddOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={<StyledBadge overlap="rectangular" color="secondary" badgeContent={invites.length}>
                                            {t("chat.Invites")}
                                        </StyledBadge>}                                      
                                    />
                                        {opened.indexOf(INVITEID) > -1 ? <ExpandMore /> : <ChevronRight />}
                                </ListItem>
                                <Divider />
                                <Collapse in={opened.indexOf(INVITEID) > -1} unmountOnExit>                                     
                                    <div  style={{ height: invites.length < 6 ? invites.length * 52 : 400 }}>
                                        <Invites messages={invites} onAccept={acceptInvite} onDecline={declineInvite} onBlock={blockInvite} />
                                    </div>                                    
                                </Collapse>
                            </>
                            }
                            
                            {contacts.map((grp, key) => (
                                <React.Fragment key={key}>
                                    <ListItem button onClick={() => handleExpand(grp.Id)}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <GroupOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={grp.FullName} />
                                        {opened.indexOf(grp.Id) > -1 ? <ExpandMore /> : <ChevronRight />}
                                    </ListItem>
                                    <Divider />
                                    <Collapse in={opened.indexOf(grp.Id) > -1} unmountOnExit>                                         
                                        <FixedSizeList                                             
                                            height={grp.Childs.length === 0 ? 0 : 400} 
                                            width="100%" itemSize={50} itemCount={grp.Childs.filter(item => item.FullName.includes(userId)).length} itemData={grp.Childs.filter(item => item.FullName.includes(userId))}>
                                            {RowContact}
                                        </FixedSizeList>
                                    </Collapse>
                                </React.Fragment>
                            ))}

                        </List>                        
                        }

                        {filtered.length > 0 &&
                            <List component="nav" className={classes.list} style={{marginTop: 16}} disablePadding>
                                <FixedSizeList
                                    height={400}
                                    width="100%" itemSize={50} itemCount={filtered.length} itemData={filtered}>
                                    {RowContact}
                                </FixedSizeList>
                            </List>
                        }
                    </div>
                    }

                    {selectedContact && 
                        <div className={classes.messages} style={{minHeight: messagesHeight}} >
                            <Messages messages={messages} contact={selectedContact} /> 
                        </div>
                    }
                                        
                    </DialogContent>
                    <DialogActions >
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={10} sm={11} >
                                <TextareaAutosize className={classes.message}
                                    aria-label="minimum height" minRows={2} placeholder={t("chat.NewMessage")}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }} />
                            </Grid>
                            <Grid item xs={2} sm={1} >
                                <IconButton aria-label="send" onClick={()=>sendMessage(MessageType.Message)}>
                                    <SendIcon />
                                </IconButton>                                
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={CloseSnackBar}
                    open={showSnackBar} autoHideDuration={10000} >
                    <Alert variant="filled" severity="success" onClose={CloseSnackBar} >
                        {snackBarTxt}
                    </Alert>
                </Snackbar>
                
                </>
            )}
        </AppConsumer>
    )
}

Chat.propTypes = {
    adviser: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
