import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paper, Chip, Grid, Box, Typography, Button } from "@material-ui/core";
import { useWidth} from '../../helpers/utils';
import { grey } from "@material-ui/core/colors";
import clsx from 'clsx';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(4),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    box: {
        textAlign: 'center',
        padding: theme.spacing(0, 2)
    },
    borderLeft: {
        borderLeft: 'dotted',
        borderColor: grey[300],
        [theme.breakpoints.down("sm")]: {
            borderLeft: 'none',
        },
    },
    button:{
        minWidth: theme.spacing(17),
        [theme.breakpoints.down("sm")]: {
            minWidth: theme.spacing(11),
        },
        marginRight: theme.spacing(2)
    },
    code401: {
        fontSize: '8em',
        [theme.breakpoints.down("sm")]: {
            fontSize: '6em',
        },
        color: grey[800],
        fontWeight: 100
    },
    text401: {
        color: grey[400],
        textTransform: 'uppercase',
    },
    img401: {
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '80%',
        },
    },
    secondText: {
        marginBottom: theme.spacing(2)        
    }
  })
);

export const Page401 = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(); 

  return (
    <div className={classes.root}>                    
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
            <Grid item xs={12} sm={8} md={6}>
                <Paper className={classes.paper}>
                    <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={4}
                    >
                    <Grid item xs={12} sm='auto'>
                        <Typography className={classes.code401} variant='h1' display='block' align='center'>401</Typography>
                        <Typography className={classes.text401} variant='h6' display='block' align='center'>unauthorized user</Typography>                
                    </Grid>
                    <Grid item xs={12} sm={true}>
                        <Box className={clsx(classes.box, classes.borderLeft)}>
                            <div>
                                <img className={classes.img401} src="./assets/images/401.svg" alt="empty" />
                            </div>
                            
                            <Typography className={classes.secondText} gutterBottom>
                                {t("The page is accessible only to the authorized user")}
                            </Typography>
                            
                            <Chip
                                className={classes.button}
                                icon={<PersonOutlineOutlinedIcon />}
                                label={t("appbar.menu.Login")}
                                clickable
                                color="primary"
                                component={Link} to="/signin"
                            />
                            <Chip
                                className={classes.button}
                                icon={<PersonAddOutlinedIcon />}
                                label={t("appbar.menu.Register")}
                                clickable
                                color="primary"
                                component={Link} to="/register"   
                            />
                        </Box>                
                    </Grid>
                    </Grid>                                                              
                    </Paper>
            </Grid>
        </Grid>                                    
    </div>
  )
}

Page401.propTypes = {
    callbackUrl: PropTypes.string
}

export default Page401;
