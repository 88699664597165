/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useCallback } from 'react';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {        
            marginTop: 1,            
            flexGrow: 1,
            padding: theme.spacing(0,2),
        },
        paper: {
            minHeight: 130,
            padding: theme.spacing(1) 
        },
        img: {
            height: theme.spacing(4),
        },
        txt2: {
            fontWeight: 400,
            fontSize: ".7em"
        }
        
    })
);


export const GeneralStaff = () => {
    const classes = useStyles();
    const [generalStaff, setGeneralStaff] = useState(null);
    const fetchData = useCallback( async () => {
        const url = '/api/v1.0/GeneralStaff';
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        
        fetch(url, { method: "GET", headers: headers })      
                .then(response => response.json())
                    .then(data => {                          
                        if(data){
                            console.log('GeneralStaff',data);
                            setGeneralStaff(data);
                        }                    
                    });

    }, []);

    useEffect( () => {
        fetchData();
    }, [fetchData]);
    

    return (
        <div className={classes.root}>
            { generalStaff && <> 
                <Grid spacing={1}  container direction="row" justify="center" alignItems="center">
                    <Grid item>
                    <a target="_blank" rel="noopener noreferrer" href={generalStaff.GeneralStaffLink} alt="Генеральний штаб ЗСУ">
                            <img src="https://jerelia.com/assets/files/images/GeneralStaff/GeneralStaff.jpg" 
                                alt="Генеральний штаб ЗСУ" 
                                style= {{width: 40 }} data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="," data-align="none" data-percentage="auto,auto" data-file-name="GeneralStaff.jpg" data-file-size="0" data-origin="," origin-size="480,480" data-index="0" /> 
                    </a>
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" component="h1" >
                        {generalStaff.Title}
                    </Typography>
                    </Grid>
                </Grid>                            
            
            <Typography display="block" align='center' variant='body2' gutterBottom>
                {generalStaff.SubTitle}
            </Typography>
            
            <Grid spacing={1}  container direction="row" >
                {generalStaff.Items.map( (item, idx) => (
                    <Grid key={idx} item xs={6} sm={3} md={2}>
                        <Paper className={classes.paper}>
                            <Typography component="div" display='block' align='center' gutterBottom>
                                <img alt={item.Img} className={classes.img} src={item.Img} />
                            </Typography>
                            <Typography display='block' align='center' variant='h6' color="error">
                                {item.Value1}
                            </Typography>
                            <Typography className={classes.txt2}  display='block' align='center' variant='button'>
                                {item.Label1}
                            </Typography>                            
                        </Paper>                               
                    </Grid>

                ))}                
            </Grid>

            <Typography variant='body2' style={{ paddingTop: 32}}>
                Дані уточнюються. Підрахунок ускладнюється високою інтенсивністю бойових дій.
            </Typography>
            <Typography variant='body2'>
                Довіряйте тільки перевіреним джерелам, не піддавайтесь паніці та продовжуйте тотальний спротив.<br />
                Разом переможемо!
            </Typography>
            <Typography variant='h6'>
                Слава Україні!!!
            </Typography>
            <p>
                <a target="_blank" rel="noopener noreferrer" href={generalStaff.GeneralStaffLink} alt={generalStaff.GeneralStaff}>
                    {generalStaff.GeneralStaff}
                </a>
            </p>
            </>
        }
        </div>
    )
}