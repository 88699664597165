import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import CatalogItemSmall from "../Catalog/CatalogItemSmall";
import Button from "@material-ui/core/Button";
import DoneAllIcon from "@material-ui/icons/DoneAll";


const styles = theme => ({
    dialogTitle:{
        margin: 0,
        padding: theme.spacing(2),
    },  
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (        
      <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  

class DlgBuyProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {    
        };
        this.handleBuySelectd = this.handleBuySelectd.bind(this);
        this.handleChangeQty = this.handleChangeQty.bind(this);
    }

    handleChangeQty = (idx, value) =>{
        console.log(idx, value);
    }

    callBackFn = (id) => {
        if(id > 0){    
            this.props.history.push(`/order/${id}`);
        }
    };

    handleBuySelectd = () => {
        const items = [];
        for (let i = 0; i < this.props.items.length; i++) {
            const item = this.props.items[i];
            if(item.Qty > 0){
                items.push({ pos: item, qty: item.Qty, orderrowtype: 0 })
            }   
        }        
        if(items.length > 0){
            this.props.actions.addToOrder(items, this.callBackFn);
        }        
    };

    // componentDidMount() {      
    //     this.setState({open: this.props.open, title: this.props.title});
    //   };
  
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.open !== this.state.open || prevState.title !== this.state.title ) {   
    //         this.setState({open: prevProps.open, title: prevProps.title });
    //     }
    // };
    
    // static getDerivedStateFromProps(nextProps, prevState){    
    //     return { open: nextProps.open, title: nextProps.title};        
    // }

    render() {
        const {open, classes, title, items, t, width } = this.props;
        
        return (
            <div className={classes.root}>            
            <Dialog
              fullScreen={width === "xs"}
              fullWidth={true}
              maxWidth={"lg"}
              open={open}
              onClose={this.props.onClose}
              TransitionComponent={Transition}              
            > 
              <DialogTitle id="buy-products" onClose={this.props.onClose}>   
                <div>{title}</div>                
              </DialogTitle>
              <DialogContent dividers={true}>
                <Grid spacing={2} container direction="row" >                  
                {
                    items.map((item, idx) => ( 
                        <Grid item key={idx} xs={12} sm={6} md={4} lg={4} xl={3} >
                            <CatalogItemSmall item={item} idx={idx} changeQty={this.handleChangeQty} ></CatalogItemSmall>
                        </Grid>
                    ))
                }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={this.handleBuySelectd} color="primary" variant="outlined">
                    <DoneAllIcon /> { t("buttons.AddSelected") }
                </Button>
                <Button onClick={this.props.onClose} color="default">
                { t("buttons.Cancel") }
                </Button>
            </DialogActions>
            </Dialog>
            </div>
        );
    }
}

export default withTranslation()(withWidth()(withRouter(withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(DlgBuyProducts)
))));