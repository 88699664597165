
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
// eslint-disable-next-line no-unused-vars
import {InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {extractFileExt, IsImage, IsVideo } from '../../helpers/utils';
import CheckIcon from '@material-ui/icons/Check';

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },    
    button: {
      marginRight: theme.spacing(1)
    },
    img: {
      maxWidth: "100%"
    },
    mediaVideo: {
      minHeight: "auto!important",
      height: "56.25vw!important",
      maxHeight: "calc(100vh - 138px - 10vh)!important"      
    },
}));

const MediaEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [src, setSrc] = useState(node.props && node.props.src ? node.props.src: '');
  const [fileExt, setFileExt] = useState(extractFileExt(node.props && node.props.src ? node.props.src: ''));
  const handleUpdate = () => {
    if(onUpdate){
      component.props.src = src;
      onUpdate(component);
    }
  };

  const handleChangeSrc = (e) => {
    setSrc(e.target.value);
    setFileExt(extractFileExt(e.target.value));
  };

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={clsx("jr-media", classes.root)} >
        {component && 
            <>           
            <Grid container spacing={1} style={{marginTop: 8}}>                                          
              {typeof component.props.src && 
                <Grid item xs={12}>
                  <TextField 
                      label="Media image or video"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="Media"
                      size="small"
                      aria-label="slider-media" placeholder="Image or video"
                      value={src}
                      onChange={handleChangeSrc}
                      />
                </Grid>
              }              
              <Grid item xs={12}>
                  <Button 
                    fullWidth 
                    size="small" 
                    variant="outlined" 
                    color='primary' 
                    onClick={handleUpdate} startIcon={<CheckIcon />}>Applay</Button>
              </Grid>              
            </Grid>
            
            <Grid container spacing={1} style={{ martinTop: 16 }} >
                <Grid item xs={12}>
                  <Typography display="block" align="center" variant="caption" gutterBottom>Preview {fileExt}</Typography>
                  
                  {src !== '' &&  IsImage(fileExt) &&
                    <img className={classes.img} src={`${host}${src}`} alt='preview' />
                  }
                  {src !== '' &&  IsVideo(fileExt) &&
                    <div className={classes.mediaVideo}>
                      <video style={{ width: "100%"}} muted playsInline>
                        <source
                          src={`${host}${src}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  }
                </Grid>
            </Grid>                                  
            </>
        }
    </div>
  )
}

MediaEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClick: () => null
}

export default MediaEditor