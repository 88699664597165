import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";
import { EntityState, Order, OrderRow, OrderRowDetail, OrderRowType, PromotionType } from "../definitions/order";

const startRequestType = "START_REQUEST";
const langRequestType = "LANG_REQUEST";

const requestNewOrderType = "REQUEST_NEW_ORDER";
const reciveNewOrderType = "RECIVE_NEW_ORDER";
const confirmOrderType = "CONFIRM_CORDER";
const errorconfirmOrderType = "ERROR_CONFIRM_CORDER";

const addRowToOrder = "ADD_ROW_TO_ORDER";
const saveOrder = "SAVE_ORDER";
const changeOrderField = "CHANGE_ORDER_FIELD";
const delRowFromOrder = "DEL_ROW_FROM_ORDER";
const updRow = "UPD_ROW";
const getSuggestionsProducts = "GET_SUGGESTIONS_PRODUCTS";
const clearSuggestionsProducts = "CLEAR_SUGGESTIONS_PRODUCTS";
const hideSnakBarFromOrder = "HIDE_SNACKBAR_ORDER";
const showSnakBarFromOrder = "SHOW_SNACKBAR_ORDER";

const requestRecommendedType = "REQUEST_RECOMMENDED";
const requestPromotionsType = "REQUEST_PROMOTIONS";
const requestChestType = "REQUEST_CHEST";


const requestScriptType = "REQUEST_SCRIPTS";
const getAppSettings = "GET_APPSETTINGS";
const beginOrderChg = "BEGIN_ORDER_CHANGE";

const requestCatalogItemType = "REQUEST_CATALOG_ITEM";
const reciveCatalogItemType = "RECIVE_CATALOG_ITEM";
const reciveRelatedType = "RECIVE_RELATED";
const reciveBySeriesType = "RECIVE_BYSERIES";
const undefinedOrder = "UNDEFINED_ORDER";

const requestCountriesType = "REQUEST_COUNTRIES";
const reciveCountriesType = "RECIVE_COUNTRIES";
const requestDeliveriesType = "REQUEST_DELIVERIES";
const reciveDeliveriesType = "RECIVE_DELIVERIES";


// eslint-disable-next-line no-undef
const AllowOrder = process.env.REACT_APP_ALLOW_ORDER;

const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const InternationalCommon = "9BE1C76C-BFE9-11EC-8104-000C29E6B2CD";

const NewOrder = () => {
  return new Order({
    ExtOrderId: emptyGuid,
    DocNumber: "",
    AppSsid: "",
    Email: "",
    ExtUserId: "",
    Amount: 0.0,
    ApplayDiscountAmount: 0.0,
    DiscountAmount: 0.0,
    AmountOrder: 0.0,
    CreatedOn: new Date(),
    UpdatedOn: new Date(),
    Id: 0,
    Rows: [],
    UseRepayment: false,
    UseBusinessPack: false,    
    ShowedRecomProducts: false,
    State: EntityState.Added,
    UsePaymentOB: false,
    AmountOB: 0.0,
    AmountCOD: 0.0,
    AmountDelivery: 0.0,
    DeliveryProviderId: emptyGuid,
    PaymentTypeId: emptyGuid,
    RefId: "",
    Country: "",
    RecipientFirstName: "",    
    RecipientLastName: "",        
    RecipientMiddleName: "",        
    RecipientPhone: "",        
    RecipientMail: "",
    RecipientAddress: "",
    CurrencyId: "",
    CurrencyName: "",
    CurrencySign: "",
  });
};

const accessBusiness = (user) => {  
  if(user){  
    //console.log("accessBusiness", user);
    if(user.profile == null || user.profile.role == null){
      return false;
    }
      const administrator = "Administrator";
      const consultant = "Consultant";
      const authorizedUser = "AuthorizedUser";
      
      const isAdministrator = user.profile.role.includes(administrator);
      const isConsultant = user.profile.role.includes(consultant);
      const isauthorizedUser = user.profile.role.includes(authorizedUser);      
      return isAdministrator || isConsultant || isauthorizedUser;
    }      
    
    return false;
}

const accessUsePaymentOB = (user) => {  
  if(user){  
    return user.profile != null;
      // const administrator = "Administrator";
      // const consultant = "Consultant";
      // const customer= "Customer";
      
      // const isAdministrator = user.profile.role.includes(administrator);
      // const isConsultant = user.profile.role.includes(consultant);
      // console.log("isAdministrator", isAdministrator, "isConsultant", isConsultant);      
      // return isAdministrator || isConsultant;
    }      
    
    return false;
}

const initialStorageState =  () => {  
    return  {
      Order: NewOrder(),      
      allowOrder: AllowOrder === "true",
      reqOrderId: "",
      lang: 'uk',
      // country: '',
      qtyrows: 0,
      isLoading: false,
      snackbarTxt: "",
      snackbarShow: false,
      suggestions: { CatalogItems: [] },
      promotions: [],
      lastTimeGetPromotions: new Date(),
      chests: [],
      recommended: [],
      scripts: [],      
      deliveries: [],
      selectedDelivery: null,
      variant: "success",
      redirect: false,
      user: null,
      accessBusinessAttr: false,
      accessUsePaymentOBAttr: false,
      disabled: false,
      code: "",
      product: null,
      related: [],
      productsBySeries: [],
      countries: []
    };
}

const initialState = initialStorageState();

const SelectedDeliveryById = (deliveries, id ) => {  
  if(!deliveries){
    return null;
  }

  for (let i = 0; i < deliveries.length; i++) {
    const delivery = deliveries[i];
    if(delivery.Id === id){
      return delivery;          
    }
  }    
  return null;
}

const SelectedPaymentTypeById = (paymenttypes, id ) => {  
  if(!paymenttypes){
    return null;
  }

  for (let i = 0; i < paymenttypes.length; i++) {
    const payment = paymenttypes[i];
    if(payment && payment.Id === id){
      return payment;
    }
  }
  
  return null;
}

async function Save(order, token) {  
  const url = "/api/v1/order/save";
  
    const responseSaveOrder = await fetch(url, {
        method: 'POST',
        headers: { 
            authorization: token, 
            "Accept": "application/json", 
            "Content-Type": "application/json;charset=UTF-8" 
         },
        body: JSON.stringify(order)
    });

    const saved = await responseSaveOrder.json();
    return saved;
}

const CreateOrderRowPromotion = (item) => {
  // console.log("Create Order Row Promotion", item);

  if(item.orderrowtype === OrderRowType.Default)
    return null;

  if(!item.pos.Source){
    return null;
  }

  const qty = parseInt(item.qty);
  const price = item.pos.Price;
  const rate = item.pos.Rate;

  const orderRowPromotion = {
    Id: 0,
    Qty: qty,
    Price: price,
    Rate: rate,    
    CatalogPrice: item.pos.CatalogPrice,
    CatalogPricePv: item.pos.CatalogPricePv,
    PersonalVolume: item.pos.UseLO ? qty * price : 0,
    PersonalVolumePv: item.pos.UseLO ? (qty * price) / rate : 0,
    AmountCdc: item.pos.DiscountForConsultant ? qty * price : 0,
    AmountCdcPv: item.pos.DiscountForConsultant ? (qty * price) / rate : 0,
    PromotionsId: item.pos.Source.Id,
    PromotionsName: item.pos.Source.Name,
    PromotionsDescr: item.pos.Source.Descr,
    DiscountForConsultant: item.pos.DiscountForConsultant,
    UseLO: item.pos.UseLO,
    State: EntityState.Added,
  };
  
  // console.log("CreateOrderRowPromotion orderRowPromotion", orderRowPromotion);
  return orderRowPromotion;
};

const GetChestById = (id, chests) => {
  if(chests){
    for (let i = 0; i < chests.length; i++) {
      const chest = chests[i];
      if(chest.Id === id){
        return chest;
      }
    }
  }
  return null;
}

const ValidateChest = (rows, item, chest) => {  
    
    // console.log("CHEST", chest);
    // console.log("ITEM", item);      
  
    const ApplyToQty = chest.ActionByCondition.ApplyToQty;
    const ApplyToPos = chest.ActionByCondition.ApplyToPos;
    let result = false;
    
    let pos = 0;
    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];
      let qty = 0;
      
        for (let d = 0; d < row.OrderRowDetails.length; d++) {
          const detail = row.OrderRowDetails[d];
          for (let p = 0; p < detail.OrderRowPromotions.length; p++) {
            const promo = detail.OrderRowPromotions[p];            
            if(promo.PromotionsId === chest.Id){
              pos++;
              console.log(`pos=${pos}; ApplyToPos=${ApplyToPos}`)
              if(pos + 1 > ApplyToPos){
                result = true;
                break;
                // return true;
              }

              qty += promo.Qty;              
              
              if(qty + item.qty > ApplyToQty){
                result = true;
                break;
                // return true;
              }
            }
          }          
        }
      
    }  
  return result;
}

const PrepareOrderRows = function (order, items) {
  let txt = "";
  // The loop on added items
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    let exists = false;
    txt += item.pos.LocalName + "; \n";
    // console.log("Add to order item", item);
    
    const qty = parseInt(item.qty);    
    const { Price, CatalogPrice, CatalogPricePv, Rate } = item.pos;
    
    order.Amount += parseFloat(qty * Price);
    order.AmountCatalogPrice += parseFloat(qty * CatalogPrice);

    // Level 0. The loop on exists order items
    for (let r = 0; r < order.Rows.length; r++) {
      const row = order.Rows[r];      
      if (row.ProductId === item.pos.ProductId) {                        
        
        row.State = EntityState.Modified;
        row.Qty += qty;      
        row.Amount += parseFloat(qty * Price);
        row.AmountPv +=  parseFloat(qty * Price / Rate);
        row.Price = parseFloat(row.Amount / row.Qty);
  
        let existsDet = false;       
        // Level 1. The loop on exists order item details
        for (let d = 0; d < row.OrderRowDetails.length; d++) {          
          const detail = row.OrderRowDetails[d];                    
          if (detail.OrderRowType === item.orderrowtype) {
            detail.State = EntityState.Modified;            
            detail.Qty += qty;
            detail.Amount +=  parseFloat(qty * Price);
            detail.AmountPv +=  parseFloat(qty * Price / Rate);            
            // detail.AmountCOD = detail.Amount;
            // detail.AmountCODPv = detail.AmountPv;
            detail.Price = detail.Amount / detail.Qty;

            // Level 2. The loop on details of promotions            
            let existsPromo = false;            
            if(detail.OrderRowPromotions){
              for(let p = 0; p < detail.OrderRowPromotions.length; p++){
                const promo = detail.OrderRowPromotions[p];
                if(!promo){
                  continue;
                }
                if(!item.pos.Source){
                  continue;
                }

                if(promo.PromotionsId === item.pos.Source.Id){
                  promo.Qty += item.qty;
                  existsPromo = true;
                }               
              }
            }

            if(!existsPromo){              
              if(item.pos.Source){
                const promoDetail = CreateOrderRowPromotion(item);
                if(promoDetail)
                  detail.OrderRowPromotions.push(promoDetail);                                              
              }              
            }
            existsDet = true;
            break;
          }
        }

        if (!existsDet) {
          const rowPromo = CreateOrderRowPromotion(item);
          row.OrderRowDetails.push(
            new OrderRowDetail({
              Id: 0,
              OrderRowType: item.orderrowtype,
              Qty: qty,
              Price: Price,
              Rate: Rate,        
              CatalogPrice: CatalogPrice,
              CatalogPricePv: CatalogPricePv,
              Amount: qty * Price,
              AmountPv: qty * Price / Rate,   
              AmountCdc: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price).toFixed(2) : 0),
              AmountCdcPv: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price / Rate).toFixed(2) : 0),
              State: EntityState.Added,
              OrderRowPromotions: rowPromo ? [rowPromo] : [],
              OutsideCatalog: item.pos.OutsideCatalog,
              UseLO: item.pos.UseLO
            })
          );
        }        
        exists = true;        
        break;
      }
    }

    // add if not exists product in order
    if (!exists) {

      const orderRow = new OrderRow({
        Id: 0,
        Price: Price,
        Rate: Rate,
        Amount: parseFloat(Price * qty),
        AmountPv:  parseFloat(Price * qty / Rate),
        CatalogPrice: CatalogPrice,
        CatalogPricePv: CatalogPricePv,        
        PriceCdc: 0,
        PriceCdcPv: 0,
        Discount: 0,
        DiscountPv: 0,        
        FastUpBonus: 0,
        PersonalVolume: 0,
        AnonymousDiscount: 0,
        DeferredDiscount: 0,
        PromoDiscount: 0,
        Qty: qty,
        ProductId: item.pos.ProductId,
        Code: item.pos.Code,
        Name: item.pos.Name,
        CatalogId: "",
        LocalName: item.pos.LocalName,
        Image: item.pos.Image,
        OrderId: order.Id,
        CreatedOn: new Date(),
        UpdatedOn: new Date(),
        State: 4,
        OutsideCatalog: item.pos.OutsideCatalog,
        UseLO: item.pos.UseLO,
        // CurrencyId: item.CurrencyId,
        // CurrencyName: item.CurrencyName,
        // CurrencySign: item.CurrencySign,
        OrderRowDetails: [
          new OrderRowDetail({
            Id: 0,
            OrderRowType: item.orderrowtype,
            Qty: qty,
            Price: Price,
            Rate: Rate,        
            CatalogPrice: CatalogPrice,
            CatalogPricePv: CatalogPricePv,
            Amount: qty * Price,
            AmountPv: qty * Price / Rate,
            AmountCdc: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price) : 0),
            AmountCdcPv: ( item.pos.UseLO && !item.pos.OutsideCatalog ? parseFloat(qty * Price / Rate) : 0),
            State: EntityState.Added,            
            OutsideCatalog: item.pos.OutsideCatalog,
            UseLO: item.pos.UseLO
          }),
        ],
      });

      if(item.pos.Source){
        const rowPromo = CreateOrderRowPromotion(item);
        if(rowPromo){
          orderRow.OrderRowDetails[0].OrderRowPromotions.push(rowPromo);
        }        
      }
            
      if(order.Rows.length === 0){
        order.CurrencyId = item.pos.CurrencyId;
        order.CurrencyName = item.pos.CurrencyName;
        order.CurrencySign = item.pos.CurrencySign;
      }

      order.Rows.push(orderRow);
    }

    if (order.Id > 0) {
      order.State = EntityState.Modified;
    }
  }

  return txt;
};

const getAmountDelivery = function (delivery, method, order) {
    console.log('getAmountDelivery');
    if(!delivery.DeliveryCosts)
        return parseFloat(order.Rate * method.CostDelivery / method.Rate);

    if(delivery.DeliveryCosts && delivery.DeliveryCosts.length > 0){        
        const amount = delivery.UseBB ? 
                        order.PersonalVolumePv : 
                        Math.round( 100 * (order.Amount - (!order.UseBusinessPack ? order.DiscountCdc : 0))) / 100;

        const deliveryCosts = delivery.DeliveryCosts.find(c => ( amount <= c.OrderAmount ));

        
        if(deliveryCosts){            
            return parseFloat(order.Rate * deliveryCosts.DeliveryAmount / method.Rate);
        }else{
            const maxOrderAmount = Math.max(...delivery.DeliveryCosts.map(c => c.OrderAmount));            
            const maxDeliveryCosts = delivery.DeliveryCosts.find(c => ( maxOrderAmount === c.OrderAmount ));
            return parseFloat(order.Rate * maxDeliveryCosts.DeliveryAmount / method.Rate);            
        }
    }
               
    return parseFloat(order.Rate * method.CostDelivery / method.Rate);;
  };

export const actionOrderCreators = {
    
    getAppSettings: () => async (dispatch, getState) => {
        const appLayout = getState().appLayout;
        const appSettings = appLayout.AppSettings;    
        dispatch({ type: getAppSettings, appSettings, user: appLayout.user });
    },

    undefinedOrder: () => async (dispatch, getState) => {    
        dispatch({ type: undefinedOrder });
    },

    getShippingCountries: (country) => async (dispatch, getState) => {        
        dispatch({ type: requestCountriesType});
        const state = getState();      
        
        const appLayout = state.appLayout;
        const user = appLayout.user;

        if(!user){
            console.log('ONLY authenticated users!!!')
            return;
        }
                
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 

        const urlShippingCountries = `api/v1/deliveryprovider/getshippingcountries/${country}`;
        
        const responseCountries = await fetch(urlShippingCountries, {headers: {Authorization: token}});
        const countries = await responseCountries.json();
        dispatch({ type: reciveCountriesType, countries: countries });

    },

    getDeliveries: (country) => async (dispatch, getState) => {        
        dispatch({ type: requestDeliveriesType});
        const state = getState();      
        
        const appLayout = state.appLayout;
        const user = appLayout.user;

        if(!user){
            console.log('ONLY authenticated users!!!')
            return;
        }
                
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
        
        const urlDelivery = `api/v1/deliveryprovider/providers/${country}`;
        const responseDelivery = await fetch(urlDelivery, {headers: {Authorization: token}});
        const deliveries = await responseDelivery.json();
        
        
        dispatch({ type: reciveDeliveriesType, deliveries: deliveries });

    },
    
    requestOrder: (id, lang) => async (dispatch, getState) => {

        let accessBusinessAttr = false;
        let accessUsePaymentOBAttr = false;         
        const orderId = !isNaN(id) && id ? id : "";
        
        const state = getState();      
        const req = state.orderRequest;
        const appLayout = state.appLayout;
        const user = appLayout.user;

        if(!user){
            console.log('ONLY authenticated users!!!')
            return;
        }
                
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
            
        if (id === req.reqOrderId && lang === req.lang) {
            // Don't issue a duplicate request (we already have or are loading the requested data)        
            return;
        }
                    
        dispatch({ type: requestNewOrderType, reqOrderId: orderId, lang: lang });
                        
        const urlOrder = `api/v1/order/get/${lang}/${orderId}`;
        // const urlDelivery = "api/v1/deliveryprovider/providers";
        
        const  date = new Date();    
        // console.info(`GET ORDER URL ${date.getTime()}`, urlOrder);

        const responseOrder = await fetch(urlOrder, {headers: {Authorization: token}});
        const order = await responseOrder.json();        

        let selectedDelivery = null;

        if(order ){
            // const filterd = deliveries.filter(d => d.Id === order.DeliveryProviderId);
            // if(filterd && filterd.length > 0){
            //     selectedDelivery = filterd[0];
            //     order.AmountService = selectedDelivery.AmountService;            
            //     const method = selectedDelivery.DeliveryMethods[0];
            //     order.AmountDelivery = method.CostDelivery;
            // }
            accessBusinessAttr = accessBusiness(user);
        }
        
        if (order) {
            dispatch({
                type: reciveNewOrderType,
                reqOrderId: orderId,
                order: order,
                qtyrows: order.Rows.length,
                Amount: order.Amount,
                user: user,
                accessBusinessAttr: accessBusinessAttr,
                accessUsePaymentOBAttr: accessUsePaymentOBAttr,                
                // selectedDelivery: selectedDelivery,
                lang: lang
              });
        }else {
            const newOrder = getState().orderRequest.Order;
            
            dispatch({
                type: reciveNewOrderType,
                reqOrderId: orderId,
                order: newOrder,
                qtyrows: 0,
                user: user,
                accessBusinessAttr: accessBusinessAttr,
                accessUsePaymentOBAttr: accessUsePaymentOBAttr,                
                // selectedDelivery: selectedDelivery,
                lang: lang
            });
        }
    },

    requestPromotions: (lang) => async (dispatch, getState) => {
        const state = getState();
        const orderRequest = state.orderRequest;
        // const appSettings = state.appLayout.AppSettings;

        if (orderRequest.promotions.length > 0 && orderRequest.lang === lang) {
            return;            
        } 

        dispatch({ type: langRequestType, lang: lang });
        
        const appLayout = state.appLayout;
        const user = appLayout.user;
        
        
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
                
        const url = `api/v1/promotions/getactivepromotions/${lang}`;
        
        const date = new Date();        
        // console.info(`GET ACTIVE PROMOTIONS URL ${date.getTime()}`, url);
        
        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    dispatch({ type: requestPromotionsType, promotions: data, lastTimeGetPromotions: date, lang: lang });
                })
            .catch((error) => {
                console.log('Error', error);
                dispatch({ type: requestPromotionsType, promotions: [], lastTimeGetPromotions: date });
            });                
    },
    
    requestChest: () => async (dispatch, getState) => {

        const state = getState();                
        const request = state.orderRequest;
                
        if (request.chests.length > 0) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        
        const appLayout = state.appLayout;
        const user = appLayout.user;        
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 

        const accessBusinessAttr = accessBusiness(user);
        if(!accessBusinessAttr){
            return;
        }
        
        const url = "api/v1/promotions/getactivechestpromotions";
        
        const date = new Date();
        // console.info(`GET ACTIVE CHEST URL ${date.getTime()}`, url);

        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
        .then((response) => response.json())
            .then((data) => {
                dispatch({ type: requestChestType, chests: data });
            })
        .catch((error) => {
            console.log('Error', error);
            
        });

        // await axios
        //     .get(url, { headers: { Authorization: token } })
        //     .then((response) => {
        //     // console.log("getactivechestpromotions", response.data);
        //     dispatch({ type: requestChestType, chests: response.data });
        // });
        
    },

    requestRecommended: () => async (dispatch, getState) => {
        
        const state = getState();       
        const request = state.orderRequest;

        
        if (request.recommended.length > 0) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        
        const appLayout = state.appLayout;
        const user = appLayout.user;        
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
        
        const url = "api/v1/CatalogItem/GetRecommended";
        
        const date = new Date();        
        // console.info(`GET RECOMENDED URL ${date.getTime()}`, url);
        
        fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
        .then((response) => response.json())
            .then((data) => {
                dispatch({ type: requestRecommendedType,  recommended: data.CatalogItems });
            })
        .catch((error) => {
            console.log('Error', error);
            
        });

    //     await axios
    //     .get(url, { headers: { Authorization: token } })
    //     .then((response) => {
    //       dispatch({
    //         type: requestRecommendedType,
    //         recommended: response.data.CatalogItems,
    //       });
    //   });
    
    },

    requestScripts: (id, callBack) => async (dispatch, getState) => {
        dispatch({ type: startRequestType });
        const state = getState();                
        
        const request = state.orderRequest;
    
        const appLayout = state.appLayout;
        const user = appLayout.user;        
        const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
            
        const url = `api/v1/promotions/getactivescriptsbyorder?id=${id}`;
        await axios
        .get(url, { headers: { Authorization: token } })
        .then((response) => {                    
        if(callBack){
            callBack(response.data.length > 0); 
        }
        // console.log("getactivescriptsbyorder", response.data);
        dispatch({ type: requestScriptType, scripts: response.data });          
        })
        .catch((error) => {
        dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
        });
        }); 
    },
                        
  handleChangeFieldValue: (e) => async (dispatch, getState) => {
    const state = getState();      
    const orderRequest = state.orderRequest;
    const appLayout = state.appLayout;
    const user = appLayout.user;
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 

    if(e.target.type === "checkbox") {      
      orderRequest.Order[e.target.name] = e.target.checked;
    }
    else {              
        orderRequest.Order[e.target.name] = e.target.value;

        if(e.target.name === "DeliveryProviderId"){
            const delivery = SelectedDeliveryById(orderRequest.deliveries, e.target.value);
            orderRequest.Order.PostOfficeId = "";
            orderRequest.Order.PostOffice = null;
            orderRequest.selectedDelivery = delivery;
            orderRequest.Order.RecipientCountry = delivery.Region;

            if(delivery.DeliveryMethods && delivery.DeliveryMethods.length > 0){
                const method = delivery.DeliveryMethods[0];
                if(method){                    
                    orderRequest.Order.AmountDelivery = getAmountDelivery(delivery, method, orderRequest.Order); //  parseFloat(orderRequest.Order.Rate * method.CostDelivery / method.Rate);
                    orderRequest.Order.DeliveryMethodId = method.Id;
                    orderRequest.Order.DeliveryMethodOrder = method.DeliveryMethodOrder;
                    orderRequest.Order.AmountService = parseFloat(orderRequest.Order.Rate * delivery.AmountService / method.Rate);  // delivery.AmountService;
                }
            }

            if(delivery.PaymentTypes && delivery.PaymentTypes.length > 0){
                const method = delivery.DeliveryMethods[0];
                const payment = delivery.PaymentTypes[0];          
                orderRequest.Order.PaymentTypeId = payment.Id;

                if(payment){
                    orderRequest.Order.AmountCOD =  parseFloat(orderRequest.Order.Rate * payment.AmountCOD / (method ? method.Rate: 1));  // payment.AmountCOD;
                }
                else{
                    orderRequest.Order.AmountCOD = 0;
                }
            }
                                    
            // Save(orderRequest.Order, token);
        }

        if(e.target.name === "PaymentTypeId"){
            const delivery = SelectedDeliveryById(orderRequest.deliveries, orderRequest.Order.DeliveryProviderId);
            if(delivery){
            const method = delivery.DeliveryMethods[0];
            const payment = SelectedPaymentTypeById(delivery.PaymentTypes, e.target.value);
            orderRequest.Order.PaymentTypeId = payment.Id;
            if(payment){
                const mR = method ? method.Rate: 1.00;
                const oR = orderRequest.Order.Rate;
                const pA = payment.AmountCOD;                      
                orderRequest.Order.AmountCOD = parseFloat(oR * pA / mR);   // payment.AmountCOD;
            }
            else{
                orderRequest.Order.AmountCOD = 0;
            }            
        }
            
        }      
    }
    
    dispatch({ type: changeOrderField, orderRequest: orderRequest });    
  },

  handleChangeRecipientCountry: (e, val) => (dispatch, getState) => {    
    const state = getState();     
    const appLayout = state.appLayout;
    const user = appLayout.user;
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 

    const orderRequest = state.orderRequest;
    orderRequest.Order.RecipientCountry = val ? val : orderRequest.Order.Country;
    orderRequest.Order.DeliveryProviderId = emptyGuid;
    orderRequest.selectedDelivery = null;
    orderRequest.Order.PostOfficeId = "";
    orderRequest.Order.PostOffice = null;
            
    const filterd = orderRequest
                        .deliveries
                            .filter(d => d.ReciverISO === orderRequest.Order.RecipientCountry 
                        );
    console.log('filterd', filterd);

    const deliveries = filterd && filterd.length > 0 ? 
                      filterd :
                      orderRequest.deliveries.filter(d => d.Id === InternationalCommon); 

    for (let i = 0; i < deliveries.length; i++) {
      const delivery = deliveries[i];
      if(delivery){
        
        orderRequest.selectedDelivery = delivery;
        orderRequest.Order.DeliveryProviderId = delivery.Id;

        if(delivery.DeliveryMethods && delivery.DeliveryMethods.length > 0){
          const method = delivery.DeliveryMethods[0];
          if(method){            
            orderRequest.Order.DeliveryMethodId = method.Id;
            orderRequest.Order.DeliveryMethodOrder = method.DeliveryMethodOrder;
            orderRequest.Order.AmountService =  parseFloat(orderRequest.Order.Rate * delivery.AmountService / method.Rate); // delivery.AmountService;
            orderRequest.Order.AmountDelivery = getAmountDelivery(delivery, method, orderRequest.Order); //parseFloat(orderRequest.Order.Rate * method.CostDelivery / method.Rate);

          }
        }
        
        if(delivery.PaymentTypes && delivery.PaymentTypes.length > 0){
          const method = delivery.DeliveryMethods[0];
          const payment = delivery.PaymentTypes[0];          
          orderRequest.Order.PaymentTypeId = payment.Id;

          if(payment ){
            orderRequest.Order.AmountCOD =  parseFloat(orderRequest.Order.Rate * payment.AmountCOD / (method ? method.Rate : 1));  // payment.AmountCOD;
          }
          else{
            orderRequest.Order.AmountCOD = 0;
          }
        }
      }
      break;
    }

    // Save(orderRequest.Order, token);

    dispatch({ type: changeOrderField, orderRequest: orderRequest });
  },

  selectPostOffice: (item) => async (dispatch, getState) => {
    const orderRequest = getState().orderRequest;
    if(item){
      orderRequest.Order.PostOfficeId = item.Id;
      orderRequest.Order.PostOffice = item;
    } else {
      orderRequest.Order.PostOfficeId = "";
      orderRequest.Order.PostOffice = null;
    }

    dispatch({ type: changeOrderField, orderRequest: orderRequest });
  },

  // Add array to order
  addToOrder: (items, callBackFn) => async (dispatch, getState) => {
    if (items === undefined) {
      return;
    }
        
    dispatch({ type: beginOrderChg });    
    
    const state = getState();    
    const orderRequest = state.orderRequest;    
    const order = orderRequest.Order;
    const chests = orderRequest.chests;
    const appLayout = state.appLayout;
    const user = appLayout.user;
    
    if(!user || !order){
        return;
    }

    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
    
    let accessBusinessAttr = false; 
    let accessUsePaymentOBAttr = false; 
            
    if (user) {           
        accessBusinessAttr = accessBusiness(user);
        accessUsePaymentOBAttr = accessUsePaymentOB(user);
    }

    if(items.length === 1){
      const item = items[0];
      if(item.orderrowtype === OrderRowType.Chest){
        const chest = GetChestById(item.pos.Source.Id, chests);
        if(chest){
          if(ValidateChest(order.Rows, item, chest)){
            dispatch({
              type: showSnakBarFromOrder,
              severity: "warning",
              variant: "filled",
              snackbarTxt: `Скриня уже використана! \r\n ${chest.Name}.`,
            });
            return;
          }
        }
      }
    }

    const txt = PrepareOrderRows(order, items, chests);
          
    order.accessBusinessAttr = accessBusinessAttr;
    order.accessUsePaymentOBAttr = accessUsePaymentOBAttr;
      
    const neworder = order.Id === 0;      
    const url = "/api/v1.0/order/save";

    const delivery = SelectedDeliveryById(orderRequest.deliveries, orderRequest.Order.DeliveryProviderId);
      
    axios.post(url, order, { headers: { Authorization: token } })
      .then((response) => {
          const _order = response.data;
          if(delivery){
            const method = delivery.DeliveryMethods[0];
            _order.AmountDelivery = getAmountDelivery(delivery, method, _order);
          }

          dispatch({ 
            type: addRowToOrder, 
            order: _order, 
            snackbarTxt: txt, 
            variant: 'filled',
            severity: 'success',
            duration: 3000,
            snackbarShow: true, 
            neworder: neworder, 
            accessBusinessAttr: accessBusinessAttr, 
            accessUsePaymentOBAttr: accessUsePaymentOBAttr 
        }); 
      

          if(callBackFn){
            if(order.Id === 0 && _order.Id > 0){
              callBackFn(_order.Id);
            }
          }

          if(_order.Id > 0){
            let date = new Date();
            const expDays = 21;
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = "orderId" + "=" + _order.Id + "; " + expires + "; path=/";             
          }

      })
        .catch((error) => {
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
          });
        });   
  },

  updRow: (ri, di, qty) => async (dispatch, getState) => {    
    const order = getState().orderRequest.Order;
    const row = order.Rows[ri];
    row.Qty = 0;
    for (let i = 0; i < row.OrderRowDetails.length; i++) {
      const detail = row.OrderRowDetails[i];
      if(i === di){
        detail.Qty = qty;  
      }
      row.Qty += detail.Qty;
    }

    dispatch({ type: updRow, order: {...order} }); 
  },

  updFromScripts: (sIndx, pIndx, qty) => async (dispatch, getState) => {        
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    // const products = scripts[sIndx].ActionByCondition.Products;
    const product = scripts[sIndx].ActionByCondition.Products[pIndx];    
    if(product){
      product.AllowQty = parseInt(qty);
    }
    // products.splice(pIndx, 1);
    // scripts[sIndx].ActionByCondition.Products = products;                
    dispatch({ type: requestScriptType, scripts: scripts });    
  },

  updToOrder: (ri, di, qty) => async (dispatch, getState) => {
    // dispatch({ type: requestNewOrderType });

    dispatch({ type: beginOrderChg });

    const state = getState();
    const orderRequest = state.orderRequest;    
    const order = orderRequest.Order;
    
    const appLayout = state.appLayout;
    const user = appLayout.user;

    const row = order.Rows[ri];
    row.Qty = 0;
    for (let i = 0; i < row.OrderRowDetails.length; i++) {
      const detail = row.OrderRowDetails[i];
      if(i === di){
        detail.Qty = qty;  
      }
      row.Qty += detail.Qty;
    }
    
    let txt = row.Name;
        
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
    let accessBusinessAttr = order.accessBusinessAttr;
    let accessUsePaymentOBAttr = order.accessUsePaymentOBAttr;

    if (user) {  
        accessBusinessAttr = accessBusiness(user);
        accessUsePaymentOBAttr = accessUsePaymentOB(user);
    }

    order.accessBusinessAttr = accessBusinessAttr;
    order.accessUsePaymentOBAttr = accessUsePaymentOBAttr;
    
    const url = "/api/v1.0/order/updrows";

    const delivery = SelectedDeliveryById(orderRequest.deliveries, orderRequest.Order.DeliveryProviderId);
    
    axios.post(url, [row], { headers: { Authorization: token } })
        .then((response) => {
            const order = response.data;
            if(delivery){
                const method = delivery.DeliveryMethods[0];
                order.AmountDelivery = getAmountDelivery(delivery, method, order);
            }
            
          dispatch({ 
            type: addRowToOrder, 
            order: order,
            snackbarTxt: txt, 
            snackbarShow: true,
            variant: 'filled',
            severity: 'success',
            duration: 3000,
            neworder: false, 
            accessBusinessAttr: accessBusinessAttr, 
            accessUsePaymentOBAttr: accessUsePaymentOBAttr });
        })
        .catch((error) => {          
          dispatch({
            type: showSnakBarFromOrder,
            variant: "error",
            snackbarTxt: `${error.response.data}`,
          });
        });
  },

  delFromOrder: (e, idx, msg) => async (dispatch, getState) => {    
    e.stopPropagation();
    dispatch({ type: beginOrderChg });

    const state = getState();
    const orderRequest = state.orderRequest;    
        
    const appLayout = state.appLayout;
    const user = appLayout.user;

    const id = orderRequest.Order.Rows[idx].Id;
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
              
    orderRequest.snackbarTxt =  msg;// "Deleted row from order";

    const delivery = SelectedDeliveryById(orderRequest.deliveries, orderRequest.Order.DeliveryProviderId);

    const url = `api/v1/order/delrow/${id}`;
    await axios
        .delete(url, { headers: { Authorization: token } })
            .then((response) => {              
              
              const _order = response.data;
              orderRequest.Order = _order;
              orderRequest.Amount = _order.Amount;
              orderRequest.qtyrows = _order.Rows.length;
              
              if(delivery){
                  const method = delivery.DeliveryMethods[0];
                  _order.AmountDelivery = getAmountDelivery(delivery, method, _order);
              }
                              
              dispatch({ type: delRowFromOrder, orderRequest: orderRequest, orderRequest, variant: 'filled', severity: 'warning', duration: 3000 });
            });    
  },

  delFromDetail: (rowId, detailId) => async (dispatch, getState) => {
    dispatch({ type: requestNewOrderType });

    const orderRequest = getState().orderRequest;
    const user = orderRequest.user;

    
      let token = null;
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      orderRequest.snackbarTxt = "Deleted row from order";
      const url = `api/v1/order/delrowdetail/${rowId}/${detailId}`;
      await axios
        .delete(url, { headers: { Authorization: token } })
        .then((response) => {
          orderRequest.Order = response.data;
          orderRequest.Amount = response.data.Amount;
          orderRequest.qtyrows = response.data.Rows.length; // orderRequest.Order.Rows.length;
          dispatch({ type: delRowFromOrder, orderRequest: orderRequest, variant: 'filled', severity: 'error', duration: 3000 });
        });    
  },

  delFromScripts: (sIndx, pIndx) => async (dispatch, getState) => {        
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    const products = scripts[sIndx].ActionByCondition.Products;
    products.splice(pIndx, 1);
    scripts[sIndx].ActionByCondition.Products = products;                
    dispatch({ type: requestScriptType, scripts: scripts });    
  },

  delAllFromScripts: () => async (dispatch, getState) => {
    const orderRequest = getState().orderRequest;
    const scripts = orderRequest.scripts.slice();    
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      const products = script.ActionByCondition.Products;
      const left = [];
      
      for (let y = 0; y < products.length; y++) {
        const product = products[y];
        if(product.ForceAdd){
          left.push(product);
        }        
        // console.log(product);
      }

      script.ActionByCondition.Products = left;
    }
    dispatch({ type: requestScriptType, scripts: scripts });
  },

  hideSnackBar: () => (dispatch) => {
    dispatch({ type: hideSnakBarFromOrder });
  },

  clearProducts: () => async (dispatch) => {
    dispatch({
      type: getSuggestionsProducts,
      suggestions: { CatalogItems: [] },
      promotions: [],
      chests: [],
    });
  },

  getProducts: (query, user, country, lang) => async (dispatch, getState) => {
    
    if(!query || query.length === 0 || query === '')
        return;

      const orderRequest = getState().orderRequest;          
      
      const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`;
            
      // const url = `/api/v1/CatalogItem/Search/${query}/${country}/${lang}/false`;
      const url = `/api/v1/CatalogItem/Search/${query}/${country}/${lang}/false`;      
      console.log('order search', url);
      fetch(url, { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    console.log('data', data)
                    dispatch({ type: getSuggestionsProducts, suggestions: data });                
                })
                .catch((error) => {
            console.log('Error', error);
            dispatch({ type: getSuggestionsProducts, suggestions: { CatalogItems: [] }}); 
        });
    
  },

  confirmOrder: (handleConfirmedOrder) => async (dispatch, getState) => {   
    dispatch({ type: beginOrderChg });
    
    const state = getState();    
    const orderRequest = state.orderRequest;        
    const appLayout = state.appLayout;
    const user = appLayout.user;
        
    const order = { ...orderRequest.Order };
    const lang = orderRequest.lang;

    // check international delivery
    const idx = orderRequest.deliveries.findIndex(d => d.Id === order.DeliveryProviderId);
    if(idx < -1){
      const deliveryMethod = orderRequest.deliveries[idx].DeliveryMethods[0];
      if(deliveryMethod.InitMethod === "INTERNATIONAL"){
        order.RecipientRaion = '';      
        order.RecipientStreetname = '';
        order.RecipientStreetnameNumber = '';
        order.RecipientApartment = '';
      }
    }

    const scripts = orderRequest.scripts;
    const items = [];

    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      // console.log("Add from Script", script);

      for (let y = 0; y < script.ActionByCondition.Products.length; y++) {        
        const data = script.ActionByCondition.Products[y]; 
        // console.log("Data by script", data);
        const pos = {
          ParentIdrref: data.Product.Idrref,
          ProductId: data.Product.Id,
          orderrowtype: OrderRowType.Script,
          Name: data.Product.Name,
          LocalName: data.Product.Name,
          Price: data.Prices[0],
          CatalogPrice: data.Product.CatalogPrice,
          CatalogPricePv: data.Product.CatalogPricePv,
          Code: data.Product.Code,
          Image: data.Product.Image,
          Rate: data.Rate,
          UseLO: script.ActionByCondition.UseLO,
          DiscountForConsultant: script.ActionByCondition.DiscountForConsultant,
          Source: { 
            Id: script.Id,
            Idrref: script.Idrref,
            Name: script.Name,
            Description: script.Description
        }
        };
        items.push({ pos: pos, qty: parseInt(data.AllowQty), orderrowtype: OrderRowType.Script, promotiontype: PromotionType.Script, } );
      }
    }
    
    PrepareOrderRows(order, items);
        
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
        
    const saved = await Save(order, token);
    
    const confirmUrl = `api/v1/order/confirm/${saved.Id}`;          
    axios.put(confirmUrl, null, { headers: { Authorization: token } })
          .then(async (response) => {            
            
            const urlOrder = `api/v1/order/get/${lang}`;
            const responseOrder = await fetch(urlOrder, {headers: {Authorization: token}});
            const newOrder = await responseOrder.json();
                        
            let selectedDelivery = null;
            if(newOrder && orderRequest.deliveries ){
              const filterd = orderRequest.deliveries.filter(d => d.Id === order.DeliveryProviderId);
              if(filterd && filterd.length > 0){
                selectedDelivery = filterd[0];
                newOrder.AmountService = selectedDelivery.AmountService;
                
                const method = selectedDelivery.DeliveryMethods[0];
                // newOrder.AmountDelivery = method.CostDelivery;
              }        
            }            
            
            dispatch({ 
                type: confirmOrderType, 
                order: newOrder, 
                snackbarTxt: "Save & confirm order", 
                snackbarShow: true,                
                redirect: true,                
            });
            
            
            handleConfirmedOrder(response.data);

          })
          .catch((error) => {                                    
            if(error.response.data.code === 409){
              let errorTxt = `${error.response.data.info}\r\n${error.response.data.description}\r\n`;
              
              for (let i = 0; i < error.response.data.rows.length; i++) {
                const pos = error.response.data.rows[i];
                errorTxt += `${pos.code} - ${pos.productname}; Не вистачає: ${pos.missed} шт.\r\n`
              }
              
              dispatch({
                type: errorconfirmOrderType,
                variant: "error",
                snackbarTxt: errorTxt,
                order: error.response.data.Order,
              });
            }else{                                          
              dispatch({
                type: showSnakBarFromOrder,
                variant: "error",
                snackbarTxt: `${error.message}. ${error.response.data.description}`,                
              });
            }
                        

          });

        // })
        // .catch((error) => {
        //   console.log("Error 2", error);
        //   dispatch({
        //     type: showSnakBarFromOrder,
        //     variant: "error",
        //     snackbarTxt: `${error.response.statusText}. ${error.response.data}`,
        //   });
        // });    
  },

  showMessage: ( text, severity, variant, duration) => async (dispatch) => {
    dispatch({
      type: showSnakBarFromOrder,
      variant: variant ? variant : 'filled',
      severity: severity ? severity : 'info' ,
      snackbarTxt: text,
      duration: duration ? duration: 30000
    });
  },

  requestCatalogItem: code => async (dispatch, getState) => {

    const state = getState();    
    const request = state.orderRequest;        
    const appLayout = state.appLayout;
    const user = appLayout.user;

    // const request = getState().orderRequest;

    if (code === request.code) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }

    dispatch({ type: requestCatalogItemType, code: code });
    const token = `${(user ? user.token_type : '')} ${(user ? user.access_token: '')}`; 
    
    const url = `/api/v1/CatalogItem/ByCode?code=${code}`;
    axios.get(url, {
          headers: { Authorization: token }
    })
      .then(function(response) {
        dispatch({ type: reciveCatalogItemType, code, product: response.data });
        if(response.data){
          // const urlBySeries = `/api/v1/CatalogItem/BySeria/${response.data.SeriesId}`;
          const urlBySeries = `/api/v1/CatalogItem/ByOwnerSeria/${code}`;
          console.log('By series', urlBySeries);

          axios.get(urlBySeries, {
            headers: { Authorization: token }
          })
          .then(function(response) {
            const productsBySeries = response.data;            
            dispatch({ type: reciveBySeriesType,  productsBySeries: productsBySeries });
          });
        }          
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: reciveCatalogItemType, code, product: null });
      });

        const urlR = `/api/v1/CatalogItem/Related/${code}`;
        console.log('Related', urlR);
        axios
          .get(urlR, {
            headers: { Authorization: token }
          })
          .then(function(response) {
            const related = response.data;
            // console.log(related);
            dispatch({ type: reciveRelatedType,  related: related });
          })
          .catch(error => {
            console.log(error);
            dispatch({ type: reciveRelatedType, related: [] });
          });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case beginOrderChg:
     return {
       ...state,
       disabled: true,
       isLoading: true,
     };
    case startRequestType:
    case requestCountriesType:
      return {
        ...state,
        isLoading: true,
      };
    case requestScriptType:      
      return {
        ...state,
        scripts: action.scripts,
        isLoading: false,
      };      
    case requestDeliveriesType  :
        return {
            ...state,            
            isLoading: false,
        };  
    case reciveCountriesType: 
    return {
            ...state,
            countries: action.countries,          
            isLoading: false,
        };
    case reciveDeliveriesType: 
        return {
            ...state,
            deliveries: action.deliveries,          
            isLoading: false,
        };
    case requestChestType:
      return {
        ...state,
        chests: action.chests,
        isLoading: false,
      };
    case requestPromotionsType:
      return {
        ...state,
        promotions: action.promotions,
        lastTimeGetPromotions: action.lastTimeGetPromotions,
        lang: action.lang,
        isLoading: false,
      };
    case langRequestType: 
        return{
            ...state,
            isLoading: true,
            lang: action.lang,
        };
    case requestRecommendedType:
      return {
        ...state,
        recommended: (action.recommended ? action.recommended: []),
        isLoading: false,
      };
    case getAppSettings:
      return {
        ...state,
        appSettings: action.appSettings,
        user: action.user,
      };
    case requestNewOrderType:
      return {
        ...state,        
        reqOrderId: action.reqOrderId,
        lang: action.lang
      };
    case changeOrderField:
      // console.log(action.orderRequest.Order);
      return {
        ...state,
        Order: { ...action.orderRequest.Order },
      };
    case reciveNewOrderType:
      return {
        ...state,
        isLoading: false,
        disabled: false,
        reqOrderId: action.reqOrderId,
        qtyrows: action.qtyrows,
        Amount: action.Amount,
        Order: action.order,
        user: action.user,
        accessBusinessAttr: action.accessBusinessAttr,
        accessUsePaymentOBAttr: action.accessUsePaymentOBAttr,        
        selectedDelivery: action.selectedDelivery,        
      };
    case saveOrder:
      return {
        ...state,
        isLoading: false,
        qtyrows: action.orderRequest.qtyrows,
        Amount: action.orderRequest.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: "success",
        snackbarShow: true,
        disabled: false,
        Order: { ...action.orderRequest.Order },
      };
    case confirmOrderType:
      return {
        ...state,
        isLoading: false,        
        Order: action.order,
        allowOrder: true,
        disabled: false,
        selectedDelivery: null,
        qtyrows: action.order.Rows.length,
        Amount: action.order.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: "success",
        scripts: [],
        snackbarShow: action.snackbarShow,
        // neworder: action.neworder,
        redirect: action.redirect,        
        mount: 0,
      };
    case errorconfirmOrderType:
      return {
        ...state,
        snackbarTxt: action.snackbarTxt,
        variant: action.variant,
        snackbarShow: true,
        disabled: false,
        isLoading: false,
        Order: { ...action.order },
      };  
    case addRowToOrder:
      return {
        ...state,
        isLoading: false,
        disabled: false,
        Order: { ...action.order },
        qtyrows: action.order.Rows.length,
        Amount: action.order.Amount,
        snackbarTxt: action.snackbarTxt,
        variant: action.variant,
        severity: action.severity,
        duration: action.duration,
        snackbarShow: action.snackbarShow,
        neworder: action.neworder,        
        accessBusinessAttr: action.accessBusinessAttr,
        accessUsePaymentOBAttr: action.accessUsePaymentOBAttr
      };
    case updRow:
      return {
        ...state,
        // isLoading: false,
        // snackbarTxt: "",// action.snackbarTxt,
        // variant: "success",
        // snackbarShow: false,
        // qtyrows: action.order.Rows.length,
        // Amount: action.order.Amount,
        Order: action.order,
        disabled: false,
      };
    case delRowFromOrder:
      return {
        ...state,
        isLoading: false,        
        disabled: false,
        snackbarTxt: action.orderRequest.snackbarTxt,
        variant: action.variant,
        severity: action.severity,
        duration: action.duration,
        snackbarShow: true,
        qtyrows: action.orderRequest.qtyrows,
        Amount: action.orderRequest.Amount,
        Order: { ...action.orderRequest.Order },
      };
    case hideSnakBarFromOrder:
      return {
        ...state,
        snackbarTxt: "",
        snackbarShow: false,
      };
    case showSnakBarFromOrder:
      return {
        ...state,
        snackbarTxt: action.snackbarTxt,
        variant: action.variant,
        severity: action.severity,
        duration: action.duration,
        snackbarShow: true,
        disabled: false,
        isLoading: false,
      };
    case getSuggestionsProducts:
      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false,
      };
    case clearSuggestionsProducts:
      return {
        ...state,
        suggestions: { CatalogItems: [] },
        isLoading: false,
      };
    case reciveCatalogItemType:
      return {
        ...state,
        code: action.code,
        product: action.product,
        isLoading: false
      };
    case reciveRelatedType: 
      return {
        ...state,
        isLoading: false,
        related: action.related,
      };
    case reciveBySeriesType: 
      return {
        ...state,
        isLoading: false,
        productsBySeries: action.productsBySeries,        
      };
    case requestCatalogItemType:
      return {
        ...state,
        code: action.code,
        isLoading: true
      };
    case undefinedOrder: 
      return {
        ...state,
        Order: null,
        qtyrows: 0,
      };
      default:
      return state;
  }
};
