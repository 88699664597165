import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Box, Grid } from "@material-ui/core";
import useScreenOrientation from '../../hooks/ScreenOrientation';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            
            display: 'flex',
            // flexGrow: 1,
            justifyItems: 'center',
            justifyContent: 'center',
            marginBottom: theme.spacing(1),
        },
        imgwrap: {
            width: 'auto',
            margin: 'auto'
        },
        
        aspectVideo:{
            width: '100%',
            aspectRatio: '16/9',
            border: 'none'
        },

        shortAspectVideo:{            
            marginTop: theme.spacing(1),
            width: 'auto', 
            // height: 865,
            // [theme.breakpoints.up("sm")]: {                
            //     width:'100%', 
            //     height: 'auto',
            // },
            aspectRatio: '9/16',
            border: 'none'
        },
        portrait: {
            width: "100%",
        },
        landscape: {
            // minHeight: 376,            
            width: '100%'
        },

        aspectImage:{
            display: 'block',            
            maxWidth: '100%', /*actual image width*/
            height: 'auto', /* maintain aspect ratio*/
            margin: 'auto',/*optional centering of image*/
            aspectRatio: '16/9'
        }
    })
);

const VideoLinkBlock = props => {
    const { url, shortVideo } = props;
    const classes = useStyles();    
    const screenOrientation = useScreenOrientation();
    
  return (
    <div className={classes.root}>
        <Grid container   direction="row" justifyContent="center" alignItems="center"spacing={0}>
            <Grid item xs={12} sm={shortVideo ? 6: 9} md={shortVideo ? 4: 8}>
            <iframe
                className={ clsx( shortVideo ? 
                    clsx(classes.shortAspectVideo, screenOrientation === "portrait-primary" ? classes.portrait: classes.landscape) : 
                    classes.aspectVideo )}
                src={url} 
                title="YouTube video player" 
                
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
            >                    
            </iframe>        
            </Grid>
        </Grid>   
            
    </div>
  )
}

VideoLinkBlock.propTypes = {
    url: PropTypes.string.isRequired,
    shortVideo: PropTypes.bool.isRequired
}

export default VideoLinkBlock;