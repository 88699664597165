import React, { useContext, Suspense, useEffect, useCallback, useState } from "react";
import { createStyles, makeStyles,  } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Notification from "../Notification/Notification";
import Footer from "../Navigator/Footer";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { ukUA } from "@material-ui/core/locale";
import AppThemeOptions from "../Themes/Default/theme";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";
import { AppContext } from "../../providers/appProvider";
import { FirstStep } from "../Start/FirstStep";
import { Navigator } from "../Navigator";
import { Typography } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { useCookies, setCookie } from 'react-cookie';
import AcceptCookies from '../AcceptCookies/AcceptCookies';
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import Subscribe from "../Subscribe/Subscribe";
const useStyles = makeStyles((theme) =>
    createStyles({
        main: {
            width: "100%",
            display: "block",
            padding: 0,    
            marginBottom: theme.spacing(6),

            [theme.breakpoints.down("xs")]: {
                minHeight: 300,                
            },
            [theme.breakpoints.up("sm")]: {
                minHeight: 597,          
            },    
        },  
        progress: {
            height: 2,
            position: "absolute",
            top: 0,
            left: 0,
            right:0,
        },  
        linearColorPrimary: {
            backgroundColor: "#abdcda",
        },
        linearBarColorPrimary: {
            backgroundColor: "#d20000",
        },
        copyright: {
            paddingTop: 4,
            paddingBottom: 4,
            display: 'block',
            textAlign: 'center',
            color: grey[200],
            backgroundColor: '#081015',
            '& a':{
                color: '#44bee4',
                textDecoration: 'none',                
                // textTransform: 'uppercase'
            }
        }
  })
);

const days_to_store = 90;

function getParamFromUri (get_param_name) {
    // const url = new URL(window.location.href);
    // const params = new URLSearchParams(url.search);
        
    // const utm_source = params.get("utm_source");
    // const admitad_uid = params.get("admitad_uid");

    var pattern = get_param_name + '=([^&]+)';
    var re = new RegExp(pattern);
    return (re.exec(document.location.search) || [])[1] || '';
};

// function setCookieValue (cookie_name, param_name) {
//     var param = getParamFromUri(param_name);
//     if (!param) { return; }
//       var period = days_to_store * 60 * 60 * 24 * 1000;   // в секундах
//       var expiresDate = new Date((period) + +new Date);
//       var cookieString = cookie_name + '=' + param + '; path=/; expires=' + expiresDate.toGMTString();
//       document.cookie = cookieString;
//       document.cookie = cookieString + '; domain=.' + location.host;
// };

// const rc = parseInt(process.env.REACT_APP_REQUIRE_COUNTRY) === 1;
const rc = process.env.REACT_APP_REQUIRE_COUNTRY === "true";
const API_URL = '/api/v1.0';

const Layout = (props) => {
    const classes = useStyles();
    const app = useContext(AppContext);  
    
    const { children } = props;
    const { theme, country, acceptCookies, changeAcceptCookies} = app;
    const muiTheme = createTheme(AppThemeOptions[theme], ukUA);
    const [cookies, setCookie, removeCookie] = useCookies();
    
    const location = useLocation();
    const { hash, pathname, search } = location;
    
    const [user, setUser] = useState();
    const [connection, setConnection] = useState(null);
    // const [isAdmin, setIsAdmin] = useState(false);    

    function gtag() {        
        window.dataLayer.push(arguments);
    };
    
    const sendConsent = useCallback((method, consent) => {                 
        gtag('consent', method, consent);
        localStorage.setItem('consentMode', JSON.stringify(consent));
    }, []);

  useEffect(() => {

    async function fetchData() {         
        const selfHost = new URL(`${window.location.protocol}//${window.location.host}`);
        
        const referrer = document.referrer;
        const referrerUrl = new URL(referrer ? referrer : window.location.href);
        

        if(referrerUrl.pathname === '/callback'){
            return;
        }
                            
        const date = new Date();
        date.setDate(date.getDate() + days_to_store);
                  
        let ref = getParamFromUri("ref");
        let utm_source = getParamFromUri("utm_source");                
        let gad_source = getParamFromUri("gad_source");
        let gclid = getParamFromUri("gclid");
        let fbclid = getParamFromUri("fbclid");
        let admitad_uid = getParamFromUri("admitad_uid");

        // gad_source= або gclid=
        // ?ref=XXXXXXX - X - будь-яка цифра
        // fbclid=
        // utm_source=

        // const period = days_to_store * 60 * 60 * 24 * 1000;   // в секундах

        if(gad_source !== '' || gclid !== '' || fbclid !== ''){
            utm_source = '';
            admitad_uid = '';
            removeCookie('utm_source');
            removeCookie('admitad_uid');
            
            if(gad_source !== ''){
                setCookie(
                    'gad_source', 
                    gad_source, {
                            path: '/',
                            expires: date                        
                        }
                );                
            }

            if(gclid !== ''){
                setCookie(
                    'gclid', 
                    gclid, {
                            path: '/',
                            expires: date                        
                        }
                );                
            }
            
            if(fbclid !== ''){
                setCookie(
                    'fbclid', 
                    fbclid, {
                            path: '/',
                            expires: date                        
                        }
                );                
            }
            return;
        }

        if(ref !== '' && utm_source === ''){
            utm_source = '';
            admitad_uid = '';
            removeCookie('utm_source');
            removeCookie('admitad_uid');
            return;
        }

        if(utm_source !== ''){
            setCookie(
                'utm_source', 
                utm_source, {
                        path: '/',
                        expires: date                        
                    }
            );
        }

        if(admitad_uid !== ''){            
            setCookie(
                'admitad_uid', 
                admitad_uid, {
                        path: '/',
                        expires: date                        
                    }
            );
        }
        
        if(process.env.NODE_ENV !== "development" && selfHost.host.toLocaleLowerCase() === referrerUrl.host.toLocaleLowerCase()){
            console.log('Return. Dont save to DB. It reerrer self host ');
            return;
        }

        const from = referrer && referrer.length > 0 ? referrer: selfHost; 
        const url = `${API_URL}/referrer`;
        
        await fetch(url, { 
            method: 'POST', 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",                
            },
            body: JSON.stringify(
                {"from": from, 
                "to": window.location.href,
                "utm_source": utm_source,
                "uid": admitad_uid,
                "ref": (ref ? ref : 0)
            }),
        })
        .then((response) => response.json())
            .then((data) => {
            })
        .catch((error) => {
            console.log("Error", error);
        });
    }
    fetchData();
  }, [removeCookie, setCookie]); 

//   useEffect(() => {        
//     if (!app) return;
//     function fetchUser() {
                
//         app.getUser().then(async (user) => {                                   
//             let token = '';
//             if (user) {                        
//                 setUser(user);
//                 token = user.access_token;
//             }

//             const connect = new HubConnectionBuilder()
//                                     .withUrl("/chatHub", { accessTokenFactory: () => `Bearer ${token}` })
//                                         .withAutomaticReconnect()                                        
//                                             .build();
//             setConnection(connect);
//         });
//     }
//     fetchUser();    
// }, [app]);


useEffect(() => {    
    if(localStorage.getItem('consentMode') === null){
        const decision = "denied";
        const consent = {
            'ad_storage': decision,
            'analytics_storage': decision,
            'ad_user_data': decision,
            'ad_personalization': decision
        };
        // sendConsent('default', consent);
        gtag('consent', 'default', consent);
        localStorage.setItem('consentMode', JSON.stringify(consent));
    }else{
        gtag('consent', 'update', JSON.parse(localStorage.getItem('consentMode')));
        // sendConsent('update', JSON.parse(localStorage.getItem('consentMode')));
    }    
}, []);



// if(process.env.NODE_ENV === "development")
//     console.log('acceptCookies', acceptCookies);

    return (
      <Suspense 
        fallback={
          <LinearProgress 
            className={classes.progress} 
            classes={{ colorPrimary: classes.linearColorPrimary, barColorPrimary: classes.linearBarColorPrimary }}
          />
        }
      >      
        <ThemeProvider theme={muiTheme}>
          <Helmet>
          <title>J’erelia - джерело здоров&apos;я, краси, фінансового благополуччя.</title>
          <meta property="og:title" content="The Rock" />
          <meta property="og:description" content="Натуральна косметика по догляду за шкірою тіла, обличчя, рук та волоссям, ексклюзивні парфуми, інноваційні еко-засоби для чистоти в домі, аксесуари, вітаміни, комплексні програми очищення організму, фітопрепарати для здоров'я, продукти функціонального харчування від української компанії 'Джерелія'."/>
          <meta property="og:url" content="https://jerelia.com/" />
          <meta property="og:site_name" content="J’erelia" />
          <meta property="og:image" content="https://jerelia.com/assets/files/images/images/jerelia.jpg" />
          </Helmet>
            {acceptCookies === 0 && 
                <AcceptCookies  onAcceptCookies={changeAcceptCookies} sendConsent={sendConsent} />
            }

            <Navigator />
            <Container maxWidth={false} className={classes.main}>
                {rc && !country && 
                    <FirstStep />
                }                
                {children}                
                
                <Notification />
                
                {connection && 
                    <Subscribe pathname={window.location.href.toLowerCase()} connection={connection} token = {`Bearer ${user ? user.access_token: ''}`} />
                }                                
            </Container>
            
            {!pathname.toLowerCase().startsWith("/builder")  && 
                <Footer />
            }
                        
            <Typography variant="caption" className={classes.copyright}>
                Copyright © {new Date().getFullYear()} <a className={classes.link} href={'https://jerelia.com'}><strong>Jerelia</strong>.</a> All rights reserved.
            </Typography>
            
        </ThemeProvider>
      </Suspense>
    ); 
}

export default Layout;