import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./privateRoute";

import Index from "./components/Index/Index";
import Counter from "./components/Counter";
import FetchData from "./components/FetchData";
import Auth from "./components/Auth";
import MenuBuilder from "./components/MenuBuilder/MenuBuilder";
import Carousels from "./components/Carousels";
import Files from "./components/Files/Files";
import Viewer from "./components/Files/Viewer";
import Calendar from "./components/Calendar/Calendar";
import ListContent from "./components/Manage/Content/ListContent";
import ContentEditor  from "./components/ContentEditor/ContenEditor";
// import SilentRenew from "./components/SilentRenew/SilentRenew";

// Register users
import Register from "./components/Register/Register";
import RegisterConsultant from "./components/Register/RegisterConsultant";
import RegisterConsultantMFA from "./components/Register/RegisterConsultantMFA";
import RegisterConsultantSuccess from "./components/Register/RegisterConsultantSuccess";
import RegisterClient from "./components/Register/RegisterClient";
import RegisterClientSuccess from "./components/Register/RegisterClientSuccess";

import Catalogs from "./components/Catalogs/Catalogs";
import Catalog from "./components/Catalog/Catalog";
// import PdfCatalog from "./components/Interactive/PdfCatalog";
import InteractiveCatalog from "./components/Interactive/InteractiveCatalog";

import Search from "./components/Search/Search";
import { Product } from "./components/Product/Product";
import { RelatedProduct } from "./components/RelatedProduct";

import Profile from "./components/Profile";
import Introduce from "./components/Profile/Introduce";

import Order from "./components/Order";
import Orders from "./components/Orders/Orders";
import History from "./components/Orders/History";

import Confirm from "./components/Order/Confirm";
import Thanks from "./components/Order/Tanks";

import AllOrders from "./components/AllOrders/AllOrders";
// import Pay from "./components/Pay";
import Pay from "./components/Pay/Pay";

import Recharge from "./components/Payment/Recharge";
import Fondy from "./components/Payment/Fondy";
import Liqpay from "./components/Payment/Liqpay";

import RechargeSuccess from "./components/Payment/RechargeSuccess";
import { Charity } from "./components/Charity";
import { GeneralStaff } from "./components/GeneralStaff";


import Article from "./components/Article/Article";
import We from "./components/We/We";
import Promotions from "./components/Promotions/Promotions";
import { Page, View, Page401, Page404 } from "./components/Page";
import NotFound from "./components/NotFound";


// import Landing from "./components/Landing/Landing";
// import LandingPage from "./components/LandingPage/LandingPage";
// import LandingEditor from "./components/LandingEditor/LandingEditor";

import BearerToken from "./components/BearerToken/BearerToken";
import News from "./components/News/News";
import Programs from "./components/Programs/Programs";
import Promo from "./components/Promo/Promo";
import Promotion from "./components/Order/Promotion";

import { Callback } from "./components/auth/callback";
import { SilentRenew } from "./components/auth/silentRenew";
import { Signin } from "./components/auth/signin";
import {Logout} from './components/auth/logout';
import { SigninPopup } from "./components/auth/signinPopup";

// Manage 
import Slides from "./components/Slides";
import Reviews from "./components/Reviews";
import Drafts from "./components/Drafts";
import UserLogins from "./components/UserLogins/UserLogins";
import { LogSms } from "./components/Logs/LogSms";


import Notifications from "./components/Manage/Notifications/Notifications";
import  Contents  from './components/Manage/Content/Contents';
import  Content  from './components/Manage/Content/Content';
import PageEditor from './components/Manage/Content/PageEditor';


import { HomePage } from "./components/Manage/HomePage";

import Builder from "./components/Builder/Builder";
import Articles from "./components/Builder/Articles";
import { Utils } from "./components/Manage/Utils/Utils";

// References
import Goods from "./components/Goods/Goods";
import Commodities from "./components/References/Commodities/Commodities";

// Templates
import {Marathon} from "./components/Sample/Marathon";

// Reports
import { Balance } from "./components/Balance";
import Dashboard from "./components/Reports/Dashboard";
import PersonalIndicators from "./components/Reports/PersonalIndicators";
import CurrentPerion from "./components/Reports/CurrentPeriod";
import Terminated from "./components/Reports/Terminated";
import NetworkStructure from "./components/Reports/NetworkStructure";
import Activity from "./components/Reports/Activity";
import BusinessPack from "./components/Reports/BusinessPack";
import OrderFin from "./components/Order/OrderFin";
import BaseIndicatorsPrev from "./components/Reports/BaseIndicatorsPrev";
import BaseIndicators from "./components/Reports/BaseIndicators";
import {Challenges} from "./components/Challenges/Challenges";
import { Collaboration } from "./components/Collaboration";

// Environment
import { Chat } from "./components/Chat";
import { Change } from "./components/Country/Change";

const reload = () => window.location.reload();
export const Routes = (
  <Switch>
    <Route exact path='/'>
      <Index />
    </Route>
    
    <Route exact path='/content/:contenttype/:indexPage?/:query?/' component={ListContent} />
    <Route exact path='/contenteditor/:contenttype/:id?' component={ContentEditor} />
    <Route exact path='/view/:key?' component={View} />

    <Route exact path='/signin' component={Signin} />
    <Route exact path='/logout' component={Logout} />

    <Route exact path='/signinpopup' component={SigninPopup} />        
    <Route exact={true} path="/callback" component={Callback} />
    <Route exact path='/silentrenew' component={SilentRenew} />

    <Route exact path='/auth' component={Auth} />        
    <Route exact path='/register/:ref?' component={Register} />
    <Route exact path='/register_consultant/:ref?' component={RegisterConsultant} />
    <Route exact path='/register_consultant_verify' component={RegisterConsultantMFA} />
    <Route exact path='/register_consultant_success' component={RegisterConsultantSuccess} />        
    <Route exact path='/register_client/:ref?' component={RegisterClient} />
    <Route exact path='/register_client_success' component={RegisterClientSuccess} />

    <Route exact path='/page/:key' component={Page} />
    <Route exact path='/catalog/:grp?/:pi?' component={Catalog} />
    {/* <Route exact path='/interactivecatalog/:query?' component={PdfCatalog} /> */}
    <Route exact path='/interactivecatalog' component={InteractiveCatalog} />
    <Route exact path='/viewer' component={Viewer} />
    
    <Route exact path='/search/:query/:page?/:size?' component={Search} />
    <Route exact path='/product/:code' component={Product} />
    <Route exact path='/relatedproduct' component={RelatedProduct} />
    <Route exact path='/order/:id?' component={Order} />
    <Route exact path='/order/thanks/:id?' component={Thanks} />
    {/* /orderfin/reg/pp; /orderfin/noreg/pp;     /orderfin/reg/cod; /orderfin/noreg/cod; */}
    
    <Route exact path='/orderfin/:auth/:paymethod/:id' component={OrderFin} />
    <Route exact path='/pay/:id' component={Pay} />
    <Route exact path='/recharge' component={Recharge} />

    <Route exact path='/payment/fondy' component={Fondy} />
    <Route exact path='/payment/liqpay' component={Liqpay} />
    
    {/* <Route exact path='/payment/fondy' render={(props) => <Fondy {...props} />} />
    <Route exact path='/payment/liqpay' render={(props) => <Liqpay {...props} />} /> */}

    <Route exact path='/charity' component={Charity} />
    
    {/* <Route exact path='/recharge_success_consult' render={(props) => ( <RechargeSuccess {...props} amount={props.amount} /> )}/> */}
    {/* <Route exact path='/recharge_success_consult' component={RechargeSuccess} />    
    <Route exact path='/recharge_success_vip' component={RechargeSuccess} />     */}
    
    <Route exact component={RechargeSuccess} path="/recharge_done_consult" />
    <Route exact component={RechargeSuccess} path="/recharge_done_vip" />

    {/* Manage */}    
    <Route exact path='/manage/homepage' component={HomePage} />
    <Route exact path='/manage/contents/' component={Contents} />
    <Route exact path='/manage/notifications' component={Notifications} />
    
    <Route exact path='/manage/content/:name?' component={Content} />
    <Route exact path='/manage/pageeditor/:name?' component={PageEditor} />

    <Route exact path='/slides' component={Slides} />    
    <Route exact path='/confirm/:id?' component={Confirm} />
    <Route exact path='/utils' component={Utils} />
    <Route exact path='/catalogs' component={Catalogs} />
    
    
    {/* Templates */}
    <Route exact path='/marathon' component={Marathon} />

    <Route exact path='/profile' component={Profile} />
    <Route exact path='/:ref/introduce' component={Introduce} />

    <PrivateRoute exact path='/orders/:t?/:q?/:pi?/:ps?' component={Orders} />
    <Route exact path='/history' component={History} /> 
    
    <Route exact path='/allorders/:q?/:pi?/:ps?' component={AllOrders} />
    <Route exact path='/we/:code' component={We} />
    <Route exact path='/counter' component={Counter} />
    <Route exact path='/menubuilder/:file?' component={MenuBuilder} />
    <Route exact path='/files/:path?' component={Files} />

    <Route exact path='/calendar' component={Calendar} />
    <Route exact path='/carousels' component={Carousels} />
    <Route exact path='/reviews' component={Reviews} />
    <Route exact path='/drafts' component={Drafts} />
    <Route exact path={'/userlogins'} component={UserLogins} />
    <Route exact path='/logsms' component={LogSms} />
            
    <Route exact path='/fetchdata/:startDateIndex?' component={FetchData} />
    
    <Route exact path='/promotions' component={Promotions} />
    {/* <Route exact path='/landings' component={Landing} /> */}
    <Route exact path='/goods' component={Goods} />
    <Route exact path='/commodities' component={Commodities} />
    
    <Route exact path='/bearertoken' component={BearerToken} />
    
    {/* <Route exact path='/landingpage/:id' component={LandingPage} /> */}
    {/* <Route exact path='/landingeditor/:id' component={LandingEditor} /> */}

    <Route exact path='/news/:q?/:page?/:size?' component={News} />
    <Route exact path='/programs/:q?/:page?/:size?' component={Programs} />
    <Route exact path='/promo' component={Promo} />
    <Route exact path='/promotion/:id' component={Promotion} />
    
    <Route exact path='/article/:id' component={Article} />
    <Route exact path='/builder/:id?' component={Builder} />
    <Route exact path='/manage/articles' component={Articles} />
    
    {/* Reports */}
    <Route exact path='/balance' component={Balance} />
    <Route exact path='/dashboard' component={Dashboard} />
    <Route exact path='/personalindicators' component={PersonalIndicators} />
    <Route exact path='/currentperiod' component={CurrentPerion} />
    <Route exact path='/terminated' component={Terminated} />
    <Route exact path='/networkstructure' component={NetworkStructure} />
    <Route exact path='/activity' component={Activity} />
    <Route exact path='/businesspack' component={BusinessPack} />
    <Route exact path='/baseindicators' component={BaseIndicators} />
    <Route exact path='/baseindicatorsprev' component={BaseIndicatorsPrev} />
    <Route exact path='/challenges' component={Challenges} />
    <Route exact path='/collaboration' component={Collaboration} />
    
    
    {/* Environment */}
    <Route exact path='/chat' component={Chat} />
    <Route exact path='/country/change' component={Change} />
    <Route exact path='/generalstaff' component={GeneralStaff} />

    {/* <Route path="/apple-developer-merchantid-domain-association" onEnter={reload} /> */}
    
    <Route path="/index.html" to="/" onEnter={reload}/>    
    <Route path="/offline.html" onEnter={reload} />

    <Route path="/notfound" component={Page404} status={404} />
    <Route path="/page401" component={Page401} />
    
    <Route path="*" component={Page404} status={404} />
    
    <Redirect to="/" />
  </Switch>
);

