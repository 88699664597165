import React, { useState, useContext, useEffect } from "react";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { Helmet } from "react-helmet";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import {  Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Grid,  
  Paper,
  Button,  
  Typography,
} from "@material-ui/core";



const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(3, 1),
      borderRadius: theme.spacing(2),
      boxShadow: theme.palette.ambilight.boxshadow
    },
    paymentbox: {
        minHeight: 355
    },
    header: {
      fontWeight: 300,
      marginBottom: theme.spacing(2),
    },
    logo: {
      maxWidth: "80%",
      height: 48,
    },
    balance: {
      fontWeight: 400,
      fontSize: "1.1rem",
    },
    sup: {
      fontSize: '.6em',
      // marginLeft: theme.spacing(1/4),
      marginRight: theme.spacing(1),
    },
    currency: {
      fontSize: '.6em',
      fontWeight: 100
    }
  })
);

const Pay = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const auth = useContext(AppContext);
  const { t } = useTranslation();

  const [token, setToken] = useState();
  const [country, setCountry] = useState();
  
  // const [amount, setAmount] = useState(100);
  const [order, setOrder] = useState();


  // Get order
  useEffect(() => {
    if (!token) return;
    if(!id) return;

    // const url = `/api/v1.0/liqpay/payorder/${id}`;
    const url = `/api/v1/order/confirmed/${id}/true`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log("Pay order", data);
          setOrder(data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {});
  }, [id, token]);

  // Get payment country
  useEffect(() => {
    if (!token) return;

    console.log('Token', token);
    
    const url = "/api/v1.0/Payment/Country";
    fetch(url, {
      method: "GET",
      headers: {        
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {          
          setCountry(data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {});
  }, [token]);

  // Get token
  useEffect(() => {
    if (!auth) return;

    function fetchUser() {
      auth.getUser().then(async (user) => {
        let token = null;
        if (user != null) {
          token = `Bearer ${user.access_token}`;
        }
        setToken(token);
      });
    }
    fetchUser();
  }, [auth]);

  const PaymentProviderLogo = (payment) => {
    const idx = payment.Values.findIndex((p) => p.FieldName === "Logo");
    if (idx > -1) {
      return payment.Values[idx].Value;
    }
    return null;
  };



  return (
    <AppConsumer>
      {() => (
        <div className={classes.root}>          
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Paper className={ clsx(classes.paper, classes.paymentbox)} elevation={0}>
                {country && country.PaymentProviders && (
                  <>
                    <Typography
                      className={classes.header}
                      variant="h4"
                      align="center"                      
                    >
                      {t("pay.Order")}
                    </Typography>
                    {order && 
                      <Typography                        
                        variant="h5"
                        align="center"
                        gutterBottom
                      >
                      {order.DocNum}
                    </Typography>
                    }

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                      style={{ marginBottom: 8 }}
                    >
                      <Grid item xs={12} sm={8}>
                        { order && 
                        <Typography variant="h3" align="center">
                          {parseInt(order.AmmoutPaymenet)}
                          <sup className={classes.sup}>
                            .{(Math.round(order.AmmoutPaymenet * 100) / 100).toFixed(2).split('.')[1]}
                          </sup>
                          <small className={classes.currency}>{order.CurrencyName}</small>
                        </Typography>
                        }
                      </Grid>
                    </Grid>
                    
                    { order && 
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center">
                        {t("Select payment system")}
                        </Typography>
                      </Grid>

                      {country.PaymentProviders.map((payment) => (
                        <Grid item xs={6} sm={4} key={payment.Id}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={clsx(classes.button )}
                            fullWidth
                            component={Link}
                            to={{
                                pathname: `/payment/${payment.Name.toLowerCase()}`,
                                state: { payment: {                                                    
                                                    amount: order.AmmoutPaymenet,
                                                    docId: order.Id,
                                                    title: `${t("pay.Order")} № ${order.DocNum}`,
                                                    order_desc: ''
                                                  }}
                            }}
                          >
                            <img
                              className={clsx(classes.logo, payment.Name.toLowerCase())}
                              alt={payment.Name}
                              src={PaymentProviderLogo(payment)}
                            />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                    }
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </AppConsumer>
  );
};

export default Pay;
