import React, {useState, forwardRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import clsx from "clsx";
import {
    Paper, IconButton
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import PageBlocks from "../Manage/Content/PageBlocks";


const useStyles = makeStyles((theme) => ({
    root: {        
        position: 'relative',       
        minWidth: "644px !important",
        width: "844px !important",        
        [theme.breakpoints.down("xs")]: {
            width: "100% !important",
            minWidth: "100% !important",
        },        
    },
    card: {
        width: "100%",
        
      },
      cardaction: {
                
      },
      typography: {
        color: "#000"
      },
      actionRoot: {
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between"
      },
      icons: {
        position: 'absolute',
        top: theme.spacing(-1),
        right: theme.spacing(-1),
        zIndex: 10000
      },
      expand: {
        padding: "8px 8px",
        // transform: "rotate(0deg)",
        // color: "#000",
        // transition: "all .2s"
      },
      expandOpen: {
        transform: "rotate(180deg)"
      },
      paper: {
        backgroundColor: "#fff",
        width: "100%",
        maxHeight: "480px !important",
        overflow: "hidden",
        overflowY: "scroll" 
        // padding: 16
      },
      checkIcon: {
        fontSize: 20,
        paddingRight: 4
      },
      button: {
        padding: 0,
        textTransform: "none"
      }
}));

const CustormSnackbar = forwardRef(function CustormSnackbar ({ id, ...props }, ref) {
    const classes = useStyles();    
    const { closeSnackbar } = useSnackbar();
    const [data, setData] = useState(props.data);

    const [expanded, setExpanded] = useState(true);
    
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {        
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    //const { message} = props;
    


    return (
        <SnackbarContent ref={ref} className={classes.root}>            
        <div className={classes.icons}>                        
            <IconButton                             
                onClick={handleDismiss}
            >
                <CloseIcon />
            </IconButton>
                </div> 
            
                
                
                    <Paper className={classes.paper}>
                        {data && <PageBlocks content={data} /> }
                    </Paper>
                
                
            
        </SnackbarContent>
    )
});

// CustormSnackbar.propTypes = {
//     data: PropTypes.object.isRequired
// }

export default CustormSnackbar;