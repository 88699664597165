import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from '@material-ui/icons/Add';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { alpha } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";

import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import InsertDriveIcon from "@material-ui/icons/InsertDriveFileOutlined";
// insert_drive_file

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionGoods } from "../../store/Goods";
import "react-sortable-tree/style.css";

const styles = theme => ({
    root: {
        flexGrow: 1,      
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    card: { 
        width: "100%",        
    },
    cardaction: {
        backgroundColor: theme.palette.background.default,
    },
    goodicon: {
        fontSize: 18,
    },
    
    treewrapper: {   
        height: 473,
      },
      search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.9),
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.white, 0.7),
        },
        marginRight: theme.spacing(1) * 1,
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(1) * 3,
          width: "auto",
        },
      },
      searchIcon: {
        width: theme.spacing(1) * 6,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      inputRoot: {
        color: "inherit",
        width: "100%",
      },
      inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 7,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
          width: 200,
        },
      },      
});

const options = [ "Code", "Name"];

class Goods extends React.Component {
    constructor(props) {        
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            search: "",
            openMenu: false,
            anchorEl: null,
            selectedIndexField: 0,
            nodeId: "00000000-0000-0000-0000-000000000000",
            selected: [],
        };
        
    }
    componentDidMount() {
        // this.props.actions.goodsGrpsRequest("", true);
        this.props.actions.goodsGrpsRequest("", false);
    }
        
    desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }
      
    handleCloseMenu = () => {
        this.setState({ openMenu: false, anchorEl: null });
    };

    handleToggleMenu = (e) => {            
        const openMenu = !this.state.openMenu;
        this.setState({openMenu: openMenu, anchorEl: e.currentTarget });  
        // this.setState((state, props, e) => ({            
        //     openMenu: !state.openMenu,
        //     anchorEl: (state.anchorEl ? null : e.currentTarget)
        //   }));
        
        // this.setState({openMenu: !this.state.openMenu, anchorEl: (this.state.anchorEl ? null : e.currentTarget) });  
    };

    // eslint-disable-next-line no-unused-vars
    handleSearch = () => {
        if(this.state.search.length < 3)
        {
            return;
        }
        this.props.actions.searchGoods(this.state.search, options[this.state.selectedIndexField]);
    };

    // eslint-disable-next-line no-unused-vars
    handleClearSearch = (e) => {
        if(this.state.search.length === 0)
        {
            return;
        }

        this.setState({ search: "" });
        this.props.actions.goodsGrpsRequest(this.state.nodeId, false);
    };

    handleChooseFiled = (e, index) => {
        // this.props.actions.choosePromotion(index);
        this.setState({openMenu: false, anchorEl: null, selectedIndexField: index });              
    };

    handleChangeSearch = e =>{
        this.setState({ search: e.target.value });
    };

    keyPressSearch = (e) => {
        if (e.key === "Enter") {      
            this.handleSearch();
            e.preventDefault();
          }
    }
    
    stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = cmp(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    };

    getSorting = (order, orderBy) => {
        return order === "desc" ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    };

    handleChangePage = (event, newPage) =>{        
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {                
        this.setState({page: 0, rowsPerPage: parseInt(event.target.value) });        
    };

    onRowDoubleClick = (e, data) => {
        
        if(this.props.parentCallback === undefined){
            return;
        }
        if(!data){
            return;
        }
        if(data.IsFolder){
            return;
        }

        this.props.parentCallback([{...data}]);
    };

    nodeClicked = (event, rowInfo) =>{
        if (event.target.className === "rstcustom__collapseButton" || event.target.className === "rstcustom__expandButton") {
            // close/expand events            
            // console.log("event: " + event.target.className);
            if(event.target.className === "rstcustom__expandButton"){                
                this.props.actions.loadNodes(rowInfo.node, rowInfo.path);
            }
          } 
        else {
            // console.log(rowInfo);
            this.setState({ nodeId: rowInfo.node.id});
            this.props.actions.goodsGrpsRequest(rowInfo.node.id, false);
        }
    };

    handleClickChecked = (e, id) =>{        
        const { selected } = this.props;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        } 
        
        this.props.actions.goodsSelected(newSelected);

        //this.setState({selected: newSelected});               
    };

    isSelected = (id) => { return this.props.selected.indexOf(id) !== -1;}

    render(){
        // eslint-disable-next-line no-unused-vars
        const { classes, isLoading, treeData, goods, checked } = this.props; 
        const {page, rowsPerPage, openMenu, anchorEl, selectedIndexField } = this.state;
        
        return(
            <div className={classes.root}>
                {isLoading && (
                    <LinearProgress
                    className={classes.progress}
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary
                    }}
                    />
                )}
                
                    
                <Paper style={{padding: 8, marginBottom: 8}}>                                                        
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>                                        
                        <Grid item xs={4} sm={9}>
                            <TextField 
                                size="small" 
                                fullWidth 
                                label={`Search product by ${options[this.state.selectedIndexField]}`}
                                value={this.state.search} 
                                variant="outlined" 
                                onChange={this.handleChangeSearch}
                                onKeyDown={this.keyPressSearch } />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <IconButton color="inherit" onClick={this.handleClearSearch} style={{marginRight: 8}}>
                                <ClearIcon />
                            </IconButton>
                            <ButtonGroup variant="contained" color="primary" aria-label="split button">
                                <Button 
                                    onClick={e => this.handleSearch(e)}>
                                    Search by {options[selectedIndexField]}
                                </Button>
                                <Button
                                    aria-owns={
                                        openMenu ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={e => this.handleToggleMenu(e)}>
                                    <ArrowDropDownIcon />
                                    </Button>
                            </ButtonGroup>
                            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={openMenu}  onClose={this.handleCloseMenu}
                            >                                                                                        
                                {options.map(
                                        (option, index) => (
                                            <MenuItem key={index} 
                                            onClick={e => this.handleChooseFiled(e, index)}>
                                                {option}
                                            </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </Paper>

                                        {
                                            goods && (
                                                <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{width: 20}}>&nbsp;</TableCell>
                                                            <TableCell style={{width: 60}}>Code</TableCell>
                                                            <TableCell>Product name</TableCell>
                                                            <TableCell style={{width: 30}}>&nbsp;</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {goods
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((good) => {
                                                        // const labelId = `enh-checkbox-${good.Id}`;
                                                        return(    
                                                        <TableRow key={good.Id} onDoubleClick={e => this.onRowDoubleClick(e, good)} >
                                                            <TableCell component="th" scope="row">
                                                            {
                                                                good.IsFolder ?  <FolderOutlinedIcon className={classes.goodicon} /> : <InsertDriveIcon className={classes.goodicon} />                                                                    
                                                            }
                                                            </TableCell>
                                                            <TableCell>
                                                                {good.Code}
                                                            </TableCell>
                                                            <TableCell>
                                                                {good.Name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    !good.IsFolder && 
                                                                    <IconButton size="small" aria-label="Add" onClick={e => this.onRowDoubleClick(e, good)}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        );
                                                    })}
                                                    </TableBody>
                                                </Table>

                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 50]}
                                                    component="div"
                                                    count={goods.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    backIconButtonProps={{
                                                        "aria-label": "previous page",
                                                    }}
                                                    nextIconButtonProps={{
                                                        "aria-label": "next page",
                                                    }}
                                                    onPageChange={this.handleChangePage}
                                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                                />                                                
                                                </TableContainer>
                                            )
                                        }

            </div>
        );
    }
}

export default withWidth()(withRouter(withStyles(styles)(
    connect(    
        state => state.goodsItemsRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionGoods ), dispatch)
        }}        
    )(Goods))
));
