import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Box, Typography } from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center'
        },
        imgwrap: {
            width: 'auto',
            margin: 'auto'
        },
        aspectImage:{
            display: 'block',            
            maxWidth: '100%', /*actual image width*/
            height: 'auto', /* maintain aspect ratio*/
            margin: 'auto'/*optional centering of image*/
            // aspectRatio: '16/9'
        },
        placeholder: {
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: theme.spacing(30),            
            paddingTop: theme.spacing(11),
            background: theme.palette.type === "dark" ? grey[800] : grey[200],
            color: grey[400],
            textTransform: 'uppercase'
        }
    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const ImageBlock = props => {
    const { url } = props;
    const classes = useStyles();

  return (
    <div className={classes.root}>         
        {url && 
        <div className={classes.imgwrap}>                
            <img className={classes.aspectImage} src={`${host}${url}`} alt={url} />                                 
        </div>
        }
        {!url && 
        <div className={classes.placeholder}>                    
            <Typography variant='h2' align='center'>Add an image here</Typography>
        </div>
        }
    </div>
  )
}

ImageBlock.propTypes = {
    url: PropTypes.string.isRequired,
}

export default ImageBlock;