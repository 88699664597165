import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Box, Card, CardActionArea, CardMedia, CardContent,  CardActions } from "@material-ui/core";
import {extractFileExt, IsImage, IsVideo } from '../../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center',
            borderRadius: 0,
        },
        imgwrap: {            
            // padding: theme.spacing(1, 0),            
            width: 'auto',
            margin: 'auto',
            // border: `1px solid ${theme.palette.divider}`,
            // aspectRatio: '16/9'
        },

        mediaVideo: {            
            width: '100%',            
          },
    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const VideoBlock = props => {
    const { url } = props;
    const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>         
        {url && url.length > 0 && 
        <div className={classes.imgwrap}>
            <video className={classes.mediaVideo} muted loop autoPlay playsInline>
                <source src={`${host}${url}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div> 
        }            
        
    </Card>
  )
}

VideoBlock.propTypes = {
    url: PropTypes.string.isRequired,
}

export default VideoBlock;