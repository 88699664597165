import React from "react";
import PropTypes from 'prop-types';
import { withStyles,makeStyles, useTheme } from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionPromotionItemCreators } from "../../store/Promotions";
import { actionGoods } from "../../store/Goods";
import { AppContext } from "../../providers/appProvider";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import {Paper, Grid, Typography, IconButton, List, ListItem, ListItemText, LinearProgress,
        Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
        Slide, ButtonGroup, Button, ClickAwayListener, Grow, Popper, Tabs, Tab, TablePagination,
        AppBar, Toolbar } from "@material-ui/core";

import { withTranslation } from "react-i18next";

import RefGoods from "../Goods/Goods";
import Promotion from "../Promotions/Promotion";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DateFnsUtils from "@date-io/moment";
import MUIDataTable from "mui-datatables";

// import DoneIcon from "@material-ui/icons/Done";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import amber from "@material-ui/core/colors/amber";

import {countryToFlag} from '../../store/countries';
  
const styles = theme => ({
    root: {
        flexGrow: 1,      
        padding: theme.spacing(0, 1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    appbar: {            
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    toolbar: {
        // padding: theme.spacing(1, 2)
    },
    mr: {
        marginRight: theme.spacing(1)
    },
    card: { 
        width: "100%",        
    },
    cardaction: {
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        padding: theme.spacing(1),      
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    dialogcontent:{
        padding: theme.spacing(2),
        margin: theme.spacing(1),
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    appBar: {
        position: "relative",
    },
    flex: {
        flex: 1,
        marginLeft: theme.spacing(1) * 2,
    },  
    warning: {
        backgroundColor: amber[900],
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    cellAtions:{
      width: 210
    },
    button: {
      margin: theme.spacing(1),
    },
    tabpanel: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(0, 1),
        borderRadius: 12,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.palette.ambilight.boxshadow
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GetNamePromotionsType = function(i){
  switch (i) {
    case 0:
      return "Promotion";
      case 1:
        return "Chest";
      case 2:
          return "Script";
      case 4:
          return "AutoInvoice";        
    default:
      return "";      
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{minHeight: 250}}>{children}</div>}
    </div>
  );
}

class Promotions extends React.Component{
    constructor(props) {        
        super(props); 
        // const {classes, t} = props;       
        this.state = {
            anchorEl: null,            
            openSelGoogs: false,
            openMenu: false,
            selectedCatalog: null,                                    
            nameError: false,
            nameErrorMess: "",            
            Duration: "0",            
            selectedDate: new Date(),
            promotion: null,
            openDelPromotion: false,
            delId: 0,
            delPromotionName: "",
            rowsPerPage: 10,
            page: 0,
            tabIndex: 0,
            
            Promotions: {},
            promotionsGrid: {
              search: "",
              onlyActive: false,
              page: 0,
              rowsPerPage: 25,
              totalCount: 0
            },

            Chests: {},
            chestsGrid: {
              search: "",
              onlyActive: false,
              page: 0,
              rowsPerPage: 25,
              totalCount: 0
            },

            Scripts: {},
            scriptsGrid: {
              search: "",
              onlyActive: false,
              page: 0,
              rowsPerPage: 25,
              totalCount: 0
            },
            Autoinvoices: {},
            autoinvoicesGrid: {
              search: "",
              onlyActive: false,
              page: 0,
              rowsPerPage: 25,
              totalCount: 0
            },
            
        };
        this.rowsPerPageOptions = [25, 50, 100];
    }

    static contextType = AppContext;

    loadData = async (search, page, rowsPerPage, onlyActive, promotionType) => {
        const { getUser } = this.context;
        getUser().then( (user) => {          
            if(!user) return;
        
            const token = user ? `${user.token_type} ${user.access_token}`: ""; 

            const empty=" ";        
            const url = `/api/v1.0/Promotions/GetPromotionsByType/${search && search.length > 0 ? search : empty}/${page+1}/${rowsPerPage}/${promotionType}/${onlyActive}`;
                        
            fetch(url, { headers: { authorization: token  } } )
            .then((response) => {  
                response.json().then((data) => {

                switch (promotionType) {
                    case 0:
                    {
                        const { promotionsGrid } = this.state;
                        promotionsGrid.search = search;
                        promotionsGrid.onlyActive = onlyActive;
                        promotionsGrid.page = page;
                        promotionsGrid.totalCount = data.TotalCount;
                        promotionsGrid.rowsPerPage = rowsPerPage;
                        this.setState({ Promotions: data, promotionsGrid: {...promotionsGrid}});
                    }
                    break;
                    case 1:
                    {
                        const {chestsGrid} = this.state;
                        chestsGrid.search = search;
                        chestsGrid.onlyActive = onlyActive;
                        chestsGrid.page = page;
                        chestsGrid.totalCount = data.TotalCount;
                        chestsGrid.rowsPerPage = rowsPerPage;
                        this.setState({ Chests: data, chestsGrid: {...chestsGrid}});                
                    }
                    break;
                    case 2:
                    {
                        const {scriptsGrid} = this.state;
                        scriptsGrid.search = search;
                        scriptsGrid.onlyActive = onlyActive;
                        scriptsGrid.page = page;
                        scriptsGrid.totalCount = data.TotalCount;
                        scriptsGrid.rowsPerPage = rowsPerPage;
                        this.setState({Scripts: data, scriptsGrid: {...scriptsGrid}});                
                    }              
                    break;
                    case 4:
                    {
                        const {autoinvoicesGrid} = this.state;
                        autoinvoicesGrid.search = search;
                        autoinvoicesGrid.onlyActive = onlyActive;
                        autoinvoicesGrid.page = page;
                        autoinvoicesGrid.totalCount = data.TotalCount;
                        autoinvoicesGrid.rowsPerPage = rowsPerPage;
                        this.setState({Autoinvoices: data, autoinvoicesGrid: {...autoinvoicesGrid}});                
                    }                
                    break;
                    default:
                    {
                        const {promotionsGrid} = this.state;
                        promotionsGrid.search = search;
                        promotionsGrid.onlyActive = onlyActive;
                        promotionsGrid.page = page;
                        promotionsGrid.totalCount = data.TotalCount;
                        promotionsGrid.rowsPerPage = rowsPerPage;
                        this.setState({Promotions: data, promotionsGrid: {...promotionsGrid}});
                    }
                    break;
                }
                })
            })
            .catch((error) => {
            console.log(error);
            });    
        });      
    };

    componentDidMount() {
        this.props.actions.promotionsType();
        this.loadData("", 0, 25, false, 0);
        this.loadData("", 0, 25, false, 1);
        this.loadData("", 0, 25, false, 2);
        this.loadData("", 0, 25, false, 4);
    }
        
    setOpenSelGoogs = (flag) =>{
      this.setState({openSelGoogs: flag});
    };

    handleClickOK = () => { this.props.actions.getSelectedGoods().then(function (response){ 
      console.log(response);
    });      
      // console.log();
      this.setState({openSelGoogs: false});
      
    };

    handleClickNewPromotion = () => {
      if(this.props.idxPromotionType === -1){
          return;
      }
      this.props.actions.newPromotion();
      // const promotion = {...this.props.promotionstype[this.props.idxPromotionType].Promotion};        
      // promotion.TypeName = this.props.promotionstype[this.props.idxPromotionType].Name;
      // promotion.TypeDescription = this.props.promotionstype[this.props.idxPromotionType].Description;
      // // promotion.Start = moment(promotion.Start).format("YYYY-MM-DD");
      // // promotion.Finish = moment(promotion.Finish).format("YYYY-MM-DD");        
      // this.setState({promotion: promotion});
      // this.props.actions.showDialog(true);
    };

    handleClickEditPromotion = (id) => {     
      this.props.actions.editPromotion(id);
    };

    handleClickCopyPromotion = (id) => {
        if(!id) return; 

        const { getUser } = this.context;
        getUser().then( (user) => {          
            if(!user) return;
            
            const token = user ? `${user.token_type} ${user.access_token}`: ""; 
            const url = `/api/v1.0/promotions/copypromotion/${id}`;
            
            fetch(url, {method: "POST", headers: { authorization: token }})
                .then((response) => {
                    if(response.status === 200){
                        this.handleRefreshPromotions();
                        alert('Copied');                        
                    }
                })
                .catch(error => {                
                    console.log("Error", error);
                });
        });        
    };

    handleClickDlgDelPromotion = (id) => {
      let pname = "";
      for (let i = 0; i < this.props.promotions.length; i++) {
        const el = this.props.promotions[i];
        if(el.Id === id) {
          pname = `${el.Name} ${el.Description}`;
          break;
        }        
      }
      this.setState({ openDelPromotion: true, delId: id, delPromotionName: pname });
    };

    handleDelelePromotion = () => {       
      this.props.actions.delPromotion(this.state.delId);      
      this.setState({ openDelPromotion: false, delPromotionName: "" });
    };

    handleCloseMenu = () => {
        this.setState({ openMenu: false, anchorEl: null });
    };

    handleCloseDelPromotion = () =>{
      this.setState({ openDelPromotion: false, delPromotionName: "" });
    }

    handleToggleMenu = (e) => {        
        this.setState({openMenu: !this.state.openMenu, anchorEl: (this.state.openMenu ? null : e.currentTarget) });  
    };

    handleChoosePromotion = (e, index) => {
        this.props.actions.choosePromotion(index);
        this.setState({openMenu: false, anchorEl: null });        
      // this.setState({openMenu: false, anchorEl: null, idxPromotionType: index, idxPromotionTypeTxt: this.props.promotionstype[index].Name});
    };

    handleChange = (e) => { 
        const errors = {};    
        if(e.target.name === "Name"){
            errors.nameError = false;
            errors.nameErrorMess = "";            
        }
        this.props.actions.updPromotionName(e.target.value, e.target.name);
        // this.setState({ [e.target.name]: e.target.value, ...errors  });        
    };

    handleChangeDuration = (e) => {
        this.props.actions.updPromotionName(e.target.value, e.target.name);
        // this.setState({ [e.target.name]: e.target.value });        
    }

    handleChangeActive = (e) => {                
      this.props.actions.updPromotionName(e.target.checked, e.target.name);
    }

    handleChangeDate = (date, name) => {        
        this.props.actions.updPromotionName(date, name);
    }

    handleClickSavePromotion = (event)  => {
        event.preventDefault();
        if(!this.validate()){
            this.props.actions.savePromotion();            
        }
    }

    selectGoods = () => {
      this.setOpenSelGoogs(true);
    }

    validate = () => {
        const errors = {};
        let hasError = false;
        
        if(this.props.selectedPromotion.Name.length === 0){
            hasError = true;
            errors.nameError = true;
        }
        
        this.setState({ 
            ...this.state, 
            hasError, 
            ...errors 
        });
        
        return hasError;        
    }

    handleClickClose = () => {
      this.props.actions.showDialog(false);
    };
        
    changePage = (page, sortOrder) => {
      console.log("page", page);

      this.setState({page: page, sortOrder});
      this.props.actions.promotionsItemsRequest({ Page: page, RowsPerPage: this.state.rowsPerPage, Refresh: false });
      
      // this.setState({
      //   isLoading: true,
      // });
      // this.xhrRequest(`/myApiServer?page=${page}`, page, sortOrder).then(res => {
      //   this.setState({
      //     isLoading: false,
      //     page: res.page,
      //     sortOrder,
      //     data: res.data,
      //     count: res.total,
      //   });
      // });
    };

    handleChangePage = (event, newPage) => {      
      const {tabIndex} = this.state;
      switch (tabIndex) {
        case 0:
          {
            const {promotionsGrid} = this.state;
            this.loadData(promotionsGrid.search, newPage, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive , 0);
          }
          break;
          case 1:
            {
              const {chestsGrid} = this.state;
              this.loadData(chestsGrid.search, newPage, chestsGrid.rowsPerPage, chestsGrid.onlyActive, 1);
            }          
            break;
          case 2:
            {
              const {scriptsGrid} = this.state;
              this.loadData(scriptsGrid.search, newPage, scriptsGrid.rowsPerPage, scriptsGrid.onlyActive, 2);
            }
            break;
          case 3:
            {
              const {autoinvoicesGrid} = this.state;
              this.loadData(autoinvoicesGrid.search, newPage, autoinvoicesGrid.rowsPerPage, autoinvoicesGrid.onlyActive, 4);
            }
            break;
          default:
            {
              const {promotionsGrid} = this.state;
              this.loadData(promotionsGrid.search, newPage, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive, 0);
            }
          break;
      }
      // this.setState({page: newPage});
      // this.props.actions.promotionsItemsRequest({ Page: newPage, RowsPerPage: this.state.rowsPerPage, Refresh: false });
    };
  
    handleChangeRowsPerPage = event => {             
      let rpp = +event.target.value;

      const {tabIndex} = this.state;
      switch (tabIndex) {
        case 0:
          {
            const {promotionsGrid} = this.state;
            this.loadData(promotionsGrid.search, 0, rpp, promotionsGrid.onlyActive, 0);
          }
          break;
          case 1:
            {
              const {chestsGrid} = this.state;
              this.loadData(chestsGrid.search, 0, rpp, chestsGrid.onlyActive, 1);
            }          
            break;
          case 2:
            {
              const {scriptsGrid} = this.state;
              this.loadData(scriptsGrid.search, 0, rpp, scriptsGrid.onlyActive, 2);
            }
            break;
          case 3:
            {
              const {autoinvoicesGrid} = this.state;
              this.loadData(autoinvoicesGrid.search, 0, rpp, autoinvoicesGrid.onlyActive, 4);
            }
            break;
          default:
            {
              const {promotionsGrid} = this.state;
              this.loadData(promotionsGrid.search, 0, rpp, promotionsGrid.onlyActive, 0);
            }
          break;
      }            
    };

    handleRefreshPromotions = () => {

      // this.loadData("", 0, 10, 0);

      const {tabIndex} = this.state;
      switch (tabIndex) {
        case 0:
          {
            const {promotionsGrid} = this.state;
            this.loadData("", 0, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive, 0);
          }
          break;
          case 1:
            {
              const {chestsGrid} = this.state;
              this.loadData("", 0, chestsGrid.rowsPerPage, chestsGrid.onlyActive, 1);
            }          
            break;
          case 2:
            {
              const {scriptsGrid} = this.state;
              this.loadData("", 0, scriptsGrid.rowsPerPage, scriptsGrid.onlyActive, 2);
            }
            break;
          case 3:
            {
              const {autoinvoicesGrid} = this.state;
              this.loadData("", 0, autoinvoicesGrid.rowsPerPage, autoinvoicesGrid.onlyActive, 4);
            }
            break;
          default:
            {
              const {promotionsGrid} = this.state;
              this.loadData("", 0, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive, 0);
            }
          break;
      }
      
      // this.props.actions.promotionsItemsRequest( { Page: this.state.page, RowsPerPage: this.state.rowsPerPage, Refresh: true });
    };

    handleChangeTabIndex = (event, value) => {
      this.setState({tabIndex: value});
    };

    handleChengeSearchText = (value) => {
            
      if(value && value.length <=2 ){
        return;
      }

      if(!value){
        value = "";
      }
      

      const {tabIndex} = this.state;
      
      switch (tabIndex) {
        case 0:
          {
            const {promotionsGrid} = this.state;
            this.loadData(value, 0, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive, 0);
          }
          break;
          case 1:
            {
              const {chestsGrid} = this.state;
              this.loadData(value, 0, chestsGrid.rowsPerPage, chestsGrid.onlyActive, 1);
            }          
            break;
          case 2:
            {
              const {scriptsGrid} = this.state;
              this.loadData(value, 0, scriptsGrid.rowsPerPage, scriptsGrid.onlyActive, 2);
            }
            break;
          case 3:
            {
              const {autoinvoicesGrid} = this.state;
              this.loadData(value, 0, autoinvoicesGrid.rowsPerPage, autoinvoicesGrid.onlyActive, 4);
            }
            break;
          default:
            {
              const {promotionsGrid} = this.state;
              this.loadData(value, 0, promotionsGrid.rowsPerPage, promotionsGrid.onlyActive, 0);
            }
          break;
      }

    };

    handleFilterSubmit = applyFilters => {
      const {t} = this.props
      const filterList = applyFilters();
      const opt = filterList[1];
      const active = opt.length === 0 ? false : opt[0] === t("promotions.thActive")

      const {tabIndex} = this.state;
      switch (tabIndex) {
        case 0:
          {
            const {promotionsGrid} = this.state;
            this.loadData(promotionsGrid.search, 0, promotionsGrid.rowsPerPage, active, 0);
          }
          break;
          case 1:
            {
              const {chestsGrid} = this.state;
              this.loadData(chestsGrid.search, 0, chestsGrid.rowsPerPage, active, 1);
            }          
            break;
          case 2:
            {
              const {scriptsGrid} = this.state;
              this.loadData(scriptsGrid.search, 0, scriptsGrid.rowsPerPage, active, 2);
            }
            break;
          case 3:
            {
              const {autoinvoicesGrid} = this.state;
              this.loadData(autoinvoicesGrid.search, 0, autoinvoicesGrid.rowsPerPage, active, 4);
            }
            break;
          default:
            {
              const {promotionsGrid} = this.state;
              this.loadData(promotionsGrid.search, 0, promotionsGrid.rowsPerPage, active, 0);
            }
          break;
      }      
    };

    render(){
        const { t, classes, selectedPromotion, promotionstype, idxPromotionType, isLoading, showDlg, locale  } = this.props; 
        const {  openSelGoogs, openMenu, openDelPromotion,  anchorEl, tabIndex,
                 Promotions, Chests, Scripts, Autoinvoices,
                 promotionsGrid, chestsGrid, scriptsGrid, autoinvoicesGrid } = this.state;
        // const today = moment( new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()));                        
        // const theme = useTheme();
        // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        
        const columns = [
          {
            name: "Id",                                         
            options: {
              display: false,
              filter: false,
              sort: false,
             }
          },               
          { name: "Active",
            label: t("promotions.thActive"),
            options: {
            sort: false,
            filter: true,
            filterType: "checkbox",
            filterOptions: {
              names: [t("promotions.thActive")],
              logic(active) {                                
                return active !== true;
              },
            },
            customBodyRender: (value) => {
              return ( 
                value ? <CheckCircleOutlineIcon color="secondary" /> : null
               );                                            
            }
          }
          },
          {
            name: "TwoLetterISORegionName",
            label: t("promotions.thCountry"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value) => {              
                return  <>{countryToFlag(value.toLowerCase())} {value}</>
              }
            }
          },

        //   {
        //     name: "PromotionType",
        //     label: t("promotions.thType"),
        //     options: {
        //       sort: false,
        //       filter: false,
        //       customBodyRender: (value) => {
        //         return ( 
        //           t(`promotions.${GetNamePromotionsType(value)}`)
        //          );                                            
        //       }
        //     }
        //   }, 
          { name: "Start",
            label: t("promotions.thStart"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value) => {
                return ( moment(value).format("DD-MM-YYYY") );                                            
              }
            }
          },
          {
            name: "Finish",
            label: t("promotions.thFinish"),
            options: {
              sort: true,
              filter: true,
              customBodyRender: (value) => {
                return ( moment(value).format("DD-MM-YYYY") );                                            
              }
            }
          },
          
          {
            name: "Code",
            label: t("promotions.thCode"),
            options: {              
              filter: true,
              filterType: "textField",
              sort: true,
            }
          },
          {
            name: "Name",
            label: t("promotions.thDescription"),
            options: {              
              filter: false,
              sort: false,
            }
          },          
          {
            name: "CatalogName",
            label: t("promotions.thCatalog"),
            // options: {
            //   filter: false,
            //   sort: false,
            // }
          },
          
          
          {
            name: "Action",
            label: t("promotions.thActions"),
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta ) => {
                return (
                  <div className={classes.actWidth} >                        
                    <IconButton
                        className={classes.mr}
                        aria-label="Edit promotion"
                        title="Edit item"
                        onClick={() => this.handleClickEditPromotion(tableMeta.rowData[0]) }
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                        aria-label="Copy promotion"
                        title="Copy item"
                        onClick={() => this.handleClickCopyPromotion(tableMeta.rowData[0])}
                    >
                        <span className="material-icons">file_copy</span>
                    </IconButton>

                    {/* 
                    <IconButton
                      aria-label="Remove promotion"
                      style={{ marginRight: "30px" }}
                      onClick={() => this.handleClickDlgDelPromotion(this.props.promotions[tableMeta.rowIndex].Id)}
                    >
                      <ClearIcon />
                    </IconButton> */}

                    {/* <IconButton                                                             
                        onClick={ () => {                              
                          this.props.parentCallback([this.props.items[tableMeta.rowIndex]]);
                          // this.props.parentCallback([{...data}]);
                        }}
                    >
                      <AddIcon />
                    </IconButton> */}

                  </div>
                );
              }
            }
          },          
        ];

        const options = {
          filter: true,
          filterType: "dropdown",
          // serverSide: true,          
          // confirmFilters: true,
          selectableRows: "none",
          download: false,
          print: false,
          viewColumns: false,
          enableNestedDataAccess: ".",
          // responsive: "scroll",
          responsive: "standard",
          pagination: false,
          // count: this.props.count, 
          setCellProps: () => ({
            onDoubleClick: (item, idx ) => {
              console.log(item, idx);
            }
          }),
          setTableProps: () => {
            return {
              padding: "none",                  
              size: "small",
            };
          },
          selectToolbarPlacement: "none",
          // selectableRowsOnClick: true,              
          // rowsSelected: this.state.rowsSelected,
          onRowSelectionChange: (rowsSelected, allRows) => {            
            this.setState({ rowsSelected: allRows
               // allRows.map(row => row.dataIndex) 
            });            
          },
          onSearchChange: this.handleChengeSearchText,
          
          customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
              <div style={{ marginTop: 40}}>
                <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
              </div>
            );
          },
          onFilterChange: (column, filterList, type) => {
            if (type === "chip") {
              var newFilters = () => (filterList);
              console.log("updating filters via chip");
              this.handleFilterSubmit(newFilters);
            }
          },
          // callback that gets executed when filters are confirmed
          // onFilterConfirm: (filterList) => {
          //   console.log("onFilterConfirm");
          //   console.dir(filterList);
          // },

          // onTableChange: (action, tableState) => {
          //   console.log(action, tableState);                        
          //   switch (action) {
          //     case "changePage":
          //       console.log("action changePage.");
          //       this.changePage(tableState.page, tableState.sortOrder);
          //       break;
          //     case "sort":
          //       console.log("action sort.");
          //       // this.sort(tableState.page, tableState.sortOrder);
          //       break;
          //     default:
          //       console.log("action not handled.");
          //   }
          // }              
        };
        
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={locale}>
            <div className={classes.root}>
              {isLoading && (
                <LinearProgress
                  className={classes.progress}
                  classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: classes.linearBarColorPrimary
                  }}
                />
              )}
                            

              <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                <Toolbar className={classes.toolbar}>
                    <ButtonGroup 
                        className={classes.mr}
                        variant='outlined' 
                        color="secondary" 
                        
                        aria-label="Split button"
                    >
                            <Button 
                                onClick={this.handleClickNewPromotion} 
                                margin="normal"
                                startIcon={<AddIcon />}
                            >
                                
                            {promotionstype !== null
                                ? t(`promotions.${promotionstype[idxPromotionType].Name}`) : t("common.Loading")}
                            </Button>
                            
                            <Button aria-owns={ openMenu ? "menu-list-grow" : undefined }
                                aria-haspopup="true"
                                onClick={e => this.handleToggleMenu(e)}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                    </ButtonGroup>
                    <Button 
                        variant='outlined'
                        onClick={()=> this.handleRefreshPromotions()}
                        startIcon={<SyncIcon /> }
                    >
                        {t("promotions.btnRefresh")}
                    </Button>

                </Toolbar>
              </AppBar>

              <Popper
                        open={openMenu}
                        anchorEl={anchorEl}
                        transition>
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              maxHeight: 300,
                              overflow: "auto",
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center buttom"
                            }}>
                            <Paper id="menu-list-grow">
                              <ClickAwayListener
                                onClickAway={() =>
                                  this.handleCloseMenu()
                                }>
                                <List>
                                  {promotionstype.map(
                                    (option, index) => (
                                      <ListItem button key={index}
                                        selected={idxPromotionType === index}
                                        onClick={e => this.handleChoosePromotion(e, index)
                                        }>
                                        <ListItemText
                                          primary={t(`promotions.${option.Name}`)}  
                                          secondary={option.Description}
                                        />
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
              </Popper>
              
              {this.state.selectedCatalog !== null ? (
                <Typography color="textSecondary">
                  {this.state.selectedCatalog.Name}
                </Typography>
              ) : null
              }
                <Paper className={classes.paper}>
                  
                    <Tabs
                      value={tabIndex}
                      onChange={this.handleChangeTabIndex}
                    //   variant="fullWidth" 
                      centered
                      aria-label="order-tabs"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                        <Tab icon={<span className="material-icons">style</span>} label={t(`promotions.${GetNamePromotionsType(0)}`)} />
                        <Tab icon={<span className="material-icons">interests</span>} label={t(`promotions.${GetNamePromotionsType(1)}`)} />
                        <Tab icon={<span className="material-icons">draw</span>} label={t(`promotions.${GetNamePromotionsType(2)}`)} />
                        <Tab icon={<span className="material-icons">dashboard_customize</span>} label={t(`promotions.${GetNamePromotionsType(4)}`)} />
                    </Tabs>
                </Paper>

                    {/* Promotions */}
                    <TabPanel className={classes.tabpanel} value={tabIndex} index={0}>
                      <MUIDataTable
                        // title={t("promotions.title")}
                        data={Promotions.Data}
                        columns={columns}
                        options={options}
                      />

                      <TablePagination
                        rowsPerPageOptions={this.rowsPerPageOptions}
                        component="div"
                        count={promotionsGrid.totalCount}
                        rowsPerPage={promotionsGrid.rowsPerPage}
                        page={promotionsGrid.page}
                        backIconButtonProps={{
                          "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "Next Page",
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />                      
                    </TabPanel>
                    
                    {/* Chests */}
                    <TabPanel className={classes.tabpanel} value={tabIndex} index={1}>
                      <MUIDataTable                      
                        data={Chests.Data}
                        columns={columns}
                        options={options}
                      />
                      <TablePagination
                        rowsPerPageOptions={this.rowsPerPageOptions}
                        component="div"
                        count={chestsGrid.totalCount}
                        rowsPerPage={chestsGrid.rowsPerPage}
                        page={chestsGrid.page}
                        backIconButtonProps={{
                          "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "Next Page",
                        }}                        
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TabPanel>
                    
                    { /* Scripts */}
                    <TabPanel className={classes.tabpanel} value={tabIndex} index={2}>
                      <MUIDataTable                        
                        data={Scripts.Data}
                        columns={columns}
                        options={options}
                      />
                      <TablePagination
                        rowsPerPageOptions={this.rowsPerPageOptions}
                        component="div"
                        count={scriptsGrid.totalCount}
                        rowsPerPage={scriptsGrid.rowsPerPage}
                        page={scriptsGrid.page}
                        backIconButtonProps={{
                          "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "Next Page",
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TabPanel>
                    
                    {/* Autoinvoices */}
                    <TabPanel className={classes.tabpanel} value={tabIndex} index={3}>
                      <MUIDataTable
                        data={Autoinvoices.Data}
                        columns={columns}
                        options={options}
                      />
                      <TablePagination
                        rowsPerPageOptions={this.rowsPerPageOptions}
                        component="div"
                        count={autoinvoicesGrid.totalCount}
                        rowsPerPage={autoinvoicesGrid.rowsPerPage}
                        page={autoinvoicesGrid.page}
                        backIconButtonProps={{
                          "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "Next Page",
                        }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />

                    </TabPanel>
                

              {/* Modal dialog promotion */}
              {selectedPromotion && (  
                <Dialog
                    fullScreen
                    open={showDlg}
                    onClose={this.handleClickClose}
                    TransitionComponent={Transition} 
                >
                    <Promotion promotion={selectedPromotion} promotiontype={promotionstype[idxPromotionType]} ></Promotion>                  
                </Dialog>
              )}

              {/* Modal dialog delete promotion */}
              <Dialog
                open={openDelPromotion}
                onClose={this.handleCloseDelPromotion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Delete promotion?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {this.state.delPromotionName}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDelelePromotion} color="secondary">
                    Yes
                  </Button>
                  <Button onClick={this.handleCloseDelPromotion} color="primary" autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Modal dialog select Googs */}
              <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={openSelGoogs}
                onClose={() => this.setOpenSelGoogs(false)}
                aria-labelledby="sel-goods-dialog-title"
                >
                <DialogTitle id="sel-goods-dialog-title">
                  <Typography>Select goods dialog</Typography>                  
                  <IconButton aria-label="close" className={classes.closeButton} onClick={() => this.setOpenSelGoogs(false)}>
                    <CloseIcon />
                  </IconButton>                  
                </DialogTitle>
                
                <DialogContent>
                  <RefGoods checked={true} ></RefGoods>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setOpenSelGoogs(false)} color="primary">
                    Close
                  </Button>
                  <Button onClick={() => this.handleClickOK()} color="primary">
                    OK
                  </Button>
              </DialogActions>
              </Dialog>

            </div>
          </MuiPickersUtilsProvider>
        );
    }
}

export default withTranslation()(withWidth()(withRouter(withStyles(styles)(
    connect(    
        state => state.promotionItemsRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionPromotionItemCreators, actionGoods ), dispatch)
        }}        
    )(Promotions))
)));
