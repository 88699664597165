import React from 'react';
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
         createStyles({             
             root: {    
                color: "#fff",                
                width: "100%",
                height: theme.spacing(4),
                minHeight: theme.spacing(4),
                textShadow: "1px 1px 2px #848484",    
                background: "linear-gradient(338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)"
              },
              topLineTxt: {
                "& *":{
                  color: "#fff",
                  margin: 0,
                  padding: theme.spacing(1),
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                }
              },
             
         })
);

const Topline = (props) => {
  const classes = useStyles();
  const { html } = props;

  return (
    
    <Typography 
        className={classes.root} 
        align="center" 
        display="block" 
        component="div" 
        variant="body2" 
        noWrap 
    >              
        <div className={classes.topLineTxt}  dangerouslySetInnerHTML={{__html: html }}></div> 
    </Typography>             
  )
}

Topline.propTypes = {
    html: PropTypes.string.isRequired,
}

export default Topline