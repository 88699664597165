import React, { useEffect, useState, useContext, useCallback } from 'react';
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography, Avatar, CircularProgress, Link} from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';


import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from '@material-ui/core/Slide';

import EmojiNatureOutlinedIcon from '@material-ui/icons/EmojiNatureOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Dialog from "@material-ui/core/Dialog";
// eslint-disable-next-line no-unused-vars
import { DialogActions, DialogContent, DialogTitle } from "../CustomDialog";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';


import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import Badge from '@material-ui/core/Badge';

import { grey, orange } from '@material-ui/core/colors';

// eslint-disable-next-line no-unused-vars
import { AppContext, AppConsumer } from "../../providers/appProvider";
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined';

// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";
// eslint-disable-next-line no-undef
const cdn = `${process.env.REACT_APP_CDN}Pictures/`;

const imgMdSize =  "183x183";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {        
        margin: theme.spacing(1),                
        borderRadius: 12,
        padding: theme.spacing(1,2),
        boxShadow: theme.palette.ambilight.boxshadow
    },
    box: {
        display: "block", 
        width: "100%", 
        marginBottom: theme.spacing(1)
    },
    imgbox: {        
        margin: "0 auto",
        // border: "1px solid #e1e0e0ab",
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginBottom: theme.spacing(1),
        cursor: "pointer"
        // "&:hover": {
        //   cursor: "pointer"
        // },
    },
    wrapper: {        
        position: 'relative',
    },
    txtbox: {
        display: "block", 
        width: "100%", 
        textAlign: "center",
        // inlineSize: theme.spacing(5),
        height: theme.spacing(5)
    },

    infoButton: {
        position: "absolute",
        right: theme.spacing(1),        
      },
    fabProgress: {
        // color: green[500],
        color: '#209eca',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
    },
    icon: {
        fontSize: "2.5em"
    },
    cicle: {
        width: theme.spacing(12),
        height: theme.spacing(12),        
        // border: "4px solid #e1e0e0b5",
        border: `4px solid ${theme.palette.divider}`,
        padding: "4px",
        borderRadius: "50%",
        boxShadow: theme.palette.ambilight.boxshadow
    },
    
    pageimg: {
        // backgroundColor: lightBlue[600],
        // backgroundColor: "transperend",
        padding: theme.spacing(2),
        width: theme.spacing(16),
        height: theme.spacing(16),
        // border: "4px solid #e1e0e0b5",
        border: `4px solid ${theme.palette.divider}`,
        boxShadow: theme.palette.ambilight.boxshadow
    },

    large: {
        color: '#fff', //theme.palette.getContrastText(lightBlue[200]),
        // backgroundColor: lightBlue[200],
        padding: theme.spacing(0), 
        width: theme.spacing(10),
        height: theme.spacing(10),
        fontWeight: "bold",
        fontSize: "2em",
        textShadow: "rgb(0 0 0 / 30%) 1px 1px"
    },
    largebig: {
        color: '#fff',// theme.palette.getContrastText(lightBlue[200]),
        // backgroundColor: lightBlue[200],
        padding: theme.spacing(),
        width: theme.spacing(24),
        height: theme.spacing(24),
        fontSize: "6em",        
        fontWeight: "bold",
        textShadow: "rgb(0 0 0 / 30%) 1px 1px",
        
    },

    completed: {
        // backgroundColor: lightBlue[600],
    },

    ciclebig: {
        width: theme.spacing(28),
        height: theme.spacing(28),        
        // border: "10px solid #e1e0e0b5",
        border: `10px solid ${theme.palette.divider}`,
        padding: 6,
        borderRadius: "50%",
        
    },
        
    unused: {
        color: grey[200],
        backgroundColor: grey[500],
    },
    imgwrap: {
        height: 75
    },
    prodimg: {
        width: "100%",
        padding: 12,
    }
  })
);

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//       backgroundColor: red[500],
//       color: red[500],
//       boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//       '&::after': {
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         borderRadius: '50%',
//         animation: '$ripple 1.2s infinite ease-in-out',
//         border: '1px solid currentColor',
//         content: '""',
//       },
//     },
//     '@keyframes ripple': {
//       '0%': {
//         transform: 'scale(.8)',
//         opacity: 1,
//       },
//       '100%': {
//         transform: 'scale(2.4)',
//         opacity: 0,
//       },
//     },
// }))(Badge);

const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  }

const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 28,
      height: 28,
      backgroundColor: orange[700],
      textShadow: "rgb(0 0 0 / 30%) 1px 1px",
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

// const percent = (progress, count) => {
//     if(count === 0) return 0;
//     const val = parseInt(100 * progress/count);
//     return val;    
// }

export const Challenges = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const app = useContext(AppContext);
    const { lang, country } = app;
    const [token, setToken] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [showDlgInfo, setShowDlgInfo] = useState(false);
    const [dlgRemun, setDlgRemun] = useState(false);
    const [dlgCondition, setDlgCondition] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const [challengePage, setChallengePage] = useState(null);
    const [selected, setSelected] = useState(null);
    const [products, setProducts] = useState([]);
    
    const [conditionTxt, setConditionTxt] = useState(CreateNewFolderOutlined);

    const handleClick = (idx) => {        
        setSelected(challengePage.Challenges[idx]);
        setShowDlgInfo(true);        
    };

    const handleClose = () => {
        setShowDlgInfo(false);
    };

    const handleCloseDlgRemun = () => {
        setDlgRemun(false);
    };

    const handleShowDlgRemun = () => {
        setDlgRemun(true);
    };

    const handleCloseDlgCondition = () => {
        setDlgCondition(false);
    };

    const handleShowDlgCondition = () => {
        setDlgCondition(true);
    };

    const getNameTarget = (item) => {        
        if(item.Progress  > 0 && item.Progress < 100 ){
            if(item.NameTarget !== ''){
                return item.NameTarget.replace(/[^\s]*/, `${parseInt(Math.round(item.Count * item.Progress/100))} із`);
            }
            return item.NameTarget;
        } 

        if(parseInt(item.Progress) === 100){
            return item.NameTarget.replace(/[^\s]*/, '');
        }

        return item.NameTarget;
    };


    // const handleChangeAgree = (event) => {
    //     selected.Agree = event.target.checked;
    //     setSelected({...selected});        
    // };


    const fetchData = useCallback( async () => {
        if(token){
            const url = `/api/v1.0/Challenges`;
            const urlProducts = `/api/v1.0/CatalogItem/GetCatalogItemsWithCoins`;
            // const urlCondition = '/api/v1.0/content/ChallengesCondition/false/uk';
            const urlCondition = `/api/v1.0/page/content/ChallengesCondition/${lang}/${country}`;

            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Accept", "application/json");
            headers.append("Authorization", `Bearer ${token}`);            
            
            fetch(url, { method: "GET", headers: headers })      
                .then(response => response.json())
                    .then(data => {                          
                        if(data){
                            console.log('Challenges',data);
                            setChallengePage(data);
                            
                            if(data.CoinRatingDescription.length > 0 && data.CoinRatingDescription !== "0" ){
                                console.log('data.CoinRatingDescription', data.CoinRatingDescription);
                                setShowInfo(true);
                            }
                            
                        }                    
                    });

            fetch(urlProducts, { method: "GET", headers: headers })      
                .then(response => response.json())
                    .then(data => {                          
                        if(data){
                            // console.log('Products',data);
                            setProducts(data);
                        }                    
                    });

            fetch(urlCondition, { method: "GET", headers: headers })      
                .then(response => response.json())
                    .then(data => {                          
                        if(data){
                            if(data){
                                setConditionTxt(data.LocalValues[0]);
                            }
                            // console.log('ChallengesCondition', data);                                
                        }                    
                    });
        }
    }, [country, lang, token]);

    useEffect( () => {
        function fetchUser() {
            app.getUser().then(user => {
                let _token = null;
                if (user != null) {                                        
                    _token = user.access_token;
                }                
                setToken(_token);                
                fetchData();                                    
            });
        }        
        fetchUser();        
    }, [app, fetchData]);

  return (
  <AppConsumer> 
    {() => (
        <Paper className={classes.root}>
            {challengePage && 
            <>  
                <div style={{ position: 'sticky' }}>
                    <IconButton aria-label="info" className={classes.infoButton} onClick={handleShowDlgCondition} >
                        <InfoOutlinedIcon />
                    </IconButton>
                    <Typography component="h1" variant='subtitle1' display="block" align='center' style={{marginBottom: 16}} >
                        {challengePage.Title}
                    </Typography>
                </div>

                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Avatar 
                            src={`${host}${challengePage.Image}`} className={classes.pageimg} 
                            onClick={() => setShowInfo(true)}
                        >
                        </Avatar>
                    </Grid>
                </Grid>
                
                <Typography component="h2" variant='subtitle1' display="block" align='center' style={{marginBottom: 4, marginTop: 16}} >
                    Виконано {challengePage.Completed} із {challengePage.Count}
                </Typography>
                
                {challengePage.RemainingCoins > 0 && 
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item style={{ paddingRight: 4}}>
                        <Typography variant="h4" display="block" align='right' style={{color: orange[700]}}>
                            {challengePage.RemainingCoins}    
                        </Typography>                        
                    </Grid>
                    <Grid item style={{ padding: 0}}>
                        <SmallAvatar > 
                            <Typography variant='caption'></Typography>
                        </SmallAvatar>
                    </Grid>
                    <Grid item>
                        {t("challenges.RemainingCoins")}
                    </Grid>
                </Grid>
                }

                {challengePage.UsedCoins > 0 && 
                    <>
                        {/* <Typography display='block' align='center' gutterBottom><small>Монети, що залишилися</small> {challengePage.RemainingCoins}</Typography>  */}
                        <Typography display='block' align='center' gutterBottom>{challengePage.CollectedCoins} <small>{t("challenges.CollectedCoins")}</small></Typography>
                        <Typography display='block' align='center' gutterBottom>{challengePage.UsedCoins} <small>{t("challenges.UsedCoins")}</small></Typography>
                    </>
                }


                <Typography component="div" display="block" align='center' style={{marginBottom: 32, marginTop: 16}} >
                    <Button onClick={handleShowDlgRemun} variant="contained" color="primary" startIcon={<EmojiNatureOutlinedIcon />} style={{ paddingLeft:64, paddingRight:64}}>
                        {t("challenges.Torewards")}
                    </Button> 
                </Typography>
                
                <Typography component="h2" variant='subtitle1' display="block" align='center' style={{marginBottom: 16, marginTop: 16}} >
                    {challengePage.Subtitle}
                </Typography>

                <Grid container direction="row" spacing={0}>
                    {challengePage.Challenges.map( (ch, idx) => (
                        <Grid key={ch.Id} item xs={4} sm={3} md={2} lg={1} > 
                            <div className={classes.box}>
                                <div className={classes.imgbox} onClick={()=>handleClick(idx)} >
                                    <div className={classes.wrapper}>
                                        <div className={classes.cicle}>
                                            { Math.round(ch.Progress) === 100 ?                                                
                                                <Avatar src={`${host}${ch.ImageCompleted}`} className={clsx(classes.large, classes.completed)} ></Avatar>
                                                :
                                                <Avatar src={`${host}${ch.ImageProgress}`} alt={`${ch.PrizePerformance}`} className={clsx(classes.large)} ></Avatar>
                                            }
                                        </div>
                                        
                                        <CircularProgress variant="determinate" size={96} thickness={2} className={classes.fabProgress}                                         
                                            value={ ch.Progress } 
                                        />

                                        {/* {ch.PrizePerformance !== 0 &&
                                        <SmallAvatar alt={`${ch.PrizePerformance}`} style={{ zIndex: 2, position: "absolute", bottom: 0, right: 8}} >                                            
                                            <Typography variant='caption'>
                                                {ch.PrizePerformance}
                                            </Typography>                                        
                                        </SmallAvatar>
                                        }                                         */}
                                    </div>
                                </div>
                                
                                <Typography className={classes.txtbox} variant='body2'>
                                    {/* {ch.Name} - { ch.Progress}% */}
                                    {ch.Name}
                                </Typography>                                
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </> 
            }

            { challengePage &&             
            <Snackbar 
                anchorOrigin={{ vertical: "top", horizontal: "center" }} 
                onClose={() => setShowInfo(false)}
                open={showInfo} autoHideDuration={5000} 
                TransitionComponent={TransitionUp}
            >
                    <Alert variant="filled" severity="info" onClose={()=> setShowInfo(false)} >
                        {challengePage.CoinRatingDescription}
                    </Alert>
            </Snackbar>
            }

            <Dialog onClose={handleCloseDlgRemun} aria-labelledby="info-dialog-title" open={dlgRemun} fullWidth={true} maxWidth="lg" fullScreen={fullScreen} >
                <DialogTitle onClose={handleCloseDlgRemun}>Обери свою винагороду</DialogTitle>
                <DialogContent style={{minHeight: 300}}>
                    <Grid container direction="row" spacing={2}>
                        {products.map((product,idx) => (
                            <Grid key={idx} item xs={12}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={4} sm={4}>
                                        <div className={classes.imgwrap}>
                                            <div className="hexagon">
                                                <div>
                                                    <Link  href={`/product/${product.Code}`} variant="body2">
                                                        <img className={classes.prodimg} alt={product.LocalName} src={`${cdn}${imgMdSize}/${product.Image}`} />
                                                    </Link>                                                    
                                                </div>                                                
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8} sm={8}>
                                        <Typography variant='body2'>{product.LocalName}</Typography>
                                        <Typography variant='body2'>Кількість монет: <b style={{fontSize: 16, color: orange[700]}}>{product.PurchasePrice}</b></Typography>
                                        
                                        
                                        {/* <Typography variant='body2'>{product.Code}</Typography> */}                                                                                
                                    </Grid>
                                </Grid>
                            </Grid>
                        )) }
                    </Grid>                        
                </DialogContent>

                <DialogActions>
                    {/* <Button color="primary" startIcon={<EmojiNatureOutlinedIcon />}>До винагород</Button>  */}
                    <Button onClick={handleCloseDlgRemun}>{t("buttons.Close")}</Button>                    
                </DialogActions>
            </Dialog>

            <Dialog onClose={()=>setShowDlgInfo(false)} aria-labelledby="info-dialog-title" open={showDlgInfo} fullWidth={true} maxWidth="lg" fullScreen={fullScreen} >
                <DialogTitle onClose={handleClose}>
                    &nbsp;
                </DialogTitle>
                <DialogContent style={{minHeight: 300}} >
                    {selected && 
                        <>
                            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                                <Grid item>
                                <div className={classes.wrapper}>
                                        <div className={classes.ciclebig}>
                                        { Math.round(selected.Progress) === 100 ?                                                
                                                <Avatar src={`${host}${selected.ImageCompleted}`} className={clsx(classes.largebig, classes.completed)} ></Avatar>
                                                :
                                                <Avatar src={`${host}${selected.ImageProgress}`} alt={`${selected.PrizePerformance}`} className={clsx(classes.largebig)} ></Avatar>
                                            }
                                        </div>

                                        <CircularProgress variant="determinate" size={224} thickness={2} className={classes.fabProgress} 
                                            value={selected.Progress} 
                                            // value={ percent(selected.Progress,selected.Count) } 
                                        />
                                        {/* <Avatar alt={`${selected.PrizePerformance}`} style={{ zIndex: 2, position: "absolute", bottom: 8, right: 32, backgroundColor: orange[700],  border: `2px solid ${theme.palette.background.paper}`, textShadow: "rgb(0 0 0 / 30%) 1px 1px"  }} >
                                            <Typography variant='body2'>
                                                    {selected.PrizePerformance}
                                                </Typography>                                                
                                        </Avatar> */}
                                    </div>
                                </Grid>
                            </Grid>

                            <Typography variant='h4' display="block" align="center" gutterBottom style={{ marginTop: 32}}>
                                {selected.Name}
                            </Typography>
                            <Typography display="block" align="center" gutterBottom> 
                                {getNameTarget(selected)}
                            </Typography>

                            <Typography component="div" display="block" align="center" gutterBottom>                            
                                <div dangerouslySetInnerHTML={{__html: selected.Description }}></div>                                
                            </Typography>

                            <Typography variant='h5' display='block' align='center' gutterBottom>
                                {selected.PrizePerformanceTitle}
                            </Typography>

                            { selected.PageRuleUrl &&
                            <Typography display="block" align='right' style={{marginTop: 32}}>
                                <Link target='blank' href={selected.PageRuleUrl ? selected.PageRuleUrl: "#" } variant="body2">
                                    Правила
                                </Link>
                                {/* <Button target='blank' href={selected.PageRuleUrl ? selected.PageRuleUrl: "#" } color="primary">
                                    Правила
                                </Button>                                 */}
                            </Typography>
                            }

                            {selected.Count === selected.Progress && 
                            <Typography display="block" align="center" style={{marginTop: 32}}>
                                <Button target='blank' href="intent://instagram.com/_u/jerelia_ua/#Intent;package=com.instagram.android;scheme=https;end" color="primary">
                                    Instagram
                                </Button>
                            </Typography>
                            }
                            

                            {/* <FormControlLabel
                                    control={<Switch checked={selected.Agree} onChange={handleChangeAgree} name="Agree" color="primary" />}
                                    label="Приймаю участь"
                                /> */}
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setShowDlgInfo(false)}>{t("buttons.Close")}</Button>                    
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleCloseDlgCondition} aria-labelledby="condition-dialog-title" open={dlgCondition} fullWidth={true} maxWidth="lg" fullScreen={fullScreen} >
                <DialogTitle onClose={handleCloseDlgCondition}>
                    {conditionTxt && <>{conditionTxt.Title}</>}
                </DialogTitle>
                <DialogContent style={{minHeight: 300}}>
                    {conditionTxt && 
                        <div dangerouslySetInnerHTML={{__html: conditionTxt.Value }} ></div>
                    }

                    {/* {conditionTxt && 
                        <div dangerouslySetInnerHTML={{__html: conditionTxt.Value.replace(/(<? *script)/gi, "illegalscript")}} ></div>
                    } */}
                </DialogContent>
                <DialogActions>                    
                    <Button onClick={handleCloseDlgCondition}>{t("buttons.Close")}</Button>                    
                </DialogActions>
            </Dialog>

        </Paper>
    )}
  </AppConsumer>
  )
}

