import { AppTheme } from "../../../AppTheme";
// import blue from "@material-ui/core/colors/blue";
import {purple, lightBlue, green, orange} from "@material-ui/core/colors";



const appThemeOptions = {
  [AppTheme.LIGHT]: {
    palette: {
      type: "light",
      primary: {
        supperdark: "#135c75",
        dark: "#166e8d",
        main: "#209eca",
        light: "#4cb1d4",
      },
      secondary: {
        dark: "#c73000",
        main: "#ff3d00",
        light: "#b4c372",
      },
      success: {
        dark:  '#00a152',// "#aec73f",
        main:  '#66bb6a', //"#bbd05e",
        light: '#33eb91'//"#81c784",
      },
      background: {
        default: "#f4f5fa"
      },
      appbar: {
        // background: blue[700],
      },
      appbarsecond: {
        background: "linear-gradient(180deg, rgba(245,251,255,1) 0%, rgba(254,254,254,1) 100%)",        
      },      
      footer: {
        background: "#f5f5f5"
      },
      ambilight: {
        boxshadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'
      }
    },
    overrides: {
      MUIDataTable: {
        paper: {
          boxShadow: "none",
        }
      },
      TextField: {
        root: {
          borderRadius: "8px"
        }
      },
      
      // MuiDrawer: {
      //   paper: {
      //     // background: "#064c9c",
      //     background: "linear-gradient(338deg, rgba(24,166,242,1) 4%, rgba(29,92,117,1) 100%)",
      //     // this is where magic happens
      //     "& *": { color: "rgba(255, 255, 255, 0.8)" }
      //   }
      // },
      
      // MuiPaper: {
      //   root: {
      //     backgroundColor: "#fff"
      //   }
      // },
    },
    typography: {
      useNextVariants: true
    }
    // "@global": {
    //     body: {
    //       fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    //     },
    //   },

    // shadows: ["none"],
  },
  [AppTheme.DARK]: {
    palette: {
      type: "dark",
      primary: {
        supperdark: "#090c10",
        dark: lightBlue[800],
        main: lightBlue[600],
        light: lightBlue[200],
        contrastText: "#dedede"
      },
      secondary: {
        dark: orange[700],
        main: orange[500],
        light: orange[200],
      },
      appbar: {
        background: "#212121"        
      },
      appbarsecond: {
        // background: "#383838"
        // background: grey[900]
        background: "linear-gradient(270deg, rgb(45 46 46) 0%, rgb(0 0 0) 100%)"
      },
      footer: {
        background: "#232323"
      },
      ambilight: {
        boxshadow: '0 8px 10px rgb(54 74 178 / 10%), 0 6px 30px rgb(54 74 178 / 2%), 0 16px 24px rgb(54 74 178 / 2%)'
      }
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#1e1f21"
        }
       },

    MUIDataTable: {
      paper: {
        boxShadow: "none",
      }
    },
    
    // MuiDrawer: {
    //   paper: {
    //       background: "#18202c",
    //       // this is where magic happens
    //       "& *": { color: "rgba(255, 255, 255, 0.7)" }
    //     }
    // }
    },
    typography: {
      useNextVariants: true
    }
    // "@global": {
    //     body: {
    //       fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    //     },
    //   },
  }
};

export default appThemeOptions;
