import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { actionRegister } from "../../store/Register";
import TextField from "@material-ui/core/TextField";
import {Grid, Paper} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withCookies } from "react-cookie";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import {lightBlue, deepPurple, grey, green } from '@material-ui/core/colors';

import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
// import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";


// const testMode = process.env.REACT_APP_TEST_MODE;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  
  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{2})(\d{3})(\d{4})$/);
  
  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = (match[1] ? "380 " : "");    
    return [intlCode, match[2], " (", match[3], ") ", match[4], "-", match[5]].join("")
  }
  return null;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {        
      padding: theme.spacing(2,8),
    },    
  },
  paper: {
    padding: theme.spacing(3,2),
    textAlign: "center",    
    minHeight: theme.spacing(8),
    borderRadius: theme.spacing(2),
    boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  },  
  paperHeader: {
    padding: theme.spacing(1),
    textAlign: "center",        
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: 1,
    marginTop: -17,
    zIndex: 1,
  },
  labelProgress: {    
    position: 'absolute',
    top: '50%',
    left: 0,
    width: 34,
    textAlign: 'center',
    marginTop: -10,
    zIndex: 2,
  },

  btn: {
    borderRadius: theme.spacing(3)
  },
  btnSecond: {
    borderRadius: theme.spacing(3),
    textTransform: "none",
    color: grey[700]
  },
  line1: {
    fontSize: "18px"
  },
  line2: {
    fontSize: "14px"
  },
  line3: {
    fontSize: "18px"
  },

  btnSendAgain: {
    textTransform: "none",
    color: grey[700]
  },
  
  btnViber: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    "&:hover": {
      backgroundColor: deepPurple[400],
    },
  },
  btnTelegram: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[500],
    },
  },
  
});

class RegisterConsultantMFA extends Component {
  constructor(props) {
    super(props);
    this.state = {        
        isLoading: false,
        verifyCode: "",
        verifycodeError: false,
        verifycodeErrorTxt: "",
        variantSanck: "error",
        showSnack: false
    };
  }

  componentDidMount() {  
    window.scrollTo({top: 0, behavior: "smooth"});    
    this.props.getSMSCode();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, verifycodeError: false, verifycodeErrorTxt: "" });
  };

  handleVerify = () => {
    const {t, smsCode} = this.props;
    const {verifyCode} = this.state;

    if(smsCode < 0) {
      this.setState({ showSnack: true, verifycodeError: true, verifycodeErrorTxt: "Bad sms code!" });
      return;      
    }
    if(parseInt(verifyCode) !== smsCode){
      this.setState({ variantSanck: "error", showSnack: true, verifycodeError: true, verifycodeErrorTxt: t("register.WrongSSMCode") });
      return;
    }
    this.props.history.push("/register_consultant_success");
  };

  handelCloseSnack = () => {
    this.setState({ showSnack: false});
  };

  handleResendSMS = () => {
    const {t} = this.props;

    this.props.getSMSCode();
    this.setState({ variantSanck: "success", showSnack: true, verifycodeError: true, verifycodeErrorTxt: t("register.SandAgain") });
  };

  render(){
    // eslint-disable-next-line no-unused-vars
    const { classes, t,
            PhoneNumber, smsCode, isLoading, maxSmsAttempts, timeleft,
          } = this.props;
    const { verifyCode, verifycodeError, verifycodeErrorTxt, showSnack, variantSanck } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>

        <Typography variant="h5" gutterBottom align="center" className={classes.line1}>{t("register.registerConsultantMFALine1")}</Typography>
        <Typography variant="h5" gutterBottom align="center" className={classes.line2}>
            <div dangerouslySetInnerHTML={{__html: t("register.registerConsultantMFALine2", {phone: formatPhoneNumber(PhoneNumber.startsWith("380") ? PhoneNumber : `38${PhoneNumber}`) })}}></div>        
          </Typography>
        <Typography variant="h5" gutterBottom align="center" className={classes.line2}>{t("register.registerConsultantMFALine3")}</Typography>

        <Typography variant="h5" gutterBottom align="center" className={classes.line3}>{t("register.registerConsultantInputSMSCode")}</Typography>
        
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 16}}>
          <Grid item xs={10} sm={3}>
            <TextField                    
              label={t("register.VerifyCode")}
              type="text"
              name="verifyCode"                        
              variant="outlined"
              autoComplete="off"
              required                                                                                         
              error={verifycodeError}
              helperText={verifycodeErrorTxt}
              fullWidth={true}
              margin="normal"
              value={verifyCode}
              onChange={this.handleChange}
            />
          </Grid>
          
          {/* {
          testMode && smsCode > 0 &&
          <Grid item xs={10} sm={3}>
            <Typography color="secondary" variant="caption">TEST MODE: {smsCode}</Typography>
          </Grid>
          } */}
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 16}}>
          <Grid item xs={11} sm={3}>
            <div className={classes.wrapper}> 
              <Button disabled={smsCode < 0} fullWidth className={classes.btn}
                variant="contained" color="primary"
                onClick={this.handleVerify}
              >
                {t("register.btnConfirmCode") }
              </Button>        
              { smsCode < 0 && isLoading &&  
                <CircularProgress size={34} className={classes.buttonProgress} /> }
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={11} sm={3}>
            <div className={classes.wrapper}> 
              <Button fullWidth className={classes.btnSecond} disabled={isLoading || maxSmsAttempts <= 0 || timeleft > 0}
                onClick={this.handleResendSMS} variant="outlined" >
                  {t("register.SendCodeAgain") } 
              </Button>
              { timeleft > 0 &&
                <>
                  <span className={classes.labelProgress}>{timeleft}</span>
                  <CircularProgress size={34} className={classes.buttonProgress} />
                </>
              }
            </div>
            <Typography variant="caption" display="block" align="center" gutterBottom>
              {t(  maxSmsAttempts > 1 ? "register.Attempts_other": "register.Attempts_one" , {attempts: maxSmsAttempts}) }
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={11} sm={3}>
            <Button fullWidth className={classes.btnSecond} variant="outlined"
                onClick={() => this.props.history.push("/register_consultant")} >
                {t("register.ChangeNumber") }
            </Button>
          </Grid>
        </Grid>
        
        <br />
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton className={classes.btnViber} aria-label="viber" target="_about" href="viber://pa?chatURI=JereliaSupport">
              <ViberIcon fontSize="small" width={24} height={24} />
            </IconButton>
            <IconButton className={classes.btnTelegram} aria-label="telegram" target="_about" href="https://t.me/JR_support_Bot">
              <TelegramIcon fontSize="small" width={24} height={24} />
            </IconButton>
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            <Typography variant="body2" >{t("register.ContactSupport")}</Typography>
          </Grid>
        </Grid>

        <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={showSnack}
            autoHideDuration={10000}
            onClose={this.handelCloseSnack}        
          >
          <Alert onClose={this.handelCloseSnack} severity={variantSanck}>
            {verifycodeErrorTxt}
          </Alert>
        </Snackbar>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(withWidth()(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
          state => state.register,
          dispatch => bindActionCreators(actionRegister, dispatch)
      )(withCookies(RegisterConsultantMFA))
    )
  )
));