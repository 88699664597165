import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef, useContext, useCallback } from "react";
import PropTypes from 'prop-types';
import { AppContext, AppConsumer } from '../../../providers/appProvider';
import moment from "moment";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors';
import { useTranslation } from "react-i18next";
import { AppBar,Toolbar, Box, Button, Grid, Chip, Typography, CircularProgress, Paper,
         TextField, FormControlLabel, Checkbox, IconButton, InputAdornment, Select, MenuItem, InputLabel,
         Accordion, AccordionSummary, AccordionDetails, Switch, FormControl, 
         RadioGroup, Radio, FormGroup, FormLabel, Divider,
         Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TranslateIcon from '@material-ui/icons/Translate';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloseIcon from '@material-ui/icons/Close';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { countryToFlag } from "../../../store/countries";
import Files from "../../Files/Files";

// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appbar: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,
            
        },
        accordion: {            
            borderRadius: theme.spacing(1),
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            '&.MuiAccordion-root:before': {
                background: 'none'
            },
        },
        paper: {            
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            
        },

        section: {
            fontWeight: 400
        },
        wrapper: {
            marginRight: theme.spacing(1/2),
            position: 'relative',
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -8,
            left: -12,
            zIndex: 1,
        },
        button: {
            marginRight: theme.spacing(1)
        },
        role: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        accordiondetails: {
            position: "relative",
            display: "block",
            width: "100%"
        },
        imgpreview: {
            width: "100%",
            maxWidth: "100%"
        },
        country: {
            width: 202,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'            
        },
        lbInfo: {
            fontSize: ".9em",            
            padding: theme.spacing(1/8, 1),
            marginRight: theme.spacing(1),
            backgroundColor: "#e8e8e863",
            borderRadius: "6px"
          },
          formControl: {
            marginRight: theme.spacing(2),
            width: 120,
          },
          selectEmpty: {
            marginTop: theme.spacing(2),
          },
          inputLabel: {
            padding: theme.spacing(0, 1),
            backgroundColor: theme.palette.background.paper,
          }
    })
);

function a11yProps(index) {
    return {
        id: `cnt-tab-${index}`,
        'aria-controls': `cnt-tabpanel-${index}`,
    };
}

const DialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>

            {onClose ? (
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`html-tabpanel-${index}`}
            aria-labelledby={`html-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit'];
const colors = ['initial', 'inherit','primary', 'secondary', 'textPrimary', 'textSecondary', 'error'];
const aligns = ['inherit', 'left', 'center', 'right', 'justify'];
const displays = ['initial', 'block', 'inline'];

const TextEditor = forwardRef( function TextEtitor (props, ref)  {
    const { article, langs, typeblock, roles, token, onSave, keyDisabled } = props;
    
    const classes = useStyles();
    const { t } = useTranslation();
    const app = useContext(AppContext);
    const {lang } = app;
    
    const [doc, setDoc] = useState();
    const [open, setOpen] = useState(false);
    
    const [inroles, setInRoles] = useState(article.Roles ? article.Roles.split(',') : []);
    const [expandedAttr, setExpandedAttr] = useState(false);
    const [expandedCountry, setExpandedCountry] = useState(false);
    const [expandedCommon, setExpandedCommon] = useState(true);

    const [langTab, setLangTab] = useState(0);    
    const [loading, setLoading] = useState(false);
    const [localValue, setLocalValue] = useState();
    const [countries, setCountries] = useState([]);
    const [variant, setVariant] = useState(article.Props.variant);
    const [color, setColor] = useState(article.Props.color);
    const [align, setAlign] = useState(article.Props.align);
    const [display, setDisplay] = useState(article.Props.display);
    
    const editor = useRef();
    
    useImperativeHandle(ref, () => ({
        updateData() {            
            updateDoc(langTab).then(                
                Save()
            );            
        },        
    }));

    const Save = () => {            
            const URL =  "/api/v1/content";
            fetch(URL, {
                method: doc.Id === 0 ? "POST" : "PUT",
                credentials: "include",
                headers: {
                    authorization: token,              
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",                
                },
                body: JSON.stringify(doc)
            })
            .then((response) => {
              if(response.status === 200){
                response.json().then((data) => {                    
                    console.log('After save doc to DB', data);
                    setDoc(data);
                    if(onSave){
                        onSave(data);
                    }
                })
              } else {
                response.text().then((text) => {
                    console.log(text);
                })
              }
          });        
    };

    const getLanguage = () => {
        if(!lang)
            return 'en';

        if(lang === 'uk')
            return 'ua';

        return lang;
    };

    const handleExpandedAttr = () => (e, isExpanded) => {
        setExpandedAttr(isExpanded ? isExpanded : false);
    };
    
    const handleExpandedCountries = () => (e, isExpanded) => {
        setExpandedCountry(isExpanded ? isExpanded : false);
    };

    const handleExpandedCommon  = () => (e, isExpanded) => {
        setExpandedCommon(isExpanded ? isExpanded : false);
    };
        
    const handleClose = () => {
        setOpen(false);
    };
        
    const handleChange = (e, type) => {
        let obj = {}
        if (type.length > 1) {
            var z = {}
            var z2 = doc[type[0]]

            type.forEach((val, idx) => {
                if (idx === type.length - 1) {
                    z[val] = e.target.value
                }
                else if (idx > 0) {
                    Object.assign(z, z2) /*{...z2 , [val]:{} }*/
                    z[val] = {}
                    z = z[val]
                    z2 = z2[val]
                } else {
                    z = { ...z2 }
                    obj = z
                }
            })
        } else {
            if (e.target.type === "checkbox") {
                obj = e.target.checked;
            }else if (e.target.type === "radio" && e.target.name === 'Visitor' ) {
                obj = parseInt(e.target.value);
            }
            else {
                obj = e.target.value;
            }
        }

        setDoc({ ...doc, [type[0]]: obj });
    }

    const checkedCountry = (country) => {
        if(!doc || !doc.Countries) return false;
        
        return doc.Countries.findIndex(c => c === country) > -1;
    }

    const handleClickCountry = (e) => {
        const dd = { ...doc };

        const idx = dd.Countries.findIndex(c => c === e.target.name);

        if(idx === -1){
            dd.Countries.push(e.target.name);
        }else{            
            dd.Countries.splice(idx, 1);            
        }

        setDoc(dd);
    };

    const handleAddAllCountries = () => {
        const dd = { ...doc };
        for (let idx = 0; idx < countries.length; idx++) {
            const country = countries[idx];            
            const i = dd.Countries.findIndex(c => c === country.TwoLetterISORegionName);
            
            if(i === -1){
                dd.Countries.push(country.TwoLetterISORegionName);
            };
        }


        setDoc(dd);
    };

    const handleClearCountries = () => {
        const dd = { ...doc };
        dd.Countries = [];
        setDoc(dd);
    };

    const getContentByLang = useCallback((lang) => {
        if(!doc) 
            return null;
        
        const idx = doc.LocalValues.findIndex( l => l.TwoLetterISOLanguageName === lang.toLowerCase());
        if(idx === -1)
            return null;

        return doc.LocalValues[idx];
    },[doc]);
        
    const onAddRole = (role) => {
        console.log(role);
        const index = inroles.indexOf(role);
        if (index === -1) {
            // inroles.splice(index, 1);
            inroles.push(role);
        }
        setInRoles([...inroles]);
        doc.Roles = inroles.join(', ');
        setDoc({ ...doc });
    };

    const onClearRole = (role) => {
        console.log(role);
        const index = inroles.indexOf(role);
        if (index !== -1) {
            inroles.splice(index, 1);
        }

        setInRoles([...inroles]);
        doc.Roles = inroles.join(', ');
        setDoc({ ...doc });
    };

    const onClearAll = () => {
        setInRoles([]);
        doc.Roles = '';
        setDoc({ ...doc });
    }

    const setTransletedValues = (data) => {
        if(!doc){
            return;
        }

        const newContent = {...doc};
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            
            const idx = newContent.LocalValues.findIndex( l => l.TwoLetterISOLanguageName === element.TwoLetterISOLanguageName.toLowerCase());
            if(idx > -1)
            {
                newContent.LocalValues[idx].Value = element.Value;                            
                // setTitle(element.Title);
                // setKeywords(element.Keywords);
                // setDescr(element.Descr);
                // setVal(element.Value);
            }
        }
        setDoc(newContent);
    };

    const handleTransleteAll = (e) => {
        
        if(!doc)
            return;

        setLoading(true);
        const UK = "uk";
            
        const sourceValue =  getContentByLang(UK);
                        
        const translateRequest = {
            sourceValue: sourceValue,
            targetValues: []
        };

        for (let i = 0; i < langs.length; i++) {
            const lang = langs[i];
            if(lang.TwoLetterISOLanguageName === UK)
                continue;
            const locVal = getContentByLang(lang.TwoLetterISOLanguageName);
            locVal.Value = "";
            translateRequest.targetValues.push(
                locVal
            );
        }
        
        fetch('/api/v1.0/translate/articleAttribs',
            {
                method: "POST",
                body: JSON.stringify(translateRequest),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){
                    setTransletedValues(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => {
                setLoading(false);
             });
    };
    
    const handleTransleteSelected = (e) => {
        
        if(!doc)
            return;

        setLoading(true);
        const UK = "uk";
        const seldLang = langs[langTab];
        if(seldLang.TwoLetterISOLanguageName.toLowerCase() === UK)
            return;

        const sourceValue =  getContentByLang(UK);
        const targetValue =  getContentByLang(seldLang.TwoLetterISOLanguageName);
        targetValue.Value = "";
        
        const translateRequest = {
            sourceValue: sourceValue,
            targetValues: [targetValue]
        };
    
        fetch('/api/v1.0/translate/articleAttribs',
            {
                method: "POST",
                body: JSON.stringify(translateRequest),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    setTransletedValues(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => {
                setLoading(false);
             });        
    };

    const updateDoc =  async (tab) => {
        if(localValue){
            const lang = langs[tab].TwoLetterISOLanguageName;
            const idx = doc
                        .LocalValues
                            .findIndex( l => l.TwoLetterISOLanguageName === lang.toLowerCase());
            doc.Props = { variant: variant, color: color, align: align, display: display };
            
            doc.LocalValues[idx] = localValue;
            console.log('updateDoc', doc);
            setDoc({...doc});
        }
    };

    const handleChangeTab = (event, newValue) => {
        updateDoc(langTab).then(
            setLangTab(newValue)
        );        
    };

    const changeValue = (field, val) => {
        if(!localValue)
            return;
        const newVal = { ...localValue, [field]: val };        
        setLocalValue(newVal);
    };

    const updateValueByLang = (event) => {        
        if(!localValue)
            return;
            changeValue(event.target.name, event.target.value);        
    };
    
    const handleChangeVariant = (event) => {
        setVariant(event.target.value);
    };

    const handleChangeColor = (event) => {
        setColor(event.target.value);
    };
    const handleChangeAlign = (event) => {
        setAlign(event.target.value);
    };

    const handleChangeDisplay = (event) => {
        setDisplay(event.target.value);
    };

    useEffect(()=> {
        if(!doc)
            return;

        const lang = langs[langTab];
        const local = getContentByLang(lang.TwoLetterISOLanguageName);
        
        if(local){
            setLocalValue(local);            
        }

    }, [doc, langs, langTab, getContentByLang]);

    useEffect(()=> {
        fetch('/api/v1.0/Utils/Countries', { headers: { authorization: token, accept: 'Accept: application/json' } })
              .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        setCountries(data);
                        
                        const _doc = {...article};
                        
                        for (let i = 0; i < langs.length; i++) {
                            const lang = langs[i];
                            if(_doc.LocalValues.findIndex( l => l.TwoLetterISOLanguageName === lang.TwoLetterISOLanguageName.toLowerCase()) === -1){
                                _doc.LocalValues.push({
                                    $type: "Start.Doing.Module.Core.Models.LocalValue, Start.Doing.Module.Core",
                                    Annonce: "",
                                    ContentId: _doc.Id,
                                    CreatedOn: _doc.CreatedOn,
                                    Descr: "",
                                    Id: 0,
                                    Keywords: "", 
                                    Title: "",
                                    TwoLetterISOLanguageName: lang.TwoLetterISOLanguageName.toLowerCase(),
                                    UpdatedOn: _doc.UpdatedOn,
                                    Value: ""
                                });
                            }
                        }

                        setDoc(_doc);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });

    }, [token, article, langs]);
    
    return (
        <div className={classes.root}>
            
            {doc &&
                <> 
                    {/* Common attributes */}
                    <Accordion 
                        className={classes.accordion}
                        style={{marginBottom: 8}}
                        expanded={expandedCommon} 
                        TransitionProps={{ unmountOnExit: true }}                        
                        onChange={handleExpandedCommon()}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelCommon-content"
                            id="panelCommon-header"
                        >
                        <Typography variant='body1' className={classes.section} >Common attributes</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordiondetails}>                            
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={6} sm={4} >
                                            <TextField
                                                id="StartedOn"
                                                name="StartedOn"
                                                label="Started On"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                type="datetime-local"
                                                value={moment(doc.StartedOn).format("YYYY-MM-DDTkk:mm")}
                                                InputLabelProps={{ shrink: true, }}
                                                onChange={e => handleChange(e, ["StartedOn"])}
                                            />
                                        </Grid> 
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                id="FinishedOn"
                                                name="FinishedOn"
                                                label="Finished On"
                                                variant="outlined"
                                                fullWidth
                                                margin="dense"
                                                type="datetime-local"
                                                value={moment(doc.FinishedOn).format("YYYY-MM-DDTkk:mm")}
                                                InputLabelProps={{ shrink: true, }}
                                                onChange={e => handleChange(e, ["FinishedOn"])}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormControlLabel style={{marginTop: 8}}
                                                control={
                                                    <Switch
                                                        checked={doc.IsActive}
                                                        onChange={e => handleChange(e, ["IsActive"])}
                                                        name="IsActive"                                                
                                                    />}
                                                label="Is Active"
                                            />                                            
                                        </Grid>
                                        
                                        
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>                                        
                                            <FormControl component="fieldset" fullWidth >
                                                <RadioGroup row
                                                    aria-label="visitor" name="Visitor"
                                                    value={`${doc.Visitor}`}                                        
                                                    onChange={e => handleChange(e, ["Visitor"])}
                                                >
                                                    <FormControlLabel value="0" control={<Radio />} label="All"  />
                                                    <FormControlLabel value="1" control={<Radio />} label="Anonymous" />
                                                    <FormControlLabel value="2" control={<Radio />} label="Authorized" />                        
                                                </RadioGroup>
                                            </FormControl>                                
                                            
                                            {doc.Visitor === 2 &&
                                            <Alert icon={false} variant="outlined" severity="info">
                                                <div>Available for roles: {inroles.length === 0  && <b className={classes.lbInfo}>Nobody</b>}</div>
                                                {/* <Typography display="block" variant="caption">Available for roles:</Typography> */}
                                                {inroles.length > 0 && inroles.map((role) => (
                                                    <Chip color='primary' className={classes.role} key={role}
                                                        label={role} variant="outlined"
                                                        size="small" icon={<HighlightOffIcon />}
                                                        onClick={() => onClearRole(role)} />
                                                    ))
                                                }                                
                                            </Alert>}                                                             

                                            {doc.Visitor === 2 && 
                                            <div>
                                                <Chip className={classes.role}
                                                    label='Clear all' variant="outlined"
                                                    color="secondary" size="small" icon={<HighlightOffIcon />}
                                                    onClick={onClearAll} />

                                                {roles.map((role) => (
                                                    <Chip className={classes.role} key={role}
                                                        disabled={doc.Visitor !== 2}
                                                        label={role} variant="outlined"
                                                        size="small" icon={<CheckCircleOutlineIcon />}
                                                        onClick={() => onAddRole(role)} />
                                                ))}
                                            </div>}                                    
                                        </Grid>
                                    </Grid>
                                </Grid>                                                                                            
                            </Grid>                                                        
                        </AccordionDetails>
                    </Accordion>
                    
                    {/* Available for countries */}
                    <Accordion 
                        className={classes.accordion}
                        style={{marginBottom: 16}}
                        expanded={expandedCountry} 
                        TransitionProps={{ unmountOnExit: true }}
                        onChange={handleExpandedCountries()}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelCountries-content"
                            id="panelCountries-header"
                        >
                        <Typography variant='h6' className={classes.section}>
                            Available for countries &nbsp;
                            {(!doc.Countries || doc.Countries.length === 0) && 
                                    <>: <span className={classes.lbInfo}>ALL</span></>
                            }
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordiondetails}>
                            <Box mb={1}>
                                <Chip variant='outlined'
                                    style={{marginRight: 8}}
                                    label='Check all' color="primary"
                                    icon={<span className="material-icons">done_all</span>}
                                    onClick={handleAddAllCountries}
                                />
                                <Chip variant='outlined'                                 
                                    label='Uncheck all' color="secondary"
                                    icon={<span className="material-icons">remove_done</span>}
                                    onClick={handleClearCountries}
                                />
                                

                            </Box>
                            <FormGroup row>                                
                                {
                                    countries.map((country) => ( 
                                        <FormControlLabel className={classes.country} key={country.TwoLetterISORegionName}
                                            control={   <Checkbox 
                                                            name={country.TwoLetterISORegionName} 
                                                            checked={checkedCountry(country.TwoLetterISORegionName)}
                                                            onChange={handleClickCountry}
                                                        />
                                                    }
                                            label={<span>{countryToFlag(country.TwoLetterISORegionName)} {country.Name}</span>}
                                        />
                                    ))
                                }

                            </FormGroup>                            
                        </AccordionDetails>
                    </Accordion>

                    {/* Content by langs */}
                    <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                        <Toolbar variant="dense" className={classes.toolbar}> 
                            <div className={classes.wrapper}>
                                <IconButton 
                                    title="Translete to all langs" 
                                    
                                    aria-label="translete_to_all" 
                                    color="secondary"
                                    style={{marginRight: 8}}
                                    disabled={loading}
                                    onClick={handleTransleteAll}
                                >
                                <span className="material-icons">g_translate</span>
                                </IconButton>
                                {loading && <CircularProgress size={48} className={classes.fabProgress} />}
                            </div>
                            <div className={classes.wrapper}>
                                <IconButton 
                                    title="Translete selected lang"
                                    
                                    aria-label="translete_to_selected" 
                                    color="primary"
                                    disabled={loading}
                                    onClick={handleTransleteSelected}
                                >
                                    <TranslateIcon />
                                </IconButton>
                                {loading && <CircularProgress size={48} className={classes.fabProgress} />}
                            </div>
                            
                            <Divider orientation='vertical' style={{height: 32}} />

                            <Tabs
                                variant="scrollable"
                                value={langTab}
                                onChange={handleChangeTab}
                                aria-label="doc tabs"
                            >
                                {langs.map((lang, index) => (
                                    <Tab
                                        key={lang.TwoLetterISOLanguageName}
                                        label={<>
                                            <Typography variant="body2">{countryToFlag(lang.Name.length === 2 ? lang.Name : lang.Name.substring(3,5) )} {lang.TwoLetterISOLanguageName}</Typography>
                                            <Typography variant="caption" className={classes.langname}>{lang.EnglishName}</Typography>
                                        </>}
                                        {...a11yProps(index)}
                                    />
                                ))
                                }
                            </Tabs>
                        </Toolbar>
                    </AppBar>
                                                        
                    {localValue && 
                    <Paper className={classes.paper}>
                        <Box p={1} >                            
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel className={classes.inputLabel} id="variants-label">Variant</InputLabel>
                                <Select
                                    labelId="variants-label"
                                    value={variant}
                                    onChange={handleChangeVariant}
                                >
                                    {variants.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem> )}                                     
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel className={classes.inputLabel} id="colors-label">Color</InputLabel>
                                <Select
                                    labelId="colors-label"
                                    value={color}
                                    onChange={handleChangeColor}
                                >
                                    {colors.map((c) => <MenuItem key={c} value={c}>{c}</MenuItem> )}                                     
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel className={classes.inputLabel} id="aling-label">Align</InputLabel>
                                <Select
                                    labelId="align-label"
                                    value={align}
                                    onChange={handleChangeAlign}
                                >
                                    {aligns.map((a) => <MenuItem key={a} value={a}>{a}</MenuItem> )}                                     
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel className={classes.inputLabel} id="dispalys-label">Display</InputLabel>
                                <Select
                                    labelId="displays-label"
                                    value={display}
                                    onChange={handleChangeDisplay}
                                >
                                    {displays.map((d) => <MenuItem key={d} value={d}>{d}</MenuItem> )}                                     
                                </Select>
                            </FormControl>

                        </Box>

                        <TextField
                            name="Value"
                            label="Value"
                            placeholder="Value"
                            fullWidth                            
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            multiline
                            minRows={4}
                            value={localValue.Value}
                            onChange={updateValueByLang}
                        />
                        <Box mt={2} p={2}>
                            <Typography variant="caption" display="block" gutterBottom>Preview: </Typography>
                            
                            <Typography variant={variant} color={color} align={align} display={display} >
                                {localValue.Value}                                
                            </Typography>

                        </Box>                 
                    </Paper>
                    }
                
                
                </>
            }
            
        </div>
    )
});

TextEditor.propTypes = {
    article: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    keyDisabled: PropTypes.bool.isRequired,
    onSave: PropTypes.func,     
    langs: PropTypes.arrayOf(
        PropTypes.shape({
            EnglishName: PropTypes.string.isRequired,
            Name: PropTypes.string.isRequired,
            NativeName: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,
            Active: PropTypes.bool.isRequired,
        })
    ).isRequired,
}

export default TextEditor;