import React from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import { connect } from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { AppContext } from "../../providers/appProvider";
// import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";

import {Grid, Typography, Card, CardActionArea, CardContent, CardMedia, 
        Radio, Button, IconButton, Snackbar, CircularProgress, Chip, Avatar } from "@material-ui/core";

import AccessTime from "@material-ui/icons/AccessTime";
import { withTranslation } from "react-i18next";
import pink from "@material-ui/core/colors/pink";
import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/grey";
import LazyLoad from "react-lazyload";
import Rating from "@material-ui/lab/Rating";
import Alert from "@material-ui/lab/Alert";

import LensIcon from "@material-ui/icons/Lens";
import ShareIcon from '@material-ui/icons/Share';
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

const styles = theme => ({
    card: {
        height: 565,
        [theme.breakpoints.down("sm")]: {
            height: 440,
        },
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    cardcontent: {
        padding: theme.spacing(1/2, 1),
        height: 300,
        [theme.breakpoints.down("sm")]: {
            height: 304,
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    propsradio: {
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1 / 4),
        },        
    },
    propsbutton: {        
        margin: 2,
        padding: (0, 2),
        // width: theme.spacing(3),
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
        // maxWidth: theme.spacing(3),        
    },
    propslabel: {
        fontSize: ".7em",
        width: theme.spacing(4),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    specialprice: {        
        position: "absolute",        
        top: 24,
        // right: 0,
        height: 22,        
        // color: theme.palette.secondary.main,
        // fontSize: 12,
        // backgroundColor: gray[50],
        // height: "24px",
        // lineHeight: "24px",
        // padding: theme.spacing(0, 1),
        // borderRadius: "6px 16px 16px 0",
        // borderWidth: 1,        
        // borderColor: gray[200],
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        // boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        zIndex: 1
    },

    priceBB: {
        position: "absolute",   
        top: 0,                
        // right: 0,
        height: 22,        
        // backgroundColor: gray[50],
        // color: gray[700],
        // padding: theme.spacing(0, 1),        
        // height: "24px",
        // lineHeight: "24px",        
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: gray[200],
        // borderRadius: "16px 6px 0 16px",         
        // textAlign: "center",        
        zIndex: 1,
    },
    sup: {
        fontSize: '.7em',
        marginRight: theme.spacing(1),
    },
    shareButton: {
        position: "absolute",   
        top: theme.spacing(-3), 
        left: 0,
    },
    pdfButton: {
        position: "absolute",   
        top: theme.spacing(-3), 
        left: theme.spacing(5),
    },    

    buyChip: {
        
    },
    specialTag: {
        position: "absolute",               
        bottom: -10,
        right: theme.spacing(1),
        zIndex: 1100,
        
    },
    specialTagLabel: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '.7rem'
        },
    },
    options: {
        minHeight: theme.spacing(5),
        height: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            height: "auto",           
        }, 
    },    
    specialcomment: {
        padding: theme.spacing(1),
        position: "absolute",
        top: 10,
        left: 5,
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#fff"
    },
    media: {
        height: 300,
        [theme.breakpoints.down("sm")]: {
            height: 183,
        },
        backgroundSize: "contain",    
        // backgroundColor: fade(theme.palette.common.black, 0.03),    
        // "&:hover": {
        //   backgroundColor: fade(theme.palette.common.black, 0.04),
        // },
    },
    grayscale: {
        webkitFilter: "opacity(30%) grayscale(100%)",
        filter: "opacity(30%) grayscale(100%)",
        "&:hover": {
            webkitFilter: "none",
            filter: "none",
          },
    },
    seria: {
        color: gray[500],        
    },    
    name: {
        height: 66,         
        textOverflow: "clip",
        overflow: "hidden",        
        [theme.breakpoints.down("sm")]: {            
            // height: 70,
            fontSize: "1em",
          },        
    },
    actions: {
        bottom: 0,
    },
    
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
    },
    buy: {
        padding: theme.spacing(4),
        width: "40% !important",
        // marginLeft: "auto",            
        // margin: theme.spacing(1),
    },
    
    expandOpen: {
        transform: "rotate(180deg)",
    },
    redprice: {       
        fontWeight: 400,
        marginRight: theme.spacing(1),
    },
    priceOld: {
        textDecoration: "line-through",        
        color: "#8a8a8a",        
    },
    
    btnright: {
        textAlign: "right",
    },
    blabel: {        
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },    
    price: {        
        marginRight: theme.spacing(2),
    },
    colorRed: {
        color: pink[500],
        fontSize: "1.4rem"
    },
    colorBlue: {
        color: blue[500],
    }, 
    circularProgress: {        
        marginLeft: 0,
        marginRight: theme.spacing(1),        
      },
    ratingstar: {
        paddingTop: 4,
        fontSize: "1.3em",        
        [theme.breakpoints.down("xs")]: {
            fontSize: ".8em",
        },
      },
    ratingtxt: {
        paddingLeft: theme.spacing(2),
        fontSize: "1em",
        [theme.breakpoints.down("xs")]: {
            fontSize: ".8em",            
        },
    },
});

const getColor = (color) => {
    if(!color || color.length === 0)
        return blue[600];
        
        if(color.charAt(0) !== "#")
            color = "#"+color;    
    return color;
}  

class CatalogItemXs extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures/`,            
            imgLgSize: "392x392",
            imgMdSize: "183x183",
            expanded: false,
            ProductId: null,
            selChildId: null,
            child: null,
            showSnack: false,
            url: ""
        };
    }

    static contextType = AppContext;

    componentDidMount () {
        const { item } = this.props;
        const { getUser } = this.context;

        getUser().then( (user) => {  
            this.setState({user: user});
        });

        if(item && item.Children.length > 0){        
            this.setState({selChildId: item.Children[0].ProductId, child:  item.Children[0]});
        }
    };

    SpecialPriceLabel = (value) => {
        // const { classes } = this.props;        
        switch (value) {
            case 0:
                return (
                    <Chip variant="outlined" color="secondary" label={<Typography variant="subtitle2">Wow ціна</Typography> } />  
                    // <div className={classes.specialprice} title="Wow ціна" style={{backgroundColor: pink[500] }} aria-label="recipe"><NewReleasesIcon /></div>  // "Wow ціна";
                )
            case 1:
                return <Chip color="primary" label={<Typography variant="subtitle2">Акція</Typography> } /> 
                // <div className={classes.specialprice}  title="Акція" style={{backgroundColor: blue[500] }} aria-label="recipe"><BrightnessAutoIcon /></div>  //"Акція";    
            default:
                // return <Chip color="primary" variant="outlined" label={<Typography variant="subtitle2">Розпродаж</Typography> } /> 
                return null;
        }
    }

    AvailableCSS = (value) =>{
        const { classes} = this.props;
        if(value <= 0){
            return classes.grayscale;            
        }
        else{
            return "";
        }
    }

    handleAddToOrder = (e, pos) => {        
        const { allowOrder, t} = this.props;
        const { user } = this.state;
        


            if( allowOrder || user){ 

                if(pos.FreeRest <= 0){
                    window.location.href = "https://direct.smartsender.com/redirect?continue=viber%3A%2F%2Fpa%3Fcontext%3DZGw6MjQ4OTg%26chatURI%3Djerelia_bot";
                    return;
                }
    
                const {disabled} = this.props;
                const { item } = this.props;
                const { child } = this.state;
                if(disabled === true)
                    return;
    
                if (item.Children.length > 0 && child){            
                    this.setState({ProductId: child.ProductId});         
                    this.props.actions.addToOrder([ { pos: child, qty: 1, orderrowtype: 0}] )
                } else {                
                    this.setState({ProductId: pos.ProductId});         
                    this.props.actions.addToOrder([ { pos: pos, qty: 1, orderrowtype: 0}] )
                }
            }else{
                this.props.actions.showMessage(t("order.AllowAddToOrderText"), "warning");            
            }


    }

    handleChangeChild = (event) => {
        const { item } = this.props;
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: event.target.value}); 
        }        
    };
    
    handleChangeColor = (event) => {
        const { item } = this.props;
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === event.target.value)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: event.target.value}); 
        }        
    }; 

    handleChangeProps = (ProductId) => {
        const { item } = this.props;
        
        if(item.Children.length > 0){
            const child = item.Children.find( (child) => {
                if(child.ProductId === ProductId)
                    return child;
                return null;
                });
            this.setState({child: {...child}, selChildId: ProductId}); 
        }
    };

    handleCopyUrl = () => {
        const {item, userName } = this.props;
        const {child } = this.state;        
        const _url = `${window.location.protocol}//${window.location.host}/product/${(child ? child.Code : item.Code)}?ref=${userName}`;
        // console.log(url);        
        navigator.clipboard.writeText(_url);
        this.setState({ showSnack: true, url: _url});
    };

    handleGoToPage = () => {
        const {item, magazine } = this.props;
        console.log('MAGAZINE', magazine);                
        const {child } = this.state;
        this.props.history.push(`/interactivecatalog?url=${magazine}&p=${(child ? child.MagazinePageIndex : item.MagazinePageIndex)}`);
    };

    handelCloseSnack = () => {
        this.setState({ showSnack: false});
    };
    
    render(){
        const { item, classes, width, userName, disabled, t, theme } = this.props;        
        const { user, child, ProductId, selChildId, cdn, showSnack } = this.state;        
        return(              
        <Card className={classes.card} >
            <CardActionArea onClick={() => this.props.history.push(`/product/${(child ? child.Code : item.Code)}`)} > 
                
                { item.Comment.length > 0 &&
                    <Chip 
                        className={classes.specialprice} 
                        variant="outlined" 
                        size="small"
                        color="secondary"
                        label={item.Comment}
                        // avatar={<Avatar>ББ</Avatar>}
                    />
                    // <span className={classes.specialprice} aria-label="recipe">{item.Comment}</span>
                }
                
                
                { user && 
                    <>
                        { child &&  child.CatalogPricePv && child.CatalogPricePv && child.CatalogPricePv > 0.01 && 
                            <Chip 
                                className={classes.priceBB} 
                                variant="outlined" 
                                size="small"
                                color="primary"
                                label={parseFloat(child.CatalogPricePv).toFixed(1)}
                                avatar={<Avatar>{t('BB')}</Avatar>}
                            />
                        }

                        {item && item.CatalogPricePv > 0.01 && 
                            <Chip 
                                className={classes.priceBB} 
                                variant="outlined" 
                                size="small" 
                                color="primary"
                                label={parseFloat(item.CatalogPricePv).toFixed(1)}
                                avatar={<Avatar>{t('BB')}</Avatar>}
                            />
                        }

                        {/* {( child ? (child.CatalogPricePv > 0.01 ? 
                            <span className={classes.priceBB}><span>{parseFloat(child.CatalogPricePv).toFixed(1)} <small>ББ</small></span></span>: null) 
                            : 
                            (item.CatalogPricePv > 0.01 ? <span className={classes.priceBB}><span>{parseFloat(item.CatalogPricePv).toFixed(1)} <small>ББ</small></span></span>: null))
                        } */}
                    </> 
                }

                { child ? 
                    <div className={classes.specialTag} >
                        { child.Tag.length > 0 &&
                            <Chip size="small"  color="secondary" 
                                label={<Typography className={classes.specialTagLabel} variant="body2">{child.Tag}</Typography> } 
                        />}                            
                    </div>
                :
                    <div className={classes.specialTag} >                                
                        { item.Tag.length > 0 &&
                            <Chip size="small"  color="secondary" 
                                label={<Typography className={classes.specialTagLabel} variant="body2">{item.Tag}</Typography> } 
                        />}
                    </div>
                }

                <LazyLoad height={300}>
                    <CardMedia                                        
                        className={clsx(classes.media, this.AvailableCSS(item.FreeRest))}
                        image={`${cdn}${ width === "xs" ? this.state.imgLgSize : this.state.imgLgSize}/${(child ? child.Image : item.Image)}`}
                        title={item.LocalName}                        
                    />
                </LazyLoad>                    
            </CardActionArea>
            <div style={{position:'relative'}}>
                {userName && 
                    <IconButton color="primary" className={classes.shareButton} onClick={()=> this.handleCopyUrl()}>
                        <ShareIcon />
                    </IconButton>
                }

                {child && child.MagazinePageIndex > 0 && 
                    <IconButton color="primary" className={classes.pdfButton} onClick={()=>this.handleGoToPage()} >
                        <PictureAsPdfOutlinedIcon />
                    </IconButton>
                }

                {item && item.MagazinePageIndex > 0 && 
                    <IconButton color="primary" className={classes.pdfButton} onClick={()=>this.handleGoToPage()}>
                        <PictureAsPdfOutlinedIcon />
                    </IconButton>
                }
            </div>

            <CardContent className={classes.cardcontent}>
                {showSnack && 
                <Snackbar
                    anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                    }}
                    open={showSnack}
                    autoHideDuration={1000}
                    onClose={this.handelCloseSnack}        
                >
                <Alert onClose={this.handelCloseSnack} severity="info" >
                    Copied
                </Alert>
                </Snackbar>                    
                }

                
                <Grid className={classes.options} container direction="row" justifyContent="center" alignItems="center" spacing={0}>
                { item.Children.length > 0 &&
                    <Grid item>
                        {item.Children.map((option) => (
                            option.Color.length > 0 ?
                            <Radio className={classes.propsradio} size= {width === "xs" ? "small": "medium"} key={option.ProductId}
                                title={ option.CharName } 
                                style={{ color: getColor(option.Color) }}
                                icon={<LensIcon fontSize={ width === "xs" ? "small": "default" } style={{ color: getColor(option.Color) }} />}
                                checked={selChildId === option.ProductId}
                                onChange={this.handleChangeColor}
                                value={option.ProductId}
                                name="radio-button-props"                            
                            />                                    
                            : <Button 
                                title={ option.CharName } 
                                color={selChildId === option.ProductId ? "secondary": "default"} 
                                className={classes.propsbutton} 
                                key={option.ProductId} 
                                size="small"  
                                variant="outlined"
                                value={option.ProductId}
                                onClick={() => this.handleChangeProps(option.ProductId)}
                                >
                                    <span className={classes.propslabel}>{option.CharName}</span>
                            </Button>
                            
                        ))}
                    </Grid>
                }
                </Grid>

                <Typography className={classes.name} align="center" gutterBottom display="block" variant="body1">
                    {child ? child.LocalName : item.LocalName}                        
                </Typography>

                <Typography noWrap className={classes.seria} align="center" display="block" variant="overline">  
                    { child ? <>{child.Code} / {child.Series} </> : <>{item.Code} / {item.Series}</>} 
                </Typography>
                                                                
                { user &&
                    <Typography align="center" component="div" display="block" >
                        <Typography color="primary">                                 
                            <span style={{fontSize: '1.6rem'}}>                                    
                                {parseInt(child ? child.SellingPrice : item.SellingPrice)}
                                <sup className={classes.sup}>
                                    .{(Math.round(100 * (child ? child.SellingPrice : item.SellingPrice)) / 100).toFixed(2).split('.')[1]}
                                </sup>
                            </span>                                
                            <small style={{fontSize: ".7em", marginRight: 16}}>
                                {item.CurrencySign}
                            </small>

                            {child ? 
                            <span className="price--line-through text-gray">
                                { parseInt( child.OldPrice === 0 ? child.Price: child.OldPrice) }
                                <sup className={classes.sup}>
                                    .{(Math.round( 100 * (child.OldPrice === 0 ? child.Price: child.OldPrice)) / 100).toFixed(2).split('.')[1]}
                                </sup>
                                <small style={{fontSize: ".7em"}}>
                                    {item.CurrencySign}
                                </small>
                            </span> :
                            <span className="price--line-through text-gray">
                                { parseInt( item.OldPrice === 0 ? item.CatalogPrice: item.OldPrice) }
                                <sup className={classes.sup}>
                                    .{(Math.round( 100 * (item.OldPrice === 0 ? item.CatalogPrice: item.OldPrice)) / 100).toFixed(2).split('.')[1]}
                                </sup>
                                <small style={{fontSize: ".7em"}}>
                                    {item.CurrencySign}
                                </small>
                            </span>
                        }
                        </Typography>
                    </Typography>
                }

                { !user &&
                    <Typography component="div" display="block" align={"center"} >                        
                        <Typography component="span" color="primary"> 
                            <span style={{fontSize: '1.4rem'}}>
                                {parseInt(child ? child.CatalogPrice : item.CatalogPrice)}
                            </span>
                            <sup className={classes.sup}>
                                .{(Math.round(100 *  (child ? child.CatalogPrice : item.CatalogPrice)) / 100).toFixed(2).split('.')[1]}
                            </sup>
                            
                            <small style={{fontSize: ".7em", marginRight: 16}}>
                                {item.CurrencySign}
                            </small>
                        </Typography>
                        {child ? 
                            child.OldPrice !== 0 ?
                            <span className="price--line-through text-gray">
                                { parseInt( child.OldPrice === 0 ? child.Price: child.OldPrice) }
                                <sup className={classes.sup}>
                                    .{(Math.round( 100 * (child.OldPrice === 0 ? child.Price: child.OldPrice)) / 100).toFixed(2).split('.')[1]}
                                </sup>
                                <small style={{fontSize: ".7em"}}>
                                    {item.CurrencySign}
                                </small>
                            </span> : null
                            :
                            item.OldPrice !== 0 ?
                            <span className="price--line-through text-gray">
                                { parseInt( item.OldPrice === 0 ? item.CatalogPrice: item.OldPrice) }
                                <sup className={classes.sup}>
                                    .{(Math.round( 100 * (item.OldPrice === 0 ? item.CatalogPrice: item.OldPrice)) / 100).toFixed(2).split('.')[1]}
                                </sup>
                                <small style={{fontSize: ".7em"}}>
                                    {item.CurrencySign}
                                </small>
                            </span> : null
                        }
                        
                    </Typography>
                }     
                    
                
                <Grid container justifyContent="center" alignItems="center" spacing={0} >
                    <Grid item >
                            <Rating name="half-rating" size="small" readOnly className={classes.ratingstar} defaultValue={item.ProductEvaluation.AvgRating} precision={0.5} />                            
                    </Grid>
                    <Grid item>                            
                        <Typography className={classes.ratingtxt} align="center" variant="body2" >
                            { item.ProductEvaluation.Qty !== 0 ? 
                            t("rev", {qty: item.ProductEvaluation.Qty})
                                : null } 
                        </Typography>
                    </Grid>
                </Grid>
                {user && 
                <Typography component="div" display="block" align="center" style={{paddingTop: 8}}>                    
                        <Chip
                            className={classes.buyChip}
                            avatar={
                            <Avatar>
                                { item.FreeRest <= 0 ? <AccessTime fontSize="small" /> : 
                                    (disabled && item.ProductId === ProductId ? <CircularProgress fontSize="small" className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon fontSize="small" style={{paddingLeft: 2}} /> )
                                }
                            </Avatar>}
                            // icon={ item.FreeRest <= 0 ? <AccessTime fontSize="small" /> : 
                            //     (disabled && item.ProductId === ProductId ? <CircularProgress fontSize="small" className={classes.circularProgress} /> :  <ShoppingCartOutlinedIcon fontSize="small" style={{paddingLeft: 2}} /> )
                            //     }
                            label= {item.FreeRest <= 0 ? t("buttons.NotifyArrivalShort") : t("buttons.Buy")}
                            clickable
                            onClick={(e) => this.handleAddToOrder(e, item)}
                            
                            variant= { item.FreeRest <= 0 ? "outlined" : (theme.palette.type === "light" ? "default" : "outlined")}                            
                            color={ item.FreeRest <= 0 ? "secondary" : "primary"}
                        />                
                </Typography>
                }
            </CardContent>
        </Card>
        );
    }
}

export default withTranslation()(withWidth()(withRouter( withStyles(styles, { withTheme: true })(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(CatalogItemXs)
))));
