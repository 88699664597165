import React, { useState  } from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
// import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) =>
createStyles({
    root: {        
        marginTop: 1,
        padding: theme.spacing(1),
        flexGrow: 1,
        width: "100%",        
    },
    paper: {
        minHeight: 600,
        padding: theme.spacing(3,2),        
        
    },
    headerbox: {
        width: "100%",
        padding: theme.spacing(3,2),
        background: 'linear-gradient(0deg, rgba(232,229,63,1) 0%, rgba(1,87,249,1) 100%)',
        color: '#fff'
    },
})
);


export const Charity = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [amount, setAmount] = useState(100.00);
    const [visibility, setVisibility] = useState(true);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNext = () => {
        if(amount === 0) return;
        const URL =  `/api/v1.0/liqpay/charity?amount=${amount}`;
        setLoading(true);
        fetch(URL, { method: "GET" })      
            .then(response => response.json())
                .then(data => {                          
                    if(data){
                        console.log('Charity',data);
                        setLoading(false);
                        setVisibility(false);                        
                        window.LiqPayCheckout.init({
                            data: data.Data,
                            signature: data.Signature,
                            embedTo: "#liqpay_checkout",
                            mode: "embed", // embed || popup
                            language: data.Language,
                            paytypes: data.Paytypes,
                        }).on("liqpay.callback", function (data) {                                                            
                            console.log("liqpay.callback data", data);
                            if(data){
                                const formData = new FormData();
                                formData.append("data", data.data);
                                formData.append("signature", data.signature);
                                
                                fetch("/api/v1.0/liqpay/callback", 
                                {
                                    method: "POST", 
                                    body: formData, 
                                    // "Content-Type": "multipart/form-data"
                                }
                                )                                
                                .then(function(result){
                                    console.log("liqpay.callback RESULT", result);
                                    // if(result.status === 200){
                                    //     self.setState({showSuccess: true });
                                        
                                    //     self.successRecharge();
                                    // }                        
                                })
                                .catch((error) => {
                                    // Error 😨
                                    if (error.response) {
                                        /*
                                        * The request was made and the server responded with a
                                        * status code that falls out of the range of 2xx
                                        */
                                        console.log("error.response.data", error.response.data);
                                        console.log("error.response.status", error.response.status);
                                        console.log("error.response.headers", error.response.headers);
                                    } else if (error.request) {
                                        /*
                                        * The request was made but no response was received, `error.request`
                                        * is an instance of XMLHttpRequest in the browser and an instance
                                        * of http.ClientRequest in Node.js
                                        */
                                        console.log("error.request", error.request);
                                    } else {
                                        // Something happened in setting up the request and triggered an Error
                                        console.log("error.message", error.message);
                                    }
                                    console.log("error.config",error.config);
                                });
                            }
                        }).on("liqpay.ready", function (data) {
                            console.log("!!! LIQPAY READY", data);                             
                            if(fullScreen === "xs")                                                                                  
                                window.scrollTo({top: 299, behavior: "instant"});
                            else
                                window.scrollTo({top: 170, behavior: "smooth"});
        
                        }).on("liqpay.close", function (data) {                    
                            console.log("liqpay.close", data);
                        });
                    }                    
                });
    };

    const handleBack = () => {
        setVisibility(true);
        const liq = document.getElementById("liqpay_checkout");
        if(liq){
            liq.innerHTML = "";
        }
    };

  return (
    <div className={classes.root}>
        <Paper className={classes.paper} elevation={fullScreen ? 0 : 1}>

        <Box component="div" className={classes.headerbox}>            
            <Typography component="h1" variant='h4' align='center' display='block' gutterBottom>
                Charitable contribution.
            </Typography>
            <Typography component="h1" variant='h5' align='center' display='block' gutterBottom>
                Help Ukraine in the war with Russia
            </Typography>
        </Box>

            {loading && 
            <div style={{ width: "100%", marginTop: 32, marginBottom: 32}}>
                <LinearProgress />
            </div>
             
            }

            <Box component="div" visibility={visibility ? 'visible' : 'hidden'} style={{marginTop: 32, display: visibility? 'block': 'none' }}>
                <Grid spacing={2}  container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={7} sm={3} md={2}>
                        <FormControl fullWidth  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">{t("pay.Amount")} </InputLabel>
                                <OutlinedInput                            
                                    type="number"
                                    id="outlined-adornment-amount"
                                    value={amount}
                                    inputProps={{ min: 1 }}
                                    onChange={ (e) => setAmount(parseInt(e.target.value))}
                                    startAdornment={<InputAdornment position="start">грн</InputAdornment>}
                                    labelWidth={60}
                                />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>                                    
                        <Button size="large" style={{ paddingTop: 15, paddingBottom: 15}}  fullWidth variant="contained" color="primary" onClick={handleNext}>
                            {t("pay.Next")}
                        </Button>                                    
                    </Grid>
                </Grid>
            </Box>
            
            <Box component="div" visibility={!visibility && !loading ? 'visible' : 'hidden'} style={{paddingTop: 16}}>
                <Typography component="div" display="block" align='center' gutterBottom>
                    <Button variant="contained" color="primary" onClick={handleBack}>
                        {t("pay.Back")}
                    </Button> 
                </Typography>
                
            </Box>

            <Grid spacing={0}  container direction="row" justify="center" alignItems="center" >
                <Grid item xs={12} sm={8} md={6} >
                    {/* LIQPAY: Платежный виджет */}
                    <div className={classes.liqpay} id="liqpay_checkout"></div>
                </Grid>                                    
            </Grid>
        </Paper>
        
    </div>
  )
}