
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";

// eslint-disable-next-line no-unused-vars
import { Typography, Button, IconButton} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      display: 'inline-block',
      flexGrow: 1,      
    },    
    button: {      
    },
    stepper: {
      padding: theme.spacing(0)      
    }
}));

const CButton = (props) => {
  // console.log('Button props', props);
  const {id, className, onClick, fullWidth, children, variant, color, size, href } = props;
  const classes = useStyles();
  
  return (
    <div id={id} className={clsx("jr-button", className, classes.root)} onClick={onClick} style={{width: fullWidth ? "100%" : "auto"}}> 
      <Button         
        size={size}        
        variant={variant}
        color={color}
        fullWidth={fullWidth}
        href={href}
      >
        {children}
      </Button>
    </div>
  )
}

CButton.propTypes = {
  className: PropTypes.string,  
  onClick: () => null
}

export default CButton