
const styles = {
    messagesContainer: {
      height: "100%",
      width: "100%"
    },
    sendMessage: {
     border:"solid 1px #36c2f652",
     backgroundColor: "#36c2f61f",
      borderRadius: "8px",     
      display: "flex",
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      padding: "6px 12px",
      width: "75%"
    },
    sendMessageContainer: {
      display: "flex",
      flex: "0 0 auto",
      justifyContent: "flex-end",
      width: "100%"
    },

    receivedMessage: {
      border:"solid 1px #f4433640",
      backgroundColor: "#f4433617",
      borderRadius: "8px",
      display: "flex",
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      padding: "6px 12px",
      width: "75%"
    },
    receivedMessageContainer: {
      display: "flex",
      flex: "0 0 auto",
      justifyContent: "flex-start",
      width: "100%"
    },

    inviteMessage: {
      border:"solid 1px #06342817",
      backgroundColor: "#36f4c017",
      borderRadius: "8px",
      display: "flex",
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      padding: "6px 16px",
      width: "99%"
    },
    inviteMessageContainer: {
      display: "flex",
      flex: "0 0 auto",
      justifyContent: "flex-start",
      width: "100%"
    },

    clockMessage: {        
        width: "100%",
        textAlign: "right",
        fontSize: ".8em"
    }
  };
  
  export default styles;
  