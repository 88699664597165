import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Paper, Collapse, Box, Typography, Grid, IconButton, CircularProgress,
    Divider, Menu, MenuItem, Fab, Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import LazyLoad from "react-lazyload";
import ConfirmationDialog from './ConfirmationDialog';

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CreateIcon from "@material-ui/icons/Create";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ReplyIcon from "@material-ui/icons/Reply";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LoopIcon from "@material-ui/icons/Loop";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { red, green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
    createStyles({        
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        dialog: {
            // width: '80%',
            // maxHeight: 335,
          },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },          
        
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: 1,
            marginTop: -14,
            zIndex: 1,
        },
        fabButton: {  
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(3),            
            zIndex: 1,
        },
        paperrow: {
            fontSize: "1em",
            // padding: theme.spacing(1,1,0,1),
            marginBottom: theme.spacing(1/2),
            boxShadow: theme.palette.ambilight.boxshadow,
            marginBottom: theme.spacing(1)
        },
        row: {
            // fontSize: "1em",            
        },
        rowhead: {
            fontSize: ".8em",
            paddingBottom: theme.spacing(0),
        },
        rowactbtn: {
            // margin: theme.spacing(0, 1),
        },
        header: {
            textTransform: "uppercase"
        },
        prices: {
            textAlign: "right",
        },
        center: {
            textAlign: "center",
        },
        payed: {
            color: theme.palette.success.main,
        },
        stateLbl: {
            textTransform: "uppercase",
            fontSize: ".75em",
            fontWeight: 500,
            marginTop: 4,
        },
        state: {
            borderLeft: "6px solid #c3c3c3",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",            
        },

        st10: {
            borderLeft: "6px solid #c3c3c3",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            
        },
        stClrr10: {
        },
        st30: {
            borderLeft: "6px solid #f44336",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            
        },
        stClr30: {
            color: "#f44336",            
        },
        st50: {
            borderLeft: "6px solid #ff9800",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            
        },
        st50T: {
            borderLeft: "6px solid #ff9800",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            
        },
        stClr50: {
            color: "#ff9800",                        
        },
        st70: {
            borderLeft: "6px solid #2196f3",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            

        },
        stClr70: {
            color: "#2196f3",
        },
        st150: {
            borderLeft: "6px solid #4caf50",
            // borderTop: "1px solid #c3c3c3",
            // borderRight: "1px solid #c3c3c3",
            // borderBottom: "1px solid #c3c3c3",
            
        },
        stClr150: {
            color: "#388e3c",                        
        },
        discount: {
            color: red[800],
        },
        menuicon: {
            marginRight: theme.spacing(2)
        },
        detailCont: {
            margin: theme.spacing(0),
            padding: theme.spacing(1, 0),
        },
        detailBox: {
            paddingTop: theme.spacing(2),            
        },
        detailsrow: {
            padding: theme.spacing(2, 0)
            // marginTop: theme.spacing(2),
        },
        bp: {
            border: "none"
        },
        colorP: {
            color: theme.palette.primary.main,
        },
        bdrbtn: {
            marginBottom: theme.spacing(1),
        },
        detail: {
            margin: theme.spacing(1, 2, 0, 2),
            borderBottom: "1px solid #e0e0e0"
        },
        detailSum: {
            borderLeft: "1px solid #e0e0e0",
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                borderLeft: "none",
            },
        }
    })
);

const boxProps = {
    position: 'relative',
    // bgcolor: 'background.paper',
    // borderColor: 'grey.300',    
    // border: 1,
    style: { width: '100%'},
};

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

const disablePay = (row) => {
    if(row.StateCode === 30 || row.StateCode === 140) {
        return true;
    }

    if(row.Paid || row.StateCode === 30 || row.StateCode === 140 || row.AmountPayment <= 0 || row.TypePaymentAbr ==="COD") {                        
        return true;
    }
    return false;
};

const DialogState = {
    Edit: 'Edit',
    Cancel: 'Cancel',    
};

const cdn = `${process.env.REACT_APP_CDN}Pictures/`;
const imgSize = "76x76";

function OrderRow(props) {
    const { onUpdate, onReload, onReloadDrafts, token, row } = props;    
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const width = useWidth();

    const [showDetails, setShowDetails] = useState(false);
    const [anchorElOrder, setAnchorElOrder] = useState();
    const [openMenuOrder, setOpenMenuOrder] = useState(false);
    const [order, setOrder] = useState();
    const [loading, setLoading ] = useState(false);

    const [openDlg, setOpenDlg] = useState(false);
    const [queryText, setQueryText] = useState("");
    const [queryTitle, setQueryTitle] = useState("");
    
    const [dialogState, setDialogState] = useState(DialogState.Edit);
    const [severity , setSeverity] = useState("success");
    const [snackBarTxt, setSnackBarTxt] = useState("");
    const [showSnackBar, setShowSnackBar] = useState(false);

    const getClassState = (state) => {
        switch (state) {
            case 10:
                return classes.st10;
            case 30:
            case 130:
            case 140:
                return classes.st30;
            case 50:
            case 60:
                return classes.st50;
            case 70:
            case 80:
                return classes.st70;
            case 150:
                return classes.st150;
            default:
                return classes.state;
        }
    };

    const getClrState = (state) => {
        switch (state) {
            case 10:
                return classes.stClr10;
            case 30:
            case 130:
            case 140:
                return classes.stClr30;
            case 50:
            case 60:
                return classes.stClr50;
            case 70:
            case 80:
                return classes.stClr70;
            case 150:
                return classes.stClr150;
            default:
                return null;
        }
    };

    const getPayColumn = () => {
        if(row.StateCode === 30 || row.StateCode === 140 || row.AmountPayment <= 0 || row.TypePaymentAbr ==="COD") {
            return null;
        }                
        return row.Paid ? t("orders.Payed") : null
    };

    const loadOrder = (id) => {
        setLoading(true);
        const url = `/api/v1/order/confirmed/${id}/true`;
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){
                    setOrder(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => {
                setLoading(false);
             });
    };

    const handleShowDetails = () => {
        const show = !showDetails;
        setShowDetails(show);
        
        if(show && !order)
            loadOrder(row.Id);
    };

    const handleClickMenuOrder = (e) => {
        setAnchorElOrder(e.currentTarget);
        setOpenMenuOrder(true);        
    }

    const handleCloseMenuOrder = () => {
        setAnchorElOrder(null);
        setOpenMenuOrder(false);        
    };

    const handleEditOrder = () => {
        handleCloseMenuOrder();
        setDialogState(DialogState.Edit);
        setQueryTitle(t("orders.EditOrderTitle", {number: row.DocNumber}));
        setQueryText(t("The order will be canceled, then you can edit it"));
        setOpenDlg(true);
    };

    const handleCopyOrder = () => {
        if(!row)
            return;

        handleCloseMenuOrder();
        const url = `/api/v1/order/copy/${row.Id}`;
        fetch(url,
            {
                method: "GET",                
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){                    
                    console.log('Copy order', data);
                    if(onReloadDrafts){
                        onReloadDrafts();
                    }
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => {
                setLoading(false);
             });        

    };

    const handleCancelOrder = () => {
        handleCloseMenuOrder();
        setDialogState(DialogState.Cancel);
        setQueryTitle(t("orders.CancelOrderTitle", {number: row.DocNumber}));
        setQueryText(t("The order will be canceled"));
        setOpenDlg(true);
    };

    const payOrder = () => {
        if(!disablePay(row)){
            history.push(`/pay/${row.Id}`);
        }
    };

    const payFromBalance = () => {
        handleCloseMenuOrder();
        
        setLoading(true);

        const url = `/api/v1/balance/payorder/${row.Id}`;
        fetch(url,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log("Payed from balance order", data);
                if(data && onReload){
                    if(data.Succes){
                        onReload();
                    }else{
                        setSeverity("error");

                        const sentences = data.ErrorDescription.split(". ");
                        let message = "";
                        console.log(sentences);
                        for (let i = 0; i < sentences.length; i++) {
                            const sentence = sentences[i];
                            message += `${t(sentence)}. `;
                        }

                        setSnackBarTxt(message);
                        setShowSnackBar(true);
                    }                    
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { setLoading(false); });
    };

    const requestCancelOrder = () => {
        const url = `/api/v1/order/cancel/${row.Id}`;
        fetch(url,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){
                    onUpdate(data);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { });
    };

    const requestEditOrder = () => {
        const url = `/api/v1/order/editconfirmed/${row.Id}`;
        fetch(url,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {
                if(data){
                    console.log('Edit order callback', data);
                    history.push(`/order/${data.Id}`);
                }
            })
            .catch((error) => {
                console.log('Error order', error);
            })
            .finally(() => { });
    };

    const dialogCloseCallBack = () => {
        switch (dialogState) {
            case DialogState.Edit:
                requestEditOrder();
                break;                
            case DialogState.Cancel:                 
                requestCancelOrder();
                break;
            default:
                break;
        }
    };

    const handleCloseDialog = (choice) => {
        setOpenDlg(false);
        if (choice) {
            dialogCloseCallBack();
        }
    };

    const closeSnackBar = () => {
        setShowSnackBar(false);
    }

    return (
    <>
        <Paper className={clsx(classes.paperrow, getClassState(row.StateCode))} elevation={0}>
            <Grid className={classes.row} container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item >
                    <div className={classes.wrapper}> 
                        <IconButton size="small" className={classes.button} aria-label="expand row" onClick={handleShowDetails}>
                            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        { loading && 
                            <CircularProgress size={28} className={classes.buttonProgress} />
                        }
                    </div>
                </Grid>
                <Grid item xs={8} sm={10}>
                    {width !== "xs" &&
                        <Grid
                            className={classes.rowhead}
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={1} sm={2} md={1} >{t("orders.State")}</Grid>
                            <Grid item xs={4} sm={2} >{t("orders.DocNum")}</Grid>
                            <Grid item xs={2} sm={1} >{t("orders.Date")}</Grid>

                            <Grid item xs={4} sm={3}>{t("orders.BarCode")}</Grid>
                            
                            <Grid item align="right" xs={4} sm={2}>{t("orders.Amount")}</Grid>
                            <Grid item align="right" xs={4} sm={2} md={1}>{t("orders.AmountOB")}</Grid>
                            <Grid item xs={4} sm={2} ></Grid>                            
                        </Grid>
                    }
                    <Grid className={classes.row} container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        {/* 1 row */}
                        <Grid item xs={5} sm={2} md={1} >
                            <Typography className={clsx(classes.stateLbl, getClrState(row.StateCode))} display="block">
                                {t(`order.State.${row.StateName}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} sm={2} align={width === "xs" ? "right" : "left"}>
                            {row.DocNumber}
                        </Grid>
                        {/* 2 row */}
                        <Grid item xs={5} sm={1} >
                            {moment(row.CreatedOn).format("DD-MM-YYYY")}
                        </Grid>
                        <Grid item xs={7} sm={3} align={width === "xs" ? "right" : "left"}>
                            {row.BarCode} <Typography variant="caption" className={clsx(classes.stateLbl, getClrState(row.StateCode))}>{t(row.StateTracking)}</Typography>
                        </Grid>                        
                        {/* 3 row */}
                        <Grid item align={width === "xs" ? "left" : "right"} xs={4} sm={2}>
                            <Typography variant="button" display="block">
                                {parseFloat(row.Amount).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item align="right" xs={4} sm={2} md={1}>
                            <Typography color="primary" variant={row.AmountOB > 0 ? "button" : "body2"} display="block">
                                {row.AmountOB !== 0 ? parseFloat(row.AmountOB).toFixed(2) : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} >
                            <Typography align={width === "xs" ? "right" : "left"} className={clsx(classes.stateLbl, getClrState(row.StateCode))} variant="button" display="block" gutterBottom>
                                {getPayColumn()}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item >
                    <IconButton size='small' className={classes.button} aria-label="order menu" onClick={handleClickMenuOrder}>
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {/* Details of order */}
            <Collapse className={classes.detailCont} in={showDetails && Boolean(order)} unmountOnExit>
                <Box pt={0} pr={1} pb={0} pl={1}  style={{minHeight: 100}}>                    
                    <Divider />
                    {order && 
                    <Grid className={classes.detailsrow}  container spacing={0} direction="row" justifyContent="flex-start" alignItems="stretch">
                        <Grid item xs={12} sm={7}> 
                            <Typography style={{paddingLeft: 16, marginBottom: 16, fontWeight: 'bold'}}>{t("Order details")}</Typography>      
                            {order.Rows.map((pos) => (
                                <div className={classes.detail} key={pos.LineNumber}> 
                                    <Grid  container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" >
                                        <Grid item xs={2} sm={1}>
                                            <Typography variant="body2">{pos.ProductCode}</Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={1} >
                                            <LazyLoad height={76}>
                                                <Link to={{ pathname: `/product/${pos.ProductCode}`}} >
                                                    <img style={{ width: "100%", maxWidth: "100%"}} alt={pos.ProductCode} src={`${cdn}${imgSize}/${pos.Image}`} />
                                                </Link>                                                                                                
                                            </LazyLoad>
                                        </Grid>
                                        <Grid item xs={8} sm={10}>
                                            <Typography color="inherit" component={Link} to={`/product/${pos.ProductCode}`} variant="body2" gutterBottom>{pos.ProductName}</Typography>
                                            <Typography variant="body2" display="block" style={{fontWeight: 500}} gutterBottom>
                                                {pos.Qty}<small>{t("qty")}</small> * {parseFloat(pos.Price).toFixed(2)} = {parseFloat(pos.Amount).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                            </div>
                            ))}                            
                        </Grid>
                        
                        <Grid item xs={12} sm={5}>
                            <Box borderRadius={8} p={2} {...boxProps} > 
                                {!disablePay(row) && 
                                <Fab 
                                    title="Pay the order" 
                                    size="medium" 
                                    className={classes.fabButton} 
                                    aria-label="pay order" 
                                    color="primary" 
                                    onClick={payOrder}
                                >
                                    <CreditCardIcon />
                                </Fab> }
                                
                                <Typography style={{marginBottom: 16, fontWeight: 'bold'}}>{t("Order summary")}</Typography>
                                
                                <Grid container spacing={1} direction="row" style={{marginBottom: 8}}>
                                    <Grid item xs={6} sm={5}>
                                        <Typography variant="subtitle2">{t("Grand total")}:</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <b>{parseFloat(order.Amount).toFixed(2)}</b> <small style={{fontSize: ".7em"}}>{order.CurrencyName}</small>                                        
                                        </Typography>                                        
                                    </Grid>
                                </Grid>
                                                                
                                {row.Paid && !(row.StateCode === 30 || row.StateCode === 140 || row.AmountPayment <= 0 || row.TypePaymentAbr ==="COD") &&
                                <>
                                    
                                    <Grid container spacing={1} direction="row" >
                                        <Grid item xs={6} sm={5}>{t("order.PaymentInfo")}:</Grid>
                                        <Grid item ><b>{getPayColumn()}</b></Grid>
                                    </Grid>
                                    <Grid container spacing={1} direction="row" >
                                        <Grid item xs={6} sm={5}>                                                                            
                                            {t("order.PaymentMethod")}:
                                        </Grid>
                                        <Grid item>
                                            <b>{t(`order.payments.${order.PaymentType}`)}</b>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} direction="row" >
                                        <Grid item xs={6} sm={5}>{getPayColumn()}:</Grid>
                                        <Grid item ><b>{parseFloat(order.AmmoutPaymenet).toFixed(2)}</b></Grid>
                                    </Grid>                                    
                                    {order.PaymentOrderDetails.length > 0 && 
                                        <Box mb={2}>
                                            {order.PaymentOrderDetails.map( (p, i) => (
                                            <Grid key={i} container spacing={1} direction="row">
                                                <Grid item xs={6} sm={5}>{p.Name}:</Grid>
                                                <Grid item ><b>{parseFloat(p.Amount).toFixed(2)}</b></Grid>    
                                            </Grid>))}
                                        </Box>
                                    }                                    
                                </>
                                }

                                { order.AmountCOD > 0 && 
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                        {t("order.AmountCOD")}:
                                    </Grid>
                                    <Grid item>
                                        <b>{parseFloat(order.AmountCOD).toFixed(2)}</b>
                                    </Grid>
                                </Grid>}
                                
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                        { t("order.AmountDelivery") }:
                                    </Grid>
                                    <Grid item>
                                        <b>{parseFloat(order.AmountDelivery).toFixed(2)}</b>
                                    </Grid>
                                </Grid>
                                
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                        {t("order.AmountService")}:
                                    </Grid>
                                    <Grid item>
                                        <b>{parseFloat(order.AmountService).toFixed(2)}</b>
                                    </Grid>
                                </Grid>

                                {row.AmountOB > 0 &&
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                        {t("order.AmountLO")}:
                                    </Grid>
                                    <Grid item>
                                        <b className={classes.colorP}>{parseFloat(row.AmountOB).toFixed(2)}</b>
                                    </Grid>
                                </Grid>}

                                

                                {order.UseBusinessPack && 
                                <Grid container spacing={1} direction="row" style={{marginTop: "16px"}} >
                                    <Grid item xs={6} sm={5}>
                                    { t("order.BusinessPack") }:
                                    </Grid>
                                    <Grid item>
                                        <DoneAllIcon color="secondary" fontSize="small" />
                                    </Grid>
                                </Grid> }
                                
                                <Grid container spacing={1} style={{marginTop: "16px"}} >
                                    <Grid item xs={6} sm={5}>
                                    { t("order.RecipientFirstName") }:
                                    </Grid>
                                    <Grid item>
                                        <b>{order.RecipientFirstName}</b>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                    { t("order.RecipientLastName") }:
                                    </Grid>
                                    <Grid item>
                                        <b>{order.RecipientLastName}</b>
                                    </Grid>
                                </Grid>
                                {/* <Grid container spacing={1} direction="row" >
                                    <Grid item>
                                    { t("order.RecipientMiddleName") }:
                                    </Grid>
                                    <Grid item>
                                        <b>{Order.RecipientMiddleName}</b>
                                    </Grid>
                                </Grid> */}
                                
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={6} sm={5}>
                                        { t("order.RecipientPhone") }:
                                    </Grid>
                                    <Grid item>
                                        <b>{order.RecipientPhone}</b>
                                    </Grid>
                                </Grid>

                                { order.PostOffice &&
                                <>
                                <Grid container spacing={1} style={{marginTop: "16px"}} >
                                    <Grid item xs={6} sm={5}>
                                        {t("order.Carrier")}:
                                    </Grid>
                                    <Grid item>
                                        <b>{order.PostOffice.Carrier}</b>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} >
                                    <Grid item xs={6} sm={5}>
                                        {t("order.DevileryAddress")}:
                                    </Grid>
                                    <Grid item>
                                        <b>{order.RecipientCountry ? order.RecipientCountry: "UA" }, {order.PostOffice.LongName}</b>
                                    </Grid>
                                </Grid>
                                </>}                                
                            </Box>
                        </Grid>                        
                    </Grid>
                    }
                </Box>                
            </Collapse>
        </Paper>

        {/* Order menu */}
        <Menu 
            anchorEl={anchorElOrder}
            open={openMenuOrder}            
            onClose={handleCloseMenuOrder}
        >
            <MenuItem onClick={handleEditOrder} disabled={row.StateCode !== 50} ><CreateIcon className={classes.menuicon} />{t("orders.Edit")}</MenuItem>
            <MenuItem onClick={handleCopyOrder} ><FilterNoneIcon className={classes.menuicon} />{t("orders.CopyOrder")}</MenuItem>
            <MenuItem onClick={handleCancelOrder} disabled={row.StateCode !== 50} ><ReplyIcon className={classes.menuicon} />{t("orders.CancelOrder")}</MenuItem>
            <Divider />
            <MenuItem onClick={payOrder} disabled={disablePay(row)} ><CreditCardIcon className={classes.menuicon} />{t("orders.PayOrder")} </MenuItem>
            <MenuItem onClick={payFromBalance} disabled={disablePay(row)}><LoopIcon className={classes.menuicon} />{t("orders.PayOrderFromBalance")}</MenuItem>
        </Menu>
        
        {openDlg && 
        <ConfirmationDialog             
            classes={{
                paper: classes.dialog,
            }}
            fullWidth={true}
            open={openDlg}
            onClose={handleCloseDialog}
            title={queryTitle}
            text={queryText}
        />}

        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={closeSnackBar}
            open={showSnackBar} autoHideDuration={8000} >
            <Alert variant="filled" severity={severity} onClose={closeSnackBar} >
                {snackBarTxt}
            </Alert>
        </Snackbar>
    </>
    )
}

OrderRow.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onReload: PropTypes.func,
    onReloadDrafts: PropTypes.func,
    token: PropTypes.string,
    row: PropTypes.shape(
        {
            Id: PropTypes.string.isRequired,
            StateCode: PropTypes.number.isRequired,
            StateName: PropTypes.string,
            DocNumber: PropTypes.string,
            StateTracking: PropTypes.string,
            BarCode: PropTypes.string,
            CreatedOn: PropTypes.string,
            Amount: PropTypes.number.isRequired,
            AmountOB: PropTypes.number.isRequired,
            Paid: PropTypes.bool.isRequired,
            AmmoutPaymenet: PropTypes.number,
            TypePaymentAbr: PropTypes.string
        }
    ).isRequired    
}

export default OrderRow;
