/* /src/components/auth/logout.jsx */

import React from "react";
import { AppConsumer } from "../../providers/appProvider";
import Typography from "@material-ui/core/Typography";

export const Logout = () => (
    <AppConsumer>
        {({ logout }) => {            
            logout();
            <Typography align="center" style={{padding: 32, display: "block", width: "100%"}}>Завантаження...</Typography>
        }}
    </AppConsumer>
);