import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, useParams } from "react-router-dom";

import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Paper, Typography, Button } from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Helmet } from "react-helmet";

const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";

const useStyles = makeStyles((theme) => {
        
        return {
            root: {
                flexGrow: 1,
                marginTop: theme.spacing(1)
            },
            paper: {
                padding: theme.spacing(1)
            },
            topbanner: {
                maxWidth: "100%",
                height: "auto",
            },
            custom: {
                width: "100%",        
                [theme.breakpoints.down("sm")]: {
                    width: 340 
                },
                overflowX: "auto"            
            },
            table: {...theme.table},
            tr: {...theme.tr},
            td: {...theme.td}
        }
    }
);

export const Page = () => {
    const classes = useStyles();    
    const history = useHistory();
    const location = useLocation();
    const callback = location.state?.callback;
    const theme =  useTheme();
    // const theme = useTheme();
    const app = useContext(AppContext);    
    const { lang, country } = app;
    const { key } = useParams();
    const [token, setToken] = useState(null);
    const [content, setContent] = useState();
    const [loading, setLoading] = useState(true);
    
    
    
    const elRef = useRef();

    // Get token
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then( async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }
                setToken(token);

                const url = `/api/v1.0/page/content/${key}/${lang}/${country}`;
                console.log(url);
                fetch(url,
                {
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': token
                    }
                })
                .then((response) => {
                    if(response.status === 200){
                        response.json().then((content) => {
                            setLoading(false);                           
                            setContent(content);
                        });
                    }else if(response.status === 404){
                        history.push({
                            pathname: '/notfound',
                            state: {callback: callback}
                        });
                    }
            })
            .catch((error) => {
                console.log('Error', error);
            })
            });
        };


        fetchUser();

    }, [app, callback, country, history, key, lang]);

  return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>                
                <Paper elevation={0}>
                {content && <>
                    <Helmet>
                        <title>{ `Jerelia, ${content.LocalValues[0].Title}` }</title>                
                    </Helmet>
                    { (content.TopBanner) &&                                
                    <figure style={{margin: "0", padding: "0"}}>
                        <picture className={classes.topbanner}>
                            {/* large */}
                            <source media="(min-width: 1134px)" srcSet={content.TopBanner.startsWith('http') ? content.TopBanner : `${host}${content.TopBanner}`} />
                            {/* <source media="(min-width: 1134px)" srcSet={content.TopBanner.replace(file, `/xl/${file}`)} /> */}
                            
                            {/* medium */}
                            {/* <source media="(max-width: 1133px)" srcSet={content.TopBanner.replace(file, `/xl/${file}`)} /> */}
                            
                            {/* small */}
                            { content.TopMobileBanner.length > 0 &&
                                <source srcSet={content.TopMobileBanner.startsWith('http') ? content.TopMobileBanner : `${host}${content.TopMobileBanner}`} /> 
                            }
                            {/* <source srcSet={content.TopBanner.replace(file, `/md/${file}`)} /> */}                            
                            <img className={classes.topbanner} src={content.TopBanner.startsWith('http') ? content.TopBanner : `${host}${content.TopBanner}`} alt={content.Name} />
                        </picture>
                    </figure>
                    }

                    <Box p={2}>
                        <Typography component='div'>
                            <div ref={elRef} dangerouslySetInnerHTML={{__html: content.LocalValues[0].Value}} ></div>
                        </Typography>

                        {/* TODO: Need include MUI styles */}
                        <div style={{display: 'none'}}>
                            <Button ></Button>
                            <TableContainer >
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>                                        
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row"></TableCell>                                        
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>                        
                    </Box>
                
                </>}
                </Paper>
            </div>
        )}        
    </AppConsumer>
    
  )
}
