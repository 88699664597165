import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import gray from "@material-ui/core/colors/grey";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        height: "100%"
    },
    card: {
        height: "100%"
    },
    info: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        height: "100%",
        minHeight: 100,
        // [theme.breakpoints.down("sm")]: {
        //     minHeight: 100,
        //   },
    },

    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {        
        // minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    progress: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
    }
});


const InfoPanel = withStyles(styles)( props => {
    const { classes, title, value, ...other } = props;
    return (
      <Paper align="center" className={classes.info} elevation={0} {...other}>
        <Typography gutterBottom color="primary" align="center" variant="overline">{title}</Typography><br />
        <Typography gutterBottom align="center" variant="button" >{ value }</Typography>
      </Paper>
    );
  });

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            error: false,
            loading: false
        };
    }

    componentDidMount() {
        this.loadDashBoard();
    }

    loadDashBoard = async () => {
        const self = this;
        const userManager = await OidcUserManager();
        this.setState({ error: false, loading: true });
        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                const URL =  "/api/v1.0/reports/dashboard";
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )
                .then(function(response){                    
                    self.setState({ data: response.data, error: false, loading: false });                    
                    // console.log("Dashboard data", response.data); 
                })
                .catch((error) => {
                    self.setState({ error: true, loading: false });
                    console.log(error);
                });                
            }
        });
    };

    render() {
        const { classes, t } = this.props; 
        const { data, error, loading } = this.state;
        const cps = data ? data.DashBoard.Curent_Period_Statistics[0] : null;
        const clps = data ? data.DashBoard.Closed_Period_Statistics[0] : null;
        // console.log("reports.dashboard",data);

        return (
            <div className={classes.root}>
                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Card elevation={1}>
                            <CardHeader        
                                title={t("reports.dashboard.title")}
                                subheader={
                                    ( data && data.Customer && data.Customer.Mentor ? 
                                        (`${t("reports.dashboard.Mentor")}: ${data.Customer.Mentor.Name}, ${data.Customer.Mentor.Phone}, ${data.Customer.Mentor.Email}` ): 
                                    "")
                                }
                            />
                        </Card>
                    </Grid>
                </Grid>
                {loading &&
                    <>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={4} style={{paddingTop: "24px"}}>
                            <Grid item>
                                <CircularProgress size={40} />
                            </Grid>                    
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                            <Grid item>
                                <Typography color="textSecondary" variant="h6" align="center" gutterBottom>
                                    {t("common.Loading")}...
                                </Typography>
                            </Grid>
                        </Grid>
                </>
                }
                {error && 
                    <>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                            <Grid item>
                                <Typography color="error" variant="h5" align="center" gutterBottom>                                    
                                    {t("common.SorrySomethingWentWrong")}
                                </Typography>
                                <Typography color="error" align="center" gutterBottom>
                                    {t("common.PleaseTryAgainFewSeconds")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                            <Grid item>
                            <Button                                                        
                                color="primary" variant="outlined"
                                onClick={this.loadDashBoard} >
                                    {t("buttons.TryAgain")}
                            </Button>
                            </Grid>
                        </Grid>
                    </>
                }

                {
                data && 
                <React.Fragment>
                    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="stretch" >
                        <Grid item xs={12} sm={3}>
                            <Card className={classes.card}>
                                <CardActionArea>
                                    <CardMedia className={classes.media}
                                        image={data.Customer.Avatar}
                                        title={data.Customer.Name}
                                    />
                                    <CardContent>                                
                                        <Typography align="center" className={classes.customer} gutterBottom variant="h5" component="h2">
                                            {data.Customer.Name.toLowerCase()}
                                        </Typography>
                                        <Typography align="center" gutterBottom variant="body1" >
                                            {data.Customer.RegistrationCode}
                                        </Typography>
                                        <Typography align="center" gutterBottom>
                                            {data.Customer.Email}, {data.Customer.Phone} 
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                    <Grid item xs={12} sm={4}>
                        <Paper className={classes.paper} elevation={1} >
                            <Grid container justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6">{t("reports.dashboard.Consultant_Current_Balance")}</Typography>                                
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>    
                            
                            <Grid container justify="flex-start" alignItems="stretch" spacing={2}>
                                {
                                data.DashBoard.Consultant_Current_Balance.map((item, idx) => (
                                <Grid item xs={12} sm={6} key={idx}>
                                    <InfoPanel
                                        title={ t(`reports.dashboard.${(item["Источник"] === "" ? "Other source" : item["Источник"])}`) }
                                        value={ parseFloat(item["Сумма"]).toFixed(2) }></InfoPanel>
                                </Grid>
                                ))
                                } 
                            </Grid>    
                            
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper} elevation={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6">{t("reports.dashboard.Consultant_Current_Data")}</Typography>
                                    <Divider className={classes.divider} />
                                </Grid>
                            </Grid>
                            
                            <>
                                {
                                    data.DashBoard.Consultant_Current_Data.map((item, i) => (
                                        <Grid key={i} container justify="flex-start" alignItems="stretch" spacing={2}>
                                        {
                                            Object.keys(item).map((field) => (
                                                <Grid item key={field} xs={12} sm={4} >                                                
                                                    <InfoPanel title={t(`reports.dashboard.${field}`)}
                                                        value={ isNaN(parseFloat(item[field])) ? item[field] : parseFloat(item[field]).toFixed(2) }
                                                    ></InfoPanel>
                                                </Grid>
                                            ))
                                        }
                                        </Grid>
                                    ))
                                }
                            </>                        
                        </Paper>
                    </Grid>
                    
                    </Grid>
                    
                    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="stretch" >
                        <Grid item xs={12} sm={7}>
                            <Paper className={classes.paper} elevation={1} >
                            <TableContainer >
                                <Table className={classes.table} aria-label="Curent_Period_Statistics">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{ t("reports.dashboard.CurrentStructure") }</TableCell>
                                            <TableCell>{ t("reports.dashboard.BussinesSturcuture") }</TableCell>
                                            <TableCell> { t("reports.dashboard.FirstLine") }</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { cps ? 
                                        <Fragment>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.AuthorizedUser") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.AuthorizedUser}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_AuthorizedUser}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.Consultant") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.Consultant}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_Consultant}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.total}>
                                                { t("reports.dashboard.AllStructure") }
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.Registered}
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.FirstLine_Registered}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.AT_ActiveAuthorizedUser") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.AT_ActiveAuthorizedUser}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_AT_ActiveAuthorizedUser}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.AT_ActiveConsultant") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.AT_ActiveConsultant}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_AT_ActiveConsultant}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.total}>
                                                { t("reports.dashboard.AT_ActiveRegistered") }
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.AT_ActiveRegistered}
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.FirstLine_AT_ActiveRegistered}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.NT_NewAuthorizedUser") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.NT_NewAuthorizedUser}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_NT_NewAuthorizedUser}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.NT_NewConsultant") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.NT_NewConsultant}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_NT_NewConsultant}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.total}>
                                                { t("reports.dashboard.NT_NewRegistered") }
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.NT_NewRegistered}
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.FirstLine_NT_NewRegistered}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.AZNT_NewCurrentPeriodActiveConsultant") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.AZNT_NewCurrentPeriodActiveConsultant}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_AZNT_NewCurrentPeriodActiveConsultant}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.ANT_NewOtherPeriodActiveConsultant") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.ANT_NewOtherPeriodActiveConsultant}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_ANT_NewOtherPeriodActiveConsultant}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.N_AN_T_NewCurrentPeriodConsultantNonActive") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.N_AN_T_NewCurrentPeriodConsultantNonActive}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_N_AN_T_NewCurrentPeriodConsultantNonActive}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.N_AN_NewLastClosedPeriodConsultantNonActive") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.N_AN_NewLastClosedPeriodConsultantNonActive}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_N_AN_NewLastClosedPeriodConsultantNonActive}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                { t("reports.dashboard.N_AN3_NewMoreThan3PeriodConsultantNonActive") }
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.N_AN3_NewMoreThan3PeriodConsultantNonActive}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cps.FirstLine_N_AN3_NewMoreThan3PeriodConsultantNonActive}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.total}>
                                                { t("reports.dashboard.АpplicantForTermination") }
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.АpplicantForTermination}
                                            </TableCell>
                                            <TableCell align="center" className={classes.total}>
                                                {cps.FirstLine_АpplicantForTermination}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.bold}>
                                                { t("reports.dashboard.Kept") }
                                            </TableCell>
                                            <TableCell align="center" className={classes.bold}>
                                                {cps.Kept}
                                            </TableCell>
                                            <TableCell align="center" className={classes.bold}>
                                                {cps.FirstLine_Kept}
                                            </TableCell>
                                        </TableRow>
                                        </Fragment> : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </Paper>                
                        </Grid>

                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper} elevation={1} >
                        <TableContainer >
                            <Table className={classes.table} aria-label="Curent_Period_Statistics_BS">
                                <TableHead>
                                    <TableRow>
                                        <TableCell> { t("reports.dashboard.StructureLastCompany") }</TableCell>
                                        <TableCell>&nbsp;</TableCell>                                    
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { clps ? 
                                    <Fragment>
                                    {                            
                                    Object.keys(clps).map((field) => (
                                        (clps[field] !== 0 ?
                                        <TableRow key={field}>
                                            <TableCell component="th" scope="row">
                                                { t(`reports.dashboard.${field}`) }
                                            </TableCell>
                                            <TableCell align="center">
                                                {clps[field]}
                                            </TableCell>
                                        </TableRow> : null )
                                    ))
                                    }

                                    </Fragment> : null
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Paper>
                    </Grid>
                                                    
                    </Grid>
                </React.Fragment>
                }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((Dashboard))));

