import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
    AppBar,
    Toolbar,
    Breadcrumbs,
    Link,
    Grid,
    Dialog,
    DialogContentText,
    Button,
    IconButton,
    TextField,
    Select,
    InputLabel,
    Typography,
    MenuItem,
    Paper,
    Divider,
    Menu,    
    FormGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
} from "@material-ui/core";

import MoreVert from "@material-ui/icons/MoreVert";

import CreateNewFolder from "@material-ui/icons/CreateNewFolderOutlined";
import CloudUpload from "@material-ui/icons/CloudUploadOutlined";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import SyncIcon from "@material-ui/icons/Sync";
import HomeIcon from "@material-ui/icons/Home";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

import { DialogActions, DialogContent, DialogTitle } from "../CustomDialog";

import gray from "@material-ui/core/colors/grey";

// import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
// import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer'

import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/FilesBuilder";
import { connect } from "react-redux";

// import { FilePond, registerPlugin} from 'react-filepond';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

// import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// // Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
// registerPlugin(FilePondPluginFileMetadata);

const styles = (theme) => ({
    root: {
        padding: theme.spacing(1, 1, 2, 1),
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
    appbar: {
        marginBottom: theme.spacing(1),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow,
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: 0,
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 12,
        padding: theme.spacing(2),
        boxShadow: theme.palette.ambilight.boxshadow,
    },
    iconButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    files: {
        marginLeft: theme.spacing(2),
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
        // position: 'relative',

        // marginRight: theme.spacing(2),
        // marginLeft: 0,
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //   marginLeft: theme.spacing(3),
        //   width: 'auto',
        // },
    },
    listitem: {
        marginTop: theme.spacing(1),
        borderRadius: 12,
        // borderTop: `1px solid ${theme.palette.divider}`,
        // borderLeft: `1px solid ${theme.palette.divider}`,
        // borderRight: `1px solid ${theme.palette.divider}`,
        border: `1px solid ${theme.palette.divider}`,
        // boxShadow: theme.palette.ambilight.boxshadow
    },

    preview: {
        maxWidth: "100%",
        height: "auto",
    },
    button: {
        margin: 0,
        padding: 0,
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    input: {
        backgroundColor: "#eee",
        padding: "2px 15px",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },

    crumbs: {
        marginRight: theme.spacing(1) * 2,
        fontSize: 24,
    },

    colorGray: {
        backgroundColor: gray[200],
    },
    mr: {
        marginRight: theme.spacing(1),
    },
    link: {
        display: "flex",
        "&:hover": {
            cursor: "pointer",
        },
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
});

const ITEM_HEIGHT = 38;

const extractFileExt = (path) => {
    const pathArray = path.split(".");
    const lastIndex = pathArray.length - 1;
    return pathArray[lastIndex];
};

const allowPreview = (row) => {
    if (row.isDirectory) return false;

    const ext = extractFileExt(row.title);
    if (
        ext === "jpg" ||
        ext === "jpeg" ||
        ext === "png" ||
        ext === "gif" ||
        ext === "webp"
    )
        return true;

    return false;
};

class Files extends Component {
    constructor(props) {
        super(props);

        const year = new Date().getFullYear();
        this.state = {
            //path: this.props.match.params.path || "",
            path: "",
            searchString: "",
            searchFocusIndex: 0,
            searchFoundCount: null,
            anchorEl: null,
            openFolder: false,
            openDelFolder: false,
            openUpload: false,
            
            openInterActiveDlg: false,
            year: year,
            qtyCatalogs: 17,

            fullWidth: true,
            maxWidth: "sm",
            folderName: "",
            isNewFolder: true,
            selectedPath: "",
            acceptedFileTypes: [
                "jpg",
                "jpeg",
                "png",
                "gif",
                "webp",
                "pdf",
                "doc",
                "docx",
                ".xls",
                ".xlsx",
                "txt",
            ], // ["image/*", "audio/*", "video/*"],
            resize: true,
            thumb: true,
            openDlgPreview: false,
            selectedImg: undefined,
        };
        // this.props.requestFiles(this.props.match.params.path || "");
        this.props.requestFiles("");
        

    }

    // This method runs when incoming props (e.g., route params) change
    // componentWillReceiveProps(nextProps) {
    //   this.setState(
    //       { path: nextProps.match.params.path || "" }
    //   );
    //   this.props.requestFiles(nextProps.match.params.path);
    // };

    handleNewFolder = () => {
        this.setState({ folderName: "", openFolder: true, isNewFolder: true });
    };

    handleCopyPath = () => {
        this.setState({ anchorEl: null });
        navigator.clipboard.writeText(
            `/assets/files/${this.state.selectedPath}`
        );
    };

    handleEdit = () => {
        this.setState({ anchorEl: null });
        const folers = this.state.selectedPath.split("/");
        let folderName = "";
        if (folers.length > 0) {
            folderName = folers[folers.length - 1];
        }
        this.setState({
            folderName: folderName,
            openFolder: true,
            isNewFolder: false,
        });
    };

    handleDelete = () => {
        const folers = this.state.selectedPath.split("/");
        let folderName = "";
        if (folers.length > 0) {
            folderName = folers[folers.length - 1];
        }
        console.log(this.state.selectedPath, folderName);
        this.setState({
            anchorEl: null,
            folderName: folderName,
            openDelFolder: true,
        });
    };

    handleClickDelete = () => {
        this.setState({ openDelFolder: false });
        const path =
            this.props.path.length === 0
                ? this.state.folderName
                : `${this.props.path}/${this.state.folderName}`;
        this.props.requestDelFolder(path);
    };

    handleSaveFolder = () => {
        this.setState({ openFolder: false });

        if (this.state.isNewFolder) {
            const path =
                this.props.path.length === 0
                    ? this.state.folderName
                    : `${this.props.path}/${this.state.folderName}`;
            this.props.requestAddFolder(path);
        } else {
            this.props.requestRename(
                this.state.selectedPath,
                this.state.folderName
            );
        }
    };

    handleOpenDlgPreview = (opened) => {
        this.setState({ openDlgPreview: opened });
    };

    handlePreview = (row) => {
        this.setState({ openDlgPreview: true, selectedImg: row });
    };

    handleOpenDlgDelFolder = (opened) => {
        this.setState({ openDelFolder: opened });
    };

    handleOpenDlgFolder = (opened) => {
        this.setState({ openFolder: opened });
    };

    handleInterActiveDlg = (state) => {
        this.setState({openInterActiveDlg: state});
    }

    handleOpenDlgUpload = (opened) => {
        this.setState({ openUpload: opened });
    };

    handleOpenMenu = (event, path) => {
        this.setState({ anchorEl: event.currentTarget, selectedPath: path });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    loadFiles = (path) => {
        this.props.requestFiles(path);
    };

    handleInit = () => {
        this.pond.props.server.process.headers = {
            ...this.pond.props.server.process.headers,
            Authorization: this.props.token,
        };
        // console.log('FilePond instance has initialised',  this.pond);
    };

    handleChangeResize = () => {
        const { resize } = this.state;
        this.setState({ resize: !resize });
    };

    handelRefresh = () => {
        const { fullPath } = this.props;

        if (fullPath && fullPath.length > 0) {
            const path = fullPath[fullPath.length - 1].Path;
            console.log(path);

            this.loadFiles(path);
        }
    };

    handleSelectFile = (file) => {
        const { onSelect } = this.props;
        if (onSelect) onSelect(file);
    };
    
    handleCreateFolders = () => {
        const data = {
            path: this.props.path,
            year: this.state.year,
            catalogs: this.state.qtyCatalogs
        };
        
        const url =  '/api/v1/files/interactivecatalogstructure';

        fetch(url, {
            method: 'POST',
            headers: {
                authorization: this.props.token,
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify(data)
        })
        .catch(error => {
            console.log("Error", error);
        })
        .finally(()=> {
            this.setState({openInterActiveDlg: false});
            this.handelRefresh();
        })
    } 
    
    copyUrl = (txt) => {       
        navigator.clipboard.writeText(txt);        
    }
    
    secondaryText = (row) => {
        if(row.isDirectory) return null;
        
        const { classes } = this.props;
        let path = '';
        if(row.Path.toLowerCase().endsWith('.pdf'))
        {
            path = `/viewer?url=assets/files/${row.Path}&p=1`;
            const icatalog = `/interactivecatalog?url=assets/files/${row.Path}&p=1`;
            return (<>
                <span className={classes.iconButton}>{path}</span> 
                <IconButton className={classes.iconButton} size='small' onClick={e => this.copyUrl(path)} title="File" >
                    <PictureAsPdfOutlinedIcon fontSize='small' />
                </IconButton>
                <IconButton size='small' onClick={e => this.copyUrl(icatalog)} title="Catalog" >
                    <PhotoLibraryOutlinedIcon fontSize='small' />
                </IconButton>
            </>);
        }
        
        path = `/assets/files/${row.Path}`;

        return (<>
            <span className={classes.iconButton}>{path}</span> 
            <IconButton size='small' onClick={e => this.copyUrl(path)} >
                <FileCopyOutlinedIcon fontSize='small' />
            </IconButton>
        </>);
    }

    render() {
        const { anchorEl, openDlgPreview, selectedImg, resize, thumb, 
                openInterActiveDlg, year, qtyCatalogs } = this.state;
        const openMenu = Boolean(anchorEl);
        const { classes, onSelect } = this.props;
        const rootImg = "/assets/files/";
        
        const years = [];
        for (let i = 0; i < 5; i++) {
            years.push(year + i);
        }

        return (
            <React.Fragment>
                <Paper className={classes.root} elevation={0}>
                    <AppBar
                        className={classes.appbar}
                        position="static"
                        color="inherit"
                        elevation={0}
                    >
                        <Toolbar variant="dense" className={classes.toolbar}>
                            <IconButton
                                className={classes.iconButton}
                                onClick={this.handleNewFolder}
                                title='New foleder'
                            >
                                <CreateNewFolder />
                            </IconButton>
                            <IconButton
                                className={classes.iconButton}
                                onClick={this.handleOpenDlgUpload.bind(
                                    this,
                                    true
                                )}
                                title='Upload file'                                
                            >
                                <CloudUpload />
                            </IconButton>
                            <IconButton
                                className={classes.iconButton}
                                onClick={this.handelRefresh}
                                title='Refresh'
                            >
                                <SyncIcon />
                            </IconButton>
                            <IconButton
                                color='secondary'
                                className={classes.iconButton}
                                onClick={()=>this.setState({openInterActiveDlg: true})}
                                title='Structure interactive catalog'
                            >
                                <AccountTreeIcon />
                            </IconButton>
                            <div className={classes.files}>
                                <Breadcrumbs
                                    aria-label="breadcrumb"
                                    separator="›"
                                >
                                    {this.props.fullPath.map((link) => (
                                        <Link
                                            key={link.Path}
                                            color="inherit"
                                            className={classes.link}
                                            onClick={() => {
                                                this.loadFiles(link.Path);
                                            }}
                                        >
                                            {link.title === "Files" ? (
                                                <HomeIcon
                                                    className={classes.icon}
                                                />
                                            ) : (
                                                link.title
                                            )}
                                        </Link>
                                    ))}
                                </Breadcrumbs>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/* <p>
                        Path: {this.props.path}
                    </p> */}

                    <List component="nav">
                        {this.props.treeFiles.map((row, index) => (
                            <ListItem
                                key={index}
                                button
                                className={classes.listitem}
                                onClick={
                                    row.isDirectory
                                        ? () => this.loadFiles(row.Path)
                                        : null
                                        // : () => {
                                        //       navigator.clipboard.writeText(
                                        //           `/assets/files/${row.Path}`
                                        //       );
                                        //   }
                                }
                            >
                                <ListItemIcon>
                                    {row.isDirectory ? (
                                        <FolderOutlinedIcon color="primary" />
                                    ) : (
                                        <InsertDriveFileOutlinedIcon />
                                    )}
                                </ListItemIcon>

                                <ListItemText
                                    primary={row.title}
                                    secondary={ this.secondaryText(row) }
                                />
                                <ListItemSecondaryAction>
                                    {onSelect && !row.isDirectory && (
                                        <Button
                                            size="small"
                                            aria-label="select-file"
                                            onClick={() =>
                                                this.handleSelectFile(
                                                    `/assets/files/${row.Path}`
                                                )
                                            }
                                        >
                                            <small>Select</small>
                                        </Button>
                                    )}

                                    {allowPreview(row) ? (
                                        <IconButton
                                            aria-label="preview"
                                            onClick={this.handlePreview.bind(
                                                this,
                                                row
                                            )}
                                        >
                                            <VisibilityOutlinedIcon />{" "}
                                        </IconButton>
                                    ) : null}

                                    <IconButton
                                        onClick={(e) =>
                                            this.handleOpenMenu(e, row.Path)
                                        }
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Paper>

                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={this.handleCloseMenu}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    <MenuItem onClick={this.handleEdit}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Rename
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={this.handleDelete}>
                        <ListItemIcon>
                            <ClearIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Delete
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleCopyPath}>
                        <ListItemIcon>
                            <FileCopyOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Copy path
                        </Typography>
                    </MenuItem>
                </Menu>

                {/* Preview Dialog */}
                <Dialog
                    fullWidth={this.state.fullWidth}
                    maxWidth="md"
                    open={openDlgPreview}
                    onClose={this.handleOpenDlgPreview.bind(this, false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="dlg-preview"
                        onClose={this.handleOpenDlgPreview.bind(this, false)}
                    >
                        Preview:{" "}
                        {selectedImg ? `${rootImg}${selectedImg.Path}` : null}
                    </DialogTitle>
                    <DialogContent style={{ height: 500 }}>
                        {selectedImg && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <img
                                        alt={selectedImg.Path}
                                        className={classes.preview}
                                        src={`${rootImg}${selectedImg.Path}`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleOpenDlgPreview.bind(
                                this,
                                false
                            )}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* InterActive Dialog */}
                <Dialog
                    fullWidth={this.state.fullWidth}
                    maxWidth="md"
                    open={openInterActiveDlg}
                    onClose={()=> this.handleInterActiveDlg(false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="dlg-interactive"
                        onClose={()=> this.handleInterActiveDlg(false)}
                    >
                        Create interactive catalog folder structure                        
                    </DialogTitle>
                    <DialogContent style={{ height: 'auto' }}>
                        <Grid 
                            container
                            direction="row"
                            spacing={2}
                            
                        >
                                <Grid item>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="select-year">Year</InputLabel>
                                        <Select
                                        labelId="select-year"
                                        id="select-year-ctl"
                                        value={year}
                                        onChange={(e)=> this.setState({year: e.target.value })}
                                        label="Year"
                                        >
                                        {years.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem> )}                                    
                                        </Select>
                                    </FormControl>                            
                                </Grid>
                                <Grid item xs={4} sm={3} >
                                <TextField 
                                    fullWidth                                   
                                    label="Number of catalogs per year"
                                    type="number"
                                    value={qtyCatalogs}                                    
                                    InputProps={{
                                        inputProps: { 
                                            max: 50, min: 1 
                                        }
                                    }}
                                    variant="outlined"
                                    onChange={(e)=>this.setState({qtyCatalogs: parseInt(e.target.value)})}
                                    />
                                </Grid>
                            </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleInterActiveDlg(false )}> 
                            Cancel
                        </Button>

                        <Button color='primary' onClick={() => this.handleCreateFolders()}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* Folder Dialog */}
                <Dialog
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.state.openFolder}
                    onClose={this.handleOpenDlgFolder.bind(this, false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="dlg-folder-title"
                        onClose={this.handleOpenDlgFolder.bind(this, false)}
                    >
                        Folder
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.temptxt}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="folderName"
                            label="Folder name"
                            type="text"
                            fullWidth
                            value={this.state.folderName}
                            onChange={this.handleChange("folderName")}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleOpenDlgFolder.bind(this, false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveFolder.bind(this)}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* Delete folder Dialog */}
                <Dialog
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.state.openDelFolder}
                    onClose={this.handleOpenDlgDelFolder.bind(this, false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="dlg-delfolder-title"
                        onClose={this.handleOpenDlgDelFolder.bind(this, false)}
                    >
                        Delete folder: {this.state.folderName} ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.temptxt}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleOpenDlgDelFolder.bind(
                                this,
                                false
                            )}
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.handleClickDelete}>Yes</Button>
                    </DialogActions>
                </Dialog>
                
                {/* Upload file Dialog */}
                <Dialog
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.state.openUpload}
                    onClose={this.handleOpenDlgUpload.bind(this, false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="dlg-upload-title"
                        onClose={this.handleOpenDlgUpload.bind(this, false)}
                    >
                        Upload
                    </DialogTitle>
                    <DialogContent>
                        <FilePond
                            ref={(ref) => (this.pond = ref)}
                            files={this.state.files}
                            allowMultiple={true}
                            allowFileSizeValidation={true}
                            maxFileSize={100}
                            labelMaxFileSizeExceeded={"File is too large"}
                            // oninit={() => this.handleInit() }
                            acceptedFileTypes={this.state.acceptedFileTypes}
                            server={{
                                process: {
                                    url: `/api/v1/files/upload?path=${this.props.path}`,
                                    headers: {
                                        Authorization: this.props.token,
                                    },
                                    ondata: (formData) => {
                                        formData.append("thumb", thumb);
                                        formData.append("resize", resize);
                                        return formData;
                                    },
                                },
                            }}
                        ></FilePond>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={resize}
                                        onChange={this.handleChangeResize}
                                        name="resize"
                                    />
                                }
                                label="Create thumbnails for image files"
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleOpenDlgUpload.bind(this, false)}
                        >
                            Close
                        </Button>
                        {/* <Button onClick={this.handleOpenDlgUpload}  >
            Upload
        </Button> */}
                    </DialogActions>
                </Dialog>



            </React.Fragment>
        );
    }
}

Files.propTypes = {
    onSelect: PropTypes.func,
};

export default withStyles(styles)(
    connect(
        (state) => state.filesBuilder,
        (dispatch) => bindActionCreators(actionCreators, dispatch)
    )(Files)
);
