import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {BlockType} from "../../../definitions/pageblocktype";

import TitleBlock from "./TitleBlock";
import HtmlBlock from "./HtmlBlock";
import VideoLinkBlock from "./VideoLinkBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import LogoImage from "./LogoImage";
import BonuseNewcomer from "./Widgets/BonuseNewcomer";
import ContentFiles from "./Widgets/ContentFiles";
import PdfBlock from "./PdfBlock";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center'
        },
        
        designblock: {
            border: `1px dashed ${theme.palette.divider}`,
        },
        
        childblock: {
            margin: theme.spacing(1/2),
            padding: theme.spacing(1/2),
            border: `1px dashed ${theme.palette.divider}`,
        },
        controls: {
            position:'relative',            
        },
        buttons: {
            position: 'absolute',                        
            bottom: theme.spacing(-2),
            right: theme.spacing(-1),            
        },
        button: {
            padding: 2,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.palette.ambilight.boxshadow
        }

    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const ContainerBlock = props => {
    const { block, design, onEdit } = props;
    const classes = useStyles();
    
    const handleEdit = (content, blockType) => {
        if(onEdit){
            onEdit(content, blockType);
        }
    }

  return (
    <div className={classes.root}>
        {block &&                 
        <div className={clsx(classes.controls, design ? classes.childblock: '')}>
            
            {block.PageBlockType === BlockType.TITLE &&
                <TitleBlock localValue={ block.Content.LocalValues[0]} blockKey={ block.Key } propsBlock={block.Content.Props} />
            }

            {block.PageBlockType === BlockType.HTMLBLOCK &&
                <HtmlBlock localValue={ block.Content.LocalValues[0]} blockKey={ block.Key }/>
            }

            {block.PageBlockType === BlockType.IMAGE &&
                <ImageBlock url={ block.Content.LocalValues[0].Value} />
            }

            {block.PageBlockType === BlockType.BIGSLIDER && 
                <BigSlider slider={ block.Content } blockKey={ block.Key } autoplay={ false } />
            }

            {block.PageBlockType === BlockType.MIDDLESLIDER && 
                <MiddleSlider slider={ block.Content } blockKey={ block.Key } />
            }

            {block.PageBlockType === BlockType.SMALLSLIDER && 
                <SmallSlider slider={ block.Content } blockKey={ block.Key } />
            }

            {block.PageBlockType === BlockType.LOGOIMAGE && 
                <LogoImage content={ block.Content } blockKey={ block.Key } />
            }

            {block.PageBlockType === BlockType.WGBONUSENEWCOMER && 
                <BonuseNewcomer data={ block.Content.Data } blockKey={ block.Key } />
            }

            {block.PageBlockType === BlockType.PDFVIEWER && 
                <PdfBlock url={ block.Content.LocalValues[0].Value } blockKey={ block.Key } />
            }

            {block.PageBlockType === BlockType.VIDEOLINK && 
                <VideoLinkBlock url={ block.Content.LocalValues[0].Value} shortVideo={block.Content.Props.ShortVideo === 'true' } />
            }

            {block.PageBlockType === BlockType.VIDEO && 
                <VideoBlock url={ block.Content.LocalValues[0].Value} />
            }


            {onEdit &&
            <div className={classes.buttons}>
                <IconButton                                 
                    className={classes.button} 
                    size='small' 
                    onClick={()=> handleEdit(block.Content, block.PageBlockType)} 
                    aria-label="edit content" 
                    title='Edit item' 
                >
                    <EditIcon fontSize='small' />
                </IconButton>
            </div> 
            }
        </div>                                
        }
    </div>
  )
}

ContainerBlock.propTypes = {
    block: PropTypes.object.isRequired,
    design: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
}

export default ContainerBlock;