import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types';
import HtmlEditor from '../Manage/HtmlEditor/HtmlEditor';
import { roles } from '../../definitions/roles';

import { useParams, Link } from "react-router-dom";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Paper, Box, IconButton } from "@material-ui/core";

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            borderRadius: 12,
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        },
        appbar: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbar: {
            paddingLeft: 0,
            paddingRight: 0,            
        },
        button: {
            marginRight: theme.spacing(1)
        }
    })
);

const ContenEditor = (props) => {
    const classes = useStyles();
    const app = useContext(AppContext);
    const { lang, country } = app;
    const { contenttype, id } = useParams();
    const [token, setToken] = useState(null);
    const refHtmlEditor = useRef();
    const [langs, setLangs] = useState([]);
    const [article, setArticle] = useState();

    const onSave = (doc, typeblock) => {
        if (!typeblock) return;
                
        // console.log('Save doc', doc);

        const URL = "/api/v1/content";
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, {
            method: doc.Id === 0 ? "POST" : "PUT",
            headers: headers,
            body: JSON.stringify(doc)
        })
        .then(response => response.json())
            .then(saved => {
                setArticle(saved);
                window.history.pushState(null, '', `/contenteditor/${typeblock}/${saved.Key}`);

            })
            .catch((error) => {
                console.log("Error SaveHtmlBlock", error);
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const handleSave = () => {
        refHtmlEditor.current.updateData();
    };

    // Get content
    useEffect(() => {        
        if(!token || !contenttype )
            return;
        if(id){
            const url = `/api/v1.0/content/${id}/true/${lang}/${country}`;
            // console.log('Get content', url);        
            fetch(url,
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                }
            })
            .then(response => response.json())
            .then(data => {                
                // if(data){
                //     prepareData(data, 0, langs);
                // }
                setArticle(data);
            })
            .catch((error) => {
                console.log('Error', error);
            })
            .finally(() => { }); 
        }else{
            const today = new Date();
            const newdate = new Date();
            newdate.setDate(today.getDate()+36524);
            const data = {
                Id: 0,
                Key: "",
                Name: "",
                TopBanner: "",
                TopMobileBanner: "",    
                IsActive: false,
                StartedOn: today,
                FinishedOn: newdate,
                Roles: "",
                ContentType: 0,    
                LocalValues: [],    
                Lang: "uk",
                Countries: []
            };
            setArticle(data);
        }

        

    }, [token, contenttype, id, lang, country]);

    // Get langs
    useEffect(() => {
        fetch('/api/v1.0/utils/getcultures',
        {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
        .then(response => response.json())
        .then(data => {                
            setLangs(data)
        })
        .catch((error) => {
            console.log('Error', error);
        })
        .finally(() => { });
}, []);

    // Get token
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }                
                setToken(token);
            });
        }

        fetchUser();

    }, [app]);

    return (
        <AppConsumer>
            {() => (
            <Paper className={classes.root}>
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                    <Toolbar variant="dense" className={classes.toolbar}> 
                    <IconButton className={classes.button}  disabled={!token} component={Link} to={`/content/${contenttype}`}>
                            <ArrowBackIcon />
                        </IconButton>
                        
                        <IconButton onClick={handleSave} disabled={!token}>
                            <SaveIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                

                {article && 
                    <HtmlEditor
                        ref={refHtmlEditor}
                        roles={roles}
                        token={token}
                        typeblock='page'
                        keyDisabled={false}
                        article={article}
                        langs={langs}
                        onSave={onSave} 
                    />
                }

                <Typography component="div" align="right" variant="caption" display="block" gutterBottom>
                    <pre>
                        <code>
                            Help : &#60;div class=&quot;__se__tag your_class&quot;&#62;Other a tags&#60;/div&#62;
                        </code>
                    </pre>
                </Typography>             
                                
            </Paper>
        )}
        </AppConsumer>        
    )
}

ContenEditor.propTypes = {

}

export default ContenEditor
