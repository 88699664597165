import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { actionCreators } from "../../store/NavItems";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions,
         Snackbar, Button, CircularProgress } from "@material-ui/core";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import Topline from './Topline';
import LeftSwipeable from './LeftSwipeable';
import TopAppBar from './TopAppBar';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
        },
    })
);

export const Navigator = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
        
    const app = useContext(AppContext);
    
    const dispatch = useDispatch();    
    const appLayout = useSelector((state) =>  state.appLayout );
    const {isLoading, snackbarTxt, variant, severity,  snackbarShow, duration, Adviser} = appLayout;

    const [showLeftSwipeable, setShowLeftSwipeable] = useState(false);
    const [showDlgGiftCard, setShowDlgGiftCard] = useState(false);
    const [certificateCode, setCertificateCode] = useState('');
    

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }        
        setShowLeftSwipeable(open);        
    };

    const showDlgCertificateCode = () => {
        setShowDlgGiftCard(true);
    };

    const applayCertificateCode = () => {
        if(certificateCode.length === 0) return;
        app.getUser().then(user => {                     
            dispatch(actionCreators.applayPromoCode(certificateCode, user));
        });                          
    };
    
    const hideSnackBar = () => {        
        dispatch(actionCreators.hideSnackBar());
    };

    const changeLang = (lang) => {        
        // const URL = `/api/v1/Languege/${lang.Name}`;                                            
        // fetch(URL)
        //   .then(() => {                                
        //     dispatch(actionCreators.requestNavItems());
        //   })        
        // .catch(error => console.log(error))
        // .finally(() => {          
        // });
    };

    useEffect (()=> {
        if(!Adviser) return;
        app.changeCountry(Adviser.Country);
    },
    [app, Adviser])

    // Get user
    useEffect(() => {
        function fetchUser() {
            app.getUser().then(async user => {                
                dispatch(actionCreators.setAppUser(user));
                dispatch(actionCreators.requestNavItems(app.country));
                if(user){
                    dispatch(actionCreators.getCustomer());
                }
            });
        }
        dispatch(actionCreators.requestLangs());
        fetchUser();
    }, [app, dispatch]);
  return (
    <AppConsumer>
        {() => (
            <div className={classes.root}>
                {appLayout && appLayout.TopLine && appLayout.TopLine.LocalValues && appLayout.TopLine.LocalValues.length > 0  &&
                    <Topline html={appLayout.TopLine.LocalValues[0].Value}  />
                }
                
                <LeftSwipeable 
                    open={showLeftSwipeable} 
                    toggleDrawer={toggleDrawer} 
                    showDlgCertificateCode={showDlgCertificateCode}
                    onChangeLang={changeLang}
                />
                
                <TopAppBar 
                    open={showLeftSwipeable} 
                    toggleDrawer={toggleDrawer} 
                    showDlgCertificateCode={showDlgCertificateCode}
                />

                <Dialog 
                    open={ showDlgGiftCard }
                    fullWidth={false}
                    onClose={() => setShowDlgGiftCard(false) }
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{t("appbar.menu.CertificateCode")}</DialogTitle>
                    <DialogContent style={{minHeight: 130}}>
                        <DialogContentText>
                            {t("Enter the gift certificate or promotional card code")}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            variant="outlined"
                            value={certificateCode}
                            id="CertificateCode"
                            name="CertificateCode"
                            label={t("appbar.menu.CertificateCodeInput")}
                            type="text"
                            fullWidth
                            error={variant === 'error'}
                            helperText={t(snackbarTxt)}
                            onChange={e => setCertificateCode(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            disabled={isLoading} 
                            onClick={applayCertificateCode} 
                            color="primary">
                            {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{              
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                                }}
                            />
                            )}  {t("buttons.Applay")}
                        </Button>
                    
                        <Button onClick={() => setShowDlgGiftCard(false)} color="primary">
                            {t("buttons.Cancel")}
                        </Button>
                    
                    </DialogActions>
                </Dialog>        
      

                <Snackbar open={snackbarShow} autoHideDuration={duration}
                    onClose={hideSnackBar} 
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} 
                >
                    <Alert onClose={hideSnackBar} severity={severity} variant={variant}>
                        <div style={{whiteSpace: "pre-line"}}>
                            {t(snackbarTxt)}
                        </div>
                    </Alert>
                </Snackbar> 

            </div>
        )}
    </AppConsumer>
  )
}

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Navigator)

export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Navigator)