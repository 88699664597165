import React, { useState, useContext, useCallback, useEffect, useRef} from "react";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { AppBar, Toolbar, Grid, Typography, Collapse, Box, TextField, FormControl, InputLabel, Select,
        MenuItem, OutlinedInput, InputAdornment, Button, IconButton, Paper, SnackbarContent, Snackbar, Chip } from "@material-ui/core";
import  { Link as RouterLink } from "react-router-dom";
import { DialogActions, DialogContent, DialogTitle } from "../../CustomDialog";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from "react-i18next";
import { useWidth} from '../../../helpers/utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FaceIcon from '@material-ui/icons/Face';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import gray from "@material-ui/core/colors/grey";
import SyncIcon from '@material-ui/icons/Sync';

import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },

        appbar: {},

        toolbar: {
            paddingLeft: theme.spacing(1),
        },
        toolbarButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },

        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            padding: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow,
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        formControl: {            
            minWidth: 120,
        },
        inputLabel: {
            padding: theme.spacing(0, 1),
            backgroundColor: theme.palette.background.paper,
        },
        send: {
            padding: theme.spacing(2, 0)
        },
        users: {
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(0.5),
            },
        },
        default: {
            backgroundColor: gray[900],
            color: gray[100]
        }
    })
);

const severities = ["default", "error", "success",  "warning",  "info"];
const variants = ["filled", ];

const Notifications = props => {
    const app = useContext(AppContext);
    // const width = useWidth();
    const classes = useStyles();
    const { t } = useTranslation();
    const [token, setToken] = useState();

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [search, setSearch] = useState('');

    const refRoot = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [notification, setNotification] = useState({
        message: "",
        // users: ['1100207', '1100008'],
        users: [],
        severity: 'info',
        variant: 'variant',
        duration: 3      
    })
    const [regNumbers, setRegNumbers] = useState('');

    const loadConnected = useCallback(() => {
        if (!token) return;
        
        fetch("/api/v1.0/notification/connectedusers", {
            headers: { authorization: token },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setData(data);                    
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });

    }, [token]);


    const handleChange = (e) => {
        setNotification({
            ...notification,
            [e.target.name]: e.target.value
        });
    }

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const handleSend = () => {
        if(notification.message === ''){
            window.alert('Empty message');
            return;
        }
        if(notification.users.length === 0){
            window.alert('Empty users list');
            return;
        }

        const url = "/api/v1.0/notification/push";                
        const method = "POST";
        fetch(url, {
            method: method,
            headers: {
                authorization: token,
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(notification),
        }).then((response) => {
            if(response.status === 200) {
                console.log(`${response.status} ${response.statusText}`);
                // response.json().then((data) => {
                //     console.log("After save", data);
                //     setContent(data);
                //     window.history.pushState(
                //         null,
                //         "",
                //         `/manage/pageeditor/${data.Key}`
                //     );
                // });
            } else {
                console.log(`${response.status} ${response.statusText}`);
            }
        })
        .catch((error) => {
            console.log("Error", error);
        })
        .finally(() => {
            setShowBackdrop(false);
        });

    };

    const handleDelete = (user) => {
        // console.info('You clicked the delete icon.');
        const idx = notification.users.findIndex((r) => r === user);
        if (idx > -1) {
            setNotification({
                ...notification,
                users: notification.users.filter((r) => r !== user),
            });
        }
    };

    const handleClear = () => {
        setRegNumbers('');
        setNotification({
            ...notification,
            users: [],
        });
    };

    const addNumber = (word) => {
        const idx = notification.users.findIndex((r) => r === word);
        if(idx !== -1)
            return;
        
        const regnumbers = [...notification.users];
        regnumbers.push(word);

        setNotification({
            ...notification,
            users: regnumbers,
        });
        setExpanded(true)
;
    };

    const handleAddRegNumber = () => {
        if(regNumbers.trim().length === 0){
            console.info('Empty');
            return;
            
        }
        
        const words = regNumbers.split(/[\s,;]+/);
        if(words.length === 0){
            return;
        }

        const regnumbers = [...notification.users];
        
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const idx = notification.users.findIndex((r) => r === word);
            if(idx !== -1){
                continue;
            }
            regnumbers.push(word);
        }

        setNotification({
            ...notification,
            users: regnumbers,
        });        
    };
        
    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async (user) => {
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }
            });
        }
        fetchUser();
        loadConnected();
        
    }, [app, loadConnected]);

    const columns = [
        {
          name: "UserName",
          label: "User name",
          options: {
            filter: true,
          }
        },
        {
            name: "FirstName",
            label: "First name",
            options: {
              filter: true,
            }
        },
        {
            name: "LastName",
            label: "Last name",
            options: {
              filter: true,
            }
        },
        {
            name: "Email",
            label: "Email",
            options: {
              filter: true,
            }
        },
        {
            name: "Phone",
            label: "Phone",
            options: {
              filter: true,
            }
        },
        {
            name: "Country",
            label: "Country",
            options: {
              filter: true,
            }
        },
        {
            name: "Connected",
            label: "Connected",
            options: {
              filter: true,
            }
        },
        {
            name: "UserName",
            label: " ",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Typography display='block' align='right'>          
                            <IconButton color="primary" onClick={()=> addNumber(data[dataIndex].UserName)}>
                                <span className="material-icons">add_alert</span>
                            </IconButton>
                        </Typography>
                    )
                }
            }
        },
    ]

    const options = {
        download: false,
        print: false,
        filter: true,
        page: page,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [50, 100, 200],
        serverSide: false,
        filterType: "textField",
        responsive: "simple",
        selectableRows: "none",
        searchText: search,
        count: data ? data.length: 0, 
        onTableChange: (action, tableState) => {
          switch (action) {
            case "changePage":          
              setPage(tableState.page);
              break;
            case "changeRowsPerPage":
              setPage(0);
              setPageSize(tableState.rowsPerPage);          
              break;
              case "search":
                {                                    
                    setPage(0);
                    setSearch(tableState.searchText ? tableState.searchText: "");                  
                  break;
                }
            default:
              break;
          }
        },                    
    };

  return (
      <AppConsumer>
          {() => (
              <div className={classes.root}>
                  <AppBar
                      ref={refRoot}
                      className={classes.appbar}
                      position="static"
                      color="inherit"
                      elevation={0}
                  >
                      <Toolbar className={classes.toolbar}>
                          <IconButton
                              className={clsx(
                                  classes.expand,
                                  {
                                      [classes.expandOpen]: expanded,
                                  },
                                  classes.mr
                              )}
                              aria-expanded={expanded}
                              aria-label="show more"
                              onClick={handleExpand}
                          >
                              <ExpandLessIcon />
                          </IconButton>

                          <IconButton
                              className={classes.mr
                              }
                              
                              aria-label="reload data"
                              onClick={loadConnected}
                          >
                              <SyncIcon />
                          </IconButton>

                          <Typography
                              variant="h6"
                              className={classes.title}
                          ></Typography>
                          <div>
                              <Typography
                                  display="block"
                                  variant="button"
                                  align="right"
                              >
                                  <b>Notification sender</b>
                              </Typography>
                              <Typography
                                  display="block"
                                  variant="caption"
                                  align="right"
                              >
                                  Send notification to online users
                              </Typography>
                          </div>
                      </Toolbar>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                          <Box p={2}>
                              <Grid
                                  container
                                  direction="row"
                                  spacing={2}
                                  style={{ marginBottom: 8 }}
                              >
                                  <Grid item xs={12} sm={10} md={11}>
                                      <TextField
                                          fullWidth
                                          name="regNumbers"
                                          label="Add reg numbers"
                                          multiline
                                          maxRows={4}
                                          minRows={3}
                                          value={regNumbers}
                                          onChange={(e) =>
                                              setRegNumbers(e.target.value)
                                          }
                                          variant="outlined"
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={2} md={1}>
                                      <Button
                                          size="large"
                                          style={{ marginBottom: 8 }}
                                          fullWidth
                                          variant="outlined"
                                          color="primary"
                                          startIcon={
                                              <PersonOutlineOutlinedIcon />
                                          }
                                          onClick={handleAddRegNumber}
                                      >
                                          Add
                                      </Button>
                                      <Button
                                          size="large"
                                          fullWidth
                                          variant="outlined"
                                          color="primary"
                                          startIcon={<ClearIcon />}
                                          onClick={handleClear}
                                      >
                                          Clear
                                      </Button>
                                      {/* <Button
                                            size='large'
                                            fullWidth                                            
                                            variant='outlined'
                                            color="primary" 
                                            startIcon={<PeopleAltOutlinedIcon />}
                                            onClick={handleSend}
                                        >
                                            Active
                                        </Button> */}
                                  </Grid>
                              </Grid>

                              <Box
                                  p={1}
                                  className={classes.users}
                                  mb={3}
                                  border={1}
                                  borderRadius={4}
                                  borderColor="grey.400"
                              >
                                  <Typography>Users: </Typography>
                                  {notification.users.map((user) => (
                                      <Chip
                                          key={user}
                                          icon={<FaceIcon />}
                                          label={user}
                                          color="primary"
                                          size="small"
                                          // onClick={handleClick}
                                          onDelete={() => handleDelete(user)}
                                          variant="outlined"
                                      />
                                  ))}
                              </Box>

                              <Grid container direction="row" spacing={2}>
                                  <Grid item xs={12} sm={10} md={10} lg={11}>
                                      <TextField
                                          fullWidth
                                          name="message"
                                          label="Message"
                                          multiline
                                          maxRows={12}
                                          minRows={9}
                                          value={notification.message}
                                          onChange={handleChange}
                                          variant="outlined"
                                      />
                                      <Box>
                                          <Typography variant="caption">{`Use macro: Hello consultant {regnumber}/{advisername}`}</Typography>
                                      </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={2} md={2} lg={1}>
                                      <Grid
                                          container
                                          direction="row"
                                          spacing={2}
                                      >
                                          <Grid item xs={12}>
                                              <Button
                                                  fullWidth
                                                  variant="outlined"
                                                  color="primary"
                                                  startIcon={<SendIcon />}
                                                  onClick={handleSend}
                                              >
                                                  Selected
                                              </Button>
                                          </Grid>
                                          <Grid item xs={12}>
                                              <Button
                                                  fullWidth
                                                  variant="outlined"
                                                  color="secondary"
                                                  startIcon={<SendIcon />}
                                                  onClick={handleSend}
                                              >
                                                  Active
                                              </Button>
                                          </Grid>
                                          <Grid item xs={12}>
                                              <FormControl
                                                  size="small"
                                                  variant="outlined"
                                                  className={
                                                      classes.formControl
                                                  }
                                                  fullWidth
                                              >
                                                  <InputLabel
                                                      className={
                                                          classes.inputLabel
                                                      }
                                                      id="severities-select-label"
                                                  >
                                                      Variants
                                                  </InputLabel>
                                                  <Select
                                                      labelId="severities-select-label"
                                                      name="severity"
                                                      value={
                                                          notification.severity
                                                      }
                                                      onChange={handleChange}
                                                  >
                                                      {severities.map((val) => (
                                                          <MenuItem
                                                              key={val}
                                                              value={val}
                                                          >
                                                              {val}
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </FormControl>
                                          </Grid>
                                          <Grid item xs={12}>
                                              <FormControl
                                                  size="small"
                                                  fullWidth
                                                  className={classes.margin}
                                                  variant="outlined"
                                              >
                                                  <InputLabel
                                                      className={
                                                          classes.inputLabel
                                                      }
                                                      htmlFor="duration"
                                                  >
                                                      Duration, sec
                                                  </InputLabel>
                                                  <OutlinedInput
                                                      type="number"
                                                      id="duration"
                                                      name="duration"
                                                      value={
                                                          notification.duration
                                                      }
                                                      inputProps={{ min: 1 }}
                                                      onChange={handleChange}
                                                      startAdornment={
                                                          <InputAdornment position="start">
                                                              <AccessTimeIcon />
                                                          </InputAdornment>
                                                      }
                                                  />
                                              </FormControl>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>

                              <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                <Grid item sx={11} sm={6} md={4}>
                                    <Typography display="block" align="center" gutterBottom>Preview</Typography>
                                    {notification.severity === 'default' ? 
                                        <SnackbarContent message={ notification.message === "" ? "This is an preview alert.": notification.message} /> :
                                        <Alert 
                                            variant="filled" 
                                            severity={notification.severity} 
                                        >
                                            { notification.message === "" ? "This is an preview alert.": notification.message}
                                        </Alert>
                                                                                
                                    }
                                    
                                    
                                    
                                </Grid>
                              </Grid>
                          </Box>
                      </Collapse>
                  </AppBar>

                  <Paper className={classes.paper} > 
                        <MUIDataTable 
                            responsive="standard"
                            title={`Users on the site now: ${data.length === 0 ? '': data.length}`}
                            data={data ?  data: []}
                            columns={columns} 
                            options={options} 
                            // components={components} 
                        />    
                    </Paper>
              </div>
          )}
      </AppConsumer>
  );
}

Notifications.propTypes = {}

export default Notifications;