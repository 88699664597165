import React, {
    useState,
    useContext,
    useCallback,
    useEffect,
    useRef,
} from "react";
import PropTypes, { bool } from "prop-types";
import { useParams, Link, useHistory } from "react-router-dom";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
    Box,
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    Backdrop,
    Dialog,
    FormGroup,
    Typography,
    Badge,
    FormControl,
    Chip,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DialogActions, DialogContent, DialogTitle } from "../../CustomDialog";
import { useWidth } from "../../../helpers/utils";
import moment from "moment";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../../store/countries";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import SaveIcon from "@material-ui/icons/Save";
import SyncIcon from "@material-ui/icons/Sync";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';


import AccessTimeIcon from '@material-ui/icons/AccessTime';

import ToolBox from "./ToolBox";
import PageAttribute from "./PageAttribute";

import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import HtmlBlock from "./HtmlBlock";
import TitleBlock from "./TitleBlock";
import LogoImage from "./LogoImage";
import BonuseNewcomer from "./Widgets/BonuseNewcomer";
import ContentFiles from "./Widgets/ContentFiles";
import PdfBlock from "./PdfBlock";
import VideoLinkBlock from "./VideoLinkBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import ColumnsBlock from "./ColumnsBlock";
import ContainerBlock from "./ContainerBlock";


import SliderEditor from "../../Slider/SliderEditor";
import HtmlEditor from "../HtmlEditor/HtmlEditor";
import TextEditor from "../HtmlEditor/TextEditor";
import { roles } from "../../../definitions/roles";
import {BlockType} from "../../../definitions/pageblocktype";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
// import { serialize, deserialize } from "react-serialize";

const host =
    process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // padding: theme.spacing(1/2, 1/2),
            // margin: theme.spacing(1/2),

            // height: 800,
        },
        appbar: {
            // marginTop: theme.spacing(1),
            // marginBottom: theme.spacing(1),
            // borderRadius: 12,
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        pagecontent: {
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
            alignItems: "stretch"
        },

        pagecontools: {
            // display: "flex",
            // height: 800,
            minHeight: theme.spacing(58)
        },
 
        pageview: {
            flex: 1,
            padding: theme.spacing(1),
            
        },

        toolbar: {
            paddingLeft: theme.spacing(1)
            // padding: theme.spacing(1, 2)
        },
        toolbarButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            //textAlign: 'right'
        },
        paper: {
            // marginTop: theme.spacing(1),
            // marginBottom: theme.spacing(1),
            borderRadius: 4,
            padding: theme.spacing(1),
            // boxShadow: theme.palette.ambilight.boxshadow
        },
        toolbox: {
            marginTop: theme.spacing(2),
            minHeight: theme.spacing(30),
        },
        toolsboxitem: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(1 / 2),
            marginBottom: theme.spacing(1),
        },
        secondaryAction: {            
            right: 0,
            // border: `1px solid ${theme.palette.divider}`,
        },
        secondaryActionIcon: {
            minWidth: theme.spacing(5)
        },
        button: {
            marginRight: theme.spacing(1),
        },
        listitem: {
        },
        listitemtext: {
            textTransform: 'capitalize'
        },
        block: {
            marginTop: theme.spacing(1),
            border: `1px dashed ${theme.palette.divider}`,
        },
        content: {
            paddingBottom: theme.spacing(2),
        },
        moveicon: {
            cursor: "move",
        },
        mr: {
            marginRight: theme.spacing(1),
        },
        dialogContent: {
            minHeight: theme.spacing(60),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        role: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        chipIcon: {
            fontSize: 18,
        },
        formControl: {
            padding: theme.spacing(0, 1),
            width: "100%",
            display: "block",
        },
        formControlLabel: {
            width: 202,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        lbInfo: {
            fontSize: ".9em",
            padding: theme.spacing(1 / 8, 1),
            marginRight: theme.spacing(1),
            backgroundColor: "#e8e8e863",
            borderRadius: "6px",
        },
    })
);


function CanEdit(blockType) {    
    return (
        blockType === BlockType.HTMLBLOCK ||
        blockType === BlockType.BIGSLIDER ||
        blockType === BlockType.MIDDLESLIDER ||
        blockType === BlockType.SMALLSLIDER ||
        blockType === BlockType.LOGOIMAGE ||
        blockType === BlockType.IMAGETYPE ||
        blockType === BlockType.PDFVIEWER ||
        blockType === BlockType.TITLE ||
        blockType === BlockType.IMAGE ||
        blockType === BlockType.BUTTON ||
        blockType === BlockType.VIDEO || 
        blockType === BlockType.VIDEOLINK
    );
}

const toolsBlocks = [
    
    {
        PageBlockType: BlockType.BIGSLIDER,
        Name: "Lg slider",
        Action: true,
        Icon: "view_carousel",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.MIDDLESLIDER,
        Name: "Md slider",
        Action: true,
        Icon: "burst_mode",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.SMALLSLIDER,
        Name: "Sm slider",
        Action: true,
        Icon: "grid_view",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.VIDEO,
        Name: "Video",
        Action: true,
        Icon: "videocam",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.VIDEOLINK,
        Name: "Youtube",
        Action: true,
        Icon: "smart_display",
        Disabled: false,
        Color: "red",
    },
    {
        PageBlockType: BlockType.IMAGE,
        Name: "Image",
        Action: true,
        Icon: "image",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.TITLE,
        Name: "Title",
        Action: true,
        Icon: "title",
        Disabled: false,
    },
    {
        PageBlockType: BlockType.HTMLBLOCK,
        Name: "Html block",
        Action: true,
        Icon: "notes",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.HTMLBLOCK,
        Name: "Link button",
        Action: true,
        Icon: "smart_button",
        Disabled: true,        
    },

    {
        PageBlockType: BlockType.WGBONUSENEWCOMER,
        Name: "Bonuse",
        Action: true,
        Icon: "widgets",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.PDFVIEWER,
        Name: "Pdf viewer",
        Action: true,
        Icon: "picture_as_pdf",
        Disabled: false,        
    },
    
    {
        PageBlockType: BlockType.COLUMNS,
        Name: "1:1 col",
        Action: true,
        Icon: "border_vertical",
        Disabled: true,        
    },    
    {
        PageBlockType: BlockType.TEXTTEXT,
        Name: "Txt:Txt",
        Action: true,
        Icon: "line_style",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.IMAGETEXT,
        Name: "Img:Txt",
        Action: true,
        Icon: "art_track",
        Disabled: false,        
    },
    {
        PageBlockType: BlockType.VIDEOTEXT,
        Name: "Video:Txt",
        Action: true,
        Icon: "art_track",
        Disabled: false,        
    },
    
];

const getIcon = (block) => {
    const typeBlock = toolsBlocks.find((b) => b.PageBlockType === block);
    if (typeBlock) {
        return typeBlock.Icon;
    } else {
        return "note";
    }
};

const PageEditor = (props) => {
    const app = useContext(AppContext);
    const history = useHistory();
    const width = useWidth();
    const classes = useStyles();
    const { t } = useTranslation();
    const { lang } = app;
    const { name } = useParams();
    const [token, setToken] = useState();
    const [langs, setLangs] = useState([]);
    const [countries, setCountries] = useState([]);

    const [content, setContent] = useState();
    const [expanded, setExpanded] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [editBlockIdx, setEditBlockIdx] = useState(-1);
    
    const [editBlock, setEditBlock] = useState(null);
    const [blockType, setBlockType] = useState(-1);

    const [showTools, setShowTools] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(true);
    const [showCommonAttib, setShowCommonAttib] = useState(false);
    const [showDelDlg, setShowDelDlg] = useState(false);
    const [showPageAttr, setShowPageAttr]  = useState(true);

    const refContentEditor = useRef();
    const refTextEditor = useRef();
    const refResourcesEditor = useRef();
    // moment.locale(lang);

    const refRoot = useRef(null);
    const refComponents = useRef(null);
    const scrollToElement = () => {
        refRoot.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            const value = parseInt(e.target.value);
            setContent({
                ...content,
                [e.target.name]: value,
            });
        } else if (e.target.type === "checkbox") {
            const value = e.target.checked;
            setContent({
                ...content,
                [e.target.name]: value,
            });
        } else {
            if(e.target.name === 'ContentType'){          
                const val = parseInt(e.target.value);

                setContent({
                    ...content,
                    [e.target.name]: val
                });
            }else{                
                setContent({
                    ...content,
                    [e.target.name]: e.target.value
                });
            }
        }
    };

    const checkUniqUrl = () => {
        if (!content) return;
        console.log(content.Key);
    };

    const handleShowToolsBox = () => {
        setShowTools(!showTools);
    };

    const handleCloseDlg = () => {
        setOpenEdit(false);
        setFullScreen(false);        
        setEditBlockIdx(-1);        
        setBlockType(-1)
        setEditBlock(null);
    };

    // const handleEditContent = (index) => {
    //     setEditBlockIdx(index);
    //     setEditBlock(content.Blocks[index].Content);
    //     setOpenEdit(true);
    // };

    const editBlockContent = (content, blockType) => {
        // console.log('Edit content', blockType, content);
        setBlockType(blockType);
        setEditBlock(content);
        setOpenEdit(true);
    } 

    const AddBlock = (blockType) => {
        fetch(`/api/v1.0/page/addblock/${blockType}`, {
            headers: { authorization: token },
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((block) => {
                        if (block) {
                            const _content = { ...content };
                            block.PageId = _content.Id;
                            block.Stack = _content.Blocks.length;
                            _content.Blocks.push(block);

                            if (process.env.NODE_ENV === "development")
                                console.log("Create new block", block);
                            //console.log(_content);
                            
                            setContent(_content);
                            handleExpandMore(_content.Blocks.length-1);
                            Save(_content);
                            refComponents.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'end'
                            });
                        } else {
                            alert("NULL Sorry! Not implemented yet");
                        }
                    });
                } else {
                    alert(
                        `${response.status} ${response.statusText}. Sorry! Not implemented yet.`
                    );
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    const Save = (c) => {
        if (!c) return;
        setShowBackdrop(true);
        const url =
            content.Id === 0
                ? "/api/v1.0/page/create/"
                : `/api/v1.0/page/update/${c.Id}`;
        const method = content.Id === 0 ? "POST" : "PUT";

        console.log("method", method, "; url", url, "save", c);

        fetch(url, {
            method: method,
            headers: {
                authorization: token,
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(c),
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        console.log("After save", data);
                        setContent(data);
                        window.history.pushState(
                            null,
                            "",
                            `/manage/pageeditor/${data.Key}`
                        );
                    });
                } else {
                    console.log(`${response.status} ${response.statusText}`);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            })
            .finally(() => {
                setShowBackdrop(false);
            });
    };

    const handleSave = () => {
        Save(content);
    };

    /* Save part of content */
    const handleSaveContent = () => {
        if (editBlock && blockType > -1) {
            refContentEditor.current.updateData();            
        }
    };

    const onSaveBlockContent = (data) => {
        console.log('Save content', data);
        loadContent();

        //loadContent();

        // if (editBlockIdx > -1 && data) {
        //     const _content = { ...content };
        //     _content.Blocks[editBlockIdx].Content = data;
        //     setContent(_content);
        // }
    };

    const handleCopyUrl = () => {
        if (!content) return;
        // const _url = `${window.location.protocol}//${window.location.host}/view/${content.Key}`;
        const _url = `/view/${content.Key}`;
        navigator.clipboard.writeText(_url);
    };

    const handleOpenUrl = () => {
        history.push(`/view/${content.Key}`);
    };

    const handleExpandMore = (index) => {
        const exp = expanded.includes(index);

        if (exp) {
            setExpanded((expanded) => {
                // return expanded.filter((_, i) => i !== index)
                return expanded.filter((i) => i !== index);
            });
        } else {
            setExpanded([...expanded, index]);
        }
    };

    const handlechangeShowPageAttr = () => {
        setShowPageAttr(!showPageAttr);
    }

    const deleteBlock = (index) => {
        setShowDelDlg(false);

        if (editBlockIdx && editBlockIdx === index) {
            setEditBlockIdx(-1);
            setEditBlock(null);
            setEditBlock(null);
        }

        setExpanded([]);
        setContent({
            ...content,
            Blocks: content.Blocks.filter((_, i) => i !== index),
        });
    };

    const handleDelete = (index) => {
        setEditBlockIdx(index);
        setEditBlock(content.Blocks[index].Content);
        setShowDelDlg(true);
    };

    const handleUnfoldMore = () => {
        setExpanded(Array.from(Array(content.Blocks.length).keys()));
    };

    const handleUnfoldLess = () => {
        setExpanded([]);
    };

    const handleSync = () => {
        loadContent();
    };

    const handleSerialize = () =>{
        // const typography = <Typography variant="h1" component='div' display='block' align='center' gutterBottom >Hello!</Typography>
        const grid = <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center" >
            <Grid item xs={12} sm={6}>Value 1</Grid>
            <Grid item xs={12} sm={6}>Value 2</Grid>
        </Grid>

        // console.log(grid.props);
        // const json = serialize(grid.props);
        console.log(json);

    };

    const loadContent = useCallback(() => {
        if (!token) return;

        fetch("/api/v1.0/utils/getcultures", {
            headers: { authorization: token },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setLangs(data);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });

        fetch("/api/v1.0/utils/countries", {
            headers: { authorization: token },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setCountries(data);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });

        const url = name
            ? `/api/v1.0/page/getbykey/${name}`
            : "/api/v1.0/page/newpage";

        fetch(url, {
            headers: {
                authorization: token,
                accept: "Accept: application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {                    
                    // console.log('Load content', data)
                    setContent(data);
                    setExpanded(Array.from(Array(data.Blocks.length).keys()));
                }
            })
            .catch((error) => {
                console.log("Error", error);
            })
            .finally(() => setShowBackdrop(false));
    }, [token, name]);

    const onDrop = ({ removedIndex, addedIndex }) => {
        const _content = { ...content };
        _content.Blocks = arrayMove(_content.Blocks, removedIndex, addedIndex);
        for (let i = 0; i < _content.Blocks.length; i++) {
            const block = _content.Blocks[i];
            block.Stack = i;
        }
        console.log("Drop", _content);
        setContent(_content);

        //setContent(content => content.Blocks = blocks));
    };

    const onClearAll = () => {
        setContent({
            ...content,
            Roles: [],
        });
    };

    const onDeleteRole = (role) => {
        const idx = content.Roles.findIndex((r) => r === role);
        if (idx > -1) {
            setContent({
                ...content,
                Roles: content.Roles.filter((r) => r !== role),
            });
        }
    };

    const onAddRole = (role) => {
        const idx = content.Roles.findIndex((r) => r === role);
        if (idx === -1) {
            const roles = [...content.Roles, role];
            setContent({
                ...content,
                Roles: roles,
            });
        }
    };

    const onAddUser = (code) => {
        const idx = content.RegistCodes.findIndex((r) => r === code);
        if (idx === -1) {
            const codes = [...content.RegistCodes, code];
            setContent({
                ...content,
                RegistCodes: codes,
            });
        }else{
            alert('User already exists')
        }
    };


    const onDeleteCode = (code) => {
        const idx = content.RegistCodes.findIndex((r) => r === code);
        if (idx > -1) {
            setContent({
                ...content,
                RegistCodes: content.RegistCodes.filter((r) => r !== code),
            });
        }
    };

    const onDeleteAllCode = () => {                
            setContent({
                ...content,
                RegistCodes: [],
            });
        
    };


    const checkedCountry = (country) => {
        if (!content.Countries) return false;

        return content.Countries.findIndex((c) => c === country) > -1;
    };

    const handleChangeCountry = (e) => {
        const _content = { ...content };
        const idx = _content.Countries.findIndex((c) => c === e.target.name);

        if (idx === -1) {
            _content.Countries.push(e.target.name);
        } else {
            _content.Countries.splice(idx, 1);
        }
        setContent(_content);
    };



    const handleClearCountries = () => {
        const _content = { ...content };
        _content.Countries = [];
        setContent(_content);
    };

    const handleAddAllCountries = () => {
        const _content = { ...content };
        _content.Countries = [];

        for (let idx = 0; idx < countries.length; idx++) {
            const country = countries[idx];
            _content.Countries.push(country.TwoLetterISORegionName);
        }
        setContent(_content);
    };

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async (user) => {
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }
            });
        }
        fetchUser();
        loadContent(name);
    }, [app, loadContent, name]);

    useEffect(() => {
        scrollToElement();
    }, []);

    return (
        <AppConsumer>
            {() => (
                <Box className={classes.root}>
                    <Backdrop className={classes.backdrop} open={showBackdrop}>
                        <div className="loader"></div>
                    </Backdrop>
                    
                    <AppBar
                        ref={refRoot}
                        className={classes.appbar}
                        position="static"
                        color="inherit"
                        elevation={0}
                    >
                        <Toolbar className={classes.toolbar}> 
                                {/* <Button size="small"
                                    variant='outlined' 
                                    color='secondary' 
                                    startIcon={<AccessTimeIcon />}
                                >
                                 wait! not ready
                                </Button>  */}

                                
                                <IconButton
                                    className={classes.mr}
                                    title="Go to list"
                                    component={Link}
                                    to="/manage/contents"
                                >
                                    <span className="material-icons">
                                        arrow_back
                                    </span>
                                </IconButton>

                                <IconButton
                                    className={classes.mr}
                                    title="Save"
                                    onClick={handleSave}
                                >
                                    <SaveIcon />
                                </IconButton>

                                <IconButton
                                    className={classes.mr}
                                    title="Reload"
                                    onClick={handleSync}
                                >
                                    <SyncIcon />
                                </IconButton>

                                <IconButton
                                    className={classes.mr}
                                    title={showPageAttr? "Hide page attributes": "Show page attributes"}
                                    onClick={handlechangeShowPageAttr}
                                >
                                    <span className="material-icons">menu_open</span>
                                </IconButton>                                

                                <IconButton
                                    className={classes.mr}
                                    title="Expand all"
                                    onClick={handleUnfoldMore}
                                >
                                    <span className="material-icons">
                                        unfold_more
                                    </span>
                                </IconButton>

                                <IconButton
                                    className={classes.mr}
                                    title="Collapse all"
                                    onClick={handleUnfoldLess}
                                >
                                    <span className="material-icons">
                                        unfold_less
                                    </span>
                                </IconButton>

                                {content && 
                                <>
                                    <IconButton
                                    className={classes.mr}
                                    title="Copy url"
                                    onClick={handleCopyUrl}
                                >
                                    <span className="material-icons">content_copy</span>
                                </IconButton>
                                <IconButton
                                    onClick={handleOpenUrl}
                                    title="Open page in the same window"
                                >
                                    <span className="material-icons">open_in_new</span>
                                </IconButton>
                                <IconButton
                                    component={Link} 
                                    to={`/view/${content.Key}`}
                                    target="_blank"
                                    title="Open page in new tab"
                                >
                                    <OpenInBrowserIcon />
                                    {/* <span className="material-icons">open_in_new</span> */}
                                </IconButton>
                                </>
                                }
                                {process.env.NODE_ENV === "development" && 
                                <IconButton
                                    onClick={handleSerialize}
                                    title="Serialize page"
                                >
                                    <span className="material-icons">data_object</span>
                                </IconButton>
                                }
                            <Typography variant="h6" className={classes.title}>                                
                            </Typography>
                            <div>
                                <Typography display="block" variant="button" align='right'><b>page builder</b></Typography>
                                {content && 
                                <Typography display="block" variant="caption" align='right'>name: {content.Name}, key: {content.Key}</Typography>
                                }
                            </div>
                            
                        </Toolbar>
                    </AppBar>
                    
                    <Box className={clsx(classes.pagecontent)}>
                        {/* Toolbox section */}
                        <Box className={classes.pagecontools}>
                            <ToolBox components={toolsBlocks} onAddComponent={AddBlock} />
                        </Box>
                        
                        {/* List of page componets section */}
                        <Box className={classes.pageview}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={showPageAttr ? 9 : 12}>
                                    <Paper
                                        ref={refComponents}
                                        className={classes.paper}
                                        elevation={0}
                                        style={{paddingTop: "0"}}
                                    >
                                        <List component="nav">
                                            {content &&  (
                                                <Container
                                                    dragHandleSelector=".drag-handle"
                                                    lockAxis="y"
                                                    onDrop={onDrop}
                                                >
                                                    {content.Blocks.map(
                                                        (block, index) => (
                                                            <Draggable 
                                                                key={block.Id}
                                                                className={classes.block}
                                                            >
                                                                <ListItem
                                                                    button
                                                                    onClick={() => handleExpandMore(index)}
                                                                    className={classes.listitem}
                                                                >
                                                                    <ListItemIcon className={classes.secondaryActionIcon}>
                                                                        {
                                                                            <span className="material-icons">
                                                                                {getIcon(
                                                                                    block.PageBlockType
                                                                                )}
                                                                            </span>
                                                                        }
                                                                    </ListItemIcon>

                                                                    <ListItemText className={classes.listitemtext} primary={ block.Name} />

                                                                    <ListItemSecondaryAction className={classes.secondaryAction}>                                                                        

                                                                        <ListItemIcon title="Delete block" className={classes.secondaryActionIcon}>
                                                                            <IconButton size='small'
                                                                                onClick={() => handleDelete(index)}
                                                                            >
                                                                                <DeleteForeverOutlinedIcon />
                                                                            </IconButton>
                                                                        </ListItemIcon>

                                                                        <ListItemIcon
                                                                            title="Edit block"
                                                                            className={classes.secondaryActionIcon}
                                                                            style={{
                                                                                marginRight: 16,
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                size='small'
                                                                                onClick={() =>
                                                                                    handleExpandMore(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                {expanded.includes(
                                                                                    index
                                                                                ) ? (
                                                                                    <ExpandLess />
                                                                                ) : (
                                                                                    <ExpandMore />
                                                                                )}
                                                                            </IconButton>
                                                                        </ListItemIcon>

                                                                        <ListItemIcon
                                                                            className={clsx(
                                                                                "drag-handle",
                                                                                classes.moveicon,
                                                                                classes.secondaryActionIcon
                                                                            )}
                                                                            title="Drag and drop me"
                                                                        >
                                                                            <DragHandleIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                                                                            
                                                                <Collapse className={ classes.content } in={expanded.includes(index)} timeout="auto" unmountOnExit >
                                                                    {block && (
                                                                        <Box p={1} > 
                                                                            { [
                                                                                BlockType.HTMLBLOCK,
                                                                                BlockType.BIGSLIDER,
                                                                                BlockType.MIDDLESLIDER,
                                                                                BlockType.SMALLSLIDER,
                                                                                BlockType.LOGOIMAGE,
                                                                                BlockType.IMAGE,
                                                                                BlockType.WGBONUSENEWCOMER,
                                                                                BlockType.PDFVIEWER,
                                                                                BlockType.VIDEO,
                                                                                BlockType.VIDEOLINK,
                                                                                BlockType.CONTAINER,
                                                                                BlockType.TITLE,
                                                                                BlockType.BUTTON,
                                                                            ].includes(block.PageBlockType) && 
                                                                                <ContainerBlock 
                                                                                    block={block} 
                                                                                    design={true} 
                                                                                    onEdit={editBlockContent} 
                                                                                />
                                                                            }
                                                                                    
                                                                            {[
                                                                                BlockType.COLUMNS,
                                                                                BlockType.TEXTTEXT,
                                                                                BlockType.IMAGETEXT,
                                                                                BlockType.VIDEOTEXT,
                                                                            ].includes(block.PageBlockType) && 
                                                                                <ColumnsBlock 
                                                                                    block={block} 
                                                                                    design={true} 
                                                                                    onEdit={editBlockContent} 
                                                                                /> 
                                                                            }
                                                                        </Box>
                                                                    )}
                                                                </Collapse>
                                                                
                                                            </Draggable>
                                                        )
                                                    )}
                                                </Container>
                                            )}
                                        </List>
                                    </Paper>
                                </Grid>

                                {showPageAttr && 
                                <Grid item xs={12} lg={3}>
                                    <Paper
                                        className={classes.paper}
                                        elevation={0}
                                    >
                                        <PageAttribute
                                            page={content}
                                            countries={countries}
                                            onChange={handleChange}
                                            onAddRole={onAddRole}
                                            onDeleteRole={onDeleteRole}
                                            onClearAll={onClearAll}
                                            onChangeCountry={handleChangeCountry}
                                            onAddAllCountries={handleAddAllCountries}
                                            onClearCountries={handleClearCountries}
                                            onAddUser={onAddUser}
                                            onDeleteUser={onDeleteCode}
                                            onDeleteAllCode={onDeleteAllCode}
                                        />
                                    </Paper>
                                </Grid>
                                }
                            </Grid>                            
                        </Box>
                    </Box>
                  
                    {/* Edit block */}
                    <Dialog
                        aria-labelledby="dialog-content"
                        open={openEdit}
                        fullWidth={true}
                        maxWidth="xl"
                        fullScreen={fullScreen}
                    >
                        <DialogTitle
                            id="dialog-content"
                            onClose={handleCloseDlg}
                        >
                            {editBlock && 
                                <>
                                    Block ›{" "}
                                    {editBlock.Name}
                                </>
                            }
                        </DialogTitle>
                        <DialogContent
                            className={classes.dialogContent}
                            dividers
                        >
                            {editBlock && blockType > -1 && (
                                <>
                                        {/* HTML block editor */}
                                        { BlockType.HTMLBLOCK === blockType && 
                                        (
                                            <HtmlEditor
                                                ref={refContentEditor}
                                                roles={roles}
                                                token={token}
                                                keyDisabled={true}
                                                article={editBlock}
                                                langs={langs}
                                                onSave={onSaveBlockContent}
                                            />
                                        )}

                                        {/* Text block editor */}
                                        { BlockType.TITLE === blockType && 
                                        (
                                            <TextEditor
                                                ref={refContentEditor}
                                                roles={roles}
                                                token={token}
                                                keyDisabled={true}
                                                article={editBlock}
                                                langs={langs}
                                                onSave={onSaveBlockContent}
                                            />
                                        )}
                                                                                                                    
                                        {/* Sliders editor */}
                                        {[ BlockType.BIGSLIDER, BlockType.MIDDLESLIDER, BlockType.SMALLSLIDER ].includes(blockType) && 
                                        (
                                            <SliderEditor
                                                ref={refContentEditor}
                                                slider={editBlock}
                                                roles={roles}
                                                langs={langs}
                                                countries={countries}
                                                onSave={onSaveBlockContent}
                                            />
                                        )}

                                        {/* Files pdf, image, video, links block editor */}
                                        {[BlockType.PDFVIEWER, BlockType.IMAGE, BlockType.VIDEO, BlockType.VIDEOLINK].includes(blockType) && 
                                        (
                                            <ContentFiles
                                                ref={refContentEditor}
                                                roles={roles}
                                                token={token}
                                                keyDisabled={true}
                                                content={editBlock}
                                                pageBlockType={blockType}
                                                langs={langs}
                                                onSave={onSaveBlockContent}
                                            />
                                        )}
                                    </>
                                )}
                        </DialogContent>
                        <DialogActions style={{ padding: 8 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item xs={4} sm={2} md={1}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        fullWidth
                                        autoFocus
                                        onClick={handleSaveContent}
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                    >
                                        {t("buttons.Save")}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        className={classes.button}
                                        variant="outlined"
                                        onClick={handleCloseDlg}
                                        color="default"
                                    >
                                        {t("buttons.Close")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    {/* Delete block */}
                    <Dialog
                        open={showDelDlg}
                        onClose={() => setShowDelDlg(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="xs"
                        fullWidth={true}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Delete block?"}
                        </DialogTitle>
                        <DialogContent>
                            {editBlockIdx > -1 &&
                                content.Blocks[editBlockIdx] && (
                                    <Typography>
                                        Url key:{" "}
                                        {content.Blocks[editBlockIdx].Key};
                                        Block name:{" "}
                                        {content.Blocks[editBlockIdx].Name}
                                    </Typography>
                                )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowDelDlg(false)}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                autoFocus
                                onClick={() => deleteBlock(editBlockIdx)}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </AppConsumer>
    );
};

PageEditor.propTypes = {};

export default PageEditor;