import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
// import { AppContext } from "../../providers/appProvider";
import PdfViewer from '../Viewer/PdfViewer';

const useStyles = makeStyles((theme) =>
    createStyles({        
        root: {
            flexGrow: 1            
        },        
    })
);


const Viewer = () => {
    const classes = useStyles();
    const query = new URLSearchParams(window.location.search);    
    const url = query.get('url');
    const p = query.get('p');
    const page = p ? parseInt(p) : 1;
    // const app = useContext(AppContext);
    // const { lang, country } = app;  
  return (
    <div className={classes.root}>        
        <PdfViewer url={url} page={page} />
    </div>
  )
}

export default Viewer;