import React from "react";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import {MobileStepper, Button, IconButton} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ReactImageMagnify from "react-image-magnify";

const styles = theme => (
{ 
    root: {
        maxWidth: "100%",
        flexGrow: 1,
                
      },
      header: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(2),

      },      
      mobileStepper:{
        backgroundColor: 'transparent'
          // backgroundColor: theme.palette.background.paper,
      },
      img: {        
        display: "block",        
        overflow: "hidden",
        width: "100%",
      },
      imgCont: {
        marginTop: -8,        
        // border: "solid 1px #36c2f652 !important",
        border: 'none !important',
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.palette.ambilight.boxshadow,
        borderRadius: "12px",
        zIndex: 10000,
        opacity: 1
      },
      
      imageLens: {                
        maxWidth: "100%",        
      },

      grayscale: {        
        // webkitFilter: "opacity(30%) grayscale(100%)",
        // filter: "opacity(30%) grayscale(100%)",
        // "&:hover": {
        //     webkitFilter: "none",
        //     filter: "none",
        //   },
    },

});

class ProductSlider extends React.Component {    
    constructor(props) {
        super(props);

        this.state = {
            // items: (props.items &&  props.items.length > 0 ? [props.items[0]] : []),
            slider: {},
            activeStep: 0,
            maxSteps: props.items.length,
            interval: 20000,
            // eslint-disable-next-line no-undef
            cdn: `${process.env.REACT_APP_CDN}Pictures`,
            imgLgSize: "392x392",
            imgMdSize: "183x183",
        };        
    }

    setActiveStep = (step) =>{
        this.setState({ activeStep: step });            
    }

    handleNext = () => {        
        console.log("Next");
        if(this.state.maxSteps > this.state.activeStep + 1){
            this.setActiveStep(this.state.activeStep + 1);
        }
      }
    
    handleBack = () => {   
        console.log("Back");     
        if(0 <= this.state.activeStep - 1){
            this.setActiveStep(this.state.activeStep - 1);
        }        
      }
    
    handleStepChange = (step) => {        
        this.setActiveStep(step);
      }
    
    render() {
        const { classes, items, available } = this.props;         
        //const { classes, rsProps, items } = this.props;         
        // const {items} = this.state;
        const { activeStep, cdn } = this.state; 
        return (
            <div className={classes.root}>
                
                { items && items.length > 0 && 
                    <React.Fragment>                        
                        <ReactImageMagnify
                        {...{
                            rimProps: {
                                isHintEnabled: true,
                                shouldHideHintAfterFirstActivation: false,
                                enlargedImagePosition: "over"
                            },
                            smallImage: {
                                alt: items[activeStep].Name,
                                isFluidWidth: true,
                                src: `${cdn}/${this.state.imgLgSize}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                srcSet:  `${cdn}/${this.state.imgLgSize}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                // srcSet: src.srcSet,
                                // sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
                            },
                            largeImage: {
                                src: `${cdn}/${items[activeStep].FileName}${items[activeStep].Extention}`,
                                isFluidWidth: true,
                                width: 1788,
                                height: 1788
                            },
                            enlargedImageContainerDimensions: {
                                width: "200%",
                                height: "109%"
                            },
                            // lensStyle: { width: "90%" },
                            shouldUsePositiveSpaceLens: true, 
                            imageClassName: clsx(classes.imageLens, (available ? "": classes.grayscale)),
                            enlargedImageContainerClassName: clsx(classes.imgCont)
                        }}                                    
                        />                                    

                         <MobileStepper
                            steps={this.state.maxSteps}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            className={classes.mobileStepper}
                            nextButton={
                            <IconButton size="small" onClick={this.handleNext} disabled={activeStep === this.state.maxSteps - 1}>                                
                                <KeyboardArrowRight />
                            </IconButton>
                            }
                            backButton={
                            <IconButton size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                               <KeyboardArrowLeft />
                            </IconButton>
                            }
                        />                                                
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default  withWidth()(withStyles(styles)(ProductSlider));