
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';



// eslint-disable-next-line no-unused-vars
import {Box, TextField, Checkbox, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    message: {
      fontSize: '1em',
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      fontWeight: 400,    
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      width: "100%",
      padding: 8,
      resize: "none",
      borderRadius: 4,
      border: "1px solid #cccccc",
      backgroundColor: "rgba(238, 238, 238, 0.35)"
    },
    button: {
      marginRight: theme.spacing(1)
    },
    formControl: {      
      width: "100%",
    },
}));

const ButtonVariants = [ 'contained', 'outlined', 'text'];
const ButtonSizes = [ 'large', 'medium', 'small'];
const ButtonColors = [ 'default', 'inherit', 'primary', 'secondary'];


const ButtonEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
      
  const handleChangeChildrenText = (e) => {
    if(typeof component.children === "string"){
      component.children = e.target.value;          
      setComponent({...component});
    }
  };

  const handleChangeHref = (e) => {
    component.props.href = e.target.value;
    setComponent({...component});
  };

  const handleChangeButtonVariant = (e) => {
    component.props.variant = e.target.value;
    setComponent({...component});
  };

  const handleChangeButtonSize = (e) => {
    component.props.size = e.target.value;
    setComponent({...component});
  };

  const handleChangeButtonColor = (e) => {
    component.props.color = e.target.value;
    setComponent({...component});
  }

  const handleChangeFullWidthBotton = (e) => {
    component.props.fullWidth = e.target.checked;
    setComponent({...component});
  };

  const handleUpdate = () => {
    if(onUpdate)
      onUpdate(component);
  };

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={classes.root}>
        {component && 
        <Box pl={1} pr={1} pt={2}>
              
            <table style={{width: "100%"}}>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    {typeof component.children === "string" &&               
                      <TextField 
                        label="Text"
                        margin="dense"
                        variant="outlined"
                        fullWidth                        
                        size="small"
                        aria-label="text-button"                      
                        placeholder="Text"
                        value={component.children} 
                        onChange={handleChangeChildrenText} 
                      />
                    }
                  </td>
                </tr>
                <tr>                    
                    <td colSpan={2}>
                      <TextField
                        label="HREF"                           
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                        aria-label="href-botton"                      
                        placeholder="HREF"
                        value={component.props.href ? component.props.href: ''} 
                        onChange={handleChangeHref} 
                      />
                    </td>
                  </tr> 

                <tr>
                  <td>Variant</td>
                  <td>                  
                    <Select
                      fullWidth
                      size="small"
                      labelId="buttonVariant"
                      id="buttonVariant"
                      value={component.props.variant ? component.props.variant: ButtonVariants[2] }
                      onChange={handleChangeButtonVariant}
                    >
                      { ButtonVariants.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}
                    </Select>                
                  </td>
                </tr>
                <tr>
                  <td>Size</td>
                  <td>                     
                      <Select
                        size="small"
                        fullWidth
                        labelId="buttonSize"
                        id="buttonSize"
                        value={component.props.size ? component.props.size: ButtonSizes[2] }
                        onChange={handleChangeButtonSize}
                        >
                        { ButtonSizes.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                      </Select>
                  </td>                  
                </tr>
                <tr>
                  <td>Color</td>
                  <td>                     
                      <Select
                        size="small"
                        fullWidth
                        labelId="buttonColor"
                        id="buttonColor"
                        value={component.props.color ? component.props.color: ButtonColors[0] }
                        onChange={handleChangeButtonColor}
                        >
                        { ButtonColors.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>  )}                    
                      </Select>
                  </td>                  
                </tr>
                <tr>
                    <td>fullWidth</td>
                    <td>                                        
                      <Checkbox
                        size="small"
                        checked={component.props.fullWidth ? component.props.fullWidth: false}
                        onChange={handleChangeFullWidthBotton}
                        color='default'
                        name="buttonFullWidth"            
                    />                                    
                    </td>
                  </tr> 
                

              </tbody>
            </table>
            
            <Box p={2}>
              <Button 
                    fullWidth 
                    size="small" 
                    variant="outlined" 
                    color='primary' 
                    onClick={handleUpdate} startIcon={<CheckIcon />}
                >
                  Applay
              </Button> 
            </Box>                          
        </Box>
        }
    </div>
  )
}

ButtonEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default ButtonEditor