import React, { useState, useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useCookies, setCookie } from 'react-cookie';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory, useLocation } from 'react-router-dom';
import { actionOrderCreators } from "../../store/Order";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";


import {Badge, IconButton, Avatar} from "@material-ui/core";
import {lightBlue} from "@material-ui/core/colors";
// import { useWidth} from '../../helpers/utils';

import ShoppingCard from "@material-ui/icons/ShoppingCartOutlined";

const useStyles = makeStyles((theme) =>
    createStyles({
        btn: {
            marginLeft: 2,
            marginRight: 2, 
            padding: 2, 
            border: "2px solid", 
            borderColor: lightBlue[700]
        },
        avatar: {        
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        qty: {
            backgroundColor: lightBlue[700],
        }
    
    })
);

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }))(Badge);


export const Basket = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();    
    const history = useHistory();
    const location = useLocation();

    const app = useContext(AppContext);
    const { lang } = app;
    const [cookies, setCookie] = useCookies();

    // const [ user, setUser ] = useState(app.user);
    
    const dispatch = useDispatch();
    const { qtyrows, Order } = useSelector((state) =>  state.orderRequest );

    // const [ orderId, setOrderId ] = useState();
    
    const handleOrder = () => {        
        if(Order && Order.Id > 0 )
            history.push(`/order/${Order.Id}`);
        else
            history.push('/order');    
    };

    useEffect(()=> {
        const _orderId = cookies.orderId;        
        
        if( !_orderId){            
            return;
        }
        if(location.pathname.toLowerCase().startsWith("/order")){
            return;
        }
               
        dispatch(actionOrderCreators.requestOrder(_orderId, lang));
    }, [cookies, dispatch, lang, location]);
    
  return (
    <AppConsumer>
        {() => (
            <IconButton className={classes.btn} aria-label="cart" onClick={handleOrder} title={Order.Id === 0 ? t("New order"): `№ ${Order.DocNumber}`}> 
                <StyledBadge badgeContent={qtyrows} color="secondary" overlap="circular" >
                    <Avatar className={ clsx(classes.avatar, qtyrows > 0 ? classes.qty: "")}>
                        <ShoppingCard />
                    </Avatar>
                </StyledBadge>
            </IconButton>
        )}
    </AppConsumer>    
  )
}

export default connect(
    state => state.orderRequest,
    dispatch => bindActionCreators(actionOrderCreators, dispatch)
)(Basket)

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Basket)