import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
// import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: 1,            
            // padding: theme.spacing(1),
            flexGrow: 1,
            width: "100%",
        },        
    })
);

const HtmlBlock = props => {
    const classes = useStyles();
    const { localValue, blockKey } = props;
    return (
        <div className={classes.root}>
            <Typography component='div' className={classes[blockKey]} dangerouslySetInnerHTML={{ __html: localValue.Value }}></Typography>    
        </div>
    )
}

HtmlBlock.propTypes = {
    localValue: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Annonce: PropTypes.string.isRequired,
        ContentId: PropTypes.number.isRequired,
        CreatedOn: PropTypes.string.isRequired,
        Descr: PropTypes.string.isRequired,
        Keywords: PropTypes.string.isRequired,
        Title: PropTypes.string.isRequired,
        TwoLetterISOLanguageName: PropTypes.string.isRequired,
        UpdatedOn: PropTypes.string.isRequired,
        Value: PropTypes.string.isRequired        
    }).isRequired,
    blockKey: PropTypes.string.isRequired,
};

export default HtmlBlock;