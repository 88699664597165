import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";

const styles = theme => ({    
    snackbar: {
        maxWidth: "80%",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "100%"
        },        
    },
    alert: {
        backgroundColor: theme.palette.background.paper
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },     
});

  
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Notification extends React.Component {  
    constructor(props) {        
        super(props);
        this.state = {};               
    }

    render(){
        const {classes, snackbarTxt, snackbarShow, severity, variant, duration } = this.props;        
        return(            
            <>
            <Snackbar 
                className={classes.snackbar}
                open={snackbarShow} 
                autoHideDuration={ duration} 
                onClose={this.props.hideSnackBar} 
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert 
                    className={clsx(variant === 'outlined' ? classes.alert : '')} 
                    onClose={this.props.hideSnackBar} 
                    severity={severity} 
                    variant={variant} 
                >
                    <div style={{whiteSpace: "pre-line"}}>
                        {snackbarTxt}
                    </div>              
                </Alert>
          </Snackbar>            
          </>
        );
    }
}

export default withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Notification)
);