import React, { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import CatalogItemXs from "./CatalogItemXs";
// import CardItem from './CardItem';
import Pagination from '@material-ui/lab/Pagination';
import {Grid, Paper} from "@material-ui/core";
// import { useWidth} from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 12,
    boxShadow: theme.palette.ambilight.boxshadow
  },
}));

const styles = {
  root: {    
    padding: '0 8px',
  },
  slideContainer: {
    padding: '0 8px',
  },
  slide: {
    padding: 16,        
  },
  
};

// const cdn = `${process.env.REACT_APP_CDN}Pictures`;
const cardToPage = (width) => {
  switch (width) {
    case "xs":
      return 2;
    case "sm":
        return 3;
    case "md":
      return 4;
    case "lg":      
    case "xl":
        return 6;
    default:
    return 4;
  }
}

const BuildPages = (items, width) => {
  
  const slides = [];
  if(!items || items.lenght === 0){    
    return slides;
  }

  const cardsToSlide = cardToPage(width);
  const ln = items.length;
  const pages = Math.floor(ln / cardsToSlide) + (ln % cardsToSlide === 0 ? 0 :1);
  
  for (let p = 0; p < pages; p++) {
    const cards = [];
    for (let i = 0; i < cardsToSlide; i++) {      
      const pos = p * cardsToSlide + i;
        if(pos >= ln)
          break;

      cards.push(items[pos])
      // console.log('pos', pos);
    }
    slides.push({cards: cards});
  }
  return slides;
}

const ItemsStepper = (props) => {  
  const classes = useStyles();  
  
  const {items, width, IsAuthenticated, userName} = props;
  const [slides, setSlides] = useState([]);
  const [page, setPage] = useState(0);

  if(items && items.lenght > 0)
    setSlides(BuildPages(items, width));

  const handleChangePage = (event, value) => {
      setPage(value-1);
  };
  
  // const handleChangeIndex = index => {
  //   setPage(index);
  // };

  useEffect(() => {
      const slds = BuildPages(items, width);
      setSlides(slds);
      // console.log('SLIDES' ,slds);
  }, [items, width]);


  return (
    <>
    {slides.length > 0 &&
      <>            
        <Grid container direction="row" spacing={width == 'xs' ? 1: 2} justifyContent="center" alignItems="center">
        {
          slides[page].cards.map((card) => (
            <Grid key={card.Code} item xs={6} sm={4} md={4} lg={2} style={{paddingBottom: 16}}>
                <CatalogItemXs 
                  item={card} 
                  IsAuthenticated={IsAuthenticated} 
                  userName={userName}
                  />
            </Grid>
          ))
        }
        </Grid>
        <Paper className={classes.paper} >
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} >
                <Grid item>
                    <Pagination 
                        count={slides.length} 
                        page={page+1} 
                        variant="outlined"
                        color='primary'
                        onChange={handleChangePage}
                        siblingCount={0}                    
                        // siblingCount={0} boundaryCount={0}
                    />
                </Grid>
            </Grid> 
      </Paper>
      </>
    }
    </>
  )
}

ItemsStepper.propTypes = {
  items: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  IsAuthenticated: PropTypes.bool

  // onClose: PropTypes.func.isRequired,  
};

export default ItemsStepper;