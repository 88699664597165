import React, {useState, useContext, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Toolbar, Paper, TextField, IconButton, Typography, Box,
         Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
         Button, Dialog } from "@material-ui/core";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { DialogActions, DialogContent, DialogTitle } from "./../CustomDialog";
import { useTranslation } from "react-i18next";
import {countryToFlag} from '../../store/countries';

import SaveIcon from "@material-ui/icons/Save";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ClearIcon from '@material-ui/icons/Clear';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import PublishIcon from '@material-ui/icons/Publish';

import LinkIcon from '@material-ui/icons/Link';
import grey from "@material-ui/core/colors/grey";
import { stableSort, getSorting } from '../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(0, 1/4)
        },        
        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            padding: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow
        },    
        appbar: {            
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: 12,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        title: {
            flexGrow: 1,
        },
        button: {
            marginRight: theme.spacing(1)
        },
        content: {
            padding: theme.spacing(2,2),
            position: 'relative',
            overflow: 'auto',
            minHeight: 400,
            maxHeight: 400,
        },
        footer: {
            padding: theme.spacing(1,2)
        },
        table: {
            width: '100%',
            minWidth: 650,
        },
        col_1: {
            
            width: theme.spacing(3)
        },
        col_2: {
            paddingTop: theme.spacing(2),
            width: '100%'
        },
        col_3: {
            paddingTop: theme.spacing(2),
            verticalAlign: 'top',
            width: theme.spacing(5)
        },
        fullpath: {
            fontSize: '.8rem',
            color: grey[500]
        },
        upload: {
            display: 'none',
        },
    })
);

const relativePath = 'assets/files/catalogs';

const CatalogFiles = props => {
    const {isOpen, catalog, countries, onClose, onSave} = props;
    const classes = useStyles();    
    const app = useContext(AppContext);    
    const { t } = useTranslation();
    const [token, setToken] = useState();
    const [files, setFiles] = useState(catalog.Files ? catalog.Files: []);
    const [catalogSetting, setCatalogSettings] = useState();

    function handleChange(e, idx) {
        const file = e.target.files[0];
        if(file){
            files[idx].File =file;
            files[idx].Name = file.name;
            setFiles([...files]);
        }        
    }

    const handleAddCatalogFile = () => {
        files.push({
            Id: 0,
            Path: '',
            Name: '',
            TwoLetterISORegionName: '',
            Appointment: 0,
            CatalogId: catalog.Id
        });
        setFiles([...files]);
    };
    
    const handleAddCatalogFiles = () => {
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i].TwoLetterISORegionName;
            const exist = files.find( f => f.TwoLetterISORegionName === country && f.Appointment === 0);
            
            if(exist)
                continue;

            files.push({
                Id: 0,
                Path: '',
                Name: '',
                TwoLetterISORegionName: country,
                Appointment: 0,
                CatalogId: catalog.Id
            });            
        }

        const sorted = stableSort(files, getSorting('desc', 'TwoLetterISORegionName'));

        setFiles(sorted);
    };
    
    const handleDelFile = (idx) => {
        files.splice(idx,1);
        setFiles([...files]);
    };

    const handleCopyPath = (idx) => {
        const file = files[idx];
        const fullName = `/${relativePath}/${catalog.Year}/${catalog.Period}/${file.TwoLetterISORegionName}/${file.Name !==""? file.Name: "❓"}`;
        navigator.clipboard.writeText(fullName);
    }

    const handleClearFile = (idx) => {
        const file = files[idx];
        const el = document.getElementById(`file_${file.TwoLetterISORegionName}`);
        if(el){            
            el.value = null;
            file.Name = '';
            file.File = null;
        }        
        setFiles([...files]);
    }

    const handleDelAllFiles = () => {
        setFiles([]);
    };

    const handleSaveFiles = () => {
        const data = new FormData();        
        const catalogFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            catalogFiles.push({
                "Id": file.Id,                 
                "Name": file.Name,
                "Path":  file.Name.length > 0 ? `${catalog.Year}/${catalog.Period}/${file.TwoLetterISORegionName.toLowerCase()}`: "", 
                "TwoLetterISORegionName": file.TwoLetterISORegionName,
                "Appointment": file.Appointment
             });

            if(file.File){
                data.append(`${catalog.Year}/${catalog.Period}/${file.TwoLetterISORegionName.toLowerCase()}`, file.File, file.File.name); 
            }            
        }             
        
        data.append('catalogId', catalog.Id);
        data.append('catalogFiles', JSON.stringify(catalogFiles));

        const url =  '/api/v1/catalog/upload';                
        
        fetch(url,  {
            method: 'POST',
            headers: {                
                "Authorization": token
            },
            body: data,
          })
        .then((res) => res.text())
        .then((data) => console.log(data))
        .catch((err) => console.error(err));
        

    }

    // Get token
    useEffect(() => {
        if (!app) return;
        
        function fetchUser() {
            app.getUser().then(async user => {
                let token = null;
                if (user != null) {
                    token = `Bearer ${user.access_token}`;
                }
                
                const url = `/api/v1.0/catalog/catalogsettings/${catalog.Id}`;
                fetch(url,
                    {
                        method: "GET",                
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': token
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if(data){                    
                            console.log('Catalog settings', data);
                            // const sorted = stableSort(data.CatalogSetting.Files, getSorting('desc', 'TwoLetterISORegionName'));
                            setFiles(data.CatalogSetting.Files);    
                            setCatalogSettings(data);
                        }
                    })
                    .catch((error) => {
                        console.log('Error', error);
                    })
                    .finally(() => {
                        setToken(token);
                    });
                                                
                        // getCatalogSettings(catalog.Id);
                    });
        }

        fetchUser();
        
            // console.log('Catalog', catalog);

    }, [app, catalog]);

 // const ff = fileList ? [...fileList] : [];
  return (
    <div className={classes.root}>
        <Dialog 
            onClose={() => onClose(false)} 
            aria-labelledby="catalog-files-dialog-title" 
            open={isOpen}
            fullWidth
            maxWidth="lg"                 
        >
            <DialogTitle onClose={() => onClose(false)}>
                <small style={{textTransform: 'uppercase'}}>Catalog files</small>
            </DialogTitle>
            <DialogContent >            
                <AppBar className={classes.appbar} position="static" color='inherit' elevation={0} >
                    <Toolbar>
                        {/* <Button
                            className={classes.button} 
                            color="inherit"
                            startIcon={<DescriptionIcon />}
                            onClick={handleAddCatalogFile}
                        >
                            Add file
                        </Button> */}
                        <Button 
                            color="inherit"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={handleAddCatalogFiles}
                        >
                            Add pdf files
                        </Button>

                        <Button 
                            color="inherit"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelAllFiles}
                        >
                            Delete all files
                        </Button>
                        <Typography className={classes.title}></Typography>
                        <Typography component='div' align='right'>
                            <div><b>Catalog</b></div>
                            <div>
                                <small>{catalog.Name}</small>
                            </div>
                            
                        </Typography>
                        
                    </Toolbar>
                </AppBar>

                {/* <Paper className={classes.paper}>
                    <input type="file" onChange={handleFileChange} multiple />
                    <button onClick={handleUploadClick}>Upload</button>
                    <div>
                    <ul>
                        {ff.map((file, i) => (
                        <li key={i}>
                            {file.name} - {file.type}
                        </li>
                        ))}
                    </ul>
                    </div>
                </Paper> */}

                <Paper className={clsx(classes.content, classes.paper) }>
                    {files.length > 0 && 
                    <Table className={classes.table} size="small" aria-label="files table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.col_1}>&nbsp;</TableCell>
                                <TableCell className={classes.col_2}>File name</TableCell>
                                <TableCell className={classes.col_3} align="right">&nbsp;</TableCell>                            
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {files.map((file, idx)=> (
                            <TableRow key={idx}>
                                <TableCell className={classes.col_1} component="th" scope="row">
                                    {file.TwoLetterISORegionName !== '' ? 
                                    <>
                                    {file.TwoLetterISORegionName}&nbsp;<sup>{countryToFlag(file.TwoLetterISORegionName)}</sup>
                                    </>:
                                    <>
                                    All&nbsp;<sup>🌎</sup>
                                    </>
                                    }
                                </TableCell>
                                <TableCell className={classes.col_2}>
                                    <TextField 
                                        fullWidth 
                                        size="small" 
                                        variant='outlined'
                                        label={`File name by ${file.TwoLetterISORegionName}`}
                                        value={file.Name} 
                                        InputProps={{
                                            endAdornment: (
                                            <>
                                                
                                                {/* <IconButton  size='small'>
                                                    <PublishIcon />
                                                </IconButton> */}

                                                <input 
                                                    accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                                                    className={classes.upload} 
                                                    id={`file_${file.TwoLetterISORegionName}`} 
                                                    type="file"                                                    
                                                    onChange={(e) => handleChange(e, idx)}
                                                />
                                                <label htmlFor={`file_${file.TwoLetterISORegionName}`}>
                                                    <IconButton 
                                                        className={classes.button} 
                                                        aria-label="upload file" 
                                                        component="span"
                                                        size='small'
                                                    >
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </label>
                                                <IconButton 
                                                    className={classes.button} 
                                                    size='small'                                                    
                                                    onClick={() => handleClearFile(idx)}
                                                >
                                                    <ClearIcon />
                                                </IconButton> 
                                            </>
                                            )
                                        }}
                                    />
                                    <Typography className={classes.fullpath} display='block'>
                                        {`/${relativePath}/${catalog.Year}/${catalog.Period}/${file.TwoLetterISORegionName.toLowerCase()}/${file.Name !=="" ? file.Name: "❓"}`}
                                        {file.Name !=="" &&
                                        <IconButton
                                            onClick={()=> handleCopyPath(idx)}
                                            size='small'
                                            style={{padding: 4, marginLeft: 8}} 
                                        >
                                            <FileCopyOutlinedIcon 
                                                style={{fontSize: 14}} 
                                            />
                                        </IconButton>
                                        }

                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.col_3} align="right">
                                    <IconButton 
                                        edge="end" 
                                        aria-label="delete" 
                                        onClick={() => handleDelFile(idx)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell> 
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    }

                    {
                        files.length === 0 && 
                        <Box mt={13}>
                            <Typography variant='h2' display='block' align='center'>
                                Empty files list
                            </Typography>
                        </Box>
                        
                    }                                     
                </Paper>
            </DialogContent>
            <DialogActions className={classes.footer}>
                <Button 
                    className={classes.button}
                    color="primary" 
                    startIcon={<PublishIcon />}
                    onClick={handleSaveFiles}
                  >{t("buttons.Upload")}
                </Button>

                <Button                    
                    autoFocus 
                    onClick={() => onClose(false)}                    
                >
                    {t("buttons.Close")}
                </Button>

            </DialogActions>
        </Dialog>
    </div>
  )
}

CatalogFiles.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired, 
    onSave: PropTypes.func.isRequired, 
    catalog: PropTypes.shape({
        Id: PropTypes.string.isRequired,                                
        Name: PropTypes.string.isRequired,
        Number: PropTypes.string.isRequired,                
        Period: PropTypes.number.isRequired,
        Year: PropTypes.number.isRequired,
        Files: PropTypes.arrayOf(
            PropTypes.shape({
              Id: PropTypes.number.isRequired,
              Path: PropTypes.string.isRequired,
              Name: PropTypes.string.isRequired,
              TwoLetterISORegionName: PropTypes.string.isRequired,
              Appointment: PropTypes.number.isRequired,
              CatalogId: PropTypes.string.isRequired,
              File: PropTypes.any
            })
          ),
    }).isRequired,
    countries: PropTypes.arrayOf(
        PropTypes.shape({})
    ).isRequired,

}

export default CatalogFiles; 