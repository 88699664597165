import axios from "axios";
import { OidcUserManager } from "../helpers/OidcSettings";
const loadingType = "LOADING";
const loadingHideType = "LOADING_HIDE";
const requestOrdersType = "REQUEST_ORDERS";
const requestDetailsType = "REQUEST_DETAILS";
const requestEditOrderType = "REQUEST_EDIT_ORDER";
const requestCopyOrderType = "REQUEST_COPY_ORDER";
const requestCancelOrderType = "REQUEST_CANCEL_ORDER";
const errorOrderMessageType = "ERROR_ORDER_MESSAGE";
const errorOrderCloseMessageType = "ERROR_ORDER_CLOSEMESSAGE";

const reciveOrdersType = "RECIVE_ORDERS";
const reciveClientsOrdersType = "RECIVE_CLIENTSORDERS";
const reciveDraftsType = "RECIVE_DRAFTS";

const reciveDraftType = "RECIVE_DRAFT";
const reciveOrderType = "RECIVE_ORDER";
const deleteDraftType = "DELETE_DRAFT";

const payOrderFromBalanceError = "PAY_ORDER_FROM_BALANCE_ERROR";
const payOrderFromBalanceSuccess = "PAY_ORDER_FROM_BALANCE_SUCCESS";
const closeMessage = "CLOSE_MESSAGE";

const initialState = { 
  locale: "en",
  isLoading: false,
  rowsPerPageOptions: [10, 25, 50, 100],
  
  orderId: null,
  Order: null,
  Orders: [],
  o_pi: -1,    
  o_ps: 10,
  o_count: 0,

  draftId: null,
  Draft: null,
  Drafts: [],
  d_pi: -1,    
  d_ps: 10,
  d_count: 0,

  OrdersClients: [],
  c_pi: 0,
  c_ps: 10,
  c_count: 0,
  
  orderPayed: false,
  orderPayedResult: null,
  
  orderError: false,
  orderErrorMessage: null,

};

export const actionOrdersCreators = {

  deleteDraft: (id) => async (dispatch, getState) => {
    const req = getState().ordersRequest;        
    if (id === req.draftId) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
    }

    dispatch({ type: deleteDraftType});
    const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {      
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        }

        const ps = req.d_ps;        
        axios.delete(`api/v1/order/deletedraft?id=${id}`, { headers: { Authorization: token } })   
        .then(function(response){
          console.log("Delete draft" ,response);
          if(response.status === 200){
            axios.get(`api/v1/orders/getdrafts?pi=${0}&ps=${ps}`, {headers:{Authorization: token }})
            .then(function(response){
              console.log("Get drafts", response.data);
              dispatch({ type: reciveDraftsType, d_pi: 0, d_ps: ps, d_count: response.data.tt, list: response.data.list });        
            })
            .catch((error) => {        
              console.log(error);            
            });
          }
        })
        .catch((error) => {        
          console.log(error);            
        });
      });        
  },

  requestDraft: (id) => async (dispatch, getState) => {
    const req = getState().ordersRequest;        
    if (id === req.draftId) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
    }

    dispatch({ type: requestDetailsType });

    const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {      
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        }

        const url = `api/v1/order/get/${id}`;

        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          // console.log("Draft" ,response.data);
          dispatch({ type: reciveDraftType,  Draft: response.data });
        })
        .catch((error) => {        
          console.log(error);            
        });
      });        
  },

  requestPayOrderFromBalance: (id) => async (dispatch, getState) => {
    const req = getState().ordersRequest; 
    
    dispatch({ type: loadingType });
    
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
    let token = null;   
    if(user != null){      
      token =  `Bearer ${user.access_token}`;
    }

    const url = `/api/v1/balance/payorder/${id}`;    
    axios.get(url,         
      { 
        headers: { Authorization: token }        
      }
    )
    .then(function(response){
      console.log("Payed from balance order", response.data);
      const orderPayedResult = response.data;
      if(!orderPayedResult.Succes){
        dispatch({ type: payOrderFromBalanceError, orderPayedResult: orderPayedResult});
      }
      else{
        const urlOdr = `api/v1/orders/getorders?pi=${req.o_pi}&ps=${req.o_ps}`;
        axios.get(urlOdr,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          // console.log("Orders" ,response.data);
          dispatch({ type: payOrderFromBalanceSuccess, orderPayedResult: orderPayedResult, Orders: response.data.list });
        })
        .catch((error) => {        
          console.log(error);            
        });          

      }      
    })
    .catch((error) => {        
      console.log(error);            
    });
  });        

  },

  requestConfirmedOrder: (id) => async (dispatch, getState) => {    
    const req = getState().ordersRequest;
    if (id === req.orderId) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
    }

    dispatch({ type: requestDetailsType });

    const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        }

        const url = `/api/v1/order/confirmed/${id}/true`;

        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          console.log("Confirmed order", response.data);
          dispatch({ type: reciveOrderType,  Order: response.data });
        })
        .catch((error) => {        
          console.log(error);            
        });
      });        
  },

  editOrder: (id, callbackEditOrder) => async  (dispatch) => {
    dispatch({ type: requestEditOrderType });
    const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        }

        const url = `/api/v1/order/editconfirmed/${id}`;

        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          console.log("Edit order1c", response.data);
          if(callbackEditOrder){
            // console.log(callbackEditOrder);
            callbackEditOrder(response.data);
          }
          dispatch({ type: loadingHideType });
        })
        .catch((error) => {        
          console.log(error.response); 
          const message = error.response.data;
          dispatch({ type: errorOrderMessageType, orderErrorMessage: message}); 
        });
      });
  },

  copyOrder: (id, callbackCopyOrder) => async  (dispatch) => {
    dispatch({ type: requestCopyOrderType });
    const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        }
        const url = `/api/v1/order/copy/${id}`;
        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){          
          if(callbackCopyOrder){
            callbackCopyOrder(response.data);
            dispatch({ type: loadingHideType });
          }
        })
        .catch((error) => {        
          const message = error.response.data;
          dispatch({ type: errorOrderMessageType, orderErrorMessage: message}); 
        });
      });
  },

  cancelOrder: (id) => async  (dispatch, getState) => {
    const req = getState().ordersRequest;
    dispatch({ type: requestCancelOrderType });    
    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }
      const url = `/api/v1/order/cancel/${id}`;
      axios.get(url, 
      { 
          headers: { Authorization: token }        
      }
    )
    .then(function(response){
        // console.log("Cancel order:", response.data);
        // console.log("ordersRequest:", req);

        const pi = req.o_pi;
        const ps = req.o_ps;
        const count = req.o_count;
        const orders = [...req.Orders];
        
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          if(response.data.Id === order.Id)
          {                                
            order.StateCode = response.data.StateCode;
            order.StateName = response.data.StateName;
            break;
          }                    
        }
        dispatch({ type: reciveOrdersType, o_pi: pi, o_ps: ps, o_count: count, list: orders });
    })
    .catch((error) => {
        const message = error.response.data;
        dispatch({ type: errorOrderMessageType, orderErrorMessage: message});
      });
    });
  },

  requestOrders: (pi, ps) => async (dispatch, getState) => {
        const req = getState().ordersRequest;        
        if (pi === req.o_pi && ps === req.o_ps) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }

        dispatch({ type: requestOrdersType });

        const userManager = await OidcUserManager();
        userManager.getUser().then(async (user) => {
          let token = null;   
          if(user != null){      
            token =  `Bearer ${user.access_token}`;
          }
          const url =  `api/v1/orders/getorders?pi=${pi}&ps=${ps}`;

        axios.get(url,         
          { 
            headers: { Authorization: token }        
          }
        )
        .then(function(response){
          // console.log("Orders" ,response.data);
          dispatch({ type: reciveOrdersType, o_pi: pi, o_ps: ps, o_count: response.data.TotalCount , list: (response.data.Data ? response.data.Data : [] ) });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  requestDrafts: (pi, ps) => async (dispatch, getState) => {
      const req = getState().ordersRequest;        
      if (pi === req.d_pi && ps === req.d_ps) {
          // Don't issue a duplicate request (we already have or are loading the requested data)
          return;
        }              

      dispatch({ type: requestOrdersType });

      const userManager = await OidcUserManager();
      userManager.getUser().then(async (user) => {      
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }

      const url =  `api/v1/orders/getdrafts?pi=${pi}&ps=${ps}`;

      axios.get(url,         
        { 
          headers: { Authorization: token }        
        }
      )
      .then(function(response){
        // console.log("Drafts", response.data);
        dispatch({ type: reciveDraftsType, d_pi: pi, d_ps: ps, d_count: response.data.tt, list: response.data.list ? response.data.list : []  });        
      })
      .catch((error) => {        
        console.log(error);            
      });          
  });      
  },

  refreshJournal: (tab) => async (dispatch, getState) => {
    const req = getState().ordersRequest;  
    const pi = 0;
    const ps = req.o_ps;

    dispatch({ type: requestOrdersType });
      const userManager = await OidcUserManager();
      userManager.getUser().then(async (user) => {      
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }

      const url =  `api/v1/orders/${(tab === 0 ? "getorders":"getdrafts")}?pi=${pi}&ps=${ps}`;
      
      axios.get(url, { headers: { Authorization: token } })
        .then(function(response){          
          if(tab === 0) {
            dispatch({ type: reciveOrdersType, o_pi: pi, o_ps: ps, o_count: response.data.TotalCount , list: (response.data.Data ? response.data.Data : [] ) });
            // dispatch({ type: reciveOrdersType, o_pi: pi, o_ps: ps, o_count: response.data.tt , list: response.data.list });
          } else {
            dispatch({ type: reciveDraftsType, d_pi: pi, d_ps: ps, d_count: response.data.tt, list: response.data.list });
          }          
        })
        .catch((error) => {        
          console.log(error);            
        });
      });
  },   

  requestClientsOrders: (query) => async (dispatch) => {
    // const req = getState().ordersRequest;
    // if (pi === req.c_pi && ps === req.c_ps) {
    //     // Don't issue a duplicate request (we already have or are loading the requested data)
    //     return;
    //   }              

    dispatch({ type: requestOrdersType });

    const userManager = await OidcUserManager();
    userManager.getUser().then(async (user) => {      
    let token = null;   
    if(user != null){      
      token =  `Bearer ${user.access_token}`;
    }

    const url =  `api/v1/orders/getordersbyclients?pi=${query.pi}&ps=${query.ps}`;

    axios.get(url,         
      { 
        headers: { Authorization: token }        
      }
    )
    .then(function(response){
      console.log("OrdersClients" ,response.data);
      dispatch({ type: reciveClientsOrdersType, c_pi: query.pi, c_ps: query.ps, c_count: response.data.tt , list: response.data.list });
    })
    .catch((error) => {        
      console.log(error);            
    });          
});      
  },

  requestCloseMessage: () => async (dispatch) => {
    dispatch({ type: closeMessage });  
  },

  closeErrorOrderMessage: () => async (dispatch) => {
    dispatch({ type: errorOrderCloseMessageType });  
  },


};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case errorOrderMessageType: 
          return {
            ...state,
            orderError: true,
            isLoading: false,
            orderErrorMessage: action.orderErrorMessage
          }
        case errorOrderCloseMessageType:
          return {
            ...state,
            orderError: false,
            erderErrorMessage: null
          }
        case closeMessage:
          return{
            ...state,
            orderPayedResult: null,
        }        
        case loadingType:
        case requestCopyOrderType:
        case requestEditOrderType:
          return{
            ...state,
            isLoading: true
        } 
        case loadingHideType:
          return {
            ...state,
            isLoading: false
        }       
        case payOrderFromBalanceError: 
        return{
          ...state,
          isLoading: false,
          orderPayedResult: action.orderPayedResult,
          orderPayed: action.orderPayedResult.Succes,
        } 
        case payOrderFromBalanceSuccess: 
        return{
          ...state,
          isLoading: false,
          orderPayedResult: action.orderPayedResult,
          orderPayed: action.orderPayedResult.Succes,
          Orders: action.Orders,
        }               
        case requestOrdersType: 
        case requestDetailsType:
        case deleteDraftType:
          return {
              ...state,
              Draft: null,
              Order: null,
              isLoading: true
        }
        case reciveDraftType: 
          return {
            ...state,
            Draft: action.Draft,
            isLoading: false,
        }
        case reciveOrderType: 
          return {
            ...state,
            Order: action.Order,
            isLoading: false,
        }          
        case reciveOrdersType:
          return {
            ...state,
            o_pi: action.o_pi,
            o_ps: action.o_ps,
            o_count: action.o_count,
            isLoading: false,
            Orders: action.list,
        }
        case reciveDraftsType:
          return {
            ...state,
            d_pi: action.d_pi,
            d_ps: action.d_ps,
            d_count: action.d_count,            
            isLoading: false,
            Drafts: action.list,
        }
        case reciveClientsOrdersType:
            return {
              ...state,
              c_pi: action.c_pi,
              c_ps: action.c_ps,
              c_count: action.c_count,
              isLoading: false,
              OrdersClients: action.list,
        }
        default:
          return state;
    }
};