import React from 'react';
import PropTypes from 'prop-types';
import PdfViewer from '../../Viewer/PdfViewer';

const PdfBlock = props => {
    const { url } = props;
    const query = new URLSearchParams(url);    
    const p = query.get('p');
    const page = p ? parseInt(p) : 1;
    return (
        <PdfViewer url={url} page={page} />
    )
}

PdfBlock.propTypes = {
    url: PropTypes.string.isRequired,
}

export default PdfBlock