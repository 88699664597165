import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import DetailPromotion from "../Promo/DetailPromotion";
import { Page401, Page404 } from '../Page';


export const Promotion = () => {    
    const { id } = useParams();
    const [token, setToken] = useState();    
    const app = useContext(AppContext);
    const { lang } = app;
    const [ promotion, setPromotion ] = useState(null);
    const [status, setStatus] = useState(200);

    const loadContent = useCallback((id, lang) => {
        if(!token) return;

        const url =  `/api/v1.0/promotions/getbyid/${id}/true/${lang}`;
        // console.log('url', url);
        
        fetch(url,            
            { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => {
                
                setStatus(response.status);
                if(response.status === 200) {
                    response.json().then((data) => {
                        if (data) {                             
                            setPromotion(data);                            
                        }
                    });
                }
            })                
                
            
    
                       
            .catch((error) => {
                console.log('Error', error);
            })
            .finally( () => {});



    }, [token, setPromotion, setStatus]);

    useEffect(() => {
        if (!app) return;

        function fetchUser() {
            app.getUser().then(async user => {                    
                if (user) {
                    setToken(`${user.token_type} ${user.access_token}`);
                }else{
                    setToken('Empty');
                }
            });
        }
        fetchUser();
        loadContent(id, lang);
    }, [app, loadContent, id, lang]);

  return (
    <AppConsumer>
        {() => (            
            <>
                {/* <p>{status}</p> */}

                { promotion &&
                    <DetailPromotion promotion={promotion} /> 
                }

                { (status === 403 || status === 401) &&
                    <Page401 />
                }

                { status === 404 &&
                    <Page404 />
                }                
            </>
        )}
    </AppConsumer>
  )
}

export default Promotion;
