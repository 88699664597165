import React, {useState } from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Viewer from '../Manage/Content/Viewer';
import { useTranslation } from "react-i18next";
import { AppContext } from "../../providers/appProvider";

const useStyles = makeStyles((theme) =>
    createStyles({
        dialogContent: {
            height: theme.spacing(70),
        },
        topScrollPaper: {
            alignItems: 'flex-end',
        },
        topPaperScrollBody: {
            verticalAlign: 'bottom',
        },

        appBar: {
            top: 'auto',
            bottom: 0,
        },
        grow: {
            flexGrow: 1,
        },
        box: {
            display: "flex",
            padding: theme.spacing(1,1),            
            height: 'auto',
            [theme.breakpoints.down("sm")]: {
                height: theme.spacing(40),
            },
            
            overflow: "hidden",
            overflowY: "scroll" 
        },
        buttons: {
         marginBottom: theme.spacing(1)
        }
    })
);

const AcceptCookies = (props) => {
    const { onAcceptCookies, sendConsent } = props;
    

    const [ open, setOpen ] = useState(true);
    const [ notAcceptCookies, setNotAcceptCookies ] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const not_accept_cookies_url = 'I_do_not_accept_cookies';
    const acceptcookies_url = 'acceptcookies';

    const handleDecision = (decision) => {
        const consent = {
            'ad_storage': decision,
            'analytics_storage': decision,
            'ad_user_data': decision,
            'ad_personalization': decision
        };        
        sendConsent('update', consent);        
    };

    const handleAccept = () => {
        if(onAcceptCookies && sendConsent){            
            handleDecision("granted");
            onAcceptCookies(1);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setNotAcceptCookies(true);
        handleDecision("denied");                        
        // setOpen(false);
    };


    return ( 
        <AppBar position="fixed" color='inherit' className={classes.appBar}>
            <div>            
                <Box className={classes.box}>            
                    { !notAcceptCookies && <Viewer url={acceptcookies_url} showSkeleton={false} /> }
                    { notAcceptCookies && <Viewer url={not_accept_cookies_url} showSkeleton={false} />  }
                </Box>
                <Box pl={2} pr={2} pt={1}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.buttons}
                        spacing={1}
                    >
                            <Grid item xs={12} sm={4} md={3} lg={2} >
                                <Button onClick={handleAccept} color="primary" autoFocus variant='contained' fullWidth >
                                    {t("chat.Accept")}
                                </Button>
                            </Grid>

                            {/* <Grid item xs={12} sm={4} md={3} lg={2} >
                                <Button onClick={handleClose} color="default" variant='outlined' fullWidth>
                                    {t("chat.Decline")}
                                </Button>
                            </Grid> */}
                            
                    </Grid>
                </Box>
            </div>
        </AppBar>       
        
    )
}

AcceptCookies.propTypes = {
    onAcceptCookies: PropTypes.func.isRequired,
    sendConsent: PropTypes.func.isRequired,
}

export default AcceptCookies;
