
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
// import Autocomplete from "@material-ui/lab/Autocomplete";

// eslint-disable-next-line no-unused-vars
import {Box, InputLabel, Checkbox, FormControlLabel, FormControl, TextField, TextareaAutosize, Typography, Button, Select, MenuItem, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },    
    button: {
      marginRight: theme.spacing(1)
    },
}));



const PaperEditor = (props) => {
  const { onUpdate, node } = props;
  const classes = useStyles();
  const [ component, setComponent] = useState(node);
  const [ padding, setPadding] = useState(node.props && node.props.style && node.props.style.padding ? node.props.style.padding: '');
  const [ margin, setMargin] = useState(node.props && node.props.style && node.props.style.margin ? node.props.style.margin: '');
      
  const handleUpdate = () => {
    if(onUpdate){ 
      console.log('update component', component); 
      onUpdate(component);
    }
  };
  
  const handleChangePadding = (e) => {    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    if(typeof component.props.style === "undefined")
      component.props.style.padding = '';
  
    const style = {...component.props.style};
    style.padding = e.target.value;
    component.props.style = style;
    setPadding(e.target.value);
    setComponent(component);
  };
  
  const handleChangeMargin = (e) => {    
    if(typeof component.props.style === "undefined")
      component.props.style = {};
    if(typeof component.props.style === "undefined")
      component.props.style.margin = '';
  
    const style = {...component.props.style};
    style.margin = e.target.value;
    component.props.style = style;
    setMargin(e.target.value);
    setComponent(component);
  };

  useEffect( () => {
    if(node)
      setComponent(node);
  }, [node]);

  return (
    <div className={classes.root}>
        {component && 
          <Box pl={1} pr={1}>
            <Box p={2}>
              <Box pb={1}>
              <TextField 
                label="padding"
                variant="outlined"
                size="small"
                value={padding}                   
                fullWidth
                onChange={handleChangePadding}
              />
              </Box>
              <TextField 
                label="margin"
                variant="outlined"
                size="small"
                value={margin}
                fullWidth
                onChange={handleChangeMargin}
              />
            </Box>
                  
            <Box p={2}>
              <Button 
                fullWidth 
                size="small" 
                variant="outlined" 
                color='primary' 
                onClick={handleUpdate} startIcon={<CheckIcon />}
              >
                Applay
              </Button>
            </Box>                                    
          </Box>
        }
    </div>
  )
}

PaperEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,  
}

export default PaperEditor