import React, { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {IconButton, Button, Box, Grid, Paper, Typography } from "@material-ui/core";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import SvgIcon from "@material-ui/core/SvgIcon";

import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const API_URL = '/api/v1.0';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex-row",
      padding : theme.spacing(2)
    },
    paper: {        
        position: 'relative',
        padding: theme.spacing(2),
        height: "100%",
        boxShadow:
        "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    },
    button: {
        minWidth: theme.spacing(14)
    },
    img: {
        right: 8,
        top: 8,
        height: 98,
        width: 98,
        position: 'absolute'
    },
    bal_conner_img: {
        right: 0,
        bottom: 0,
        height: "90%",
        position: "absolute"
    },

    bal_img: {
        color: theme.palette.primary.light,
        fontSize: theme.spacing(9),
        right: 8,
        [theme.breakpoints.up("sm")]: {
            fontSize: theme.spacing(11),
            right: 16,
        },
        
        // bottom: 10,
        top: 8,        
        position: "absolute",
        filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))",
        
    },
}));

export const Utils = () => {
    const classes = useStyles();    
    const auth = useContext(AppContext);
    
    const [token, setToken] = useState(null);
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);

    const handleClearMessage = () => {
        setMessage('');
    };

    const handleClearCache = async () => {
        if(!token){
            return;
        }
        setDisable(true);
        const url = `${API_URL}/tools/clearcache`;
        await fetch(url, { 
            method: 'GET', 
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": token
            },          
        })
        .then((response) => response.json())
          .then((data) => {
            if(data){
                console.log(data);
                setMessage(`Ok! Cleared cache by keys: ${data.join(', ')}`);  
            }
          })
          .catch((error) => {
            console.log("Error", error);
          })
          .finally(() => {
            setDisable(false);
          });
    };

    const handlePopulateIndex = async () => {
        if(!token){
            return;
        }

        setDisable(true);
        const url = `${API_URL}/tools/populatesearchindex`;
        await fetch(url, { 
            method: 'GET', 
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": token
            },          
        })
        .then((response) => response.json())
          .then((data) => {
            if(data){
                setMessage(`Ok! Clear and populate fulltext index. ${data}`);                  
            }
          })
          .catch((error) => {        
            console.log("Error", error);
          })
          .finally(() => {
            setDisable(false);
          });
    };
    
    useEffect(() => {
        function fetchUser() {
          auth.getUser().then((user) => {
            let token = null;
            if (user != null) {
              token = `${user.token_type} ${user.access_token}`;
            }
            setToken(token);
          });
        }
        fetchUser();
      }, [auth]);

  return (
    <AppConsumer>
      {() => (
        <div className={classes.root}>


            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch" 
                spacing={2}
                
            >
                <Grid item xs={12} sm={6} md={5}>
                    <Paper className={classes.paper}>
                        <img className={classes.bal_conner_img} alt="triangle background" src="/assets/images/triangle-light.png" />
                        <SvgIcon className={classes.bal_img} color="primary">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </SvgIcon>
                        <Box pb={2}>
                            <Typography variant="h4" display="block" gutterBottom>Clear cache</Typography>
                            <Typography variant="caption" display="block" gutterBottom>Full clear application cache</Typography>
                        </Box>
                        <Button 
                            className={classes.button} 
                            color="primary" 
                            variant="outlined"
                            onClick={handleClearCache}
                            disabled={disable}
                            startIcon={<DeleteIcon />}
                        >
                                Clear
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>                            
                        <img className={classes.bal_conner_img} alt="triangle background" src="/assets/images/triangle-light.png" />
                        <SvgIcon className={classes.bal_img} color="primary">
                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                        </SvgIcon>

                        <Box pb={2}>
                            <Typography variant="h4" display="block" gutterBottom>Populate index</Typography>
                            <Typography variant="caption" display="block" gutterBottom>Clear and populate product catalog fulltext index</Typography>
                        </Box>
                        <Button 
                            className={classes.button} 
                            color="primary" 
                            variant="outlined"
                            onClick={handlePopulateIndex}
                            disabled={disable}
                            startIcon={<UpdateIcon />}
                        >
                            Populate
                        </Button>
                    </Paper>
                </Grid>            
            </Grid>
            
            {message !== '' && 
            <Box p={4}>
                <IconButton
                    onClick={handleClearMessage}
                >
                    <HighlightOffIcon />
                </IconButton>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                    <Grid item xs={12} >
                        <Paper className={classes.paper} elevation={0}>
                            <Typography>
                                {message}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        }
            
                
            
        </div>
       )}
    </AppConsumer>
  )
}
