import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";
import {Divider, AppBar, Toolbar, CssBaseline, Paper, Box, InputBase, Typography, Button, Grid,  IconButton, CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { TableCell } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "../../CustomDialog";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LangResEditor from './LangResEditor';

import EditIcon from "@material-ui/icons/Edit";
import {FolderOutlineIcon, FolderOpenOutlineIcon} from '../../icons/jerelia-icons';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';

// import { countryToFlag } from "../../../store/countries";

const API_URL = '/api/v1.0';
const emptyGuid = "00000000-0000-0000-0000-000000000000";

const oldRender = TableCell.render;

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

const CDN = `${process.env.REACT_APP_CDN}Pictures/76x76/`;
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {      
      padding: theme.spacing(1)
    },
    grow: {
      flexGrow: 1,
    },
    sectionDesktop: {            
      display: 'flex',      
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: 0,
      left: -12,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: 8,
      marginTop: -14,
      // marginLeft: -12,
    },
    dialogtitle: {
      textAlign: 'center',
      fontSize: '1.1rem'
    },
    fieldname: {
      margin: theme.spacing(1,0)
    },
    roottab: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: 390,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    langname: {
      fontSize: '.6rem'
    },
    search: {
      position: 'relative',
      padding: 2,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
      },
      marginLeft: 0,
      marginRight: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '18ch',
        '&:focus': {
          width: '28ch',
        },
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
  })
);

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    // marginRight: theme.spacing(1),
  },
  labelText: {
    marginLeft: theme.spacing(1),
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,  
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `lang-tab-${index}`,
    'aria-controls': `lang-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Commodities = () => {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useContext(AppContext);
  const [token, setToken] = useState(null);
  const attrRef = useRef();

  // const containerRef = useRef();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [treeData, setTreeData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [cultures, setCultures] = useState([]);

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedProd, setSelectedProd] = useState();
  const [baseRes, setBaseRes] = useState();
  const [prodId, setProdId] = useState();
  const [filterArrayFullMatch, setFilterArrayFullMatch] = useState(true);
  const [search, setSearch] = useState('');
  const [openDlg, setOpenDlg] = useState(false);
  const [lang, setLang] = useState('uk');
  const [langTab, setLangTab] = useState(0);
  const [procSave, setProcSave] = useState(false);
  const [loadingTr, setLoadingTr] = useState(false);
  const [loadingClear, setLoadingClear] = useState(false);
  const [loadingPopulate, setLoadingPopulate] = useState(false);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    // loadGridData(nodeIds);
  };
  
  const isExpanded = (id) => {
    return expanded.findIndex(el => el === id) > -1;    
  };

  const loadCultures = useCallback( async () => {
    if(!token) return;

    const URL = `${API_URL}/languege`;        
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", token);
    
    fetch(URL, { method: "GET", headers: headers })      
      .then(response => response.json())
        .then(data => {
          setCultures(data);         
    })
    .finally(() => {        
    });
  }, [token]);
  
  const loadTreeData = useCallback( async () => {
    if(!token ) return;  
    
    const URL = `${API_URL}/goods/GeProductGroupSiteTree`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", token);
    fetch(URL, { method: "GET", headers: headers })
      .then(response => response.json())
        .then(data => {
          setTreeData(data);         
    })
    .finally(() => {        
    });
  }, [token]);

  const loadGridData = useCallback (async (grpId) => {
    if(!token ) return;
    if(grpId.length > 0){
        const URL = `${API_URL}/goods/getproductssimple/${grpId}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        fetch(URL, { method: "GET", headers: headers })      
          .then(response => response.json())
            .then(data => {
                setGridData(data);
        })
        .catch( ()=> setGridData([]))
        .finally(() => {        
        });
    }
  }, [token]);

  const searchGridData = useCallback (async () => {
    if(!token ) return;
    
    const URL = `${API_URL}/goods/searchproductssimple/${search}`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", token);
    fetch(URL, { method: "GET", headers: headers })      
      .then(response => response.json())
        .then(data => {            
            setGridData(data);                          
    })
    .finally(() => {        
    });    
  }, [search, token]);

  const handlesearchGridData = () => {
    searchGridData();
  };

  const keyPressSearch = e => {
    if (e.key === "Enter") {      
      searchGridData();
      e.preventDefault();
    }
  };

  const editProduct = (idx) => {    
    setProdId(gridData[idx].Id);
    loadProduct(gridData[idx].Id);
  };

  const handleTransleteSelected = () => {
    if(!selectedProd)
      return;

    // console.log('Translate product', selectedProd);
    
    setLoadingTr(true);

    const url = '/api/v1.0/translate/product';    
    fetch(url,
        {
            method: 'POST',         
            body: JSON.stringify(selectedProd),
            headers: {                  
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": token
            }
        },
    )
    .then((response) => response.json())
        .then((data) => {          
          // console.log('Transleted product', data);          
          setSelectedProd(data);
          if(langTab === 0){
            setLangTab(1);
          }
          else{
            setLangTab(0);
          }
          // attrRef.current.updateTab();
        })
        .catch((error) => {
            console.log('Error', error);                
        })
        .finally(()=>{
          setLoadingTr(false);
        });    
  };

  const handleClearCache = async () => {
    if(!token){
        return;
    }
    setLoadingClear(true);
    const url = `${API_URL}/tools/clearcache`;
    await fetch(url, { 
        method: 'GET', 
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": token
        },          
    })
    .then((response) => response.json())
      .then((data) => {
        if(data){
          setLoadingClear(false);
          alert(`Clear cache keys: ${data.join(', ')}`)    
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {     
        setLoadingClear(false);   
      });
};

const handlePopulateIndex = async () => {
    if(!token){
        return;
    }

    setLoadingPopulate(true);
    const url = `${API_URL}/tools/populatesearchindex`;
    await fetch(url, { 
        method: 'GET', 
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": token
        },          
    })
    .then((response) => response.json())
      .then((data) => {
        if(data){
          setLoadingPopulate(false);
          alert(`Ok! Fill fulltext search index. ${data}`);                  
        }
      })
      .catch((error) => {        
        console.log("Error", error);
      })
      .finally(() => {
        setLoadingPopulate(false);      
      });
};

  const saveProduct = () => {
    updateResource();
    setProcSave(true);
  };

   useEffect(() => {    
    if(!procSave){
      return;
    }

    const URL = `${API_URL}/goods/saveproduct`;    
    
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", token);
    
    fetch(URL, { 
            method: "POST",
            headers: headers,
            body: JSON.stringify(selectedProd)
    })      
    .then(response => response.json())
        .then(saved => {
            console.log('Saved product' ,saved);
    })
    .catch((error) => {
        console.log("Error Save prdouct", error);        
    })
    .finally(() => {
      setProcSave(false);
    });

    // fetch(URL, { method: "POST", headers: headers })      
    //   .then(response => response.json())
    //     .then(data => {
            
    //         setGridData(data);
    // })
    // .catch( ()=> setGridData([]))
    // .finally(() => {        
    // });

    

  }, [procSave, selectedProd, token]);

  const loadProduct =  useCallback((id) => { 
    if(!token ) return;
    if(!id) return;

    const URL = `${API_URL}/goods/getproduct/${id}/1`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Authorization", token);
    fetch(URL, { method: "GET", headers: headers })      
      .then(response => response.json())
        .then(data => {
          console.log("PRODUCT", data);
          setSelectedProd(data);
          if(data){
            const fi = data.TransletedAAttrs.findIndex(t => t.LangTwoLatter === "uk");
            if(fi > -1){
              setBaseRes(data.TransletedAAttrs[fi]);
            }
          }
          setOpenDlg(true);
    })
    .catch( ()=> setSelectedProd(null))
    .finally(() => {        
    });

    
  }, [token ]);
  
  useEffect(() => {    
    loadGridData(selected);    
  }, [loadGridData, selected]);
  
  useEffect(() => {
    if(!auth ) return;

    function fetchUser() {
      auth.getUser().then(async user => {
          let token = null;
          if (user != null) {
              token = `Bearer ${user.access_token}`;
              await loadTreeData();
              await loadCultures();
          }
          setToken(token);
      });
    }
    fetchUser();
    
  }, [auth, loadTreeData, loadCultures]);
  
  const updateResource = () => {
    var changed =  attrRef.current.updateData();
    if(selectedProd && changed){
      const idx = selectedProd.TransletedAAttrs.findIndex(a => a.LangTwoLatter === changed.LangTwoLatter);
      if(idx > -1){
        selectedProd.TransletedAAttrs[idx] = changed;
        setSelectedProd(selectedProd);
      }      
    }
  };

  const handleChangeTab = (event, newValue) => {    
    updateResource();
    setLangTab(newValue);
  };

  const buildTreeChild = (parent) => {    
    return (
      <StyledTreeItem key={parent.Id} 
        nodeId={parent.Id} 
        labelText={parent.Name}         
        labelIcon={ parent.children.length > 0 ? isExpanded(parent.Id) ? FolderOpenOutlineIcon : FolderOutlineIcon : InsertDriveFileOutlinedIcon} >
        { parent && parent.children.length > 0 && parent.children.map((node) => buildTreeChild(node))}
      </StyledTreeItem>
    );
  };

  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [20, 50, 100, 250],
    filter: true,
    selectableRows: 'none',
    filterArrayFullMatch: filterArrayFullMatch,
    filterType: 'dropdown',
    responsive: 'standard',
    jumpToPage: true,
  };

  const columns = [
    {
      name: "Image",
      label: "Img",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value !== "" ?
                <img style={{height: 38}} alt={value} src={`${CDN}${value}`} />
                : undefined
        )
      }
    },
    {
      name: 'Code',
      label: t('product.Code'),
      options: {
        filter: true,        
      },
    },
    {
      name: 'Name',
      label: t('product.Name'),
      options: {
        filter: true,        
      },
    },
    {
      name: 'SeriaName',
      label: t('product.Seria'),
      options: {
        filter: true,
      },
    },
    {
      name: 'ProductGroupSiteName',
      label: t('product.Group'),
      options: {
        filter: true,        
      },
    },
    {
      name: 'ProductGroupSiteKey',
      label: t('product.GroupKey'),
      options: {
        filter: true,        
      },
    },
    {
      name: "Act",
      label: " ",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton onClick={() => {                
                editProduct(dataIndex);
              }}>                
              <EditIcon />
            </IconButton>
          );
        }
      }
    },
  ];

  return (
    <AppConsumer>
      {() => (
        <div className={classes.root}>          
            <AppBar position="static" color='inherit' elevation={1} style={{marginBottom: 8}}>
              <Toolbar variant="dense">            
                <div className={classes.search}>
                  <div className={classes.searchIcon}>                    
                      <SearchIcon />                                        
                  </div>
                  <InputBase
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => keyPressSearch(e) }
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
                <Button                   
                  variant="outlined"
                  color='primary'
                  onClick={handlesearchGridData}                  
                >
                  Search
                </Button>                
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>                                                                  
                  <div className={classes.wrapper}>
                    {/* <IconButton            
                      edge="start"                      
                      aria-label="clear_cache"
                      title="Clear cache"
                      disabled={loadingClear}
                      onClick={handleClearCache}
                    >
                    <DeleteIcon />
                  </IconButton> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear cache"
                    disabled={loadingClear}
                    onClick={handleClearCache}
                    startIcon={<DeleteIcon />}
                  >
                    Clear cache
                  </Button>

                  {loadingClear && <CircularProgress size={28} className={classes.buttonProgress} />}
                  </div>

                  <div className={classes.wrapper}>
                    {/* <IconButton            
                      edge="start"                      
                      aria-label="populate"
                      title="Populate search index"
                      disabled={loadingPopulate}
                      onClick={handlePopulateIndex}
                    >
                    <UpdateIcon />
                  </IconButton> */}
                   <Button
                    variant="outlined"
                    color="primary"
                    title="Populate search index"
                    disabled={loadingPopulate}
                    onClick={handlePopulateIndex}
                    startIcon={<UpdateIcon />}
                  >
                    Populate index
                  </Button>

                  {loadingPopulate && <CircularProgress size={28} className={classes.buttonProgress} />}
                  </div>
                  </div>
                

              </Toolbar>
            </AppBar>                  
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={3}>              
              <Paper  elevation={1} style={{padding: 8}} >
                {treeData && 
                <TreeView                    
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  selected={selected}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleSelect}
                >
                  { treeData && treeData.length > 0 && treeData.map((node) => buildTreeChild(node)  )  }
                </TreeView>
                }
              </Paper>
            </Grid>
            <Grid item xs={9}>  
              {gridData.length > 0 &&           
                <MUIDataTable 
                  title={t("product.Products")}
                  data={gridData}
                  columns={columns}
                  options={options} /> }
            </Grid>
          </Grid>

          <Dialog onClose={() => setOpenDlg(false)} aria-labelledby="product-dialog-title" open={openDlg} fullWidth={true} maxWidth="xl" fullScreen={fullScreen} >
            <DialogTitle className={classes.dialogtitle} onClose={() => setOpenDlg(false)}>
              {selectedProd ? `${selectedProd.Code}, ${selectedProd.Name}, ${selectedProd.SeriaName}` : undefined}
              </DialogTitle>
            <DialogContent style={{height: 660, paddingTop: 8}}>
              {selectedProd && <>
                <AppBar position="static" color='default'>
                <Toolbar variant="dense">
                  <div className={classes.wrapper}>
                    <IconButton            
                      edge="start"
                      color="secondary" 
                      aria-label="translete"
                      title="Translate product attributes"
                      disabled={loadingTr}
                      onClick={handleTransleteSelected}
                    >
                    <TranslateIcon />
                  </IconButton>
                  {loadingTr && <CircularProgress size={48} className={classes.fabProgress} />}
                  </div>                    
                      <Tabs                                    
                        variant="scrollable"
                        value={langTab}
                        onChange={handleChangeTab}
                        aria-label="Langs resources"
                        // className={classes.tabs}
                      >
                      {selectedProd.TransletedAAttrs.map((res, idx) => 
                        <Tab key={res.LangTwoLatter} 
                            wrapped 
                            label={<>
                                    <Typography variant="body2">{res.LangTwoLatter}</Typography>
                                    <Typography variant="caption" className={classes.langname}>{res.LangName}</Typography>
                                  </>} 
                            {...a11yProps(idx)} /> 
                      )}
                      </Tabs>
                </Toolbar>
                </AppBar>
                {selectedProd.TransletedAAttrs.map((res, idx) => 
                <TabPanel key={res.LangTwoLatter} value={langTab} index={idx} >
                  <LangResEditor 
                    ref={attrRef} 
                    data={res} 
                    baseRes={baseRes}
                    token={token}
                  />
                </TabPanel> )}
              </>}
            </DialogContent>
            <DialogActions>
                    <Button 
                      disabled={procSave}
                      color="primary" 
                      startIcon={<SaveIcon />}
                      onClick={saveProduct}
                    >{t("buttons.Save")}</Button> 
                    <Button onClick={() => setOpenDlg(false)}>{t("buttons.Close")}</Button>                    
            </DialogActions>
          </Dialog>
        </div>
    )}
    </AppConsumer>
  )
}

export default withRouter(Commodities);