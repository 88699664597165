import React, { useEffect, useContext, useState } from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionCreators } from "../../store/NavItems";

import { useExternalScript } from "./useExternalScript";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { useHistory } from "react-router-dom";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Typography, CircularProgress } from "@material-ui/core";
import GoBackRecharge from "./GoBackRecharge";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),      
    },    
    paper: {
      padding: theme.spacing(0, 1),
      boxShadow:
        "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    }    
  })
);

const Fondy = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const auth = useContext(AppContext);
    const [token, setToken] = useState();
    const [doc, setDoc] = useState();
    const history = useHistory();
    
    const dispatch = useDispatch();     
    // const appLayout = useSelector((state) =>  state.appLayout );
    
    const { payment } = props.location.state;
    
    const status = useExternalScript("https://pay.fondy.eu/latest/checkout-vue/checkout.js");
    
  // Get token
  useEffect(() => {
    if (!auth) return;

    function fetchUser() {
      auth.getUser().then(async (user) => {
        let token = null;
        if (user != null) {
          token = `Bearer ${user.access_token}`;
        }
        setToken(token);
      });
    }
    fetchUser();
  }, [auth]);

    // Fondy checkout
  useEffect(() => {    
    if(!token) return;
    if(!payment) return;
    if(!window.fondy) return;
    
    console.log('PAYMENT', payment);

  
    const url = payment.docId ? 
                `/api/v1.0/fondy/payorder/${payment.docId}/` :
                `/api/v1.0/fondy/pay/${payment.amount}/`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('payment', data);
        if (data) {
          
          setDoc(data);

          const Options = {
            options: {
              methods: ['card', 'wallets'],              
              methods_disabled: ['banklinks_eu', 'local_methods','receipt','loans','most_popular'],
              link: 'https://jerelia.com',
              active_tab: 'card',
              card_icons: ['mastercard', 'visa'],
              logo_url: 'https://jerelia.com/assets/files/images/logos/jerelia.svg',
              full_screen: true,              
              title: (data.baseId ? t("order.OrderNum", {num: data.title}) : t(data.title).toUpperCase()) ,
              default_country: 'UA',
              email: false,
              countries: ['UA', 'US','DE', 'GB', 'PL'],
              fields: false,
              locales: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'it', 'ko', 'lv', 'pl', 'ro', 'sk', 'uk'],
              theme: {
                type: "light",
                preset: "navy_shimmer"
              },
            },
            css_variable: {
              main: '#4527a0',
              main_bg: '#f4f5fa',
            },
            params: {
              merchant_id: parseInt(data.merchantid),              
              required_rectoken: 'y',
              currency: data.currency,
              lang: i18n.language,
              order_id: data.docid,
              amount: data.amount,
              order_desc: t("OrderDescription", {str: data.description}),
              // custom: data,
              custom: {
                "docid": data.docid,
                "title": data.title,
                "amount": data.amount,
                "baseid": data.baseid,
                "doctype": data.doctype,
                "currency": data.currency,
                "merchantid": data.merchantid,
                "description": `Customer ${data.description}`
              },
              
              server_callback_url: data.server_callback_url
            }
          };

          const app = fondy("#fondy_checkout", Options);
          app.$on('success', function (model) {
              if(process.env.NODE_ENV === "development"){
                console.log('Fondy success');
                console.log(model);
              }

              if(model.data.order.response_status === "success" && model.data.order.order_data.order_status === "approved"){                
                    
                const additional_info = JSON.parse(model.data.order.order_data.additional_info);
                if(process.env.NODE_ENV === "development")
                  console.log("additional_info", additional_info);
                
                const reservation_data = JSON.parse(additional_info.reservation_data);
                
                if(process.env.NODE_ENV === "development")
                  console.log("reservation_data", reservation_data);

                setTimeout(() => {
                  dispatch(actionCreators.getCustomer(token));
                }, 2000);

                if(reservation_data && reservation_data.base_id){
                  history.push(`/orderfin/reg/pp/${reservation_data.base_id}`); 
                }
              }
          });
      
          app.$on('error', function (model) {
              console.log('error event handled', model);
          });


          
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
      .finally(() => {});


  }, [history, t, i18n.language, payment, status, token, dispatch ]);


  return (
    <AppConsumer>
      {() => (
          <div className={classes.root}>
            <Helmet>              
              <link rel='stylesheet' href='https://pay.fondy.eu/latest/checkout-vue/checkout.css' />                            
            </Helmet>                       
              {!window.fondy && 
                <Typography component='div' display="block" align="center" style={{paddingTop: 64}}>
                  <CircularProgress size={80} />
                </Typography>                
              }              
              <GoBackRecharge />
              <div id="fondy_checkout"></div>
          </div>
      )}
    </AppConsumer>
    );
};

export default connect(
  state => state.appLayout,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Fondy)

// export default Fondy