import React, { useRef, useEffect } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
// import Rellax from "rellax";
// import clsx from "clsx";
import MiddleSlide from "./MiddleSlide";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {    
        marginTop: theme.spacing(1),        
    },    
  })
);

const MiddleSlider = (props) => {    
    const classes = useStyles();
    const { slider, key, cname } = props;
    
    // const rellaxRef = useRef();

    const speed = (idx)=> {
        if(idx === 0)
            return '4';
        else if (idx === 1)
            return '0';
        else if(idx === 2)
            return "-2"
        else return '-4';

    };

    // useEffect(() => {
    //     new Rellax(".rellax", {               
    //         center: true,
    //         // round: true,     
    //         // wrapper: '.wrapper-rellax'
    //     });            
    //   }, []);
    
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div className={classes.root } key={key} cname={cname}>
        <Grid container direction="row" spacing={2} justifyContent="center"  alignItems="stretch">
        {slider.Items.map((slide, idx) => (
            <Grid item key={slide.Id} xs={12} sm={6} md={3}>                 
                <MiddleSlide slide={slide} />                 
            </Grid>
          ))}
        </Grid>
      </div>
    )
}

MiddleSlider.propTypes = {
  key: PropTypes.string,
  cname: PropTypes.string,
  slider: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Descr: PropTypes.string,
    ViewName: PropTypes.string.isRequired,
    FileName: PropTypes.string.isRequired,
    Items: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Caption: PropTypes.string.isRequired,
        Url: PropTypes.string.isRequired,
        Text: PropTypes.string.isRequired,
        Path: PropTypes.string.isRequired,
        // Img: PropTypes.string.isRequired,
        FullPath: PropTypes.string.isRequired,
        IsAuthenticated: PropTypes.bool.isRequired,
        Roles: PropTypes.arrayOf(PropTypes.string),
        Hide: PropTypes.bool.isRequired,
        ShowText: PropTypes.bool.isRequired,
        ShowFrom: PropTypes.string.isRequired,
        ShowTo: PropTypes.string.isRequired,
        ButtonText: PropTypes.string.isRequired,
      })
    ).isRequired,
  })  
};


export default MiddleSlider;