/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/uk";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import Box from "@material-ui/core/Box";
import AutoSizer from "react-virtualized-auto-sizer";
import { List } from "react-virtualized";
import { VariableSizeTree as Tree } from "react-vtree";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Alert, AlertTitle } from '@material-ui/lab';

import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Brightness1Icon from '@material-ui/icons/Brightness1';

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import CachedIcon from '@material-ui/icons/Cached';

// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import Popper from '@material-ui/core/Popper';
// import MenuList from '@material-ui/core/MenuList';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from "@material-ui/core/Collapse";
//import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Icon from '@material-ui/core/Icon';

import matchSorter from "match-sorter";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { red } from '@material-ui/core/colors';


moment.locale("uk");

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {    
        marginTop: 1,
        padding: theme.spacing(1,1),
        flexGrow: 1,
        width: "100%",
        // background: "#f7f9fc",
    },
    progress: {
        display: 'flex',        
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
    },
    buttonProgress: {        
    position: 'absolute',
    top: '50%',
    left: '20%',
    marginTop: -12,
    marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    shortname: {
        position: 'absolute',
        top: '25%',
        left: '22%',        
    },
    btnLeft: {
        borderRadius: "4px",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,        
        boxShadow: "none"
    },
    btnRight: {
        borderRadius: 0,
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        minWidth: 14, 
        padding: "6px 2px", 
        marginRight: 3,
        boxShadow: "none"
    },
    btnSearch: {
        height: 31,
        minWidth: 32,   
        padding: "4px 2px", 
        boxShadow: "none"
    },
    paper: {      
      padding: theme.spacing(1)
    },
    button: {
        marginRight: "4px"
    },
    popper: {
        zIndex: 1
    },
    iconButton: {
        marginRight: theme.spacing(1)
    },
    section: {
        marginTop: theme.spacing(1)
    },
    sectionbox: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    row: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        marginBottom: theme.spacing(1),
    },
    capitalize: {
        textTransform: "capitalize",
    },
    adviser: {        
    },
    ellipsis: {
        textOverflow: "ellipsis"
    },
    formControl: {        
        width: "auto",
        [theme.breakpoints.down("xs")]: {        
            width: "100%",           
        },
    },    
    autocomplete: {
        // width: 300,
        marginTop: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {        
            width: "100%",           
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    report: {
        padding: theme.spacing(0, 1),
    },
    reporthd: {
        padding: theme.spacing(2,4),
    },
    reportdetails: {
        minHeight: 269,
        [theme.breakpoints.down("xs")]: {        
            minHeight: "auto",
        },
    },
    reportdetails2: {
        minHeight: 120,
        [theme.breakpoints.down("xs")]: {        
            minHeight: "auto",
        },
    },
    indicator: {
        height: "100%",       
    }, 
    table: {
        height: "100%"
    }, 
    cell: {
        borderBottom: "none"
    },
    indname: {
        fontWeight: 400
    },    
    large: {
        width: 132,
        height: 132,
        textAlign: "center",
        display: "block",
        margin: "0 auto",
        border: "2px solid #fff",
        fontSize: "4em",
        // lineHeight: "3em",
        // color: theme.palette.getContrastText(lightBlue[500]),
        // backgroundColor: lightBlue[300],
        letterSpacing: "-0.1em",        
        // [theme.breakpoints.down("xs")]: {        
        //     display: "none",           
        // },
    },
    smallinfo: {
        padding: 2,
    },
    detailIcon: {
        height: 32,
        width: 32,
    },
    container: {
        padding: theme.spacing(0,1),
        // maxHeight: 440,
    },
    arrow: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        // margin: "auto",
        marginBottom: 4,        
        padding: 4,
        cursor: "pointer"
    },
    item: {
        display: "flex",
        alignItems: "center",
        // padding: "0 5px 0 0"
    },
    username: {        
        fontSize: ".85rem",
        textTransform: "capitalize",
        fontWeight: "400",
        lineHeight: "2.22",
        letterSpacing: "0.03333em"

    },
    shape: {
        margin: "6px 8px 0 0",
        backgroundColor: theme.palette.primary.main,
        width: 18,
        height: 18,
    },
    shapeCircle: {
        borderRadius: '50%',
        textAlign: 'center',
        paddingTop: 4,
        fontSize: '8px',
        fontWeight: 'bold',
        color: '#fff'
    },
    bd: {
        color: red[500]
    },
    btns: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    badge: {
        display: "block !important",
    },
    small: {
        marginTop: 4,
        fontSize: '8px',
        fontWeight: 'bold',
        width: 18,
        height: 18,
        
      },
  })
);

const StyledBadge = withStyles((theme) => ({
    badge: {        
        left: theme.spacing(2),
        top: 2,
        height: theme.spacing(2),
        padding: 0,
    },
  }))(Badge);

  const StyledBadgeStatus = withStyles((theme) => ({
    badge: {        
        left: 20,
        top: 2,
        height: theme.spacing(2),
        padding: 0,
    },
  }))(Badge);

function getUserName(userName){
    const words = userName.split(" ");
    const res = (`${words[1].charAt(0)} ${words[0].charAt(0)}`).toUpperCase();    
    return res;
}

const createNode = (data) => {
    const node = {
        id: data.id, 
        name: data.name,
        nlo: data.nlo, 
        lo: data.lo, 
        go: data.go,
        phone: data.phone,
        status: data.status, 
        statusactive: data.statusactive,
        bd: data.bd,
        isbeginner: data.isbeginner,
        isLeaf: data.isLeaf, 
        children: []
    };    
    return node;
};

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const getLastDay = (days) => {
    if(days > 100000)
        return "ніколи";

    switch (days) {
        case 0:
            return "сьогодні";
        // case 738171: 
        //     return "ніколи";    
        default:
            return `${days} дн тому`;
    }
}

const getColor = (status) => {
    switch (status) {
        case "Холодный":
            return "#ff3d00";        
        case "Теплый":        
            return "orange";
        case "Горячий":        
            return "green";    
        default:
            return "green";
    }
};

const howLongWithUs = (date) => {
    const registerDate = moment(date);
    const current = moment().startOf('day');
    const days = Math.abs(Math.floor( moment.duration(registerDate.diff( current)).asDays()));    
    // const days = moment.duration(registerDate.diff(current)).asDays();
    return days;
};

const validPhone = (phone) => {
    if(!phone.startsWith('38') && phone.length === 10 )
        return `38${phone}`;
    return phone;
};

const getStatus = (status) => {
    switch (status) {
        case "Старший партнер": return "СПР";
        case "Партнер": return "ПР";
        case "Молодший партнер": return "МПР";
        case "Глобал президент": return "ГПР";
        case "Президент": return "ПР";
        case "Віце-президент": return "ВПР";
        case "Прем’єр": return "П";
        case "Віце-прем’єр": return "ВП";
        case "Міжнародний директор": return "МД";
        case "Національний директор": return "НД";
        case "Регіональний директор": return "РД";
        case "Корпоративний директор": return "КД";
        case "Керуючий директор": return "УД";
        case "Гранд менеджер": return "ГМ";
        case "Керуючий менеджер": return "КМ";
        case "Старший менеджер": return "СМ";
        case "Менеджер": return "М";
        case "Молодший менеджер": return "ММ";
        case "Консультант": return "К";
        case "VIP клиент": return "VIP";
        default:
            return '?'        
    }
};

const prepareNodes = (data) => {
    if(!data || data.length === 0)
        return [];
    
    const nodes = [data.length];
    for (let i = 0; i < data.length; i++) {
        const el = data[i];
        // if(el["Клиент"] === "ddbcc7ae-a072-11e7-80cd-000c29102a69"){
        //     debugger;
        // }
        if(el["Новичок"] === true){
            console.log(el);
        }   

        nodes[i] = { 
            id: el["Клиент"], 
            name: el["ФИ"], 
            lo: el["ЛичныйОбъем"], 
            go: el["ГрупповойОбъем"],
            nlo: el["ЛОПоНеоплаченнымЗаказам"] ? el["ЛОПоНеоплаченнымЗаказам"] : 0,            
            cacheback: el["КэшБэкОстаток"],
            bonusregister: el["БонусПриРегистрацииОстаток"],            
            percentCacheBack: el["ТекущийПроцентКэшБек"], 
            amountNextStepCacheBack: el["СуммаДоСледующегоШагаВКэшБеке"],
            phone: el["Телефон"],
            status: el["Статус"], 
            statusactive: el["СтатусАктивности"],
            bd: el["СимволДР"],
            isbeginner: el["Новичок"],
            isLeaf: !el["Кликабельный"], 
            children: []
        };
    }     
    return nodes;    
};

const Circle = (props) => {
    const { bgColor, status, title } = props;
    const classes = useStyles();
    return (
        <div title={title} className={clsx(classes.shape, classes.shapeCircle)} style={{ backgroundColor: bgColor}}>{status}</div>
    );
}

const getMessangerIcon = (chanel) => {
    switch (chanel) {
        case "viber":
            return <ViberIcon fontSize="small" width={24} height={24} />            
        case "telegram":
            return <TelegramIcon fontSize="small" width={24} height={24} />
        case "phone":
        case "телефон":            
            return <PhoneIcon />
        case "email":
        case "e-mail":
            return <MailOutlineIcon />
        default:
            return null;
    }
}

const getMessangerHref = (chanel, phone, email) => {
    switch (chanel) {
        case "viber":
            return `viber://chat?number=%2B${validPhone(phone)}`
        case "telegram":
            return `telegram://chat?number=${validPhone(phone)}`
        case "phone":
        case "телефон":            
            return `tel:${validPhone(phone)}`
        case "email":
        case "e-mail":
            return `mailto:${validPhone(email)}`
        default:
            return "#";
    }
}

function getChildren (parent, nestingLevel, numperiod, year, token) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        const URL = `/api/v1.0/reports/getreportbaseindicatorsdetails/${parent.id}/${numperiod}/${year}/${0}`;
        console.log(URL);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {
                    if(data && data.Data){                        
                        // console.log("getChildren", data.Data);
                        const childs = [];
                        const consultants = prepareNodes(data.Data);
                        for (let i = 0; i < consultants.length; i++) {
                            const el = consultants[i];                            
                            const child = createNode(el);
                            getNodeData(child, nestingLevel + 1);
                            childs.push(child); 
                        }
                        parent.children = childs;
                    }                    
                    resolve(parent);
            })
            .finally(() => {
                
            });


        // for (let i = 0; i < 5; i++) {                                            
        //     const child = createNode(
        //         {
        //             id: `e43eb87b-7f53-4ef8-97a2-c8721903acd${i}`,  
        //             name: `Child #${i} of ${parent.name}`, 
        //             lo: parent.lo, 
        //             go: parent.go, 
        //             status: "", 
        //             statusactive: "",
        //             bd: false,
        //             isLeaf: true, 
        //             children: []
        //         });
            
        //     getNodeData(child, nestingLevel + 1)
        //     childs.push(child);                                            
        // }
        
    });
}

const getNodeData = ( node, nestingLevel) => (
    {
        data: {
            // download,
            id: node.id,
            isLeaf: node.isLeaf,
            isOpenByDefault: false,
            name: node.name,
            lo: node.lo,
            go: node.go,
            cacheback: node.cacheback,
            percentCacheBack: node.percentCacheBack, 
            amountNextStepCacheBack: node.amountNextStepCacheBack,
            bonusregister: node.bonusregister,            
            status: node.status,
            statusactive: node.statusactive,
            bd: node.bd,
            isbeginner: node.isbeginner,
            phone: node.phone,
            nestingLevel
        },
        nestingLevel,
        node,
    }
);

const BaseIndicators = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    

    const auth = useContext(AppContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // eslint-disable-next-line no-undef
    const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";

    const [loading, setLoading] = useState(true);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [owner, setOwner] = useState(null);
    const [adviser, setAdviser] = useState(null);
    const [token, setToken] = useState(null);
    const [headerData, setHeaderData] = useState(null);
    // const [customerInfo, setCustomerInfo] = useState(null);
    
    const [salary, setSalary] = useState(0);

    const [periods, setPeriods] = useState([]);
    const [period, setPeriod] = useState("");
    const [selectedperiod, setSelectedperiod] = useState(null);

    const [leftDay, setleftDay] = useState(0);

    const [showDlgInfo, setShowDlgInfo] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
            
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [suggestion, setSuggestion] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [testURL, setTestURL] = useState("viber://chat?number=%2B380XXXXXXX");
    
    const [infoConsultant, setInfoConsultant] = useState(null);
    const [detailsStructure, setDetailsStructure] = useState([]);
    const [detailsVIPs, setDetailsVIPs] = useState([]);
    const [detailsGrandManagers, setDetailsGrandManagers] = useState([]);

    const [detailsShowStructure, setShowDetailsStructure] = useState(false);
    const [detailsShowVIPs, setShowDetailsVIPs] = useState(false);
    const [detailsShowFirstLineStructure, setShowFirstLineStructure] = useState(false);


    const [detailsShowGrandManagers, setDetailsShowGrandManagers] = useState(false);
    const [loadingDetailsGrandManagers, setLoadingDetailsGrandManagers] = useState(false);

    const howLongWithUsStr = (days) => {
        if(isNaN(days))
            return "∞";

        let dd = days;
        if(days < 0)
            dd = (-1) * days;
        
        const leftDays = parseInt(dd % 365);
        const years = parseInt(dd / 365);
        return `${years} р ${ leftDays} дн `;
        
    };

    const showInfo = async (e, id) => {
        e.preventDefault();                
        if(id){
            setShowDlgInfo(true);
            // setHeaderData
            if(infoConsultant && infoConsultant.Customer.Id === id.toUpperCase()){
                return;
            }


            setLoadingInfo(true);
            setInfoConsultant(null);
            if(selectedperiod){
                await loadReportHeaders(id, selectedperiod.Period, selectedperiod.Year);
            }else{
                await loadReportHeaders(id);
            }
        }        
    };

    const handleChange = (event) => {
        setPeriod(event.target.value);
        
        // console.log("period", event.target.value);

        let selperiod = undefined;
                
        if(period && periods ){            
            selperiod = periods.find((p) => {
                if(p.Id === event.target.value)
                    return p;
                return null;
            });            
        }
        if(selperiod){
            setSelectedperiod(selperiod);
            loadReportData(adviser.Id, selperiod.Period, selperiod.Year);
        }
    };

    const handleToggleSearchBox = () => {
        setShowSearchBox((showSearchBox) => !showSearchBox);
    };       

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, {
        keys: ["Name"],
    });

    const handelChangeSelectedAdvisers = (e, val) => {
        setSuggestion(val);        
    };
  
    const handelChangeSearchAdvisers = (e, val) => {
        if (val === "") {
            setSuggestion(null);                
          return false;
        }
        if(val.length < 3){
          return false;
        }
        //console.log("Search advisers by value", val);
        searchAdvisers(val);
    };

    const searchAdvisers = (str) => {
        const URL = `/api/v1.0/Adviser/SearchAdvisersInTree/${str}`;
        // console.log(URL);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {                    
                    setSuggestions(data.Data);                             
                });
    };

    const handleOwnerLoadData = () => {
        if(!owner)
            return;
        if(!selectedperiod)
            return;
            
        setAdviser(owner);

        if(owner.Id === adviser.Id){
            console.log("Stop recive data");
            return;
        }
        resetDataReport();
        loadReportData(owner.Id, selectedperiod.Period, selectedperiod.Year);
    };

    const handleLinkOwnerLoadData = (id, e) => {
        e.preventDefault();          
        if(owner.Id === adviser.Id){
            console.log("Stop recive data");
            return;
        }

        resetDataReport();
        reloadDataByConsultant(id, selectedperiod.Period, selectedperiod.Year);
        // handleOwnerLoadData();
    };

    const resetDataReport = () => {
        setShowDetailsStructure(false);
        setShowDetailsVIPs(false);
        setShowFirstLineStructure(false);
        setDetailsShowGrandManagers(false);

        setDetailsStructure([]);
        setDetailsVIPs([]);
        setDetailsGrandManagers([]);
    };

    const reloadDataByConsultant = (id) => {
        resetDataReport();
        console.log("id", id);
        const URL = `/api/v1.0/adviser/getadviserbyid?id=${id}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);                
        
        fetch(URL, { method: "GET", headers: headers })
            .then(response => response.json())
                .then(data => {
                    setAdviser(data);
                    loadReportData(data.Id, selectedperiod.Period, selectedperiod.Year);
                });

    };

    const handleChildLoadData = () => {
        if(!suggestion)
            return;

        console.log("suggestion", suggestion);
        reloadDataByConsultant(suggestion.Id);                
    }

    const handleClickByDetails = (clientId, viewType, section) => {
        if(!selectedperiod){            
            return;
        }

        let allowloading = false;
        switch (section) {
            case "detailsStructure":
                allowloading = !detailsShowStructure;
                setShowDetailsStructure(allowloading);
                break;
            case "detailsFirstLine":
                allowloading = !detailsShowFirstLineStructure;
                setShowFirstLineStructure(allowloading);
                break;
            case "detailsVIPs":
                    allowloading = !detailsShowVIPs;
                    setShowDetailsVIPs(allowloading);
                    break;
            default:                
                break;
        }

        if(allowloading)
            loadDetails(clientId, selectedperiod.Period, selectedperiod.Year, viewType, section);
    };
    
    const handleClickByDetailsGrandManagers = () => {
        if(detailsShowGrandManagers){
            setDetailsShowGrandManagers(false);
            return;
        }

        if(!selectedperiod){            
            return;
        }
        loadDetailsGrandManagers(adviser.Id, selectedperiod.Period, selectedperiod.Year);
    };

    const loadDetailsGrandManagers = async (clientId, numperiod, year) => {      
        setDetailsShowGrandManagers(false);        
        setLoadingDetailsGrandManagers(true);
        setDetailsGrandManagers([]);

        const URL = `/api/v1.0/reports/GetReportBaseindicatorsGrandManagersDetails/${clientId}/${numperiod}/${year}`;

        console.log("loadDetailsGrandManagers", URL);
        
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {
                    if(data && data.Data){
                        console.info("Details", data.Data);
                        setDetailsShowGrandManagers(true);
                        const prepared = prepareNodes(data.Data);
                        console.log('DetailsGrandManagers', prepared);
                        setDetailsGrandManagers(prepared);
                    }                
            })
            .finally(() => {
                setDetailsShowGrandManagers(true);
                setLoadingDetailsGrandManagers(false);
                // setLoadingDetails(false);
            });
    };

    const loadDetails = async (clientId, numperiod, year, viewType, section) => {
        switch (section) {
            case "detailsStructure":
            case "detailsFirstLine":
                if (detailsStructure.length > 0)
                    return;
                break;                                        
            case "detailsVIPs":
                if(detailsVIPs.length > 0)
                    return;
                break;
            default:                                
                return;
        }

        setLoadingDetails(true);
        const URL = `/api/v1.0/reports/getreportbaseindicatorsdetails/${clientId}/${numperiod}/${year}/${viewType}`;
        console.log("loadDetails", URL);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {
                    if(data && data.Data){
                        console.info("Details", data.Data);
                        switch (section) {
                            case "detailsStructure":
                            case "detailsFirstLine":
                                setDetailsStructure(prepareNodes(data.Data));
                                break;                                                            
                            case "detailsVIPs":                                 
                                setDetailsVIPs(prepareNodes(data.Data));                                
                                break;
                            default:                                
                                break;
                        }
                    }                
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    };

    const loadPeriods = async () => {        
        const URL = '/api/v1.0/reports/getperiods/';        
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(periods => {
                    
                setPeriods(periods);                
                if(period === ""){
                    setPeriod(periods[0].Id);
                    setSelectedperiod(periods[0]);
                }
                const planedFinishDate = moment(periods[0].PlanedFinishDate, "YYYY-MM-DD");
                const current = moment().startOf('day');                
                const _leftDays = moment.duration(planedFinishDate.diff(current)).asDays();                                
                setleftDay(Math.round(_leftDays));
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const loadReportHeaders = async (clientId, numperiod, year) => {                
        const URL = `/api/v1.0/reports/getreportbaseindicatorsheaders/${clientId}` + (numperiod && year ? `/${numperiod}/${year}` : "");
        // console.log("loadReportData", URL);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        const result = await fetch(URL, { method: "GET", headers: headers });
        const data = await result.json();

        console.log('loadReportHeaders', data);
        setInfoConsultant(data);
        setLoadingInfo(false);
    };

    const loadReportData = async (clientId, numperiod, year) => {
        // setLoading(true);
        const URL = `/api/v1.0/reports/getreportbaseindicatorsheaders/${clientId}` + (numperiod && year ? `/${numperiod}/${year}` : "");
        // console.log("loadReportData", URL);
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);
        
        fetch(URL, { method: "GET", headers: headers })      
            .then(response => response.json())
                .then(data => {                
                    console.log("loadReportData", data);
                    setHeaderData(data.Headers.Data);
                    setInfoConsultant(data);

                    let _salary = 0.00;
                    if(data.Headers.Data){
                        if(data.Headers.Data["СтруктурныйБонус"])
                            _salary += data.Headers.Data["СтруктурныйБонус"];
                        
                        if(data.Headers.Data["ДиректорскийБонус"])
                            _salary += data.Headers.Data["ДиректорскийБонус"]; 
                    }
                    setSalary(_salary.toFixed(2));
                    // setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };
   
    const fetchData = useCallback( async () => {
        if(!token ) return;
        if(!adviser ) return;
                    
        // console.log('token', token);
        await loadPeriods();
        await loadReportData(adviser.Id);

    // eslint-disable-next-line
    }, [token]);

    const TableHeader = () => {
        return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} style={{marginBottom: 8, borderBottom: "1px solid #ccc"}}>                                        
            <Grid item xs={7}></Grid>
            <Grid item xs={2}><Typography display="block" align="right" style={{fontSize: ".8em"}}>{t("reports.networkstructure.ОО")}</Typography></Grid>
            <Grid item xs={2}><Typography display="block" align="right" style={{fontSize: ".8em"}}>{t("reports.networkstructure.ГО")}</Typography></Grid>
            <Grid item xs={1}></Grid>                                        
        </Grid>);
    };

    const CellNLO = ({data}) => {
        return data.nlo !== 0 ? 
            <Typography display="block" align="right" className={classes.username} style={{color: "orange"}} component="div">{data.nlo}</Typography> : 
            ( data.lo !== 0 ? <Typography display="block" align="right" className={classes.username} component="div">{data.lo}</Typography> : " " )       
    };

    const RowBuilder = ({row, numperiod, year}) => {
        return(
            row ?
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={1}>
                    {/* {<Circle bgColor={getColor(row.statusactive)} title={row.status}  status={getStatus(row.status)} />} */}
                    <StyledBadgeStatus
                                 overlap='circular'
                                 badgeContent={ row.isbeginner ? <small style={{ color: getColor(row.statusactive) }}>н</small> : null}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                             }}>
                                 <Avatar className={classes.small} style={{ backgroundColor: getColor(row.statusactive)}}>
                                     {getStatus(row.status)}
                                 </Avatar>        
                        </StyledBadgeStatus>
                </Grid>
                <Grid item xs={6}> 
                { row.bd ? 
                     <StyledBadge title={row.name}
                        overlap='circular'
                        badgeContent={ <small className={classes.bd}>др</small>} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                      }}>
                        <Typography noWrap className={classes.username} component="div" display="block">
                            {/* {row.name.toLowerCase()} */}
                            <Link color="inherit" onClick={()=> reloadDataByConsultant(row.id)}>{row.name.toLowerCase()}</Link>
                        </Typography>
                    </StyledBadge> : 
                    <Typography title={row.name} noWrap className={classes.username} component="div" display="block" >                        
                        <Link color="inherit" onClick={()=> reloadDataByConsultant(row.id)}>{row.name.toLowerCase()}</Link>                        
                    </Typography>
                }
                </Grid>
                <Grid item xs={2}>
                    <CellNLO data={row} /> 
                </Grid>
                <Grid item xs={2}>
                    <Typography display="block" align="right" className={classes.username} component="div" >{row.go !== 0 ? row.go : null}</Typography> 
                </Grid>
                <Grid item xs={1} > 
                    <Typography noWrap display="block" align="right">                
                        <IconButton className={classes.smallinfo} aria-label="info" onClick={(e) => showInfo(e, row.id)}> 
                            <InfoOutlinedIcon style={{fontSize: "18px", marginRight: "2px"}}/>
                        </IconButton>
                        {/* <IconButton className={classes.smallinfo} aria-label="info" href={`tel:${validPhone(row.phone)}`} > 
                        <PhoneIcon style={{fontSize: "18px"}}/>
                        </IconButton> */}
                    </Typography>
                </Grid>
            </Grid> : null   
        );
    };
    
    const RowBuilderVip = ({row}) => {
        return(
            row ?
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={1}>
                    {/* {<Circle bgColor={getColor(row.statusactive)} title={row.status}  status={getStatus(row.status)} />} */}
                        <StyledBadgeStatus
                                 overlap='circular'
                                 badgeContent={ row.isbeginner ? <small style={{ color: getColor(row.statusactive) }}>н</small> : null}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                             }}>
                                 <Avatar className={classes.small} style={{ backgroundColor: getColor(row.statusactive)}}>
                                     {getStatus(row.status)}
                                 </Avatar>        
                        </StyledBadgeStatus>
                </Grid>
                <Grid item xs={4}>
                { row.bd ? 
                     <StyledBadge title={row.name}
                        overlap='circular'
                        badgeContent={ <small className={classes.bd}>др</small>} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                      }}>
                        <Typography noWrap className={classes.username} component="div" display="block">                            
                            <Link color="inherit" onClick={()=> reloadDataByConsultant(row.id)}>{row.name.toLowerCase()}</Link>
                        </Typography>
                    </StyledBadge>                     
                    : 
                    <Typography title={row.name} noWrap className={classes.username} component="div" display="block" >                        
                        <Link color="inherit" onClick={()=> reloadDataByConsultant(row.id)}>{row.name.toLowerCase()}</Link>                        
                    </Typography>
                }
                </Grid>
                <Grid item xs={4}>
                    {/* <Typography display="block" align="right" className={classes.username} component="div">{` ${123.99} / ${123.88}`}</Typography> */}
                    { row.bonusregister === 0 && row.cacheback === 0 ? null :
                        <Typography display="block" align="right" component="div" variant="body2" style={{ marginTop: 6}}>                        
                            {row.bonusregister}/{row.cacheback}                                                 
                        </Typography>
                    
                    // <Typography display="block" align="right" className={classes.username} component="div">{`${row.bonusregister}/${row.cacheback}`}</Typography> : null
                    }
                </Grid>
                <Grid item xs={2}>
                    <CellNLO data={row} /> 
                </Grid>                
                <Grid item xs={1} > 
                    <Typography noWrap display="block" align="right"> 
                    <IconButton className={classes.smallinfo} aria-label="info" onClick={(e) => showInfo(e, row.id)}> 
                        <InfoOutlinedIcon style={{fontSize: "18px", marginRight: "2px"}}/>
                    </IconButton>
                    {/* <IconButton className={classes.smallinfo} aria-label="info" href={`tel:${validPhone(row.phone)}`} > 
                        <PhoneIcon style={{fontSize: "18px"}}/>
                    </IconButton> */}
                    </Typography>
                </Grid>
            </Grid> : null   
        );
    };
    
    const ListPresenter = ({ data, report }) => {
        const rowRenderer = ({index, key, style}) => {            
            const row =  data[index];
            return (
              <div key={key} style={style}>   
                {report === "vip" ?      
                    <RowBuilderVip row={row} /> : <RowBuilder row={row} />
                }
              </div>
            );
        };
    
        return (
            <AutoSizer>
                {({width, height}) => (                                    
                    <List
                        className={classes.list}
                        height={height}
                        rowCount={data.length}
                        rowHeight={30}
                        rowRenderer={rowRenderer}
                        width={width}
                    />                  
                )}
            </AutoSizer>
        );
    };

    const Node = (props) => {
        const { data, isOpen, style, toggle } = props;
        const { isLeaf, id, nestingLevel, node, numperiod, year  } = data;
        // const [isLoading, setLoading] = useState(false);        
        const classes = useStyles();
                    
        const handelBtnNode = async (node, nestingLevel, numperiod, year) => {        
            if(!isOpen){
                if(node.children.length === 0){
                    await getChildren(node, nestingLevel, numperiod, year, token);                    
                }                                
            }                        
            toggle();
        };
        return (
            (!id || id === emptyGuid) ? null : 
            <div className={classes.item} style={{ ...style, backgroundColor: ( isOpen ? "#c8f9cb57" : "") }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item xs={1}>
                        {!isLeaf ?
                            <IconButton aria-label="toggle" size="small" onClick={() => handelBtnNode(node, nestingLevel, numperiod, year)}>
                                {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                            </IconButton> : 
                            <span style={{ width: "28px", display: "block"}}></span>
                        }      
                    </Grid>
                    <Grid item xs={1}>                        
                        <StyledBadgeStatus
                                overlap='circular' 
                                badgeContent={ node.isbeginner ? <small style={{ color: getColor(node.statusactive) }}>н</small> : null}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                            }}>
                                <Avatar className={classes.small} style={{ backgroundColor: getColor(node.statusactive)}}>
                                    {getStatus(node.status)}
                                </Avatar>        
                            </StyledBadgeStatus>
                        
                        {/* {<Circle bgColor={getColor(node.statusactive)} title={node.status}  status={getStatus(node.status)} />} */}
                    </Grid>
                    <Grid item xs={5}> 
                        { node.bd ? 
                            <StyledBadge className={classes.badge}
                                overlap='circular' 
                                badgeContent={ <small className={classes.bd}>др</small>} 
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                            }}>
                                <Typography noWrap className={classes.username} component="div" display="block">
                                <small>{nestingLevel + 1}</small> <Link color="inherit" onClick={()=> reloadDataByConsultant(id)}>{node.name.toLowerCase()}</Link>
                                </Typography>
                            </StyledBadge> :
                            <Typography title={node.name} noWrap className={classes.username} component="div" display="block" >
                                <small>{nestingLevel + 1}</small> <Link color="inherit" onClick={()=> reloadDataByConsultant(id)}>{node.name.toLowerCase()}</Link> 
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <CellNLO data={node} /> 
                    </Grid>
                    <Grid item xs={2}>
                        {node.go !== 0 ? <Typography display="block" align="right" className={classes.username} component="div" >{node.go}</Typography> : null }
                    </Grid>
                    <Grid item xs={1}>
                    <Typography noWrap display="block" align="right">                
                        <IconButton className={classes.smallinfo} aria-label="info" onClick={(e) => showInfo(e, node.id)} > 
                            <InfoOutlinedIcon style={{fontSize: "18px", marginRight: "2px"}}/>
                        </IconButton>
                        {/* <IconButton className={classes.smallinfo} aria-label="call" href={`tel:${validPhone(node.phone)}`} > 
                            <PhoneIcon style={{fontSize: "18px"}}/>
                        </IconButton> */}
                        </Typography>
                    </Grid>                    
                </Grid>
            </div>
        );
    };

    // Build tree
    const TreePresenter = ({ itemSize, data, numperiod, year, viewType }) => {
        const tree = useRef(null);        
    
        const rootNode = useMemo(() => {
            const root = createNode(
                {
                    id: emptyGuid,  
                    name: "Root", 
                    lo: 0, 
                    go: 0, 
                    status: "", 
                    statusactive: "",
                    phone: "",
                    bd: false,
                    isbeginner: false,
                    isLeaf: false, 
                    children: []
                });
            if(data.length > 0){
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    const child = createNode(element);
                    root.children.push(child);
                }
            }
            return root;
        }, [data]);
        
        const treeWalker = useCallback(
          function* treeWalker(refresh) {        
            const stack = [];
      
            // multiple tree root
            for (let i = rootNode.children.length-1; i >= 0; i--) {
            // for (let i = 0; i < rootNode.children.length; i++) {
                // const data = getNodeData( rootNode.children[i], 0, createDownloader(rootNode.children[i]) );             
                const data = getNodeData(rootNode.children[i], 0); 
                stack.push(data);
            }
            
            // пушим один и тот же узел rootNode
            // так, а если у нас 
            while (stack.length !== 0) {
              // пока в стеке что то есть
              const { node, nestingLevel } = stack.pop();
              // выкидываем последний элемент из стека
              const id = node.id;
              const {length} = node.children;
              const lastIndex = length - 1;
              
              // const isFolder = !data.isFolder;
              const isLeaf = node.isLeaf; //length === 0;
              const isNested = !isLeaf;
              
              const isOpened = yield refresh
                ? {
                    node,
                    defaultHeight: itemSize,
                    id,
                    isLeaf,
                    isOpenByDefault: false,                
                    nestingLevel,                
                    numperiod,
                    year                    
                  }
                : id;
    
              // какая-то магия для открытия и закрытия узлов
              if (isNested && isOpened) {
              // если это вложенный узел и он открыт
                for (let i = lastIndex; i >= 0; i--) {
                    // записываем в стек детей
                    // const child = getNodeData(node.children[i], (nestingLevel + 1), createDownloader(node.children[i])); 
                    const child = getNodeData(node.children[i], (nestingLevel + 1)); 
                    stack.push(child);            
                }
              }          
            }            
    
          },
          [itemSize, numperiod, year, rootNode.children]
        );
        
        return (
          <AutoSizer disableWidth>
            {({ height }) => (
              <Tree style={{ overflowX: "hidden" }}
                ref={tree}            
                // itemSize={itemSize}
                treeWalker={treeWalker}
                height={height}
                width={"100%"}
                async={true}
              >
                {Node}
              </Tree>
            )}
          </AutoSizer>
        );
    };

    const getColumnName = (str) => {        
        return  t("reports.baseindicators.ColumnPeriod", {period: str.substring(8, 10)}) ;
    }
  
    useEffect(() => {
        function fetchUser() {
            auth.getUser().then(user => {
                let token = null;
                if (user != null) {
                    token =  `Bearer ${user.access_token}`;
                }
                
                const URL = "/api/v1.0/adviser/getadviserbycontext";
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Accept", "application/json");
                headers.append("Authorization", token);
                fetch(URL, { method: "GET", headers: headers })
                    .then(response => response.json())
                        .then(data => {
                            setAdviser(data);
                            setOwner(data);
                            setToken(token);
                        });
            });
        }
        fetchUser();
        fetchData();
    }, [auth, fetchData]);

    return (
      <AppConsumer>
        {() => (
          <div className={classes.root}>
            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="stretch">                
                {/* row 1 */}
                <Grid item xs={12}>
                    <Paper style={{padding: 8}}>                        
                        { leftDay !== 0 &&                         
                            <Typography display="block" variant="caption" align={fullScreen ? "left" : "right"}>
                                {leftDay === 1 ? 
                                    <b style={{ color: "red"}}>Останній день періоду!</b> : <>Днів до закінчення періоду: <b>{`${leftDay}`}</b></>} 
                            </Typography>
                        }

                        { adviser && 
                        <>
                        <Grid container direction="row" spacing={0}>
                            <Grid item >
                            { owner && 
                                <div className={classes.btns}>
                                    <IconButton size="small"
                                        aria-label="home"
                                        onClick={handleOwnerLoadData}
                                        title={`GO TO: ${owner.FirstName} ${owner.LastName}`}>
                                        <HomeOutlinedIcon />
                                    </IconButton>
                                </div>
                                }
                            </Grid>
                            <Grid item>
                                <div className={classes.btns}>
                                    {adviser && 
                                    <IconButton size="small" style={{color: infoConsultant ? getColor(infoConsultant.Headers.Data["СтатусАктивности"]): null }}
                                        aria-label="info" 
                                        onClick={(e)=>showInfo(e, adviser.Id)}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={8} sm={10}>
                                <Typography display="block" variant="h6" > 
                                   <span className={classes.capitalize}>
                                    {`${adviser.FirstName.toLowerCase()} ${adviser.LastName.toLowerCase()}`}
                                    </span> <small>{headerData ? <Typography component="span" noWrap >{`${ headerData["ОВГрн"] ? headerData["ОВГрн"] : '-' }`} <small>{headerData["Валюта"]}</small></Typography>  : null}</small>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" spacing={0}>
                            <Grid item >
                                <div className={classes.btns}>
                                    <span style={{width: 30, display:"block"}}></span>
                                </div>
                            </Grid>
                            <Grid item >
                                {adviser && adviser.Mentor &&
                                    <div className={classes.btns}>
                                        <IconButton size="small"
                                            aria-label="info" 
                                            onClick={(e)=>showInfo(e, adviser.Mentor.Id)} style={{paddingTop: 4}}>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={8} sm={10}>
                                { adviser.Mentor && 
                                    <Typography component="div" display="block" className={clsx(classes.capitalize)} style={{marginTop: 4}}
                                            variant="body1" color="textSecondary" >
                                        <Link href="#" onClick={ (e) => handleLinkOwnerLoadData(adviser.Mentor.Id, e)} color="inherit"> 
                                        { `${adviser.Mentor.FirstName.toLowerCase()} ${adviser.Mentor.LastName.toLowerCase()}` }
                                        </Link>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>                        
                        </>
                        }

                        { adviser && 
                            <Grid container direction="row" style={{marginTop: 8}} spacing={1} justifyContent={fullScreen ? "flex-start": "flex-start" } alignItems="center">
                                {/* <Grid item>
                                    <Button color="primary" variant="contained" aria-label="search" onClick={handleToggleSearchBox} ><SearchIcon /></Button>
                                </Grid> */}
                                <Grid item xs={4} md={1}>
                                    <Button style={{marginTop: 7}} size="large" fullWidth href={`tel:${validPhone(adviser.Phone)}`} variant="contained" aria-label="phone"><PhoneIcon /></Button>
                                </Grid>
                                <Grid item xs={4} md={1}>
                                    <Button style={{marginTop: 7}} size="large" fullWidth href={`viber://chat?number=%2B${validPhone(adviser.Phone)}`} variant="contained" aria-label="viber"><ViberIcon fontSize="small" width={24} height={24} /></Button>
                                </Grid>
                                <Grid item xs={4} md={1}>
                                    <Button style={{marginTop: 7}} size="large" fullWidth href={`telegram://chat?number=${validPhone(adviser.Phone)}`} variant="contained" aria-label="telegram" ><TelegramIcon fontSize="small" width={24} height={24} /></Button>
                                </Grid>
                                <Grid item xs={9} md={5}>
                                    <Autocomplete className={classes.autocomplete}
                                        id={"search"}
                                        fullWidth
                                        getOptionLabel={ (option) => option.Name }
                                        getOptionSelected={(option, value) => option.Name === value.Name}
                                        options={suggestions}
                                        value={suggestion}                                    
                                        autoComplete
                                        includeInputInList                                            
                                        filterOptions={filterOptions} 
                                        filterSelectedOptions
                                        onInputChange={(event, newInputValue) => {
                                            handelChangeSearchAdvisers(event, newInputValue);
                                        }}
                                        onChange={(event, newValue) => {
                                            handelChangeSelectedAdvisers(event, newValue);                      
                                        }}
                                        renderInput={(params) => 
                                            <TextField 
                                                    {...params} 
                                                    label={`${t("common.Search")}`} 
                                                    variant="outlined" 
                                                    size="small"
                                            />
                                        }
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.Name, inputValue.toUpperCase());
                                            const parts = parse(option.Name, matches);                                          
                                            return (                                                
                                                <Typography display="block">
                                                    {parts.map((part, index) =>
                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                            {part.text}
                                                        </span>                                                    
                                                    )}
                                                    <Typography variant="caption" display="block">
                                                        {option.RegistrationCode} { t(`reports.baseindicators.${option.ClientView}`)}
                                                    </Typography>
                                                </Typography>                                                
                                            );
                                    }}
                                    /> 
                                </Grid>
                                <Grid item xs={3} md={1}>
                                    <Button 
                                        size="large"
                                        color="primary" 
                                        disabled={!suggestion} 
                                        fullWidth 
                                        variant="contained" 
                                        aria-label="get-data" style={{marginTop: 7}}
                                        onClick={handleChildLoadData}>
                                            <CheckOutlinedIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        }                                                                        
                    </Paper>
                </Grid>
                
                {/* Summary */}
                <Grid item xs={12} md={4}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>                    
                    {headerData && <div className={classes.reportdetails}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}><Typography display="block" variant="caption" color="textSecondary" gutterBottom noWrap>&nbsp;</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["Период"])}</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["ПериодПрошлый"])}</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            {/* Row #1 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Групповой Объем">{t("reports.baseindicators.ГрупповойОбъем")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ГрупповойОбъем"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ГрупповойОбъемПрошлый"]}
                            </Grid>
                            {/* Row #2 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Групповой Объем">{t("reports.baseindicators.ГОНеоплаченныхЗаказов")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ГОНеоплаченныхЗаказов"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>                                
                            </Grid>                            
                            {/* Row #3 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="ЛичныйОбъем">{t("reports.baseindicators.ЛичныйОбъем")}:</Typography>                                
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ЛичныйОбъемТекущий"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ЛичныйОбъемПрошлый"]}
                            </Grid>
                            {/* Row #4 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="FastUp бонус">{t("reports.baseindicators.FUБонус")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["FUБонус"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["FUБонусПрошлый"]}
                            </Grid>
                            {/* Row #5 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Структурный Бонус">{t("reports.baseindicators.СтруктурныйБонус")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>    
                                {headerData["СтруктурныйБонус"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктурныйБонусПрошлый"]}
                            </Grid>
                            {/* Row #6 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Директорский Бонус">{t("reports.baseindicators.ДиректорскийБонус")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ДиректорскийБонус"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ДиректорскийБонусПрошлый"]}
                            </Grid>

                            {/* Row #7 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Директорский Бонус">
                                    {t("reports.baseindicators.ОВ")} :
                                    {/* ( <span title="Currency exchange"> <span className="material-icons" style={{fontSize: "14px"}}>currency_exchange</span> {`${parseFloat(headerData["КурсВыплатОВ"]).toFixed(2)}`} </span> ) */}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ОВ"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ОВПрошлый"]}
                            </Grid>

                            {/* Row #8 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap >{t("Currency exchange")} {t("reports.baseindicators.ОВ")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {parseFloat(headerData["КурсВыплатОВ"]).toFixed(2)}
                            </Grid>
                            <Grid item xs={4}>
                                &nbsp;
                            </Grid>
                            

                            {/* Row #8 */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="Директорский Бонус">
                                    {t("reports.baseindicators.ОВГрн")}, <sub>{headerData["Валюта"]}</sub>:
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ОВГрн"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ОВПрошлыйГрн"]}
                            </Grid>


                            {/* Row #last */}
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap>{t("reports.baseindicators.Статус")}:</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" align="right" display="block" >{headerData["Статус"]}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" align="right" display="block">{headerData["СтатусПрошлый"]}</Typography>
                            </Grid>
                        </Grid>
                    </div>}
                    {loading && 
                        <Grid container direction="row" spacing={1} justifyContent="flex-end">
                            <Grid item>
                                <CircularProgress size={25} />                        
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth disabled={loading} size="small" color="primary" >{t("reports.baseindicators.Details")}</Button>
                            </Grid>
                    </Grid>
                     }
                  </Paper>
                </Grid>
                                
                {/* Structure */}
                <Grid item xs={12} md={4}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>
                    {headerData && 
                        <div className={classes.reportdetails}> 
                        
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}><Typography display="block" variant="caption" color="textSecondary" gutterBottom noWrap>Структура</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["Период"])}</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["ПериодПрошлый"])}</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="СтруктураКоличество">{t("reports.baseindicators.СтруктураКоличество")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураКоличество"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураКоличествоПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="СтруктураНовички">{t("reports.baseindicators.СтруктураНовички")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураНовички"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураНовичкиПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="СтруктураАктивные">{t("reports.baseindicators.СтруктураАктивные")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураАктивные"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураАктивныеПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="СтруктураРекрутеры">{t("reports.baseindicators.СтруктураРекрутеры")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураРекрутеры"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["СтруктураРекрутерыПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="ТерминированыеКоличество">{t("reports.baseindicators.ТерминированыеКоличество")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ТерминированыеКоличество"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["ТерминированыеКоличествоПрошлый"]}
                            </Grid>
                        </Grid>
                        </div>
                    }
                    <Grid container direction="row" spacing={1} justifyContent="flex-end">
                        <Grid item>
                            {loading && 
                                <CircularProgress size={25} />
                            }
                        </Grid>
                        <Grid item xs={4}>                                
                            <Button fullWidth disabled={loading} size="small" color="primary" onClick={()=> handleClickByDetails(adviser.Id, 0, "detailsStructure")} >{t("reports.baseindicators.Details")}</Button>                                
                        </Grid>
                    </Grid>
                        
                        {/* { showLoadingDetails() } */}
                        {detailsShowStructure && detailsStructure.length > 0 && 
                        <Box overflow="auto" height="66vh" flexDirection="column" display="flex" >

                            <TableHeader />
                            
                            <Box overflow="auto" flex={1} flexDirection="column" display="flex">
                                {loadingDetails && 
                                    <Grid container direction="row" justifyContent="center" alignItems="center"  style={{ height: 178}}> 
                                        <Grid item className={classes.progress}>                                
                                            <CircularProgress />                            
                                        </Grid>
                                    </Grid>
                                }
                                                               
                                <TreePresenter itemSize={30} data={detailsStructure} token={token} numperiod={selectedperiod.Period} year={selectedperiod.Year} viewType={0} />                               
                            </Box>
                        </Box>
                        }                                        
                    </Paper>
                </Grid> 

                {/* VIP */}
                {headerData && (headerData["КоличествоVIP"] !== 0 || headerData["КоличествоVIPПрошлый"] !== 0 || headerData["НовичкиVIP"] !== 0 || headerData["НовичкиVIPПрошлый"] !== 0 || headerData["АктивныеVIP"] !== 0  || headerData["АктивныеVIPПрошлый"] !== 0) && 
                <Grid item xs={12} md={4}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>
                    <div className={classes.reportdetails}> 
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}><Typography display="block" variant="caption" color="textSecondary" gutterBottom noWrap>{t("reports.baseindicators.VIPклиенты")}</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["Период"])}</Typography></Grid>
                            <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["ПериодПрошлый"])}</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="КоличествоVIP">{t("reports.baseindicators.КоличествоVIP")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["КоличествоVIP"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["КоличествоVIPПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="НовичкиVIP">{t("reports.baseindicators.НовичкиVIP")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["НовичкиVIP"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["НовичкиVIPПрошлый"]}
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="body2" display="block" noWrap title="АктивныеVIP">{t("reports.baseindicators.АктивныеVIP")}:</Typography>
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["АктивныеVIP"]}
                            </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>
                                {headerData["АктивныеVIPПрошлый"]}
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container direction="row" spacing={1} justifyContent="flex-end">
                        <Grid item>
                            {loading && 
                                <CircularProgress size={25} />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Button fullWidth disabled={loading} size="small" color="primary" onClick={()=> handleClickByDetails(adviser.Id, 1, "detailsVIPs")} >{t("reports.baseindicators.Details")}</Button>
                        </Grid>
                    </Grid>
                        {detailsShowVIPs && 
                        <Box overflow="auto" height="66vh" flexDirection="column" display="flex" >
                            {selectedperiod && 
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} style={{marginBottom: 8, borderBottom: "1px solid #ccc"}}>                                                                            
                                    <Grid item xs={9}><Typography display="block" noWrap align="right" style={{fontSize: ".8em"}}>Welcome bonus / Cashback</Typography></Grid>
                                    <Grid item xs={2}><Typography display="block" align="right" style={{fontSize: ".8em"}}>{t("reports.networkstructure.ОО")}</Typography></Grid>
                                    <Grid item xs={1}></Grid>                                        
                                </Grid>
                            }

                            <Box overflow="auto" flex={1} flexDirection="column" display="flex" style={{padding: "4px"}}>
                                {loadingDetails && 
                                    <Grid container direction="row" justifyContent="center" alignItems="center"  style={{ height: 178}}> 
                                        <Grid item className={classes.progress}>                                
                                            <CircularProgress />                            
                                        </Grid>
                                    </Grid>
                                }
                                {selectedperiod &&                                                                        
                                    <ListPresenter data={detailsVIPs} report="vip" />                                                                        
                                }
                            </Box>
                        </Box>
                        }                                        
                    </Paper>
                </Grid>
                }

                {/* First line */}
                {headerData && (
                    headerData["КоличествоBusiness"] !== 0 || headerData["КоличествоBusinessПрошлый"] !== 0  ||
                    headerData["НовичкиBusiness"] !== 0 || headerData["НовичкиBusinessПрошлый"] !== 0 ||
                    headerData["АктивныеBusiness"] !== 0 ||  headerData["АктивныеBusinessПрошлый"] !== 0) &&
                <Grid item xs={12} md={4}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>
                        <div className={classes.reportdetails2}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={4}><Typography display="block" variant="caption" color="textSecondary" gutterBottom noWrap>{t("reports.baseindicators.Перваялиния")}</Typography></Grid>
                                <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["Период"])}</Typography></Grid>
                                <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["ПериодПрошлый"])}</Typography></Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" display="block" noWrap title="КоличествоBusiness">{t("reports.baseindicators.КоличествоBusiness")}:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["КоличествоBusiness"]}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["КоличествоBusinessПрошлый"]}
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" display="block" noWrap title="НовичкиBusiness">{t("reports.baseindicators.НовичкиBusiness")}:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["НовичкиBusiness"]}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["НовичкиBusinessПрошлый"]}
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" display="block" noWrap title="АктивныеBusiness">{t("reports.baseindicators.АктивныеBusiness")}:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["АктивныеBusiness"]}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["АктивныеBusinessПрошлый"]}
                                </Grid>
                            </Grid>                                                        
                        </div>
                        <Grid container direction="row" spacing={1} justifyContent="flex-end">
                                <Grid item>
                                {loading && 
                                    <CircularProgress size={25} />
                                }
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.wrapper}>
                                        <Button fullWidth disabled={loading} size="small" color="primary" onClick={()=> handleClickByDetails(adviser.Id, 0, "detailsFirstLine")} >{t("reports.baseindicators.Details")}</Button>
                                    </div>
                                </Grid>
                        </Grid>
                        
                        {detailsShowFirstLineStructure && 
                        <Box overflow="auto" height="66vh" flexDirection="column" display="flex" >
                            <TableHeader />
                            <Box overflow="auto" flex={1} flexDirection="column" display="flex">
                                {loadingDetails && 
                                    <Grid container direction="row" justifyContent="center" alignItems="center"  style={{ height: 178}}> 
                                        <Grid item className={classes.progress}>                                
                                            <CircularProgress />                            
                                        </Grid>
                                    </Grid>
                                }
                                {selectedperiod &&                                                                            
                                    <ListPresenter data={detailsStructure} />
                                }
                            </Box>
                        </Box>
                        }                                        
                    </Paper>
                </Grid>
                }

                {/* Grand managers */}
                {headerData && (headerData["СтруктураГрандМенеджеры"] !== 0 || headerData["СтруктураГрандМенеджерыПрошлый"] !== 0 ) && 
                <Grid item xs={12} md={4}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>                    
                        <div className={classes.reportdetails2}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={4}><Typography display="block" variant="caption" color="textSecondary" gutterBottom noWrap>{t("reports.baseindicators.ГрандМенеджеры")}</Typography></Grid>
                                <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["Период"])}</Typography></Grid>
                                <Grid item xs={4}><Typography display="block" align="right" variant="caption" color="textSecondary" gutterBottom noWrap>{getColumnName(headerData["ПериодПрошлый"])}</Typography></Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" display="block" noWrap title="СтруктураГрандМенеджеры">{t("reports.baseindicators.СтруктураГрандМенеджеры")}:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["СтруктураГрандМенеджеры"]}
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "right"}}>
                                    {headerData["СтруктураГрандМенеджерыПрошлый"]}
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container direction="row" spacing={1} justifyContent="flex-end">
                            <Grid item>
                                {loading && 
                                    <CircularProgress size={25} />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.wrapper}>
                                <Button fullWidth disabled={loading} size="small" color="primary" onClick={handleClickByDetailsGrandManagers} >{t("reports.baseindicators.Details")}</Button>
                                {loadingDetailsGrandManagers && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                        {detailsShowGrandManagers && 
                        <Box overflow="auto" height="66vh" flexDirection="column" display="flex" >
                            <TableHeader />
                            <Box overflow="auto" flex={1} flexDirection="column" display="flex">
                                {loadingDetails && 
                                    <Grid container direction="row" justifyContent="center" alignItems="center"  style={{ height: 178}}> 
                                        <Grid item className={classes.progress}>                                
                                            <CircularProgress />                            
                                        </Grid>
                                    </Grid>
                                }
                                {selectedperiod &&                                                                            
                                    <ListPresenter data={detailsGrandManagers} />
                                }
                            </Box>
                        </Box>
                        }                    
                    </Paper>
                </Grid>
                }
                
                {/* Select catalog */}
                <Grid item xs={12}>
                    <Paper className={clsx(classes.paper, classes.indicator)}>                        
                        <FormControl className={classes.formControl}>
                            <InputLabel id="select-period-label">{t("reports.baseindicators.SelectCatalog")}</InputLabel>
                            <Select labelId="select-period-label"id="select-period"
                                value={period}
                                onChange={handleChange}
                                label="Period"
                            >                                
                            {
                                periods.map((item) => {
                                    return (                        
                                    <MenuItem color="" key={item.Id} value={item.Id}>{ item.Name }</MenuItem>
                                    );
                                })
                            }
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog onClose={()=>setShowDlgInfo(false)} aria-labelledby="inof-dialog-title" open={showDlgInfo} fullWidth={true} maxWidth="sm" fullScreen={fullScreen} >                                            
                    <DialogContent style={{padding: "8px 18px", minHeight: 600}} >
                        { loadingInfo &&
                            <div>
                                <Typography align="center" display="block" component="div" gutterBottom>{t("common.Loading")}</Typography>
                                <Grid container direction="row" justifyContent="center" alignItems="center">                                     
                                    <Grid item className={classes.progress}>                                        
                                        <CircularProgress />                            
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {infoConsultant && infoConsultant.Headers &&
                        <div>                            
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item>
                                    {infoConsultant.Customer.Avatar !== "" ?
                                    <Avatar alt={ infoConsultant.Customer.Name} src={`${host}${infoConsultant.Customer.Avatar}`} className={classes.large} style={{ borderWidth: 4, borderColor: getColor(infoConsultant.Headers.Data["СтатусАктивности"]) }} /> :
                                    <Avatar alt={ infoConsultant.Customer.Name } className={classes.large} style={{ borderWidth: 4, backgroundColor: getColor(infoConsultant.Headers.Data["СтатусАктивности"]), borderColor: getColor(infoConsultant.Headers.Data["СтатусАктивности"]) }} >
                                        <div className={classes.shortname}>
                                            { getUserName(infoConsultant.Customer.Name)}
                                        </div>                                        
                                    </Avatar>
                                    }
                                </Grid>
                                <p>
                                
                                </p>
                                <Grid item xs={12}>
                                    <Typography align="center" display="block" noWrap variant="subtitle2">{infoConsultant.Customer.RegistrationCode}</Typography>
                                    {infoConsultant.Headers.Data["СтатусПрошлый"].length > 0 && 
                                        <Typography align="center" display="block" noWrap variant="caption">{infoConsultant.Headers.Data["СтатусПрошлый"]}, <span style={{ textTransform: "lowercase" }}>{infoConsultant.Headers.Data["ПериодПрошлый"]}</span></Typography>
                                    }
                                    <Typography align="center" display="block" noWrap variant="subtitle2" className={classes.capitalize} >{infoConsultant.Customer.Name.toLowerCase()}</Typography>
                                    <Typography align="center" display="block" noWrap variant="caption" gutterBottom>{infoConsultant.Headers.Data["НаселенныйПункт"]}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} style={{marginBottom: 8}}>
                                <Grid item xs={6}>
                                    <Button 
                                        size="small" 
                                        fullWidth 
                                        variant="outlined"                                         
                                        href={`tel:${validPhone(infoConsultant.Customer.Phone)}`} startIcon={<PhoneIcon />}>
                                        Телефон
                                    </Button>
                                    {/* <Link title={infoConsultant.Customer.Phone} color="inherit" href={`tel:${validPhone(infoConsultant.Customer.Phone)}`}><PhoneIcon fontSize="small" /></Link> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                        size="small" 
                                        fullWidth 
                                        variant="outlined"                                         
                                        href={`mailto:${infoConsultant.Customer.Email}`} startIcon={<MailOutlineIcon />}>
                                        EMail
                                    </Button>
                                    {/* <Link title={infoConsultant.Customer.Email} color="inherit" href={`mailto:${infoConsultant.Customer.Email}`} ><MailOutlineIcon fontSize="small" /></Link> */}
                                </Grid>
                                <Grid item xs={12}>
                                    {infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"] && 
                                    <Button 
                                        size="small" 
                                        fullWidth 
                                        variant="outlined" 
                                        color="primary" 
                                        href={getMessangerHref (( infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"] !== "" ? infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"].toLowerCase(): "телефон") , infoConsultant.Customer.Phone, infoConsultant.Customer.Email)} 
                                        startIcon={ getMessangerIcon(( infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"] !== "" ? infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"].toLowerCase(): "телефон")) }>
                                        {t("reports.baseindicators.ПредпочитаемыйКаналСвязи")} - {(infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"] !== "" ? infoConsultant.Headers.Data["ПредпочитаемыйКаналСвязи"].toLowerCase(): "телефон")}
                                    </Button>
                                    }
                                </Grid>
                            </Grid>
                                                                                    
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.ДатаРегистрации")}:</Typography></Grid>
                                <Grid item xs={5}><Typography display="block" noWrap variant="body2">{ howLongWithUsStr(howLongWithUs(infoConsultant.Headers.Data["ДатаРегистрации"]))}</Typography></Grid>
                            </Grid>
                            
                            {infoConsultant.LastLogin && 
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.LastLogin")}:</Typography></Grid>
                                <Grid item xs={5}><Typography display="block" noWrap variant="body2">{getLastDay(howLongWithUs(infoConsultant.LastLogin.CreatedOn))}</Typography></Grid>
                            </Grid>
                            }
                            {infoConsultant.Customer.Birthday !== "1900-01-01T00:00:00" && 
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("register.Birthday")}</Typography></Grid>                                
                                <Grid item xs={5}><Typography display="block" noWrap variant="body2">{ moment(infoConsultant.Customer.Birthday).format("DD-MM-YYYY") }</Typography></Grid>                                
                            </Grid>
                            }
                        
                            {infoConsultant.Customer.Mentor && 
                            <Alert icon={false} variant="outlined" severity="warning" style={{marginTop: 8, marginBottom: 16 }}>
                                <AlertTitle style={{fontSize: 12}}>Наставник</AlertTitle>
                                <Typography display="block" noWrap variant="body2" className={classes.capitalize} gutterBottom> {infoConsultant.Customer.Mentor.Name.toLowerCase()}</Typography>
                                <Typography display="block" noWrap variant="body2" gutterBottom> {infoConsultant.Customer.Mentor.RegistrationCode}</Typography>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} style={{marginBottom: 8}}>
                                <Grid item xs={6}>
                                    <Button 
                                        size="small" 
                                        fullWidth                                                                            
                                        href={`tel:${validPhone(infoConsultant.Customer.Mentor.Phone)}`} startIcon={<PhoneIcon />}>
                                        Телефон
                                    </Button>                                    
                                </Grid>
                                <Grid item xs={6}>
                                    <Button 
                                        size="small" 
                                        fullWidth                                                                                 
                                        href={`mailto:${infoConsultant.Customer.Mentor.Email}`} startIcon={<MailOutlineIcon />}>
                                        EMail
                                    </Button>                                    
                                </Grid>
                            </Grid>
                            </Alert>
                            }
                                                            
                                {infoConsultant.Headers.Data["ТекущийПроцентКэшБек"] > 0 &&
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.ТекущийПроцентКэшБек")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{infoConsultant.Headers.Data["ТекущийПроцентКэшБек"]} %</Typography></Grid>
                                </Grid>
                                }
                                
                                {infoConsultant.Headers.Data["СуммаДоСледующегоШагаВКэшБеке"] > 0 && 
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" variant="body2">{t("reports.baseindicators.СуммаДоСледующегоШагаВКэшБеке")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{infoConsultant.Headers.Data["СуммаДоСледующегоШагаВКэшБеке"]} грн</Typography></Grid>
                                </Grid>
                                }

                                <Typography variant="subtitle2" style={{marginTop: 16, marginBottom: 16}} >Замовлення</Typography>
                            
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.ПоследнийОплаченныйЗаказДнейНазад")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{ getLastDay(infoConsultant.Headers.Data["ПоследнийОплаченныйЗаказДнейНазад"])}</Typography></Grid>
                                </Grid>
                                
                                {infoConsultant.Headers.Data["КоличествоНезавершенныхЗаказов"] > 0 &&
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.КоличествоНезавершенныхЗаказов")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{infoConsultant.Headers.Data["КоличествоНезавершенныхЗаказов"]} шт</Typography></Grid>
                                </Grid>                            
                                }
                                
                                {infoConsultant.Headers.Data["ПоследнийНеоплаченныйЗаказДнейНазад"] > 0 && 
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.ПоследнийНеоплаченныйЗаказДнейНазад")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{infoConsultant.Headers.Data["ПоследнийНеоплаченныйЗаказДнейНазад"]} дн</Typography></Grid>
                                </Grid>
                                }

                                {infoConsultant.Headers.Data["НезабранныйЗаказНаложкаДнейНазад"] > 0  &&
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.НезабранныйЗаказНаложкаДнейНазад")}</Typography></Grid>
                                    <Grid item xs={5}><Typography display="block" noWrap variant="body2">{infoConsultant.Headers.Data["НезабранныйЗаказНаложкаДнейНазад"]} дн</Typography></Grid>
                                </Grid>
                                }

                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={7}><Typography display="block" noWrap variant="body2">{t("reports.baseindicators.СтатусАктивности")}</Typography></Grid>
                                    <Grid item xs={5}>
                                        <Brightness1Icon fontSize="small" title={infoConsultant.Headers.Data["СтатусАктивности"]} style={{ color: getColor(infoConsultant.Headers.Data["СтатусАктивности"]) }} />                                    
                                    </Grid>
                                </Grid>
                            
                            

                        </div>
                    }
                    </DialogContent>
                
                    <DialogActions>
                        <Button onClick={()=>setShowDlgInfo(false)}>{t("buttons.Close")}</Button>                    
                    </DialogActions>
            </Dialog>
          
          </div>
        )}
      </AppConsumer>
    );
}

export default BaseIndicators;
