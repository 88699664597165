import React, { Component } from "react";
// import {withRouter} from "react-router-dom";
// import { bindActionCreators } from "redux";
// import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
// import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { withTranslation } from "react-i18next";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme => ({
    card: {    
        position: "relative",
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        // height: 512,
        // [theme.breakpoints.down("sm")]: {
        //     height: "auto",
        // },
    }, 
    media: {
        height: 180,
        [theme.breakpoints.up("sm")]: {
            height: 360,
        },
        
    },
    content: {
        minHeight: theme.spacing(20),
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    chip: {
        marginRight: theme.spacing(2),
    },
    act_detail: {
        position: "absolute",        
        right: 8,
        top: 164,
        [theme.breakpoints.up("sm")]: {
            top: 343,
        },
    },
    backdrop: {
        position: "absolute",   
        
        top: theme.spacing(12), 
        left: '50%',
        [theme.breakpoints.down("sm")]: {
            top: theme.spacing(10), 
            left: '45%',
        },
        zIndex: theme.zIndex.drawer + 1,
        // color: '#fff',
    },
});

const cdn = process.env.REACT_APP_HOST;

class PromotionsThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {     
        };
    }

    clickByPromotions = () => {
        this.props.clickByPromotions(this.props.item);
    };

    render() {
        const {item, classes, t, width } = this.props;
        return (
            item ?
            <Card className={classes.card}  elevation={0}>
                <CardActionArea onClick={this.clickByPromotions}>
                    {item.Banners && item.Banners.length > 0 ?
                        <CardMedia
                            className={classes.media}
                            image={ `${cdn}${item.Banners[0].FileName}`}
                            title={item.Name}
                        /> : <CardMedia
                                image={`${cdn}/assets/files/images/promotions/default.png`}
                                className={classes.media}                                    
                                title={item.Name}
                            />
                    }                    
                </CardActionArea>
                <div className={classes.act_detail}>
                            <Button 
                                variant="contained" 
                                size="small" 
                                color="secondary"
                                startIcon={<InfoOutlinedIcon />} 
                                onClick={this.clickByPromotions}
                            >
                                {
                                    item.PromotionType === 0 ? t("promotions.DetailsAboutPromotions") :
                                    t("promotions.DetailsAboutChest")
                                }
                            </Button>
                </div>

                <CardContent className={classes.content}>
                    <Typography  variant="h5" component="h2">
                        {parseInt(item.AvailableQuantity) > 0 ? <Chip className={classes.chip}  variant="outlined"  size="small" label={`${item.AvailableQuantity}`} />  : null}
                        { item.Localizations && item.Localizations.length > 0 ? item.Localizations[0].Name : item.Name }
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        { item.Localizations && item.Localizations.length > 0 ? item.Localizations[0].Description : item.Description }
                    </Typography>                        
                </CardContent>
            </Card> 
            :
            // Empty data
            <Card className={classes.card}  elevation={0}>
                <div style={{position:'relative'}}>
                    <div className={classes.backdrop}>
                        <div className="loader" style={{ width: 110, height: 50}}></div>
                    </div>
                </div>

                <Skeleton variant='rect' 
                    width={"100%"} 
                    height= {width === 'xs' ? 180 : 360}
                />                
                
                <CardContent className={classes.content}>
                    <Typography component="div" variant='h3'>
                        <Skeleton />
                    </Typography>

                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </CardContent>
            </Card>

        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)((PromotionsThumb))));