import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import {IconButton, Grid, MobileStepper, Paper } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { useTranslation } from "react-i18next";
import BigSlide from "./BigSlide";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      borderRadius: 0,
      // background: theme.palette.background.paper,
      //boxShadow: theme.palette.ambilight.boxshadow
    },
    mobileStepper: {
        background: 'none',
    },
    header: {
        padding: theme.spacing(1),      
        background: "linear-gradient(338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)",      
        color: "#fff",
    },
    
    img: {
      display: "block",
      overflow: "hidden",
      width: "100%",
    },
    caption: {
      display: "block",
      width: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 300,
        fontSize: "1.8rem",
      },
    },
    headertxt: {
      display: "block",
      width: "100%",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 300,
        fontSize: "1.1rem",
      },
    },
    tilebar: {
      position: "absolute",
      left: 0,
      right: 0,
      // display: "flex",
      // alignItems: "center",
      bottom: 46,
      background: "rgba(0,0,0,.5)",
      // minHeight: 80,
      // position: "absolute",
      // left: 0,
      // right: 0,
      // bottom: 46,
      // padding: theme.spacing(1),
      // background: "rgba(0, 0, 0, 0.4)",
    },    
  })
);

const BigSlider = (props) => {    
    const classes = useStyles();
    const { slider, cname, autoplay } = props;
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    
    const handleNext = () => {
        if(slider.Items.length > activeStep + 1){
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if(0 <= activeStep - 1){
            setActiveStep(activeStep - 1);
        }
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (            
      // eslint-disable-next-line react/no-unknown-property
      <Paper className={classes.root} cname={cname} elevation={0}> 
        { slider && slider.Items && slider.Items.length > 0 &&
        <>
            <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                autoplay={autoplay}
                interval={slider.AutoPlayInterval * 1000}
                style={{position: "relative"}}
            >
            {slider.Items.map((slide) => (
                <React.Fragment key={slide.Id}>
                    <BigSlide slide={slide} /> 
                </React.Fragment>                            
            ))}
            </AutoPlaySwipeableViews> 

            {slider.Items.length > 1 && 
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center" 
            >
                <Grid item xs={12} sm={6}>
                    <MobileStepper
                        className={classes.mobileStepper}
                        steps={slider.Items.length}
                        position="static"
                        variant='text'
                        activeStep={ activeStep }                             
                        nextButton={
                            <IconButton onClick={handleNext} disabled={activeStep === slider.Items.length - 1}>                    
                                <KeyboardArrowRight />
                            </IconButton>
                        // <Button size="small" onClick={handleNext} disabled={activeStep === slider.Items.length - 1}>
                        //     {t("buttons.Next")}
                        //     <KeyboardArrowRight />
                        // </Button>
                        }
                        backButton={
                            <IconButton onClick={handleBack} disabled={slider.Items.length === 0}>
                                <KeyboardArrowLeft />                 
                            </IconButton>
                        // <Button size="small" onClick={handleBack} disabled={slider.Items.length === 0}>
                        // <KeyboardArrowLeft /> 
                        // {t("buttons.Back")}                                
                        // </Button>
                        }
                    />
                </Grid>
            </Grid>
            }
        </> 
        }
      </Paper>
    )
}

BigSlider.propTypes = {  
  cname: PropTypes.string,
  slider: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Descr: PropTypes.string,
    ViewName: PropTypes.string.isRequired,
    FileName: PropTypes.string.isRequired,
    Items: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Caption: PropTypes.string.isRequired,
        Url: PropTypes.string.isRequired,
        Text: PropTypes.string.isRequired,
        Path: PropTypes.string.isRequired,
        // Img: PropTypes.string.isRequired,
        FullPath: PropTypes.string.isRequired,
        IsAuthenticated: PropTypes.bool.isRequired,
        Roles: PropTypes.arrayOf(PropTypes.string),
        Hide: PropTypes.bool.isRequired,
        ShowText: PropTypes.bool.isRequired,
        ShowFrom: PropTypes.string.isRequired,
        ShowTo: PropTypes.string.isRequired,
        ButtonText: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  autoplay: PropTypes.bool.isRequired
};

export default BigSlider;