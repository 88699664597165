import React from 'react';

import PropTypes from 'prop-types';
import { Typography, Box, Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        paper: {            
            borderRadius: 12,
            padding: theme.spacing(2,1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
    })
);


const UnderConstr = () => {
    const classes = useStyles();
  return (
    <Box className={classes.root} m={1} p={1}> 
        <Paper className={classes.paper} elevation={0}>        
            <Typography align='center'>
                <span className="material-icons" style={{fontSize: '8em'}}>engineering</span>
                <span className="material-icons" style={{fontSize: '4em'}}>construction</span>
            </Typography>
            <Typography variant='h1' align='center'>Under construction</Typography>
        </Paper>
    </Box>
  )
}

export default UnderConstr;