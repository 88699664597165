import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";
const requestMenuType = "REQUEST_MENU";
const receiveMenuType = "RECEIVE_MENU";
const changeMenuType = "CHANGE_MENU";
const saveMenuType = "SAVE_MENU";
const receiveCountriesType = "RECEIVE_COUNTRIES";
const receiveLangsType = "RECEIVE_LANGS";

const initialState = { 
  treeData: [], 
  countries: [],
  langs: [], 
  translates: [],
  isLoading: true 
};

export const actionCreators = {
    requestMenu : (file) => async (dispatch, getState) => { 
        if(file === getState().menuBuilder.file){
            return;
        }                
        dispatch({ type: requestMenuType, file });

        const userManager = await OidcUserManager();
        
        userManager.getUser()
        .then( (user) => {
            let token = null;   
            if(user != null){      
              token =  `Bearer ${user.access_token}`;
            }  

            fetch('/api/v1.0/Utils/Countries', { headers: { authorization: token, accept: 'Accept: application/json' } })
              .then((response) => response.json())
                .then((data) => {
                    if (data) {
                      dispatch({ type: receiveCountriesType,  countries: data});
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });


            fetch('/api/v1.0/Utils/GetCultures', { headers: { authorization: token, accept: 'Accept: application/json' } })
              .then((response) => response.json())
                .then((data) => {
                    if (data) {
                      dispatch({ type: receiveLangsType,  langs: data});
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });
              
              fetch(`/api/v1/menu/${file}`, { headers: { authorization: token, accept: 'Accept: application/json' } })
                .then((response) => response.json())
                  .then((data) => {
                      if (data) {
                        dispatch({ type: receiveMenuType,  treeData: data, file: file});
                      }
                  })
                  .catch((error) => {
                      console.log('Error', error);
                  });
            
            // axios.get(`/api/v1/menu/${file}`,  { headers: { Authorization: token } })
            //   .then(function(response){
            //     console.log('Get menu items', response.data);
            //     dispatch({ type: receiveMenuType,  treeData: response.data, file: file });
            // })
            // .catch((error) => {        
            //   console.log(error);           
            // });  

        });
    },

    changeMenu:  (treeData) => async (dispatch) => {        
        dispatch({ type: changeMenuType,  treeData: treeData });
    },

    saveMenu:  (treeData) => async (dispatch, getState) => {      
      const userManager = await OidcUserManager();
      userManager.getUser().then(function (user) {
        let token = null;   
        if(user != null){      
          token =  `Bearer ${user.access_token}`;
        } 
        let file = getState().menuBuilder.file;         
        const URL = "/api/v1/menu";
        axios.post(URL, {items: treeData, file: file}, { headers: { Authorization: token } })        
          .then(function(response){
            if(response.data){              
              dispatch({ type: saveMenuType,  treeData: treeData });           
            }            
      })
      .catch((error) => {        
        console.log(error);  
        // dispatch({ type: receiveErrorContentType, message: error.message });
      });  
    });

  },


}

export const reducer = (state, action) => {
    state = state || initialState;  
    switch (action.type) {
      case requestMenuType:
        return {
          ...state,
          file: action.file,
          isLoading: true,
        }
      case receiveMenuType:     
        return {
          ...state,
          file: action.file,
          treeData: action.treeData,
          isLoading: false,
      }
      case changeMenuType:     
        return {
          ...state,
          treeData: action.treeData,
          isLoading: false,
        } 
      case receiveCountriesType:
        return {
          ...state,
          countries: action.countries,          
        } 
      case receiveLangsType: 
      return {
        ...state,
        langs: action.langs,
      } 
      case saveMenuType:     
        return {
          ...state,
          treeData: action.treeData,
          isLoading: false,
        }      
      default:
        return state;
    }
    
};