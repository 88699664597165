import React, {useState, useEffect, useCallback} from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Components from "../Builder/components";

const API_URL = '/api/v1.0';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'block',
      width: '100%'
    },
  })
);

const Article = () => {
  const classes = useStyles();
  const {id} = useParams();
  const [page, setPage] = useState(null);

  const blankPage = () => {
      return {
        Id: 0,
        Data: {
          Id: '5bdf0b73-1bc2-4b2f-86c4-787f7addb7e3',
          component: 'root',
          children: [          
            {
              Id: '81c60e80-bade-4252-8989-da5a1b150f2d',
              component: "Typography",
              props: {
                display: "block",
                align: "center",
                gutterBottom: true,
                variant: "h2",
              },
              children: "Empty page",
            }
          ],
        },  
        Title: 'Empty page',
        Name: 'Empty page',
      }
  };

  const fetchPage = useCallback(async () => {
      if(!id){          
        setPage(blankPage());
      }else{
        const url = `${API_URL}/articles/read/${id}`;                    
        await fetch(url, { 
            method: 'GET', 
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",                
            },          
        })
        .then((response) => response.json())
          .then((data) => {
            if(data){            
              setPage({
                Id: data.Id,
                Name: data.Name,
                Title: data.Title,
                Data: JSON.parse(data.Data)
              });
            } else{                
              setPage(blankPage());
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } 
            
  }, [id]);
  
  useEffect( () => {
    fetchPage();
  }, [fetchPage]);
  
  return (
    <div className={classes.root}>
        { page ? page.Data.children.map(item => Components(item)) 
               : <h2 style={{ flexGrow: 1, padding: 8}}>Empty page</h2>
        }
    </div>
  )
}

export default Article