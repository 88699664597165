import React, { useRef } from 'react';
import { createStyles, makeStyles,  } from "@material-ui/core/styles";
import { Button, Paper, Box, Grid, Typography} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Country from './Country';

const useStyles = makeStyles((theme) =>
    createStyles({      
        root: {            
            flexGrow: 1,
            margin: theme.spacing(2, 1)
        },
        paper: {
            padding: theme.spacing(3,4),
            minHeight: theme.spacing(8),
            borderRadius: theme.spacing(2),
            boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        }
  })
);

export const Change = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const refCountry = useRef();

    const handleSave = () => {
        refCountry.current.Save();
    }

  return (
    <div className={classes.root}>        
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={6} md={5} >            
            <Paper className={classes.paper}>
                <Typography 
                    variant='h5' 
                    align='center' 
                    display='block'
                    gutterBottom                
                >
                {t('Change country')}
            </Typography>
            
            <Country ref={refCountry} />

            <Box mt={2} style={{textAlign: 'right'}}>
                <Button 
                    variant='contained' 
                    color="primary" 
                    onClick={handleSave}
                >
                    {t('buttons.Save')}
                </Button>
            </Box>


            </Paper>
          </Grid>
        </Grid>
        

    </div>
  )
}

export default Change;