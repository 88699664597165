import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {Box, Typography, Grid,  TextField, IconButton, CircularProgress } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import TranslateIcon from '@material-ui/icons/Translate';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SunEditor from "suneditor-react";
import CodeMirror from "codemirror";

import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/mode/xml/xml";
import "codemirror/mode/css/css";

import 'codemirror/lib/codemirror.css'
import "codemirror/theme/dracula.css";


const editorHeight = 130;
const ButtonList = [["formatBlock", "bold", "underline", "italic", "strike", "subscript", "superscript","fontColor", "hiliteColor", "removeFormat", "outdent", "indent", "align", "horizontalRule","list", "table", "link", "showBlocks", "fullScreen", "codeView"]];
const Fonts = [ "Roboto", "Arial", "tahoma", "Courier New,Courier", "Comic Sans MS", "Impact", "Georgia", "Trebuchet MS", "Verdana" ];
const Options = {
  "mode": "inline",
  "className": "editor_custom",
  "stickyToolbar": "-12px",
  font: Fonts,
  codeMirror: {
    src: CodeMirror,
    options: {            
        mode: 'htmlmixed',
        htmlMode: true,
        lineNumbers: true,
        lineWrapping: true,
        theme: "dracula"
    }
  },
  addTagsWhitelist: "br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|picture|source|iframe|audio|video|table|thead|tbody|tr|th|td|a|b|strong|var|i|em|u|ins|s|span|strike|del|sub|sup|code|svg|path",
  buttonList: ButtonList,
  attributesWhitelist: {
    "all": "contenteditable|id|style|colspan|rowspan|target|href|download|rel|src|alt|class|type|controls|media",
  },
};

const tabs = [
  {id: 'commonAttr', title: 'Common attributes'},
  {id:'descrAttr', title: 'Description attributes'},
  {id:'htmlAttr', title: 'Html attributes'},
  {id:'facebookAttr', title: 'Facebook attributes'}];

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: 0,
      left: -12,
      zIndex: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    acc_sum: {
      fontWeight: 400,
      fontSize: '1.2rem'
    }
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const LangResEditor = forwardRef(function LangResEditor(props, ref) {
  const { t, i18n } = useTranslation();

  const { token, data, baseRes } = props;  
  const classes = useStyles();
  const [expanded, setExpanded] = useState('commonAttr');
  const [changed, setChanged] = useState(false);
  const [shortName, setShortName] = useState(data.ShortName);
  const [extendedName, setExtendedName] = useState(data.ExtendedName);
  const [searchField, setSearchField] = useState(data.SearchField);
  const [htmlDescr, setHtmlDescr] = useState(data.HtmlDescr);
  const [htmlKeywords, setHtmlKeywords] = useState(data.HtmlKeywords);
  const [htmlTitle, setHtmlTitle] = useState(data.HtmlTitle);
  const [descr, setDescr] = useState(data.Descr);
  const [useSubstance, setUseSubstance] = useState(data.UseSubstance);
  const [substanceComposition, setSubstanceComposition] = useState(data.SubstanceComposition);
  const [facebookDescr, setFacebookDescr] = useState(data.FacebookDescr);
  const [facebookFeedDescr, setFacebookFeedDescr] = useState(data.FacebookFeedDescr);
  const [resTab, setResTab] = useState(0);
  const [loading, setLoading] = useState(false);
  
  useImperativeHandle(ref, () => ({
    updateData() {
      if(changed)
        return {
          Descr: descr,
          ExtendedName: extendedName,
          FacebookDescr: facebookDescr,
          FacebookFeedDescr: facebookFeedDescr,
          HtmlDescr: htmlDescr,
          HtmlKeywords: htmlKeywords,
          HtmlTitle: htmlTitle,
          Idrref: props.data.Idrref,
          KeyRowRef: props.data.KeyRowRef,
          LangIdrref: props.data.LangIdrref,
          LangTwoLatter: props.data.LangTwoLatter,
          LineNumber: props.data.LineNumber,
          Name: props.data.Name,
          SearchField: searchField,
          ShortName: shortName,
          SubstanceComposition: substanceComposition,
          UseSubstance: useSubstance
        };
      else
        return null;
    },
    // updateTab() {
    //   debugger;
    //   if(resTab === 0){
    //     setResTab(1);
    //   }else{
    //     setResTab(0);
    //   }
    // },
  }))

  const handleChangeField = (e) =>  {
    if(!e.target) return;
    
    setChanged(true);

    switch (e.target.name) {
      case 'ShortName':
        setShortName(e.target.value);
        break;
      case 'ExtendedName':
        setExtendedName(e.target.value);
        break;
      case 'SearchField':
        setSearchField(e.target.value);
        break;      
      case 'HtmlDescr':
        setHtmlDescr(e.target.value);
        break;
      case 'HtmlKeywords':
        setHtmlKeywords(e.target.value);
        break;
      case 'HtmlTitle':
        setHtmlTitle(e.target.value);
        break;
      case 'FacebookDescr':
        setFacebookDescr(e.target.value);
        break;
      case 'FacebookFeedDescr':
        setFacebookFeedDescr(e.target.value);
        break;
      default:
        break;
    }

  };

  const handleChangeTab = (event, newValue) => {    
    setResTab(newValue);
  };

  const handleTransleteSelected = () => {
    // console.log(data, baseRes);
    setLoading(true);
    const url = '/api/v1.0/translate/productattributes';
    const req = {
      "SourceLangAttr": baseRes ,
      "TargetLangAttr": data 
    };
    
    // console.log(req);
    
    fetch(url,
        {
            method: 'POST',         
            body: JSON.stringify(req),
            headers: {                  
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": token
            }
        },
    )
    .then((response) => response.json())
        .then((data) => {                    
          setChanged(true);
          
          // tab_1
          setShortName(data.ShortName);
          setExtendedName(data.ExtendedName);
          setSearchField(data.SearchField);
          
          // tab_2
          setDescr(data.Descr);          
          setUseSubstance(data.UseSubstance);
          setSubstanceComposition(data.SubstanceComposition);
          
          // tab_3
          setHtmlDescr(data.HtmlDescr);
          setHtmlKeywords(data.HtmlKeywords);
          setHtmlTitle(data.HtmlTitle);                    
        })
        .catch((error) => {
            console.log('Error', error);                
        })
        .finally(()=>{
          setLoading(false);
        });    
  };


  // console.log(i18n);
  
  return (
    <> 
    <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
      >      
      <Grid item xs={11}>
        <Tabs
          centered
          value={resTab}
          variant="fullWidth"
          onChange={handleChangeTab}
          aria-label="Resources tab"
        >
          {tabs.map((res, idx) => <Tab key={res.id} label={t(`langreseditor.${res.title}`)} {...a11yProps(idx)} /> )}
        </Tabs>
      </Grid>
      <Grid item>
        <div className={classes.wrapper}>
          <IconButton            
            edge="start"
            color="primary" 
            aria-label="translete"
            title="Translate product attributes"
            disabled={loading}
            onClick={handleTransleteSelected}
          >
          <TranslateIcon />
        </IconButton>
        {loading && <CircularProgress size={48} className={classes.fabProgress} />}
        </div>
      </Grid>
    </Grid>
        
    <TabPanel value={resTab} index={0} >
      <Grid container direction="row" spacing={2} >
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>{t('langreseditor.ShortName')}:</Typography>        
            <TextField
              id="ShortName"
              name="ShortName"
              variant="outlined"
              fullWidth              
              value={shortName}
              onChange={handleChangeField}
            />        
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>{t('langreseditor.ExtendedName')}:</Typography>        
            <TextField
              id="ExtendedName"
              name="ExtendedName"
              variant="outlined"
              fullWidth
              value={extendedName}
              onChange={handleChangeField}
            />        
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>{t('langreseditor.SearchField')}:</Typography>        
            <TextField
              id="SearchField"
              name="SearchField"
              variant="outlined"
              fullWidth
              value={searchField}
              onChange={handleChangeField}
            />
          </Grid>
      </Grid>
    </TabPanel>

    <TabPanel value={resTab} index={1} >
      <Grid container direction="row" spacing={2} >
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.Description')}:</Typography>
          <SunEditor
            id="Descr"
            name="Descr"
            placeholder="Please type here..."
            height={editorHeight}
            width="100%"        
            autoFocus={false} 
            setOptions={Options}
            setContents={descr}                                
            onChange={(content) => {setDescr(content);setChanged(true);}}
            lang={i18n.language === 'uk'? 'ua': i18n.language}
          />        
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.Use substance')}:</Typography>
          <SunEditor                  
            id="UseSubstance"
            name="UseSubstance"
            placeholder="Please type here..."
            height={editorHeight}        
            autoFocus={false}
            setOptions={Options}
            setContents={useSubstance}
            onChange={(content) => {setUseSubstance(content);setChanged(true);}}
            lang={i18n.language === 'uk'? 'ua': i18n.language}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.Substance composition')}:</Typography>
          <SunEditor                  
            id="SubstanceComposition"
            name="SubstanceComposition"
            placeholder="Please type here..."
            height={editorHeight}
            autoFocus={false}
            setOptions={Options}
            setContents={substanceComposition}
            onChange={(content) => {setSubstanceComposition(content);setChanged(true);}}
            lang={i18n.language === 'uk'? 'ua': i18n.language}
          />
        </Grid>
      </Grid>
    </TabPanel>

    <TabPanel value={resTab} index={2} >
      <Grid container direction="row" spacing={2} >
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.HtmlDescr')}:</Typography>
            <TextField
              id="HtmlDescr"
              name="HtmlDescr"
              variant="outlined"
              fullWidth
              value={htmlDescr}
              onChange={handleChangeField}
            />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.HtmlKeywords')}:</Typography>
            <TextField
              id="HtmlKeywords"
              name="HtmlKeywords"
              variant="outlined"
              fullWidth
              value={htmlKeywords}
              onChange={handleChangeField}
            />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.HtmlTitle')}:</Typography>
            <TextField
              id="HtmlTitle"
              name="HtmlTitle"
              variant="outlined"
              fullWidth
              value={htmlTitle}
              onChange={handleChangeField}
            />
        </Grid>
      </Grid>
    </TabPanel>

    <TabPanel value={resTab} index={3} >
      <Grid container direction="row" spacing={2} >
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.Facebook Descr')}:</Typography>        
          <TextField
            id="FacebookDescr"
            name="FacebookDescr"
            variant="outlined"
            fullWidth
            value={facebookDescr}
            onChange={handleChangeField}
          />        
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' gutterBottom>{t('langreseditor.Facebook Feed Descr')}:</Typography>        
          <TextField
            id="FacebookFeedDescr"
            name="FacebookFeedDescr"
            variant="outlined"
            fullWidth
            value={facebookFeedDescr}
            onChange={handleChangeField}
          />        
        </Grid>
      </Grid>
    </TabPanel>
    
    </>
  )
});

LangResEditor.propTypes = {
    token: PropTypes.any,
    data: PropTypes.shape({
        Descr: PropTypes.string,
        ExtendedName: PropTypes.string,
        FacebookDescr: PropTypes.string,
        FacebookFeedDescr: PropTypes.string,
        HtmlDescr: PropTypes.string,
        HtmlKeywords: PropTypes.string,
        HtmlTitle: PropTypes.string,
        Idrref: PropTypes.object,
        KeyRowRef: PropTypes.object,
        LangIdrref: PropTypes.object,
        LangTwoLatter: PropTypes.string,
        LineNumber: PropTypes.number.isRequired,
        Name: PropTypes.string,
        SearchField: PropTypes.string,
        ShortName: PropTypes.string,
        SubstanceComposition: PropTypes.string,
        UseSubstance: PropTypes.string
    }).isRequired,
    baseRes: PropTypes.shape({
      Descr: PropTypes.string,
      ExtendedName: PropTypes.string,
      FacebookDescr: PropTypes.string,
      FacebookFeedDescr: PropTypes.string,
      HtmlDescr: PropTypes.string,
      HtmlKeywords: PropTypes.string,
      HtmlTitle: PropTypes.string,
      Idrref: PropTypes.object,
      KeyRowRef: PropTypes.object,
      LangIdrref: PropTypes.object,
      LangTwoLatter: PropTypes.string,
      LineNumber: PropTypes.number.isRequired,
      Name: PropTypes.string,
      SearchField: PropTypes.string,
      ShortName: PropTypes.string,
      SubstanceComposition: PropTypes.string,
      UseSubstance: PropTypes.string
  }),

    // onChange: PropTypes.func.isRequired,  
}

export default LangResEditor;