import React, { useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import {
    IconButton,
} from "@material-ui/core";
import { connect, useDispatch } from 'react-redux';
import { actionOrderCreators } from "../../store/Order";
import { bindActionCreators } from "redux";

import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import "react-phone-input-2/lib/material.css";
import { useSnackbar } from 'notistack';
import { AppContext } from "../../providers/appProvider";

const useStyles = makeStyles((theme) => ({
    root: {},
    snackbar: {
        top: 206,
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            top: 200,
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: "25ch",
    },
    box: {
        // padding: theme.spacing(2, 2),
        width: "60%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    phone: {
        width: "100%",
    },
    content: {
        padding: theme.spacing(1),
        height: 600,
        [theme.breakpoints.down("xs")]: {
            height: 600,
        },
        overflowY: "auto",
    },
    notification: {
        position: "relative",
    },
    close: {
        backgroundColor: theme.palette.background.paper,
        position: "absolute",
        right: theme.spacing(-1),
        top: theme.spacing(-1),
        zIndex: 1000,
    },
    iconClose: {
        color: "#fff"
    }
}));

const Subscribe = (props) => {
    const { pathname, connection, token } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const app = useContext(AppContext);
    const { lang, country } = app;
        
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const saveViewed = useCallback(async (pageId) => {        
        const url  = `/api/v1.0/page/PageViewed/${pageId}`;
        console.log(url);

        await fetch(url, { 
            method: "POST",
            headers: { authorization: token} 
        })
        .then((response) => response.json())
            .then((data) => {
                console.log('Add page viewed', data);                
            })
       .catch((error) => {
           console.log('Error', error);
       });
        
    }, [token]);

    useEffect(() => {        
        async function fetchNotifications() {
            if(pathname.toLowerCase().startsWith("/manage")){
                return;
            }

            const req = {'url': pathname, 'lang': lang, 'country': country}
            
            await fetch('/api/v1.0/Page/Notification', {
                method: "POST",
                headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                  "Authorization": token
                },
                body: JSON.stringify(req)
              })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if(data && data.length > 0){
                        for (let i = 0; i < data.length; i++) {
                            const el = data[i];
                            const autoHideDuration = parseInt(el.ShowDuration);
                            enqueueSnackbar(
                                el.Name,
                                {
                                    variant: "custormSnackbar",
                                    persist: false,
                                    preventDuplicate: true,
                                    anchorOrigin: { vertical: "bottom", horizontal: "right" },
                                    autoHideDuration: 1000 * (autoHideDuration === 0 ? 3 : autoHideDuration),
                                    allowDownload: true,
                                    data: el
                                }
                            ); 

                            // if(el.ShowFirstTime)
                            saveViewed(el.Id);
                        }
                    }                    
                })
                .catch((error) => {
                  console.log("Error", error);
                });  
        }
        
        fetchNotifications();

    }, [country, enqueueSnackbar, lang, token, pathname, saveViewed]);

    useEffect(() => {
        if(connection){
            if(connection._connectionStarted) return;

            connection.start()
            .then(() => {
                connection.on("SendMessageToUser", (_message) => {                    
                    console.log("SendMessageToUser", _message);
                    const notification = JSON.parse(_message);
                    console.log(notification);

                    // setMessage(_message);
                    // setOpen(true);
                    // dispatch(actionOrderCreators.showMessage(_message, "info", 'filled', 30000));
                    // enqueueSnackbar(
                    //     _message,                         
                    //     {   variant: 'info',
                    //         autoHideDuration: 3000,
                    //         persist: true,
                    //         anchorOrigin: { vertical: "top", horizontal: "right" }
                    //     }
                    // );

                    

                    enqueueSnackbar(
                        notification.message,
                        {
                            variant: notification.severity,
                            persist: false,
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: notification.duration,
                            allowDownload: true,                       
                            action: (key) => (
                                <>                                    
                                    <IconButton size='small' onClick={() => closeSnackbar(key)}>
                                        <CloseIcon  className={classes.iconClose} />
                                    </IconButton>
                                </>
                            )
                        }
                    );
                });

                connection.on("SendMessageToAll", (_message) => {                    
                    // console.log("SendMessageToAll", _message);
                    // dispatch(actionOrderCreators.showMessage(_message, "warning", 'outlined', 10000000));
                    enqueueSnackbar(
                        _message, 
                        {   variant: 'info',
                            autoHideDuration: 30000,
                            anchorOrigin: { vertical: "top", horizontal: "right" }
                        }
                    );
                });
                
                connection.on("ReceiveSystemMessage", (_user, _message) => {
                    console.log("ReceiveSystemMessage", _user, _message);
                    // setMessage(_message);
                    // setOpen(true);
                });
            })
            .catch((error) => console.log(error));
        }
        
    }, [classes.iconClose, closeSnackbar, connection, enqueueSnackbar]);

    return (
        null
    );
};

Subscribe.propTypes = {
    connection: PropTypes.object.isRequired,
    token: PropTypes.string
};

// export default Subscribe;
export default connect(
    state => state.orderRequest,
    dispatch => bindActionCreators(actionOrderCreators, dispatch)
)(Subscribe)

