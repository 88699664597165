import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

import {extractFileExt, IsImage, IsVideo } from '../../../helpers/utils';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        card: {
            position:'relative',
            width: '100%',
            borderRadius: 0,
            height: '100%',        
        },
        media: {
            height: 460,
        },
        img: {
            // paddingTop: "36.25%", // 16:9
            // [theme.breakpoints.down("sm")]: {
            //     paddingTop: "56.25%", // 16:9      
            // },
          },
        video: {        
            // height: "46.25vw!important",        
            [theme.breakpoints.down("sm")]: {        
              // height: "65vw!important",     
            },
        },
        content: {
            width: "100%",
            display: "block",
            padding: theme.spacing(1, 0),            
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            color: grey[200],
            textShadow: `0px 1px ${theme.spacing(1/8)}px ${grey[700]}`,      
            backgroundColor: '#00000052',      
        },
    })
);

const PreviewMedia = props => {
    const classes = useStyles();
    const { src, caption, text, showText} = props;
    const ext = (extractFileExt(src));

    return (
        <Card className={classes.card} elevation={0}>
            {IsImage(ext) && 
                <CardMedia
                    className={classes.media}
                    image={src}
                    title={caption}                    
                />
            }
            {IsVideo(ext) && 
                <div className={classes.video}>
                  <video style={{ width: "100%"}} muted loop autoPlay playsInline>
                    <source src={src} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>
                </div>
            }
            {showText && 
            <div className={classes.content} >
                <Typography className={classes.caption} variant="h5" display='block' align='center' >
                    {caption}    
                </Typography>
                <Typography className={classes.text} variant="h6"  display='block' align='center'>
                    {text}
                </Typography>
            </div>
            }

        </Card>
        
    )
}

PreviewMedia.propTypes = {
    src: PropTypes.string.isRequired,
    caption: PropTypes.string,
    text: PropTypes.string,
    showText: PropTypes.bool.isRequired
}

export default PreviewMedia