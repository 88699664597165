
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
import { Typography, IconButton, Grid} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


import TypographyEditor from "./TypographyEditor";
import ImgEditor from "./ImgEditor";
import MediaEditor from "./MediaEditor";
import GridEditor from "./GridEditor";

import CarouselEditor from "./CarouselEditor";

import ButtonEditor from './ButtonEditor';
import BoxEditor from "./BoxEditor";
import PaperEditor from "./PaperEditor";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    message: {
      fontSize: '1em',
      fontFamily: "Roboto, Helvetica, Arial, sans-seri",
      fontWeight: 400,
      lineHeight: "1.1876em",
      letterSpacing: "0.00938em",
      width: "100%",
      padding: 8,
      resize: "none",
      borderRadius: 4,
      border: "1px solid #cccccc",
      backgroundColor: "rgba(238, 238, 238, 0.35)"
    },
    button: {
      marginRight: theme.spacing(1)
    },
}));

const KeysToComponentMap = {
  Typography: TypographyEditor,
  Grid: GridEditor,
  'Paper': PaperEditor,
  'Box': BoxEditor,
  'CButton': ButtonEditor,
  "img": ImgEditor,
  "Media": MediaEditor,
  "BigCarousel": CarouselEditor,
  "MdCarousel": CarouselEditor,
  "SmCarousel": CarouselEditor,
};

const PropsEditor = (props) => {
    // console.log(props.node);
    const { onUpdate, onMove, onDelete, node } = props;
    const classes = useStyles();

    const [ component, setComponent] = useState(node);
    const [componetExt, setComponetExt] = useState('');
        
    const handleMove = (direction) => {
      if(onMove)
        onMove(component, direction);
    };

    const handleDelete = () => {
      if(onDelete)
        onDelete(component);
    };
    
    const editComponet = () => {
      if(!node) return;

      if (typeof KeysToComponentMap[node.component] !== "undefined") {
        return React.createElement(
          KeysToComponentMap[node.component],
          { key: `ed-${node.Id}`, node: node, onUpdate: onUpdate }
        );        
      }
      return;            
    }

    useEffect( () => {
      if(node){      
          setComponent(node);
          if(node.component === 'Grid'){          
            setComponetExt(typeof node.props.item === 'undefined' ? ' -> Row' : " ->  Item") ;
            return;
          }
          setComponetExt('');          
      }
    }, [node]);

  return (
    <div className={classes.root}>
        {component && 
            <div>
              <Typography display="block" variant='caption' align="right">{component.component} {componetExt}</Typography> 
              <Grid container spacing={1} style={{marginTop: 8}}>
                <Grid item xs={10}>
                  <IconButton size="small" className={classes.button} onClick={()=> handleMove('up')}><ArrowUpwardIcon /></IconButton>
                  <IconButton size="small" className={classes.button} onClick={()=> handleMove('down')}><ArrowDownwardIcon /></IconButton>                
                </Grid>
                <Grid item xs={2}>
                  <IconButton size="small" className={classes.button} onClick={handleDelete}><DeleteForeverIcon /></IconButton>
                </Grid>
                
                { editComponet() }
                              
              </Grid>
            </div>
        }
    </div>
  )
}

PropsEditor.propTypes = {
  node: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default PropsEditor