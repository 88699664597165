import React, {
    useState,
    useContext,
    useCallback,
    useEffect,
    useRef,
} from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, alpha } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    Backdrop,
    Dialog,
    FormGroup,
    Typography,
    Badge,
    FormControl,
    Chip,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
} from "@material-ui/core";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: "relative",
            width: WIDTH_COLLAPSED + 1,
            height: "100%",
            zIndex: 1,
            overflow: "visible",
            
        },
        containerbox: {
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: theme.spacing(1),
            bottom: theme.spacing(1),
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.palette.ambilight.boxshadow
            //borderTop: `1px solid ${theme.palette.divider}`,
            // borderRight: `1px solid ${theme.palette.divider}`,
        },
        componetheader: {
            position: "absolute",
            top: 0,
            display: "flex",
            alignItems: "center",
            // fontSize: 20,
            transform: "rotate(90deg) translate(-10px, 0)",
            transformOrigin: "0 50%",
            whiteSpace: "nowrap",            
        },
        collapsebox: {
            height: "100%",
            justifyContent: "flex-end",            
        },
        sectionname: {
            display: 'block',
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2)
        },
        components: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: theme.spacing(1),
            padding: theme.spacing(1)
        },
        componentbutton: {
            position: 'relative',            
            color: theme.palette.type === "dark" ? '#eee' : theme.palette.typography, 
            backgroundColor: 'transparent',
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            verticalAlign: 'middle',       
            width: theme.spacing(8),
            height: theme.spacing(8),
            border: `1px solid ${theme.palette.divider}`,
            cursor: 'pointer',
            borderRadius: theme.spacing(1/2),
            "&:hover": {
                // backgroundColor: theme.palette.type === "dark" ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)' 
                backgroundColor: theme.palette.type === "dark" ? alpha(theme.palette.primary.main, 0.08) : alpha(theme.palette.primary.main, 0.08)  
              },
            
        },
        componentLabel: {
            fontSize: '.7em'
        }

    })
);

const WIDTH_COLLAPSED = 40;

const ToolBox = (props) => {
    const classes = useStyles();
    const { components, onAddComponent } = props

    const [openStart, setOpenStart] = React.useState(0);
    
    const closeTimeoutRef = React.useRef(null);

    const openDrawer = React.useCallback(() => {
        if (closeTimeoutRef.current) {
          clearTimeout(closeTimeoutRef.current);
        }
    
        closeTimeoutRef.current = setOpenStart(Date.now());
      }, []);
    
      const closeDrawer = React.useCallback(
        (delay) => {
          const timeOpen = Date.now() - openStart;
          const defaultDelay = timeOpen > 750 ? 500 : 0;
          closeTimeoutRef.current = setTimeout(() => {
            setOpenStart(0);
          }, delay ?? defaultDelay);
        },
        [openStart, setOpenStart],
      );
    

    const handleMouseEnter = React.useCallback(() => openDrawer(), [openDrawer]);
    const handleMouseLeave = React.useCallback(() => closeDrawer(), [closeDrawer]);


    const drawerOpen = !!openStart;
    return (
        <div 
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Paper className={classes.containerbox} square elevation={0}>
                <Collapse
                    in={drawerOpen}                    
                    timeout={200}
                    className={classes.collapsebox}
                    style={{ display : drawerOpen ? 'flex': 'none' }}
                >
                    <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
                        <Box
                            sx={{
                            width: '100%',
                            // pl: 1,
                            // pr: 1,
                            }}
                        >
                            
                            <Box
                                sx={{
                                flex: 1,
                                width: 240,
                                overflow: 'auto',
                                scrollbarGutter: 'stable',
                                }}
                            >
                                <Typography className={classes.sectionname} variant='button'>
                                    Components
                                </Typography>

                                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={1} padding={1}>
                                    {components && 
                                    <Box className={classes.components}>
                                        {components.map((component, i) => {
                                            return(
                                                <button key={i} 
                                                    className={classes.componentbutton}
                                                    disabled={component.Disabled}
                                                    style={{color: component.Color ? component.Color : ''}}
                                                    onClick={() => onAddComponent(component.PageBlockType)}
                                                >
                                                    <Typography display="block" align="center">
                                                        <span className={clsx("material-icons")} >{component.Icon}</span>
                                                    </Typography>
                                                    <Typography variant="caption" className={classes.componentLabel}>
                                                         {component.Name}
                                                     </Typography>
                                                </button>
                                                
                                            )
                                        })}
                                    </Box>}
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Collapse>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: WIDTH_COLLAPSED,
                    }}
                >
                    <Box sx={{ mt: 2 }}>
                        {openStart ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                    </Box>
                    <Box position="relative">
                        <Typography variant='button' className={classes.componetheader}>
                            Component library
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
};

ToolBox.propTypes = {
    components: PropTypes.array,
    onAddComponent: PropTypes.func.isRequired,
};

export default ToolBox;
