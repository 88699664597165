import React, { useState, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { actionCreators } from "../../store/NavItems";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";

import { ClickAwayListener, AppBar, Tabs, Tab, Popover, List, ListItem, Grid, Box, Icon, IconButton, Typography} from "@material-ui/core";
import { Link } from "react-router-dom";

import { useWidth} from '../../helpers/utils';

import { CatalogIcon, ChildIcon, FaceIcon, ForBodyIcon, ForHomeIcon, HairIcon, HealthIcon, JewerlyIcon, MakeupIcon, ManIcon, 
    ParfumsIcon, HeartPulseIcon, HomeLightbulbIcon, FaceOutlineIcon, AccountTieOutline, WomanHeadIcon } from "../icons/jerelia-icons";
import CloseIcon from "@material-ui/icons/Close";
import {gray} from "@material-ui/core/colors";
  
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
            // marginBottom: theme.spacing(1),
            boxShadow: theme.palette.ambilight.boxshadow
        },
        appbarsecond: {
          background: theme.palette.appbarsecond.background,
          paddingBottom: theme.spacing(1/4)
        },
        navbar: {
          paddingTop: 0,
          paddingBottom: 0,
          maxHeight: "100%",
        },
        menuarea:{
          paddingTop: theme.spacing(2),
          background: theme.palette.appbarsecond.background,      
          height: "38em",
          [theme.breakpoints.up("sm")]: {
            height: "36em",
          },
          width: "100%",                  
          overflow: "auto",      
        },
        menuitem: {            
          paddingTop: 6,
          paddingBottom: 2,
          '&:hover': {
            borderRadius: theme.spacing(1)
          },
        },
        bgimg: {            
          overflow: "auto",      
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
        },
        tabs: {
        },
    
        tabLabel: {
          fontSize: ".7rem",
          [theme.breakpoints.up("sm")]: {
            fontSize: ".8rem",
          },
        },
        indicator: {
          backgroundColor: theme.palette.primary.dark      
        },
        labelIcon: {
          fontSize: ".7rem",
          [theme.breakpoints.up("sm")]: {
            fontSize: ".8rem",
          },
        },
        label: {
          color: "red"
        },
        wrapper: {      
        },
        selected:{      
        },        
        closeBtn: {
          position: "absolute",               
          top: theme.spacing(1),
          right: theme.spacing(1),
          zIndex: 2            
        },
        navbgImg:{                
          padding: theme.spacing(1),    
          position: "absolute",               
          bottom: theme.spacing(1),
          right: theme.spacing(1),
          width: 'auto',
          // border: '4px solid #eeeeee4f',
          // backgroundColor: "#eeeeee4f",
          borderRadius: 24,
          // zIndex: 2            
        },
        bgImg:{
          maxWidth: "100%",
          height: 240,
          maxHeight: "100%",
          // border: '4px solid #fff',
          // borderRadius: 32,
        },
        listItem: {
          '&:hover': {
            borderRadius: theme.spacing(1)
        },
        }

    })
);

const host = process.env.NODE_ENV === "development" ? "https://jerelia.com": "";

const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
}

export const BigMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const width = useWidth();
  const app = useContext(AppContext);
    
  const dispatch = useDispatch();    
  const appLayout = useSelector((state) =>  state.appLayout );
  const { GroupNavItems } = appLayout;

  const [show, setShow] = useState(false);    
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickAway = () => {
      setShow(false);
      setAnchorEl(null);
  };
  
  const handleChangeTab = (event, newValue) => {    
      
    if(value === newValue){        
        setShow(!show);  
      }else{
        setShow(true);        
      }

      setAnchorEl(event.currentTarget);
      setValue(newValue);
  };

  const getIcon = (icon) => {
      switch (icon) {
        case "catalog":          
          return (<CatalogIcon width={24} height={24} />);
        case "child":          
          return (<ChildIcon width={24} height={24} />);
          case "women-head":          
          return (<WomanHeadIcon width={24} height={24} />);  
        case "face":
          return (<FaceIcon width={24} height={24} />);
        case "face-outline":          
          return (<FaceOutlineIcon/>);  
        case "for-body":          
          return (<ForBodyIcon width={24} height={24} />);
        case "for-home":          
          return (<ForHomeIcon width={24} height={24} />);
        case "hair":          
          return (<HairIcon width={24} height={24} />);
        case "health":          
          return (<HealthIcon width={24} height={24}/>);
        case "jewerly":          
          return (<JewerlyIcon width={24} height={24} />);
        case "makeup":          
          return (<MakeupIcon width={24} height={24}/>);
        case "account-tie-outline":          
          return (<AccountTieOutline />);
        case "parfums":          
          return (<ParfumsIcon width={24} height={24} />);
        case "heart-pulse":          
          return (<HeartPulseIcon width={24} height={24} />);
          case "manager":          
          return (<ManIcon width={24} height={24} />);  
        case "home-lightbulb":          
          return (<HomeLightbulbIcon width={24} height={24} />);
        default:
          return (<Icon width={24} height={24}>{icon}</Icon>)          
      }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'groups-popover' : undefined;

  return (
    <AppConsumer>
    {() => (
        <div className={classes.root}>                  
            <AppBar 
                color="inherit"  
                className={classes.appbarsecond} 
                position="static" 
                elevation={0} 
            >
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons="on"                  
                  classes={{                    
                    indicator: classes.indicator,
                    flexContainer: classes.flexContainer,
                  }}                  
                  textColor="primary"
                  aria-label="big menu"                  
                >
                {
                    GroupNavItems.map((item) => {
                      return (                        
                            <Tab 
                                color="inherit" 
                                key={item.id} 
                                aria-label={item.title}
                                classes={{
                                    root: classes.tabRoot,
                                    wrapper: classes.wrapper,
                                    labelIcon: classes.labelIcon,
                                    selected: classes.selected,                            
                                }}
                                icon={getIcon(item.icon)} 
                                label={item.title}                            
                            />  
                      );
                    })
                  }                  
                </Tabs>


                {/* {show && 
                <Box boxShadow={1}>
                  {
                    GroupNavItems.map((item, index) => {
                      return(                    
                        value === index && 
                        <TabPanel  className={classes.menuarea} key={item.id}>                                           
                          {( item.children.length > 0 && 
                              <Grid container spacing={4} className={classes.bgimg}>
                              {
                                item.children.map((sitem) => {
                                  return(
                                    <Grid key={sitem.id} item xs={12} sm='auto' >
                                      {
                                        (sitem.children !== null ? 
                                          (
                                          <List component="nav" className={classes.navbar}>
                                            <ListItemLink  key={sitem.id} component={Link} onClick={handleClickAway} to={sitem.href}>                                          
                                              <Typography color="textPrimary" variant="button">{sitem.title}</Typography>
                                            </ListItemLink>
                                            {
                                              sitem.children.map((titem) => (

                                                <ListItem button key={titem.id} component={Link} className={classes.menuitem} onClick={handleClickAway} to={titem.href}>                                                
                                                  <Typography variant="body2" key={titem.id} color="inherit" gutterBottom >{titem.title}</Typography>
                                                </ListItem>
                                                )
                                              )
                                            }
                                          </List>                                                    
                                          ) : (<p>{sitem.title}</p>))
                                      }
                                  </Grid>    
                                  )
                                })
                              }
                              </Grid>                                                    
                          )}
                        </TabPanel>
                    )})
                  } 
                </Box>
                } */}


            </AppBar>               
              
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClickAway}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              PaperProps={{
                style: { width: '100%', 
                         minHeight: 300 , 
                         borderRadius: theme.spacing(2),
                         boxShadow: theme.palette.ambilight.boxshadow
                        },
              }}
            >
              
                <div className={classes.closeBtn}>
                  <IconButton 
                    className={classes.buttons} 
                    role="button" 
                    aria-label="close menu"                     
                    onClick={handleClickAway}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              
              {GroupNavItems && GroupNavItems.length > 0  &&
              <Box pt={3} pb={2} pl={1} pr={1}>
                
                {GroupNavItems[value].backgroundimg.length > 0 && width !== "xs" &&
                  <div className={classes.navbgImg}>                    
                    <img className={classes.bgImg} alt={GroupNavItems[value].title} src={`${host}${GroupNavItems[value].backgroundimg}`} />
                  </div>
                }
                
                <Grid container spacing={width === 'xs' ? 2 : 4} className={classes.bgimg}>
                  { GroupNavItems[value].children.map((sitem) => {
                    return(
                      <Grid key={sitem.id} item xs={12} sm='auto' >
                        {
                          (sitem.children !== null ? 
                            (
                            <List component="nav" className={classes.navbar}>
                              <ListItemLink className={classes.listItem}  key={sitem.id} component={Link} onClick={handleClickAway} to={sitem.href}>                                          
                                <Typography color="textPrimary" variant="button">{sitem.title}</Typography>
                              </ListItemLink>
                              {
                                sitem.children.map((titem) => (
                                  <ListItem button key={titem.id} component={Link} className={classes.menuitem} onClick={handleClickAway} to={titem.href}>                                                
                                    <Typography variant="body2" key={titem.id} color="inherit" gutterBottom >{titem.title}</Typography>
                                  </ListItem>
                                  )
                                )
                              }
                            </List>                                                    
                            ) : (<div className={classes.listItem}>{sitem.title}</div>))
                        }
                      </Grid>    
                    )
                  })}
                </Grid>
              </Box>
              } 
              
            </Popover>
        </div>
    )}
    </AppConsumer>
  )
}

// export default BigMenu
export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(BigMenu)