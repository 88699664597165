import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";
const requestContentType = "REQUEST_CONTENTS";
const receiveContentType = "RECEIVE_CONTENTS";
const receiveErrorContentType = "ERROR_CONTENTS";
const closeErrorContentType = "CLOSE_ERROR_CONTENTS";

let today = new Date();
let newdate = new Date();
newdate.setDate(today.getDate()+36524);

const initialState = {
  content: {
    Id: 0,
    Key: "",
    Name: "",
    TopBanner: "",
    TopMobileBanner: "",    
    IsActive: false,
    StartedOn: today,
    FinishedOn: newdate,    
    ContentType: 0,    
    LocalValues: [],    
    Lang: "en"
  },
  Langs: [],
  // key: "",
  // contenttype: "",
  showDlg: false,
  dlgMessage: "", 
  loading: false,  
};

export const actionCreators = {

  
  requestContent: (key, contentType ) => async (dispatch, getState) => {     
    const contentEditor = getState().contentEditor; 
    
    if (key === contentEditor.content.key && contentType === contentEditor.content.contenttype) {
      // Don't issue a duplicate request (we already have or are loading the requested data)      
      return;
    }
           
    dispatch({ type: requestContentType });
        
    let content = null;
    if(key !== "")
    {       
      const URLCONT =  `/api/v1.0/content/${key}/true/%20`;
      const respCont = await fetch(URLCONT);
      content = await respCont.json();      
    }
    
    const URLLANG = "/api/v1.0/languege"; 
    const respLang = await fetch(URLLANG);
    const Langs = await respLang.json();          
    
    // new content
    if(content === null){
      let contenttype = 0;
        switch (contentType.toLowerCase()) {
          case "page":
            contenttype = 0;
            break;
          case "post":
            contenttype = 1;
            break;
          case "landing":
            contenttype = 2;
            break;
          case "widget":
            contenttype = 3;
            break;
          case "notification":
            contenttype = 4;
            break;
          case "event":
            contenttype = 5;
            break;
          case "program":
            contenttype = 6;
            break;
          case "block":
              contenttype = 7;
              break;  
          default:
            contenttype = 0;
            break;
        }
      
        let today = new Date();
        let newdate = new Date();
        newdate.setDate(today.getDate()+36524);

        content = {           
          Name: "",
          TopBanner: "",
          TopMobileBanner: "",           
          Id: 0,
          Key: key, 
          IsActive: false,
          StartedOn: today,
          FinishedOn: newdate,
          ContentType: contenttype, 
          LocalValues:[], 
          // Langs: Langs, 
          Lang: "en"
        };              
        
        
        for (let i = 0; i < Langs.length; i++) {
          const element = Langs[i]; 
          if(element.active){
            content.Lang = element.TwoLetterISOLanguageName;
          }         
          content.LocalValues.push(
            { Id: 0, 
              Title: "", 
              Descr: "", 
              Keywords: "",
              Value: "",
              Annonce: "",
              TwoLetterISOLanguageName: element.TwoLetterISOLanguageName
            });          
        }
    }
    else{
      // content.Langs = Langs;
    }    
    dispatch({ type: receiveContentType, contenttype:contentType, content, Langs });

  },

  saveContent: (handleSaved) => async (dispatch, getState) => { 
    const userManager = await OidcUserManager();
    userManager.getUser().then(function (user) {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }
      
      const contentedidtor = {...getState().contentEditor};

      const URL = "/api/v1/content";            
      if(contentedidtor.content.Id === 0){        
        axios.post(URL, contentedidtor.content, { headers: { Authorization: token } })
          .then(function(response){      
          if(response.data){
            if(handleSaved){
              handleSaved(response.data.Key);
            }            
            dispatch({ type: receiveContentType, content: response.data, Langs: contentedidtor.Langs });
          }       
        })
        .catch((error) => {        
          console.log(error); 
          dispatch({ type: receiveErrorContentType, message: error.message });
        });  
      }
      else {        
        axios.put(URL, contentedidtor.content, { headers: { Authorization: token } })
        .then(function(response){                  
          if(response.data){

            dispatch({ type: receiveContentType, content: response.data, Langs: contentedidtor.Langs});
          }
      })
      .catch((error) => {        
        console.log(error);        
        dispatch({ type: receiveErrorContentType, message: error.message });
      });  
      }      
    });   
  },

  updateContent: (index, target, value) => async (dispatch, getState) => {
    const contentEditor = getState().contentEditor;
    const content = {...contentEditor.content};
    
    if(content[target] !== undefined){
      content[target] = value;
    }
    else{
      content.LocalValues[index][target] = value;
    }
    
    dispatch({ type: receiveContentType, key: contentEditor.key, contenttype: contentEditor.contenttype, content: content, Langs: contentEditor.Langs });
  },

  updateContentChecked: (target, checked) => async (dispatch, getState) => {             
    const contentEditor = getState().contentEditor;
    const content = {...contentEditor.content};

    content[target] = checked;    
    dispatch({ type: receiveContentType, key: contentEditor.key, contenttype: contentEditor.contenttype, content: content,  Langs: contentEditor.Langs});
  },

  clearError: () => async (dispatch) => {
    dispatch({ type: closeErrorContentType, message: "" });
  },

};

export const reducer = (state, action) => {
  state = state || initialState;  
  switch (action.type) {
    case requestContentType:
      return {
        ...state,
        loading: true,
      }
    case receiveContentType:
      return {
        ...state,        
        contenttype: action.contenttype,
        content: action.content,
        Langs: action.Langs, 
        loading: false,
      }
    case receiveErrorContentType:    
      return {
        ...state,
        showDlg: true,
        dlgMessage: action.message,
        loading: false,
      }
    case closeErrorContentType:    
      return {
        ...state,
        showDlg: false,
        dlgMessage: action.message,
        loading: false,     
      }      
    default:
      return state;
  }
  
};