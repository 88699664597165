import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import clsx from "clsx";
import moment from "moment";
import "moment/locale/uk";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {            
            flexGrow: 1,            
        },
        detailsrow: {            
            marginBottom: theme.spacing(1),
            borderBottom: 'solid 1px #eee'
        },
        buttons: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        small: {
            fontSize: '.7em'
        },        
        
        stClr10: {        
        },
        
        stClr30: {
            backgroundColor: "#f44336",            
        },        
        stClr50: {
            backgroundColor: "#ff9800",                        
        },        
        stClr70: {
            backgroundColor: "#2196f3",            
        },        
        stClr150: {
            color: "#fff",
            backgroundColor: "#388e3c",
            border: "solid 1px #388e3c"
        },

        stateLbl: {
            padding: '2px 6px',
            marginRight: theme.spacing(1),
            borderRadius: 4,
            textTransform: "uppercase",
            fontSize: ".9em",
            fontWeight: 500,
        },
    })
);

const OrderHistory = (props) => {
    const { order } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [buttons, setButtons] = useState([]);
    moment.locale("uk");

    
    
    const GetClrState = (state) => {
        switch (state) {
            case 10:
                return classes.stClr10;
            case 30:
            case 130:
            case 140:
                return classes.stClr30;
            case 50:
            case 60:    
                return classes.stClr50;    
            case 70:
            case 80:    
                return classes.stClr70;
            case 150:
                return classes.stClr150;
            default:
                return null;
        }
    }

    const getPayStr = () => {                
        if(order.StateCode === 30 || order.StateCode === 140 || order.AmountPayment <= 0 || order.TypePaymentAbr ==="COD") {
            return null;
        }        
        //TODO: Not finish yet, payement type?
        return order.Paid ? t("orders.Payed") : null
    };

    useEffect (() => {
        if(!order)
            return;
        if(!order.RecipientPhone || order.RecipientPhone === '' )
            return;

        const strPhone = `${(order.RecipientPhone.startsWith("380") ? order.RecipientPhone : `38${order.RecipientPhone}`)}`;
        const tel =             
                <Button style={{marginLeft: 16, marginRight: 16}} size="small" href={`tel:${strPhone}`} startIcon={<PhoneForwardedIcon fontSize="small" />}>
                    Телефон
                </Button>;
        const viber =
                <Button style={{marginLeft: 16, marginRight: 16}} size="small" href={`viber://chat/?number=%2B${strPhone}`} startIcon={<ViberIcon fontSize="small" width={24} height={24} />}>
                    Viber
                </Button>;
        
        const telegram =            
                <Button style={{marginLeft: 16, marginRight: 16}} size="small" href={`https://t.me/+${strPhone}`} startIcon={<TelegramIcon fontSize="small" width={24} height={24} />}>
                    Telegram
                </Button>;
        
        const whatsapp =        
            <Button style={{marginLeft: 16, marginRight: 16}} size="small" href={`https://wa.me/+${strPhone}`} startIcon={<WhatsAppIcon fontSize="small" />}>
                WhatsApp
            </Button>;

            const items = [];
            items.push(tel);
            items.push(viber);
            items.push(telegram);
            items.push(whatsapp);

            setButtons(items);

    },[order]);

  return (
    <div className={classes.root}>        
        {order && 
            <div>
                <div style={{display: 'block', width: '100%', overflow: 'auto', paddingBottom: 8, marginBottom: 16, borderBottom: 'solid 1px #eee'}}>
                    
                    <Typography component='span'>{ t("order.Client") }: <b>{order.RecipientFirstName} {order.RecipientLastName}</b></Typography>
                    
                    <div style={{display: 'inline-block', flot: 'right', width: 'auto'}}>
                        {
                            buttons.map((component, idx) => (
                            React.cloneElement(component, { key: idx })
                            ))
                        }
                    </div>
                </div>
            

            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="stretch">
                <Grid item xs={12} sm={7}>
                    {order.Rows.map((row) => (
                        <div key={row.LineNumber} className={classes.detailsrow} >
                            <Grid  container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={2}>
                                    {row.ProductCode}
                                </Grid>
                                <Grid item xs={10}>
                                    {row.ProductName}
                                </Grid>
                            </Grid>
                                
                            <Grid className={classes.bdrbtn}  container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">                                                                                    
                                <Grid item xs={2} >
                                    <Typography variant="button" display="block">{row.Qty} <small className={classes.small}>шт</small></Typography>                                                                                        
                                </Grid>
                                <Grid item  xs={4} sm={2} >
                                    <Typography variant="button" display="block">{parseFloat(row.Price).toFixed(2)}</Typography>
                                </Grid>
                                <Grid item align="right" xs={4} sm={3} >
                                    <Typography variant="button" display="block">{parseFloat(row.Amount).toFixed(2)}</Typography>                                                                                        
                                </Grid> 
                            </Grid>   

                        </div>
                    ) )}
                </Grid>
                <Grid item xs={12} sm={5} >
                    <div style={{marginBottom: 16}}>
                        <Typography component='span' className={clsx(classes.stateLbl, GetClrState(order.StateCode))}>
                            {t(`order.State.${order.StateName}`)}
                        </Typography>
                        <Typography component='span' className={clsx(classes.stateLbl, GetClrState(order.StateCode))}>
                            {getPayStr()}
                        </Typography>
                    </div>
                                                            
                    <Typography display='block' variant="body1" gutterBottom noWrap >{t("order.AmountPaid")}: <b>{parseFloat(order.Amount).toFixed(2)}</b></Typography>                                                                                                            

                    { order.AmountCOD > 0 &&                                                         
                        <Typography display='block' variant="body2" gutterBottom noWrap > 
                            {t("order.AmountCOD") }: <b>{parseFloat(order.AmountCOD).toFixed(2)}</b>
                        </Typography>

                    }

                    <Typography display='block' variant="body2" gutterBottom noWrap >
                        {t("order.AmountDelivery")}: <b>{parseFloat(order.AmountDelivery).toFixed(2)}</b>                    
                    </Typography>
                    
                    <Typography display='block' variant="body2" gutterBottom noWrap >
                        {t("order.AmountService")}: <b>{parseFloat(order.AmountService).toFixed(2)}</b>
                    </Typography>
                                                                                        

                    { order.AmountOB > 0 &&
                    <Typography color='primary' display='block' variant="body2" gutterBottom noWrap >
                        {t("order.AmountLO") }: <b>{parseFloat(order.AmountOB).toFixed(2)}</b>
                    </Typography>                        
                    }
                    
                    <Grid container spacing={1} direction="row" style={{marginTop: 8}} >
                        <Grid item>
                            { t("order.BusinessPack") }:
                        </Grid>
                        <Grid item>
                            {order.UseBusinessPack ? <DoneAllIcon color="secondary" fontSize="small" />: null}
                        </Grid>
                    </Grid>
                    
                    <Typography display='block' variant="body2" gutterBottom noWrap >
                        {t("order.PaymentMethod")}: <b>{t(`order.payments.${order.PaymentType}`)}</b>
                    </Typography>
                                                                                    
                    { order.PostOffice &&
                    <>
                    <Typography display='block' variant="body2" gutterBottom noWrap >
                        {t("order.Carrier")}: <b>{order.PostOffice.Carrier}</b>
                    </Typography>
                    <Typography display='block' variant="body2" gutterBottom noWrap >
                        {t("order.DevileryAddress")}: <b>{order.PostOffice.LongName}</b>
                    </Typography>                                                                                                                                                
                    </>
                    }


                </Grid>                
            </Grid>
            </div>
        }
    </div>
  )
}

OrderHistory.propTypes = {
    order: PropTypes.object.isRequired,
}

export default OrderHistory
