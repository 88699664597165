import React from 'react';
import { useParams } from 'react-router-dom';
import Viewer from '../Manage/Content/Viewer';

export const View = () => {    
    const { key } = useParams();    
    return (        
        key && <Viewer url={key} showSkeleton={true} />
    )
}
export default View;