import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paper, Chip, Grid, Box, Typography, IconButton } from "@material-ui/core";
import { useWidth} from '../../helpers/utils';
import { grey } from "@material-ui/core/colors";
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: theme.spacing(1)
    },
    paper: {
        position: 'relative',
        padding: theme.spacing(4),
        borderRadius: 12,
        boxShadow: theme.palette.ambilight.boxshadow
    },
    box: {
        textAlign: 'center',
        padding: theme.spacing(0, 2)
    },
    borderLeft: {
        borderLeft: 'dotted',
        borderColor: grey[300],
        [theme.breakpoints.down("sm")]: {
            borderLeft: 'none',
        },
    },
    button:{
        minWidth: theme.spacing(17),
        marginRight: theme.spacing(2)
    },
    code404: {
        fontSize: '8em',
        [theme.breakpoints.down("sm")]: {
            fontSize: '6em',
        },
        color: grey[800],
        fontWeight: 100
    },
    text404: {
        color: grey[400],
        textTransform: 'uppercase',
    },
    img404: {
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '80%',
        },
    },
    secondText: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)        
    },
    back: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1)
    }
  })
);

export const Page404 = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(); 
    const { text } = props;
    const location = useLocation();

    const callback = location.state?.callback;

  return (
    <div className={classes.root}>        
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={6}>
          <Paper className={classes.paper}>
            { callback ?
            <IconButton className={classes.back} component={Link} to={callback} >
                <span className='material-icons'>arrow_back</span>
            </IconButton>:
            <IconButton className={classes.back} component={Link} to='/' >
                <span className='material-icons'>home</span>
            </IconButton>
            }

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm='auto'>
                <Typography className={classes.code404} variant='h1' display='block' align='center'>404</Typography>
                <Typography className={classes.text404} variant='h6' display='block' align='center'>Page not found</Typography>                
              </Grid>
              <Grid item xs={12} sm={true}>
                <Box className={clsx(classes.box, classes.borderLeft)}>
                    <div>
                        <img className={classes.img404} src="./assets/images/404.svg" alt="empty" />
                    </div>
                    {text && 
                    <Typography className={classes.secondText} gutterBottom>
                        {t("common.SearchNotFound", {str: text})}
                    </Typography>
                    }                    
                </Box>                
              </Grid>
        </Grid>

                              
                                
                      </Paper>
                </Grid>
              </Grid>
                
            
        
    </div>
  )
}

Page404.propTypes = {    
    text: PropTypes.string,
}

export default Page404;
