import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    blockquote, align, font, fontSize, fontColor, hiliteColor, horizontalRule,
    list, table, formatBlock,
    lineHeight, template, paragraphStyle, textStyle, link, image, video
} from 'suneditor/src/plugins';

import CodeMirror from "codemirror";

export const extImages = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
export const extVideos = ["mp4"];

export const extractFileExt = (path) => {
    const pathArray = path.split(".");
    const lastIndex = pathArray.length - 1;
    return pathArray[lastIndex].toLowerCase();
};

export const IsImage = (ext) => {
    return extImages.indexOf(ext) >= 0;
};

export const IsVideo = (ext) => {
    return extVideos.indexOf(ext) >= 0;
};

const plugin_command = {
    // @Required @Unique
    // plugin name
    name: 'customCommand',
    // @Required
    // data display
    display: 'command',

    // @Options
    title: 'Add range tag',
    buttonClass: '',
    innerHTML: 'H1',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
        const context = core.context;
        const rangeTag = core.util.createElement('div');
        core.util.addClass(rangeTag, '__se__me');

        // @Required
        // Registering a namespace for caching as a plugin name in the context object
        context.customCommand = {
            targetButton: targetElement,
            tag: rangeTag
        };
    },

    // @Override core
    // Plugins with active methods load immediately when the editor loads.
    // Called each time the selection is moved.
    active: function (element) {
        if (!element) {
            this.util.removeClass(this.context.customCommand.targetButton, 'active');
        } else if (this.util.hasClass(element, '__se__me')) {
            this.util.addClass(this.context.customCommand.targetButton, 'active');
            return true;
        }

        return false;
    },

    // @Required, @Override core
    // The behavior of the "command plugin" must be defined in the "action" method.
    action: function () {
        const rangeTag = this.util.getRangeFormatElement(this.getSelectionNode());

        if (this.util.hasClass(rangeTag, '__se__me')) {
            this.detachRangeFormatElement(rangeTag, null, null, false, false);
        } else {
            this.applyRangeFormatElement(this.context.customCommand.tag.cloneNode(false));
        }
    }
}

export const editorHeight = 600;
export const ButtonList = [
    ["formatBlock",],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
    ["align", "outdent", "indent"],
    ["horizontalRule", "blockquote", "list", "table", "link", "image", "video"],
    ["removeFormat", "showBlocks", "fullScreen", "codeView", "preview"],
    // ['customCommand_2',]
];

export const PluginList = [
    blockquote, align, font, fontSize, fontColor, hiliteColor, horizontalRule,
    list, table, formatBlock,
    lineHeight, template, paragraphStyle, textStyle, link, image, video,
    //plugin_command_2
];

export const SunEditorFonts = ["Roboto", "Helvetica", "Arial", "sans-serif"];
export const SunEditorOptions = {
    "className": "editor_custom",
    "stickyToolbar": "-12px",
    codeMirror: {
        src: CodeMirror,
        options: {
            mode: 'htmlmixed',
            htmlMode: true,
            lineNumbers: true,
            lineWrapping: true,
            theme: "dracula"
        }
    },
    plugins: PluginList,
    buttonList: ButtonList,
    font: SunEditorFonts,
    addTagsWhitelist: "br|p|div|pre|blockquote|h1|h2|h3|h4|h5|h6|ol|ul|li|hr|figure|figcaption|img|picture|source|iframe|audio|video|table|thead|tbody|tr|th|td|a|b|strong|var|i|button|em|u|ins|s|span|strike|del|sub|sup|code|svg|path",
    attributesWhitelist: {
        "all": "contenteditable|id|style|colspan|rowspan|target|href|download|rel|src|alt|class|type|controls|media",
    },
    // templates: [
    //     {
    //         name: "Template-1",
    //         html: "<p>HTML source1</p>"
    //     },
    //     {
    //         name: "Template-2",
    //         html: "<p>HTML source2</p>"
    //     }
    // ],
};

 
export const useWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {    
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  

export const stableSort = (array, cmp) => {  
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
export const getSorting = (order, orderBy) => {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }